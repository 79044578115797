import React from 'react';


export default function Put01(props) {
    
    return (
        <div className={`Put01-container ${props.size}`}> 
 
            <svg  xmlns="http://www.w3.org/2000/svg" id={props.id} width="500" height="500" viewBox="0 0 500 500">
                
            <pattern id="01_yellow" width="100%" height="100%">
                      <image xlinkHref="/01_yellow.jpg"/>
              </pattern>
          <pattern id="02_lightAmber" width="100%" height="100%">
              <image xlinkHref="/02_lightAmber.jpg"/>
          </pattern>
          <pattern id="03_darkAmber" width="100%" height="100%">
              <image xlinkHref="/03_darkAmber.jpg"/>
          </pattern>
          <pattern id="04_cherry" width="100%" height="100%">
              <image xlinkHref="/04_cherry.jpg"/>
          </pattern>
          <pattern id="05_redAmber" width="100%" height="100%">
              <image xlinkHref="/05_redAmber.jpg"/>
          </pattern>    
          <pattern id="06_orange" width="100%" height="100%">
              <image xlinkHref="/06_orange.jpg"/>
          </pattern>
          <pattern id="07_green" width="100%" height="100%">
              <image xlinkHref="/07_green.jpg"/>
          </pattern>
          <pattern id="08_olive" width="100%" height="100%">
              <image xlinkHref="/08_olive.jpg"/>
          </pattern>
          <pattern id="09_purple" width="100%" height="100%">
              <image xlinkHref="/09_purple.jpg"/>
          </pattern>
          <pattern id="10_lightBlue" width="100%" height="100%">
              <image xlinkHref="/10_lightBlue.jpg"/>
          </pattern>  
          <pattern id="11_aquaBlue" width="100%" height="100%">
              <image xlinkHref="/11_aquaBlue.jpg"/>
          </pattern>
          <pattern id="12_oceanBlue" width="100%" height="100%">
              <image xlinkHref="/12_oceanBlue.jpg"/>
          </pattern>
          <pattern id="13_cobaltBlue" width="100%" height="100%">
              <image xlinkHref="/13_cobaltBlue.jpg"/>
          </pattern>
          <pattern id="14_tealBlue" width="100%" height="100%">
              <image xlinkHref="/14_tealBlue.jpg"/>
          </pattern>
          <pattern id="15_clear" width="100%" height="100%">
              <image xlinkHref="/15_clear.jpg"/>
          </pattern>  
          <pattern id="16_granite" width="100%" height="100%">
              <image xlinkHref="/16_granite.jpg"/>
          </pattern>
          <pattern id="17_crackle" width="100%" height="100%">
              <image xlinkHref="/17_crackle.jpg"/>
          </pattern>
          <pattern id="18_iridescent" width="100%" height="100%">
              <image xlinkHref="/18_iridescent.jpg"/>
          </pattern>
          <pattern id="19_taffy" width="100%" height="100%">
              <image xlinkHref="/19_taffy.jpg"/>
          </pattern>
          <pattern id="20_seedy" width="100%" height="100%">
              <image xlinkHref="/20_seedy.jpg"/>
          </pattern> 
          <pattern id="21_frosted" width="100%" height="100%">
              <image xlinkHref="/21_frosted.jpg"/>
          </pattern>                                      
            <g className="hummingbird-image" >

<path id="background" fill="black"  d="m0,500V0h752v752H0Z"/>

    <path  onClick={() => props.onFill(1)} fill={props.fillColors[1]}   d="M192.8,187.3c-33.2-50.1-50.6-104.9-55.8-164.2c37.2,0,74.1,0,111.5,0C243.4,82.3,226,137.1,192.8,187.3z"/>
	<path  onClick={() => props.onFill(2)} fill={props.fillColors[2]}   d="M307.3,187.3c-33.2-50-50.5-104.8-55.8-164.1c37.1,0,74.1,0,111.5,0C357.8,82.5,340.5,137.3,307.3,187.3z"/>
	<path  onClick={() => props.onFill(3)} fill={props.fillColors[3]}   d="M477.5,23.2c-5.2,59.3-22.6,114-55.5,163.9c-31-44.2-51.4-105.5-55.9-163.9C403.1,23.2,440,23.2,477.5,23.2z"/>
	<path  onClick={() => props.onFill(4)} fill={props.fillColors[4]}   d="M22.6,23.1c37.6,0,74.3,0,111.4,0c-2.3,29.4-8,57.9-17.3,85.6c-9.2,27.7-21.7,53.9-38.5,78.5 C45.2,137.3,27.8,82.5,22.6,23.1z"/>
	<path  onClick={() => props.onFill(5)} fill={props.fillColors[5]}   d="M137.1,477.5c5.2-59.2,22.6-114,55.4-163.6c24.4,26,57.5,123.1,55.7,163.6 C211.4,477.5,174.6,477.5,137.1,477.5z"/>
	<path  onClick={() => props.onFill(6)} fill={props.fillColors[6]}   d="M477.5,477.5c-37.7,0-74.5,0-111.5,0c5.4-59.1,22.5-114,55.8-164C455,363.4,472.3,418.2,477.5,477.5z"/>
	<path  onClick={() => props.onFill(7)} fill={props.fillColors[7]}   d="M363.1,477.5c-37.4,0-74.3,0-111.5,0c5.3-59.2,22.5-114.1,55.8-164.1C340.5,363.4,357.7,418.1,363.1,477.5z"/>
	<path  onClick={() => props.onFill(8)} fill={props.fillColors[8]}   d="M134.1,477.5c-37.1,0-73.9,0-111.3,0c2.1-29.3,8-57.8,17.2-85.5c9.2-27.7,21.7-53.8,38.4-78.4 C111.6,363.5,128.8,418.3,134.1,477.5z"/>
	<path  onClick={() => props.onFill(9)} fill={props.fillColors[9]}   d="M364.5,252.1c21.8,16.6,39.9,36.4,55.7,59c-31.2,46.3-48.6,97.7-56,153c-0.3-2.4-0.5-4.9-0.9-7.3 c-0.4-2.6-1-5.1-1.4-7.7c-6.6-42.5-20.4-82.4-41.7-119.7c-3.1-5.5-6.8-10.6-9.9-16c-0.6-1.1-0.8-3.2-0.2-4.1 C325.5,287.7,343.2,268.4,364.5,252.1z"/>
	<path  onClick={() => props.onFill(10)} fill={props.fillColors[10]}   d="M135.8,252.3c20.7,15.9,38.1,34.7,53.3,55.7c1.8,2.4,1.7,4.1,0.1,6.5c-20.1,30.2-34.5,63.1-43.5,98.3 c-4,15.9-6.3,32.3-9.5,48.5c-0.2,0.9-0.5,1.7-0.5,1.7c-3.2-15.8-6-32.4-10.1-48.7c-8.7-34.5-22.5-66.9-41.9-96.8 c-0.9-1.4-2.1-2.8-2.6-4.4c-0.4-1.1-0.5-2.8,0.1-3.7c15.1-21.5,32.7-40.6,53.6-56.7C135.1,252.5,135.3,252.5,135.8,252.3z"/>
	<path  onClick={() => props.onFill(11)} fill={props.fillColors[11]}   d="M250.1,36.8c1.8,10.6,3.1,20.5,5.2,30.3c8.8,42.7,24.3,82.7,48.5,119.2c1.7,2.5,1.5,4.2-0.2,6.5 c-15.2,20.9-32.5,39.8-53.6,55.8c-3.8-3-7.7-6-11.4-9.2c-16.1-14.2-30.3-30.1-42.8-47.6c-1.6-2.2-0.6-3.5,0.5-5.2 c11.8-17.7,21.6-36.5,29.6-56.2c8.2-20.2,14.4-40.9,18.7-62.3C246.7,58,248.2,47.8,250.1,36.8z"/>
	<path  onClick={() => props.onFill(12)} fill={props.fillColors[12]}   d="M420.3,189.6c-15.8,22.5-33.9,42.4-55.7,59c-14.8-11.2-27.9-24-39.7-38.1c-4.9-5.8-9.2-12.1-14-18 c-1.7-2.1-1.6-3.6-0.2-5.8c10.8-16,19.8-33,27.4-50.7c10.8-25.4,18.6-51.7,23.1-78.9c1.1-6.8,2.2-13.7,3.4-20.5 C371.6,91.7,389,143,420.3,189.6z"/>
	<path  onClick={() => props.onFill(13)} fill={props.fillColors[13]}   d="M135.6,248.6c-16.7-12.9-31.3-27.3-44.1-43.6c-3.3-4.2-6.5-8.6-9.8-12.8c-1.4-1.8-1.5-3.2-0.2-5.2 c15-22.4,26.7-46.5,35.7-71.9c7.4-21,12.7-42.6,16.2-64.6c0.7-4.5,1.3-9,2.4-13.6c0.8,4.8,1.5,9.7,2.3,14.5 c7.6,48.1,23.7,92.9,50.6,133.7c2.2,3.4,2.2,5.6-0.3,8.9c-14.7,19.8-31.2,37.9-50.7,53C137,247.5,136.4,248,135.6,248.6z"/>
	<path  onClick={() => props.onFill(14)} fill={props.fillColors[14]}   d="M250.1,464.3c-2-11.5-3.5-22-5.6-32.4c-7.4-35.6-19.7-69.4-37.9-101c-3.3-5.8-7-11.4-10.6-17 c-1.3-1.9-1.3-3.2,0.1-5.2c15.2-21.3,32.8-40.2,54-56.5c4.2,3.4,8.6,6.7,12.7,10.3c15.4,13.8,29.1,29.1,41.1,46 c1.4,2,1.7,3.3,0.2,5.5c-19.6,29.3-33.9,61.2-42.8,95.2c-4.5,17.3-7.1,35.1-10.5,52.7C250.7,462.3,250.5,462.8,250.1,464.3z"/>
	<path  onClick={() => props.onFill(15)} fill={props.fillColors[15]}   d="M422.2,308.3c-8.9-10.5-17.1-21.1-26.2-30.8c-9.1-9.6-19.1-18.3-28.3-27.1c9.3-8.8,19.4-17.7,28.5-27.3 c9.1-9.6,17.2-20.1,26-30.5c15.3,21.5,33.2,41.2,54.9,57.8C455.7,267,437.7,286.5,422.2,308.3z"/>
	<path  onClick={() => props.onFill(16)} fill={props.fillColors[16]}   d="M78.3,308.9C62.6,286.6,44.6,267,23,250.4c21.6-16.7,39.6-36.2,55.4-58.5c15.8,22.2,33.7,41.9,55.4,58.5 C112.1,267,94.1,286.7,78.3,308.9z"/>
	<path  onClick={() => props.onFill(17)} fill={props.fillColors[17]}   d="M251.9,250.4c21.7-16.7,39.6-36.3,55.4-58.5c15.7,22.1,33.5,41.7,55.3,58.4c-21.5,16.8-39.6,36.2-55.3,58.6 C291.5,286.7,273.6,267,251.9,250.4z"/>
	<path  onClick={() => props.onFill(18)} fill={props.fillColors[18]}   d="M192.4,192.5c8.8,10.5,17,21.1,26.1,30.8c9.1,9.6,19.1,18.3,28.4,27.1c-9.4,8.9-19.5,17.7-28.6,27.4 c-9.1,9.6-17.2,20.1-25.9,30.5c-15.3-21.5-33.2-41.2-55-57.9C159,233.7,177,214.2,192.4,192.5z"/>
	<path  onClick={() => props.onFill(19)} fill={props.fillColors[19]}   d="M23.7,253.1c9.7,9.4,19.8,18.3,29,28.2c8,8.6,14.9,18.3,22.4,27.4c1.5,1.9,1.4,3.2,0.1,5.2 C55.6,343.2,41.5,375,32.3,409c-3.4,12.7-5.7,25.8-8.5,38.7c-0.4,1.6-0.7,3.3-1.4,4.9c0-66.2,0-132.4,0-198.5 C22.8,253.7,23.2,253.4,23.7,253.1z"/>
	<path  onClick={() => props.onFill(20)} fill={props.fillColors[20]}   d="M22.6,48.5c2.6,12,4.9,24.3,7.9,36.5c7.3,29.2,18.3,57,33.2,83.3c3.7,6.5,7.9,12.7,11.6,19.2 c0.6,1.1,0.8,3.1,0.1,4c-14.5,20.6-31.3,39.1-51.2,54.7c-0.4,0.3-0.9,0.5-1.7,1.1C22.6,180.6,22.6,114.4,22.6,48.5z"/>
	<path  onClick={() => props.onFill(21)} fill={props.fillColors[21]}   d="M477.7,47.3c0,67.1,0,133.3,0,200.4c-4.5-3.7-8.6-6.9-12.4-10.3c-15-13.5-28.2-28.5-40-44.9 c-1.6-2.2-1.5-3.7-0.1-5.8c16-23.9,28.2-49.6,37.3-76.8c6.3-18.8,11.1-37.9,14-57.5C476.6,50.9,477.1,49.6,477.7,47.3z"/>
	<path  onClick={() => props.onFill(22)} fill={props.fillColors[22]}   d="M477.7,253.3c0,66.9,0,133,0,200.3c-2.2-10.5-3.9-20-6-29.4c-8.8-39.2-23.9-75.8-46.1-109.4 c-1.8-2.7-1.8-4.4,0.2-7.1c13.7-18.9,29.2-36.1,47.4-50.8C474.4,255.8,475.7,254.8,477.7,253.3z"/>
	<path  onClick={() => props.onFill(23)} fill={props.fillColors[23]}   d="M134.1,2.9c0,5.8,0,11.3,0,17.1c-37.2,0-74.3,0-111.6,0c0-5.7,0-11.3,0-17.1C59.7,2.9,96.6,2.9,134.1,2.9z"/>
	<path  onClick={() => props.onFill(24)} fill={props.fillColors[24]}   d="M366,20c0-5.8,0-11.3,0-17.1c37.2,0,74.3,0,111.6,0c0,5.7,0,11.3,0,17.1C440.4,20,403.4,20,366,20z"/>
	<path  onClick={() => props.onFill(25)} fill={props.fillColors[25]}   d="M22.4,497.8c0-5.8,0-11.3,0-17.1c37.2,0,74.3,0,111.6,0c0,5.7,0,11.3,0,17.1C96.8,497.8,59.9,497.8,22.4,497.8 z"/>
	<path  onClick={() => props.onFill(26)} fill={props.fillColors[26]}   d="M477.6,480.7c0,5.8,0,11.3,0,17.1c-37.2,0-74.2,0-111.5,0c0-5.7,0-11.3,0-17.1 C403.2,480.7,440.3,480.7,477.6,480.7z"/>
	<path  onClick={() => props.onFill(27)} fill={props.fillColors[27]}   d="M248.4,480.7c0,5.8,0,11.3,0,17.1c-37.2,0-74.2,0-111.4,0c0-5.7,0-11.3,0-17.1 C174.1,480.7,211.2,480.7,248.4,480.7z"/>
	<path  onClick={() => props.onFill(28)} fill={props.fillColors[28]}   d="M363.1,480.7c0,5.8,0,11.4,0,17.2c-37.2,0-74.2,0-111.4,0c0-5.8,0-11.3,0-17.2 C288.8,480.7,325.9,480.7,363.1,480.7z"/>
	<path  onClick={() => props.onFill(29)} fill={props.fillColors[29]}   d="M248.4,20c-37.3,0-74.3,0-111.4,0c0-5.8,0-11.4,0-17.2c37.2,0,74.2,0,111.4,0C248.4,8.6,248.4,14.1,248.4,20z" />
	<path  onClick={() => props.onFill(30)} fill={props.fillColors[30]}   d="M251.6,20c0-5.7,0-11.2,0-17c37.1,0,74.2,0,111.5,0c0,5.6,0,11.2,0,17C326,20,289,20,251.6,20z"/>
	<path  onClick={() => props.onFill(31)} fill={props.fillColors[31]}   d="M497.4,249c-5.8,0-11.2,0-16.9,0c0-37.2,0-74.2,0-111.5c5.5,0,11.1,0,16.9,0 C497.4,174.6,497.4,211.6,497.4,249z"/>
	<path  onClick={() => props.onFill(32)} fill={props.fillColors[32]}   d="M2.6,251.7c5.8,0,11.2,0,16.9,0c0,37.1,0,74.2,0,111.5c-5.5,0-11,0-16.9,0C2.6,326.1,2.6,289.1,2.6,251.7z"/>
	<path  onClick={() => props.onFill(33)} fill={props.fillColors[33]}   d="M497.4,363.3c-5.7,0-11.1,0-16.9,0c0-37.2,0-74.2,0-111.5c5.6,0,11,0,16.9,0 C497.4,288.9,497.4,325.9,497.4,363.3z"/>
	<path  onClick={() => props.onFill(34)} fill={props.fillColors[34]}   d="M2.6,137.4c5.8,0,11.2,0,17,0c0,37.2,0,74.2,0,111.5c-5.6,0-11.1,0-17,0C2.6,211.8,2.6,174.8,2.6,137.4z"/>
	<path  onClick={() => props.onFill(35)} fill={props.fillColors[35]}   d="M480.6,23.1c5.6,0,11.1,0,16.9,0c0,37.2,0,74.2,0,111.5c-5.6,0-11.1,0-16.9,0 C480.6,97.6,480.6,60.5,480.6,23.1z"/>
	<path  onClick={() => props.onFill(36)} fill={props.fillColors[36]}   d="M19.6,23.2c0,37.2,0,74.2,0,111.4c-5.6,0-11.1,0-16.9,0c0-37,0-74.1,0-111.4C8.2,23.2,13.7,23.2,19.6,23.2z"/>
	<path  onClick={() => props.onFill(37)} fill={props.fillColors[37]}   d="M2.6,366.1c5.7,0,11.1,0,16.9,0c0,37.1,0,74.1,0,111.4c-5.5,0-11.1,0-16.9,0C2.6,440.4,2.6,403.3,2.6,366.1z" />
	<path  onClick={() => props.onFill(38)} fill={props.fillColors[38]}   d="M497.5,477.6c-5.8,0-11.3,0-17,0c0-37.2,0-74.2,0-111.5c5.7,0,11.2,0,17,0 C497.5,403.3,497.5,440.2,497.5,477.6z"/>
	<path  onClick={() => props.onFill(39)} fill={props.fillColors[39]}   d="M2.5,20c0-5.9,0-11.4,0-17.1c5.7,0,11.3,0,17.1,0c0,5.7,0,11.3,0,17.1C14,20,8.4,20,2.5,20z"/>
	<path  onClick={() => props.onFill(40)} fill={props.fillColors[40]}   d="M480.4,2.9c5.9,0,11.4,0,17.1,0c0,5.8,0,11.4,0,17.2c-5.7,0-11.3,0-17.1,0C480.4,14.3,480.4,8.7,480.4,2.9z"/>
	<path  onClick={() => props.onFill(41)} fill={props.fillColors[41]}   d="M19.5,497.9c-5.7,0-11.2,0-16.9,0c0-5.7,0-11.3,0-17.2c5.5,0,11.1,0,16.9,0C19.5,486.3,19.5,491.8,19.5,497.9z "/>
	<path  onClick={() => props.onFill(42)} fill={props.fillColors[42]}   d="M497.5,497.8c-5.9,0-11.4,0-17,0c0-5.8,0-11.4,0-17.2c5.7,0,11.2,0,17,0C497.5,486.4,497.5,491.9,497.5,497.8z "/>


    </g>
            </svg>
        </div>
    )
}