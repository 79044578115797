import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { glassData } from "./data";


const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

export default function ColorPalette(props) {

            return (
                <div className="color-palette calculator">
                {glassData.map((data, key) => {
                  const activeClass = props.currentColor === (data.id) ? "color-swatch-active" : "";
                  return (
                    <Grid container spacing={2}>
                    <Grid item xs onClick={() => {props.changeColor(data.id)}}>
                    <HtmlTooltip title={
          <React.Fragment>
            <Typography color="inherit">{data.color} {data.type}</Typography>
            <div>
            <em>{data.description}</em>
            </div>
            <svg width="150" height="150">
                <rect width="150" height="150" fill={data.id}></rect>
              </svg>
          </React.Fragment>   } >
                <svg id={data.id}  className={`color-swatch ${activeClass}`}  width="50" height="50">
                <rect width="50" height="50" fill={data.id}></rect>
                </svg>
                    </HtmlTooltip>   
                    </Grid>
                    </Grid>
                )
            })}  
      </div>
      
      
    )
  }