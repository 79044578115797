import React from 'react';
import './Hummingbird';


export default function Hermosasquare(props) {
    
    return (
        <div className={`hermosasquare-container ${props.size}`}> 
 
            <svg  xmlns="http://www.w3.org/2000/svg" id={props.id} width="500" height="500" viewBox="0 0 500 500">
                
            <pattern id="01_yellow" width="100%" height="100%">
                      <image xlinkHref="/01_yellow.jpg"/>
              </pattern>
          <pattern id="02_lightAmber" width="100%" height="100%">
              <image xlinkHref="/02_lightAmber.jpg"/>
          </pattern>
          <pattern id="03_darkAmber" width="100%" height="100%">
              <image xlinkHref="/03_darkAmber.jpg"/>
          </pattern>
          <pattern id="04_cherry" width="100%" height="100%">
              <image xlinkHref="/04_cherry.jpg"/>
          </pattern>
          <pattern id="05_redAmber" width="100%" height="100%">
              <image xlinkHref="/05_redAmber.jpg"/>
          </pattern>    
          <pattern id="06_orange" width="100%" height="100%">
              <image xlinkHref="/06_orange.jpg"/>
          </pattern>
          <pattern id="07_green" width="100%" height="100%">
              <image xlinkHref="/07_green.jpg"/>
          </pattern>
          <pattern id="08_olive" width="100%" height="100%">
              <image xlinkHref="/08_olive.jpg"/>
          </pattern>
          <pattern id="09_purple" width="100%" height="100%">
              <image xlinkHref="/09_purple.jpg"/>
          </pattern>
          <pattern id="10_lightBlue" width="100%" height="100%">
              <image xlinkHref="/10_lightBlue.jpg"/>
          </pattern>  
          <pattern id="11_aquaBlue" width="100%" height="100%">
              <image xlinkHref="/11_aquaBlue.jpg"/>
          </pattern>
          <pattern id="12_oceanBlue" width="100%" height="100%">
              <image xlinkHref="/12_oceanBlue.jpg"/>
          </pattern>
          <pattern id="13_cobaltBlue" width="100%" height="100%">
              <image xlinkHref="/13_cobaltBlue.jpg"/>
          </pattern>
          <pattern id="14_tealBlue" width="100%" height="100%">
              <image xlinkHref="/14_tealBlue.jpg"/>
          </pattern>
          <pattern id="15_clear" width="100%" height="100%">
              <image xlinkHref="/15_clear.jpg"/>
          </pattern>  
          <pattern id="16_granite" width="100%" height="100%">
              <image xlinkHref="/16_granite.jpg"/>
          </pattern>
          <pattern id="17_crackle" width="100%" height="100%">
              <image xlinkHref="/17_crackle.jpg"/>
          </pattern>
          <pattern id="18_iridescent" width="100%" height="100%">
              <image xlinkHref="/18_iridescent.jpg"/>
          </pattern>
          <pattern id="19_taffy" width="100%" height="100%">
              <image xlinkHref="/19_taffy.jpg"/>
          </pattern>
          <pattern id="20_seedy" width="100%" height="100%">
              <image xlinkHref="/20_seedy.jpg"/>
          </pattern> 
          <pattern id="21_frosted" width="100%" height="100%">
              <image xlinkHref="/21_frosted.jpg"/>
          </pattern>                                      
            <g className="hummingbird-image" >

<path id="background" fill="black"  d="m0,500V0h500v500H0Z"/>

  <path onClick={() => props.onFill(1)} fill={props.fillColors[1]} d="m27.28,472.92v-103.51c3.06-.49,6.04-.93,9.01-1.44,7.48-1.29,15-2.18,22.6-2.29,12.01-.18,23.66,1.9,35.05,5.63,9.61,3.15,18.57,7.64,27.15,12.96,5.17,3.2,10.08,6.78,14.69,10.73,5.7,4.88,11.12,10.08,16.06,15.76,11.59,13.34,22.01,27.58,32.47,41.8,4.5,6.11,8.96,12.25,13.44,18.38.41.56.78,1.15,1.34,1.99H27.28Z"/>
  <path onClick={() => props.onFill(2)} fill={props.fillColors[2]} d="m316.08,472.8c2.25-2.14,4.26-4.07,6.27-5.98,5.39-5.12,10.92-10.09,16.15-15.37,5.85-5.9,11.44-12.06,17.11-18.14,13.99-15,25.73-31.74,37.51-48.46,1.16-1.64,2.4-2.41,4.36-2.21.71.07,1.43-.05,2.15-.1,4.22-.33,8.28.23,12.15,2,1.89.86,3.86,1.54,5.7,2.5,6.41,3.34,13.1,3.77,20.03,2.17,7.32-1.69,14.79-2.4,22.23-3.3,3.7-.45,7.31-1.57,10.96-2.42.77-.18,1.51-.47,2.47-.77v89.96c-1.63.5-153.28.69-157.08.12Z"/>
  <path onClick={() => props.onFill(3)} fill={props.fillColors[3]} d="m471.95,380.93c-3.96,1.69-7.72,2.73-11.6,3.08-8.43.76-16.79,1.96-25.08,3.66-5.39,1.11-10.62.67-15.63-1.73-1.8-.86-3.72-1.48-5.47-2.41-5.3-2.82-10.99-3.28-16.78-2.73-6.36.6-12.4-.24-18.13-3.15-1.99-1.01-4.12-1.75-6.09-2.79-6.55-3.46-13.56-4.52-20.87-4.22-1.99.08-4,.03-5.98-.15-3.25-.3-6.17-1.57-8.87-3.37-2.19-1.46-4.41-2.9-6.49-4.51-5.16-3.99-10.8-7.07-16.91-9.34-2.77-1.03-5.46-2.25-8.18-3.39-3.19-1.34-5.99-3.28-8.94-5.6.91-3.19,1.7-6.43,2.76-9.59,1.84-5.48,4.37-10.62,8.17-15.06,2.37-2.76,5.15-5.05,8.21-6.92,7.49-4.56,14.08-10.22,20.22-16.4,6-6.04,10.82-13,14.81-20.52,1.74-3.28,1.73-3.38,5.42-3.43,2.14-.03,4.33.13,6.43.53,3.31.64,5.65,2.86,7.63,5.45,1.36,1.78,2.69,3.58,3.85,5.49,3.55,5.84,8.42,10.12,14.58,13.08,3.68,1.77,7.2,3.88,9.8,7.14,1.54,1.93,2.97,3.96,4.33,6.02,1.36,2.07,2.65,4.18,3.82,6.36,3.83,7.19,9.46,12.57,16.42,16.68,3.44,2.03,6.77,4.23,10.12,6.41,3.75,2.45,6.64,5.74,8.92,9.56,1.76,2.95,3.5,5.91,5.09,8.95,4.04,7.68,9.73,13.78,17.15,18.28,2.37,1.44,4.69,2.96,7.31,4.62Z"/>
  <path onClick={() => props.onFill(4)} fill={props.fillColors[4]} d="m153.02,184.4c-3.5-3.99-6.81-7.88-10.26-11.65-3.23-3.54-6.62-6.94-9.91-10.42-5.88-6.23-12.33-11.83-19.16-16.99-1.11-.84-1.61-1.77-1.74-3.15-.46-4.92-1.22-9.83-1.57-14.76-.64-8.92,2.25-16.33,9.84-21.53,4.31-2.95,8.98-5.18,13.93-6.82,12.12-4,24.27-7.94,36.37-12.01,6.01-2.02,11.6-4.95,16.7-8.74,6.15-4.58,10.6-10.6,14.01-17.38,2.19-4.34,4.08-8.84,6.1-13.26.26-.58.54-1.15.88-1.88,1.69,1.02,3.23,2.14,4.91,2.95,6.39,3.09,12.84,6.06,19.24,9.13,2.88,1.38,5.75,2.78,8.52,4.36,5.38,3.06,9.78,7.21,12.9,12.57,2.2,3.78,4.15,7.72,6.21,11.58-.29.41-.43.7-.65.91-10.17,9.5-17.91,20.77-24.04,33.2-.74,1.5-1.73,2.48-3.29,3.22-4.25,2.01-7.6,5.24-10.74,8.68-.86.94-1.88,1.78-2.57,2.84-1.03,1.56-2.4,1.78-4.1,1.77-1.99-.02-3.98.21-5.96.43-3.96.45-7.33,2.24-10.11,5.01-2.2,2.19-4.3,4.51-6.21,6.96-1.26,1.62-2.64,2.57-4.67,2.87-2.04.3-4.05.86-6.07,1.35-6.89,1.69-11.78,5.84-14.8,12.21-1.2,2.52-2.3,5.09-3.35,7.68-.5,1.22-1.13,2.19-2.25,2.96-1.57,1.08-3.05,2.33-4.42,3.66-1.31,1.27-2.42,2.74-3.75,4.28Z"/>
  <path onClick={() => props.onFill(5)} fill={props.fillColors[5]} d="m27.31,124.33c2.11,1.5,3.92,2.77,5.72,4.07,7.85,5.64,15.88,11.04,23.5,16.98,9.57,7.47,18.84,15.3,26.9,24.47,9.92,11.29,18.21,23.68,24.84,37.12,4.45,9,8.35,18.28,12.43,27.46,2.82,6.34,5.53,12.74,8.28,19.11.28.65.55,1.31.89,2.11-.51.21-.92.42-1.34.55-5,1.64-10.01,3.24-15,4.91-1.2.4-2.18.36-3.36-.23-3.7-1.86-7.41-3.75-11.27-5.25-6.29-2.45-12.94-2.75-19.59-2.26-8.38.62-16.56,2.4-24.55,5.05-8.3,2.75-16.18,6.34-23.42,11.3-1.23.84-2.51,1.59-4.04,2.56V124.33Z"/>
  <path onClick={() => props.onFill(6)} fill={props.fillColors[6]} d="m373.36,86.4c-6.22-3.81-12.26-7.38-18.16-11.18-6.11-3.93-12.08-8.08-18.1-12.16-3.04-2.06-5.95-4.35-9.11-6.19-5.46-3.19-11.27-4.82-17.61-2.74-3.34,1.1-6.55,2.38-9.57,4.27-6.02,3.78-12.2,7.3-18.26,11-6.76,4.13-13.02,8.95-18.98,14.17-.6.52-1.23,1-2.03,1.66-.43-.75-.82-1.39-1.18-2.05-1.87-3.43-3.59-6.95-5.61-10.29-3.66-6.04-8.94-10.42-15.2-13.52-6.29-3.12-12.73-5.93-19.09-8.89-5.97-2.78-11.62-6.03-16.24-10.85-3.6-3.75-6.61-7.95-9.54-12.11.07-.22.07-.31.12-.37.04-.06.12-.12.18-.12,71.86-.05,143.72-.09,215.58-.12.63,0,1.26.06,2.2.11-.62,2.68-1.17,5.21-1.8,7.73-1.48,5.98-3.33,11.82-6.25,17.28-2.77,5.19-6.67,9.32-11.64,12.46-2.5,1.58-4.94,3.24-7.43,4.83-4.67,2.98-7.89,7.15-10.12,12.15-.74,1.67-1.47,3.34-2.16,4.92Z"/>
  <path onClick={() => props.onFill(7)} fill={props.fillColors[7]} d="m407.14,190.7c0-4.3.11-8.6-.02-12.89-.25-7.85-1.22-15.59-4.26-22.94-1.69-4.1-3.98-7.83-6.79-11.23-1.42-1.72-1.77-3.37-1.57-5.63,1.13-13.01-1.39-25.24-8.4-36.4-2.52-4.01-5.41-7.71-8.8-11.02-2.6-2.54-2.59-2.57-1.09-5.99.71-1.61,1.41-3.22,2.23-4.77,1.88-3.51,4.43-6.43,7.78-8.62,2.07-1.35,4.08-2.82,6.22-4.06,8.52-4.9,14.03-12.23,17.08-21.42,1.8-5.44,3.16-11.04,4.72-16.56.19-.68.37-1.36.58-2.11h37.37c.66,3.37,1.44,6.62,1.91,9.92,1.04,7.34,2.32,14.68,2.79,22.07.61,9.72.12,19.45-1.45,29.12-1.14,7.02-2.5,13.98-4.36,20.84-2.51,9.26-5.74,18.26-9.58,27.04-6.32,14.47-14.04,28.12-23.56,40.75-2.88,3.83-5.62,7.77-8.43,11.65-.6.83-1.24,1.63-1.86,2.45-.17-.06-.34-.12-.51-.18Z"/>
  <path onClick={() => props.onFill(8)} fill={props.fillColors[8]} d="m53.58,26.91h2.9c44.28,0,88.55.01,132.83-.04,1.89,0,3.04.59,4.12,2.12,3.21,4.57,6.47,9.12,10.55,13,.81.77,1.63,1.52,2.65,2.47-.91,2.19-1.79,4.47-2.8,6.7-2.44,5.38-4.9,10.73-8.26,15.64-4.11,6-9.43,10.54-15.74,14.06-6.54,3.65-13.67,5.71-20.7,8.05-8.93,2.98-17.91,5.8-26.82,8.83-4.77,1.62-9.27,3.88-13.41,6.8-.44.31-.88.6-1.59,1.08-.16-2.66-.21-5.02-.45-7.37-.39-3.8-.77-7.61-1.41-11.38-1.26-7.37-5.34-13.04-11.11-17.61-4.97-3.93-10.53-6.92-16.03-10-5.86-3.27-11.71-6.54-16.9-10.86-3.88-3.24-7.31-6.91-10.32-10.96-2.19-2.95-4.31-5.95-6.46-8.93-.27-.37-.5-.77-1.05-1.62Z"/>
  <path onClick={() => props.onFill(9)} fill={props.fillColors[9]} d="m233.67,472.95c-1.1.06-1.79.14-2.49.14-9.11,0-18.23-.03-27.34.04-1.63.01-2.59-.54-3.54-1.84-13.44-18.37-26.68-36.89-40.95-54.64-5.16-6.42-10.79-12.4-16.77-18.08-7.1-6.74-14.86-12.62-23.26-17.6-8.66-5.14-17.75-9.4-27.46-12.27-9.15-2.71-18.43-4.48-27.99-4.8-9.37-.32-18.63.66-27.84,2.22-1.56.26-3.12.48-4.85.39.46-.38.9-.79,1.39-1.13,10.13-7.13,21.33-11.77,33.41-14.37,6.4-1.38,12.85-2.53,19.39-2.86,6.13-.31,12.28-.6,18.41-.43,7.58.2,15.08,1.32,22.5,3.02,12.88,2.96,24.98,7.72,35.98,15.12,6.92,4.66,13.09,10.16,18.9,16.15,9.08,9.37,16.12,20.19,22.98,31.17,4.95,7.93,9.96,15.82,15.01,23.68.94,1.46,1.44,3,1.75,4.65.39,2.04.82,4.07,1.13,6.12.43,2.9,1.74,5.46,3,8.04,2.78,5.65,5.62,11.28,8.63,17.3Z"/>
  <path onClick={() => props.onFill(10)} fill={props.fillColors[10]} d="m110.12,142.47c-3.42-2.23-6.45-4.27-9.55-6.2-10.95-6.83-22.5-12.39-34.83-16.24-5.96-1.86-12.06-2.98-18.34-3.08-5.91-.09-11.58.96-17.11,2.95-.88.32-1.78.61-2.68.91-.52-1.91-.7-89.55-.22-93.7.56-.07,1.17-.2,1.78-.2,6.72-.01,13.43.02,20.15-.04,1.34-.01,2.18.4,2.97,1.52,3,4.24,6,8.48,9.2,12.56,5.97,7.61,13.69,13.08,22.04,17.78,5.63,3.17,11.28,6.35,16.69,9.87,2.84,1.85,5.39,4.26,7.74,6.74,3.51,3.7,5.3,8.37,6,13.34.62,4.33.7,8.74,1.22,13.09.42,3.55-.2,6.46-2.67,9.35-3.26,3.83-4.1,8.8-4.03,13.74.05,4.06.59,8.11.95,12.16.15,1.66.42,3.3.69,5.43Z"/>
  <path onClick={() => props.onFill(11)} fill={props.fillColors[11]} d="m263.14,186.49c.15-3.28.27-6.22.41-9.16.36-7.73.72-15.46,1.1-23.19.26-5.26.57-10.52.84-15.78.08-1.51.17-3.04.1-4.55-.58-13.6,3.73-25.7,10.95-37.02,5.55-8.71,12.23-16.55,18.36-24.82,3.18-4.3,6.54-8.46,9.75-12.74.86-1.15,1.88-1.9,3.23-2.29,1.68-.48,3.34-1.09,5.04-1.5,4.44-1.07,8.48.34,12.59,2.18.4,5.23.82,10.47,1.19,15.71.6,8.43,1.04,16.88,1.78,25.3.83,9.49,2.68,18.78,6.52,27.58.5,1.15.65,2.16.17,3.42-2.28,5.95-4.23,12.04-6.69,17.92-5.08,12.14-14.15,20.42-25.85,26.1-10.62,5.16-21.92,8.24-33.24,11.27-1.92.51-3.85.98-6.24,1.58Z"/>
  <path onClick={() => props.onFill(12)} fill={props.fillColors[12]} d="m330.89,298.95c-2.96,2.51-5.87,4.98-8.9,7.55-.92-.79-1.79-1.48-2.59-2.24-3.89-3.71-8.52-5.86-13.83-6.52-2.4-.3-4.65-.93-6.85-1.94-11.83-5.4-23.68-10.76-35.55-16.05-1.57-.7-2.26-1.63-2.55-3.32-1.21-7.08-2.68-14.11-3.84-21.19-.44-2.66-.47-5.41-.42-8.11.07-4.43,1.93-8.25,4.87-11.43,4.61-4.98,9.37-9.83,14.12-14.68,3.65-3.72,8.05-6.23,13.02-7.78,2.28-.71,4.52-1.57,6.83-2.21,6.44-1.76,12.03-5.02,17.14-9.25,6.4-5.3,13.02-10.29,20.5-14.04,7.66-3.84,15.73-6.07,24.25-6.84,5.21-.47,10.36-.24,15.5.55.37.06.74.17,1.69.39-1.33.7-2.17,1.23-3.09,1.6-4.51,1.81-9.01,3.65-13.58,5.34-5.35,1.97-10.5,4.25-14.76,8.18-3.38,3.11-6.18,6.69-8.74,10.45-5.21,7.67-10.01,15.59-13.77,24.08-3.78,8.53-5.52,17.48-5.12,26.8.43,10.05,3.06,19.52,8.1,28.26,1.99,3.46,4.16,6.81,6.24,10.22.41.68.82,1.35,1.32,2.18Z"/>
  <path onClick={() => props.onFill(13)} fill={props.fillColors[13]} d="m249.13,25.01H27.54c-.47-1.72-.62-19.89-.2-23.6,1.75-.47,218.91-.59,221.79-.1v23.7Z"/>
  <path onClick={() => props.onFill(14)} fill={props.fillColors[14]} d="m473.21,1.29v23.71h-221.59c-.51-1.65-.7-18.91-.25-23.56,1.67-.5,218.46-.68,221.84-.15Z"/>
  <path onClick={() => props.onFill(15)} fill={props.fillColors[15]} d="m1.37,27.04h23.6c.51,1.71.64,219.11.11,221.81H1.37V27.04Z"/>
  <path onClick={() => props.onFill(16)} fill={props.fillColors[16]} d="m499.08,248.85h-23.73V27.37c1.72-.56,20.74-.7,23.73-.17v221.65Z"/>
  <path onClick={() => props.onFill(17)} fill={props.fillColors[17]} d="m25.1,472.94H1.4v-221.81h23.55c.52,1.61.69,218.68.15,221.81Z"/>
  <path onClick={() => props.onFill(18)} fill={props.fillColors[18]} d="m499.1,472.88h-23.66v-221.48c1.76-.51,21.06-.6,23.66-.09v221.57Z"/>
  <path onClick={() => props.onFill(19)} fill={props.fillColors[19]} d="m407.5,194.41c2.3-3.2,4.52-6.3,6.75-9.4,3.93-5.44,8.04-10.76,11.74-16.35,4.54-6.85,8.59-14.01,12.3-21.37,5.48-10.87,9.96-22.13,13.46-33.76,2.62-8.72,4.47-17.63,5.76-26.65,1.37-9.6,2.01-19.26,1.15-28.91-.73-8.17-2.16-16.28-3.3-24.41-.18-1.26-.45-2.5-.71-3.88.31.04.56,0,.67.11,7.18,6.86,12.8,14.69,15.05,24.55,1.1,4.82,1.96,9.7,2.89,14.56.15.77.06,1.6.06,2.39,0,26.23.05,52.46-.06,78.69-.01,3.15-.76,6.32-1.41,9.44-1.57,7.5-3.09,15.01-4.98,22.44-2.69,10.63-6.76,20.78-11.66,30.58-2.29,4.57-4.66,9.09-7.01,13.63-.36.7-.78,1.38-1.28,2.26-1.14-.41-2.28-.73-3.34-1.22-3.04-1.4-6.12-2.75-9.05-4.35-3.79-2.07-6.71-5.08-8.92-8.82-1.78-3.02-3.57-6.06-5.66-8.86-2.7-3.63-6.55-6.05-10.05-8.83-.74-.59-1.5-1.15-2.4-1.83Z"/>
  <path onClick={() => props.onFill(20)} fill={props.fillColors[20]} d="m27.29,498.69v-23.47h221.91c.16,7.78.03,15.56.08,23.28-1.89.53-218.69.69-221.99.19Z"/>
  <path onClick={() => props.onFill(21)} fill={props.fillColors[21]} d="m251.35,498.67v-23.46h221.6c.47,1.69.61,19.92.18,23.37-1.77.46-218.92.57-221.78.1Z"/>
  <path onClick={() => props.onFill(22)} fill={props.fillColors[22]} d="m152.09,186.24c-.5,1.14-.95,2.24-1.45,3.31-2.37,5.1-3.46,10.45-2.91,16.06.19,2-.61,3.3-2.05,4.51-4.06,3.41-6.4,7.76-6.51,13.1-.06,3.1.11,6.24.52,9.31.27,2.04,0,3.66-1.21,5.36-2.04,2.88-3.86,5.97-3.9,9.62-.03,2.27.45,4.54.74,7.12-.89.2-2.02.46-3.34.76-.38-.75-.76-1.43-1.07-2.13-5.59-12.88-11.13-25.79-16.78-38.65-4.28-9.75-9.47-19.02-15.46-27.8-3.68-5.4-7.5-10.76-11.71-15.75-5.5-6.54-11.72-12.4-18.17-18.05-12.06-10.55-25.49-19.22-38.3-28.75-.51-.38-1.03-.76-1.52-1.16-.1-.08-.12-.27-.23-.55,3.74-1.62,7.62-2.71,11.64-3.32,8.66-1.32,17.05.13,25.25,2.72,14.03,4.42,27,11.08,39.19,19.26,8.11,5.44,15.74,11.52,22.7,18.36,7.46,7.34,14.63,14.97,21.39,22.97,1.03,1.21,2.07,2.41,3.18,3.71Z"/>
  <path onClick={() => props.onFill(23)} fill={props.fillColors[23]} d="m215.78,245.72c.15-4.02.24-8.01.45-11.99.26-5.18.59-10.36.92-15.54.43-6.69.78-13.38,1.37-20.06.9-10.17,1.97-20.32,2.98-30.48.16-1.61.71-3.01,1.65-4.4,4.39-6.51,9.28-12.64,14.79-18.19,4.72-4.75,9.86-9.09,14.94-13.45,3.49-3,7.22-5.72,11.25-8.89,0,1.32.03,2.15,0,2.97-.14,3.59-.3,7.18-.46,10.76-.27,6.06-.55,12.12-.83,18.18-.2,4.39-.39,8.77-.61,13.16-.27,5.34-.38,10.7-.87,16.02-1.5,16.28-3.13,32.55-4.74,48.82-.39,3.96-.87,7.9-1.77,11.81-1.03,4.49-.39,9.06.42,13.55,1.09,6.04,2.29,12.07,3.44,18.1.07.38.1.76.18,1.42-.61-.17-1.03-.23-1.41-.4-3.49-1.57-7-3.12-10.45-4.77-.9-.43-1.88-1.03-2.43-1.82-7.38-10.64-17.81-17.71-28.51-24.47-.06-.04-.1-.12-.3-.34Z"/>
  <path onClick={() => props.onFill(24)} fill={props.fillColors[24]} d="m172.95,371.45c-2.34-1.77-4.64-3.59-7.02-5.31-8.48-6.11-17.71-10.73-27.64-14-5.77-1.9-11.63-3.45-17.6-4.49-9.38-1.62-18.84-2.37-28.36-1.84-3.58.2-7.15.54-10.73.8-.78.06-1.57,0-2.46,0-1.73-7.48-3.35-14.87-3.02-22.51.22-5.2,1.86-10,4.59-14.43.88-1.43,1.86-2.8,2.67-4.26.62-1.13,1.4-1.54,2.69-1.47,4.73.23,9.38-.4,14.01-1.4,5.19-1.12,9.75-3.45,13.86-6.72,1.19-.94,2.42-1.84,3.5-2.9,1.1-1.08,2.28-1.25,3.71-.96,6.6,1.31,13.27,1.84,19.99,1.77.55,0,1.11.07,1.85.12,1.51,4.82,4.64,8.79,7.12,13.12,2.18,3.79,3.53,7.92,4.21,12.33,2.16,13.88,6.56,27.08,12.86,39.64,2.04,4.06,4.05,8.13,6.07,12.2l-.3.33Z"/>
  <path onClick={() => props.onFill(25)} fill={props.fillColors[25]} d="m290.94,472.9c.15-.56.22-.93.34-1.29,3.01-9.01,6.43-17.88,10.5-26.46,3.45-7.28,7.49-14.28,11.27-21.39,2.25-4.23,4.57-8.42,6.74-12.69.7-1.37,1.68-2.2,2.97-2.94,3.52-2.02,7.16-3.92,10.4-6.34,4.96-3.68,8.37-8.76,11.17-14.19,2.23-4.32,4.17-8.79,6.24-13.19.27-.57.56-1.12.85-1.7,6.44-.49,12.65-.05,18.53,2.75,3.24,1.54,6.57,2.91,9.83,4.4,3.29,1.51,6.66,2.66,10.3,2.88.54.03,1.07.24,1.83.42-.59.93-1.07,1.73-1.6,2.5-9.04,13.08-18.59,25.78-28.64,38.09-3.02,3.71-6.4,7.13-9.66,10.64-4.46,4.8-8.88,9.63-13.46,14.31-3.85,3.94-7.89,7.69-11.88,11.49-4.05,3.86-8.14,7.66-12.19,11.53-.84.8-1.62,1.4-2.89,1.39-6.55-.06-13.11-.03-19.66-.05-.23,0-.47-.08-1.01-.17Z"/>
  <path onClick={() => props.onFill(26)} fill={props.fillColors[26]} d="m332.53,297.3c-.26-.13-.46-.16-.53-.28-4.64-7.09-9.29-14.16-11.92-22.32-1.63-5.04-2.58-10.19-2.89-15.49-.23-4.01-.02-7.97.56-11.92.13-.9.52-1.86,1.07-2.59,4.15-5.57,8.16-11.26,12.63-16.57,5.15-6.13,11.27-11.31,17.82-15.93,6.08-4.29,12.43-8.12,19.33-11,5.71-2.38,11.58-4.16,17.74-4.68,5.96-.51,11.94-.7,17.92-.96.74-.03,1.66.12,2.23.54,4.13,3.01,8.4,5.88,11.73,9.82,1.58,1.86,2.82,4,4.22,6.01-.11.14-.22.28-.33.41-.81-.23-1.64-.42-2.44-.69-5.08-1.68-10.21-3.09-15.55-3.76-8.55-1.07-16.09,1.07-22.83,6.42-5.95,4.73-10.37,10.67-13.52,17.47-3.01,6.51-5.49,13.26-8.21,19.9-2.52,6.13-4.85,12.34-7.63,18.34-4.49,9.71-10.42,18.49-18.03,26.07-.44.44-.93.82-1.36,1.19Z"/>
  <path onClick={() => props.onFill(27)} fill={props.fillColors[27]} d="m443.78,233.08c-.45.85-.62,1.26-.87,1.62-3.61,5.07-7.08,10.25-10.9,15.17-2.24,2.89-5,5.38-7.58,8-5.99,6.1-11.92,12.28-18.06,18.24-4.22,4.1-7.58,8.84-10.91,13.63-1.37,1.96-2.62,4-4.06,6.2-3.09-1.4-6.1-2.88-8.58-5.1-2-1.79-3.74-3.92-5.4-6.05-2.11-2.71-3.94-5.62-6.03-8.35-3.19-4.18-7.54-5.99-12.75-5.89-1.49.03-2.98,0-4.64,0,.17-.62.23-.93.34-1.21,3.99-10.01,8-20,11.94-30.02.49-1.25,1.1-2.26,2.24-2.97,2.64-1.65,5.15-3.53,7.9-4.96,7.18-3.74,14.9-4.3,22.61-2.5,6.51,1.52,12.85,3.79,19.24,5.8,3.59,1.13,7.22,2.06,10.98,2.33,3.99.29,7.77-.57,11.35-2.37.9-.45,1.8-.89,3.17-1.56Z"/>
  <path onClick={() => props.onFill(28)} fill={props.fillColors[28]} d="m82.09,303.88c-.86,1.39-1.64,2.66-2.43,3.92-2.8,4.45-4.72,9.22-5.28,14.49-.45,4.25-.1,8.46.54,12.65.51,3.31,1.25,6.58,1.88,9.87.12.62.22,1.25.37,2.14-1.92.37-3.76.76-5.61,1.06-15.04,2.44-28.94,7.71-41.35,16.68-.83.6-1.69,1.15-2.58,1.75-.58-1.79-.81-85.52-.27-90.47.91.49,1.73.89,2.52,1.36,8.78,5.23,17.54,10.5,26.33,15.71,5.7,3.38,11.46,6.67,17.84,8.65,2.51.78,5.07,1.38,8.04,2.18Z"/>
  <path onClick={() => props.onFill(29)} fill={props.fillColors[29]} d="m271.9,397.16c-1.37-2.21-3.11-3.76-5.41-4.66-.42-.16-.76-.58-1.09-.93-4.52-4.84-9.21-9.52-12.98-15.03-4.9-7.17-7.96-15.06-9.55-23.56-.15-.83-.15-1.52.4-2.22,5.04-6.49,9.74-13.25,15.64-19.05,5.52-5.42,11.79-9.77,18.6-13.36,7.51-3.96,15.32-7.14,23.68-8.86,5.45-1.12,10.95-1.08,16.46-.87.11,0,.22.17.5.41-.61.41-1.16.83-1.75,1.18-9.45,5.51-15.26,13.86-18.59,24.08-3.2,9.83-5.29,19.92-5.22,30.27.03,3.55-1.09,6.18-2.84,9.02-4.93,7.97-10.68,15.3-16.8,22.37-.3.35-.61.7-1.05,1.2Z"/>
  <path onClick={() => props.onFill(30)} fill={props.fillColors[30]} d="m283.42,386.67c.59,1.56,1.14,2.95,1.64,4.35,3.28,9.15,5.24,18.55,5.49,28.3.08,3.03.26,6.06.48,9.08.1,1.38-.38,2.42-1.21,3.46-2.85,3.55-5.75,7.06-8.42,10.74-4.74,6.52-9.34,13.14-13.98,19.72-2.21,3.13-4.45,6.24-6.57,9.43-.66,1-1.4,1.35-2.55,1.35-6.87-.03-13.74-.05-20.62,0-1.29.01-2.03-.38-2.61-1.57-3.11-6.38-6.24-12.76-9.48-19.08-.88-1.72-.87-3.24-.23-5.01,1.38-3.77,2.8-7.52,5.67-10.43,1.84-1.87,3.83-3.62,5.95-5.16,3.68-2.67,7.45-5.23,11.3-7.65,5.07-3.19,9.27-7.31,13.11-11.83,7.13-8.39,14.21-16.83,21.31-25.24.1-.12.26-.18.73-.49Z"/>
  <path onClick={() => props.onFill(31)} fill={props.fillColors[31]} d="m214.37,309.95c5-2.37,8.47-6.2,12.44-9.37,4.92-3.93,10.73-5.91,16.8-7.13,7.83-1.57,15.78-2.27,23.75-2.6,1.49-.06,3.03.14,4.48.51,9.04,2.28,18.06,4.68,27.11,6.95,2.93.74,5.94,1.18,8.91,1.78,3.87.77,7.12,2.72,10.03,5.31.33.29.58.69,1.07,1.28-.86,0-1.37.03-1.88,0-6.09-.4-12.13-.05-18.06,1.4-14.01,3.45-26.63,9.74-37.75,18.93-4.63,3.82-8.56,8.37-12.23,13.11-2.14,2.77-4.24,5.57-6.39,8.39-3.25-7.45-6.68-14.64-11.08-21.34-4.49-6.83-10.83-11.83-17.2-17.21Z"/>
  <path onClick={() => props.onFill(32)} fill={props.fillColors[32]} d="m433.51,337.13c2.43-5.13,4.78-10.09,7.13-15.06,3-6.35,6.09-12.65,8.99-19.05,4.45-9.82,11.09-17.84,19.6-24.39,1.24-.95,2.44-1.96,3.94-3.18v103.76c-.55-.26-1.05-.44-1.49-.71-2.84-1.78-5.73-3.5-8.5-5.38-5.57-3.79-9.98-8.67-13.25-14.58-1.89-3.42-3.84-6.82-5.81-10.19-2.33-3.97-5.26-7.41-9.09-10.04-.45-.31-.87-.68-1.52-1.2Z"/>
  <path onClick={() => props.onFill(33)} fill={props.fillColors[33]} d="m472.64,273.35c-1.94,1.63-3.59,3.13-5.36,4.49-9.07,6.96-15.56,15.87-20.25,26.25-4.46,9.88-9.24,19.62-13.88,29.42-.37.79-.77,1.56-1.28,2.58-2.93-1.8-5.74-3.56-8.59-5.26-6.32-3.77-11.23-8.88-14.72-15.36-1.1-2.04-2.37-3.98-3.57-5.96-2.37-3.91-5.01-7.6-8.89-10.61,1.1-1.02,2.09-1.99,3.14-2.9,3.38-2.92,6.73-5.87,10.18-8.71,5.92-4.87,12.64-8.35,19.85-10.83,7.01-2.41,14.07-4.69,21.15-6.86,3.44-1.05,6.99-1.52,10.64-1.23,4.32.34,7.89,2.26,11.59,4.98Z"/>
  <path onClick={() => props.onFill(34)} fill={props.fillColors[34]} d="m319.23,193.94c.17-1.04.22-1.58.36-2.1,1.4-5.22,1.23-10.43-.13-15.61-.71-2.7-1.52-5.36-2.32-8.04-.24-.8-.39-1.45.39-2.14,8.58-7.54,13.2-17.45,16.66-28.04.74-2.27,1.59-4.51,2.4-6.75.04-.12.19-.19.44-.42.69,1.21,1.33,2.38,2.02,3.53,3.09,5.18,6.79,9.84,11.68,13.47,5.67,4.21,12.08,5.95,19.06,5.98,2.23,0,4.46.21,6.67.5,3.59.47,6.19,2.31,7.61,5.76,1.32,3.22,1.86,6.59,2.27,10,.48,3.96.84,7.93,1.24,11.89.04.38-.06.77-.11,1.4-24.55-8.64-47.14-4.84-68.25,10.59Z"/>
  <path onClick={() => props.onFill(35)} fill={props.fillColors[35]} d="m258.26,235.5c1.61-15.66,3.23-31.33,4.84-47.02,2.18-.57,4.18-1.1,6.18-1.62,9.04-2.33,18.01-4.87,26.71-8.26,6.41-2.5,12.53-5.58,18.07-9.72.25-.18.53-.32.97-.57.25.55.51,1.01.67,1.5,1.45,4.56,2.81,9.13,2.93,13.99.09,3.9-.77,7.6-1.9,11.27-.2.64-.69,1.27-1.21,1.7-2.08,1.74-4.22,3.41-6.34,5.1-4.23,3.39-8.98,5.76-14.18,7.26-2.3.67-4.54,1.51-6.84,2.18-6.63,1.95-11.99,5.82-16.67,10.79-4.25,4.52-8.56,8.98-12.84,13.47-.13-.03-.26-.06-.39-.09Z"/>
  <path onClick={() => props.onFill(36)} fill={props.fillColors[36]} d="m262.46,472.7c2.42-3.49,4.8-6.87,7.14-10.29,7.13-10.42,14.59-20.58,22.43-30.48,4.78-6.03,8.19-12.92,11.45-19.88,5.74-12.25,9.89-25.05,13.45-38.06,1.26-4.62,2.56-9.22,3.86-13.83.13-.45.32-.89.58-1.61.84.48,1.6.85,2.29,1.32,2.5,1.71,4.96,3.5,7.49,5.17,1.09.72,1.43,1.51,1.12,2.8-3.17,12.95-6.4,25.9-12.06,38.03-3.13,6.72-6.81,13.19-10.28,19.75-3.21,6.07-6.74,11.98-9.63,18.2-3.06,6.57-5.56,13.4-8.26,20.14-1,2.52-1.87,5.09-2.75,7.65-.32.92-.71,1.5-1.83,1.49-8.15-.06-16.3-.07-24.45-.1-.07,0-.14-.07-.55-.3Z"/>
  <path onClick={() => props.onFill(37)} fill={props.fillColors[37]} d="m439.04,243.69c8.9-11.94,15.39-24.91,21.43-38.13,6.05-13.25,9.7-27.24,12.74-41.43v107.07c-3.37-1.91-6.44-4.13-10.16-4.41-3.55-.26-7.13-.06-11.03-.06-.2-.77-.45-1.82-.74-2.87-.61-2.23-1.19-4.47-1.89-6.67-1.69-5.35-4.71-9.74-9.44-12.86-.2-.13-.39-.28-.91-.65Z"/>
  <path onClick={() => props.onFill(38)} fill={props.fillColors[38]} d="m378.42,133.61c-6.62-4.82-13.46-9.12-18.68-15.44-3.55-4.3-6.09-9.17-8.2-14.27-2.2-5.3-4.3-10.65-6.32-16.02-2.66-7.06-6.29-13.6-10.34-19.93-1.37-2.15-2.8-4.26-4-6.69.56.31,1.15.57,1.68.93,5.73,3.93,11.4,7.95,17.19,11.77,3.86,2.55,7.89,4.83,11.85,7.22,5.55,3.35,10.9,6.95,15.4,11.7,6.31,6.67,10.92,14.3,13.59,23.1.26.85.52,1.65-.09,2.5-3.59,4.99-7.03,10.09-11.33,14.52-.16.17-.37.3-.75.59Z"/>
  <path onClick={() => props.onFill(39)} fill={props.fillColors[39]} d="m183.99,256.21c-2.37,2.22-4.49,4.26-5.02,7.17-.51,2.73-.66,5.52-1.01,8.62-2.12,1.15-4.79,1.79-7.42,2.12-6.72.83-13.47,1.42-20.22,1.97-8.26.68-16.56,1-24.79,1.91-9.41,1.04-18.61.28-27.75-1.99-5.1-1.27-10.21-2.51-15.33-4.17.56-.18,1.11-.42,1.68-.54,12.12-2.52,23.88-6.28,35.53-10.42,8.47-3.01,17.15-4.88,26.13-5.42,5.09-.31,10.15-1,15.24-1.31,5.91-.37,11.8.04,17.64,1.07,1.62.28,3.23.6,5.32.99Z"/>
  <path onClick={() => props.onFill(40)} fill={props.fillColors[40]} d="m328.16,61.34c6.91,9.69,12.68,19.65,16.68,30.62,2.1,5.76,4.54,11.41,7.01,17.02,3.52,8.02,9.2,14.3,16.21,19.45,2.95,2.16,5.91,4.3,9,6.56-.59.64-1.03,1.2-1.54,1.65-5.69,5.02-12.47,8.27-19.16,11.64-.91.46-1.52,0-2.2-.41-3.66-2.22-6.74-5.09-9.37-8.45-7-8.94-10.81-19.25-12.96-30.27-1.35-6.91-1.72-13.91-2.16-20.92-.45-7.16-1.09-14.31-1.64-21.46-.11-1.43-.19-2.87-.23-4.3,0-.31.19-.63.36-1.13Z"/>
  <path onClick={() => props.onFill(41)} fill={props.fillColors[41]} d="m229.01,379.79c.73,1.05,1.21,1.76,1.71,2.46,4.89,6.96,11.25,11.7,19.8,13.11,3.59.59,7.16.21,10.72-.39.63-.11,1.25-.23,1.88-.33,3.88-.58,5.54.37,7.3,4.32-1.59,1.85-3.23,3.79-4.91,5.7-3.54,4.01-7.02,8.08-10.69,11.97-1.63,1.73-3.57,3.2-5.52,4.57-4.18,2.94-8.45,5.75-12.7,8.59-5.58,3.74-9.99,8.44-12.24,14.9-.08.22-.21.42-.34.61-.03.05-.13.04-.42.12-.85-3.69-1.83-7.37-1.82-11.16,0-4.38.18-8.77.5-13.14.4-5.42.42-10.83.2-16.27-.28-6.94.75-13.7,3.93-19.99.79-1.56,1.59-3.12,2.59-5.08Z"/>
  <path onClick={() => props.onFill(42)} fill={props.fillColors[42]} d="m262.5,121.65c-2.08,1.61-3.76,2.9-5.44,4.2-6.93,5.4-13.76,10.94-19.87,17.27-9.41,9.76-17.53,20.48-23.58,32.69-1.77,3.57-3.62,7.11-5.47,10.64-.36.69-.81,1.41-1.39,1.9-4.5,3.82-9.05,7.59-13.59,11.37-.23.19-.51.32-1.22.75.29-2.2.51-3.96.74-5.72.86-6.49,2.02-12.92,4.01-19.18,2.76-8.68,7.24-16.48,12.37-23.95,2.67-3.88,5.2-7.88,8.16-11.53,3.11-3.83,6.48-7.48,10.05-10.89,4.68-4.47,10.61-6.46,16.93-7.03,5.4-.48,10.83-.51,16.25-.72.51-.02,1.04.1,2.05.2Z"/>
  <path onClick={() => props.onFill(43)} fill={props.fillColors[43]} d="m159.54,277.35c9.69-.43,18.85-2.61,27.69-7.21-.19,1.63-.3,2.8-.47,3.96-.47,3.09.29,5.85,2.22,8.3.54.69,1.07,1.41,1.71,2,3.72,3.47,6.52,7.65,9.03,12.01,3.28,5.7,7.81,10.25,12.77,14.44,3.41,2.88,6.89,5.7,10.17,8.72,5.16,4.76,9.04,10.5,12.17,16.76.87,1.74,1.72,3.5,2.58,5.24-.14.1-.28.21-.42.31-.71-.58-1.41-1.18-2.13-1.75-3.4-2.65-6.74-5.37-10.21-7.91-7.97-5.84-17.11-9.55-25.86-13.96-5.7-2.87-11.45-5.74-16.19-10.09-3.23-2.96-6.35-6.09-9.13-9.46-5.24-6.35-9.49-13.41-13.75-20.44-.07-.12-.05-.3-.15-.95Z"/>
  <path onClick={() => props.onFill(44)} fill={props.fillColors[44]} d="m157.29,252.4c3.13-3.18,3.84-7.2,4.56-11.2.6-3.34,1.68-6.49,3.49-9.36,2.84-4.52,5.59-9.12,8.65-13.49,2.49-3.55,5.8-6.41,9.36-8.86,7.58-5.22,14.45-11.31,21.55-17.12.61-.5,1.24-.98,2.22-1.76-4.68,22.37-10.61,43.85-21.64,63.95-9.12-1.93-18.51-2.95-28.19-2.15Z"/>
  <path onClick={() => props.onFill(45)} fill={props.fillColors[45]} d="m206.98,363.23c.45.89.87,1.93,1.47,2.86,1.3,2,2.48,4.13,4.11,5.84,1.28,1.35,1.82,2.62,1.69,4.41-.7,9.69,1,19.05,4.11,28.2.13.38.18.79.34,1.14,2.5,5.46,2.17,11.17,1.6,16.93-.3,3.01-.39,6.05-.58,9.07-.03.53-.1,1.05-.19,2.07-.66-.91-1.09-1.43-1.45-2-6.84-10.78-13.67-21.57-20.49-32.36-.38-.6-.81-1.21-1.04-1.88-2.43-7.33-5.25-14.61-5.33-22.47-.04-4.13.37-8.27.67-12.4.12-1.74.47-3.46.72-5.27,2.37,1.32,4.53,2.8,6.9,3.77,2.34.95,4.89,1.39,7.48,2.08Z"/>
  <path onClick={() => props.onFill(46)} fill={props.fillColors[46]} d="m220.07,331.41c1.55,1.02,2.73,1.67,3.77,2.5,5.13,4.06,10.22,8.18,15.35,12.24.86.68,1.01,1.58,1.13,2.53.6,4.76,1.63,9.41,3.13,13.98,2.82,8.57,7.52,15.99,13.53,22.64,1.82,2.01,3.7,3.98,5.54,5.98.31.34.57.71.99,1.24-9.98,2.58-18.83,1.23-26.45-5.78-5-4.6-8.19-10.4-10.63-16.6-2.63-6.69-4.67-13.56-5.69-20.72-.83-5.8-.73-11.6-.67-18.01Z"/>
  <path onClick={() => props.onFill(47)} fill={props.fillColors[47]} d="m264.69,119.63c-9.19.19-18.33-.58-27.68,2.06.42-.99.68-1.68,1.01-2.34,7.04-14.15,16.73-26.14,28.9-36.25,8.21-6.82,17.1-12.55,26.28-17.92,2.41-1.41,4.8-2.85,7.47-4.1-.34.55-.64,1.13-1.04,1.65-6.46,8.43-13.04,16.77-19.36,25.3-6.15,8.31-11.43,17.12-14.4,27.14-.43,1.44-.78,2.9-1.19,4.45Z"/>
  <path onClick={() => props.onFill(48)} fill={props.fillColors[48]} d="m445.09,229.8c-4.2,2.88-8.32,5.12-13.32,5.29-4.21.14-8.25-.69-12.22-1.98-5.39-1.75-10.73-3.66-16.18-5.2-7.49-2.13-15.07-2.52-22.67-.29-3.49,1.02-6.63,2.7-9.65,4.67-.52.34-1.04.66-1.59,1.01-.07-.23-.2-.41-.15-.51,3.96-7.91,9.05-14.84,16.78-19.51,5.4-3.26,11.2-4.32,17.46-3.59,6.8.79,13.2,2.97,19.63,5.1.55.18,1.14.64,1.45,1.14,3.39,5.49,8.61,8.64,14.26,11.23,1.95.89,3.95,1.69,6.2,2.65Z"/>
  <path onClick={() => props.onFill(49)} fill={props.fillColors[49]} d="m155.88,315.97c1.37.73,2.61,1.29,3.73,2.04.6.4,1.2,1.06,1.42,1.74,1.9,5.68,5.59,9.79,10.87,12.5,1.69.87,2.85,2,3.65,3.83,1.75,4,4.9,6.84,8.62,9.06.68.41,1.38.81,2.1,1.16,1.21.59,2.02,1.37,2.27,2.82.21,1.24.64,2.53,1.32,3.57,1.17,1.78.98,3.59.78,5.51-.49,4.68-1.02,9.36-1.3,14.05-.35,5.9.94,11.59,2.6,17.2.22.76.44,1.52.65,2.29.02.06-.05.15-.12.37-.52-.56-1.05-1.04-1.47-1.59-3.72-4.82-7.89-9.22-12.14-13.56-.61-.62-1.2-1.31-1.57-2.08-4.22-8.68-8.56-17.31-12.56-26.1-4.22-9.27-6.68-19.1-8.48-29.1-.18-1.02-.34-2.04-.48-3.07-.02-.14.06-.3.14-.64Z"/>
  <path onClick={() => props.onFill(50)} fill={props.fillColors[50]} d="m164.81,227.81c-.53-5.28-1.07-10.57-1.58-15.85-.91-9.5-.32-18.98.55-28.45.14-1.51.2-3.03.4-4.53.93-6.91,3.94-12.9,8.32-18.22,1.82-2.21,4.3-3.61,6.98-4.48,3.09-1,6.26-1.73,9.92-2.72-.42,1.18-.65,1.88-.92,2.57-2.56,6.53-5.28,13-7.64,19.61-2.89,8.09-4.16,16.53-4.51,25.1-.16,3.83-.29,7.66-.5,11.49-.03.59-.24,1.31-.64,1.72-3.79,3.96-6.64,8.57-9.39,13.26-.12.2-.3.36-.46.54-.18,0-.36-.02-.54-.02Z"/>
  <path onClick={() => props.onFill(51)} fill={props.fillColors[51]} d="m213.87,245.38c-9.47-.27-16.95,4.63-25.42,8.1.43-1.07.65-1.77.97-2.42,3.56-7.24,6.83-14.6,9.48-22.23,2.88-8.3,5.21-16.76,7.2-25.31,1.14-4.9,2.31-9.78,3.51-14.67.26-1.08.6-2.16,1.09-3.15,2.32-4.64,4.7-9.25,7.08-13.86.36-.69.82-1.32,1.45-1.91-2.96,25.02-4.16,50.13-5.36,75.45Z"/>
  <path onClick={() => props.onFill(52)} fill={props.fillColors[52]} d="m319.77,239.99c.69-4.21,2.26-8.03,4.14-11.72,4.25-8.34,9.08-16.33,14.68-23.84,4.78-6.41,10.9-10.89,18.49-13.44,6.66-2.24,13.2-4.78,19.45-8.02.58-.3,1.41-.46,2.03-.31,8.39,2.03,16.15,5.48,23.29,10.34.11.07.16.24.42.66-1.06.04-1.96.09-2.86.11-7.67.18-15.31.65-22.77,2.68-6.28,1.7-12.19,4.28-17.83,7.47-8.53,4.81-16.37,10.58-23.44,17.35-4.97,4.76-9.23,10.16-13.36,15.65-.76,1.01-1.49,2.05-2.24,3.07Z"/>
  <path onClick={() => props.onFill(53)} fill={props.fillColors[53]} d="m108.6,262.52c-25.33,9-51.38,12.51-78.55,10.51.66-.53,1.04-.88,1.47-1.18,7.45-5.18,15.58-8.97,24.2-11.78,8.3-2.71,16.8-4.46,25.5-4.99,8.36-.51,16.34,1.18,23.77,5.15,1.12.6,2.24,1.21,3.35,1.83.12.07.16.26.26.45Z"/>
  <path onClick={() => props.onFill(54)} fill={props.fillColors[54]} d="m215.47,139.19c-2.13,3.05-4.06,5.87-6.05,8.66-5.21,7.29-9.87,14.88-13.13,23.27-2.58,6.66-4.01,13.59-5.07,20.62-.48,3.23-.93,6.47-1.45,9.69-.09.57-.35,1.28-.78,1.6-3.56,2.68-7.17,5.29-10.99,8.08-.35-1.45-.24-2.78-.17-4.06.23-4.22.49-8.44.86-12.64.79-8.89,3.33-17.35,6.54-25.62,1.3-3.34,2.9-6.57,4.08-9.96,2.11-6.07,5.99-10.84,10.55-15.15,1.94-1.83,4.17-3.17,6.76-3.94,2.83-.83,5.42-1.07,8.84-.56Z"/>
  <path onClick={() => props.onFill(55)} fill={props.fillColors[55]} d="m296.6,346.46c6.85,5.43,15.12,7.42,22.58,11.08.02,4.39-11.39,41.17-14.37,46.27-.16-.17-.35-.29-.42-.46-2.27-6.04-4.71-12.03-6.76-18.15-2.52-7.55-3.58-15.38-3.2-23.35.24-5.07.87-10.11,2.16-15.4Z"/>
  <path onClick={() => props.onFill(56)} fill={props.fillColors[56]} d="m438.01,245.46c6.33,3.49,9.91,9.71,12.1,22.23-.76.24-1.56.49-2.36.76-7.7,2.6-15.46,5.03-23.07,7.84-8.26,3.05-15.3,8.17-21.84,13.98-2.44,2.17-4.86,4.38-7.29,6.56-.75.68-1.51,1.28-2.46-.27,2.8-4.02,5.6-8.18,8.57-12.22,1.55-2.11,3.32-4.08,5.16-5.96,8.59-8.8,17.25-17.52,25.83-26.33,1.44-1.48,2.59-3.23,3.89-4.85.49-.61,1.01-1.19,1.49-1.75Z"/>
  <path onClick={() => props.onFill(57)} fill={props.fillColors[57]} d="m215.76,263.82c-.24-.5-.45-.93-.65-1.36-1.81-3.98-6.85-3.83-9.15-1.74-.41.38-.87.73-1.19,1.18-.66.91-1.51,1.21-2.6,1.38-5.66.86-10.82,3.23-16.02,5.44-1.39.59-2.78,1.2-4.19,1.76-.49.2-1.02.29-1.55.43-.92-7.1,1.04-11.68,8.15-15.27,4.97-2.51,10.15-4.61,15.3-6.74,2.73-1.13,5.65-1.82,8.64-1.51,1.38.14,2.93.41,4.05,1.15,6.49,4.35,13.13,8.51,18.75,14.02,2.5,2.45,4.8,5.07,6.84,8.05-1.87.45-3.58.93-5.32,1.23-.55.09-1.21-.27-1.78-.52-3.89-1.65-7.73-3.41-11.65-4.97-2.44-.97-4.98-1.66-7.63-2.53Z"/>
  <path onClick={() => props.onFill(58)} fill={props.fillColors[58]} d="m205.05,323.39c4.66,2.36,8.85,4.48,13.23,6.7-.12,7.9-.38,15.8,1.27,23.57,1.64,7.7,4.06,15.16,7.46,22.52-7.81-.93-13.69-4.05-17.12-11.21-4.9-10.24-7.16-20.9-5.89-32.24.33-2.99.68-5.98,1.06-9.34Z"/>
  <path onClick={() => props.onFill(59)} fill={props.fillColors[59]} d="m405.15,192.71c-2.68-1.6-5.03-3.07-7.44-4.42-1.87-1.05-3.75-2.14-5.75-2.9-1.87-.71-2.45-1.95-2.59-3.77-.26-3.49-.63-6.98-1.01-10.46-.18-1.66-.43-3.32-.71-4.97-.73-4.28-1.83-8.41-5.23-11.71,4.9-2.45,9.36-5.1,11.03-10.91.6.64,1.04,1.05,1.41,1.52,5.26,6.57,8.27,14.07,9.46,22.4,1.19,8.26,1.2,16.53.82,25.22Z"/>
  <path onClick={() => props.onFill(60)} fill={props.fillColors[60]} d="m235.46,273.71c.18,2.31.35,4.36.53,6.61-6.95-.43-13.97-1.37-21.06-.45-7.04.91-13.54,3.55-20.42,5.91-1.5-1.71-3.09-3.38-4.51-5.19-1.66-2.11-1.55-4.63-1.3-7.12.42-4.12.42-4.19,4.28-5.57,2.77-.99,5.62-1.77,8.45-2.63,1.55-.47,2.97-.37,4.13.97.36.41.86.7,1.32,1.02,2.23,1.57,4.52,1.67,6.62-.1,1.4-1.18,2.7-1.24,4.38-.75,5.49,1.6,11.74,4.34,17.58,7.29Z"/>
  <path onClick={() => props.onFill(61)} fill={props.fillColors[61]} d="m162.25,178.06c-.4,5.92-.96,11.41-1.09,16.9-.13,5.66-.06,11.34.31,16.99.38,5.88,1.25,11.73,1.85,17.6.08.83.05,1.84-.34,2.54-1.92,3.49-2.7,7.3-3.44,11.15-.25,1.32-.69,2.61-1.19,4.45-.49-.94-.82-1.41-1-1.93-3.54-9.87-5.95-20.01-7.02-30.46-.46-4.53-1.15-9.06-.77-13.61.74-9.02,3.94-16.89,11.26-22.67.36-.28.77-.51,1.46-.96Z"/>
  <path onClick={() => props.onFill(62)} fill={props.fillColors[62]} d="m30.6,275.4c3.64,0,6.83.07,10.01-.01,7.73-.21,15.46-.49,23.19-.72.86-.03,1.78-.03,2.58.24,9.04,3.1,18.07,6.26,27.09,9.41.27.1.5.31,1.15.72-3.88,1.08-7.35,2.11-10.85,3-7.03,1.79-14.19,2.06-21.39,1.47-3.25-.27-6.49-.78-9.72-1.25-.68-.1-1.38-.41-1.98-.76-6.26-3.68-12.5-7.38-18.74-11.08-.38-.23-.72-.53-1.35-1Z"/>
  <path onClick={() => props.onFill(63)} fill={props.fillColors[63]} d="m25.25,1.23v23.89c-7.94.05-15.79,0-23.82.02V1.5c1.58-.58,19.03-.81,23.82-.26Z"/>
  <path onClick={() => props.onFill(64)} fill={props.fillColors[64]} d="m475.38,24.89V1.19h23.46c.48,1.67.67,18.73.25,23.55-1.64.49-20.43.63-23.71.14Z"/>
  <path onClick={() => props.onFill(65)} fill={props.fillColors[65]} d="m475.39,498.78v-23.55h23.67v23.55h-23.67Z"/>
  <path onClick={() => props.onFill(66)} fill={props.fillColors[66]} d="m25.25,475.24v23.27c-1.73.55-21.3.65-23.86.09v-23.09c1.71-.56,18.61-.77,23.86-.28Z"/>
  <path onClick={() => props.onFill(67)} fill={props.fillColors[67]} d="m143.62,278.22c-.51,4.77-.98,9.1-1.48,13.7-.57,0-1.43.02-2.29,0-8.88-.23-17.62-1.36-26.27-3.51-12.19-3.04-23.9-7.48-35.76-11.51-2.48-.84-4.95-1.74-7.55-2.65,2.38-1.12,6.77-1.56,8.98-1.02,8.14,1.98,16.25,4.08,24.43,5.86,6.1,1.33,12.28,1.73,18.56,1,7.04-.82,14.12-1.25,21.39-1.86Z"/>
  <path onClick={() => props.onFill(68)} fill={props.fillColors[68]} d="m57.23,290.9c2.85.2,5.71.34,8.55.62,5.55.53,11.01-.03,16.39-1.3,4.73-1.12,9.42-2.41,14.1-3.72,1.28-.36,2.42-.38,3.72,0,4.98,1.44,10,2.76,15,4.13.38.1.75.26,1.31.45-1.26,1.8-3.03,2.85-4.6,4.1-4.78,3.78-10.4,5.4-16.31,6.39-11.33,1.9-21.67-.87-31.51-6.31-2.28-1.26-4.55-2.53-6.83-3.8.06-.18.11-.37.17-.55Z"/>
  <path onClick={() => props.onFill(69)} fill={props.fillColors[69]} d="m205.82,360.56c-6.55.17-13.06-4.43-15.08-10.6-1.69-5.13-2.32-10.45-2.1-15.81.22-5.49.79-10.96,1.23-16.44.04-.54.29-1.06.45-1.61.31.1.55.14.75.24,3.96,2.04,7.92,4.09,12.09,6.25-.17,1.79-.34,3.67-.52,5.55-.24,2.54-.49,5.07-.71,7.61-.69,7.93.71,15.56,3.31,23.02.2.58.37,1.17.57,1.79Z"/>
  <path onClick={() => props.onFill(70)} fill={props.fillColors[70]} d="m218.77,304.79c-2.04-5.27-3.65-16.27-3.41-22.96.92-.13,1.91-.36,2.9-.4,6.24-.25,12.46.16,18.63.98,2.83.38,5.59,1.26,8.37,1.97,4.79,1.23,9.57,2.49,14.35,3.75.76.2,1.5.44,2.24,1.01-2.59.29-5.17.6-7.77.87-5.24.54-10.43,1.32-15.52,2.76-6.35,1.79-11.92,4.91-16.65,9.52-.8.78-1.67,1.48-2.52,2.21-.11.1-.28.13-.62.28Z"/>
  <path onClick={() => props.onFill(71)} fill={props.fillColors[71]} d="m357.9,149.61c6.95-3.56,13.68-6.85,19.27-11.95,5.49-5.01,9.8-11.01,14.11-17.02.16.04.32.08.48.12.29,1.68.73,3.35.86,5.04.39,5.01.55,10.03-.38,15.02-.82,4.42-3.05,7.81-7.01,10.02-.63.35-1.26.68-1.88,1.04-1.76,1.02-3.43,1.42-5.62.9-3.07-.72-6.33-.6-9.49-.99-2.83-.35-5.64-.87-8.46-1.37-.52-.09-.99-.42-1.88-.82Z"/>
  <path onClick={() => props.onFill(72)} fill={props.fillColors[72]} d="m292.88,372.84c.1.51.23,1.02.31,1.53,1.21,8.27,4.01,16.07,7.12,23.78,1.01,2.51,2.02,5.04,2.91,7.59.24.69.37,1.66.07,2.27-2.93,6.11-5.64,12.34-9.43,18-.17.26-.39.48-.9,1.09-.18-1.94-.46-3.53-.46-5.12-.01-12.13-2.52-23.76-6.97-34.99-.71-1.79-.49-3.06.57-4.57,2.07-2.93,3.95-6,5.9-9.02.17-.27.25-.59.38-.89.17.11.33.21.5.32Z"/>
  <path onClick={() => props.onFill(73)} fill={props.fillColors[73]} d="m334.32,367.25c4.48,3.32,9.2,5.28,14.76,5.34-.19.69-.28,1.21-.48,1.68-2.95,6.8-5.98,13.54-10.39,19.58-4.02,5.52-9.32,9.27-15.8,12.34,1.63-4.46,3.21-8.54,4.59-12.69,1.43-4.3,2.75-8.65,3.96-13.01,1.19-4.28,2.19-8.61,3.35-13.24Z"/>
  <path onClick={() => props.onFill(74)} fill={props.fillColors[74]} d="m173.41,330.83c-3.4-1.47-6.4-3.76-8.4-7.12-3.71-6.22-4.42-12.88-2.32-19.8,1.05-3.44,2.71-6.61,4.76-9.81.55.66,1,1.2,1.44,1.74,2.26,2.79,4.45,5.62,6.8,8.33.76.87.73,1.69.59,2.64-.63,4.17-1.37,8.33-1.87,12.52-.46,3.77-.67,7.58-1,11.5Z"/>
  <path onClick={() => props.onFill(75)} fill={props.fillColors[75]} d="m187.59,344.85c-6.31-3.22-10.65-7.38-11.87-14.21-.55-3.05-.17-6.05.2-9.04.55-4.5,1.28-8.99,1.96-13.48.04-.29.25-.56.4-.88,3.32,2.56,6.57,5.07,10.03,7.73-.83,9.64-2.7,19.5-.71,29.87Z"/>
  <path onClick={() => props.onFill(76)} fill={props.fillColors[76]} d="m156.9,249.95c-1.33.99-2.41,1.79-3.5,2.61-4.68-2.99-7.97-6.83-9.84-12.04-1.59-4.43-2.32-8.95-2.63-13.57-.42-6.13,1.14-11.57,6.17-15.59.17-.14.39-.22.91-.5,1.01,13.51,3.95,26.41,8.89,39.1Z"/>
  <path onClick={() => props.onFill(77)} fill={props.fillColors[77]} d="m213.35,282.04c.19,8.52,1.46,16.44,3.66,24.29-1.51.82-2.75,1.5-3.98,2.18-7.92-5.29-11.74-13.64-16.95-21.2,5.59-2.11,11.02-4.3,17.28-5.27Z"/>
  <path onClick={() => props.onFill(78)} fill={props.fillColors[78]} d="m160.61,283.4c1.95,3.04,3.75,5.85,5.67,8.85-4.31,7.16-7.88,14.61-6.12,23.6-2.32-.55-3.75-1.9-4.96-3.41-3.04-3.8-4.1-8.26-3.69-13.02.56-6.57,3.95-11.63,9.1-16.02Z"/>
  <path onClick={() => props.onFill(79)} fill={props.fillColors[79]} d="m288.5,293.67c-3.59-.91-7.19-1.81-10.78-2.74-12.33-3.2-24.66-6.4-36.99-9.61-2.47-.64-2.76-.97-3.1-3.48-.6-4.39.39-5.43,4.71-4.76,1.4.22,2.83.56,4.11,1.13,13.3,5.96,26.57,11.98,39.85,17.99.78.35,1.54.77,2.31,1.16-.04.1-.07.2-.11.31Z"/>
  <path onClick={() => props.onFill(80)} fill={props.fillColors[80]} d="m473.2,56.82c-2.36-11.81-8.83-21.27-17.67-29.42,2.05-.59,15.05-.67,17.67-.18v29.6Z"/>
  <path onClick={() => props.onFill(81)} fill={props.fillColors[81]} d="m157.07,277.69c.75,1.21,1.54,2.49,2.44,3.95-.78.8-1.53,1.6-2.31,2.36-4.76,4.66-7.56,10.2-7.59,16.97,0,.23-.1.45-.23,1.03-2.34-3.13-4.02-6.26-4.77-9.88-.91-4.37.22-8.54,1.03-12.76.2-1.02.91-1.24,1.74-1.27,3.17-.15,6.35-.26,9.68-.4Z"/>
  <path onClick={() => props.onFill(82)} fill={props.fillColors[82]} d="m216.28,375.29c3.63,1.08,7.26,2.16,10.93,3.25-1.7,4.1-3.55,8.13-5.04,12.3-1.48,4.15-1.45,8.58-1.57,13.16-3.17-4.89-5.68-22.1-4.32-28.7Z"/>
  <path onClick={() => props.onFill(83)} fill={props.fillColors[83]} d="m150.05,253.16c-4.07.39-8.13.78-12.09,1.15-2.7-4.54-2.11-10.36,2.88-16.19,1.63,5.92,4.33,11.03,9.47,14.57-.09.16-.17.31-.26.47Z"/>
  <path onClick={() => props.onFill(84)} fill={props.fillColors[84]} d="m213.85,264.34c-2.72,3.1-4.96,2.81-8.14-.52,1.06-1.7,2.49-2.78,4.62-2.65,1.95.12,2.88,1.41,3.52,3.16Z"/>



        
                
    </g>
            </svg>
        </div>
    )
}