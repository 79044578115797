import React from 'react';


export default function Tudor(props) {
    
    return (
        <div className={`Put05-container ${props.size}`}> 
 
            <svg  xmlns="http://www.w3.org/2000/svg" id={props.id} width="500" height="500" viewBox="0 0 500 500">
                
            <pattern id="01_yellow" width="100%" height="100%">
                      <image xlinkHref="/01_yellow.jpg"/>
              </pattern>
          <pattern id="02_lightAmber" width="100%" height="100%">
              <image xlinkHref="/02_lightAmber.jpg"/>
          </pattern>
          <pattern id="03_darkAmber" width="100%" height="100%">
              <image xlinkHref="/03_darkAmber.jpg"/>
          </pattern>
          <pattern id="04_cherry" width="100%" height="100%">
              <image xlinkHref="/04_cherry.jpg"/>
          </pattern>
          <pattern id="05_redAmber" width="100%" height="100%">
              <image xlinkHref="/05_redAmber.jpg"/>
          </pattern>    
          <pattern id="06_orange" width="100%" height="100%">
              <image xlinkHref="/06_orange.jpg"/>
          </pattern>
          <pattern id="07_green" width="100%" height="100%">
              <image xlinkHref="/07_green.jpg"/>
          </pattern>
          <pattern id="08_olive" width="100%" height="100%">
              <image xlinkHref="/08_olive.jpg"/>
          </pattern>
          <pattern id="09_purple" width="100%" height="100%">
              <image xlinkHref="/09_purple.jpg"/>
          </pattern>
          <pattern id="10_lightBlue" width="100%" height="100%">
              <image xlinkHref="/10_lightBlue.jpg"/>
          </pattern>  
          <pattern id="11_aquaBlue" width="100%" height="100%">
              <image xlinkHref="/11_aquaBlue.jpg"/>
          </pattern>
          <pattern id="12_oceanBlue" width="100%" height="100%">
              <image xlinkHref="/12_oceanBlue.jpg"/>
          </pattern>
          <pattern id="13_cobaltBlue" width="100%" height="100%">
              <image xlinkHref="/13_cobaltBlue.jpg"/>
          </pattern>
          <pattern id="14_tealBlue" width="100%" height="100%">
              <image xlinkHref="/14_tealBlue.jpg"/>
          </pattern>
          <pattern id="15_clear" width="100%" height="100%">
              <image xlinkHref="/15_clear.jpg"/>
          </pattern>  
          <pattern id="16_granite" width="100%" height="100%">
              <image xlinkHref="/16_granite.jpg"/>
          </pattern>
          <pattern id="17_crackle" width="100%" height="100%">
              <image xlinkHref="/17_crackle.jpg"/>
          </pattern>
          <pattern id="18_iridescent" width="100%" height="100%">
              <image xlinkHref="/18_iridescent.jpg"/>
          </pattern>
          <pattern id="19_taffy" width="100%" height="100%">
              <image xlinkHref="/19_taffy.jpg"/>
          </pattern>
          <pattern id="20_seedy" width="100%" height="100%">
              <image xlinkHref="/20_seedy.jpg"/>
          </pattern> 
          <pattern id="21_frosted" width="100%" height="100%">
              <image xlinkHref="/21_frosted.jpg"/>
          </pattern>                                      
            <g className="hummingbird-image" >

<path id="background" fill="black"  d="m0,500V0h500v500H0Z"/>

  <path onClick={() => props.onFill(1)} fill={props.fillColors[1]} d="M204.8,5.91c12.06,21.09,23.4,42.31,36.18,62.62,5.76,9.15,5.45,15.71-.16,24.7-12.63,20.22-23.95,41.27-36.01,62.35-.92-1.34-1.67-2.31-2.28-3.36-12.97-22.67-25.97-45.32-38.79-68.08-.9-1.6-1.29-4.46-.47-5.92,13.19-23.47,26.6-46.82,39.98-70.18,.38-.67,.91-1.25,1.56-2.14Z"/>
  <path onClick={() => props.onFill(2)} fill={props.fillColors[2]} d="M384.72,155.94c-9.08-15.89-17.74-31.02-26.39-46.16-4.93-8.62-9.94-17.19-14.66-25.92-.81-1.51-1.14-4.14-.37-5.51,13.34-23.73,26.88-47.34,40.38-70.98,.23-.41,.61-.73,1.14-1.34,1.11,1.82,2.2,3.49,3.18,5.22,12.63,22.1,25.27,44.19,37.79,66.36,.8,1.41,1.36,3.86,.68,5.07-13.67,24.27-27.54,48.43-41.75,73.26Z"/>
  <path onClick={() => props.onFill(3)} fill={props.fillColors[3]} d="M69.61,392.21c-1.46-2.54-2.48-4.32-3.5-6.1-12.48-21.84-25.02-43.64-37.34-65.57-.95-1.7-1.23-4.77-.33-6.37,12.92-23.06,26.09-45.98,39.23-68.92,.53-.93,1.27-1.74,2.16-2.94,10.54,18.43,20.88,36.49,31.21,54.55,3.34,5.84,6.8,11.62,9.89,17.59,.71,1.38,.82,3.78,.08,5.09-13.53,24-27.24,47.9-41.39,72.67Z"/>
  <path onClick={() => props.onFill(4)} fill={props.fillColors[4]} d="M69.79,84.78c3.25,5.68,6.33,11.04,9.41,16.41,10.51,18.36,21.08,36.68,31.43,55.13,.88,1.56,1.06,4.4,.22,5.89-13.18,23.47-26.58,46.81-39.94,70.18-.3,.52-.72,.97-1.34,1.81-1-1.57-1.88-2.85-2.65-4.2-12.72-22.26-25.48-44.49-38.04-66.84-.97-1.73-1.4-4.8-.52-6.37,13.17-23.48,26.61-46.82,39.99-70.18,.3-.53,.76-.97,1.44-1.82Z"/>
  <path onClick={() => props.onFill(5)} fill={props.fillColors[5]} d="M339.53,391.87c-14-24.47-27.88-48.67-41.64-72.93-.56-.98-.5-2.86,.07-3.86,13.68-24.12,27.46-48.19,41.47-72.69,.83,1.06,1.55,1.77,2.04,2.62,13.15,22.96,26.32,45.91,39.29,68.98,.88,1.56,1,4.42,.15,5.93-13.05,23.21-26.32,46.29-39.55,69.39-.47,.81-1.09,1.53-1.83,2.56Z"/>
  <path onClick={() => props.onFill(6)} fill={props.fillColors[6]} d="M114.38,312.76c-.98-1.61-1.9-3.05-2.75-4.53-12.64-22.09-25.32-44.16-37.81-66.34-.92-1.64-1.45-4.53-.63-5.98,13.47-24.02,27.19-47.9,41.1-72.26,.88,1.05,1.65,1.75,2.14,2.61,13.13,22.92,26.27,45.83,39.25,68.83,.85,1.5,1.16,4.2,.38,5.59-13.35,23.73-26.91,47.33-40.45,70.96-.21,.37-.66,.61-1.24,1.12Z"/>
  <path onClick={() => props.onFill(7)} fill={props.fillColors[7]} d="M114.66,155.87c-2.86-4.96-5.25-9.08-7.62-13.21-11.14-19.47-22.32-38.92-33.3-58.48-.91-1.62-1.28-4.51-.44-5.99,13.26-23.61,26.77-47.09,40.23-70.59,.22-.38,.6-.66,1.2-1.3,.87,1.31,1.69,2.41,2.37,3.6,12.81,22.38,25.64,44.75,38.3,67.22,.93,1.65,1.48,4.53,.67,5.98-13.48,24.02-27.2,47.91-41.4,72.77Z"/>
  <path onClick={() => props.onFill(8)} fill={props.fillColors[8]} d="M429.75,391.77c-.89-1.36-1.62-2.36-2.23-3.43-12.89-22.52-25.83-45.02-38.55-67.63-.97-1.73-1.36-4.81-.48-6.39,13.07-23.34,26.41-46.52,39.69-69.74,.38-.66,.88-1.24,1.71-2.4,3.65,6.38,7.03,12.28,10.41,18.2,10.09,17.67,20.24,35.3,30.15,53.06,.97,1.73,1.37,4.81,.49,6.39-13.14,23.47-26.54,46.8-39.89,70.16-.29,.51-.68,.96-1.29,1.79Z"/>
  <path onClick={() => props.onFill(9)} fill={props.fillColors[9]} d="M294.46,313.09c-14.05-24.6-27.76-48.53-41.34-72.53-.64-1.14-.73-3.24-.1-4.35,13.64-24.13,27.42-48.18,41.44-72.7,.95,1.35,1.73,2.3,2.33,3.34,12.99,22.68,26.01,45.34,38.84,68.12,.9,1.6,1.19,4.5,.34,6-13.19,23.47-26.62,46.81-40,70.18-.3,.52-.73,.95-1.52,1.94Z"/>
  <path onClick={() => props.onFill(10)} fill={props.fillColors[10]} d="M384.88,163.39c14.08,24.62,27.86,48.66,41.52,72.77,.62,1.1,.62,3.16,0,4.25-13.66,24.13-27.45,48.18-41.52,72.78-.97-1.43-1.77-2.47-2.42-3.61-12.88-22.54-25.74-45.09-38.64-67.62-1.22-2.14-2.11-3.84-.56-6.52,13.5-23.3,26.79-46.73,40.15-70.11,.31-.54,.75-1,1.45-1.93Z"/>
  <path onClick={() => props.onFill(11)} fill={props.fillColors[11]} d="M384.71,471.02c-7.8-13.67-15.27-26.74-22.73-39.82-6.12-10.72-12.32-21.4-18.26-32.21-.83-1.51-1.24-4.16-.48-5.51,13.35-23.74,26.92-47.37,40.45-71.01,.23-.4,.63-.7,1.2-1.31,.95,1.59,1.85,3.03,2.69,4.5,12.74,22.27,25.52,44.51,38.14,66.85,.85,1.51,1.35,4.16,.6,5.49-13.59,24.16-27.4,48.19-41.6,73.02Z"/>
  <path onClick={() => props.onFill(12)} fill={props.fillColors[12]} d="M249.79,234.47c-1.25-1.98-2.14-3.28-2.91-4.63-12.66-22.14-25.24-44.32-38.02-66.39-1.67-2.88-1.69-4.96,0-7.85,13.02-22.47,25.85-45.04,38.75-67.58,.54-.95,1.17-1.84,2.05-3.21,1.04,1.64,1.9,2.88,2.64,4.19,12.66,22.13,25.26,44.3,38.04,66.36,1.75,3.01,1.8,5.19,.03,8.25-13.5,23.31-26.79,46.74-40.58,70.88Z"/>
  <path onClick={() => props.onFill(13)} fill={props.fillColors[13]} d="M429.85,84.49c14.07,24.65,27.75,48.55,41.29,72.54,.7,1.24,.79,3.52,.11,4.72-13.47,23.86-27.09,47.64-40.7,71.42-.14,.24-.45,.38-.98,.8-.86-1.33-1.77-2.58-2.53-3.92-12.64-22.1-25.22-44.24-37.96-66.29-1.71-2.95-1.93-5.17-.13-8.28,12.8-22.03,25.36-44.2,38.01-66.32,.78-1.36,1.64-2.67,2.89-4.67Z"/>
  <path onClick={() => props.onFill(14)} fill={props.fillColors[14]} d="M294.5,470.7c-14.04-24.6-27.73-48.51-41.26-72.5-.69-1.23-.72-3.52-.04-4.74,13.55-23.99,27.25-47.9,41.3-72.47,1.03,1.56,1.84,2.62,2.49,3.77,12.82,22.38,25.6,44.78,38.45,67.13,1.34,2.34,2.17,4.22,.56,7.01-13.32,23.03-26.46,46.16-39.67,69.25-.38,.66-.89,1.25-1.83,2.55Z"/>
  <path onClick={() => props.onFill(15)} fill={props.fillColors[15]} d="M159.53,392.08c-14.06-24.59-27.83-48.63-41.48-72.74-.63-1.12-.76-3.18-.15-4.26,13.57-23.97,27.27-47.87,40.96-71.78,.14-.25,.43-.41,.93-.87,.95,1.54,1.9,2.99,2.75,4.49,12.56,21.98,25.04,44,37.71,65.92,1.72,2.97,1.92,5.17,.14,8.25-12.94,22.31-25.67,44.74-38.47,67.13-.63,1.1-1.33,2.15-2.4,3.85Z"/>
  <path onClick={() => props.onFill(16)} fill={props.fillColors[16]} d="M159.51,84.56c.97,1.53,1.77,2.68,2.46,3.89,12.8,22.37,25.54,44.78,38.44,67.1,1.62,2.81,1.76,4.88,.06,7.81-12.85,22.18-25.48,44.48-38.19,66.73-.78,1.36-1.65,2.67-2.75,4.45-10.35-18.14-20.43-35.77-30.5-53.41-3.57-6.26-7.23-12.47-10.64-18.82-.7-1.31-1.17-3.55-.53-4.67,13.69-24.27,27.56-48.44,41.65-73.07Z"/>
  <path onClick={() => props.onFill(17)} fill={props.fillColors[17]} d="M204.76,320.93c14.11,24.68,27.9,48.72,41.56,72.85,.62,1.09,.54,3.16-.09,4.27-13.66,24.11-27.44,48.14-41.4,72.56-.79-1.07-1.47-1.84-1.97-2.71-13.13-22.95-26.29-45.87-39.25-68.91-.88-1.57-1.08-4.42-.24-5.91,13.12-23.31,26.46-46.5,39.76-69.71,.39-.68,.88-1.31,1.63-2.43Z"/>
  <path onClick={() => props.onFill(18)} fill={props.fillColors[18]} d="M339.77,84.86c.8,1.27,1.6,2.42,2.29,3.64,12.81,22.37,25.57,44.78,38.48,67.1,1.64,2.83,1.67,4.92,0,7.8-12.85,22.17-25.5,44.45-38.22,66.69-.78,1.36-1.61,2.69-2.68,4.48-11.67-20.41-23.04-40.27-34.37-60.16-2.84-4.99-7.64-10.05-7.69-15.12-.05-4.85,4.88-9.77,7.67-14.63,11.22-19.61,22.45-39.21,33.68-58.81,.16-.27,.41-.49,.84-.99Z"/>
  <path onClick={() => props.onFill(19)} fill={props.fillColors[19]} d="M204.62,313.45c-11.33-19.81-22.22-38.85-33.11-57.88-2.78-4.87-5.69-9.67-8.26-14.65-.67-1.3-.94-3.53-.28-4.69,13.57-23.99,27.3-47.88,41.01-71.79,.15-.26,.48-.41,.94-.77,10.04,17.55,20.07,35.06,30.09,52.57,3.66,6.4,7.38,12.76,10.87,19.25,.7,1.31,1.18,3.53,.55,4.64-13.7,24.28-27.58,48.46-41.8,73.33Z"/>
  <path onClick={() => props.onFill(20)} fill={props.fillColors[20]} d="M114.41,470.67c-14.05-24.61-27.74-48.53-41.3-72.52-.65-1.15-.8-3.25-.18-4.34,13.63-24.11,27.4-48.14,41.4-72.61,.9,1.14,1.65,1.89,2.17,2.78,13.07,22.81,26.16,45.6,39.06,68.51,.9,1.6,1.2,4.49,.36,5.98-13.18,23.46-26.59,46.8-39.96,70.16-.29,.51-.71,.95-1.55,2.05Z"/>
  <path onClick={() => props.onFill(21)} fill={props.fillColors[21]} d="M294.47,155.56c-14.04-24.57-27.73-48.47-41.3-72.45-.65-1.15-.81-3.25-.19-4.33,13.63-24.12,27.4-48.16,41.44-72.74,.97,1.34,1.77,2.25,2.35,3.27,12.98,22.67,25.98,45.33,38.8,68.1,.91,1.61,1.25,4.5,.41,5.98-13.19,23.47-26.61,46.8-39.98,70.16-.3,.52-.72,.96-1.53,2.01Z"/>
  <path onClick={() => props.onFill(22)} fill={props.fillColors[22]} d="M249.96,242.47c10.55,18.44,20.96,36.63,31.36,54.82,3.26,5.7,6.61,11.35,9.67,17.16,.69,1.3,.99,3.54,.34,4.7-13.62,24.11-27.41,48.14-41.44,72.66-.92-1.28-1.7-2.19-2.28-3.21-12.98-22.65-25.98-45.29-38.8-68.03-.92-1.62-1.36-4.51-.53-5.97,13.34-23.74,26.91-47.35,40.45-70.98,.15-.26,.45-.43,1.24-1.15Z"/>
  <path onClick={() => props.onFill(23)} fill={props.fillColors[23]} d="M24.4,313.17c-6.77-11.87-13.4-23.39-19.87-35-.72-1.28-.66-3.1-.66-4.67-.04-23.43-.04-46.87,0-70.3,0-1.71,.05-3.67,.84-5.09,6.38-11.48,12.93-22.86,19.73-34.79,1.08,1.72,1.93,2.97,2.68,4.27,12.82,22.41,25.68,44.8,38.35,67.29,.9,1.6,1.31,4.44,.5,5.9-13.18,23.48-26.6,46.83-39.98,70.2-.37,.65-.89,1.23-1.59,2.2Z"/>
  <path onClick={() => props.onFill(24)} fill={props.fillColors[24]} d="M24.56,5.61c10.62,18.55,20.95,36.62,31.29,54.68,3.42,5.98,6.94,11.9,10.16,17.99,.67,1.27,.85,3.5,.18,4.67-13.64,24.1-27.43,48.12-41.6,72.88-3.18-5.52-5.66-10.85-9.06-15.51-9.78-13.4-13.02-28.06-11.93-44.59,1.09-16.6,.16-33.33,.33-50,.02-2.42,.73-5.08,1.9-7.2C11.85,27.63,18.13,16.86,24.56,5.61Z"/>
  <path onClick={() => props.onFill(25)} fill={props.fillColors[25]} d="M24.56,320.68c12.96,22.66,25.24,44.77,38.25,66.46,3.86,6.44,3.88,11.02-.05,17.5-12.29,20.27-23.73,41.05-35.5,61.63-.79,1.38-1.61,2.75-2.73,4.65-6.86-12.01-13.55-23.61-20.06-35.3-.71-1.28-.6-3.1-.6-4.68-.03-23.41-.04-46.82,.01-70.23,0-1.71,.04-3.68,.83-5.1,6.38-11.47,12.95-22.83,19.86-34.94Z"/>
  <path onClick={() => props.onFill(26)} fill={props.fillColors[26]} d="M474.72,155.89c-13.23-23.14-25.75-45.5-38.79-67.55-3.23-5.46-3.47-9.41-.12-15.02,12.41-20.76,24.16-41.91,36.16-62.91,.77-1.35,1.62-2.65,2.83-4.62,4.65,8.08,8.63,15.92,13.47,23.19,5.46,8.2,7.46,16.76,7.16,26.63-.61,20.02-.1,40.07-.28,60.11-.02,2.72-.86,5.71-2.17,8.11-5.75,10.5-11.82,20.82-18.27,32.07Z"/>
  <path onClick={() => props.onFill(27)} fill={props.fillColors[27]} d="M474.73,471c-8.29-14.52-16.15-28.27-24.01-42.02-5.73-10.01-11.54-19.98-17.11-30.08-.78-1.42-1.21-3.89-.51-5.14,13.6-24.14,27.39-48.16,41.6-73.02,2.78,4.81,4.87,9.31,7.75,13.23,10.58,14.44,14.29,24.77,13.55,24.03,0,15.84-.64,56.54-.84,72.73-.03,2.73-.83,5.71-2.14,8.11-5.75,10.52-11.82,20.86-18.29,32.16Z"/>
  <path onClick={() => props.onFill(28)} fill={props.fillColors[28]} d="M475.03,163.71c4.8,8.36,9.23,16.85,14.41,24.86,4.44,6.87,6.14,13.93,5.97,22.12-.45,20.83-.07,41.68-.24,62.52-.02,2.72-.84,5.71-2.14,8.11-5.03,9.29-10.39,18.4-15.65,27.57-.77,1.34-1.65,2.62-2.76,4.36-11.02-19.26-21.73-37.99-32.45-56.72-2.94-5.14-6.01-10.22-8.7-15.49-.71-1.4-.93-3.79-.21-5.07,13.45-23.85,27.08-47.6,40.69-71.36,.14-.24,.45-.37,1.09-.89Z"/>
  <path onClick={() => props.onFill(29)} fill={props.fillColors[29]} d="M69.84,399.84c7.76,13.59,15.37,26.91,22.98,40.23,5.96,10.43,11.87,20.89,17.96,31.24,1.38,2.35,1.5,4.18,.03,6.53-2.89,4.61-5.27,9.55-8.29,14.06-1.02,1.52-3.32,3.06-5.06,3.08-18.6,.22-37.21,.21-55.82,.01-1.64-.02-3.88-1.36-4.8-2.77-3.15-4.82-5.99-9.86-8.59-15-.71-1.4-.81-3.83-.06-5.15,13.3-23.58,26.77-47.05,40.21-70.54,.3-.52,.79-.94,1.44-1.69Z"/>
  <path onClick={() => props.onFill(30)} fill={props.fillColors[30]} d="M249.65,399.85c1.01,1.58,1.81,2.71,2.49,3.9,12.73,22.26,25.38,44.57,38.23,66.76,1.77,3.06,1.82,5.35-.08,8.28-2.79,4.29-4.94,9.01-7.82,13.23-1.02,1.5-3.37,2.93-5.13,2.95-18.45,.21-36.9,.06-55.35,.2-2.86,.02-4.32-1.05-5.55-3.43-1.9-3.7-3.84-7.44-6.26-10.81-3.07-4.3-2.95-7.82-.17-12.51,12.55-21.26,24.63-42.79,36.89-64.22,.77-1.35,1.66-2.64,2.75-4.36Z"/>
  <path onClick={() => props.onFill(31)} fill={props.fillColors[31]} d="M159.63,399.39c10.33,18.08,20.26,35.47,30.19,52.86,3.66,6.4,7.48,12.72,10.85,19.27,.83,1.62,.9,4.32,.12,5.95-2.42,5.04-5.22,9.95-8.32,14.6-1,1.5-3.4,2.89-5.17,2.91-18.46,.21-36.92,.21-55.38,0-1.76-.02-4.05-1.47-5.14-2.96-2.36-3.21-3.77-7.13-6.16-10.3-3.68-4.87-3.25-8.88-.14-14.14,13.22-22.35,25.91-45,39.16-68.2Z"/>
  <path onClick={() => props.onFill(32)} fill={props.fillColors[32]} d="M429.71,399.35c4.6,8.05,8.71,15.26,12.82,22.45,9.37,16.4,18.71,32.83,28.17,49.18,1.43,2.47,1.75,4.47,.14,7.02-2.9,4.6-5.26,9.55-8.29,14.05-1,1.49-3.37,2.91-5.13,2.93-18.43,.21-36.86,.07-55.29,.19-2.79,.02-4.33-.91-5.6-3.34-2.6-4.96-5.64-9.69-8.2-14.67-.67-1.3-.87-3.54-.19-4.73,13.58-24.1,27.32-48.1,41.57-73.08Z"/>
  <path onClick={() => props.onFill(33)} fill={props.fillColors[33]} d="M339.7,399.42c5.57,9.75,10.72,18.77,15.87,27.79,8.34,14.59,16.6,29.22,25.09,43.72,1.6,2.73,1.7,4.71-.03,7.37-2.87,4.42-5.09,9.27-8.03,13.64-1.02,1.52-3.36,3.02-5.11,3.04-18.59,.22-37.18,.2-55.77,.01-1.64-.02-3.9-1.35-4.79-2.75-3.5-5.54-8.98-11.31-9.1-17.08-.12-5.67,5.25-11.54,8.45-17.18,10.96-19.35,22.04-38.63,33.42-58.56Z"/>
  <path onClick={() => props.onFill(34)} fill={props.fillColors[34]} d="M249.66,77.17c-14.11-24.69-27.76-48.57-41.7-72.95h83.37c-13.87,24.28-27.5,48.15-41.67,72.95Z"/>
  <path onClick={() => props.onFill(35)} fill={props.fillColors[35]} d="M387.91,4.03h83.58c-13.91,24.4-27.58,48.36-41.74,73.19-14.1-24.66-27.83-48.68-41.84-73.19Z"/>
  <path onClick={() => props.onFill(36)} fill={props.fillColors[36]} d="M117.81,3.95h83.62c-14.01,24.55-27.76,48.65-41.8,73.27-14.05-24.62-27.75-48.62-41.82-73.27Z"/>
  <path onClick={() => props.onFill(37)} fill={props.fillColors[37]} d="M339.71,77.16c-14.09-24.66-27.74-48.56-41.7-72.98h83.4c-13.92,24.36-27.63,48.36-41.7,72.98Z"/>
  <path onClick={() => props.onFill(38)} fill={props.fillColors[38]} d="M111.3,4.17c-13.94,24.39-27.61,48.33-41.7,72.99C55.55,52.59,41.85,28.63,27.87,4.17H111.3Z"/>
  <path onClick={() => props.onFill(39)} fill={props.fillColors[39]} d="M4.05,347.6v-62.81c6.37,11.14,12.07,21.05,17.64,31.03,.42,.75,.17,2.23-.3,3.07-5.45,9.72-11.01,19.39-16.53,29.07l-.8-.35Z"/>
  <path onClick={() => props.onFill(40)} fill={props.fillColors[40]} d="M4.86,128.64c5.6,9.8,11.22,19.59,16.75,29.43,.4,.71,.49,2,.11,2.67-5.6,9.98-11.3,19.89-17.65,30.99v-62.66l.78-.43Z"/>
  <path onClick={() => props.onFill(41)} fill={props.fillColors[41]} d="M494.81,191.01c-6.14-10.76-11.71-20.41-17.11-30.15-.47-.84-.19-2.49,.34-3.43,5.32-9.48,10.76-18.88,16.77-29.37v62.95Z"/>
  <path onClick={() => props.onFill(42)} fill={props.fillColors[42]} d="M495.05,286.92v61.85c-2.98-5.13-5.34-9.15-7.66-13.19-3.03-5.27-6.15-10.5-8.97-15.88-.68-1.29-.98-3.48-.34-4.64,5.22-9.54,10.7-18.93,16.11-28.36l.87,.23Z"/>
  <path onClick={() => props.onFill(43)} fill={props.fillColors[43]} d="M4.86,443.73c5.53,9.68,11.12,19.32,16.48,29.09,.59,1.07,.27,3.09-.35,4.3-2.34,4.55-5.31,8.79-7.43,13.43-1.99,4.35-4.89,5.46-9.44,4.12v-50.62l.74-.32Z"/>
  <path onClick={() => props.onFill(44)} fill={props.fillColors[44]} d="M494.98,494.68c-4.39,1.24-7.15,.21-9.08-3.96-2.6-5.63-8.12-10.94-8.15-16.42-.03-5.28,5.31-10.59,8.32-15.88,2.73-4.8,5.5-9.59,8.91-15.53v51.78Z"/>
  <path onClick={() => props.onFill(45)} fill={props.fillColors[45]} d="M4.12,34.15V4.09H21.29C15.69,13.9,10.27,23.39,4.12,34.15Z"/>
  <path onClick={() => props.onFill(46)} fill={props.fillColors[46]} d="M477.99,4.06h17V31.96l-.75,.42c-5.31-9.25-10.63-18.51-16.25-28.31Z"/>
  <path onClick={() => props.onFill(47)} fill={props.fillColors[47]} d="M34.1,494.87H15.06c3.24-5.69,6.2-10.87,9.49-16.65,3.25,5.67,6.21,10.83,9.55,16.65Z"/>
  <path onClick={() => props.onFill(48)} fill={props.fillColors[48]} d="M195.17,494.84c3.31-5.82,6.22-10.93,9.46-16.63,3.28,5.75,6.25,10.94,9.5,16.63h-18.96Z"/>
  <path onClick={() => props.onFill(49)} fill={props.fillColors[49]} d="M465.35,494.69c3.06-5.39,5.94-10.45,9.36-16.46,3.28,5.68,6.21,10.77,9.49,16.46h-18.85Z"/>
  <path onClick={() => props.onFill(50)} fill={props.fillColors[50]} d="M124.11,494.86h-18.97c3.19-5.58,6.08-10.66,9.48-16.6,3.31,5.79,6.25,10.93,9.5,16.6Z"/>
  <path onClick={() => props.onFill(51)} fill={props.fillColors[51]} d="M304.23,494.88h-19.04c3.15-5.55,6.03-10.62,9.47-16.67,3.27,5.7,6.2,10.79,9.57,16.67Z"/>
  <path onClick={() => props.onFill(52)} fill={props.fillColors[52]} d="M375.28,494.78c3.24-5.7,6.14-10.81,9.42-16.58,3.27,5.74,6.21,10.9,9.46,16.58h-18.88Z"/>

</g>
            </svg>
        </div>
    )
}