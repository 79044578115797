import React from 'react';
import Patterns from './patterns';


export default function Maywood(props) {
    
    return (
        <div className={`maywood-container ${props.size}`}> 
 
            <svg  xmlns="http://www.w3.org/2000/svg" id={props.id} width="500" height="500" viewBox="0 0 500 500">
                
<Patterns />
            <g className="hummingbird-image" >

<path id="background" fill="black"  d="m0,500V0h500v500H0Z"/>   

    <path onClick={() => props.onFill(1)} fill={props.fillColors[1]} d="M139.94,87.61c-16.61,16.61-33.49,33.5-50.69,50.69-17.2-16.82-34.53-33.77-52.19-51.04,17.43-17.04,34.75-33.98,51.83-50.68,17.08,17.07,34.02,34.01,51.04,51.03Z"/>
    <path onClick={() => props.onFill(2)} fill={props.fillColors[2]} d="M88.87,353.62c-17.02-16.66-34.3-33.58-51.78-50.7,17.5-17.13,34.84-34.09,51.16-50.06,17.11,17.1,34.03,34.02,50.7,50.68-16.2,16.2-32.99,32.98-50.08,50.07Z"/>
    <path onClick={() => props.onFill(3)} fill={props.fillColors[3]} d="M89.34,143.97c.95,.89,1.72,1.57,2.45,2.29,15.34,15.33,30.62,30.74,46.09,45.94,2.24,2.2,1.78,3.6,.15,5.46-.55,.63-1.15,1.21-1.74,1.8-14.82,14.82-29.65,29.64-44.47,44.46-.73,.73-1.49,1.41-2.47,2.33-17.42-17.05-34.75-34.02-52.26-51.16,17.48-17.1,34.81-34.06,52.25-51.12Z"/>
    <path onClick={() => props.onFill(4)} fill={props.fillColors[4]} d="M37.1,410.77c17.52-17.15,34.79-34.05,51.82-50.72,17.11,17.1,34.04,34.04,50.62,50.61-16.43,16.75-33.13,33.78-50.01,50.99-17.51-17-34.78-33.76-52.43-50.88Z"/>
    <path onClick={() => props.onFill(5)} fill={props.fillColors[5]} d="M146.36,87.47c17.11-17.1,33.98-33.97,50.55-50.52,16.86,16.85,33.85,33.84,50.98,50.96-4.47,4.46-9.41,9.39-14.34,14.31-10.55,10.54-21.09,21.09-31.64,31.63-.59,.59-1.18,1.18-1.78,1.76-1.96,1.86-3.5,2.37-5.86-.03-15.36-15.61-30.91-31.03-46.4-46.52-.51-.51-.99-1.05-1.51-1.6Z"/>
    <path onClick={() => props.onFill(6)} fill={props.fillColors[6]} d="M254.44,87.66c17.02-17.03,33.95-33.97,50.65-50.67,16.67,16.66,33.61,33.6,50.72,50.7-.33,.36-.98,1.12-1.69,1.83-15.19,15.2-30.44,30.35-45.54,45.65-2.08,2.11-3.72,2.2-5.84,.57-.66-.51-1.34-1-1.93-1.58-15.21-15.19-30.4-30.39-45.6-45.59-.36-.36-.67-.78-.78-.91Z"/>
    <path onClick={() => props.onFill(7)} fill={props.fillColors[7]} d="M146.72,409.64c1.75-1.63,4.19-3.76,6.47-6.04,13.74-13.7,27.51-27.37,41.11-41.2,2.09-2.13,3.55-1.96,5.44-.24,.46,.42,.93,.83,1.37,1.27,15.35,15.34,30.69,30.67,46.04,46.02,.36,.36,.66,.77,.75,.87-16.79,17.12-33.49,34.15-50.43,51.42-16.93-17.38-33.57-34.46-50.75-52.1Z"/>
    <path onClick={() => props.onFill(8)} fill={props.fillColors[8]} d="M254.63,409.72c2.23-2.14,5.13-4.84,7.93-7.64,12.42-12.38,24.81-24.79,37.21-37.18,.66-.66,1.3-1.36,2.02-1.97,3.19-2.73,4.03-2.69,6.97,.25,15.13,15.13,30.26,30.26,45.41,45.37,.66,.66,1.51,1.12,1.88,1.39-17.05,17.45-33.74,34.52-50.75,51.93-16.94-17.44-33.57-34.55-50.66-52.15Z"/>
    <path onClick={() => props.onFill(9)} fill={props.fillColors[9]} d="M362.74,87.15c16.61-16.6,33.55-33.54,50.64-50.63,16.26,16.74,32.74,33.72,49.3,50.76-16.59,17.08-33.01,33.99-49.15,50.61-17.08-17.07-34.07-34.05-50.78-50.74Z"/>
    <path onClick={() => props.onFill(10)} fill={props.fillColors[10]} d="M413.05,251.82c16.58,17.08,33,33.98,49.62,51.1-16.53,17.03-32.99,34-49.61,51.12-.82-.73-1.53-1.3-2.17-1.94-15.28-15.26-30.52-30.56-45.87-45.76-1.75-1.73-2.08-3.15-.68-5.07,.79-1.09,1.43-2.33,2.36-3.27,15.02-15.08,30.08-30.12,45.14-45.16,.29-.29,.63-.54,1.2-1.02Z"/>
    <path onClick={() => props.onFill(11)} fill={props.fillColors[11]} d="M412.84,461.67c-16.78-17.17-33.47-34.24-49.91-51.07,16.44-16.45,33.38-33.39,50.52-50.53,16.08,16.57,32.53,33.52,49.21,50.7-16.6,16.97-33.07,33.8-49.81,50.9Z"/>
    <path onClick={() => props.onFill(12)} fill={props.fillColors[12]} d="M412.76,143.68c16.91,17.42,33.33,34.32,49.91,51.4-16.55,17.05-33.01,34.02-49.62,51.13-.87-.8-1.63-1.47-2.35-2.18-14.47-14.45-28.93-28.91-43.39-43.37-.37-.37-.75-.73-1.1-1.11-3.58-3.9-3.57-5.33,.05-8.96,14.68-14.68,29.37-29.36,44.04-44.05,.8-.81,1.49-1.73,2.45-2.86Z"/>
    <path onClick={() => props.onFill(13)} fill={props.fillColors[13]} d="M143.5,90.73c16.16,16.16,32.53,32.52,49.05,49.04-21.84,11.75-38.64,28.93-50.56,50.65-16.46-16.45-32.89-32.88-49.1-49.09,16.72-16.71,33.66-33.65,50.61-50.6Z"/>
    <path onClick={() => props.onFill(14)} fill={props.fillColors[14]} d="M143.06,407.01c-16.57-16.56-33.51-33.49-50.32-50.3,16.24-16.23,32.67-32.65,49.19-49.18,11.99,21.61,28.7,38.74,50.2,50.41-16.38,16.37-32.76,32.76-49.07,49.06Z"/>
    <path onClick={() => props.onFill(15)} fill={props.fillColors[15]} d="M360.89,308.19c16.31,16.31,32.52,32.51,48.42,48.41-16.71,16.71-33.69,33.69-50.47,50.47-15.85-15.85-32.13-32.12-48.6-48.6,21.66-11.73,38.72-28.6,50.66-50.28Z"/>
    <path onClick={() => props.onFill(16)} fill={props.fillColors[16]} d="M310.28,139.56c16.44-16.44,32.73-32.72,48.86-48.85,16.76,16.76,33.72,33.71,50.51,50.5-16.06,16.06-32.28,32.28-48.66,48.67-11.97-21.63-28.83-38.59-50.71-50.32Z"/>
    <path onClick={() => props.onFill(17)} fill={props.fillColors[17]} d="M319.67,466.32v29.88H180.07v-29.88h139.6Z"/>
    <path onClick={() => props.onFill(18)} fill={props.fillColors[18]} d="M323.79,496.14v-29.81h139.39v29.81h-139.39Z"/>
    <path onClick={() => props.onFill(19)} fill={props.fillColors[19]} d="M176,466.32c0,9.58,.04,18.96-.08,28.33,0,.6-1.14,1.32-1.89,1.72-.49,.26-1.23,.06-1.85,.06-44.03,0-88.06,0-132.08,0h-3.6v-30.11H176Z"/>
    <path onClick={() => props.onFill(20)} fill={props.fillColors[20]} d="M319.69,1.51V31.09H180.03V1.51h139.66Z"/>
    <path onClick={() => props.onFill(21)} fill={props.fillColors[21]} d="M497.11,318.53h-29.63V178.94h29.63v139.59Z"/>
    <path onClick={() => props.onFill(22)} fill={props.fillColors[22]} d="M32.14,318.53H2.58V178.99h29.56v139.54Z"/>
    <path onClick={() => props.onFill(23)} fill={props.fillColors[23]} d="M175.71,31.27H36.52V1.5H175.71V31.27Z"/>
    <path onClick={() => props.onFill(24)} fill={props.fillColors[24]} d="M463.28,1.52V31.16h-139.41V1.52h139.41Z"/>
    <path onClick={() => props.onFill(25)} fill={props.fillColors[25]} d="M467.5,322.71h29.6v139.39h-29.6v-139.39Z"/>
    <path onClick={() => props.onFill(26)} fill={props.fillColors[26]} d="M32.22,174.71H2.62V35.44h29.6V174.71Z"/>
    <path onClick={() => props.onFill(27)} fill={props.fillColors[27]} d="M2.62,322.7h29.54v139.39H2.62v-139.39Z"/>
    <path onClick={() => props.onFill(28)} fill={props.fillColors[28]} d="M467.45,174.85V35.39h29.88c.06,1.34,.17,2.56,.17,3.78,0,43.83,0,87.66,0,131.5q0,4.18-4.3,4.19c-7.41,0-14.82,0-22.23,0-1.12,0-2.23,0-3.52,0Z"/>
    <path onClick={() => props.onFill(29)} fill={props.fillColors[29]} d="M36.47,406.02v-98.35c16.88,16.54,33.37,32.7,50.2,49.19-16.67,16.32-33.21,32.52-50.2,49.16Z"/>
    <path onClick={() => props.onFill(30)} fill={props.fillColors[30]} d="M36.41,190.4V91.94c17,16.66,33.55,32.87,50.27,49.24-16.73,16.38-33.25,32.55-50.27,49.22Z"/>
    <path onClick={() => props.onFill(31)} fill={props.fillColors[31]} d="M36.55,199.91c16.87,16.53,33.39,32.71,50.12,49.11-16.68,16.34-33.2,32.52-50.12,49.08v-98.19Z"/>
    <path onClick={() => props.onFill(32)} fill={props.fillColors[32]} d="M191.83,35.36c-16.2,16.2-32.41,32.4-48.38,48.37-15.97-15.97-32.23-32.23-48.38-48.37h96.75Z"/>
    <path onClick={() => props.onFill(33)} fill={props.fillColors[33]} d="M407.57,35.39c-16.32,16.31-32.52,32.51-48.43,48.41-16.04-16.04-32.24-32.23-48.42-48.41h96.85Z"/>
    <path onClick={() => props.onFill(34)} fill={props.fillColors[34]} d="M203.13,35.36h96.22c-16.02,16.02-32.21,32.2-48.12,48.1-15.76-15.76-32-31.99-48.1-48.1Z"/>
    <path onClick={() => props.onFill(35)} fill={props.fillColors[35]} d="M95.96,461.96c15.51-15.89,31.35-32.12,47.41-48.59,16.06,16.46,31.89,32.69,47.41,48.59H95.96Z"/>
    <path onClick={() => props.onFill(36)} fill={props.fillColors[36]} d="M251.44,413.16c16.12,16.52,31.91,32.7,47.62,48.79h-95.34c15.49-15.83,31.37-32.07,47.72-48.79Z"/>
    <path onClick={() => props.onFill(37)} fill={props.fillColors[37]} d="M359.07,413.2c16.11,16.55,31.94,32.82,47.42,48.72h-95.01c15.53-15.89,31.42-32.15,47.6-48.72Z"/>
    <path onClick={() => props.onFill(38)} fill={props.fillColors[38]} d="M463.18,297.96c-16.05-16.52-31.7-32.64-47.53-48.94,15.82-16.31,31.49-32.44,47.53-48.97v97.91Z"/>
    <path onClick={() => props.onFill(39)} fill={props.fillColors[39]} d="M415.65,141.18c15.92-16.39,31.52-32.45,47.51-48.91v97.83c-16-16.47-31.66-32.59-47.51-48.92Z"/>
    <path onClick={() => props.onFill(40)} fill={props.fillColors[40]} d="M415.66,356.86c15.79-16.27,31.39-32.35,47.42-48.86v97.69c-15.95-16.42-31.6-32.54-47.42-48.83Z"/>
    <path onClick={() => props.onFill(41)} fill={props.fillColors[41]} d="M249.61,244.64c-6.67-5.81-12.93-11.27-19.2-16.73-3.46-3.02-7.01-5.94-10.31-9.13-.82-.8-1.43-2.59-1.14-3.66,6.29-22.89,12.73-45.75,19.14-68.61,3.49-12.42,7-24.84,10.5-37.26,.22-.77,.48-1.53,1-3.19V244.64Z"/>
    <path onClick={() => props.onFill(42)} fill={props.fillColors[42]} d="M249.25,390.98c-2.39-8.48-4.78-16.96-7.16-25.44-7.67-27.37-15.33-54.74-23.04-82.09-.49-1.75-.66-3.01,.95-4.39,9.5-8.15,18.9-16.42,28.34-24.65,.29-.25,.64-.44,1.22-.84v137.22l-.31,.19Z"/>
    <path onClick={() => props.onFill(43)} fill={props.fillColors[43]} d="M254.15,107.52c3.28,11.61,6.59,23.21,9.85,34.82,6.66,23.75,13.22,47.53,20.01,71.24,.82,2.87,.26,4.45-1.93,6.31-8.74,7.43-17.31,15.06-25.96,22.61-.62,.54-1.28,1.03-2.28,1.82V107.5l.3,.03Z"/>
    <path onClick={() => props.onFill(44)} fill={props.fillColors[44]} d="M253.77,253.41c10.35,9.04,20.2,17.57,29.91,26.25,.69,.61,.79,2.44,.5,3.51-5.86,21.27-11.81,42.51-17.77,63.75-3.85,13.72-7.73,27.44-11.61,41.16-.22,.78-.48,1.56-1.03,2.3V253.41Z"/>
    <path onClick={() => props.onFill(45)} fill={props.fillColors[45]} d="M113.09,250.92H246.39c-1.47,1.31-2.5,2.26-3.55,3.18-8.4,7.35-16.83,14.66-25.17,22.08-1.4,1.25-2.64,1.63-4.45,1.15-32.61-8.55-65.24-17.05-97.86-25.58-.66-.17-1.29-.47-2.27-.83Z"/>
    <path onClick={() => props.onFill(46)} fill={props.fillColors[46]} d="M389.86,251.24c-8.44,2.21-16.88,4.44-25.32,6.64-24.57,6.4-49.16,12.78-73.71,19.23-2.2,.58-3.77,.41-5.58-1.22-8.7-7.8-17.55-15.42-26.35-23.1-.53-.46-1.09-.89-2.24-1.82h133.11l.1,.27Z"/>
    <path onClick={() => props.onFill(47)} fill={props.fillColors[47]} d="M246.4,246.98H113.03c1.38-.45,2.31-.82,3.27-1.07,32.23-8.42,64.47-16.83,96.71-25.19,1.08-.28,2.74-.4,3.44,.2,9.82,8.4,19.52,16.94,29.94,26.05Z"/>
    <path onClick={() => props.onFill(48)} fill={props.fillColors[48]} d="M256.76,247.07c5.23-4.58,9.84-8.61,14.46-12.64,4.87-4.26,9.68-8.6,14.67-12.7,1-.82,2.86-1.34,4.08-1.02,21.89,5.61,43.73,11.38,65.59,17.1,10.77,2.82,21.56,5.59,32.34,8.39,.55,.14,1.07,.4,2.35,.88h-133.48Z"/>
    <path onClick={() => props.onFill(49)} fill={props.fillColors[49]} d="M354.63,233.57c-13.96-3.66-27.33-7.16-40.94-10.72,4.4-14.85,8.71-29.37,13.19-44.48,14.91,15.77,24.14,33.7,27.75,55.2Z"/>
    <path onClick={() => props.onFill(50)} fill={props.fillColors[50]} d="M326.89,319.6c-4.47-15.06-8.78-29.58-13.21-44.52,13.56-3.53,26.97-7.03,40.92-10.67-3.53,21.46-12.85,39.35-27.71,55.19Z"/>
    <path onClick={() => props.onFill(51)} fill={props.fillColors[51]} d="M176.38,178.37c4.45,15,8.77,29.55,13.2,44.48-13.54,3.55-26.97,7.06-40.95,10.73,3.54-21.44,12.83-39.45,27.75-55.21Z"/>
    <path onClick={() => props.onFill(52)} fill={props.fillColors[52]} d="M189.58,275.07c-4.43,14.94-8.75,29.49-13.21,44.53-14.87-15.79-24.19-33.73-27.74-55.19,13.89,3.62,27.25,7.09,40.95,10.66Z"/>
    <path onClick={() => props.onFill(53)} fill={props.fillColors[53]} d="M84.59,35.45c-16.12,15.78-31.88,31.21-48.04,47.03V35.45h48.04Z"/>
    <path onClick={() => props.onFill(54)} fill={props.fillColors[54]} d="M84.57,462.15H36.46v-46.67c16.03,15.56,31.77,30.82,48.1,46.67Z"/>
    <path onClick={() => props.onFill(55)} fill={props.fillColors[55]} d="M463.23,82.35c-15.38-15.83-30.3-31.19-45.62-46.96h45.62v46.96Z"/>
    <path onClick={() => props.onFill(56)} fill={props.fillColors[56]} d="M463.18,462.19h-45.5c15.32-15.65,30.18-30.82,45.5-46.46v46.46Z"/>
    <path onClick={() => props.onFill(57)} fill={props.fillColors[57]} d="M234,350.93c-20.25-4.37-37.49-12.98-52.84-26.81,14.16-4.14,27.9-8.16,41.88-12.24,3.64,12.99,7.2,25.67,10.96,39.05Z"/>
    <path onClick={() => props.onFill(58)} fill={props.fillColors[58]} d="M269.37,350.49c3.63-12.94,7.19-25.64,10.82-38.61,13.92,4.07,27.63,8.07,41.45,12.11-9.01,10.89-38.99,26.11-52.27,26.5Z"/>
    <path onClick={() => props.onFill(59)} fill={props.fillColors[59]} d="M234,147c-3.74,13.32-7.3,26-10.96,39.04-13.89-4.05-27.6-8.05-41.84-12.21,15.25-13.82,32.49-22.42,52.8-26.83Z"/>
    <path onClick={() => props.onFill(60)} fill={props.fillColors[60]} d="M280.19,186.05c-3.65-13.01-7.21-25.71-10.78-38.44,10.8-.93,43.68,15.64,52.11,26.25-.4,.15-.75,.32-1.13,.43-13.27,3.88-26.53,7.76-40.21,11.76Z"/>
    <path onClick={() => props.onFill(61)} fill={props.fillColors[61]} d="M239.03,129.06c-1.23,4.41-2.23,8.35-3.5,12.19-.28,.85-1.44,1.8-2.34,1.99-14.23,2.98-27.56,8.2-39.67,16.27-5.61,3.74-10.83,8.06-16.27,12.14-2.93-2.97-6-6.07-9.28-9.38,19.92-18.59,43.41-29.59,71.05-33.21Z"/>
    <path onClick={() => props.onFill(62)} fill={props.fillColors[62]} d="M264.27,368.79c1.18-4.27,2.15-8.2,3.41-12.05,.28-.86,1.43-1.84,2.34-2.03,20.74-4.38,39.04-13.52,54.72-27.83,.3-.27,.66-.49,.87-.64,3.12,3.14,6.17,6.21,9.41,9.47-6.86,6.69-14.6,12.61-23.19,17.36-2.05,1.14-3.92,3.25-6.77,2.03-.29-.12-1.19,.35-1.25,.66-.57,2.7-3.04,2.91-4.92,3.77-10.19,4.67-20.91,7.56-31.99,9.13-.71,.1-1.43,.07-2.62,.13Z"/>
    <path onClick={() => props.onFill(63)} fill={props.fillColors[63]} d="M335.5,161.81c-3.68,3.65-6.76,6.71-9.66,9.59-5.78-4.24-11.56-8.88-17.73-12.9-11.46-7.47-24.09-12.27-37.46-15.07-1.92-.4-2.84-1.18-3.29-3.08-.86-3.64-1.98-7.21-3.18-11.46,4.55,.79,8.81,1.11,12.81,2.31,8.15,2.45,16.72,4.37,24.01,8.48,4.76,2.68,10.23,3.7,14.58,7.22,6.45,5.22,13.27,9.97,19.92,14.91Z"/>
    <path onClick={() => props.onFill(64)} fill={props.fillColors[64]} d="M239,368.88c-13.07-1.59-25.17-4.79-36.67-10.16-1-.47-1.68-1.57-2.65-2.14-1.07-.63-2.3-1.01-3.48-1.43-.56-.2-1.24-.1-1.75-.37-9.73-5.16-18.54-11.57-26.32-19.18,3.16-3.18,6.23-6.27,9.1-9.15,6.2,4.51,12.2,9.34,18.66,13.47,11.25,7.18,23.6,11.87,36.67,14.57,1.9,.39,2.89,1.16,3.35,3.02,.9,3.63,1.95,7.22,3.08,11.36Z"/>
    <path onClick={() => props.onFill(65)} fill={props.fillColors[65]} d="M131.45,238.02c3.07-28.31,14.31-52.21,33.34-72.5,3.18,3.2,6.22,6.27,9.13,9.21-4.13,5.31-8.66,10.61-12.6,16.33-8.38,12.16-13.75,25.64-16.14,40.23-.42,2.56-1.37,3.77-3.92,4.23-3.16,.58-6.24,1.57-9.82,2.5Z"/>
    <path onClick={() => props.onFill(66)} fill={props.fillColors[66]} d="M131.23,259.89c4.27,1.1,7.82,1.9,11.28,3,.94,.3,2.07,1.4,2.3,2.34,7.09,28.77,11.17,36.84,29.94,58.34-1.54,1.4-3.18,2.79-4.71,4.3-1.55,1.53-2.99,3.16-5,5.3-4.37-5.49-8.56-10.49-12.44-15.72-2.35-3.17-4.32-6.63-6.25-10.08-.69-1.24-.73-2.83-1.1-4.26-.12-.49-.2-1.05-.49-1.42-6.68-8.43-8.85-18.65-11.33-28.72-1.01-4.11-1.43-8.36-2.21-13.09Z"/>
    <path onClick={() => props.onFill(67)} fill={props.fillColors[67]} d="M338.48,332.37c-3.39-3.24-6.46-6.17-9.32-8.9,4.42-5.74,9.08-11.22,13.11-17.14,8.31-12.21,13.57-25.74,15.93-40.34,.32-1.99,1.05-2.92,2.92-3.34,3.44-.79,6.84-1.71,10.71-2.7-3.07,28.16-14.29,52.12-33.35,72.41Z"/>
    <path onClick={() => props.onFill(68)} fill={props.fillColors[68]} d="M328.8,174.82c3.21-3.15,6.25-6.12,9.5-9.3,5.98,6.02,11.22,12.83,15.78,20.2,1.2,1.95,2.92,3.87,3.25,5.99,.42,2.69,1.99,4.38,3.09,6.53,6.28,12.29,10.25,25.41,11.32,39.76-4.12-1.08-8.01-2.02-11.83-3.18-.66-.2-1.38-1.25-1.5-2-3.63-22.43-13.73-41.56-29.59-58Z"/>
    <path onClick={() => props.onFill(69)} fill={props.fillColors[69]} d="M2.5,496.24v-30.01h29.67v30.01H2.5Z"/>
    <path onClick={() => props.onFill(70)} fill={props.fillColors[70]} d="M497.15,466.34v29.9h-29.66v-29.9h29.66Z"/>
    <path onClick={() => props.onFill(71)} fill={props.fillColors[71]} d="M32.13,31.15H2.6V1.54h29.53V31.15Z"/>
    <path onClick={() => props.onFill(72)} fill={props.fillColors[72]} d="M467.44,31.09V1.51h29.69V31.09h-29.69Z"/>
    <path onClick={() => props.onFill(73)} fill={props.fillColors[73]} d="M215.2,283.85c2.32,8.35,4.51,16.2,6.77,24.34-12.86,3.76-25.3,7.4-37.74,11.04l-.37-.41c10.34-11.53,20.68-23.06,31.34-34.96Z"/>
    <path onClick={() => props.onFill(74)} fill={props.fillColors[74]} d="M281.22,189.74c12.85-3.75,25.33-7.39,37.8-11.04l.37,.42c-10.3,11.48-20.6,22.95-31.32,34.89-2.35-8.33-4.55-16.1-6.85-24.28Z"/>
    <path onClick={() => props.onFill(75)} fill={props.fillColors[75]} d="M184.12,178.67c12.45,3.64,24.9,7.28,37.84,11.06-2.24,8.06-4.42,15.93-6.75,24.34-10.66-11.87-20.99-23.37-31.33-34.88l.24-.52Z"/>
    <path onClick={() => props.onFill(76)} fill={props.fillColors[76]} d="M320.01,319.51c-13.43-3.92-25.95-7.57-38.78-11.32,2.25-8.01,4.42-15.77,6.81-24.28,10.71,11.93,21.02,23.41,31.97,35.6Z"/>
    <path onClick={() => props.onFill(77)} fill={props.fillColors[77]} d="M133.1,209.33c-1.57,8.06-3.49,18.02-5.48,27.97-.15,.74-.76,1.81-1.35,1.97-10.24,2.68-20.52,5.24-30.79,7.82-.16,.04-.37-.14-.11-.03,12.98-12.98,26.1-26.09,37.73-37.72Z"/>
    <path onClick={() => props.onFill(78)} fill={props.fillColors[78]} d="M95.57,251.12c1.04,.14,2.56,.19,3.99,.55,8.47,2.14,16.92,4.39,25.4,6.51,1.87,.47,2.46,1.33,2.82,3.3,1.77,9.78,3.85,19.51,5.41,27.24-11.54-11.54-24.61-24.6-37.62-37.6Z"/>
    <path onClick={() => props.onFill(79)} fill={props.fillColors[79]} d="M249.11,93.27c-1.73,6.14-3.61,12.81-5.49,19.47-.82,2.9-1.83,5.76-2.39,8.71-.52,2.73-1.8,3.76-4.6,4.25-8.96,1.58-17.85,3.6-25.05,5.1,11.72-11.72,24.74-24.74,37.54-37.54Z"/>
    <path onClick={() => props.onFill(80)} fill={props.fillColors[80]} d="M249.38,405.57c-13.44-13.45-26.51-26.53-38.47-38.5,8.41,1.7,18.25,3.67,28.07,5.71,.55,.11,1.27,.73,1.43,1.26,3,10.37,5.93,20.75,8.98,31.53Z"/>
    <path onClick={() => props.onFill(81)} fill={props.fillColors[81]} d="M407.12,247.2c-9.77-2.53-19.74-5.07-29.68-7.75-.73-.2-1.57-1.28-1.73-2.07-1.9-9.46-3.67-18.94-5.15-26.68,11.33,11.31,23.93,23.9,36.56,36.51Z"/>
    <path onClick={() => props.onFill(82)} fill={props.fillColors[82]} d="M370.56,287.23c1.44-7.55,3.26-16.7,4.9-25.88,.32-1.77,.89-2.69,2.68-3.14,8.68-2.17,17.32-4.48,25.99-6.69,.98-.25,2.03-.25,2.46-.3-12.28,12.28-24.81,24.81-36.02,36.02Z"/>
    <path onClick={() => props.onFill(83)} fill={props.fillColors[83]} d="M290.89,130.43c-8.01-1.58-17.27-3.39-26.51-5.29-.63-.13-1.45-.86-1.63-1.47-2.88-9.96-5.67-19.94-8.46-29.84,12.55,12.55,25.05,25.05,36.6,36.6Z"/>
    <path onClick={() => props.onFill(84)} fill={props.fillColors[84]} d="M254.47,403.52c2.18-7.77,4.55-16.24,6.93-24.71,1.66-5.9,1.73-5.62,7.85-6.75,7.64-1.4,15.18-3.31,21.38-4.69-11.51,11.5-24.01,24-36.16,36.15Z"/>
    <path onClick={() => props.onFill(85)} fill={props.fillColors[85]} d="M193.26,221.9c-3.84-12.99-7.51-25.43-11.19-37.87l.66-.41c9.82,10.94,19.64,21.88,29.84,33.25-6.67,1.74-12.84,3.35-19.31,5.03Z"/>
    <path onClick={() => props.onFill(86)} fill={props.fillColors[86]} d="M193.25,276.04c6.5,1.69,12.6,3.28,19.32,5.04-10.16,11.32-19.99,22.27-29.81,33.22l-.7-.36c3.69-12.48,7.37-24.97,11.19-37.89Z"/>
    <path onClick={() => props.onFill(87)} fill={props.fillColors[87]} d="M309.97,221.89c-6.5-1.69-12.58-3.27-19.27-5.01,10.24-11.43,20.14-22.46,30.03-33.5l.56,.28c-3.73,12.59-7.46,25.18-11.33,38.23Z"/>
    <path onClick={() => props.onFill(88)} fill={props.fillColors[88]} d="M320.68,314.5c-9.85-10.98-19.71-21.96-29.98-33.4,6.36-1.67,12.23-3.25,18.13-4.68,.42-.1,1.43,.77,1.6,1.35,3.66,12.12,7.24,24.26,10.83,36.39l-.58,.34Z"/>
    
    </g>

            </svg>
        </div>
    )
}