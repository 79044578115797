import React, { useState } from 'react';
import {NavLink} from 'react-router-dom'
import { useSelector } from 'react-redux';
import InputIcon from '@mui/icons-material/Input';
import LogoutIcon from '@mui/icons-material/Logout';
import { useHistory } from "react-router-dom";
import HowToRegIcon from '@mui/icons-material/HowToReg';


export default function NavBar2() {
    const {token} = useSelector(state => state.auth);
    
    return (
        <>
        {(!token) ?
            ( 
            <ul className='login-ul'>
            <li  className="login">
                <NavLink className="nav-bar-link" to="/login"><InputIcon/> Login</NavLink>
            </li>
            <li className="signup">
                <NavLink className="nav-bar-link" to="/signup"><HowToRegIcon/> Sign Up</NavLink>
            </li>
            </ul>
            )
        :
            (
                <ul className='login-ul'>
                <li   className="logout">
                <NavLink className="nav-bar-link" to="/logout"><LogoutIcon/> Log Out</NavLink>
                </li>
            </ul>
            )}
        </>

    )
};