import React from 'react';
import './Mountainviewrounded';
import Patterns from './patterns';

export default function Mountainviewrounded(props) {
    
    return (
        <div className={`mountainviewrounded-container ${props.size}`}> 
 
            <svg  xmlns="http://www.w3.org/2000/svg" id={props.id} width="500" height="500" viewBox="0 0 500 500">
            <circle  className="st0" cx="250" cy="250" r="250"/>
            <Patterns />    
                                    
            <g className="mountainviewrounded-image" >

  <path onClick={() => props.onFill(1)} fill={props.fillColors[1]} d="m252.92,477.8c0-3.02,0-5.87,0-8.73-.04-17.9-.05-35.81-.13-53.71-.1-23.89-.24-47.79-.42-71.68-.02-2.21.4-4.23,1.21-6.27,4.01-10.18,8.03-20.38,13.3-29.98,12.85-23.43,31.35-40.79,56.08-51.15,6.16-2.58,12.7-4.26,19.09-6.28.89-.28,1.89-.25,2.84-.24,17.98.18,35.95.48,53.93.54,26.21.08,52.42,0,78.63-.02.95,0,1.9,0,3.19,0-.17,30.08-5.84,58.88-17.38,86.42-19.39,46.27-50.59,82.53-93.48,108.57-39.13,23.75-81.89,32.84-116.86,32.56Z"/>
  <path onClick={() => props.onFill(2)} fill={props.fillColors[2]} d="m480.45,248.3h-2.77c-27.49,0-54.98.06-82.47-.02-16.54-.05-33.08-.32-49.61-.55-1.57-.02-3.18-.32-4.71-.74-9.11-2.47-18.06-5.39-26.44-9.82-11.92-6.3-22.39-14.44-31.41-24.47-9.07-10.08-16.23-21.35-21.51-33.81-2.86-6.76-5.68-13.55-8.44-20.36-.44-1.08-.66-2.32-.66-3.48,0-20.3.04-40.6.12-60.91.1-23.5.27-47,.41-70.49,0-.94,0-1.88,0-2.8,42.71-.94,103.83,14.2,153.33,59.59,55.21,50.64,74.74,117.29,74.17,167.86Z"/>
  <path onClick={() => props.onFill(3)} fill={props.fillColors[3]} d="m251,477.79c-124.99.18-227.76-103.19-227.46-227.34.59-.06,1.2-.18,1.81-.18,29.8.05,59.61,0,89.41.22,16.53.12,33.06.65,49.6,1.04,1.18.03,2.39.22,3.53.54,18.89,5.32,35.1,15.18,48.78,29.18,9.51,9.74,17.25,20.77,22.83,33.21,3.55,7.94,6.82,16.01,10.12,24.06.53,1.29.8,2.77.8,4.16.11,18.3.14,36.6.22,54.9.11,25.73.25,51.46.37,77.2,0,.95,0,1.9,0,3Z"/>
  <path onClick={() => props.onFill(4)} fill={props.fillColors[4]} d="m23.27,248.3c1.15-58.42,20.5-109.48,59.44-152.65C131.09,42.01,195.91,20.5,250.78,20.68c.07.57.21,1.18.21,1.78-.08,16.3-.17,32.61-.26,48.91-.07,12.39-.16,24.78-.2,37.16-.05,15.35-.07,30.69-.07,46.04,0,1.55-.31,2.97-.87,4.41-4.08,10.5-8.15,21.03-13.63,30.88-14.79,26.6-36.27,45.3-65.5,54.63-3.12.99-6.26,1.92-9.41,2.79-1.18.33-2.35.53-2.41,2.25-45.07-1.64-89.97-1.16-135.36-1.23Z"/>
  <path onClick={() => props.onFill(5)} fill={props.fillColors[5]} d="m250.89,480.06v18.94c-20.86.2-41.26-2.38-61.34-7.44-43.71-11.02-81.38-32.7-113.05-64.32,4.54-4.54,8.97-8.97,13.56-13.57,44.57,42.81,98.25,65.07,160.83,66.38Z"/>
  <path onClick={() => props.onFill(6)} fill={props.fillColors[6]} d="m88.03,411.47c-4.62,4.62-9.07,9.06-13.64,13.64-23.6-24.29-41.72-52.34-54.05-84.11-11.27-29.05-16.85-59.16-16.9-90.53h17.94c.24,30.2,6.04,59.31,17.62,87.2,11.56,27.83,27.99,52.49,49.03,73.8Z"/>
  <path onClick={() => props.onFill(7)} fill={props.fillColors[7]} d="m253.03,498.94c-.06-.54-.14-.92-.14-1.3,0-5.75,0-11.49,0-17.56,62.22-1.24,115.79-23.22,160.53-65.87,3.95,3.94,7.88,7.87,12.04,12.01-16.69,17.04-35.8,31.58-57.17,43.1-39.35,21.2-80.96,29.62-115.25,29.62Z"/>
  <path onClick={() => props.onFill(8)} fill={props.fillColors[8]} d="m21.24,248.29H3.43c.21-31.42,6-61.64,17.52-90.75,12.38-31.29,30.4-58.92,53.71-82.81,4.34,4.34,8.49,8.5,12.74,12.75C44.61,132.26,22.53,185.87,21.24,248.29Z"/>
  <path onClick={() => props.onFill(9)} fill={props.fillColors[9]} d="m250.83,1.5v17.04c-30.21.24-59.39,6.06-87.34,17.68-27.91,11.61-52.61,28.12-73.9,49.24-4.29-4.29-8.45-8.45-12.83-12.83,14.77-14.81,31.4-27.89,50.04-38.29C165.23,12.9,206.32,1.4,250.83,1.5Z"/>
  <path onClick={() => props.onFill(10)} fill={props.fillColors[10]} d="m413.93,84.97c-21.44-21.12-46.12-37.5-73.98-48.99-27.75-11.45-56.7-17.23-86.89-17.42V1.52c15.8.12,31.31,1.79,46.66,4.92,28.34,5.78,54.88,16.22,79.61,31.2,16.8,10.17,32.24,22.1,45.88,36.04-3.76,3.76-7.41,7.42-11.27,11.29Z"/>
  <path onClick={() => props.onFill(11)} fill={props.fillColors[11]} d="m427.26,424.41c-3.66-3.67-7.16-7.17-10.65-10.68-.39-.39-.76-.81-1.29-1.38,43.64-45.09,66.11-99.01,67.47-162.02,4.81-.1,9.62,0,14.74.07-.04,30.37-5.28,59.57-15.85,87.81-12.15,32.46-30.32,61.11-54.42,86.19Z"/>
  <path onClick={() => props.onFill(12)} fill={props.fillColors[12]} d="m497.55,248.3h-14.75c-1.26-62.66-23.57-116.56-66.8-161.46,3.74-3.74,7.39-7.38,11.2-11.19,21.97,22.74,39.17,48.84,51.34,78.24,12.48,30.16,18.73,61.57,19.02,94.4Z"/>
  <path onClick={() => props.onFill(13)} fill={props.fillColors[13]} d="m251.58,336.81c-8.05-19.71-16.26-39.03-31.44-54.63,10.84-11.12,21.54-22.09,32.46-33.29,10.32,10.68,20.55,21.27,30.89,31.97-.43.54-.77,1.06-1.19,1.51-9.07,9.74-16.19,20.77-21.56,32.93-2.87,6.5-5.55,13.08-8.32,19.62-.27.63-.56,1.26-.85,1.89Z"/>
  <path onClick={() => props.onFill(14)} fill={props.fillColors[14]} d="m220.2,215.32c10.38,10.75,20.58,21.31,31.01,32.12-10.73,11.01-21.4,21.96-32.29,33.12-7.66-7.63-15.92-14.34-25.2-19.76-9.23-5.39-19.15-9.02-29.45-12.17,21.77-5.73,40.31-16.72,55.92-33.31Z"/>
  <path onClick={() => props.onFill(15)} fill={props.fillColors[15]} d="m282.56,215.24c-10.04,10.32-19.89,20.44-29.94,30.76-10.38-10.76-20.67-21.41-31.01-32.12.6-.77,1.06-1.41,1.58-2,6.9-7.85,12.61-16.52,17.07-25.93,3.49-7.35,6.48-14.93,9.68-22.42.43-1,.82-2.03,1.39-3.46.48,1,.8,1.6,1.07,2.22,3.32,7.78,6.4,15.67,10,23.32,4.51,9.57,10.29,18.38,17.33,26.33.9,1.01,1.76,2.05,2.83,3.29Z"/>
  <path onClick={() => props.onFill(16)} fill={props.fillColors[16]} d="m254.02,247.44c10.03-10.31,19.83-20.38,29.79-30.61.61.53,1.22.99,1.76,1.53,10.55,10.47,22.53,18.86,36.33,24.45,4.8,1.94,9.79,3.4,14.7,5.08.6.2,1.21.39,1.86.78-9.88,3.08-19.59,6.48-28.57,11.67-9.05,5.23-17.23,11.56-24.87,19.17-10.36-10.63-20.56-21.26-30.99-32.06Z"/>




    </g>
            </svg>
        </div>
    )
}