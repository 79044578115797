import React from 'react';
import './Fleur';


export default function Fleur(props) {
    
    return (
        <div className={`fleur-container ${props.size}`}> 
 
            <svg  xmlns="http://www.w3.org/2000/svg" id={props.id} width="500" height="500" viewBox="0 0 500 500">
                
            <pattern id="01_yellow" width="100%" height="100%">
                      <image xlinkHref="/01_yellow.jpg"/>
              </pattern>
          <pattern id="02_lightAmber" width="100%" height="100%">
              <image xlinkHref="/02_lightAmber.jpg"/>
          </pattern>
          <pattern id="03_darkAmber" width="100%" height="100%">
              <image xlinkHref="/03_darkAmber.jpg"/>
          </pattern>
          <pattern id="04_cherry" width="100%" height="100%">
              <image xlinkHref="/04_cherry.jpg"/>
          </pattern>
          <pattern id="05_redAmber" width="100%" height="100%">
              <image xlinkHref="/05_redAmber.jpg"/>
          </pattern>    
          <pattern id="06_orange" width="100%" height="100%">
              <image xlinkHref="/06_orange.jpg"/>
          </pattern>
          <pattern id="07_green" width="100%" height="100%">
              <image xlinkHref="/07_green.jpg"/>
          </pattern>
          <pattern id="08_olive" width="100%" height="100%">
              <image xlinkHref="/08_olive.jpg"/>
          </pattern>
          <pattern id="09_purple" width="100%" height="100%">
              <image xlinkHref="/09_purple.jpg"/>
          </pattern>
          <pattern id="10_lightBlue" width="100%" height="100%">
              <image xlinkHref="/10_lightBlue.jpg"/>
          </pattern>  
          <pattern id="11_aquaBlue" width="100%" height="100%">
              <image xlinkHref="/11_aquaBlue.jpg"/>
          </pattern>
          <pattern id="12_oceanBlue" width="100%" height="100%">
              <image xlinkHref="/12_oceanBlue.jpg"/>
          </pattern>
          <pattern id="13_cobaltBlue" width="100%" height="100%">
              <image xlinkHref="/13_cobaltBlue.jpg"/>
          </pattern>
          <pattern id="14_tealBlue" width="100%" height="100%">
              <image xlinkHref="/14_tealBlue.jpg"/>
          </pattern>
          <pattern id="15_clear" width="100%" height="100%">
              <image xlinkHref="/15_clear.jpg"/>
          </pattern>  
          <pattern id="16_granite" width="100%" height="100%">
              <image xlinkHref="/16_granite.jpg"/>
          </pattern>
          <pattern id="17_crackle" width="100%" height="100%">
              <image xlinkHref="/17_crackle.jpg"/>
          </pattern>
          <pattern id="18_iridescent" width="100%" height="100%">
              <image xlinkHref="/18_iridescent.jpg"/>
          </pattern>
          <pattern id="19_taffy" width="100%" height="100%">
              <image xlinkHref="/19_taffy.jpg"/>
          </pattern>
          <pattern id="20_seedy" width="100%" height="100%">
              <image xlinkHref="/20_seedy.jpg"/>
          </pattern> 
          <pattern id="21_frosted" width="100%" height="100%">
              <image xlinkHref="/21_frosted.jpg"/>
          </pattern>                                      
            <g className="hummingbird-image" >

<path id="background" fill="black"  d="m0,500V0h500v500H0Z"/>
  
  
  <path onClick={() => props.onFill(1)} fill={props.fillColors[1]} d="m248.6,32.97c25.56-.27,50.17,4.04,74.04,12.51,35.27,12.52,63.64,33.37,80.9,51.54-.46.51-.92,1.03-1.41,1.53-13.93,14.03-27.88,28.06-41.81,42.09-9.29,9.36-18.56,18.73-27.84,28.09-9.17,9.25-18.33,18.51-27.52,27.73-2.06,2.06-4.26,3.98-6.42,5.99-.14-1.48-.35-3.16-.46-4.84-.66-9.72-3.89-18.63-9.02-26.79-4.72-7.53-9.77-14.85-14.88-22.13-3.89-5.54-7.28-11.37-10.41-17.34-4.86-9.28-9.39-18.73-14.11-28.08-.63-1.25-.9-2.49-.88-3.87.05-3.89.03-7.78.01-11.68-.06-12.31-.14-24.62-.19-36.92-.03-5.79,0-11.59,0-17.82Z"/>
  <path onClick={() => props.onFill(2)} fill={props.fillColors[2]} d="m292.94,224.19c.75-1.75,1.28-2.99,1.8-4.22,1.79-4.19,3.05-8.52,3.3-13.08.06-1.16.69-1.88,1.43-2.63,5.65-5.69,11.28-11.41,16.93-17.1,13.99-14.1,27.98-28.18,41.97-42.28,9.18-9.25,18.35-18.5,27.52-27.75,5.7-5.76,11.39-11.53,17.09-17.28.55-.55,1.14-1.07,1.86-1.74,20,20.41,35.32,43.74,46.06,70.04,10.67,26.12,16.02,53.34,16.14,81.8-1.93.08-3.73.23-5.54.23-17.71.07-35.43.09-53.14.18-7.22.04-14.45.2-21.67.3-.71.01-1.42,0-1.99,0-1.66-2.92-3.08-5.77-4.82-8.41-4.52-6.82-10.39-12.08-17.91-15.54-8.72-4.02-17.73-6.86-27.29-7.93-13.72-1.55-27.05.02-39.99,4.87-.42.16-.85.27-1.76.55Z"/>
  <path onClick={() => props.onFill(3)} fill={props.fillColors[3]} d="m94.45,98.41c22.72-23.15,49.19-40.29,79.37-51.8,21.03-8.03,60.67-15.4,73.13-13.35.04.73.11,1.5.11,2.27.01,4.21,0,8.42,0,12.63.06,14.13.13,28.26.2,42.4.01,2.78.03,5.56.13,8.33.06,1.55-.24,2.98-.88,4.37-4.07,8.87-7.94,17.83-12.23,26.58-3.88,7.91-8.73,15.28-13.85,22.47-3.91,5.49-7.62,11.13-11.31,16.78-5.7,8.71-9.12,18.27-10.14,28.64-.19,1.88-.03,3.8-.03,5.62-.29.11-.36.15-.44.16-.07.01-.18.02-.22-.02-1.87-1.83-3.77-3.63-5.61-5.49-32.72-33.16-65.44-66.33-98.24-99.58Z"/>
  <path onClick={() => props.onFill(4)} fill={props.fillColors[4]} d="m204.17,224.16c-2.52-.83-4.6-1.53-6.7-2.2-9.71-3.08-19.67-4.17-29.82-3.63-13.81.73-26.67,4.64-38.52,11.72-4.4,2.63-7.87,6.43-10.75,10.65-2.14,3.12-3.93,6.49-6.08,10.11-26.24-.25-52.87-.5-79.75-.76.04-24.55,4.1-48.1,12.02-70.96,10.92-31.51,28.72-58.99,48.79-79.41.56.52,1.15,1.03,1.69,1.58,11.86,11.95,23.72,23.89,35.57,35.85,6.48,6.54,12.94,13.11,19.41,19.66,6.7,6.78,13.4,13.55,20.1,20.33,6.53,6.61,13.06,13.22,19.58,19.83,2.79,2.83,5.57,5.66,8.29,8.55.56.6,1.1,1.45,1.17,2.23.49,5.21,2.17,10.05,4.36,14.76.2.42.33.87.65,1.69Z"/>
  <path onClick={() => props.onFill(5)} fill={props.fillColors[5]} d="m279.51,355.24c9.26,10.27,19.96,15.76,33.86,12.04,6.56-1.76,11.99-5.32,16.3-10.59,4.24-5.19,6.48-11.18,7.1-18.16.75.64,1.29,1.05,1.77,1.52,4.85,4.81,9.67,9.65,14.52,14.46,7.5,7.44,15.02,14.86,22.53,22.29,6.49,6.42,12.99,12.85,19.49,19.26,2.6,2.56,5.21,5.12,7.85,7.64.57.54,1.29.93,2.1,1.51-18.29,17.9-38.62,31.82-61.32,42.21-29.78,13.63-61.09,20.38-94.15,20.21-.04-.94-.11-1.78-.11-2.62,0-8.58,0-17.16,0-25.74-.02-11.2-.04-22.4-.11-33.6-.01-2.01.25-3.93.89-5.84,1.94-5.79,3.61-11.67,5.74-17.39,3.09-8.28,8.29-15.13,15.21-20.64,2.72-2.17,5.48-4.31,8.35-6.55Z"/>
  <path onClick={() => props.onFill(6)} fill={props.fillColors[6]} d="m217.71,355.36c8.01,5.99,15.92,11.9,20.57,20.95,1.77,3.45,3.35,7.02,4.66,10.66,1.63,4.55,2.92,9.22,4.3,13.86.33,1.12.57,2.32.58,3.48.09,20.09.13,40.19.18,60.28,0,.87,0,1.74,0,2.58-6.74,1.34-36.71-1.86-56.37-7.49-18.41-5.27-35.95-12.46-52.49-22.09-16.54-9.63-31.55-21.23-45.17-34.96,22.13-22.41,44.13-44.69,66.35-67.19.42,14.77,6.85,25.26,20.33,30.74,9.75,3.97,23.32,4.45,37.05-10.82Z"/>
  <path onClick={() => props.onFill(7)} fill={props.fillColors[7]} d="m385.04,252.02c27.46-.17,54.59-.33,81.95-.5.95,25-2.75,49.03-10.65,72.46-11.88,35.2-33.07,63.72-50.58,80.28-.51-.46-1.05-.91-1.55-1.41-7.63-7.53-15.25-15.06-22.88-22.6-7.51-7.42-15.01-14.86-22.52-22.28-7.12-7.03-14.25-14.04-21.35-21.09-.4-.4-.75-1.05-.79-1.6-.41-5.03-2.34-9.51-4.83-13.8-.59-1.01-1.13-2.06-1.9-3.45,2.45-.18,4.54-.46,6.63-.46,5.01,0,10-.18,14.86-1.51,16.95-4.64,28.44-15.24,34.19-31.84,3.52-10.15,3.25-20.4-.08-30.59-.12-.37-.23-.74-.49-1.62Z"/>
  <path onClick={() => props.onFill(8)} fill={props.fillColors[8]} d="m92.97,401.61c-22.26-22.92-38.56-49.14-48.92-78.97-7.94-22.87-11.88-46.45-11.46-71.01,26.53-.07,52.85.57,79.32.63-.64,2.58-1.36,4.93-1.8,7.32-3,16.23.96,30.56,12.18,42.67,9.57,10.33,21.65,15.16,35.67,15.32,2.92.03,5.85.21,8.83.32.08,1.42-.71,2.27-1.23,3.2-1.9,3.4-3.63,6.86-4.5,10.71-.18.78-.82,1.53-1.41,2.13-6.62,6.74-13.27,13.46-19.92,20.18-6.92,7-13.84,14.01-20.76,21.01-6.2,6.27-12.4,12.54-18.58,18.82-2.44,2.48-4.84,5-7.42,7.67Z"/>
  <path onClick={() => props.onFill(9)} fill={props.fillColors[9]} d="m264.51,297.56h-31.75c0-1.47-.02-2.8,0-4.13.13-7.01-.51-13.95-1.95-20.8-3.47-16.46-10.46-31.25-21.54-43.97-3.44-3.95-5.46-8.55-6.97-13.4-3.55-11.39-2.16-22.56,2-33.53,2.76-7.27,7.06-13.66,11.34-20.06,2.74-4.09,5.62-8.08,8.48-12.09,5.91-8.3,10.81-17.2,15.15-26.4,2.54-5.38,4.85-10.87,7.26-16.31.44-1,.91-1.99,1.54-3.39.59,1.13.99,1.87,1.36,2.62,3.58,7.17,7.17,14.34,10.73,21.52,5.09,10.27,11.18,19.91,17.89,29.19,4.51,6.24,8.87,12.6,12.36,19.49,3.48,6.86,5.55,14.1,6.26,21.8.87,9.52-1.38,18.26-5.8,26.55-.91,1.72-2.37,3.16-3.65,4.67-5.7,6.7-10.27,14.11-13.95,22.07-4.15,8.99-6.85,18.43-8.11,28.24-.62,4.79-1.02,9.62-.68,14.46.08,1.09.01,2.19.01,3.44Z"/>
  <path onClick={() => props.onFill(10)} fill={props.fillColors[10]} d="m156.48,315.91c-4.05.2-7.73-.43-11.28-1.52-18.59-5.68-30.13-18.08-33.84-37.14-2.65-13.63.9-26.07,9.29-37.16,4.91-6.49,11.48-10.56,18.81-13.68,8.39-3.57,17.13-5.81,26.19-6.51,13.24-1.03,26.1.89,38.41,6.02.84.35,1.69.91,2.3,1.59,11.16,12.5,18.71,26.88,22.49,43.22,1.98,8.55,2.74,17.21,2.38,25.97,0,.23-.09.46-.15.75-.29.09-.59.25-.89.25-4.37.02-8.74-.02-13.1.06-1.27.02-1.57-.79-1.99-1.66-3.11-6.44-7.81-11.32-14.24-14.5-19.37-9.58-41.76,2.82-44.49,23.44-.37,2.79-.06,5.67-.05,8.51,0,.71.09,1.41.15,2.36Z"/>
  <path onClick={() => props.onFill(11)} fill={props.fillColors[11]} d="m340.53,315.81c1.05-5.15.82-10.16-.6-15.12-6.11-21.42-32.69-29.5-49.73-15.14-3.52,2.97-6.23,6.53-8.19,10.67-.39.83-.72,1.54-1.83,1.52-4.52-.06-9.05-.06-13.57-.09-.14,0-.28-.14-.44-.22,0-4.42-.26-8.87.06-13.27.59-8.33,2.42-16.45,5.26-24.31,4.36-12.08,10.77-22.94,19.46-32.43.56-.62,1.31-1.16,2.08-1.48,11.54-4.74,23.55-6.8,36.02-6.15,13.23.69,25.59,4.29,37.06,10.88,5.49,3.16,9.55,7.82,12.95,13.05,5.64,8.66,8.24,18.25,7.42,28.5-1.22,15.3-8.26,27.43-21.03,36.09-6.5,4.41-13.77,6.72-21.53,7.62-.71.08-1.43.05-2.14.03-.38,0-.76-.09-1.25-.15Z"/>
  <path onClick={() => props.onFill(12)} fill={props.fillColors[12]} d="m93.15,97.09c-19.68-21.35-39.35-42.69-58.96-63.96.12-.29.14-.36.19-.43.04-.06.1-.13.16-.14.39-.03.79-.07,1.18-.07,62.02.14,124.05.28,186.09.56-7.96,1.21-15.87,2.68-23.69,4.57-7.87,1.9-15.6,4.28-23.21,7.03-7.69,2.78-15.19,6.02-22.5,9.67-7.25,3.62-14.29,7.61-21.09,12.01-6.87,4.45-13.57,9.15-19.79,14.46-6.14,5.25-12.11,10.71-18.39,16.29Z"/>
  <path onClick={() => props.onFill(13)} fill={props.fillColors[13]} d="m221.7,467.41H33.66c19.96-21.39,39.51-42.36,59.3-63.57,11.71,11.54,24.26,21.8,38.03,30.54,13.73,8.71,28.22,15.85,43.49,21.39,15.32,5.56,31.08,9.37,47.22,11.64Z"/>
  <path onClick={() => props.onFill(14)} fill={props.fillColors[14]} d="m405.15,96.17c-35.9-35.39-78.19-56.18-127.36-63.36,1.19,0,2.38,0,3.57,0,34.71,0,69.41.02,104.12,0,16.04-.01,32.08-.13,48.12-.19,9.93-.04,19.85-.08,29.78-.12.78,0,1.57,0,2.71,0-.45.65-.67,1.11-1.01,1.46-6.14,6.32-12.29,12.63-18.45,18.94-9.53,9.78-19.07,19.55-28.59,29.33-3.76,3.87-7.5,7.76-11.22,11.67-.6.63-1.04,1.41-1.67,2.28Z"/>
  <path onClick={() => props.onFill(15)} fill={props.fillColors[15]} d="m467.56,34.35c-.15,62.78-.3,125.56-.45,188.35-6.93-48.44-27.13-90.4-61.23-125.8,20.46-20.98,40.83-41.88,61.2-62.77l.48.22Z"/>
  <path onClick={() => props.onFill(16)} fill={props.fillColors[16]} d="m279.96,467.64c48.31-7.09,90.38-27,125.41-60.56,20.26,20,40.39,39.88,60.53,59.77l-.12.49c-.73.03-1.46.08-2.19.08-16.28.04-32.55.11-48.83.12-32.24.02-64.47,0-96.71,0-11.59,0-23.18.06-34.77.09-1.11,0-2.22,0-3.33,0Z"/>
  <path onClick={() => props.onFill(17)} fill={props.fillColors[17]} d="m33.08,34.16c19.76,21.44,39.41,42.75,59.21,64.23-32.98,34.84-52.69,75.98-59.45,123.47-.53-3.21-.35-185.23.24-187.7Z"/>
  <path onClick={() => props.onFill(18)} fill={props.fillColors[18]} d="m32.57,282.71c3.61,22.54,10.13,44.15,20.23,64.67,10.07,20.46,23.14,38.78,39.13,55.35-19.63,21.04-39.13,41.96-58.92,63.17-.15-.89-.31-1.38-.31-1.87-.06-4.05-.12-8.1-.12-12.15,0-55.36,0-110.72,0-166.08v-3.09Z"/>
  <path onClick={() => props.onFill(19)} fill={props.fillColors[19]} d="m406.94,405.55c32.88-33.24,53.01-72.76,60.18-118.86.14,59.68.29,119.36.43,179.68-20.44-20.51-40.47-40.6-60.62-60.82Z"/>
  <path onClick={() => props.onFill(20)} fill={props.fillColors[20]} d="m497.46,321.72h-28.69v-143.43c1.81-.5,25.83-.61,28.69-.13v143.57Z"/>
  <path onClick={() => props.onFill(21)} fill={props.fillColors[21]} d="m178.38,31.22V2.61h143.45c.48,1.74.57,25.87.1,28.61h-143.54Z"/>
  <path onClick={() => props.onFill(22)} fill={props.fillColors[22]} d="m497.5,323.55v143.71h-28.36c-.04-.93-.11-1.85-.11-2.76,0-18.43.03-36.86-.01-55.3-.07-27.56-.2-55.13-.31-82.69,0-.93,0-1.87,0-2.96h28.8Z"/>
  <path onClick={() => props.onFill(23)} fill={props.fillColors[23]} d="m32.85,30.98V2.58h143.69v28.78c-47.96-.13-95.75-.25-143.69-.38Z"/>
  <path onClick={() => props.onFill(24)} fill={props.fillColors[24]} d="m468.63,176.32c.15-47.99.31-95.76.46-143.68h28.35v143.68h-28.8Z"/>
  <path onClick={() => props.onFill(25)} fill={props.fillColors[25]} d="m467.46,2.63v28.43c-1.03,0-1.95,0-2.88,0-23.75,0-47.5-.02-71.24,0-15.88.02-31.76.12-47.64.19-6.43.03-12.86.08-19.3.11-.78,0-1.57,0-2.3,0-.54-1.79-.72-25.03-.24-28.75h143.61Z"/>
  <path onClick={() => props.onFill(26)} fill={props.fillColors[26]} d="m2.8,323.56h27.93c.47,2.09.63,140.72.16,143.72H2.94c-.48-1.79-.62-140.72-.14-143.72Z"/>
  <path onClick={() => props.onFill(27)} fill={props.fillColors[27]} d="m33.08,497.46c-.51-1.82-.58-25.92-.09-28.37h143.27c.58,1.88.64,26.06.08,28.37H33.08Z"/>
  <path onClick={() => props.onFill(28)} fill={props.fillColors[28]} d="m2.7,178.01h27.93c.54,1.65.74,139.77.2,143.67H2.7v-143.67Z"/>
  <path onClick={() => props.onFill(29)} fill={props.fillColors[29]} d="m31,176.31H2.66V32.9c1.76-.51,25.7-.6,28.34-.1v143.51Z"/>
  <path onClick={() => props.onFill(30)} fill={props.fillColors[30]} d="m178.35,497.17v-27.95h143.72v27.92s-.1.11-.15.16c-.06.05-.11.14-.18.15-.31.05-.63.12-.94.12-47.18,0-94.36,0-141.53,0-.16,0-.33,0-.47-.07-.14-.06-.25-.18-.44-.33Z"/>
  <path onClick={() => props.onFill(31)} fill={props.fillColors[31]} d="m467.45,469.17v28.24h-143.67v-28.24h143.67Z"/>
  <path onClick={() => props.onFill(32)} fill={props.fillColors[32]} d="m278.6,354.01c-2.34,1.85-4.48,3.6-6.69,5.26-9.54,7.15-15.84,16.52-19.31,27.87-1.07,3.49-2.16,6.97-3.25,10.44-.18.57-.42,1.12-.82,2.15-.78-2.47-1.42-4.49-2.06-6.52-1.52-4.84-2.94-9.69-5.1-14.32-3.53-7.58-8.75-13.74-15.21-18.93-2.41-1.93-4.86-3.8-7.52-5.88,3.67-4.69,6.89-9.56,9.31-14.89,2.4-5.26,4.62-10.6,6.95-15.97h27.35c4.72,10.53,8.45,21.68,16.34,30.78Z"/>
  <path onClick={() => props.onFill(33)} fill={props.fillColors[33]} d="m168.81,318.38c2.55.24,4.85.93,6.88,2.14,3.68,2.18,7.22,4.59,10.83,6.89,2.14,1.36,4.24,2.79,6.44,4.05,4.82,2.77,9.59,2.5,14.07-.73,2.3-1.66,4.47-3.55,6.53-5.51,1.4-1.33,2.79-2.06,4.81-2,3.88.12,7.77-.05,11.66-.1.95-.01,1.89,0,2.91,0,0,.36.07.61-.01.79-2.59,5.87-4.99,11.85-7.86,17.58-3.77,7.51-8.82,14.11-15.55,19.3-5.39,4.16-11.45,6.5-18.31,6.16-15.14-.74-26.8-11.41-28.98-25.79-.84-5.56.18-10.91,2.78-15.91,1.2-2.31,2.52-4.56,3.79-6.86Z"/>
  <path onClick={() => props.onFill(34)} fill={props.fillColors[34]} d="m264.14,323.23c.82-.04,1.51-.12,2.2-.11,4.52.04,9.04.13,13.57.13,1.11,0,1.91.35,2.73,1.1,2.28,2.08,4.56,4.19,7.02,6.04,4.62,3.47,9.57,4.01,14.66.99,2.38-1.41,4.7-2.94,7.02-4.45,2.92-1.9,5.78-3.91,8.76-5.73,2.45-1.49,5.14-2.43,8.03-2.84,6.36,9.1,9.52,18.71,4.97,29.52-4.52,10.73-12.64,16.98-24.16,18.77-8.77,1.36-16.23-1.58-22.85-7.1-5.88-4.9-10.38-10.95-13.8-17.71-2.89-5.72-5.28-11.7-7.89-17.56-.12-.27-.15-.58-.25-1.04Z"/>
  <path onClick={() => props.onFill(35)} fill={props.fillColors[35]} d="m214.25,298.16c-1.39,2.4-2.85,4.67-4.04,7.07-.73,1.47-1.16,3.11-1.52,4.73-.97,4.32.23,7.98,3.59,10.9.6.52,1.18,1.05,1.89,1.68-3.05,3.15-6.04,6.06-9.77,8.06-3.43,1.84-6.89,1.69-10.23-.19-2.34-1.32-4.58-2.83-6.85-4.28-3.68-2.33-7.29-4.79-11.05-6.97-3.05-1.77-6.5-2.45-10.01-2.7-2.6-.19-5.2-.33-7.78-.49-3.51-10.6,1.83-28.27,18.44-34.34,16.38-5.99,32.63,3.54,37.34,16.54Z"/>
  <path onClick={() => props.onFill(36)} fill={props.fillColors[36]} d="m338.78,315.95c-2.55.17-4.91.37-7.28.47-5.21.22-9.95,1.87-14.28,4.72-3.45,2.27-6.81,4.66-10.25,6.94-1.39.92-2.85,1.72-4.33,2.5-3.62,1.91-7.19,1.66-10.64-.43-3.28-1.98-6.09-4.54-8.88-7.41.59-.61,1.08-1.19,1.65-1.69,3.94-3.5,4.88-7.76,3.4-12.74-.92-3.09-2.4-5.86-4.3-8.43-.33-.45-.64-.91-.94-1.34,3.19-10.87,17.76-22.33,34.94-17.68,16.77,4.54,24.57,21.53,20.92,35.1Z"/>
  <path onClick={() => props.onFill(37)} fill={props.fillColors[37]} d="m248.64,299.28c10.24,0,20.49.03,30.73-.04,1.6-.01,2.62.44,3.52,1.78,2.1,3.13,3.78,6.38,4.33,10.19.35,2.43-.21,4.54-1.52,6.52-.18.26-.37.52-.57.76-1.86,2.35-4.03,3.49-7.3,3.44-16.43-.25-32.86-.21-49.3-.24-3.73,0-7.46.06-11.19.17-1.28.04-2.32-.23-3.15-1.24-.35-.42-.84-.72-1.24-1.11-2.63-2.59-3.59-5.65-2.84-9.32.78-3.75,2.59-6.94,4.8-9.99.77-1.06,1.75-.91,2.75-.92,9.69,0,19.37,0,29.06,0,.64,0,1.27,0,1.91,0Z"/>
  <path onClick={() => props.onFill(38)} fill={props.fillColors[38]} d="m497.47,30.94h-28.31V2.84c1.72-.47,25.6-.56,28.31-.09v28.19Z"/>
  <path onClick={() => props.onFill(39)} fill={props.fillColors[39]} d="m30.99,30.94H2.68V2.85c1.71-.49,25.43-.6,28.31-.11v28.2Z"/>
  <path onClick={() => props.onFill(40)} fill={props.fillColors[40]} d="m31.02,469.12v28.31H2.94c-.5-1.71-.61-25.48-.12-28.31h28.19Z"/>
  <path onClick={() => props.onFill(41)} fill={props.fillColors[41]} d="m497.45,497.45h-28.26v-28.08c1.54-.48,24.5-.65,28.26-.18v28.26Z"/>
           
    </g>
            </svg>
        </div>
    )
}