// AllUsersGallery.js
import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import AllImagesCard from './AllImagesCard';
import LoadingSpinner from '../LoadingSpinner';
//import styles from './AllUsersGallery.module.css'; // REMOVED styles

export default function AllUsersGallery() {
  const [allImages, setAllImages] = useState([]);
  const [images, setImages] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [userImagesLoading, setUserImagesLoading] = useState(true); // loading state for all images
  const [templateImagesLoading, setTemplateImagesLoading] = useState(true); // loading state for images
  const { token } = useSelector(state => state.auth);

  // --- Fetch User Colored Images ---
  useEffect(() => {
    const fetchAllImages = async () => {
      if (!token) {
        setUserImagesLoading(false);
        return;
      }

      try {
        const response = await fetch('https://test-api.stainedglassusa.com/user_colored_images', {
          headers: {
            'Authorization': `bearer ${token}`,
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log("Fetched user colored images data:", data);
        setAllImages(data);
      } catch (error) {
        console.error("Error fetching all images:", error);
      } finally {
          setUserImagesLoading(false);
      }
    };

    fetchAllImages();

    const intervalId = setInterval(() => {
      fetchAllImages();
    }, 3000);

    return () => clearInterval(intervalId);
  }, [token]);

    // --- Fetch Template Image Data ---
    useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch('https://test-api.stainedglassusa.com/images');
        if (!response.ok) {
          throw new Error("Failed to get images");
        }
        const data = await response.json();
        console.log("Fetched template images:", data);
        setImages(data);
      }
      catch(error){
        console.error("Failed to get all images", error);
      } finally {
        setTemplateImagesLoading(false);
      }
    };

    fetchImages();
  }, []);

  // Create imageId to component name map
  const imageMap = useMemo(() => {
    const map = {};
    images.forEach(img => {
      map[img.id] = img.component;
    });
    return map;
  }, [images]);


  // --- Filtering and Sorting ---
  const filteredAndSortedImages = useMemo(() => {
    // 1. Sort by user_image.id:
    const sortedImages = [...allImages].sort((a, b) => a.user_image.id - b.user_image.id);

    // 2. Filter based on searchTerm:
    const filteredImages = sortedImages.filter(imageData => {
      if (!imageData || !imageData.user_image || !imageData.user) {
        return false;
      }

      const userImageIdMatch = String(imageData.user_image.id).includes(searchTerm); // Search by user_image.id
      const usernameMatch = imageData.user.username.toLowerCase().includes(searchTerm.toLowerCase());
      return userImageIdMatch || usernameMatch;
    });

    return filteredImages;
  }, [allImages, searchTerm]); // Depend on allImages and searchTerm


  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

    const loading = userImagesLoading || templateImagesLoading;


  return (
    <div>
      <h1>All Users' Gallery</h1>
<div className="search-bar"> {/* Removed styles */}
      <input
        id="search-input"
        type="text"
        placeholder="Search by User Image ID or Username"
        value={searchTerm}
        onChange={handleSearchChange}
        //className={styles.searchInput} // Removed styling
      />
      </div>

      {loading ? (
        <LoadingSpinner />
      ) : filteredAndSortedImages.length === 0 ? (
        <LoadingSpinner />
      ) : (
        <div id="all-users-gallery"> {/* Removed styles */}
          {filteredAndSortedImages.map((imageData) => {
            if (!imageData.user_image || !imageMap[imageData.image_id]) {
              console.error("Skipping image data due to missing data or component mapping:", imageData);
              return null;
            }
            return (
              <AllImagesCard
                key={imageData.user_image.id}
                userImage={imageData.user_image}
                imageId={imageData.image_id}
                user={imageData.user}
                imageComponent={imageMap[imageData.image_id]}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}