// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-container {
    height: 15rem;
    width: 15rem;
    overflow: hidden;
}
.hermosa-image {
    max-width: -moz-fit-content;
    max-width: fit-content;
    transform-origin: -10px 70px;
}

.hermosa-container.large {
    width: 704px; 
    height: 513px;
}

.hermosa-container.large .hermosa-image {
    transform: scale(.55); 
    transform-origin: 0px 0px;
}

@media only screen 
  and (max-width: 490px) {
    .hermosa-container.large {
        width: 351px; 
        height: 252px;
    }
    
    .hermosa-container.large .hermosa-image {
        transform: scale(.27); 
        transform-origin: 0px 0px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Images/Hermosasquare.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,gBAAgB;AACpB;AACA;IACI,2BAAsB;IAAtB,sBAAsB;IACtB,4BAA4B;AAChC;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,qBAAqB;IACrB,yBAAyB;AAC7B;;AAEA;;IAEI;QACI,YAAY;QACZ,aAAa;IACjB;;IAEA;QACI,qBAAqB;QACrB,yBAAyB;IAC7B;AACJ","sourcesContent":[".image-container {\r\n    height: 15rem;\r\n    width: 15rem;\r\n    overflow: hidden;\r\n}\r\n.hermosa-image {\r\n    max-width: fit-content;\r\n    transform-origin: -10px 70px;\r\n}\r\n\r\n.hermosa-container.large {\r\n    width: 704px; \r\n    height: 513px;\r\n}\r\n\r\n.hermosa-container.large .hermosa-image {\r\n    transform: scale(.55); \r\n    transform-origin: 0px 0px;\r\n}\r\n\r\n@media only screen \r\n  and (max-width: 490px) {\r\n    .hermosa-container.large {\r\n        width: 351px; \r\n        height: 252px;\r\n    }\r\n    \r\n    .hermosa-container.large .hermosa-image {\r\n        transform: scale(.27); \r\n        transform-origin: 0px 0px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
