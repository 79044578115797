import React, {useRef} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ColoredInImage from '../Images/ColoredInImage';
import swal from 'sweetalert';
import emailjs from '@emailjs/browser';


export default function MyImageCard(props) {

    const {user, token} = useSelector(state => state.auth);
    let image = props.userImage.image
    const history = useHistory();
    const form = useRef();
    



    const addOrder = (e) => {
        e.preventDefault();
        swal({
            title: "Do you Want to Place an Order?",
            text: "",
            icon: "info",
            buttons: true,
            dangerMode: false,
        })
        .then((willPlaceAnOrder) => {
            if (willPlaceAnOrder) {

                fetch(`https://test-api.stainedglassusa.com/user_images/${props.userImage.id}`,{
                    method: 'PATCH',
                    headers: {
                        'Access-Control-Allow-Origin':'*',
                        'Content-Type': 'application/json',
                        'Authorization': `bearer ${token}`},
                    body: JSON.stringify({id: props.userImage.id, cart: false, status: "Processing" })
                })
                .then(r => r.json())
                .then(console.log)   
                sendEmail(e)
                    swal("Order Placed", "Your Order has been placed correctly", "success");
                    history.push(`/mygallery`)  
                }        
            }
          );
    }



    const sendEmail = () => {
  
        emailjs.sendForm('service_vaf1y8q', 'template_pulszs3', form.current, 'STYLshGA6_JYfnjRI')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };




    const RemoveFromCart = (e) => {
        e.preventDefault();
        swal({
            title: "Do you Want to Remove this image to Cart?",
            text: "You can review all the details on the Cart Page",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willRemoveFromCart) => {
            if (willRemoveFromCart) {

                fetch(`https://test-api.stainedglassusa.com/user_images/${props.userImage.id}`,{
                    method: 'PATCH',
                    headers: {
                        'Access-Control-Allow-Origin':'*',
                        'Content-Type': 'application/json',
                        'Authorization': `bearer ${token}`},
                    body: JSON.stringify({id: props.userImage.id, cart: false, status: "coloring" })
                })
                .then(r => r.json())
                .then(console.log)   
                
                    swal("Image Removed", "This image has been removed from your cart", "success");
                    history.go(0)
                }        
            }
          );
    }


    return(

        
        props.userImage.cart === false ? 
        <h2>
        </h2>
        :
        
        <div className="image-card-cart">
            {props.user.username}
            <div className="cart-container">
            <div className="image-container-cart">
                <ColoredInImage className="new-image" component={image.component} onFill={()=>{}} fillColors={props.userImage.fill_colors}/>
            </div>  
            </div>            
            <div className="size">
                <p>{image.title} ! {props.userImage.id}</p>
            </div>
            <div> 
            <p>Size: {props.userImage.glass_width} x {props.userImage.glass_height}</p>
            <p>{props.userImage.cart}</p>
            </div>
            <div>
            <p>Total Cost: ${props.userImage.cost} </p>
            </div>
            <form ref={form} onSubmit={addOrder}><input type="submit" value="Place an Order" /><input type="hidden" value={user.username} name="name" /></form>
            <form onSubmit={RemoveFromCart}> <input type="submit" value="Remove from Cart" /></form>
        </div>
    )

}

