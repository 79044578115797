import React, { useState, useRef } from 'react';
import {useParams, useHistory} from 'react-router-dom';
import { useSelector } from 'react-redux';
import ColoredInImage from '../Images/ColoredInImage'
import ColorPalette from './ColorPalette';
import LoadingSpinner from '../LoadingSpinner';
import SelectedColor from './SelectedColor';
import InstalationOptions from "./InstalationOptions";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import IconButton from '@mui/material/IconButton';
import swal from 'sweetalert';
import emailjs from '@emailjs/browser';



export default function ColoringPage() {

    let {id} = useParams();
    const {token} = useSelector(state => state.auth);
    const [userImage, setUserImage] = useState({});
    const [color, setColor] = useState("white");
    const [fetched, setFetched] = useState(false);
    const [counter, setCounter] = useState(0)
    const form = useRef();


    const history = useHistory();

    const installation = [
        'For Interior Use', // Title as a plain string
        { value: 'easy', label: 'Easy / Minimal Install' },
        { value: 'hang', label: 'Hang / Suspend' },
        'For Exterior Use', // Another plain string for the title
        { value: 'insulated', label: 'Insulated in clear tempered glass (triple paned)' },
        { value: 'vinylframed', label: 'Vinyl Framed and insulated (triple paned)' },
        { value: 'woodframed', label: 'Wood Framed and insulated (triple paned)' },
      ];
      

    const [currentInstallation, setCurrentInstallation] = useState('easy')
  
    const changeInstallation = (newInstallation) => {
      setCounter(counter + 10)        
      setCurrentInstallation(newInstallation)

    }    

    if(!localStorage.getItem("token")){
        history.push("/login")
    }

    if (token && !fetched) {
        fetch(`https://test-api.stainedglassusa.com/user_images/${id}`,{
            headers: {'Access-Control-Allow-Origin':'*','Authorization': `bearer ${token}`}
        })
        .then(r => r.json())
        .then(data => {
            setUserImage(data)
            setFetched(true)
        })
        
    }
    
    if (!fetched) {
        return(
           <LoadingSpinner />
        )
    }

    if (fetched && !userImage.id) {
        return (
            <h1>Image not found</h1>
        )
    }


    const onFillColor = (i) => {
        let pastFillColors = userImage.fill_colors 
        let newFillColors = userImage.fill_colors.slice(0)
        newFillColors[i] = color 
        
        setUserImage({
            ...userImage,
            fill_colors: newFillColors,
            past_fill_colors: pastFillColors,
            past: true,
            future: false
        })


        fetch(`https://test-api.stainedglassusa.com/user_images/${id}`,{
            method: 'PATCH',
            headers: {
                'Access-Control-Allow-Origin':'*',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`},
            body: JSON.stringify({id: userImage.id, fill_colors: newFillColors, past_fill_colors: pastFillColors, past: true, future: false })
        })
        .then(r => r.json())
        .then(console.log)   
    }

    
    const onPastColor = (i) => {
        let futureFillColors = userImage.fill_colors 
        let pastFillColors = userImage.past_fill_colors 

        setUserImage({
            ...userImage,
            fill_colors: pastFillColors,
            future_fill_colors: futureFillColors,
            past: false,
            future: true
        })

        fetch(`https://test-api.stainedglassusa.com/user_images/${id}`,{
            method: 'PATCH',
            headers: {
                'Access-Control-Allow-Origin':'*',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`},
            body: JSON.stringify({id: userImage.id, fill_colors: pastFillColors, future_fill_colors: futureFillColors, past: false, future: true})
        })
        .then(r => r.json())
        .then(console.log)   
    }

    const onFutureColor = (i) => {
        let pastFillColors = userImage.fill_colors 
        let futureFillColors = userImage.future_fill_colors 

        setUserImage({
            ...userImage,
            fill_colors: futureFillColors,
            past_fill_colors: pastFillColors,
            past: true,
            future: false
        })

        fetch(`https://test-api.stainedglassusa.com/user_images/${id}`,{
            method: 'PATCH',
            headers: {
                'Access-Control-Allow-Origin':'*',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`},
            body: JSON.stringify({id: userImage.id, fill_colors: pastFillColors, future_fill_colors: futureFillColors, past: true, future: false})
        })
        .then(r => r.json())
        .then(console.log)   
    }



    const onChangeWidth =(e) => {
        setCounter(counter + 1)
        let newGlassWidth = e
        setUserImage({
            ...userImage,
            glass_width: newGlassWidth
        })

        fetch(`https://test-api.stainedglassusa.com/user_images/${id}`,{
            method: 'PATCH',
            headers: {
                'Access-Control-Allow-Origin':'*',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`},
            body: JSON.stringify({id: userImage.id, glass_width: newGlassWidth })
        })
        .then(r => r.json())
        .then(console.log)   

    }

    const onChangeHeight =(e) => {
        setCounter(counter + 1)
        let newGlassHeight = e
        setUserImage({
            ...userImage,
            glass_height: newGlassHeight
        })

        fetch(`https://test-api.stainedglassusa.com/user_images/${id}`,{
            method: 'PATCH',
            headers: {
                'Access-Control-Allow-Origin':'*',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`},
            body: JSON.stringify({id: userImage.id, glass_height: newGlassHeight })
        })
        .then(r => r.json())
        .then(console.log)   

    }


const sendQuote = (e) => {
    swal({
        title: "Do you Want to get a Quote?",
        text: "You will receive a Free Quote in your email!",
        icon: "info",
        buttons: true,
        dangerMode: false,
        
    })
    .then((willSendQuote) => {
        if (willSendQuote) {
            sendEmail();
            swal("Quote Sent", "Your quote has been requested", "success");
            history.push(`/mygallery`);

        }        
    }
    );
}


const sendEmail = () => {

    const shape = document.querySelector('select[name="windowShape"]').value;
    const zipcode = document.querySelector('input[name="zipcode"]').value;
    const notes = document.querySelector('input[name="notes"]').value;

    const data = {
        width: userImage.glass_width,
        height: userImage.glass_height,
        installation: currentInstallation,
        shape: shape,
        zipcode: zipcode,
        notes: notes,
        id: userImage.id

    };

    emailjs.send('service_vaf1y8q', 'template_pulszs3', data, 'STYLshGA6_JYfnjRI')
        .then((result) => {
            console.log(result.text);
        })
        .catch((error) => {
            console.log(error.text);
        });
};

    
const calculateCost = () => {
    setCounter(counter - counter)
    let sqft
    let glassCost
    let installationCost
    let widthFeet = userImage.glass_width/12
    let heightFeet = userImage.glass_height/12

    widthFeet = widthFeet.toFixed(2);
    heightFeet = heightFeet.toFixed(2);

    

    sqft = widthFeet * heightFeet

    if (sqft < 2) {
    installationCost  = 150   
    }else if (sqft <= 3) {
    installationCost  = 140        
    }else if (sqft <= 4) {
    installationCost  = 130  
    }else if (sqft > 4){
    installationCost = 120   
    }

        

        
    if (currentInstallation === "insulated"){
    installationCost  = installationCost + 33       
    }


    glassCost = sqft *installationCost


    if (currentInstallation === "vinylframed" && sqft < 4) {
        glassCost  = glassCost + 80   
    }else if (currentInstallation === "vinylframed" && sqft <= 6) {
        glassCost  = glassCost + 100       
    }else if (currentInstallation === "vinylframed" && sqft <= 8) {
        glassCost  = glassCost + 125  
    }else if (currentInstallation === "vinylframed" && sqft <= 11){
        glassCost  = glassCost + 140 
    }else if (currentInstallation === "vinylframed" && sqft <= 16){
        glassCost  = glassCost + 150 
    }else if (currentInstallation === "vinylframed" && sqft > 16){
        glassCost  = glassCost + 170                         
    }    


    if (currentInstallation === "woodframed" && sqft < 4) {
        glassCost  = glassCost + 105   
    }else if (currentInstallation === "woodframed" && sqft <= 6) {
        glassCost  = glassCost + 125       
    }else if (currentInstallation === "woodframed" && sqft <= 8) {
        glassCost  = glassCost + 150  
    }else if (currentInstallation === "woodframed" && sqft <= 11){
        glassCost  = glassCost + 165 
    }else if (currentInstallation === "woodframed" && sqft <= 16){
        glassCost  = glassCost + 175 
    }else if (currentInstallation === "woodframed" && sqft > 16){
        glassCost  = glassCost + 195                         
    }    



    glassCost = glassCost.toFixed(2);

    console.log(sqft)
    console.log(glassCost)
    console.log(widthFeet)
    console.log(heightFeet)

    setUserImage({
        ...userImage,
        cost: glassCost
    })

    fetch(`https://test-api.stainedglassusa.com/user_images/${id}`,{
        method: 'PATCH',
        headers: {
            'Access-Control-Allow-Origin':'*',
            'Content-Type': 'application/json',
            'Authorization': `bearer ${token}`},
        body: JSON.stringify({id: userImage.id, cost: glassCost,  })
    })
    .then(r => r.json())
    .then(console.log)   


}

const addToCart = () => {

    {userImage.cost <= 0 ?
        swal({
            title: "Window Size not defined",
            text: "Please select window size before adding to the cart",
            icon: "warning",
            buttons: false,
            dangerMode: true,
        })

        :


    swal({
        title: "Do you Want to Add this image to Cart?",
        text: "You can review all the details on the Cart Page",
        icon: "info",
        buttons: true,
        dangerMode: false,
        
    })
    .then((willAddtoCart) => {
        if (willAddtoCart) {

            fetch(`https://test-api.stainedglassusa.com/user_images/${id}`,{
                method: 'PATCH',
                headers: {
                    'Access-Control-Allow-Origin':'*',
                    'Content-Type': 'application/json',
                    'Authorization': `bearer ${token}`},
                body: JSON.stringify({id: userImage.id, cart: true })
            })
            .then(r => r.json())
            .then(console.log)   
            
                swal("Image Added", "This image has been added to your cart", "success");
                history.push(`/cart`)
            }        
        }
      );
}
}

    let currentImage = userImage.image


    return (
        <><div className="new-div">
            <h1 className='title-color'>{currentImage.description}</h1>
        </div><div className="coloring-page">
                <div className="image-description">
                    <h2 id="description-heading">Color Palette </h2>
                    <hr />
                    <ColorPalette currentColor={color} changeColor={setColor} />
                    <SelectedColor currentColor={color} />
                </div>
                <div id="coloring-image-container">
                    <div className="controls"></div>
                    <hr />



                    <div className="row">
                        <div className="col-1">
                            <Tooltip title="Undo">
                                <IconButton aria-label="Undo" onClick={onPastColor} disabled={userImage.future ?? true} style={{ transform: 'scale(3)' }}>
                                    <UndoIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className="col-10">
                            <ColoredInImage size="large" component={userImage.image.component} onFill={onFillColor} fillColors={userImage.fill_colors} />
                        </div>
                        <div className="col-1">
                            <Tooltip title="Redo">
                                <IconButton aria-label="Redo" onClick={onFutureColor} disabled={userImage?.past ?? true} style={{ transform: 'scale(3)' }}>
                                    <RedoIcon />
                                </IconButton>
                            </Tooltip>
                        </div>

                    </div>
                    <p className="auto-save-text">* This image will save automatically!</p>
                </div>
                <div className="image-description">
                    <h2 id="description-heading">Get a Quote</h2>
                    <hr />

                    <div className="calculator">
                        <label>Width (inches):
                            <input
                                className='calculator-input'
                                type="text"
                                defaultValue={userImage.glass_width}
                                onChange={e => onChangeWidth(e.target.value)}
                                label="Width" />
                        </label>

                        <label>Height (inches):
                            <input
                                className='calculator-input'
                                type="text"
                                defaultValue={userImage.glass_height}
                                onChange={e => onChangeHeight(e.target.value)}
                                label="Height" />
                        </label>

                        <label>Window Shape
                            <select
                                className='calculator-input'
                                name='windowShape'
                            >
                                <option value="oval">Oval</option>
                                <option value="arch">Arch</option>
                                <option value="square">Square</option>
                                <option value="rectangular">Rectangular</option>
                                <option value="round">Round</option>
                            </select>
                        </label>

                        <label>
                            Installation Options
                            <select
                                className="calculator-input"
                                defaultValue={currentInstallation}
                                onChange={(event) => changeInstallation(event.target.value)}
                                value={currentInstallation}
                                helperText="Please select an Installation Option"
                            >
                                <optgroup label="For Interior Use">
                                    {installation
                                        .filter((option) => ['easy', 'hang'].includes(option.value)) // Filter for interior options
                                        .map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                </optgroup>
                                <optgroup label="For Exterior Use">
                                    {installation
                                        .filter((option) => ['insulated', 'vinylframed', 'woodframed'].includes(option.value)
                                        ) // Filter for exterior options
                                        .map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                </optgroup>
                            </select>
                        </label>

                        <InstalationOptions data={currentInstallation} />
                        <hr />

                        <label>Shipping ZipCode
                            <input
                                className='calculator-input'
                                type="text"
                                label="ZipCode"
                                name='zipcode' />
                        </label>

                        <label>Additional notes
                            <textarea
                                className='calculator-input'
                                rows="4"
                                label="Notes"
                                name='notes' />
                        </label>



                        {counter > 1 ?

                            <button type="submit" onClick={sendQuote} disabled={!true}>
                                Get A Free Quote
                            </button>

                            :

                            <button type="submit" onClick={sendQuote} disabled={!true}>
                                Get A Free Quote
                            </button>}


                    </div>
                </div>


            </div></>
    )
}