import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import MyImageCardOrder from './MyImageCardOrder';
import LoadingSpinner from '../LoadingSpinner';


export default function MyOrders() {

    const {token, user} = useSelector(state => state.auth);
    const history = useHistory();
    const [userImages, setUserImages] = useState([]);
    const [fetched, setFetched] = useState(false);
    const [imagesCart, setImagesCart] = useState([]);
      
    if(!localStorage.getItem("token")){
        history.push("/login")
    }
    
    if(token && !fetched){
        fetch(`https://test-api.stainedglassusa.com/user_images`,
        {
            headers: {
            'Access-Control-Allow-Origin':'*',
            'Access-Control-Allow-Methods': 'OPTIONS,POST,GET',
            'Authorization': `bearer ${token}`}
        })
        .then(r => r.json())
        .then(data => {
            setUserImages(data)
            setFetched(true)
        }) 
    } 
    
    return (
        <div>
            {<p>My Orders</p>}
            {!fetched ? <LoadingSpinner /> : 
                userImages.length === 0 ? 
                <ul>    
                {userImages.map(userImages => {
                  return (
                    <li>Id: {userImages.id}
                    </li>
                  )
                })}
              </ul>
                 :
                    <div id="my-cart">                        
                        {userImages.map(userImageObj => <MyImageCardOrder id="my-image-card-cart" key={userImageObj.id} product_id={userImages.id} userImage={userImageObj} cart={userImages.cart} cost={userImages.cost} width={userImages.glass_width} height={userImages.glass_height} user={user.username} />)}
                    </div>
                }
        </div>
    );
    
}