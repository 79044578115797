// patterns.js
import React from 'react';
import bevelImage from '../colors/00_bevel.jpg?v=1';
import yellowImage from '../colors/01_yellow.jpg';
import lightAmberImage from '../colors/02_lightAmber.jpg';
import darkAmberImage from '../colors/03_darkAmber.jpg';
import cherryImage from '../colors/04_cherry.jpg';
import redAmberImage from '../colors/05_redAmber.jpg';
import orangeImage from '../colors/06_orange.jpg';
import greenImage from '../colors/07_green.jpg';
import oliveImage from '../colors/08_olive.jpg';
import purpleImage from '../colors/09_purple.jpg';
import lightBlueImage from '../colors/10_lightBlue.jpg';
import aquaBlueImage from '../colors/11_aquaBlue.jpg';
import oceanBlueImage from '../colors/12_oceanBlue.jpg';
import cobaltBlueImage from '../colors/13_cobaltBlue.jpg';
import tealBlueImage from '../colors/14_tealBlue.jpg';
import clearImage from '../colors/15_clear.jpg';
import graniteImage from '../colors/16_granite.jpg';
import crackleImage from '../colors/17_crackle.jpg';
import iridescentImage from '../colors/18_iridescent.jpg';
import taffyImage from '../colors/19_taffy.jpg';
import seedyImage from '../colors/20_seedy.jpg';
import frostedImage from '../colors/21_frosted.jpg';
import bevels from '../colors/bevels.jpg';

const BevelFilter = () => (
    <filter id="Bevel" filterUnits="objectBoundingBox" x="-10%" y="-10%" width="150%" height="150%">
        <feGaussianBlur in="SourceAlpha" stdDeviation="3" result="blur" />
        <feSpecularLighting in="blur" surfaceScale="5" specularConstant="0.5" specularExponent="10" result="specOut" lightingColor="white">
            <fePointLight x="-5000" y="-10000" z="20000" />
        </feSpecularLighting>
        <feComposite in="specOut" in2="SourceAlpha" operator="in" result="specOut2" />
        <feComposite in="SourceGraphic" in2="specOut2" operator="arithmetic" k1="0" k2="1" k3="1" k4="0" result="litPaint" />
    </filter>
);


const patterns = {
    "00_bevel": bevelImage,
    "01_yellow": yellowImage,
    "02_lightAmber": lightAmberImage,
    "03_darkAmber": darkAmberImage,
    "04_cherry": cherryImage,
    "05_redAmber": redAmberImage,
    "06_orange": orangeImage,
    "07_green": greenImage,
    "08_olive": oliveImage,
    "09_purple": purpleImage,
    "10_lightBlue": lightBlueImage,
    "11_aquaBlue": aquaBlueImage,
    "12_oceanBlue": oceanBlueImage,
    "13_cobaltBlue": cobaltBlueImage,
    "14_tealBlue": tealBlueImage,
    "15_clear": clearImage,
    "16_granite": graniteImage,
    "17_crackle": crackleImage,
    "18_iridescent": iridescentImage,
    "19_taffy": taffyImage,
    "20_seedy": seedyImage,
    "21_frosted": frostedImage,
    "bevels": bevels,
};

const Patterns = () => {
    return (
        <>
            <BevelFilter />
            {Object.entries(patterns).map(([id, image]) => (
                <pattern key={id} id={id} width="100%" height="100%">
                    <image
                        href={image}
                        width="100%"
                        height="100%"
                        preserveAspectRatio="xMidYMid slice"
                        filter={id === "00_bevel" ? "url(#Bevel)" : undefined}
                    />
                                        {id === "00_bevel" && (
                        <rect 
                            width="100%" 
                            height="100%" 
                            fill="none" // Important: Don't fill the rect
                            stroke="aquamarine" 
                            strokeWidth="10" 
                            strokeLinejoin="bevel"
                        />
                    )}
                </pattern>
            ))}
        </>
    );
};

export default Patterns;