import React from 'react';
import './Hummingbird.css';


export default function Hummingbird(props) {
    
    return (
        <div className={`hummingbird-container ${props.size}`}> 
 
            <svg  xmlns="http://www.w3.org/2000/svg" id={props.id} width="500" height="500" viewBox="0 0 500 500">
                
            <pattern id="01_yellow" width="100%" height="100%">
                      <image xlinkHref="/01_yellow.jpg"/>
              </pattern>
          <pattern id="02_lightAmber" width="100%" height="100%">
              <image xlinkHref="/02_lightAmber.jpg"/>
          </pattern>
          <pattern id="03_darkAmber" width="100%" height="100%">
              <image xlinkHref="/03_darkAmber.jpg"/>
          </pattern>
          <pattern id="04_cherry" width="100%" height="100%">
              <image xlinkHref="/04_cherry.jpg"/>
          </pattern>
          <pattern id="05_redAmber" width="100%" height="100%">
              <image xlinkHref="/05_redAmber.jpg"/>
          </pattern>    
          <pattern id="06_orange" width="100%" height="100%">
              <image xlinkHref="/06_orange.jpg"/>
          </pattern>
          <pattern id="07_green" width="100%" height="100%">
              <image xlinkHref="/07_green.jpg"/>
          </pattern>
          <pattern id="08_olive" width="100%" height="100%">
              <image xlinkHref="/08_olive.jpg"/>
          </pattern>
          <pattern id="09_purple" width="100%" height="100%">
              <image xlinkHref="/09_purple.jpg"/>
          </pattern>
          <pattern id="10_lightBlue" width="100%" height="100%">
              <image xlinkHref="/10_lightBlue.jpg"/>
          </pattern>  
          <pattern id="11_aquaBlue" width="100%" height="100%">
              <image xlinkHref="/11_aquaBlue.jpg"/>
          </pattern>
          <pattern id="12_oceanBlue" width="100%" height="100%">
              <image xlinkHref="/12_oceanBlue.jpg"/>
          </pattern>
          <pattern id="13_cobaltBlue" width="100%" height="100%">
              <image xlinkHref="/13_cobaltBlue.jpg"/>
          </pattern>
          <pattern id="14_tealBlue" width="100%" height="100%">
              <image xlinkHref="/14_tealBlue.jpg"/>
          </pattern>
          <pattern id="15_clear" width="100%" height="100%">
              <image xlinkHref="/15_clear.jpg"/>
          </pattern>  
          <pattern id="16_granite" width="100%" height="100%">
              <image xlinkHref="/16_granite.jpg"/>
          </pattern>
          <pattern id="17_crackle" width="100%" height="100%">
              <image xlinkHref="/17_crackle.jpg"/>
          </pattern>
          <pattern id="18_iridescent" width="100%" height="100%">
              <image xlinkHref="/18_iridescent.jpg"/>
          </pattern>
          <pattern id="19_taffy" width="100%" height="100%">
              <image xlinkHref="/19_taffy.jpg"/>
          </pattern>
          <pattern id="20_seedy" width="100%" height="100%">
              <image xlinkHref="/20_seedy.jpg"/>
          </pattern> 
          <pattern id="21_frosted" width="100%" height="100%">
              <image xlinkHref="/21_frosted.jpg"/>
          </pattern>                                      
            <g className="hummingbird-image" >


<path id="background" fill="black"  d="M0,500C0,331.3,0,166.9,0,0c165.9,0,330.1,0,499.9,0c0.1,166.8,0.1,332,0,500C331.2,500,167.1,500,0,500z"/>
<path onClick={() => props.onFill(98)} fill={props.fillColors[98]} d="M347.7,35.1c39.3,0,78.7,0,118.3,0c0.1,0.8,0.2,1.6,0.2,2.4c0,19.4,0,38.7,0,58.1
	c0,1.5-0.3,2.6-1.4,3.7c-3,3.1-5.8,6.4-8.9,9.4c-3.6,3.4-7.9,5.3-12.8,6c-2,0.3-3.9,0.7-5.9,1c-3.1,0.6-5.9,1.9-8.4,4
	c-1,0.8-1.9,1.7-3,2.6c-0.5-0.3-1.1-0.7-1.6-1c-1.2-0.8-2.3-1.7-3.6-2.4c-2.7-1.5-4.8-1.2-7,0.9c-0.6,0.5-1,1.2-1.6,1.7
	c-0.4,0.3-0.9,0.7-1.4,0.8c-2.2,0.2-4,1.3-5.6,2.6c-4.2,3.5-7.9,7.5-10.7,12.2c-1.3,2.1-2.5,4.3-3.7,6.4c-2.5,4.9-6.2,8.7-10.4,12.1
	c-1.5,1.2-3,2.4-4.5,3.5c-2.7,2.1-4.7,4.7-5.9,8c-0.4,1.2-1,2.4-1.3,3.6c-1.5,6.2-4.4,11.8-7.8,17.1c-2.8,4.3-1.2,4-6.7,3.5
	c-3.5-0.3-7-0.9-10.4-1.5c-0.7-0.1-1.5-0.6-2.1-1.1c-4.2-3.5-8.4-7.1-12.7-10.6c-1.5-1.2-2.4-2.7-2.6-4.6c-0.3-2.5-0.6-5.1-1.1-7.6
	c-0.2-1.4-0.6-2.8-1.1-4.2c-0.9-2.6-2.7-4.5-5.6-5.5c2.4-1.8,3.7-4,4.1-6.6c0.1-0.7,0.7-1.4,1.3-1.8c2.2-1.5,3.6-3.6,4.2-6.2
	c0.3-1.5,1-2.8,2.2-3.9c1.5-1.4,2.2-3.2,2.1-5.2c-0.1-1.5,0.4-2.4,1.6-3.2c0.6-0.4,1.1-0.9,1.7-1.4c2.2-1.7,3.5-3.8,2.2-6.2
	c1.5-1.7,2.8-3.2,4.2-4.6c2.1-2.3,3.2-4.7,1.8-7.9c-0.3-0.6,0-1.5,0.3-2.2c1.5-3,2.2-6.2,2.1-9.5c-0.1-1.7-0.3-3.4-1.6-4.7
	c-0.9-0.9-0.9-1.8-0.6-2.8c0.2-0.9,0.3-1.7,0.5-2.6c0.3-1.9-0.1-3.7-1.2-5.2c-0.7-0.9-0.9-1.9-1.1-3.1c-0.2-1.6-0.8-3.2-1.5-4.7
	c-0.5-1.1-0.8-1.9-0.3-3.1c0.6-1.5,0.8-3.2,1.2-4.9c0.4-1.6,0.6-3.3,1.2-4.9c3.1-7.3,4.6-14.8,4.3-22.7c0-1.2,0.1-2.4,0.1-3.6
	C347.4,35.5,347.5,35.4,347.7,35.1z"/>
<path onClick={() => props.onFill(97)} fill={props.fillColors[97]} d="M84.9,142.2c-5.2-3.1-9.2-7.1-12.4-11.9c-2.9-4.3-5.6-8.7-8.5-13.1c-1.6-2.5-3.3-4.9-5.1-7.3
	c-4.3-5.9-10.1-9.7-16.9-12.1c-1.7-0.6-3.4-1.3-5.2-1.8c-0.8-0.3-1.2-0.7-1.2-1.6c0-0.6,0-1.1,0-1.7c0-18.2,0-36.5,0-54.7
	c0-0.9,0-1.9,0-2.9c62.3,0,124.4,0,186.6,0c0.3,2.7,0.7,5.3,0.9,7.9c0.4,7.1,1.4,14,4.4,20.6c-0.6,0.5-1.1,0.9-1.7,1.3
	c-10.3,6.9-20.5,14-30.9,20.7c-8.3,5.4-17.3,9.6-26.6,13.1c-8,3.1-16.3,4.9-24.7,6.8c-9,1.9-18,3.9-26.8,6.3
	c-7.7,2.1-14.7,5.8-20.3,11.7c-3.4,3.6-6,7.9-8.3,12.3C87.1,137.9,86.1,139.9,84.9,142.2z"/>
<path onClick={() => props.onFill(96)} fill={props.fillColors[96]} d="M185.1,186.1c-3.2-1.7-6.2-3.3-9.1-4.9c-0.6-0.3-1.3-0.6-2-0.8c-3-0.8-4.9-0.1-6.6,2.5
	c-0.8,1.2-1.5,2.5-2,3.8c-2.3,5.3-5.5,10.1-9.4,14.4c-2.3,2.5-4.5,5.2-7,8c-0.7-0.7-1.3-1.3-1.8-2c-1-1.4-2-2.9-3-4.3
	c-4.3-5.7-9.7-7.6-16.7-5.8c-0.8,0.2-1.5,0.4-2.5,0.7c-0.3-2.3-0.5-4.4-0.7-6.5c-0.6-6.2-1.6-12.3-3.4-18.4
	c-2.3-7.9-6.8-14.3-14-18.4c-5.5-3.2-11.1-6.1-16.6-9.1c-1.2-0.6-2.4-1.3-3.5-1.9c-0.1-0.3-0.1-0.5-0.1-0.6
	c2.7-5.7,5.6-11.3,9.6-16.3c4.4-5.4,10.2-8.9,16.6-11.5c6.9-2.7,14.1-4.1,21.2-5.7c10-2.2,20.2-3.8,30-7.2
	c12.1-4.2,23.7-9.6,34.4-16.7c9.5-6.3,19-12.8,28.5-19.1c0.4-0.3,0.8-0.5,1.3-0.8c0.4,0.5,0.8,1,1.2,1.5c2.2,3.3,5.1,6,8.6,7.9
	c2.5,1.4,5.1,2.7,7.6,4.1c0.5,0.3,1,0.5,1.5,0.8c6.4,3,9.7,8.1,11.1,15c2.2,11.2,3.6,22.5,4,34c0.1,3.5,0,7-1.5,10.3
	c-1.2,2.6-1.3,2.8-4.1,2.4c-2.9-0.4-5.8-1.1-8.7-1.6c-10.7-2.1-20.1,0.9-28.1,8.1c-2.8,2.5-4.4,5.7-4.7,9.4c-0.2,2-1.1,3.5-2.6,4.9
	c-6.9,6.1-13.6,12.4-20.5,18.5C189.6,182.7,187.2,184.4,185.1,186.1z"/>
<path onClick={() => props.onFill(95)} fill={props.fillColors[95]} d="M72.2,358.5c-5.4,1.2-10.5,0.8-15.2-2.1c-1.7-1-3.5-1.9-5.2-2.9c-4-2.4-8.3-3.1-12.9-2.2
	c-0.9,0.2-1.9,0.3-2.7,0.4c-0.2-0.1-0.3-0.1-0.3-0.2c-0.1-0.1-0.1-0.1-0.1-0.2c-0.1-0.6-0.1-1.1-0.1-1.7c0-42,0-84,0-125.9
	c0-0.2,0.1-0.5,0.2-0.7c15-2.7,29.4-1.6,42.8,7.1c-0.7,4.8,2.2,7.5,6.1,9.5c0.2,0.1,0.4,0.2,0.6,0.4c3.3,1.9,3.7,3.1,2.1,6.6
	c-0.8,1.7-1.7,3.3-2.6,4.8c-0.5,0.8-0.5,1.6-0.2,2.5c1.4,3.8,3.3,7.1,6.7,9.4c2.5,1.7,4.2,3.9,4.8,7.1c0.7,4,3.4,6.8,7,8.6
	c1.3,0.6,2.2,1.5,3.1,2.6c2,2.6,4,5.2,6,7.8c0.5,0.7,1,1.4,1.4,2c-3.2,3-6.3,5.8-9.4,8.5c-2.3,2-3,4.4-2.9,7.3c0.1,1.5,0,3-0.1,4.5
	c-0.1,2.8-1.4,5-3.6,6.7c-1.4,1.1-2.8,2.1-4.2,3.2c-5.2,3.9-6.7,8.7-4.7,14.8c0.4,1.3,0.9,2.6,1.3,3.9c0.5,1.6,0.1,3-1.4,3.9
	c-1.2,0.7-2.3,1.4-3.5,2c-3,1.6-6,3.4-8.5,5.8C74.7,353.7,73.1,355.7,72.2,358.5z"/>
<path onClick={() => props.onFill(94)} fill={props.fillColors[94]} d="M287.3,271.6c-4.3-3.2-9.3-4.5-14.1-6.2c-1.1-0.4-2.2-0.8-3.3-1.4c-2.1-1-3.5-2.7-4.1-5.1
	c-0.5-1.9-1-3.7-1.3-5.6c-0.8-4.9-3-8.5-10.6-10c-1.8-0.4-3.6-0.4-5.7-0.6c-0.3-0.7-0.7-1.5-0.9-2.3c-1.6-5.7-5.4-9.4-10.7-11.5
	c-3.3-1.3-6.8-2.3-10.2-3.3c-1.5-0.4-2.7-1-3.5-2.5c-0.7-1.4-1.8-2.7-2.8-3.9c-2-2.4-3.1-5.2-3.7-8.2c-0.4-2.2-1-4.4-1.6-6.5
	c-0.9-2.9-2.8-5.2-5.5-6.7c-3-1.6-6.1-3.2-9.2-4.5c-1.9-0.8-4-1.1-6-1.3c-3.2-0.3-5.9-1.3-7.9-4.4c2.3-1.7,4.4-3.6,6.9-5
	c8.5-5.1,17.1-10,25.7-14.9c1.1-0.6,2.3-1,3.6-1.3c3-0.7,6.1-1.1,9.1-1.8c4.1-1,7.9-0.3,11.6,1.5c0.6,0.3,1.2,0.5,1.7,0.9
	c8.4,5.3,17.8,8.2,27.3,10.8c7,1.9,14.1,2.8,21.3,2.6c3.7-0.1,7.4-0.3,11-0.2c1.6,0,3.2,0.7,4.8,1.2c1.1,0.4,1.5,1.3,1.5,2.5
	c0.2,10,0.3,20-1.2,29.9c-0.4,2.4-0.2,4.9-0.3,7.4c-0.4,5.7-0.6,11.5-1.3,17.2c-1.2,9.5-5.5,17.6-12,24.6
	C293,265.7,290.2,268.6,287.3,271.6z"/>
<path onClick={() => props.onFill(93)} fill={props.fillColors[93]} d="M35.8,220.9c0-41.2,0-82,0-123c0.6,0.1,1.2,0.2,1.7,0.4c10.4,2.8,18.2,9,23.8,18.1
	c2.8,4.6,5.9,9.2,8.8,13.7c4.4,6.8,10.2,12.2,17.5,15.9c4.8,2.4,9.5,5,14.2,7.5c0.5,0.3,1,0.5,1.5,0.8c8.8,4.5,14.3,11.6,16.5,21.2
	c1.1,4.9,1.7,9.9,2.5,14.9c0.3,2.1,0.5,4.3,0.7,6.5c-1.1,0.6-2,1.2-3,1.7c-2.3,1.2-3.9,3.1-4.5,5.6c-0.1,0.3-0.2,0.6-0.3,0.9
	c-0.6,2.7-1.4,5.1-4.5,6.3c-2.6,0.9-5,2.2-7.8,1.4c-1.4-0.4-2.8-0.5-4.3-0.6c-2.8-0.3-5.1,0.8-6.6,3.1c-1.1,1.6-1.9,3.3-2.4,5.1
	c-0.8,2.6-2.2,4.3-4.8,5.1c-1.4,0.5-2.8,1.1-4.1,1.9c-0.9,0.6-1.7,0.6-2.6,0.1c-3.3-1.6-6.6-3.1-10-4.5c-7.2-2.9-14.7-3.4-22.4-2.8
	C42.4,220.4,39.2,220.6,35.8,220.9z"/>
<path onClick={() => props.onFill(92)} fill={props.fillColors[92]} d="M345.4,35.2c0.6,8.7-0.2,17.1-3.7,25c-0.9,2-1.4,4-1.6,6.2c-0.1,1.6-0.8,3.2-1.3,4.8
	c-0.3,1.1-0.9,2-1.3,3.1c-2.5,7.8-7.2,14.1-12.4,20.3c-8.4,9.8-17.8,18.6-27.3,27.3c-1.9,1.7-3.7,3.5-5.6,5.2
	c-0.5,0.5-1.1,0.9-1.8,1.5c-0.3-2.3-1.1-4.1-2.7-5.5c-0.4-0.4-0.7-1.1-0.7-1.6c0.1-3.7-1.9-6.1-5-7.8c-0.6-0.3-1.1-0.7-1.7-0.9
	c-1.2-0.5-2.1-1.3-2.8-2.5c-1.4-2.6-3.8-4.1-6.6-4.7c-1.2-0.3-1.8-0.7-2.3-1.8c-1.3-2.9-3.4-5-6.5-5.9c-1.3-0.4-1.8-1.1-2-2.3
	c-0.4-1.8-0.8-3.6-1.3-5.3c-1.2-4.5-3.9-7.9-7.8-10.2c-2.7-1.7-5.5-3.4-8.4-4.6c-9.5-4.1-14.6-11.4-16.3-21.3
	c-1.1-6.2-1.6-12.5-2.4-18.7C225.9,34.9,342.3,34.8,345.4,35.2z"/>
<path onClick={() => props.onFill(91)} fill={props.fillColors[91]} d="M499.4,249.4c-10.5,0-20.7,0-31,0c-0.5-1.9-0.7-207.7-0.3-214c1.6-0.5,26.4-0.7,31-0.3
	c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.1,0.2c0.1,0.3,0.1,0.6,0.1,0.9c0,70.6,0,141.1,0,211.7
	C499.6,248.4,499.5,248.7,499.4,249.4z"/>
<path onClick={() => props.onFill(90)} fill={props.fillColors[90]} d="M499.4,465.5c-10.6,0-20.8,0-31,0c-0.1-0.1-0.2-0.2-0.2-0.2c-0.1-0.1-0.1-0.1-0.1-0.2
	c0-0.5-0.1-1-0.1-1.4c0-70.6,0-141.1,0.1-211.7c0-0.1,0.1-0.3,0.3-0.7c10.2,0,20.6-0.3,31,0C499.4,322.8,499.4,394,499.4,465.5z"/>
<path onClick={() => props.onFill(89)} fill={props.fillColors[89]} d="M33.5,35.1c0,71.4,0,142.7,0,214.2c-10.4,0-20.6,0-30.8,0C2.2,247.5,1.9,42.2,2.5,35.4
	C4.2,34.9,28,34.7,33.5,35.1z"/>
<path onClick={() => props.onFill(88)} fill={props.fillColors[88]} d="M249.8,498.8c-71.3,0-142.6,0-213.8,0c-0.5-1.9-0.6-28.8-0.1-31.2c71.2,0,142.4,0,213.8,0
	C249.8,477.9,249.8,488.2,249.8,498.8z"/>
<path onClick={() => props.onFill(87)} fill={props.fillColors[87]} d="M249.9,1.9c0,10.5,0,20.7,0,31.1c-71.3,0-142.6,0-213.8,0C35.5,31.2,35.4,4.2,36,1.9
	C107.2,1.9,178.5,1.9,249.9,1.9z"/>
<path onClick={() => props.onFill(86)} fill={props.fillColors[86]} d="M2.6,251.4c10.3,0,20.5,0,31,0c0,71.4,0,142.6,0,213.9c-1.7,0.5-26.9,0.6-30.8,0.2
	C2.3,463.8,2.1,254.6,2.6,251.4z"/>
<path onClick={() => props.onFill(85)} fill={props.fillColors[85]} d="M466.1,1.9c0,10.4,0,20.7,0,31.1c-71.4,0-142.6,0-213.8,0c-0.6-1.7-0.8-28.3-0.1-31.1
	C323.4,1.9,394.7,1.9,466.1,1.9z"/>
<path onClick={() => props.onFill(84)} fill={props.fillColors[84]} d="M465.8,498.8c-71.2,0-142.4,0-213.6,0c-0.6-2-0.6-28.8,0-31.2c71.2,0,142.4,0,213.6,0
	C466.4,469.5,466.4,496.5,465.8,498.8z"/>
<path onClick={() => props.onFill(83)} fill={props.fillColors[83]} d="M182.6,465.5c-47.6,0-94.9,0-142.7,0c0.9-0.5,1.3-0.8,1.8-1c3.2-1.6,6.5-3.1,9.6-4.8
	c8.2-4.4,14.4-10.7,18.8-18.9c2.3-4.3,4.8-8.5,7.1-12.8c4.6-8.7,11.3-15.2,19.9-19.9c3-1.7,6-3.3,9-5.1c1-0.6,1.8-0.6,2.9-0.2
	c2.1,0.6,4.4,0.9,6.5,1.3c0.9,0.2,1.8,0.2,2.6,0.5c7.5,2.8,14.9,5.6,22.3,8.6c15.2,6.2,26.1,17.1,33.2,31.7c3,6.1,5.6,12.4,8.4,18.6
	C182.1,464,182.3,464.6,182.6,465.5z"/>
<path onClick={() => props.onFill(82)} fill={props.fillColors[82]} d="M253.4,464.6c-0.5,0-0.8,0.1-1.1,0c-6.6-2.5-13.4-4.8-19.9-7.6c-9-3.8-15.9-10.2-21.9-17.8
	c-4.3-5.5-8.7-11.1-13.8-15.9c-11-10.3-23.4-18-38.1-21.7c-6.9-1.8-14-2.5-21.1-1.8c-6.1,0.6-12.2,1.4-18.3,2.3
	c-3.1,0.4-6.1,0.2-9-0.8c-1.3-0.5-2.6-1.4-3.4-2.5c-1.5-2-2.3-4.3-2.8-7c4.9-2.5,7.7-7,10.9-11.2c4.2-5.6,9.7-9.4,16.2-11.9
	c1.3-0.5,2.6-0.9,3.8-1.4c2-0.8,3.3-2.1,3.9-4.2c0.2-0.8,0.6-1.7,0.7-2.5c0.4-2.1,1.5-3.8,3-5.4c0.7-0.7,1.4-1.5,2.2-2.2
	c2.7-2.5,4.6-5.5,6-8.9c0.5-1.2,1.1-2.5,1.6-3.8c4.5,1.4,7.5,4.1,8.4,8.7c1.2,6.7,3.8,13,6,19.4c1.6,4.5,4.7,6.4,9.1,6.9
	c3.3,0.4,6.7,0.6,10,1.4c2.5,0.6,4.8,1.6,7.1,2.7c2.7,1.3,5.2,3,7.9,4.5c2.6,1.4,5.3,2.7,8.4,2c1-0.2,1.4,0.6,1.9,1.2
	c3.7,5,7.3,10.1,11.2,15c4.8,6,8.6,12.7,11.7,19.7c6,13.5,12.3,26.9,18.4,40.3C252.7,462.9,253,463.7,253.4,464.6z"/>
<path onClick={() => props.onFill(81)} fill={props.fillColors[81]} d="M35.6,353.7c5-1.3,9.7-1.7,14.2,0.8c2.2,1.2,4.4,2.3,6.5,3.6c4.3,2.6,8.9,3.3,13.8,2.6
	c0.5-0.1,0.9-0.1,1.4-0.1c0.1,0,0.3,0.1,0.6,0.2c0.7,2.7,2.2,5,4.5,6.8c1.7,1.4,3.5,2.3,5.7,2.1c0.8,0,1.6,0.1,2.4,0.2
	c2.3,0.4,2.7,0.6,3,3c0.8,6.1,3.8,11.3,7,16.4c1.6,2.5,4,3.9,7.1,3.4c1.2,2.8,2.4,5.5,3.7,8.4c-0.3,0.2-0.8,0.6-1.3,0.9
	c-2.8,1.6-5.7,3.2-8.5,4.8c-8.5,4.8-15.2,11.4-19.9,20c-2.3,4.2-4.7,8.3-6.9,12.6c-4.6,8.9-11.5,15.5-20.4,19.9
	c-3.5,1.7-7.1,3.4-10.6,5c-0.6,0.3-1.2,0.4-1.7,0.6c-0.2-0.1-0.3-0.2-0.3-0.2c-0.1-0.1-0.1-0.1-0.1-0.2c0-0.6-0.1-1.3-0.1-1.9
	C35.6,426.3,35.6,390.1,35.6,353.7z"/>
<path onClick={() => props.onFill(80)} fill={props.fillColors[80]} d="M255.6,303.3c2.7,0,5,0,7.3,0c1.7,0,3.1,0.9,4.2,2c1.3,1.2,2.4,2.6,3.6,3.8c4.5,4.7,9.8,6.9,16.4,6.2
	c0.1,0,0.3,0.1,0.7,0.2c0.5,3.3,1,6.6,1.5,10c1,6.6,1.1,13.2,1,19.9c-0.1,7.6-1.5,14.9-3.5,22.2c-1.8,6.7-3.7,13.4-4.8,20.3
	c-1.9,11.3-3.3,22.8-4.7,34.2c-1,8-1.7,16-2.6,24.5c-0.6-0.7-1-1-1.4-1.5c-9.9-12.3-16.2-26.3-20.5-41.4c-1.5-5.3-2.4-10.6-2.9-16
	c-0.7-7.1-1.5-14.2-2.1-21.2c-0.2-2,0-4,0-6c0-3,0-5.9,0-8.9c0-2.1,0-4.2-0.1-6.2c-0.1-1.2,0.4-2.1,1.3-2.9c1.1-1,2.2-2.1,3.3-3.1
	c2.2-2.2,3.6-4.9,3.6-8.1c0-4.3,1.1-8.2,3.1-11.9c2.3-4.4,1.7-8.5-1-12.4C257.4,305.9,256.6,304.8,255.6,303.3z"/>
<path onClick={() => props.onFill(79)} fill={props.fillColors[79]} d="M465.9,465.5c-16,0-31.8,0-47.6,0c1.9-5.3,11.1-16.5,16.8-20.5c6.9,4.1,14.3,7,22.4,9.9
	c-0.1-1.2-0.1-2-0.4-2.6c-2-4.8-4.1-9.6-6.9-13.9c-4.8-7.5-11.7-11.3-20.6-11.4c-0.7,0-1.4-0.1-2.3-0.1c0.4-0.9,0.7-1.5,1.1-2.1
	c4.5-7.2,5.1-14.9,2.3-22.8c-1.6-4.5-3.7-8.8-5.6-13.2c-0.5-1.2-1.1-2.3-1.7-3.5c0.4-0.6,0.8-1.2,1.2-1.8c4.3-6.5,8.7-13,12.9-19.6
	c1.5-2.4,2.6-5.1,3.8-7.7c0.6-1.3,0.8-2.7,0-4.2c-0.2-0.4-0.2-1.3,0.1-1.7c1.6-2.2,3.2-4.4,5-6.4c1.5-1.6,3.6-2.4,5.7-2.8
	c1.6-0.4,3.1-0.7,4.7-1c3.3-0.5,6-2.1,8.1-4.7c0.2-0.3,0.5-0.6,0.8-0.9C466.3,336.1,466.5,462.5,465.9,465.5z"/>
<path onClick={() => props.onFill(78)} fill={props.fillColors[78]} d="M159.5,288c-2.5-0.6-4.7-1.2-6.8-1.5c-2.4-0.3-4.8-0.4-7.2-0.4c-1.3,0-2.3-0.4-3.3-1.1
	c-3.9-2.9-7.9-5.7-11.8-8.6c-3.3-2.4-6.7-4.3-10.5-5.7c-0.7-0.3-1.5-0.6-2.2-0.9c-3.1-1.2-5.4-3.4-6.6-6.5
	c-1.6-4.2-3.3-8.3-4.5-12.6c-1.4-5-0.9-9.8,2.4-14.1c1.6-2,2.5-4.2,2.4-6.9c-0.1-1.2,0.3-2.4,1.2-3.3c1.7-1.7,1.7-3.7,1.1-5.8
	c-0.3-1-0.5-2-0.9-3c-0.6-1.5-0.4-2.9,0.6-4c1.9-2.3,3.1-5,3.7-7.9c0.4-2.1,1.5-3.6,3.7-4.2c2.7-0.8,5.2-2,7.9-2.7
	c5.5-1.5,10.1,0.3,13.6,4.6c1.3,1.5,2.3,3.3,3.4,4.9c1.3,1.9,2.9,3.4,5.1,4.1c0.8,0.3,1.6,0.8,2.3,1.3c4.9,4.1,8.9,8.9,10.8,15.2
	c1.6,5.1,3.1,10.2,4.6,15.3c0.6,1.9,0.6,3.8,0,5.7c-1.1,3.6-2.1,7.2-3.5,10.7c-0.9,2.3-2.2,4.5-3.7,6.4c-5,6-5.4,12.4-2.4,19.3
	C159,286.6,159.1,287.1,159.5,288z"/>
<path onClick={() => props.onFill(77)} fill={props.fillColors[77]} d="M196.9,293.9c1.4-2.4,2.7-4.7,4-6.9c0.4-0.6,0.9-1.1,1.5-1.5c3.4-2.2,6.7-4.3,10.1-6.4
	c0.5-0.3,1-0.6,1.5-0.7c3.9-0.3,7.8-0.7,11.6,0.6c2.2,0.8,4.4,1.5,6.6,2c5,1.2,7.7,4.6,8,9.5c0.3,4.2,2.3,6.8,6,8.1
	c4.4,1.5,7.3,4.8,9.8,8.4c0.6,0.8,1.1,1.7,1.6,2.7c1.3,2.6,1.5,5.2,0.3,7.8c-0.3,0.6-0.5,1.2-0.8,1.7c-2,3.8-3,7.9-2.9,12.2
	c0,2.3-1,4.3-2.6,6c-1.3,1.3-2.5,2.7-3.9,3.9c-1.3,1.1-1.7,2.4-1.6,4c0.1,4.1,0,8.2,0.1,12.2c0.1,4.7-1.4,8.9-4.5,12.3
	c-2,2.2-4.4,4.2-6.9,5.9c-3.2,2.2-6.2,1.7-8.9-0.8c-0.6-0.5-0.9-1-0.7-1.9c2.2-8.9-1.8-15.3-8.3-20.7c-3.1-2.6-6.5-5-9.6-7.6
	c-1.5-1.3-2.9-2.7-4.2-4.3c-1.4-1.7-2-3.8-1.6-6.1c0.6-3.5,1.4-7.1,2.1-10.6c1.6-7.4,1.2-14.8,0.8-22.2c-0.1-1-0.4-2-0.7-3
	c-0.9-2.9-2.7-4.7-5.9-4.6c-0.2,0-0.5,0-0.7-0.1C197,294,196.9,294,196.9,293.9z"/>
<path onClick={() => props.onFill(76)} fill={props.fillColors[76]} d="M183.2,301.3c0.1,0.3,0.2,0.4,0.1,0.4c-1.8,4.6-0.6,8.9,1.4,13.2c1.7,3.5,3.3,7.1,4.8,10.7
	c1.6,3.9,4.1,7,7.7,9.2c1.5,0.9,2.5,2,2.9,3.8c0.4,2.1,1.7,3.8,3.2,5.2c3,2.6,6,5,9.1,7.5c1.6,1.3,3.3,2.5,4.9,3.9
	c3.2,2.9,5.5,6.4,6.5,10.6c0.6,2.5,0.2,4.9-0.4,7.3c-0.1,0.5-0.6,1.1-1.1,1.4c-0.8,0.6-1.8,1.1-2.7,1.6c-2.9,1.5-5.4,3.4-7.6,5.8
	c-2.8,2.9-4.4,3.3-8.1,1.6c-2.6-1.2-5.1-2.6-7.5-4.2c-5.4-3.5-11.3-5-17.6-5.5c-1.9-0.1-3.8-0.5-5.7-0.9c-2-0.5-3.4-1.8-4.1-3.7
	c-2.3-6.4-5.2-12.7-6.3-19.6c-0.2-1.3-0.6-2.5-1-3.7c-0.9-2.4-2.4-4.3-4.7-5.5c-0.8-0.4-1.7-0.9-2.5-1.3c-2.4-1.2-4.4-2.9-5.8-5.3
	c-2.1-3.6-4.1-7.2-6.1-10.8c-0.3-0.6-0.6-1.3-0.8-2c-0.4-1.4-0.3-2.7,0.9-3.8c2.7-2.4,4.5-5.4,5.9-8.6c0.8-1.7,2.2-2.7,4-3.2
	c1.2-0.3,2.5-0.7,3.7-0.8c2.9-0.2,5.5-1.4,7.5-3.3c2.9-2.8,6.3-3.5,10.1-3.5c0.5,0,1.2,0.1,1.5,0.4c2.1,1.9,4.7,2.6,7.5,2.8
	C182.9,301,183,301.2,183.2,301.3z"/>
<path onClick={() => props.onFill(75)} fill={props.fillColors[75]} d="M202.7,274.8c-0.7-4.5-1.8-8.4-3.6-12c-0.8-1.6-1.7-3.1-2.7-4.5c-1.8-2.5-4.4-3.7-7.4-3.9
	c-2.7-0.2-5.4-0.3-8.1-0.5c-2.9-0.1-5.2-1.3-7.1-3.5c-1.7-2-2.9-4.1-3.6-6.8c-1.4-5.8-3.3-11.5-5.3-17.2
	c-2.1-6.2-6.5-10.7-11.4-14.8c-0.7-0.6-1.7-0.9-2.7-1.4c0.3-0.5,0.6-1,1-1.5c2.3-2.7,4.7-5.5,7.1-8.2c3-3.4,5.5-7.3,7.4-11.4
	c0.8-1.8,1.7-3.6,2.8-5.3c1.2-1.9,2.3-2.3,4.5-1.6c3.8,1.2,7.2,3,10.1,5.7c0.5,0.4,0.9,0.9,1.4,1.4c2.3,2.7,5.1,4.3,8.7,4.4
	c3.3,0.1,6.2,1.3,9.1,2.8c1.6,0.9,3.3,1.7,4.9,2.5c3,1.5,4.9,3.8,5.8,7c0.6,2.2,1.1,4.3,1.6,6.5c0.5,2.6,1.6,5,3.3,7.1
	c0.8,1.1,1.6,2.2,2.4,3.3c2.5,3.2,2.9,6.8,1.8,10.7c-0.8,2.8-2,5.4-3.8,7.7c-4.7,5.7-7.4,12.2-8.1,19.6
	C210,266.4,207,270.8,202.7,274.8z"/>
<path onClick={() => props.onFill(74)} fill={props.fillColors[74]} d="M338.6,464c-1.5-7.2-2.5-14-2.4-20.9c0-4.2,0.4-8.5,0.8-12.7c0.7-7.8,2.1-15.5,3.7-23.2
	c0.1-0.5,0.2-0.9,0.3-1.4c4.4-12.1,8.7-24.2,15-35.5c4.3-7.7,9.5-14.8,16.4-20.5c6.4-5.3,13.9-8.8,21.7-11.5
	c5.6-1.9,11.3-3.6,16.9-5.3c7.2-2.2,13.1-6.2,17.8-12c0.7-0.9,1.1-2.1,1.7-3.2c1.6-3.4,3.2-6.8,4.9-10.1c0.4-0.8,1-1.6,1.8-2.8
	c0,0.9,0.1,1.3,0,1.6c-2.3,9.6-6.4,18.2-13.2,25.5c-0.7,0.7-1.4,1.3-2.2,1.9c-5.4,4.1-10.8,8-16.1,12.1
	c-11.9,9.4-19.8,21.6-24.7,35.8c-3.8,11.2-7.6,22.4-11.6,33.5c-1.3,3.5-2.8,7-4.5,10.3c-3.8,7.3-8.2,14.3-13.1,20.9
	c-3.9,5.3-7.9,10.5-11.8,15.7C339.7,462.8,339.3,463.3,338.6,464z"/>
<path onClick={() => props.onFill(73)} fill={props.fillColors[73]} d="M291.3,460.6c0-1.2-0.1-2.4,0-3.5c0.5-8.9,1-17.7,1.4-26.6c0.4-8.9,0.7-17.9,1.3-26.8
	c0.3-4.1,1.3-8.2,2.2-12.2c2.8-13.1,5.6-26.2,8.5-39.3c0.2-1.1,0.7-2.1,1.3-3c11-16.4,22-32.8,33.2-49c3.9-5.6,8.5-10.8,12.8-16.2
	c0.4-0.5,1.1-1,1.7-1.3c5.9-2.5,11.7-5.2,17.9-6.8c3.9-1,7.9-1.4,12.2-1.7c-0.3,0.4-0.3,0.7-0.5,0.8c-6.7,5.6-12.3,12.3-18,18.9
	c-5.9,6.8-12,13.3-18,19.9c-6.5,7.1-10.8,15.4-12.3,25c-0.4,2.5-1.2,4.9-2.3,7.3c-5.6,11.5-9.2,23.6-12.2,36
	c-2.8,11.6-5.6,23.3-9.6,34.6c-2.5,7.1-5.4,14-8.4,20.9c-3.4,7.8-7.1,15.5-10.7,23.3C291.7,460.7,291.5,460.6,291.3,460.6z"/>
<path onClick={() => props.onFill(72)} fill={props.fillColors[72]} d="M421.8,200.7c-0.1-1.1-0.1-1.8-0.1-2.5c0-7.5,0-15,0-22.6c0-1.7,0.3-3.3,1.2-4.8
	c1.6-2.4,2.2-5,2.1-7.9c-0.1-4.4,0.1-8.8,0.5-13.2c0.2-2,0.6-4.1,1.4-6.1c2-5.6,2.1-11.2,0.6-17c-0.1-0.5-0.2-0.9-0.3-1.4
	c-0.3-1,0.1-1.8,0.9-2.5c3.2-3.1,6.9-5.1,11.3-5.8c2.3-0.3,4.6-0.7,6.8-1.2c5.8-1.3,10.3-4.7,14.3-8.8c1.8-1.9,3.5-3.9,5.4-6
	c0.6,1,0.4,1.9,0.4,2.8c0,24.2,0,48.3,0,72.5c0,0.4,0,0.8,0,1.2c0,1.9,0,2-1.9,2.5c-4.2,1-7.9,3-10.9,6.1c-0.5,0.5-1.4,0.8-2.1,0.9
	c-2,0.1-3.9,0.7-5.7,1.6c-1.9,0.9-4,1.6-5.9,2.5c-1.7,0.8-3.4,1.5-4.9,2.5c-3.5,2.4-7,4.8-11,6.4
	C423.3,200.2,422.7,200.3,421.8,200.7z"/>
<path onClick={() => props.onFill(71)} fill={props.fillColors[71]} d="M288.7,272.9c2.6-2.8,5.1-5.5,7.6-8.1c7.6-7.8,12.2-17.1,13.5-28c0.6-5.1,0.7-10.2,1-15.3
	c0.1-1.3,0-2.5,0-3.7c1.2-0.4,2.4-0.6,3.3-1.2c0.8-0.6,1.3-1.6,2-2.4c1.3-1.5,2.5-3.1,3.9-4.5c1.6-1.6,2.9-3.4,3.5-5.6
	c0.7-2.6,2.3-4.2,4.6-5.4c2.1-1.1,4.1-2.4,6.2-3.6c1.5-0.8,2.9-1.6,4.5-2.3c2.1-1,4.3-1.1,6.7-0.6c3.8,0.8,7.7,1.1,11.8,1.6
	c-0.1,0.3-0.2,0.9-0.4,1.3c-2,4.3-2.5,8.7-0.8,13.3c0.5,1.5,0.2,2.7-0.5,4.1c-12.3,23.6-24.5,47.2-36.7,70.8
	c-1.9,3.7-3.6,7.5-5.4,11.2c-0.4,0.8-0.8,1.5-1.4,2.6c-0.3-0.8-0.4-1.2-0.6-1.6c-2.3-7.3-6.7-12.8-14-15.7
	C293.8,278.4,291.3,275.7,288.7,272.9z"/>
<path onClick={() => props.onFill(70)} fill={props.fillColors[70]} d="M442.5,249.6c1.9,11.7,1.9,23-5.6,32.9c-0.5,0.7-1,1.4-1.5,2.2c-1.7,2.7-3.7,4.9-6.2,6.8
	c-3.8,2.8-6.9,6.4-9.6,10.3c-3.7,5.3-7.5,10.5-11.1,15.9c-3.6,5.5-8.2,10-13.2,14.2c-2.9,2.4-5.8,4.7-8.8,7c-0.9,0.7-2,1.4-3,1.9
	c-11.4,5.3-19.9,13.8-26.3,24.5c-2.6,4.3-4.8,8.9-7.1,13.3c-0.4,0.8-0.8,1.6-1.6,2.3c0.2-1.8,0.4-3.6,0.7-5.4
	c0.6-3.5,1.1-6.9,1.9-10.4c1.8-7.4,5.6-13.7,10.9-19.1c3.8-3.9,7.6-7.8,11.3-11.7c4.2-4.4,7.4-9.5,9.4-15.2
	c3.4-9.5,8.2-18.3,12.8-27.3c3.9-7.7,7.9-15.3,11.7-23.1c1.3-2.6,3-4.7,5.4-6.5c5.1-3.8,10.5-6.7,16.6-8.3c1.9-0.5,3.9-1.1,5.7-1.7
	C437.3,251.5,439.8,250.6,442.5,249.6z"/>
<path onClick={() => props.onFill(69)} fill={props.fillColors[69]} d="M334.8,346.2c0.2,0.4,0.4,0.7,0.5,1.1c2.5,8.8,4.9,17.7,5.7,26.9c0.6,7.1,0.5,14.2-0.6,21.3
	c-1.4,8.8-3,17.6-4.3,26.5c-1.3,8.5-2.3,17.1-1.7,25.8c0.4,5.3,1.4,10.6,2.1,15.9c0.1,0.5,0.2,1.1,0.3,2c-15.1,0-30.1,0-45.4,0
	c0.4-1,0.7-1.7,1-2.4c3.5-7.4,7.3-14.7,10.6-22.3c5-11.4,9.9-22.9,13.1-34.9c3.1-11.8,6.2-23.6,9.2-35.5c2.1-8.2,5.5-16,9-23.7
	C334.4,346.6,334.6,346.4,334.8,346.2z"/>
<path onClick={() => props.onFill(68)} fill={props.fillColors[68]} d="M356.1,279c1.4-1.6,2.8-3.3,4.1-4.9c1.3-1.6,2.6-3.2,3.7-5c0.9-1.4,2-2.4,3.5-3.1
	c6.1-2.9,12.1-5.9,18.1-8.8c8.6-4.2,16.3-9.7,23.2-16.5c4.5-4.4,9.3-8.5,14.6-12.1c4.6-3.2,8.3-7.5,12.1-11.6
	c6-6.7,11.4-13.8,16.5-21.2c1.5-2.2,3.1-4.5,2.7-7.5c-0.1-0.4,0.3-0.9,0.6-1.2c2.9-3.2,6.6-4.9,11-5.6c0,0.9,0.1,1.7,0.1,2.5
	c0,13.1,0,26.2,0,39.4c0,1.7-0.4,3-1.6,4.3c-4.4,4.5-8.5,9.1-13,13.4c-4.8,4.6-10.6,7.6-17,9.4c-1.8,0.5-3.5,1.1-5.3,1.5
	c-6.9,1.7-13.1,5-18.6,9.5c-1.8,1.4-3.7,2.4-5.9,3.1c-5.8,1.9-11.5,3.8-16.8,6.9c-0.8,0.5-1.8,0.5-2.8,0.6c-6,0.3-12,0.8-17.7,2.9
	c-3,1.1-6,2.3-8.9,3.4c-0.7,0.3-1.5,0.6-2.2,0.9C356.3,279.2,356.2,279.1,356.1,279z"/>
<path onClick={() => props.onFill(67)} fill={props.fillColors[67]} d="M152.2,303.6c-3.3,0.9-5.1,3.1-6.2,5.8c-0.9,2.2-2.3,4.2-4.1,5.8c-1.7,1.6-3.5,3.3-5,5.1
	c-1.5,1.8-3,3.8-3.9,6c-1.7,4.3-2.9,8.8-4.3,13.2c-0.8,2.4-1.4,4.7-2.3,7.1c-1.9,5.1-5.6,8-11,8.7c-4,0.5-8,1.3-12.1,1.8
	c-3.6,0.5-6.3,2.3-8.6,5.1c-1.5,1.9-3.4,3.6-5.2,5.2c-0.9,0.8-2,1.1-3.3,0.8c-1.3-0.3-2.7-0.4-4-0.3c-3.3,0.2-7.7-4.7-7.9-7.6
	c-0.2-1.8,0.5-3.3,1.6-4.6c1.8-2.4,4.1-4.3,6.6-5.8c1.9-1.2,3.9-2.2,5.9-3.3c4.1-2.3,4.7-3.9,3.3-8.2c-0.4-1.4-0.9-2.7-1.3-4.1
	c-1.1-4.2,0.2-7.7,3.5-10.4c1.3-1.1,2.6-2.1,4-3c4.6-2.9,6.1-7.3,5.6-12.4c-0.3-3.5,0.6-6,3.3-8.2c2.2-1.8,4.1-3.8,6.2-5.6
	c1.6-1.4,3.4-2.1,5.5-2.1c1.8,0,3.4,0.6,4.2,2.2c2,3.9,5.6,5.2,9.4,5.1c6.8-0.2,13,2.2,19.5,3.3
	C151.6,303.1,151.7,303.3,152.2,303.6z"/>
<path onClick={() => props.onFill(66)} fill={props.fillColors[66]} d="M372.4,412.8c1.3-3.8,2.6-7.7,3.9-11.5c3-8.4,5.8-16.9,9.1-25.2c5-12.8,13.6-22.8,24.5-31
	c3.5-2.6,6.8-5.3,10.4-7.7c5.8-4,9.8-9.5,13.2-15.6c2.1-3.9,3.5-8,4.7-12.3c0.4-1.6,1.1-3.2,1.3-4.8c0.4-3.2,2.4-5.1,4.9-6.7
	c4.9-3.3,10.5-4.6,16.2-5.2c1.7-0.2,3.5-0.4,5.3-0.6c0.1,0.8,0.2,1.4,0.2,2c0,11.4,0,22.9,0,34.3c0,1.4-0.4,2.4-1.2,3.5
	c-2.3,3.5-5.4,5.9-9.7,6.3c-0.4,0-0.8,0.2-1.2,0.3c-6.4,0.6-10.7,4.2-13.8,9.6c-0.9,1.5-1.8,2.4-3.7,2.6c-2.1,0.2-3.6,1.6-5.1,2.9
	c-1.7,1.3-3.4,2.7-5.3,3.7c-13,6.6-24.6,15.1-35.6,24.6c-3.9,3.4-6.8,7.4-8.6,12.2c-1.3,3.4-2.6,6.9-4,10.2c-1.3,3.2-3,6.1-5.4,8.7
	C372.6,413,372.5,412.9,372.4,412.8z"/>
<path onClick={() => props.onFill(65)} fill={props.fillColors[65]} d="M122,403.4c3.4-0.5,6.9-1.2,10.3-1.6c21.1-2.5,39.6,3.9,56.1,16.8c6.5,5.1,12.1,10.9,17.1,17.4
	c1.8,2.3,3.8,4.5,5.6,6.9c6.7,8.5,15.4,14.1,25.3,18c3.3,1.3,6.6,2.5,9.9,3.7c0.6,0.2,1.1,0.5,1.6,1c-21,0-42,0-63.2,0
	c-0.5-1.1-1-2.2-1.5-3.4c-3.2-7.6-6.5-15.1-10.5-22.3c-7.5-13.7-18.9-23-33.1-28.9c-5.4-2.2-10.9-4.2-16.3-6.4
	c-0.5-0.2-1-0.4-1.5-0.7C122,403.7,122,403.5,122,403.4z"/>
<path onClick={() => props.onFill(64)} fill={props.fillColors[64]} d="M406.2,266.7c-1.4,2.8-2.9,5.7-4.3,8.5c-5.4,10.4-10.8,20.7-16,31.1c-1.3,2.6-2.3,5.3-3.2,8
	c-3.1,9-8.1,16.8-15,23.4c-2.5,2.4-4.9,5.1-7.4,7.5c-6.9,6.8-10.7,15.3-12.2,24.7c-0.9,5.5-1.3,11.1-2,16.7
	c-0.5,3.4-2.1,6.4-3.8,9.5c0.1-1,0.3-2,0.4-3c0.8-7.3,0.7-14.7-0.1-22c-1-8.8-3.5-17.2-5.8-25.7c-0.4-1.7-0.6-3.2-0.2-4.9
	c1-4.8,2-9.5,4.2-14c2.2-4.3,4.9-8.3,8.2-11.7c8.5-8.9,16.6-18.2,24.6-27.6c2.7-3.1,5.6-5.9,8.6-8.7c4.9-4.7,10.9-7.8,17.3-10.1
	c2.2-0.8,4.3-1.5,6.5-2.2C406,266.4,406.1,266.5,406.2,266.7z"/>
<path onClick={() => props.onFill(63)} fill={props.fillColors[63]} d="M339.9,465.6c1.2-1.6,2-2.7,2.8-3.8c3.9-5.2,7.8-10.5,11.8-15.7c0.9-1.1,1.9-2.2,3-3.1
	c3.7-3.2,7.5-6.2,11.2-9.4c3.6-3,7.3-5.7,11.4-7.9c8.1-4.4,15-10.3,21-17.2c4.5-5.1,9.1-10,14.4-14.4c2.4-2,4.4-4.5,6.7-6.9
	c3.6,6.6,7,13.4,8.2,21c0.7,4.7,0,9.2-2.3,13.5c-0.4,0.6-0.7,1.2-1,1.9c-1.1,2.6-2.9,3.9-5.8,4.6c-9.1,2-16.7,7-23.9,12.8
	c-7.3,5.9-13.5,12.9-19.5,20.1c-1.3,1.5-2.4,3.6-4,4.3c-1.7,0.8-3.9,0.3-5.9,0.3c-8.4,0-16.8,0-25.2,0
	C342,465.6,341.3,465.6,339.9,465.6z"/>
<path onClick={() => props.onFill(62)} fill={props.fillColors[62]} d="M241.6,287c-0.3-0.6-0.6-1.1-0.8-1.5c-1.5-2.8-3.7-4.8-6.8-5.7c-2.9-0.9-5.8-1.9-8.7-2.6
	c-2.3-0.5-4.7-0.7-7.3-1c0.8-0.5,1.4-0.9,2-1.3c4.2-2.3,7.3-5.5,8.6-10.1c1-3.6,3.2-6.2,6.5-7.9c0.3-0.1,0.5-0.4,0.8-0.5
	c3.7-2,5.6-4.9,5-9.2c0-0.3,0-0.6,0-1.2c1.2-0.3,2.4-0.7,3.6-0.9c3.8-0.6,7.6-0.5,11.4,0.7c3.6,1.2,6,3.5,6.8,7.3
	c0.4,1.9,0.9,3.7,1.2,5.6c0.7,3.8,3,6.3,6.5,7.7c2.2,0.9,4.5,1.7,6.8,2.4c4.8,1.5,9.1,3.9,12.4,7.7c2.2,2.5,5,4.1,8.1,5.4
	c6.8,3,10.7,8.5,12.3,15.5c0.9,4-0.4,6.5-4.3,7.9c-2.5,0.9-5.3,1.2-7.9,1.9c-3.7,0.9-7.2,0-10.7-1.4c-5.8-2.3-10.7-5.7-15.3-9.7
	c-0.6-0.6-1.1-1.3-1.6-2c-1.2-1.6-2.3-3.2-3.5-4.8c-4-5.4-9.2-7.1-15.6-5.4c-2.5,0.7-5,1.6-7.5,2.5
	C242.9,286.5,242.3,286.7,241.6,287z"/>
<path onClick={() => props.onFill(61)} fill={props.fillColors[61]} d="M367.2,263.6c1.4-2.8,2.4-5.7,2.5-8.9c0-0.8,0.2-1.6,0.1-2.4c-0.4-2.4,0.5-4.3,2-6.2
	c3.7-4.7,7.1-9.7,10.9-14.3c4.9-6,11.2-10.5,17.4-15c4.9-3.5,9.8-6.9,14.8-10.3c2.5-1.7,5.2-3.2,8-4.4c4.6-1.9,8.9-4.3,12.9-7.2
	c1.4-1,3.1-1.6,4.7-2.4c1.9-0.9,3.8-1.7,5.7-2.4c1.4-0.6,2.8-1.1,4.3-1.5c0.6-0.2,1.4,0,2.1,0.1c0.4,1.9-0.5,3.2-1.4,4.4
	c-2.3,3.4-4.7,6.7-7.1,10c-4.4,5.9-9,11.6-14.2,16.9c-2.7,2.7-5.6,5.3-8.6,7.6c-4.3,3.2-8.6,6.5-12.3,10.3
	c-8.3,8.3-17.9,14.6-28.4,19.7c-4.3,2.1-8.6,4.2-12.9,6.3C367.5,263.9,367.4,263.8,367.2,263.6z"/>
<path onClick={() => props.onFill(60)} fill={props.fillColors[60]} d="M360,437.9c0.4-0.7,0.8-1.3,1.2-2c2.7-5,5.4-10,8.1-14.9c0.8-1.5,1.7-2.9,2.8-4.1c4-4.4,7-9.3,9-14.9
	c0.7-2,1.6-4,2.3-6c1.9-5.4,5.1-9.7,9.4-13.3c7.9-6.5,15.8-13,24.7-18.2c3.5-2,7.1-3.9,10.7-6c1.5-0.9,2.9-1.9,4.3-3
	c1.5-1.2,2.9-2.5,4.9-3c2.2-0.5,3.2,0.4,2.5,2.5c-0.6,1.8-1.3,3.6-2.3,5.3c-4.2,7.4-8.7,14.5-13.7,21.4c-4,5.5-8.5,10.5-13.7,15
	c-3.9,3.5-7.4,7.5-10.9,11.4c-5.3,6-11.2,11.2-18.2,15.1c-6.9,3.8-12.9,8.6-18.8,13.6c-0.6,0.5-1.2,1-1.9,1.5
	C360.2,438.1,360.1,438,360,437.9z"/>
<path onClick={() => props.onFill(59)} fill={props.fillColors[59]} d="M89.1,369.7c2.7-1.5,5-3.5,6.7-6.1c2.2-3.3,5.3-4.6,9.1-5.1c4-0.5,8.1-1.2,12.1-1.8
	c5.4-0.9,9-4.1,10.9-9.1c1.3-3.3,2.1-6.9,3.2-10.3c1.3-3.9,2.6-7.7,4.1-11.5c0.8-2.1,2.3-3.8,4.1-5.1c3.5,6.4,6.4,13,11.4,18.6
	c-1.7,4.6-3.5,9.1-7.4,12.5c-2.9,2.6-5.2,5.6-5.8,9.6c-0.4,2.5-2,3.9-4.3,4.6c-4,1.2-7.7,2.9-11.2,5.3c-3.2,2.1-5.9,4.7-8.1,7.7
	c-1.8,2.4-3.6,4.7-5.4,7c-1.5,1.9-3.4,3.3-5.7,4.2c-2.4,0.9-3.9,0.6-5.6-1.3c-0.5-0.6-1.1-1.2-1.4-1.9
	C92.4,381.7,89.4,376.4,89.1,369.7z"/>
<path onClick={() => props.onFill(58)} fill={props.fillColors[58]} d="M318.6,308.8c0.1-0.4,0.2-0.9,0.4-1.3c5-13,11.5-25.3,19.2-36.9c5.9-8.8,12-17.5,17.8-26.5
	c3.2-5,5.9-10.2,8.8-15.4c0.8-1.5,1.9-2.7,3.3-3.7c10-7.1,20.5-13.2,31.3-19c4.8-2.6,8.9-6,12.3-10.1c1.2-1.5,2.4-3,3.7-4.4
	c1.4-1.7,2.2-3.6,2.2-5.9c0-2.9,0.7-5,2.2-7.2c0.1,0.5,0.1,0.8,0.1,1c0,6.9,0,13.8,0,20.6c0,0.9-0.2,1.6-1.1,2.2
	c-7,4.7-13.9,9.4-20.9,14.1c-10.1,6.8-18.4,15.3-25.2,25.4c-0.3,0.4-0.6,0.8-0.8,1.2c-2.3,3.7-5.3,6.8-8.7,9.5
	c-6.3,5-11,11.3-15.4,18c-8.7,13.1-18.1,25.7-28.3,37.8c-0.2,0.3-0.4,0.6-0.6,1C318.9,309,318.8,308.9,318.6,308.8z"/>
<path onClick={() => props.onFill(57)} fill={props.fillColors[57]} d="M231.5,412.3c1.8,0.2,3.2,0.5,4.7,0.6c3.6,0.4,6.6-0.9,8.8-3.8c1.2-1.7,2.2-3.5,3.3-5.3
	c0.6-1,1-2.1,1.8-3.7c0.5,1.4,0.8,2.3,1,3.2c2.3,8,4.9,15.9,8.5,23.3c4.9,10,11.6,18.9,18.7,27.5c2.8,3.4,5.7,6.7,8.5,10.1
	c0.3,0.3,0.4,0.6,0.7,1c-1.7,0.6-24.6,0.8-31.5,0.3C247.9,447.9,239.7,430.2,231.5,412.3z"/>
<path onClick={() => props.onFill(56)} fill={props.fillColors[56]} d="M110.9,213.4c-0.8,2.8,0,5.1,0.8,7.5c0.6,1.7,0.4,3.1-0.8,4.4c-1.2,1.2-1.6,2.8-1.5,4.4
	c0.1,2-0.6,3.8-1.8,5.3c-4.4,5.5-4.5,11.6-2.4,18c1.2,3.9,2.8,7.6,4.4,11.4c1.3,3.1,3.4,5.5,6.6,6.6c8.8,3.1,16,9,23.4,14.3
	c0.2,0.1,0.3,0.3,0.6,0.7c-1.4,0.7-2.8,0.9-4.1,0c-3.9-2.7-8.3-3.1-12.8-3.3c-3.5-0.2-7-0.8-10.3-2c-2.6-0.9-5.1-1.8-7.6-2.8
	c-4-1.5-6.8-4.3-7.5-8.6c-0.6-3.3-2.6-5.6-5.2-7.4c-2.7-1.9-4.5-4.5-5.7-7.5c-0.5-1.1-0.5-2.1,0.2-3.1c0.9-1.5,1.8-3,2.4-4.7
	c1.6-3.9,0.8-5.9-2.7-8c-1.1-0.6-2.3-1.2-3.3-2c-1-0.9-2-1.9-2.6-3c-1.1-2.4-0.3-4.4,2.1-5.5c1.4-0.6,2.8-1.1,4.2-1.7
	c1.8-0.8,3-2,3.6-3.9c0.4-1.3,0.8-2.6,1.4-3.8c1.2-3,3.8-5.8,8.1-4.6c0.2,0,0.3,0,0.5,0.1C105.9,214.9,105.9,214.9,110.9,213.4z"/>
<path onClick={() => props.onFill(55)} fill={props.fillColors[55]} d="M317.2,106.4c2.3,1.8,2.3,4.2,2.3,6.5c0,3.3-0.7,6.3-2.5,9.3c-2.5,4.1-3.2,8.9-2,13.6
	c0.6,2.4,1.2,4.7,0.1,7.2c-0.5,1-0.2,2.5,0,3.8c0.5,2.3,0.1,4.3-1.4,6.1c-1.4,1.6-2.7,3.5-4.5,4.7c-3.4,2.3-7.2,2.2-10.9,0.6
	c-0.7-0.3-1.4-0.8-2-1.2c-2.4-1.8-5.2-2.3-8.1-2.2c-3.1,0.1-6.2,0.6-9.3,0c-2.6-0.5-5.1-1.2-7.3-3c0.4-0.5,0.6-1,0.9-1.4
	c6-8.2,13.6-14.8,21-21.6c7.7-7.1,15.3-14.3,23-21.4C316.6,106.9,316.9,106.7,317.2,106.4z"/>
<path onClick={() => props.onFill(54)} fill={props.fillColors[54]} d="M253.4,169.4c1.4-0.6,2.3-1,3.1-1.4c3.4-1.8,4.5-4.1,4-7.9c-0.3-2.1-0.6-4.3-0.9-6.5
	c0.4-0.2,0.7-0.4,1-0.4c2.5-0.2,4.9-0.3,7.4-0.5c1.1-0.1,2,0,3,0.6c3.6,2.2,7.6,3.2,11.9,3c2.3-0.1,4.6,0,6.9-0.1
	c1.7,0,3.4,0.3,4.7,1.4c3.9,3,8.2,3.7,12.9,2.5c1.4-0.4,2.6,0.1,3.9,0.8c4.9,2.6,7.9,9.4,6.2,14.7c-0.4,1.2-1,2.4-1.8,3.4
	c-1.1,1.5-3.3,2-4.7,1.1c-2.9-2-6.1-2.1-9.4-2.1c-6,0.1-12,0.8-17.9-0.1c-2.8-0.4-5.6-0.7-8.2-1.5c-6.4-1.8-12.7-3.8-19-5.8
	C255.5,170.5,254.7,170,253.4,169.4z"/>
<path onClick={() => props.onFill(53)} fill={props.fillColors[53]} d="M436.1,286.9c1.1-1.6,1.8-2.8,2.6-4c5.3-7.8,7.4-16.3,6.3-25.7c-0.3-2.4-0.4-4.8-0.6-7.2
	c-0.1-1,0.1-1.8,1.1-2.4c6.9-3.9,12.2-9.7,17.4-15.6c0.9-1,1.8-2,2.8-3c0.6,1.8,0.7,57.1,0.2,60.6c-0.8-0.2-1.5-0.4-2.2-0.7
	c-4.6-1.5-9.1-2.9-13.9-3.6c-4.1-0.6-8.1-0.3-12,1C437.5,286.6,437.1,286.7,436.1,286.9z"/>
<path onClick={() => props.onFill(52)} fill={props.fillColors[52]} d="M410.4,180.8c1.2,0.3,2.9,0.8,4.6,1.3c0.7,0.2,1.2,0.8,1.1,1.6c-0.2,2.1-0.3,4.1-1.6,5.9
	c-4.9,6.4-10.4,12-17.7,15.8c-10.4,5.4-20.4,11.5-30,18.3c-1.6,1.1-3.2,2.1-4.9,3c-0.9,0.5-2,1.2-2.9,0.4c-1.1-0.9-1.8-2.4-1.3-3.6
	c1.3-3,1.5-6.3,3.8-8.9c3.9-4.5,7.4-9.2,11.2-13.7c6.4-7.5,14.5-12.7,23.7-16.1c3.8-1.4,7.5-3.2,11.6-3.6
	C408.6,181.1,409.3,181,410.4,180.8z"/>
<path onClick={() => props.onFill(51)} fill={props.fillColors[51]} d="M433.2,443.8c-0.4,0.5-0.6,0.8-0.8,1c-5.6,4.6-9.9,10.4-13.8,16.5c-1,1.5-1.8,3.3-3.2,4.1
	c-1.3,0.7-3.3,0.2-5,0.2c-10.6,0-21.3,0-31.9,0c-0.5,0-1,0-1.7,0c0.2-0.5,0.3-0.9,0.5-1.1c6.4-7.7,12.8-15.4,20.6-21.7
	c2-1.6,4-3.2,6.1-4.7c0.8-0.6,1.9-1.1,2.8-1.2c3.9-0.1,7.8-0.1,11.7,0.1c4.2,0.3,7.8,2.3,11.2,4.5
	C430.9,442.3,431.9,443,433.2,443.8z"/>
<path onClick={() => props.onFill(50)} fill={props.fillColors[50]} d="M499.4,467.6c0,10.4,0,20.6,0,30.8c-1.9,0.5-28.6,0.6-31.3,0.1c0-10.2,0-20.5,0-30.8
	C469.8,467.3,495.2,467.1,499.4,467.6z"/>
<path onClick={() => props.onFill(49)} fill={props.fillColors[49]} d="M468.1,32.9c0-10.5,0-20.7,0-31c10.4,0,20.8,0,31.5,0c0,10.4,0,20.6,0,31
	C488.9,32.9,478.6,32.9,468.1,32.9z"/>
<path onClick={() => props.onFill(48)} fill={props.fillColors[48]} d="M33.5,33c-10.4,0-20.6,0-31,0c0-10.4,0-20.6,0-31.1c10.3,0,20.6,0,30.8,0C33.8,3.7,33.9,29.6,33.5,33
	z"/>
<path onClick={() => props.onFill(47)} fill={props.fillColors[47]} d="M33.6,467.6c0,10.4,0,20.7,0,31.1c-10.4,0-20.6,0-31.1,0c0-10.3,0-20.6,0-30.9
	C4.2,467.3,28.1,467.1,33.6,467.6z"/>
<path onClick={() => props.onFill(46)} fill={props.fillColors[46]} d="M211.7,385c3.3-4.2,7.5-6.9,12.2-9.1c0.5,0.3,1.1,0.6,1.5,1c3.3,2.9,6.7,3,11.2,0
	c3.5-2.3,6.5-5.1,8.7-8.8c0.1-0.2,0.2-0.4,0.8-0.5c0.2,1.2,0.4,2.4,0.5,3.6c0.8,7.7,1.6,15.4,2.3,23.1c0.2,1.8,0.1,3.5-0.8,5.1
	c-1.3,2.3-2.2,4.8-3.6,7c-2.3,3.7-5.5,5.5-9.9,4.4c-0.5-0.1-1.1-0.2-1.6-0.3c-2.1-0.2-3.4-1.3-4.6-3c-4.8-6.7-9.8-13.2-14.7-19.8
	C213.1,386.9,212.4,386,211.7,385z"/>
<path onClick={() => props.onFill(45)} fill={props.fillColors[45]} d="M293.4,394.9c-1.1-5.5-5-8.1-8.8-10.9c1.1-4.3,2.2-8.3,3.1-12.5c3-14.6,8.9-28.3,13.9-42.3
	c2.9-8.1,5.8-16.2,9-24.2c2-5.1,4.5-10,6.8-15c0.1-0.2,0.3-0.3,0.7-0.6c0.9,2.7,1.9,5.2,2.5,7.8c0.2,0.9-0.2,2-0.5,2.9
	c-4.4,10.3-8.3,20.8-11.5,31.5c-3.6,12-6.5,24.2-9.1,36.5c-1.7,8.3-3.5,16.6-5.3,24.8C294,393.4,293.8,393.9,293.4,394.9z"/>
<path onClick={() => props.onFill(44)} fill={props.fillColors[44]} d="M284.2,386.1c4,2.3,7,5.1,7.5,9.6c0.4,3.7,0.5,7.5,0.4,11.2c-0.1,6-0.5,12-0.8,17.9
	c-0.4,8.9-0.9,17.7-1.3,26.6c-0.2,3.3-0.4,6.7-0.6,10c0,0.7-0.2,1.4-0.3,2c-0.2,0.1-0.4,0.2-0.6,0.4c-0.3-0.5-0.6-1.1-1-1.6
	c-3.3-3.9-6.6-7.8-9.9-11.7c-0.9-1.1-1.1-2.1-1-3.5c1.1-10.6,2.1-21.3,3.3-31.9c0.5-4.8,1.3-9.6,2-14.5c0.6-3.9,1.2-7.9,1.8-11.8
	C283.8,388.1,284,387.2,284.2,386.1z"/>
<path onClick={() => props.onFill(43)} fill={props.fillColors[43]} d="M462.2,290.6c-3.3,0.6-6.2,1-9,1.7c-7.8,1.9-14,6.1-18.3,12.9c-2,3.2-3.9,6.6-5.2,10.2
	c-1.7,4.7-5.1,7.8-8.8,10.8c-2.9,2.4-6.4,3.8-10,5c-5.9,1.9-11.9,3.7-17.8,5.6c-0.1,0-0.3,0-0.8-0.1c0.9-0.7,1.5-1.3,2.2-1.9
	c6.3-5,11.9-10.6,16.3-17.4c3.2-4.9,6.7-9.7,10.1-14.6c2.6-3.7,5.7-6.9,9.1-9.8c6.5-5.5,13.9-7.3,22.2-5.4c2.9,0.6,5.7,1.5,8.5,2.3
	C461.2,290,461.5,290.3,462.2,290.6z"/>
<path onClick={() => props.onFill(42)} fill={props.fillColors[42]} d="M368,251c0.2,4.2-0.4,7.7-2.1,11.1c-2.3,4.5-5.2,8.6-8.5,12.4c-10,11.8-19.8,23.9-28.2,36.9
	c-6.7,10.3-13.7,20.4-20.6,30.6c-0.4,0.5-0.7,1-1.4,1.9c0-0.9,0-1.3,0.1-1.6c2.6-8.3,5.1-16.6,7.8-24.9c0.4-1.2,1.1-2.3,1.9-3.3
	c5.3-6.5,10.8-12.9,15.8-19.6c6.7-9,13-18.2,19.4-27.4c4-5.8,8.7-10.8,14.2-15.2C366.8,251.7,367.2,251.5,368,251z"/>
<path onClick={() => props.onFill(41)} fill={props.fillColors[41]} d="M322,295.5c-1.1-3.1-2-5.6-2.7-8.2c-0.2-0.8,0.3-1.8,0.7-2.6c4.8-9.4,9.7-18.7,14.6-28
	c7.1-13.7,14.2-27.5,21.3-41.3c0.3-0.6,0.7-1.2,1.2-2.1c0.9,2,0.7,3.8,0.3,5.6c-0.3,1-0.6,2-1.1,2.9c-1.2,2.2-0.4,4.2,0.7,6.1
	c0.8,1.3,2,1.7,3.5,1.4c0.6-0.1,1.2-0.4,2.2-0.7c-0.4,0.9-0.7,1.6-1,2.2c-5,9.9-11.5,18.9-17.7,28.2c-5.5,8.2-11.2,16.3-15.9,25
	C326.1,287.5,324.2,291.2,322,295.5z"/>
<path onClick={() => props.onFill(40)} fill={props.fillColors[40]} d="M359.4,214.2c-0.9-3.5-1.8-6.4-2.5-9.5c-0.7-3.4,0.3-6.5,1.8-9.5c1.6-3,3.3-5.9,5.1-8.7
	c2.7-4.3,4.8-8.8,6.2-13.7c0.3-0.9,0.6-1.8,0.9-2.7c1.4-5.5,4.9-9.3,9.9-12.2c0.8,1.9,1.1,3.9,1.2,6c0.1,2,0,4-0.2,6
	c-0.4,4.2-0.3,8.2,2.2,11.9c1.8,2.6,1.2,6-1.4,7.9c-8.5,6.1-15,14.1-21.2,22.3C360.8,212.5,360.3,213.1,359.4,214.2z"/>
<path onClick={() => props.onFill(39)} fill={props.fillColors[39]} d="M383.5,177.2c0-2.9-0.3-5.3,0.1-7.6c0.6-4,0.2-7.8-0.8-11.6c-0.3-1.2,0-1.9,0.9-2.7
	c3.8-3.2,6.7-7,9.1-11.4c2.5-4.6,5-9.3,8.8-13.1c1.8-1.7,3.6-3.4,5.5-5c0.4-0.3,0.8-0.6,1.2-0.8c2.5-1.4,3.7-0.9,4,2
	c0.2,2.1,0.1,4.3,0,6.5c0,2.3-0.7,4.3-2.1,6.2c-3,4.1-5.8,8.4-8.9,12.5c-3.3,4.3-5.6,8.9-6.4,14.3c-0.2,1.8-1,3-2.6,4
	c-2.4,1.6-4.7,3.6-7,5.4C384.9,176.3,384.4,176.6,383.5,177.2z"/>
<path onClick={() => props.onFill(38)} fill={props.fillColors[38]} d="M425.2,125.5c1.4,4.5,2.4,8.9,1.1,13.2c-1.8,6.1-3,12.2-2.6,18.6c0.1,1.1-0.3,2.3-0.4,3.4
	c-0.3,0-0.4,0.1-0.5,0.1c-3.9-2.4-7.7-1.7-11.6,0.2c-4.7,2.3-9.5,4.4-14.6,6.7c0.2-2.8,0.8-5.3,1.9-7.6c0.8-1.7,1.7-3.3,2.8-4.7
	c4.2-5.7,8.5-11.4,12.2-17.5C416.4,132.9,420.7,129.3,425.2,125.5z"/>
<path onClick={() => props.onFill(37)} fill={props.fillColors[37]} d="M199.5,333.9c-3.6-1.9-6.3-4.5-7.9-8.2c-1.9-4.5-4-9-6.1-13.5c-1.6-3.6-1.9-7.2,0.1-10.8
	c1.2-2.2,2.9-3.9,5.4-4.4c2.1-0.5,4.2-0.9,6.4-0.9c2.7-0.1,3.9,0.8,4.7,3.5c0.5,1.9,0.8,3.9,0.9,5.9c0.6,9.4-0.7,18.6-3.1,27.7
	C199.8,333.3,199.7,333.5,199.5,333.9z"/>
<path onClick={() => props.onFill(36)} fill={props.fillColors[36]} d="M384.6,179c4.9-4.6,10.1-8.6,16.1-11.4c4.2-2,8.4-3.8,12.6-5.7c1.9-0.9,4-1.4,6.1-0.7
	c3.4,1.1,5.1,3.1,3.1,6.6c-2.2,4-4.3,8-5.7,12.3c0,0.1-0.2,0.2-0.5,0.4c-2.9-1.2-6.1-1.7-9.2-1.1c-2.6,0.5-5.1,1.2-7.6,2.2
	c-4.3,1.6-8.5,3.5-12.8,5.3c-0.1-1.3,0.1-2.8-0.3-4.1C386.1,181.6,385.3,180.4,384.6,179z"/>
<path onClick={() => props.onFill(35)} fill={props.fillColors[35]} d="M290.8,309.2c-1.6,3.9-3.8,5.3-7.7,4.6c-4.5-0.8-8.6-2.5-11.5-6.3c-0.2-0.3-0.6-0.6-0.8-0.9
	c-2.9-3.8-6.6-5.6-11.4-5c-2.1,0.3-4.1-0.4-6-1.4c-1.9-1-3.9-1.9-5.9-2.7c-3.9-1.5-5.9-4.2-5.4-8.7c4.1-1.7,8.3-3.4,12.9-3.7
	c3.1-0.2,5.9,0.7,8,3c1.9,2.1,3.6,4.5,5.4,6.7c1.1,1.3,2.3,2.7,3.5,3.8c5,4.5,10.7,7.7,17.1,9.8
	C289.7,308.6,290.2,308.9,290.8,309.2z"/>
<path onClick={() => props.onFill(34)} fill={props.fillColors[34]} d="M246,242.8c-0.5,0.2-1,0.4-1.4,0.4c-3.6,0.3-6.7,1.8-9.7,3.7c-3.9,2.4-7.9,4.8-12,7
	c-3.4,1.8-6.3,4-9,6.7c-0.5,0.5-1,0.9-1.8,1.7c0.8-6.4,1.7-12.3,5.8-17.1c1-1.1,1.8-2.5,2.6-3.7c3.2-4.8,4.3-8.7,4.1-14.4
	c0.5,0.1,1.1,0.1,1.5,0.2c3,0.9,6.1,1.8,9.1,2.9C241,232.5,244.8,236.4,246,242.8z"/>
<path onClick={() => props.onFill(33)} fill={props.fillColors[33]} d="M170.3,250.4c3.4,6.9,6.1,13.3,6.7,20.4c0.1,1.8-0.4,3.8-1,5.6c-0.7,2.2-1.8,4.2-2.7,6.4
	c-1.8,4.3-2,5.8-1.2,10.5c-0.9-0.5-1.7-1-2.5-1.3c-1.2-0.4-2.5-0.6-3.7-0.7c-1.6,0-2.8-0.7-3.5-2c-2-3.9-3.7-7.8-3.5-12.3
	c0.1-2.1,0.7-4.1,1.8-5.9c0.1-0.1,0.2-0.3,0.3-0.4c4.6-5.4,7.1-11.9,8.9-18.6C169.8,251.7,169.9,251.4,170.3,250.4z"/>
<path onClick={() => props.onFill(32)} fill={props.fillColors[32]} d="M123.4,292.4c2.9-1.8,6.1-2.8,9.4-3.4c5.4-1,10.9-1.3,16.5-1.1c3.7,0.1,7.2,1.2,10.7,2.5
	c0.4,0.2,0.9,0.4,1.2,0.7c1.4,1.6,3.2,2.1,5.2,2.1c2.2,0,3.7,1,5.2,2.5c-0.1,0.2-0.2,0.4-0.3,0.4c-3.6,0.3-6.6,1.7-9.2,4.2
	c-1.9,1.8-4.2,2.5-7,1.8c-4.1-1.1-8.2-2-12.4-2.8c-3-0.5-6-0.8-9-1.1c-1.3-0.1-2.6-0.1-3.8-0.3C127,297.1,124.6,295.6,123.4,292.4z"
	/>
<path onClick={() => props.onFill(31)} fill={props.fillColors[31]} d="M173.8,253.1c3.2,2.3,6.4,2.7,9.7,2.7c1.8,0,3.5,0.2,5.3,0.3c2.9,0.2,5.2,1.6,6.7,4.1
	c2.8,4.3,4.5,9,5.2,14c0.6,4.4-0.1,8.6-1.8,12.7c-0.2-0.2-0.4-0.3-0.4-0.4c-0.7-3.7-3.2-6-6.1-8c-2.2-1.5-4.4-2.9-6.5-4.4
	c-1.5-1.1-3-2.2-4.3-3.4c-2.1-2-3.6-4.3-4.3-7.3C176.5,260.1,175.1,256.9,173.8,253.1z"/>
<path onClick={() => props.onFill(30)} fill={props.fillColors[30]} d="M178.9,270.8c3,3.1,6.6,5.2,10.1,7.5c1.8,1.3,3.7,2.5,5.3,4c3,2.9,2.7,5.5,1.6,8.8
	c-0.6,1.8-2.3,3.1-4.2,3.6c-2.5,0.7-4.8,1.7-6.5,3.8c-0.3,0.4-1.1,0.7-1.6,0.7c-5.5-0.3-8.9-2.5-10.1-8.6c-0.2-1.1-0.2-2.3,0.1-3.3
	c0.5-1.8,1.2-3.6,1.9-5.4C177.1,278.5,178.9,275,178.9,270.8z"/>
<path onClick={() => props.onFill(29)} fill={props.fillColors[29]} d="M455,451.8c-0.8-0.2-1.1-0.2-1.5-0.3c-7.6-2.9-15-6.2-21.9-10.8c-3.3-2.2-6.6-4.3-10.6-4.9
	c-2.6-0.4-5.2-0.6-7.8-0.8c-1-0.1-2,0-3.1,0c0.1-0.4,0.1-0.6,0.2-0.7c6.1-3.4,12.5-5.7,19.6-5.5c7.3,0.1,13.3,2.9,17.7,9
	C450.7,441.9,452.9,446.6,455,451.8z"/>
<path onClick={() => props.onFill(28)} fill={props.fillColors[28]} d="M238.8,246.9c0.8,3.4-0.2,5.7-2.9,7.3c-1.1,0.7-2.2,1.4-3.3,2c-2.5,1.5-4.2,3.6-5.2,6.3
	c-0.2,0.5-0.4,1-0.6,1.6c-1.3,4-3.9,6.9-7.5,8.9c-4.3,2.5-8.6,5-12.8,7.5c-1.4,0.8-2.8,1.8-4.4,2.8c0.2-1.8,0.3-3.2,0.5-4.6
	c0.1-0.7,0.4-1.4,0.8-1.9c2.8-3.2,5.2-6.6,7.6-10.1c2.7-4.1,6.4-7.6,10.8-10.1c5-2.9,9.9-5.8,14.9-8.7
	C237.4,247.6,238,247.3,238.8,246.9z"/>
<path onClick={() => props.onFill(27)} fill={props.fillColors[27]} d="M292.1,349.1c0-2.8,0-5.6,0-8.4c0.1-7.4-0.8-14.6-2-21.9c0-0.2-0.1-0.3-0.1-0.5
	c-0.5-2.3-0.3-4.2,1.2-6.3c1.5-2.1,3.1-2.8,5.4-3c3.7-0.2,7.4-1.1,11.4-2.7c-5.4,14.6-10.6,28.7-15.8,42.9
	C292.2,349.2,292.2,349.2,292.1,349.1z"/>
<path onClick={() => props.onFill(26)} fill={props.fillColors[26]} d="M257.7,152.9c0.4,3.2,0.8,5.9,1.1,8.7c0.1,1.2-0.3,2.4-1.2,3.3c-1.8,1.8-4,2.6-6.4,2.9
	c-0.6,0.1-1.2-0.2-1.8-0.5c-1.6-0.8-3.2-1.8-4.8-2.6c-4.7-2.5-9.6-3.3-14.8-1.8c-2,0.6-4,0.9-6.3,0.9c0.4-0.4,0.7-0.9,1.1-1.3
	c1.2-1.2,2.3-2.5,3.6-3.5c0.8-0.6,1.8-1,2.7-1.4c0.6-0.2,1.3-0.2,1.9-0.4c2.5-0.8,4.6-2,4.8-5.2
	C244.3,152.4,250.9,152.7,257.7,152.9z"/>
<path onClick={() => props.onFill(25)} fill={props.fillColors[25]} d="M250.6,142.5c-0.7,0.4-1.1,0.6-1.5,0.8c-3.7,1.4-7.2,3.1-10.4,5.4c-1.3,1-2.5,1.4-4.1,0.5
	c-1.6-0.9-3.3-0.5-4.8,0.3c-2.1,1.1-2.8,2.6-2.4,5c0.1,0.7,0.3,1.4,0.4,2.1c-0.1,0.1-0.1,0.3-0.2,0.4c-4,4.2-4.4,4.3-11.1,2.6
	c0-3.5,1-6.6,3.4-9.3c3.7-4.3,8.5-6.6,13.8-8.2C239.2,140.4,244.7,140.4,250.6,142.5z"/>
<path onClick={() => props.onFill(24)} fill={props.fillColors[24]} d="M276.8,142c-2.6,3.1-4.8,5.8-7.1,8.4c-0.3,0.4-1,0.6-1.5,0.7c-2.9,0.1-5.7,0.3-8.6,0.3
	c-5.3-0.1-10.7-0.4-16-0.7c-1.2-0.1-2.3-0.2-3.5-0.3c0-0.2-0.1-0.3-0.1-0.5c0.7-0.5,1.4-1,2.1-1.4c3.8-1.7,7.5-3.4,11.4-5
	c0.9-0.4,2-0.3,3.1-0.3c4.9-0.1,9.7-0.1,14.6-0.3C272.9,142.9,274.5,142.4,276.8,142z"/>
<path onClick={() => props.onFill(23)} fill={props.fillColors[23]} d="M320,121.4c0.5-2.4,1-4.4,1.5-6.8c10.1-3.4,17.4-10.3,21.7-20.5c1.3,1.1,1.4,2.4,1.4,3.7
	c0.2,5.2-1.6,9.6-5.3,13.1c-1.1,1.1-2.4,2.1-3.7,3C330.7,117,325.3,119,320,121.4z"/>
<path onClick={() => props.onFill(22)} fill={props.fillColors[22]} d="M341.8,83.2c1.1,1.6,1.4,2.9,1.1,4.4c-2.4,12.3-9.7,20.4-21.6,25.2c-0.2-1.8-0.3-3.3-0.5-5
	C330.7,102.2,336.5,93.2,341.8,83.2z"/>
<path onClick={() => props.onFill(21)} fill={props.fillColors[21]} d="M279.1,114.3c5.7,2.5,7,4.9,5.4,10.7c-1.5,5.6-4.8,10-9.7,13.5c-0.9-0.8-1.9-1.7-3-2.7
	C275.8,129.1,278.3,122,279.1,114.3z"/>
<path onClick={() => props.onFill(20)} fill={props.fillColors[20]} d="M267.2,129.1c-0.6-0.1-0.9-0.1-1.3-0.1c-0.5-0.1-1.1-0.3-1.9-0.4c-0.3-4.8-0.6-9.7-1.1-14.5
	c-0.5-4.7-1.2-9.4-1.9-14.3c2.2,0.2,3.7,1.4,4.9,2.9c1.4,1.8,2.2,4,2.2,6.2c0,5.7,0.5,11.4-0.4,17
	C267.6,126.9,267.4,127.9,267.2,129.1z"/>
<path onClick={() => props.onFill(19)} fill={props.fillColors[19]} d="M271,133.4c-1.7-1.8-2.2-3.5-1.8-5.8c0.5-3.1,0.7-6.2,0.8-9.3c0.1-3.6,0-7.2,0-10.7
	c2.9-0.7,7,3.6,7.1,6.4c0,0.7,0.1,1.5,0,2.2C276.1,122.2,274.3,128,271,133.4z"/>
<path onClick={() => props.onFill(18)} fill={props.fillColors[18]} d="M339.3,75.5c1.1,1.8,1.7,3.3,1.2,5.1c-0.3,0.9-0.5,1.9-0.9,2.7c-3.5,6.8-7.5,13.2-13.3,18.3
	c-2,1.7-4.2,3.1-6.3,4.7c-0.6-0.6-1-0.9-1.5-1.4c0.3-0.5,0.5-0.9,0.8-1.2c3.2-3.7,6.4-7.3,9.6-11c4.1-4.6,7.2-9.7,9.3-15.5
	c0.1-0.4,0.3-0.7,0.5-1.1C338.9,76.1,339,76,339.3,75.5z"/>
<path onClick={() => props.onFill(17)} fill={props.fillColors[17]} d="M342.3,110.7c0.8,1.5,0.6,2.6-0.1,3.6c-0.7,1-1.4,1.9-2.2,2.8c-5.7,5.9-14.6,10.5-22.8,11.7
	c-0.5-2.7,0.2-4.2,2.6-5.3c4.1-1.9,8.3-3.7,12.4-5.6c3.5-1.6,6.7-3.7,9.4-6.5C341.6,111.1,341.9,111,342.3,110.7z"/>
<path onClick={() => props.onFill(16)} fill={props.fillColors[16]} d="M320,171.5c3.2,3.2,6.3,6.5,9.7,9.5c3.4,3,7,5.8,10.8,9c-1.7,0.7-3,1.3-4.4,1.8c-0.4,0.1-1,0-1.3-0.3
	c-5.5-3.9-10.9-7.9-13.4-14.5c-0.7-1.8-1.2-3.6-1.8-5.4C319.6,171.6,319.8,171.6,320,171.5z"/>
<path onClick={() => props.onFill(15)} fill={props.fillColors[15]} d="M311.1,215.2c0-0.3-0.2-0.6-0.1-0.9c1.5-9.3,1.7-18.7,1.6-28.1c1.7,1.9,2.3,4.2,2.6,6.5
	c0.8,5.4,1.3,10.8,1.9,16.2c0,0.4-0.1,1-0.3,1.3c-1,1.4-2.1,2.8-3.1,4.2C313,215.4,312.3,215.8,311.1,215.2z"/>
<path onClick={() => props.onFill(14)} fill={props.fillColors[14]} d="M316.7,135.7c-0.2-1.6-0.4-3.1-0.6-4.8c7.4-1.2,13.9-3.8,19.7-8.1c0.9,0.6,1,1.3,0.5,2.1
	c-0.3,0.5-0.7,0.9-1.1,1.3C330.2,131.1,324.2,134.3,316.7,135.7z"/>
<path onClick={() => props.onFill(13)} fill={props.fillColors[13]} d="M424.3,123.5c-3.5,3.4-6.7,6.5-10.2,9.8c-0.1-0.6-0.2-1.1-0.3-1.7c-0.1-2.5,0.3-5.1-0.7-7.6
	c-0.1-0.2-0.1-0.5,0-0.7c0.4-2.3,4.1-4.2,6.3-3.2c0.7,0.3,1.4,0.7,2.1,1.2C422.4,122,423.3,122.7,424.3,123.5z"/>
<path onClick={() => props.onFill(12)} fill={props.fillColors[12]} d="M273.8,140.6c-3.8,1.3-7.8,0.9-11.7,0.9c0.8-3.7,1.6-7.2,2.4-10.9c1.5-0.2,2.8,0.5,3.7,2
	c0.5,0.7,0.7,1.6,1.2,2.3C270.8,136.8,272.2,138.6,273.8,140.6z"/>
<path onClick={() => props.onFill(11)} fill={props.fillColors[11]} d="M318.8,208.1c-1.1-9-0.9-17.7-5.7-25.4c1.1-0.6,1.8-0.6,2.3,0.4c3,5.3,5.4,10.8,7,16.7
	c0.1,0.4,0.2,1,0.1,1.4c-0.8,1.9-1.6,3.8-2.4,5.7C320,207.2,319.5,207.4,318.8,208.1z"/>
<path onClick={() => props.onFill(10)} fill={props.fillColors[10]} d="M324,172.8c-2.1-2.5-5-4.3-6.2-7.6c-0.7-2-2.2-3.5-3.9-4.7c-0.5-0.4-1-0.7-1.5-1.1
	c4.6-2.7,8.4-1.4,10.3,3.4c0.5,1.2,0.7,2.6,1,3.9c0.3,1.9,0.5,3.9,0.8,5.9C324.2,172.6,324.1,172.7,324,172.8z"/>
<path onClick={() => props.onFill(9)} fill={props.fillColors[9]} d="M222.2,162.4c-1.3,1.1-2.1,2.1-3.1,2.8c-5.8,3.5-11.7,6.9-17.5,10.3c-0.2,0.1-0.5,0.1-1.2,0.3
	c0.5-0.6,0.7-0.9,1-1.2c4.2-3.9,8.6-7.7,12.7-11.6c1.2-1.1,2.4-1.4,3.9-1.2C219.2,162,220.4,162.1,222.2,162.4z"/>
<path onClick={() => props.onFill(8)} fill={props.fillColors[8]} d="M120.6,284.6c0.5,1.8,1,4,1.5,6.2c-2.1,0-3.9,0-5.7,0c-0.4,0-0.8-0.2-1-0.4c-2.2-2.9-4.4-5.8-6.7-9
	C112.7,282.5,116.5,283.5,120.6,284.6z"/>
<path onClick={() => props.onFill(7)} fill={props.fillColors[7]} d="M317.6,142.1c0-1.7,0-3.1,0-4.3c4.4-1.7,8.6-3.4,13-5.1c0.3,1,0.1,2.1-0.6,2.9
	C326.8,139.5,322.7,141.6,317.6,142.1z"/>
<path onClick={() => props.onFill(6)} fill={props.fillColors[6]} d="M333.7,193.1c-0.7,0.5-1,0.7-1.3,1c-1.7,1.1-1.7,1.1-3.3-0.1c-5.1-3.8-8.1-9.1-10.3-15
	c-0.2-0.5,0-1.1,0.2-1.8C321.9,184.4,327.6,188.8,333.7,193.1z"/>
<path onClick={() => props.onFill(5)} fill={props.fillColors[5]} d="M286.5,124.7c2.3,1.7,2.1,3.8,1.9,5.7c0,0.4-0.5,0.8-0.8,1.1c-2.5,2.3-5,4.6-7.5,7
	c-0.8,0.8-1.7,1.4-3.4,1C281.5,135.5,284.9,130.8,286.5,124.7z"/>
<path onClick={() => props.onFill(4)} fill={props.fillColors[4]} d="M317.5,180.4c2.4,6.2,5.7,11.7,11.5,15.7c-1.8,1-3.2,1.8-4.9,2.8c-1.5-6.4-4.7-12-7.4-17.7
	c-0.1-0.1,0.2-0.4,0.2-0.6C317.1,180.5,317.3,180.4,317.5,180.4z"/>
<path onClick={() => props.onFill(3)} fill={props.fillColors[3]} d="M122.4,284.7c5-0.3,8.5,0.4,11,2.2c-3.2,1-6.3,1.9-9.6,2.9C123.4,288.2,122.9,286.5,122.4,284.7z"/>
<path onClick={() => props.onFill(2)} fill={props.fillColors[2]} d="M317.1,147.5c-0.2-1-0.3-2-0.5-2.9c3.3-1,6.4-1.9,9.5-2.8C326.1,145.3,322.2,148,317.1,147.5z"/>
<path onClick={() => props.onFill(1)} fill={props.fillColors[1]} d="M229.6,156c-0.6-1.7-1.2-3.2,0.3-4.4c1.3-1.1,2.8-1.3,4.5-0.5c1.5,0.7,1.7,2.1,0.5,3.2
	C233.4,155.4,231.6,155.8,229.6,156z"/>
<path onClick={() => props.onFill(0)} fill={props.fillColors[0]} d="M317.7,149.6c0.7-0.1,1.8-0.2,2.9-0.4c0.2,3.7-2.8,6.6-7.3,7.3C314.6,154.4,316.1,152.2,317.7,149.6z
	"/>
    </g>
            </svg>
        </div>
    )
}