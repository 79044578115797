import React from 'react';
import Hermosasquare from './Hermosasquare.js';
import Hermosarounded from './Hermosarounded.js';
import Hermosaoctagon from './Hermosaoctagon.js';
import Hummingbird from './Hummingbird.js';
import HummingbirdIris from './HummingbirdIris.js';
import Denton from './Denton.js';
import Maywood from './Maywood.js';
import Fleur from './Fleur.js';
import Mountainviewsquare from './Mountainviewsquare.js';
import Mountainviewrounded from './Mountainviewrounded.js';
import Mountainviewoctagon from './Mountainviewoctagon.js';
import Bakersfield from './Bakersfield.js'
import Cross from './Cross.js'
import Tudor from './Tudor.js';
import Put01 from './Put01.js'
import Put02 from './Put02.js'
import Put03 from './Put03.js'
import Put04 from './Put04.js'
import Put05 from './Put05.js'
import Bakers from './Bakers.js';
import Design5 from './Design5.js'
import Design6 from './Design6.js';   
import Sun from './Sun.js';
import Karen from './Karen.js';
import Temecula from './Temecula.js';

const components = {
    "Hermosasquare": Hermosasquare,
    "Hermosarounded": Hermosarounded,
    "Hermosaoctagon": Hermosaoctagon,
    "Hummingbird" : Hummingbird,
    "HummingbirdIris" : HummingbirdIris,
    "Denton" : Denton,
    "Maywood" : Maywood,
    "Fleur" : Fleur,
    "Mountainviewsquare" : Mountainviewsquare,
    "Mountainviewrounded" : Mountainviewrounded,
    "Mountainviewoctagon" : Mountainviewoctagon,
    "Bakersfield" : Bakersfield,
    "Cross" : Cross,
    "Tudor" : Tudor,
    "Put01" : Put01,
    "Put02" : Put02,
    "Put03" : Put03,
    "Put04" : Put04,
    "Put05" : Put05,
    "Bakers" : Bakers,
    "Design5" : Design5,
    "Design6" : Design6,
    "Sun" : Sun,
    "Karen" : Karen,
    "Temecula" : Temecula



}

export default function ColoredInImage(props) {

    const ColorImage = components[props.component]

   
    return(
       
        <ColorImage id="svg-image" onFill={props.onFill} fillColors={props.fillColors} {...props} />   
        
    )
}