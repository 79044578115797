import React from 'react';
import Patterns from './patterns';

export default function Hermosarounded(props) {
    
    return (
        <div className={`hermosarounded-container ${props.size}`}> 
 
            <svg  xmlns="http://www.w3.org/2000/svg" id={props.id} width="500" height="500" viewBox="0 0 500 500" >
            <circle  className="st0" cx="250" cy="250" r="250"/>

<Patterns />        
                                                                  
            <g className="hermosarounded-image" >
<path onClick={() => props.onFill(24)} fill={props.fillColors[24]} d="M472.2,247.8H380.1c-1.1-17.1-7.7-31.4-20.1-42.9s-27.1-16.7-43.9-17.4c-.5-17.6-6.4-32.8-18.4-45.4s-27-19.1-44.7-19.3v-94a214.8,214.8,0,0,1,83.7,17c44.6,18.8,79.6,49,104.5,90.6C461.7,170.6,471.9,207.8,472.2,247.8Z" />
<path onClick={() => props.onFill(23)} fill={props.fillColors[23]} d="M316.2,312.7c17.2-.6,32.4-6.4,44.8-18.4s18.7-26.8,19.1-44.4c10.2-.3,20.4,0,30.6-.1H472c.7,48.2-18,109-65.1,155.5-49.7,49.1-111.9,64.4-154.2,63.5V375.4l5.1-.9a77,77,0,0,0,40.7-21.1c10.8-10.6,16.5-23.7,17.5-38.9A14.1,14.1,0,0,1,316.2,312.7Z" />
<path onClick={() => props.onFill(22)} fill={props.fillColors[22]} d="M31.1,247.7c1.3-57.6,20.8-108.1,60.6-150,43-45.2,96.4-67.5,158.7-69.1a7.4,7.4,0,0,1,.3,1.9,4.5,4.5,0,0,1,.1,1.1c0,17.8,0,35.5.1,53.3,0,11.2.1,22.4.1,33.6v4.1c-8.5.4-16.8,1.5-24.6,5A65.7,65.7,0,0,0,206,141.8a64,64,0,0,0-13.7,21.1,65.8,65.8,0,0,0-4.9,24.6c-1.7.1-3.3.1-4.9.3-23.2,2.3-41.1,13.1-52.7,33.7a56.1,56.1,0,0,0-7.1,24.3,17.6,17.6,0,0,1-.2,1.9Z" />
<path onClick={() => props.onFill(21)} fill={props.fillColors[21]} d="M31.3,249.9h91.2c.3,17.7,6.8,32.7,19.5,44.7s27.9,17.6,45.3,18.1c.2,2.3.4,4.4.7,6.5,2.3,16.5,10.2,29.7,23.2,39.7a89.3,89.3,0,0,0,24.2,13.8,49.2,49.2,0,0,0,13.2,2.7H250c.6,2.3,1,87.9.5,93.5a198,198,0,0,1-43.4-4.4A220.2,220.2,0,0,1,48,333.1,216.1,216.1,0,0,1,31.3,249.9Z" />
<path onClick={() => props.onFill(20)} fill={props.fillColors[20]} d="M2.5,250.2H29.1c1.4,60.4,22.5,113,65.5,155.8s95.3,63.9,155.9,65.3v26.2c-37,0-72.2-7.6-105.5-23.4C95.7,450.7,57.8,414.9,31.9,366.8,12.3,330.6,2.8,291.7,2.5,250.2Z" />
<path onClick={() => props.onFill(19)} fill={props.fillColors[19]} d="M29.1,248H2.6a238.7,238.7,0,0,1,14.9-82.8c18.9-51.1,51.1-91.9,96.6-122A244.8,244.8,0,0,1,250.2,2.5c.5,1.7.7,20.5.3,24.1C190,28,137.5,49.1,94.5,91.9S30.6,187.2,29.1,248Z" />
<path onClick={() => props.onFill(18)} fill={props.fillColors[18]} d="M474.3,250.2h22.9c1.1,49.2-16.7,118.9-71.3,174S302,497.7,252.7,497.3v-26l2.3-.2c6.2-.4,12.3-.5,18.4-1.1a213.6,213.6,0,0,0,49.9-10.7A221.4,221.4,0,0,0,466.7,306a185.3,185.3,0,0,0,5.8-30.4c.7-7.6,1.1-15.3,1.7-23C474.2,251.8,474.3,251,474.3,250.2Z" />
<path onClick={() => props.onFill(17)} fill={props.fillColors[17]} d="M252.7,26.6V2.5a240.6,240.6,0,0,1,84,15.7c53.8,20.4,95.8,55.3,125.5,104.5,23.2,38.4,34.7,80.2,35.3,125.3H474.4c-1.4-60.7-22.5-113.3-65.5-156.2S313.5,28,252.7,26.6Z" />
<path onClick={() => props.onFill(16)} fill={props.fillColors[16]} d="M189.6,187.3c-.5-34.3,29-63.7,61.2-62.3a12.7,12.7,0,0,1,.1,2,79,79,0,0,1-2.1,19.4,89.9,89.9,0,0,1-13.6,31.8c-4.8,7.1-11.6,11.5-19.6,14.4a2.8,2.8,0,0,1-2-.1,70.1,70.1,0,0,0-23.5-5Z" />
<path onClick={() => props.onFill(15)} fill={props.fillColors[15]} d="M313.9,187.1c-4.8.8-9.5,1.3-14,2.2s-6.4,2-9.6,2.9a4.4,4.4,0,0,1-2.5.2c-6.6-2.3-12.6-5.5-17.2-11.1a75.8,75.8,0,0,1-8.9-14.3,97,97,0,0,1-8.3-26.8c-.9-5.1-.3-10.1-.4-15.3a43.5,43.5,0,0,1,9.6.6c23.9,3.7,43.7,22.9,49.5,46.9a100.9,100.9,0,0,1,1.8,11A22.9,22.9,0,0,1,313.9,187.1Z" />
<path onClick={() => props.onFill(14)} fill={props.fillColors[14]} d="M124.8,249.8h13.9a3.2,3.2,0,0,1,1.4.3c11,3.6,21.9,7.3,32.1,12.7a75.5,75.5,0,0,1,8.9,5.8c5.7,4.3,9,10.3,11.4,16.7a2.8,2.8,0,0,1-.2,2,61.7,61.7,0,0,0-4.5,16.3l-.6,6.8C153.6,311.3,123.5,282.5,124.8,249.8Z" />
<path onClick={() => props.onFill(13)} fill={props.fillColors[13]} d="M316.5,310.6c-.8-4.9-1.4-9.5-2.4-14a52.8,52.8,0,0,0-2.8-8.5,5.3,5.3,0,0,1,0-3.9c3.5-9.8,10.5-16.4,19.4-21s18.1-8.9,27.2-13.3a3.7,3.7,0,0,1,1.8-.5l17.4.3.8.2a34.1,34.1,0,0,1-.5,9c-4,25.5-23.6,44.7-48.1,50.2-3.5.7-7.1,1-10.6,1.5Z" />
<path onClick={() => props.onFill(12)} fill={props.fillColors[12]} d="M187.3,189.5c.2,2.6.4,4.8.7,7.1a60.4,60.4,0,0,0,4.2,15.8,4.5,4.5,0,0,1,0,3.4c-2.5,6.2-5.9,11.8-11.2,16.1a60.2,60.2,0,0,1-14.8,8.4c-8.4,3.5-17.3,5.4-26.2,7.1-2.7.6-5.7.3-8.5.3s-4.4,0-7,0a58.6,58.6,0,0,1,17.3-39.1C154.3,196,169.6,190.1,187.3,189.5Z" />
<path onClick={() => props.onFill(11)} fill={props.fillColors[11]} d="M377.8,247.5l-2.1.2-14.5-.2a22.2,22.2,0,0,1-4.5-.7c-6.5-2.1-13-4.1-19.4-6.5a55.6,55.6,0,0,1-13.5-7.4c-6.4-4.6-10.2-11-12.8-18.2a2.7,2.7,0,0,1,.1-1.8,62.3,62.3,0,0,0,4.9-22.5l.3-.6C346.6,189.2,377.1,214.3,377.8,247.5Z" />
<path onClick={() => props.onFill(10)} fill={props.fillColors[10]} d="M189.5,312.8l7.6-.7a61.8,61.8,0,0,0,15.9-4,4.5,4.5,0,0,1,3.4-.1c10.6,3.5,17.7,10.7,22.9,20.4s8.4,20.4,11.2,31.1a6.1,6.1,0,0,1,.1,1.9c0,3.5-.1,7-.1,10.5,0,.4-.2.9-.2,1.5a44.2,44.2,0,0,1-10.4-1.4,46.2,46.2,0,0,1-14.4-6.2c-4-2.5-8.1-4.8-11.9-7.6-12.5-9.3-20.6-21.6-23.4-37.2-.3-1.9-.5-3.9-.7-5.9A17.6,17.6,0,0,1,189.5,312.8Z" />
<path onClick={() => props.onFill(9)} fill={props.fillColors[9]} d="M252.4,373.3c.4-5.9-.4-11.5,1.3-17,2.4-7.8,4.5-15.6,8-23s7-13.3,12.9-18.4c3.9-3.4,8.6-5.4,13.3-7.1a3,3,0,0,1,2.1.1,63.4,63.4,0,0,0,17,4.3l4.6.4,2.4.2a40.9,40.9,0,0,1-1.1,10,58.2,58.2,0,0,1-23.4,35.7,78.8,78.8,0,0,1-33.9,14.4Z" />
<path onClick={() => props.onFill(8)} fill={props.fillColors[8]} d="M217.3,194.1l4.4-1.9c7.2-3.2,12.6-8.5,16.7-15.1a90.9,90.9,0,0,0,12.7-31.9,3.9,3.9,0,0,1,.9-2.1c.2,1,.3,2,.5,3A89.2,89.2,0,0,0,267.1,180a35,35,0,0,0,16.5,12.9L286,194c-17.5,9-29,22.7-34.3,42.2C246.5,216.9,235,203.2,217.3,194.1Z" />
<path onClick={() => props.onFill(7)} fill={props.fillColors[7]} d="M194,216.6c9.5,17.6,23.8,28.5,43.4,33.5-19.6,5-33.9,15.9-43.3,33.4a18.2,18.2,0,0,1-1-1.9c-3.2-8.3-9-14.3-16.6-18.7a100.4,100.4,0,0,0-10.9-5.4c-6.6-2.7-13.4-5.1-20.2-7.7a2.9,2.9,0,0,1-1.5-1.1l2.7-.6a107.8,107.8,0,0,0,20.1-5.9c7.1-2.9,13.8-6.5,19.2-12.2a40.3,40.3,0,0,0,6.8-10.5Z" />
<path onClick={() => props.onFill(6)} fill={props.fillColors[6]} d="M217.4,306.3c17.7-9.2,29.1-22.9,34.3-42.3,5.2,19.4,16.7,33.2,34.3,42.2l-1.8.9c-9.7,3.5-16.2,10.5-21.2,19.2s-7.8,17.6-10.4,26.8c-.3.8-.5,1.6-.9,2.8l-1-3.3a131.2,131.2,0,0,0-7.2-20.2,71.8,71.8,0,0,0-8.1-13.6,35.7,35.7,0,0,0-14.7-11.1Z" />
<path onClick={() => props.onFill(5)} fill={props.fillColors[5]} d="M355.7,248.6l-2.5,1.3c-7.5,3.7-15,7.2-22.4,11.1a86.5,86.5,0,0,0-10.3,6.7,31.4,31.4,0,0,0-9.8,13l-1.2,2.8c-1.5-2.4-2.8-4.6-4.3-6.7a77.3,77.3,0,0,0-4.9-6.4,74,74,0,0,0-5.6-5.6,77.3,77.3,0,0,0-6.4-4.9,65.8,65.8,0,0,0-6.7-4,69.6,69.6,0,0,0-7.4-3.3c-2.5-.9-5-1.6-7.9-2.5,19.4-5,33.7-16,43.2-33.7l1.9,4.5a36.2,36.2,0,0,0,13.2,15c7.5,5,15.8,8.1,24.3,10.7Z" />
<path onClick={() => props.onFill(4)} fill={props.fillColors[4]} d="M189.4,310.5A58.3,58.3,0,0,1,203.5,273c10.1-12.1,23.2-19.1,38.9-21.7,1.1,4.3,3.7,6.9,7.9,7.8C247.2,286.2,221,310.7,189.4,310.5Z" />
<path onClick={() => props.onFill(3)} fill={props.fillColors[3]} d="M253.1,241.1c4.1-30.2,32.7-52.2,60.7-51.3.6,26.7-21.5,55-52.8,59A9.6,9.6,0,0,0,253.1,241.1Z" />
<path onClick={() => props.onFill(2)} fill={props.fillColors[2]} d="M313.9,310.5c-30.2.3-57.4-23.1-60.8-51.4,4.1-.9,6.9-3.4,7.8-7.6C289.4,254.5,314.1,281,313.9,310.5Z" />
<path onClick={() => props.onFill(1)} fill={props.fillColors[1]} d="M189.6,189.7c28.4,0,56.5,21.3,60.8,51.4a9.4,9.4,0,0,0-7.9,7.7C213.9,245.3,189.4,219.7,189.6,189.7Z" />
<path onClick={() => props.onFill(0)} fill={props.fillColors[0]} d="M251.8,257.1a7.1,7.1,0,0,1-7.3-6.8,7.1,7.1,0,0,1,7.3-7.2,7,7,0,1,1,0,14Z" />
               
                
    </g>
            </svg>
        </div>
    )
}