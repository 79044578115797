import React from 'react';
import Patterns from './patterns';

export default function Put05(props) {
    
    return (
        <div className={`Put05-container ${props.size}`}> 
 
            <svg  xmlns="http://www.w3.org/2000/svg" id={props.id} width="500" height="500" viewBox="0 0 500 500">
                
                 <Patterns />                
            <g className="hummingbird-image" >

<path id="background" fill="black"  d="m0,500V0h500v500H0Z"/>



   <path onClick={() => props.onFill(1)} fill={props.fillColors[1]} d="M248.8,392.62h-7.94c-19.46,0-38.91,.11-58.37,.11-18.31,.11-36.5,.11-54.8,.34-1.5,0-2.19-.45-2.99-1.69-10.13-16.61-20.26-33.11-30.28-49.71-.69-1.13-1.04-2.49-1.04-3.73-.12-28.13,0-56.27,0-84.4,0-2.15,0-2.26,2.19-2.26,29.93,0,59.87-.11,89.8-.23,3.11,0,4.84,.9,6.56,3.5,8.63,12.99,17.5,25.87,26.25,38.87,10.02,14.69,19.92,29.26,29.93,43.95,.46,.68,.81,1.58,.81,2.26v51.75c0,.23,0,.56-.12,1.24Z"/>
  <path onClick={() => props.onFill(2)} fill={props.fillColors[2]} d="M251.8,392.51v-51.41c0-1.36,.46-2.94,1.27-4.07,9.56-14.12,19.11-28.13,28.67-42.14,9.44-13.9,18.77-27.8,28.09-41.69,1.04-1.47,2.07-2.03,3.91-2.03,14.62,.11,29.24,0,43.75,0,16,0,31.89,.11,47.89,.11,1.61,0,2.07,.45,2.07,2.03,0,27.91,0,55.7-.12,83.61,0,1.24-.46,2.6-1.04,3.62-10.25,16.72-20.49,33.44-30.62,50.28-.58,.9-1.15,1.24-2.19,1.24h-13.93c-18.31,.11-36.5,.11-54.8,.23-16.92,.11-33.73,.11-50.66,.23-.58,0-1.38,0-2.3,0Z"/>
  <path onClick={() => props.onFill(3)} fill={props.fillColors[3]} d="M141.5,248.45h-46.17c-1.61,0-2.07-.45-2.07-2.03,0-27.46,0-54.91,.12-82.37,0-1.13,.35-2.37,.92-3.28,10.13-16.72,20.49-33.56,30.62-50.28,.58-.9,1.27-1.24,2.3-1.24,19.8,0,39.6,.11,59.41,.11,3.91,0,7.83,.11,11.63,.11,16.23,.11,32.35,.11,48.58,.11,1.5,0,2.07,.34,2.07,1.92-.12,16.04,0,32.09-.12,48.25,0,.9-.35,2.03-.81,2.82-9.1,13.56-18.31,27-27.52,40.45-9.9,14.58-19.92,29.15-29.82,43.84-.81,1.24-1.61,1.69-3.22,1.69-15.2-.11-30.51-.11-45.94-.11Z"/>
  <path onClick={() => props.onFill(4)} fill={props.fillColors[4]} d="M359.21,248.45h-45.48c-1.96,0-2.99-.68-3.91-2.15-9.33-13.78-18.65-27.57-27.98-41.35-9.67-14.24-19.34-28.36-29.01-42.48-.58-.9-.92-2.03-.92-3.16-.12-15.93,0-31.86-.12-47.91,0-1.36,.46-1.81,1.84-1.81,29.93,.11,59.75,.11,89.68,.23,9.9,0,19.8,.11,29.7,.11,1.5,0,2.42,.45,3.11,1.81,10.13,16.61,20.26,33.22,30.39,49.83,.69,1.13,1.04,2.49,1.04,3.84,.12,27,0,53.9,0,80.9q0,2.15-2.3,2.15c-15.31,0-30.74,0-46.05,0Z"/>
  <path onClick={() => props.onFill(5)} fill={props.fillColors[5]} d="M329.62,33.43h75.41q2.42,0,2.42,2.37c0,5.42,.12,10.85,0,16.38,0,1.24-.35,2.71-1.04,3.84-10.02,16.5-20.15,32.99-30.28,49.6-.81,1.36-1.73,1.81-3.22,1.81-24.75-.11-49.5-.11-74.14-.23-13.01,0-26.02-.11-39.03-.23-2.19,0-4.49-.11-6.68-.11-.92,0-1.38-.34-1.38-1.24V35.92q0-2.37,2.3-2.37c25.33-.11,50.43-.11,75.64-.11Z"/>
  <path onClick={() => props.onFill(6)} fill={props.fillColors[6]} d="M171.21,33.43h75.41q2.3,0,2.3,2.26V104.62q0,2.15-2.19,2.15c-16.58,0-33.04,0-49.62-.11-10.36,0-20.61-.11-30.97-.11s-21.18-.11-31.78-.11c-2.3,0-4.61-.23-6.91-.11-1.38,0-2.07-.45-2.76-1.58-9.79-16.04-19.57-32.09-29.47-48.02-1.5-2.37-2.3-4.75-2.07-7.57,.23-4.52,.12-9.04,.12-13.45q0-2.26,2.3-2.26c25.21,0,50.43,0,75.64,0Z"/>
  <path onClick={() => props.onFill(7)} fill={props.fillColors[7]} d="M329.62,466.07h-75.41q-2.42,0-2.42-2.37v-66.21q0-2.37,2.3-2.37c19.11,0,38.11,0,57.22-.11,10.48,0,20.95,0,31.43-.11,10.02,0,20.03-.11,30.05-.23,1.61,0,2.53,.45,3.45,1.81,10.02,16.5,20.15,32.99,30.28,49.6,.58,1.02,.92,2.26,.92,3.5,.12,4.75,0,9.6,.12,14.35,0,1.69-.46,2.15-2.19,2.15-25.33,0-50.54,0-75.75,0Z"/>
  <path onClick={() => props.onFill(8)} fill={props.fillColors[8]} d="M171.21,466.07H95.91q-2.53,0-2.53-2.49c0-4.41-.12-8.93,0-13.33,0-.9,.23-1.92,.69-2.71l31.08-50.84c.35-.56,1.27-.79,1.96-1.24,.23-.11,.46,0,.69,0,19.34,0,38.68-.11,58.02-.11,10.59,0,21.07,0,31.66-.11,9.79,0,19.57-.11,29.36-.23,1.61,0,2.19,.34,2.19,2.03,0,22.26-.12,44.52,0,66.78,0,1.81-.58,2.15-2.3,2.15-25.21,.11-50.43,.11-75.52,.11Z"/>
  <path onClick={() => props.onFill(9)} fill={props.fillColors[9]} d="M37.08,321.89v-68.58q0-2.26,2.19-2.26h32.01c5.87,0,11.74,.11,17.73,.11,1.27,0,1.73,.34,1.73,1.58,0,28.59,0,57.06-.12,85.65,0,.9-.35,2.03-.81,2.82-10.25,16.84-20.49,33.56-30.74,50.39-.46,.9-1.04,1.36-2.19,1.24-6.1-.11-12.2-.23-18.42-.23-1.38,0-1.38-.79-1.38-1.69v-21.58c0-15.71,0-31.52,0-47.46Z"/>
  <path onClick={() => props.onFill(10)} fill={props.fillColors[10]} d="M463.75,392.4h-11.4c-2.65,0-5.41-.23-8.06-.23-1.15,0-1.84-.34-2.53-1.36-10.25-16.84-20.49-33.56-30.74-50.39-.58-.9-.81-2.03-.81-2.94v-84.29c0-.45,0-1.02,.12-1.69,.58,0,1.15-.11,1.73-.11,16.46-.11,32.93-.11,49.5-.11q2.3,0,2.3,2.15v137.17c-.12,.45-.12,1.13-.12,1.81Z"/>
  <path onClick={() => props.onFill(11)} fill={props.fillColors[11]} d="M37.08,248.34V111.51q0-2.26,2.19-2.26c5.76,0,11.4-.11,17.15-.23,1.27,0,1.96,.34,2.65,1.36,10.13,16.72,20.38,33.44,30.51,50.17,.58,.9,.92,2.15,.92,3.16,0,27.57,0,55.14,.12,82.71,0,1.24-.12,1.92-1.73,1.92H38.58c-.46,.11-.81,0-1.5,0Z"/>
  <path onClick={() => props.onFill(12)} fill={props.fillColors[12]} d="M410.21,248.34v-83.39c0-1.13,.35-2.37,.92-3.28,10.13-16.72,20.38-33.33,30.51-49.94,.81-1.24,1.61-1.58,2.99-1.69,5.76-.11,11.4-.23,17.15-.45,1.5-.11,1.96,.45,1.96,1.92V246.64c0,1.13,0,1.92-1.61,1.92h-50.66c-.35-.11-.69-.23-1.27-.23Z"/>
  <path onClick={() => props.onFill(13)} fill={props.fillColors[13]} d="M2.5,383V253.3q0-2,2-2h28.6c1.4,0,1.8,.4,1.8,1.8v130c-10.8-.1-21.4-.1-32.4-.1Z"/>
  <path onClick={() => props.onFill(14)} fill={props.fillColors[14]} d="M34.9,183v63.8q0,2-2,2H4.1c-1.2,0-1.6-.3-1.6-1.6V118.7c0-1.3,.4-1.6,1.6-1.5h29.2c1.2,0,1.6,.3,1.6,1.6-.1,19.5-.1,39.1-.1,58.6,.1,1.8,.1,3.7,.1,5.6h0Z"/>
  <path onClick={() => props.onFill(15)} fill={props.fillColors[15]} d="M497.5,317.3v63.8q0,2-2,2h-27.2q-2,0-2-2v-127.5q0-2.3,2.3-2.3h27c1.5,0,1.9,.4,1.9,1.9,0,21.3,0,42.7,0,64.1Z"/>
  <path onClick={() => props.onFill(16)} fill={props.fillColors[16]} d="M497.5,117.2V246.8q0,2-1.9,2h-27.6c-1.4,0-1.7-.4-1.7-1.8V119.1q0-2,2-2h27.3c.5,.1,1.1,.1,1.9,.1Z"/>
  <path onClick={() => props.onFill(17)} fill={props.fillColors[17]} d="M249.2,31.3H118.5q-2,0-2-2.1V4.1c0-1.3,.4-1.7,1.7-1.7H247.9c.8,0,1.5-.1,1.5,1.2V30.3c-.1,.3-.1,.6-.2,1Z"/>
  <path onClick={() => props.onFill(18)} fill={props.fillColors[18]} d="M384.5,31.3H253.6c-1.5,0-1.9-.4-1.9-1.9,.1-8.4,.1-16.8,0-25.2,0-1.3,.4-1.7,1.7-1.7h129.7c1.1,0,1.6,.2,1.6,1.5-.1,8.7,0,17.3,0,26-.1,.3-.1,.7-.2,1.3Z"/>
  <path onClick={() => props.onFill(19)} fill={props.fillColors[19]} d="M249.3,469.1v26.8c0,1.4-.5,1.7-1.8,1.7H118.3c-1.4,0-1.9-.3-1.9-1.8,.1-8.4,.1-16.7,0-25.1,0-1.2,.4-1.6,1.6-1.6H247.8c.5,0,.9,0,1.5,0Z"/>
  <path onClick={() => props.onFill(20)} fill={props.fillColors[20]} d="M251.8,469.1h130.9q2,0,2,1.9v24.8c0,1.4-.4,1.8-1.8,1.8H253.6c-1.4,0-1.9-.4-1.8-1.9,.1-8.3,0-16.6,0-24.9v-1.7Z"/>
  <path onClick={() => props.onFill(21)} fill={props.fillColors[21]} d="M2.6,4.2c1.1,1,2,1.7,2.8,2.4,9.5,8.4,18.9,16.9,28.3,25.4,.6,.5,1.1,1.6,1.1,2.4,.1,26.3,0,52.6,.1,78.9,0,1.2-.4,1.6-1.6,1.6H4.1c-1.2,0-1.6-.3-1.6-1.6V5.7c0-.3,.1-.7,.1-1.5Z"/>
  <path onClick={() => props.onFill(22)} fill={props.fillColors[22]} d="M2.5,495.9v-108.4c0-2,0-2.1,2-2.1h28.3q2.1,0,2.1,2v55.3c0,7.7,0,15.4,.1,23.1,0,1-.3,1.7-1.1,2.4-10,8.8-19.9,17.6-29.8,26.4-.4,.4-.8,.7-1.6,1.3Z"/>
  <path onClick={() => props.onFill(23)} fill={props.fillColors[23]} d="M497.4,4.4c0,.8,.1,1.3,.1,1.9V113.1c0,1.4-.3,1.9-1.8,1.9-9.3-.1-18.5-.1-27.8,0-1.4,0-1.7-.5-1.7-1.8V34.6c0-.8,.4-1.9,1-2.5,9.7-9.1,19.5-18,29.2-27,.3-.2,.5-.4,1-.7Z"/>
  <path onClick={() => props.onFill(24)} fill={props.fillColors[24]} d="M497.1,495.5c-.5-.5-1.1-.9-1.6-1.4-9.3-8.5-18.6-17-28-25.5-.9-.8-1.3-1.7-1.3-3v-78.1q0-2,1.9-2h27.4c1.6,0,1.9,.5,1.9,2v108c-.1-.1-.2-.1-.3,0Z"/>
  <path onClick={() => props.onFill(25)} fill={props.fillColors[25]} d="M114,31.3H38.2c-1.2,0-2-.3-2.9-1.1C25.6,21.4,15.9,12.7,6.1,4c-.4-.3-.8-.7-1.4-1.3H114V31.3Z"/>
  <path onClick={() => props.onFill(26)} fill={props.fillColors[26]} d="M387.2,2.6h108.1c-.4,.5-.7,.8-1,1.1-9.5,8.8-19,17.5-28.5,26.3-.9,.9-1.9,1.3-3.2,1.3h-75.5c.1-9.7,.1-19.1,.1-28.7Z"/>
  <path onClick={() => props.onFill(27)} fill={props.fillColors[27]} d="M114.1,469.1v26.7c0,1.2-.2,1.7-1.5,1.7H6.4c-.4,0-.8-.1-1.3-.1-.1-.2-.3-.4-.4-.5,.4-.2,.9-.4,1.3-.7,9.8-8.7,19.7-17.4,29.5-26,.6-.5,1.6-1,2.3-1H112.5c.5-.1,1-.1,1.6-.1Z"/>
  <path onClick={() => props.onFill(28)} fill={props.fillColors[28]} d="M494.9,497.5h-105.8q-2.1,0-2.1-2v-26.4h76c1.1,0,1.9,.3,2.6,1,9.4,8.6,18.8,17.3,28.3,25.9,.4,.4,.8,.7,1.1,1.1,0,.1,0,.2-.1,.4Z"/>
  <path onClick={() => props.onFill(29)} fill={props.fillColors[29]} d="M92,343.13c2.07,3.5,4.14,6.67,6.1,9.94,8.17,13.33,16.23,26.55,24.41,39.77,.58,.9,.81,1.69,.12,2.71-10.02,16.27-19.92,32.54-29.82,48.81-.23,.34-.46,.68-.81,1.13-.35-.45-.69-.79-.92-1.24-9.9-16.16-19.8-32.31-29.7-48.47-.69-1.13-.69-1.92,0-3.05,9.9-16.04,19.69-32.2,29.59-48.36,.35-.34,.58-.68,1.04-1.24Z"/>
  <path onClick={() => props.onFill(30)} fill={props.fillColors[30]} d="M91.89,159.08c-1.27-2.03-2.42-3.95-3.57-5.76-8.98-14.58-17.84-29.26-26.82-43.84-.69-1.13-.81-2.03-.12-3.16,9.79-15.93,19.46-31.86,29.24-47.79,.35-.56,.81-1.24,1.38-2.03,2.42,4.07,4.84,7.8,7.14,11.64,7.83,12.77,15.54,25.54,23.37,38.19,.58,.9,.81,1.69,.12,2.71-10.02,16.27-19.92,32.65-29.93,48.92-.12,.23-.35,.56-.81,1.13Z"/>
  <path onClick={() => props.onFill(31)} fill={props.fillColors[31]} d="M408.83,160.09c-2.19-3.5-4.14-6.67-6.1-9.94-8.06-13.22-16.12-26.44-24.29-39.66-.81-1.24-.81-2.15,0-3.39,9.79-16.04,19.57-31.98,29.47-48.02,.23-.45,.58-.9,1.04-1.58,1.96,3.28,3.91,6.33,5.76,9.38,8.17,13.33,16.35,26.78,24.52,40.11,.81,1.24,.81,2.15,0,3.5-9.9,15.93-19.69,31.98-29.47,48.02-.23,.45-.46,.79-.92,1.58Z"/>
  <path onClick={() => props.onFill(32)} fill={props.fillColors[32]} d="M408.95,342.12c2.99,4.86,5.87,9.6,8.75,14.24,7.25,11.86,14.51,23.73,21.76,35.48,.58,1.02,.81,1.81,.12,2.94-10.02,16.27-19.92,32.54-29.82,48.81-.23,.34-.35,.56-.81,1.02-.35-.56-.81-.9-1.04-1.36-9.9-16.16-19.8-32.31-29.7-48.47-.58-.9-.69-1.58-.12-2.49,10.02-16.27,19.92-32.65,29.93-48.92,.23-.45,.46-.68,.92-1.24Z"/>
  <path onClick={() => props.onFill(33)} fill={props.fillColors[33]} d="M463.75,70.15v34.57c0,1.58-.46,2.15-2.07,2.15-5.76,.11-11.4,.23-17.15,.45-1.38,0-2.19-.34-2.88-1.58-10.13-16.72-20.38-33.33-30.51-49.94-.58-.9-.92-2.03-.92-3.16-.12-5.76,0-11.52-.12-17.4,0-1.47,.46-1.92,1.96-1.92h49.62c1.61,0,1.96,.56,1.96,2.03,.12,11.75,.12,23.28,.12,34.8Z"/>
  <path onClick={() => props.onFill(34)} fill={props.fillColors[34]} d="M37.08,106.65V35.92q0-2.37,2.42-2.37h48.93c1.73,0,2.3,.34,2.3,2.15-.12,5.31,0,10.62-.12,16.04,0,1.02-.35,2.26-.92,3.16-10.25,16.72-20.49,33.44-30.62,50.28-.81,1.36-1.96,1.47-3.34,1.47-4.49,.11-8.86,.11-13.35,.11-1.73-.11-3.34-.11-5.3-.11Z"/>
  <path onClick={() => props.onFill(35)} fill={props.fillColors[35]} d="M463.75,430.81v32.99q0,2.26-2.19,2.26h-49.27c-1.73,0-2.07-.56-2.07-2.15,.12-4.86,0-9.72,.12-14.69,0-1.02,.35-2.15,.81-2.94,10.25-16.72,20.49-33.44,30.62-50.28,.58-.9,1.15-1.36,2.3-1.36,6.1,.23,12.09,.34,18.19,.56,1.5,0,1.61,.9,1.61,2.03-.12,11.19-.12,22.37-.12,33.56Z"/>
  <path onClick={() => props.onFill(36)} fill={props.fillColors[36]} d="M37.2,395.56h11.86c2.42,0,4.95,.11,7.37,.11,1.04,0,1.73,.23,2.42,1.24,10.25,16.95,20.61,33.78,30.97,50.73,.46,.68,.69,1.58,.69,2.37,.12,4.86,0,9.72,.12,14.69,0,1.13-.35,1.58-1.61,1.58H38.12c-.23,0-.58-.11-1.04-.11,.12-23.61,.12-47.12,.12-70.62Z"/>
  <path onClick={() => props.onFill(37)} fill={props.fillColors[37]} d="M220.37,208.23c.46,.68,.81,1.13,1.15,1.58,8.75,12.88,17.38,25.65,26.13,38.53,.81,1.13,.92,1.92,.12,3.16-8.75,12.77-17.5,25.65-26.25,38.53-.35,.45-.69,1.02-1.27,1.58-.58-.9-1.15-1.58-1.61-2.26-8.63-12.65-17.15-25.31-25.79-37.96-.81-1.13-.81-1.92,0-3.16,8.75-12.88,17.5-25.76,26.25-38.64,.46-.34,.81-.79,1.27-1.36Z"/>
  <path onClick={() => props.onFill(38)} fill={props.fillColors[38]} d="M280.46,291.5c-.46-.68-.81-1.02-1.15-1.47-8.75-12.88-17.38-25.65-26.25-38.53-.81-1.24-.81-2.03,0-3.28,8.75-12.77,17.38-25.54,26.13-38.3,.35-.45,.69-1.02,1.15-1.58,.46,.68,.92,1.13,1.27,1.58,8.75,12.77,17.38,25.54,26.13,38.3,.81,1.13,.92,1.92,.12,3.05-8.86,12.88-17.61,25.87-26.36,38.76-.23,.45-.58,.79-1.04,1.47Z"/>
  <path onClick={() => props.onFill(39)} fill={props.fillColors[39]} d="M278.74,294.1c-9.44,13.9-18.77,27.68-28.32,41.69-1.84-2.71-3.57-5.08-5.18-7.57-7.48-10.96-14.97-22.03-22.45-32.99-.58-.79-.69-1.36-.12-2.26,8.98-13.11,17.96-26.33,26.82-39.43,.23-.23,.46-.56,.92-1.13,9.56,14.01,19,27.8,28.32,41.69Z"/>
  <path onClick={() => props.onFill(40)} fill={props.fillColors[40]} d="M250.42,247.32c-4.14-6.1-8.06-11.86-11.97-17.63-5.18-7.57-10.36-15.25-15.54-22.82-.58-.79-.69-1.36-.12-2.26,8.98-13.11,17.96-26.33,26.94-39.43,.23-.34,.46-.56,.81-1.02,.35,.45,.69,.9,1.04,1.24,8.75,12.88,17.61,25.87,26.36,38.76,.81,1.13,.81,1.81,0,2.94-8.75,12.88-17.5,25.76-26.25,38.64-.46,.45-.81,.9-1.27,1.58Z"/>


</g>
            </svg>
        </div>
    )
}