import React, { useState } from 'react';
import {NavLink} from 'react-router-dom'
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import CollectionsIcon from '@mui/icons-material/Collections';


export default function NavBar() {
    const {token} = useSelector(state => state.auth);
    const [mobileActive, setMobileActive] = useState(false); 
    const activeClass = mobileActive ? "active" : ""


    const toggleActive = () => {
        setMobileActive(!mobileActive)
    }
    
    return (
        <>
        <img src="/white-hamburger-menu.png" alt="mobile menu" id="hamburger-menu" onClick={toggleActive}/>
        {(!token) ?
            ( 
            <ul className={`nav-bar ${activeClass}`}>
            <li>
                <NavLink onClick={toggleActive} className="nav-bar-link" to="/">Home</NavLink>
            </li>
            <NavLink onClick={toggleActive} className="nav-bar-link" to="/images">
            <li>
                Our Designs
            </li>
            </NavLink>
            </ul>
            )
        :
            (
            <ul className={`nav-bar ${activeClass}`}>
                <li>
                <NavLink onClick={toggleActive} className="nav-bar-link" to="/">Home</NavLink>
                </li>
                <li>
                <NavLink onClick={toggleActive} className="nav-bar-link" to="/images">Our Designs</NavLink>
                </li>                
                <li>
                <NavLink onClick={toggleActive} className="nav-bar-link" to="/mygallery"><CollectionsIcon/> My Gallery</NavLink>
                </li>
                <li>
                <NavLink onClick={toggleActive} className="nav-bar-link" to="/cart">My Cart</NavLink>
                </li>
                <li>
                <NavLink onClick={toggleActive} className="nav-bar-link" to="/orders">My Orders</NavLink>
                </li>
            </ul>
            )}
        </>

    )
};