import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ImageCard from './ImageCard.js';
import LoadingSpinner from '../LoadingSpinner.js';


export default function ImageGallery() {
 
    const dispatch = useDispatch();
    useEffect(() => {
      fetch("https://test-api.stainedglassusa.com/images", {
        headers: {
            'Access-Control-Allow-Origin':'*',
            'Access-Control-Allow-Methods': 'OPTIONS,POST,GET',
        }
    })
        .then(r => r.json())
        .then((resp) => {
            dispatch({
                type: 'SET_IMAGES',
                payload: resp
            });
        })  
        .catch(error => alert(error.message));
    }, [dispatch]);

    const images = useSelector(state => state.image);

    
    return (
        <>
            {images.length === 0 ?
            <LoadingSpinner /> :
            <div>
                <h1>Choose a Pattern to color!</h1>
                <div id="image-gallery"> 
                    {images.map(image => 
                        <ImageCard image={image} key={image.id} />
                    )}
                </div> 
            </div>
            }
        </>
    )
}

