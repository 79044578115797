import React from 'react';
import Patterns from './patterns';


export default function Put01(props) {
    
    return (
        <div className={`Put01-container ${props.size}`}> 
 
            <svg  xmlns="http://www.w3.org/2000/svg" id={props.id} width="500" height="500" viewBox="0 0 500 500">
                
<Patterns />                                   
            <g className="hummingbird-image" >

<path id="background" fill="black"  d="m0,500V0h752v752H0Z"/>

  <path  onClick={() => props.onFill(1)} fill={props.fillColors[1]}  d="M267.04,58.6c53.92,.05,107.83,.04,161.75,.05,.72,0,1.43,.08,2.46,.15h0v3.66c0,48.31,.05,122.17,0,170.48v.87c-1.15,1.1-14.33,14.57-14.33,14.57h-.8c-18.58-.27-37.99-.42-56.57-.55-2.7-.02-3.54-.93-3.9-4.08-2.34-20.56-12.64-34.99-29.35-44.13-6.91-3.78-14.37-5.45-21.88-6.03-3.68-34.57-21.53-53.25-52.8-57.67-.04-.59-.16-1.46-.16-2.32,0-13.62-.03-27.23,.03-40.85,0-1.36,.1-18.05,.1-19.25"/>
  <path  onClick={() => props.onFill(2)} fill={props.fillColors[2]}  d="M434.27,233.59c-.03-49.44-.02-123.33-.02-172.78,0-.59,.06-1.18,.1-1.97h44.24V248.6h0c-2.68,0-5.23,.02-7.78,0-6.91-.06-15.08-.18-21.98-.16"/>
  <path  onClick={() => props.onFill(3)} fill={props.fillColors[3]}  d="M252.4,25.52h178.7v29.62c-1.11,.05-2.1,.14-3.08,.14-53.44,0-106.88,0-160.32,.06,.38,0-15.23-13.6-16.11-15.79v-14.04h.81Z"/>
  <path  onClick={() => props.onFill(4)} fill={props.fillColors[4]}  d="M251.6,2.5h226.92V21.79H251.6V2.5Z"/>
  <path  onClick={() => props.onFill(5)} fill={props.fillColors[5]}  d="M481.98,25.47h15.52V248.36h-15.52V25.47Z"/>
  <path  onClick={() => props.onFill(6)} fill={props.fillColors[6]}  d="M300.69,193.08c-4.6,.91-9.46,1.06-13.72,2.89-9.42,4.03-15.78-1.17-21.09-8.07-8.45-10.97-12.95-24-13.89-38.4-.2-3.1-.3-6.21-.43-9.32,23.9-.66,49.11,22.43,49.13,52.9Z"/>
  <path  onClick={() => props.onFill(7)} fill={props.fillColors[7]}  d="M352.93,247.33c-11.09,.02-21.38-2.1-31.13-6.97-3.42-1.71-6.77-3.62-10.03-5.66-5.75-3.61-9.46-9.14-11.71-15.88-.23-.68-.27-1.55-.1-2.24,1.54-6.28,3.13-12.54,4.86-19.38,11.08,.43,21.56,4.6,30.63,12.75,10.63,9.54,16.48,21.9,17.47,37.38h0Z"/>
  <path  onClick={() => props.onFill(8)} fill={props.fillColors[8]}  d="M265.48,249.57c19.1-9.31,21.84-11.66,31.97-27.26,.16,.03,.43,0,.46,.1,4.25,10.81,12.61,16.49,21.95,20.74,4.9,2.22,10.06,3.75,15.09,5.6,.41,.15,.8,.37,1.22,.58-15.16,5.33-30.69,9.69-38.63,27.46-7.46-13.67-18.18-22.42-32.06-27.21h0Z"/>
  <path  onClick={() => props.onFill(9)} fill={props.fillColors[9]}  d="M276.2,200.19c-12.67,7.43-21.57,18.21-26.39,33.7-4.88-15.25-13.56-26.12-26.3-33.63,16.4-7.95,21.53-24.26,26.37-40.3,4.84,15.99,9.9,32.27,26.32,40.22h0Z"/>
  <path  onClick={() => props.onFill(10)} fill={props.fillColors[10]}  d="M300.92,196.56c-1.3,18.19-8.34,31.67-21.1,41.58-5.85,4.55-12.38,7.45-19.41,8.9-.73,.15-1.79-.64-2.45-1.3-1.4-1.39-2.4-3.57-4.01-4.33-2.3-1.1-2.14-2.45-1.6-4.47,5.3-19.92,17.5-32.13,35.38-37.8,4.08-1.29,8.39-1.67,13.19-2.58h0Z"/>
  <path  onClick={() => props.onFill(11)} fill={props.fillColors[11]}  d="M434.47,25.5h44.07v29.48h-44.07V25.5Z"/>
  <path  onClick={() => props.onFill(12)} fill={props.fillColors[12]}  d="M481.89,21.71V2.53h15.6V21.71h-15.6Z"/>
  <path  onClick={() => props.onFill(13)} fill={props.fillColors[13]}  d="M249.77,255.32c-3.17-.02-5.46-2.37-5.5-5.67-.05-3.6,2.27-5.93,5.85-5.89,3.17,.03,5.36,2.41,5.37,5.83,0,3.42-2.32,5.76-5.72,5.74h0Z"/>
  <polygon  onClick={() => props.onFill(14)} fill={props.fillColors[14]}  points="249.72 42.23 264.11 56.84 249.72 71.45 235.33 56.84 249.72 42.23"/>
  <polygon  onClick={() => props.onFill(15)} fill={props.fillColors[15]}  points="432.88 234.7 447.27 249.31 432.88 263.92 418.49 249.31 432.88 234.7"/>
  <path  onClick={() => props.onFill(16)} fill={props.fillColors[16]}  d="M248.4,73.5c0,1.2,.1,17.89,.1,19.25,.06,13.62,.04,27.23,.03,40.85,0,.86-.12,1.73-.16,2.32-31.26,4.42-49.11,23.1-52.8,57.67-7.51,.58-14.96,2.25-21.88,6.03-16.72,9.14-27.01,23.57-29.35,44.13-.36,3.15-1.2,4.06-3.9,4.08-18.58,.13-37.99,.28-56.57,.55h-.8s-13.18-13.47-14.33-14.57v-.87c-.05-48.31,0-122.17,0-170.48v-3.66h0c1.03-.07,1.74-.15,2.46-.15,53.92,0,107.83,0,161.75-.05"/>
  <path  onClick={() => props.onFill(17)} fill={props.fillColors[17]}  d="M51.17,248.44c-6.91-.02-15.08,.1-21.98,.16-2.55,.02-5.1,0-7.78,0h0V58.84h44.24c.04,.79,.1,1.38,.1,1.97,0,49.44,0,123.33-.02,172.78"/>
  <path  onClick={() => props.onFill(18)} fill={props.fillColors[18]}  d="M248.4,25.51v14.04c-.88,2.19-16.49,15.79-16.11,15.79-53.45-.07-106.89-.06-160.32-.06-.99,0-1.97-.09-3.08-.14V25.52H248.4Z"/>
  <path  onClick={() => props.onFill(19)} fill={props.fillColors[19]}  d="M248.4,21.79H21.48V2.5H248.4V21.79Z"/>
  <path  onClick={() => props.onFill(20)} fill={props.fillColors[20]}  d="M18.02,248.36H2.5V25.47h15.52V248.36Z"/>
  <path  onClick={() => props.onFill(21)} fill={props.fillColors[21]}  d="M248.44,140.18c-.14,3.11-.24,6.22-.43,9.32-.94,14.4-5.44,27.43-13.89,38.4-5.31,6.9-11.67,12.1-21.09,8.07-4.27-1.83-9.12-1.98-13.72-2.89,.02-30.47,25.24-53.56,49.13-52.9Z"/>
  <path  onClick={() => props.onFill(22)} fill={props.fillColors[22]}  d="M147.07,247.33c.99-15.48,6.85-27.84,17.47-37.38,9.07-8.15,19.55-12.32,30.63-12.75,1.72,6.84,3.32,13.1,4.86,19.38,.17,.69,.13,1.56-.1,2.24-2.26,6.74-5.96,12.27-11.71,15.88-3.26,2.04-6.61,3.95-10.03,5.66-9.75,4.87-20.04,6.99-31.13,6.97h0Z"/>
  <path  onClick={() => props.onFill(23)} fill={props.fillColors[23]}  d="M234.52,249.58c-13.88,4.79-24.61,13.54-32.06,27.21-7.94-17.77-23.47-22.13-38.63-27.46,.42-.21,.81-.43,1.22-.58,5.03-1.85,10.2-3.38,15.09-5.6,9.34-4.25,17.7-9.93,21.95-20.74,.03-.1,.31-.07,.46-.1,10.14,15.6,12.87,17.95,31.97,27.26h0Z"/>
  <path  onClick={() => props.onFill(24)} fill={props.fillColors[24]}  d="M199.08,196.56c4.8,.91,9.11,1.29,13.19,2.58,17.88,5.67,30.08,17.88,35.38,37.8,.54,2.02,.71,3.37-1.6,4.47-1.61,.76-2.61,2.94-4.01,4.33-.66,.66-1.72,1.45-2.45,1.3-7.03-1.45-13.56-4.35-19.41-8.9-12.76-9.91-19.8-23.39-21.1-41.58h0Z"/>
  <path  onClick={() => props.onFill(25)} fill={props.fillColors[25]}  d="M65.53,54.98H21.46V25.5h44.07v29.48Z"/>
  <path  onClick={() => props.onFill(26)} fill={props.fillColors[26]}  d="M2.51,21.71V2.53h15.6V21.71H2.51Z"/>
  <path  onClick={() => props.onFill(27)} fill={props.fillColors[27]}  d="M250.23,255.33c-3.4,.02-5.72-2.32-5.72-5.74,0-3.42,2.2-5.8,5.37-5.83,3.59-.04,5.9,2.29,5.85,5.89-.04,3.3-2.32,5.65-5.5,5.67h0Z"/>
  <polygon  onClick={() => props.onFill(28)} fill={props.fillColors[28]} points="67.12 234.7 52.73 249.31 67.12 263.92 81.51 249.31 67.12 234.7"/>
  <path  onClick={() => props.onFill(29)} fill={props.fillColors[29]} d="M251.6,425.5c0-1.2-.1-17.89-.1-19.25-.06-13.62-.04-27.23-.03-40.85,0-.86,.12-1.73,.16-2.32,31.26-4.42,49.11-23.1,52.8-57.67,7.51-.58,14.96-2.25,21.88-6.03,16.72-9.14,27.01-23.57,29.35-44.13,.36-3.15,1.2-4.06,3.9-4.08,18.58-.13,37.99-.28,56.57-.55h.8s13.18,13.47,14.33,14.57v.87c.05,48.31,0,122.17,0,170.48v3.66h0c-1.03,.07-1.74,.15-2.46,.15-53.92,0-107.83,0-161.75,.05"/>
  <path  onClick={() => props.onFill(30)} fill={props.fillColors[30]} d="M448.83,250.56c6.91,.02,15.08-.1,21.98-.16,2.55-.02,5.1,0,7.78,0h0v189.76h-44.24c-.04-.79-.1-1.38-.1-1.97,0-49.44,0-123.33,.02-172.78"/>
  <path  onClick={() => props.onFill(31)} fill={props.fillColors[31]} d="M251.6,473.49v-14.04c.88-2.19,16.49-15.79,16.11-15.79,53.45,.07,106.89,.06,160.32,.06,.99,0,1.97,.09,3.08,.14v29.62H251.6Z"/>
  <path  onClick={() => props.onFill(32)} fill={props.fillColors[32]} d="M251.6,477.21h226.92v19.29H251.6v-19.29Z"/>
  <path  onClick={() => props.onFill(33)} fill={props.fillColors[33]} d="M481.98,250.64h15.52v222.89h-15.52V250.64Z"/>
  <path  onClick={() => props.onFill(34)} fill={props.fillColors[34]} d="M251.56,358.82c.14-3.11,.24-6.22,.43-9.32,.94-14.4,5.44-27.43,13.89-38.4,5.31-6.9,11.67-12.1,21.09-8.07,4.27,1.83,9.12,1.98,13.72,2.89-.02,30.47-25.24,53.56-49.13,52.9Z"/>
  <path  onClick={() => props.onFill(35)} fill={props.fillColors[35]} d="M352.93,251.67c-.99,15.48-6.85,27.84-17.47,37.38-9.07,8.15-19.55,12.32-30.63,12.75-1.72-6.84-3.32-13.1-4.86-19.38-.17-.69-.13-1.56,.1-2.24,2.26-6.74,5.96-12.27,11.71-15.88,3.26-2.04,6.61-3.95,10.03-5.66,9.75-4.87,20.04-6.99,31.13-6.97h0Z"/>
  <path  onClick={() => props.onFill(36)} fill={props.fillColors[36]} d="M276.2,298.82c-16.42,7.95-21.48,24.23-26.32,40.22-4.84-16.04-9.97-32.35-26.37-40.3,12.74-7.51,21.42-18.38,26.3-33.63,4.82,15.49,13.72,26.27,26.39,33.7h0Z"/>
  <path  onClick={() => props.onFill(37)} fill={props.fillColors[37]} d="M300.92,302.44c-4.8-.91-9.11-1.29-13.19-2.58-17.88-5.67-30.08-17.88-35.38-37.8-.54-2.02-.71-3.37,1.6-4.47,1.61-.76,2.61-2.94,4.01-4.33,.66-.66,1.72-1.45,2.45-1.3,7.03,1.45,13.56,4.35,19.41,8.9,12.76,9.91,19.8,23.39,21.1,41.58h0Z"/>
  <path  onClick={() => props.onFill(38)} fill={props.fillColors[38]} d="M434.47,444.02h44.07v29.48h-44.07v-29.48Z"/>
  <path  onClick={() => props.onFill(39)} fill={props.fillColors[39]} d="M497.49,477.29v19.18h-15.6v-19.18h15.6Z"/>
  <polygon  onClick={() => props.onFill(40)} fill={props.fillColors[40]}  points="249.72 456.77 264.11 442.16 249.72 427.55 235.33 442.16 249.72 456.77"/>
  <path  onClick={() => props.onFill(41)} fill={props.fillColors[41]}  d="M232.96,440.4c-53.92-.05-107.83-.04-161.75-.05-.72,0-1.43-.08-2.46-.15h0v-3.66c0-48.31-.05-122.17,0-170.48v-.87c1.15-1.1,14.33-14.57,14.33-14.57h.8c18.58,.27,37.99,.42,56.57,.55,2.7,.02,3.54,.93,3.9,4.08,2.34,20.56,12.64,34.99,29.35,44.13,6.91,3.78,14.37,5.45,21.88,6.03,3.68,34.57,21.53,53.25,52.8,57.67,.04,.59,.16,1.46,.16,2.32,0,13.62,.03,27.23-.03,40.85,0,1.36-.1,18.05-.1,19.25"/>
  <path  onClick={() => props.onFill(42)} fill={props.fillColors[42]}  d="M65.73,265.41c.03,49.44,.02,123.33,.02,172.78,0,.59-.06,1.18-.1,1.97H21.41V250.4h0c2.68,0,5.23-.02,7.78,0,6.91,.06,15.08,.18,21.98,.16"/>
  <path  onClick={() => props.onFill(43)} fill={props.fillColors[43]}  d="M247.6,473.48H68.89v-29.62c1.11-.05,2.1-.14,3.08-.14,53.44,0,106.88,0,160.32-.06-.38,0,15.23,13.6,16.11,15.79v14.04h-.81Z"/>
  <path  onClick={() => props.onFill(44)} fill={props.fillColors[44]}  d="M248.4,496.5H21.48v-19.29H248.4v19.29Z"/>
  <path  onClick={() => props.onFill(45)} fill={props.fillColors[45]}  d="M18.02,473.53H2.5V250.64h15.52v222.89Z"/>
  <path  onClick={() => props.onFill(46)} fill={props.fillColors[46]}  d="M199.31,305.92c4.6-.91,9.46-1.06,13.72-2.89,9.42-4.03,15.78,1.17,21.09,8.07,8.45,10.97,12.95,24,13.89,38.4,.2,3.1,.3,6.21,.43,9.32-23.9,.66-49.11-22.43-49.13-52.9Z"/>
  <path  onClick={() => props.onFill(47)} fill={props.fillColors[47]}  d="M147.07,251.67c11.09-.02,21.38,2.1,31.13,6.97,3.42,1.71,6.77,3.62,10.03,5.66,5.75,3.61,9.46,9.14,11.71,15.88,.23,.68,.27,1.55,.1,2.24-1.54,6.28-3.13,12.54-4.86,19.38-11.08-.43-21.56-4.6-30.63-12.75-10.63-9.54-16.48-21.9-17.47-37.38h0Z"/>
  <path  onClick={() => props.onFill(48)} fill={props.fillColors[48]}  d="M199.08,302.44c1.3-18.19,8.34-31.67,21.1-41.58,5.85-4.55,12.38-7.45,19.41-8.9,.73-.15,1.79,.64,2.45,1.3,1.4,1.39,2.4,3.57,4.01,4.33,2.3,1.1,2.14,2.45,1.6,4.47-5.3,19.92-17.5,32.13-35.38,37.8-4.08,1.29-8.39,1.67-13.19,2.58h0Z"/>
  <path  onClick={() => props.onFill(49)} fill={props.fillColors[49]}  d="M65.53,473.5H21.46v-29.48h44.07v29.48Z"/>
  <path  onClick={() => props.onFill(50)} fill={props.fillColors[50]}  d="M18.11,477.29v19.18H2.51v-19.18h15.6Z"/>



    </g>
            </svg>
        </div>
    )
}