import React from 'react';
import Patterns from './patterns';

export default function Cross(props) {
    
    return (
        <div className={`Cross-container ${props.size}`}> 
 
            <svg  xmlns="http://www.w3.org/2000/svg" id={props.id} width="500" height="655" viewBox="0 0 500 655">
            <Patterns />   
            <g className="hummingbird-image" >

<path id="background" fill="black"  d="m0,500V0h655v655H0Z"/>

<path onClick={() => props.onFill(1)} fill={props.fillColors[1]} d="M461.4,325.5c12.3,0,24.3,0,36.4,0c0,96.2,0,192.2,0,288.5c-3.6,0-7.2,0-10.8,0c-2.5,0-5.3,0.6-7.5-0.3 c-2.1-0.9-3.6-3.3-5.3-5c-3.9-3.8-7.9-7.7-11.9-11.5c-1-0.9-1.3-1.8-1.3-3.1c0.2-72.5,0.3-144.9,0.4-217.4c0-16.3,0-32.6,0-48.9 C461.4,326.9,461.4,326.2,461.4,325.5z"/>
<path onClick={() => props.onFill(2)} fill={props.fillColors[2]} d="M39.3,325.3c0,5.3,0,10.4,0,15.6c0,12,0,24,0,36c0,17.4,0,34.8,0,52.2c0,50.6,0,101.2,0,151.7 c0,4.2-0.1,8.5-0.4,12.7c-0.1,1-1.1,2.1-1.9,3c-5.3,5.4-10.6,10.8-15.9,16.1c-0.5,0.5-1.5,0.9-2.3,0.9c-3.4,0.1-6.8,0.1-10.3,0 c-1.8,0-3.6-0.3-5.6-0.5c0-95.8,0-191.7,0-287.7C15,325.3,27,325.3,39.3,325.3z"/>
<path onClick={() => props.onFill(3)} fill={props.fillColors[3]} d="M497.8,323.2c-12.1-0.2-24.1-0.4-36.4-0.6c0-0.8,0-1.5,0-2.1c0-86,0-172,0-258c0-2,0.5-3.6,2-5.1 c5-4.7,9.7-9.6,14.6-14.5c1.3-1.3,2.6-1.9,4.5-1.9c5.1,0.1,10.1,0,15.3,0C497.8,135.1,497.8,229.1,497.8,323.2z"/>
<path onClick={() => props.onFill(4)} fill={props.fillColors[4]} d="M39.3,322.5c-12.3,0-24.3,0-36.4,0c0-94,0-187.9,0-282.1c0.6,0,1.2,0,1.8,0c4.6,0,9.3-0.1,13.9,0 c0.9,0,2,0.4,2.6,1.1c5.2,5.1,10.2,10.3,15.4,15.3c1.6,1.5,2.3,3.3,2.4,5.3c0.2,3.6,0.4,7.2,0.4,10.8c0,31.8,0,63.6,0,95.4 c0,20.7,0,41.4,0,62.1c0,13.6,0,27.2,0,40.8c0,16.3,0,32.6,0,49C39.3,320.8,39.3,321.6,39.3,322.5z"/>
<path onClick={() => props.onFill(5)} fill={props.fillColors[5]} d="M250.7,38.4c0-12.3,0-24.3,0-36.5c69.5,0,138.8,0,208.5,0c0,0.6,0,1.2,0,1.8c0,4.6,0.1,9.3-0.1,13.9 c0,1.1-0.5,2.4-1.2,3.1c-5.3,5.5-10.8,10.9-16.3,16.3c-0.6,0.6-1.8,0.9-2.7,0.9c-49.7,0-99.3,0-149,0.1c-12.3,0-24.7,0.2-37,0.4 C252.2,38.4,251.5,38.4,250.7,38.4z"/>
<path onClick={() => props.onFill(6)} fill={props.fillColors[6]} d="M250.3,652.7c0-12.2,0-24.2,0-36.4c0.6,0,1.2,0,1.7,0c0.4,0,0.8,0,1.1,0c30.1,0.1,60.2,0.3,90.3,0.4 c31.7,0.1,63.4,0.3,95.1,0.4c1.2,0,2.1,0.3,3,1.2c5.3,5.6,10.8,11.1,16.1,16.6c0.4,0.4,0.7,0.9,0.7,1.4c0,5.4,0,10.9,0,16.5 C389,652.7,319.7,652.7,250.3,652.7z"/>
<path onClick={() => props.onFill(7)} fill={props.fillColors[7]} d="M41.2,1.9c69,0,137.7,0,206.6,0c0,12.1,0,24.2,0,36.4c-0.6,0-1.2,0.1-1.8,0.1c-10.1,0-20.3,0-30.4,0 c-51.4-0.1-102.8-0.2-154.2-0.4c-0.9,0-2.1-0.5-2.8-1.1c-5.4-5.3-10.8-10.8-16.2-16.1c-0.9-0.9-1.2-1.8-1.2-3 C41.3,12.5,41.2,7.3,41.2,1.9z"/>
<path onClick={() => props.onFill(8)} fill={props.fillColors[8]} d="M41.2,652.6c0-5.9,0-11.8,0-17.6c0-0.6,0.5-1.3,0.9-1.7c2.8-2.8,5.6-5.6,8.5-8.4c2.4-2.4,4.8-4.8,7.3-7 c0.6-0.5,1.5-0.9,2.3-0.9c31,0,62,0,93-0.1c20.8,0,41.5-0.2,62.3-0.4c10-0.1,20-0.2,30-0.4c0.7,0,1.4,0,2.1,0c0,12.2,0,24.3,0,36.4 C179,652.6,110.3,652.6,41.2,652.6z"/>
<path onClick={() => props.onFill(9)} fill={props.fillColors[9]} d="M352.2,498.5c2.8,0,5.5,0.2,8.1,0c5.2-0.5,10.4-1.3,13.8-5.8c1.8-2.3,3-5.1,4.3-7.7c0.9-1.9,1.2-4,1.9-6.3 c2.8,3.7,5.4,7.1,7.9,10.6c2.8,3.8,5.6,7.6,8.4,11.4c2.9,4,5.8,7.9,8.7,11.9c3.6,4.9,7.3,9.8,10.9,14.7c2.7,3.7,5.3,7.4,8,11 c3.8,5.1,7.6,10.2,11.4,15.3c6.9,9.3,13.5,18.8,20.6,28c2.3,3,2.9,5.9,2.1,9.4c0,0.2-0.1,0.4,0,0.6c0.8,3.5-1.1,5.7-3.4,8 c-4.9,4.7-9.6,9.6-14.5,14.3c-0.6,0.5-1.5,1-2.3,1c-11.7,0.1-23.3,0-35,0.1c-2.5,0-2.5-2-3-3.2c-5-11.4-9.8-22.9-14.6-34.4 c-2.5-6-5-12-7.6-18c-2.8-6.6-5.8-13.1-8.6-19.7c-3.1-7.4-6.2-14.8-9.3-22.1C357.6,511.2,354.9,505,352.2,498.5z"/>
<path onClick={() => props.onFill(10)} fill={props.fillColors[10]} d="M119.2,478.6c0.7,2.4,1.4,4.4,2,6.5c1,3.5,2.7,6.7,5.4,9c1.8,1.6,4.2,2.8,6.6,3.2c4.6,0.9,9.3,1.2,14,1.7 c-1.4,3.4-2.9,7.2-4.5,10.9c-1.7,4-3.5,7.9-5.2,11.8c-2.6,6.1-5.1,12.1-7.7,18.2c-2.4,5.7-4.8,11.4-7.3,17.1 c-2.6,5.9-5.2,11.8-7.7,17.8c-2.1,4.9-4.1,9.8-6.2,14.6c-2.8,6.5-5.5,13-8.2,19.6c-0.5,1.1-1.1,2.1-1.4,3.3 c-0.5,2.2-1.8,2.5-3.8,2.5c-11.4-0.1-22.8,0-34.3-0.1c-0.9,0-2.1-0.5-2.7-1.2c-5.5-6-11.1-12.1-16.3-18.3c-0.8-0.9-0.4-2.8-0.5-4.3 c0-0.4,0.3-0.9,0.2-1.3c-0.9-4.2,1.5-7.1,3.8-10.1c5.5-7.4,10.9-14.8,16.4-22.3c4.1-5.5,8.1-11,12.2-16.6 c5.4-7.4,10.8-14.9,16.2-22.4c5.9-8.1,11.9-16.2,17.8-24.2C111.6,489,115.3,483.9,119.2,478.6z"/>
<path onClick={() => props.onFill(11)} fill={props.fillColors[11]} d="M421.7,40.3c5.8,0,11.7,0,17.5,0c0.8,0,1.8,0.4,2.4,1c4,3.9,7.9,7.8,11.9,11.8c1.7,1.7,3.4,3.6,5,5.4 c0.3,0.4,0.6,1,0.6,1.5c-0.1,5.1-0.2,10.1-0.4,15.2c0,0.7-0.4,1.5-0.9,2c-3.8,3.4-7.6,6.7-11.5,10.1c-1.7,1.5-3.2,3-4.9,4.4 c-5.6,5-11.2,10-16.8,14.9c-2.6,2.3-5.2,4.7-7.9,7.1c-5.6,5-11.2,9.9-16.8,14.9c-2.4,2.1-4.8,4.3-7.2,6.4 c-5.9,5.2-11.7,10.5-17.6,15.7c-1,0.9-2,1.7-2.9,2.7c-2.4,2.9-5.4,1.6-8,0.9c-7.9-2.1-12.3-8.1-15.6-15.1c-0.8-1.7-1.4-3.4-2.3-5.1 c-1.1-2.3,0.4-3.8,1.4-5.5c7.5-12.3,15-24.6,22.6-36.9c5.5-8.9,11-17.8,16.5-26.7c4.8-7.7,9.5-15.5,14.2-23.2 c0.7-1.2,1.6-1.6,2.9-1.6C410,40.3,415.9,40.3,421.7,40.3z"/>
<path onClick={() => props.onFill(12)} fill={props.fillColors[12]} d="M41.7,70.2c-0.2-3.4-0.4-6.8-0.4-10.2c0-0.7,0.3-1.5,0.7-2c5.4-5.6,10.8-11.2,16.3-16.6c0.6-0.6,1.7-1,2.6-1.1 c11.5-0.1,23,0,34.4-0.1c1.5,0,2.4,0.5,3.2,1.8c4.7,7.7,9.5,15.3,14.2,23c4.8,7.8,9.6,15.7,14.4,23.5c4.8,7.7,9.6,15.4,14.3,23.2 c3.9,6.3,7.9,12.7,11.7,19.1c0.4,0.8,0.4,2.1,0,3c-2.2,5.4-4.5,10.7-8.8,15c-3.6,3.6-8.1,5.4-12.9,6.6c-2.2,0.5-3.6-0.1-5-1.5 c-4-3.8-8-7.5-12-11.2c-7.1-6.5-14.2-13-21.4-19.5c-6-5.5-12-11-18-16.5c-4.4-4-8.8-8.1-13.2-12.1c-4.5-4.2-9.1-8.4-13.7-12.5 c-1.5-1.4-3.1-2.7-4.7-4c-1.2-1-2.1-2.1-1.8-3.9C41.8,72.9,41.6,71.6,41.7,70.2C41.7,70.2,41.7,70.2,41.7,70.2z"/>
<path onClick={() => props.onFill(13)} fill={props.fillColors[13]} d="M314.7,461c-2.5,0.7-4.9,1.4-7.3,2c-5.7,1.3-11.4,2.3-17.3,2c-5.2-0.3-10.2-1.3-15-3.5 c-6.9-3.1-14-5.4-21.4-6.8c-2.8-0.5-2.9-0.6-2.9-3.3c0-34.8,0-69.7,0-104.5c0-12.5-0.2-25-0.4-37.4c0-1.4,0.5-2.3,1.7-2.8 c5.6-2.4,9.9-6.2,12.2-11.9c0.1-0.2,0.3-0.3,0.5-0.5c0.8,5.8,1.6,11.5,2.4,17.3c0.9,6.2,1.7,12.5,2.7,18.7 c0.8,5.1,1.9,10.2,2.9,15.4c0.9,4.7,1.8,9.4,2.8,14c1.3,5.8,2.7,11.6,4,17.4c0.6,2.7,1.1,5.4,1.7,8c1.1,4.5,2.3,9,3.7,13.4 c1.2,4.1,2.5,8.3,4,12.4c1.6,4.5,3.2,9.1,5.2,13.5c2,4.5,4.2,8.8,6.5,13.1c3,5.5,6.3,11,9.5,16.4C311.6,456.1,313.1,458.4,314.7,461 z"/>
<path onClick={() => props.onFill(14)} fill={props.fillColors[14]} d="M458.8,79.8c0,0.9,0,1.6,0,2.2c0,29.4,0,58.9,0,88.3c0,1.4-0.3,2.1-1.8,2.7c-7.4,3.1-14.7,6.4-22.1,9.7 c-5,2.2-10.1,4.3-15.2,6.5c-4.6,2-9.2,4.2-13.9,6.2c-2.1,0.9-4.2,1.7-6.4,2.6c0.1-2.4,0.1-4.5,0.4-6.7c1.2-10.5-1.5-19.4-10.4-26.6 c-4.7-3.8-9.9-6.3-15.6-8.2c-0.3-0.1-0.5-0.3-0.9-0.5c2.5-2.3,4.8-4.5,7.2-6.6c2.7-2.4,5.4-4.6,8.1-7c6.8-6.1,13.5-12.2,20.3-18.3 c5.8-5.2,11.6-10.3,17.3-15.5c5.8-5.1,11.5-10.3,17.3-15.4c4.6-4.1,9.3-8.3,13.9-12.4C457.6,80.6,458.1,80.3,458.8,79.8z"/>
<path onClick={() => props.onFill(15)} fill={props.fillColors[15]} d="M184.8,461.1c2.9-4.6,5.9-9.1,8.5-13.7c2.5-4.3,4.6-9,7.1-13.3c4-6.8,6.8-14.1,9.6-21.5 c1.6-4.2,2.8-8.5,4.1-12.7c0.9-3,1.9-6,2.7-9.1c0.7-2.6,1.3-5.2,1.8-7.8c0.8-3.8,1.7-7.6,2.5-11.4c1.1-4.8,2.1-9.7,3.2-14.5 c0.7-3,1.4-5.9,2-8.9c0.9-5.1,1.7-10.3,2.6-15.4c0.6-3.7,1.3-7.5,1.9-11.2c0.6-3.9,1.3-7.9,1.8-11.8c0.7-5.2,1.3-10.3,2-15.7 c1.3,2.1,2.2,4.3,3.7,5.9c2.3,2.4,5,4.4,7.8,6.3c1.5,1,2.2,1.9,2.2,3.8c-0.1,47.2-0.1,94.5,0,141.7c0,2-0.7,2.6-2.5,2.9 c-7.3,1.2-14.2,3.8-21.1,6.5c-3.8,1.5-7.8,2.6-11.7,3.3c-8,1.4-15.9-0.2-23.7-2.3C187.9,461.8,186.4,461.5,184.8,461.1z"/>
<path onClick={() => props.onFill(16)} fill={props.fillColors[16]} d="M41.6,79.9c0.7,0.4,1.1,0.6,1.5,0.9c3.6,3.3,7.2,6.7,10.8,10.1c6.1,5.6,12.2,11.2,18.2,16.7 c4.4,4,8.7,8,13.1,12c6,5.5,12.1,11.1,18.1,16.6c4.4,4,8.8,8,13.2,12c3.1,2.8,6.2,5.5,9,8.1c-4.3,2.2-8.6,4-12.6,6.5 c-4.5,2.9-8.4,6.6-10.8,11.5c-1.7,3.4-2.5,7-2.6,10.7c0,4.3,0.3,8.5,0.4,13c-1-0.4-2.1-0.7-3.2-1.2c-7.7-3.4-15.5-6.8-23.2-10.3 c-7.2-3.2-14.4-6.6-21.6-9.8c-3-1.4-6.2-2.5-9.2-3.9c-0.5-0.2-0.8-1.1-1.2-1.7c-0.1-0.1,0-0.4,0-0.6c0-29.8,0-59.6,0-89.5 C41.6,80.8,41.6,80.6,41.6,79.9z"/>
<path onClick={() => props.onFill(17)} fill={props.fillColors[17]} d="M154,614.5c-17.6,0-35.2,0-53,0c0.8-2.1,1.6-4.1,2.5-6.2c2.2-5.2,4.5-10.3,6.7-15.4c2.9-6.7,5.7-13.3,8.6-20 c2.1-4.9,4.2-9.9,6.3-14.8c2.4-5.7,4.9-11.4,7.3-17.1c2.5-5.8,4.9-11.7,7.4-17.5c2.5-5.9,5.1-11.7,7.7-17.6c0.9-2,1.6-4,2.5-5.9 c0.2-0.4,0.7-0.8,1.1-0.8c9.3,0.8,18,3.4,25.4,9.5c0.9,0.8,1.8,1.6,2.9,2.1c2.3,1,1.9,2.8,1.4,4.5c-1,3.8-2.1,7.5-3.1,11.3 c-1.1,4.2-2.2,8.5-3.3,12.7c-1.6,6.3-3.3,12.6-4.9,18.8c-1.4,5.3-2.9,10.6-4.3,15.9c-2,7.8-4,15.6-6.1,23.4 C157.2,602.9,155.6,608.5,154,614.5z"/>
<path onClick={() => props.onFill(18)} fill={props.fillColors[18]} d="M395.5,439.4c3.5,3.3,6.9,6.4,10.1,9.6c7.4,7.2,14.8,14.5,22.2,21.8c6.9,6.8,13.9,13.4,20.9,20.2 c3.1,2.9,6.1,5.8,9.2,8.8c0.5,0.5,0.9,1.3,0.9,1.9c0,26.1,0,52.2,0,78.8c-2-2.7-3.6-5.1-5.3-7.4c-5-6.8-10.1-13.5-15.1-20.2 c-5.1-6.9-10.2-13.8-15.3-20.7c-7.1-9.6-14.2-19.2-21.3-28.9c-5.8-7.9-11.7-15.9-17.5-23.8c-0.3-0.5-0.7-0.9-1.1-1.3 c-3.1-3.2-2.6-7.1-2.1-11c0.3-2.6,0.5-5.2,0.9-7.8c1-6.6,4.2-12.1,9.3-16.3C392.6,441.8,394.1,440.6,395.5,439.4z"/>
<path onClick={() => props.onFill(19)} fill={props.fillColors[19]} d="M398.7,614.7c-4.6,0-9,0-13.4,0c-12.2-0.1-24.4-0.3-36.6-0.4c-1,0-1.7-0.2-2-1.3c-0.9-3.4-1.8-6.8-2.8-10.2 c-0.9-3.3-1.9-6.5-2.8-9.8c-1-3.6-1.9-7.2-2.9-10.7c-2.1-7.4-4.2-14.7-6.3-22.1c-1-3.5-1.8-7.1-2.8-10.6c-1.7-6-3.5-12-5.2-18 c-1.4-4.9-2.6-9.8-4-14.7c-0.3-1.1-0.4-2.2-0.9-3.2c-0.6-1.4,0-2,1.1-2.7c3.7-2.4,7.1-5,10.9-7.3c3.9-2.3,8.3-3.5,12.9-4 c1.5-0.2,3-0.5,4.4-0.6c0.5,0,1.4,0.4,1.6,0.9c3.1,7.2,6.1,14.5,9.2,21.8c3.2,7.5,6.6,14.9,9.8,22.4c4.4,10.3,8.6,20.7,13,31 c3.2,7.5,6.5,15,9.8,22.5C394,603.3,396.3,608.9,398.7,614.7z"/>
<path onClick={() => props.onFill(20)} fill={props.fillColors[20]} d="M41.6,580.1c0-0.9,0-1.5,0-2c0-25.3,0-50.5,0-75.8c0-1.1,0.2-2,1.1-2.8c9.7-9.5,19.4-19.1,29.1-28.7 c6.5-6.4,13.1-12.9,19.7-19.3c4.1-4,8.3-8,12.6-12c4.2,3.4,8.6,6.7,11,11.7c1.5,3.1,2.4,6.5,3,9.9c0.6,3.6,0.5,7.3,0.9,10.9 c0.3,2.7-1.3,4.6-2.7,6.4c-6.8,9.4-13.8,18.7-20.7,28.1c-7.3,9.9-14.5,19.8-21.7,29.7c-7.2,9.9-14.4,19.7-21.6,29.6 c-3.3,4.5-6.5,9-9.7,13.5C42.4,579.5,42.1,579.7,41.6,580.1z"/>
<path onClick={() => props.onFill(21)} fill={props.fillColors[21]} d="M100.4,40.3c10,0,19.6,0,29.2,0c4.1,0,8.1,0,12.2,0c6.1,0.1,12.1,0.3,18.2,0.3c2,0,1.9,1.8,2.3,2.7 c1.8,4.3,3.3,8.8,5,13.1c1.7,4.4,3.5,8.8,5.2,13.3c1.1,2.9,2.1,5.8,3.2,8.7c2.4,6.2,4.8,12.3,7.2,18.4c1.9,4.8,3.8,9.7,5.6,14.5 c1.3,3.3,2.5,6.7,3.7,10.1c0.1,0.4-0.2,1.3-0.5,1.6c-5.7,4.3-12,6.9-19.2,8c-5,0.7-9.8-0.1-14.7-0.2c-0.9,0-2-0.8-2.5-1.6 c-5.7-9-11.2-18-16.8-27.1c-5-8.2-10-16.3-15-24.5c-3.5-5.8-7.1-11.5-10.6-17.3c-3.7-6.1-7.5-12.2-11.3-18.3 C101.2,41.7,100.9,41.1,100.4,40.3z"/>
<path onClick={() => props.onFill(22)} fill={props.fillColors[22]} d="M398.8,40.7c-2,3.4-4.1,6.9-6.2,10.4c-3.7,6.1-7.4,12.1-11.1,18.1c-5.1,8.2-10.2,16.4-15.2,24.6 c-5.2,8.5-10.3,17-15.5,25.5c-2.1,3.4-4.2,6.8-6.4,10.1c-0.5,0.7-1.5,1.5-2.3,1.5c-6.5,0.6-13.1,1-19.5-0.4c-4.5-1-8.6-3.2-12.2-6.1 c-3-2.5-2.9-2.6-1.5-6.2c3.8-9.8,7.5-19.6,11.3-29.4c3.3-8.5,6.6-16.9,9.8-25.4c2.7-7.1,5.4-14.3,8.1-21.5c0.2-0.5,0.9-1,1.4-1 C359.2,40.7,378.9,40.7,398.8,40.7z"/>
<path onClick={() => props.onFill(23)} fill={props.fillColors[23]} d="M271.4,257.2c0.6-0.4,1.3-0.8,1.8-1.3c31.8-31.8,63.7-63.7,95.5-95.6c1.5-1.5,2.6-2.1,4.8-1.2 c4.8,2,9.6,4,13.8,7.2c5.2,4,9,9,9.7,15.6c0.5,4.5,0,9.1-0.1,13.7c0,0.2-0.2,0.4-0.2,0.5c0.9,3.7-1.9,4.3-4.4,5.4 c-4.4,2.1-8.8,4.4-13.1,6.5c-4.1,2-8.3,3.9-12.4,6c-5.6,2.8-11.3,5.6-16.9,8.5c-4.2,2.1-8.3,4.4-12.4,6.6c-5,2.7-10,5.4-15.1,8.1 c-5.5,2.9-10.9,5.8-16.4,8.8c-6.2,3.3-12.4,6.7-18.6,10c-1.7,0.9-3.4,1.5-5.5,1.5c-3.4-0.1-6.8,0.3-10.2,0.4 C271.7,257.7,271.5,257.4,271.4,257.2z"/>
<path onClick={() => props.onFill(24)} fill={props.fillColors[24]} d="M227,260.1c-3.8-0.8-7.4-1.4-10.9-2.5c-2.9-0.9-5.5-2.5-8.2-3.9c-5.5-2.9-10.9-5.8-16.4-8.8 c-6.9-3.7-13.8-7.5-20.8-11.1c-7.3-3.9-14.7-7.7-22-11.6c-7.4-3.8-14.9-7.7-22.4-11.4c-7.3-3.6-14.7-7.1-22-10.7 c-0.6-0.3-1.2-1-1.3-1.6c-0.4-4.7-0.9-9.4-0.7-14.1c0.1-5.4,2.1-10.2,5.8-14.2c4.4-4.9,10-7.7,16-10.2c3.4-1.4,5.1-0.6,7.4,1.8 c10.7,11.2,21.5,22.2,32.3,33.3c3.4,3.5,6.9,7.1,10.3,10.6c6.6,6.7,13.2,13.5,19.8,20.2c4.7,4.8,9.5,9.7,14.2,14.6 C214.2,246.9,220.5,253.4,227,260.1z"/>
<path onClick={() => props.onFill(25)} fill={props.fillColors[25]} d="M270,308.6c1.6,2.5,3.3,5.1,4.9,7.6c3.9,6,7.9,11.9,11.8,17.9c4.6,7.1,9.2,14.3,13.8,21.5 c4.9,7.6,9.9,15.2,14.8,22.9c5.9,9.1,11.7,18.2,17.6,27.3c5.8,9,11.7,17.9,17.6,26.8c4.7,7.2,9.3,14.4,13.9,21.6 c4.4,6.8,8.8,13.5,13.2,20.3c0.4,0.6,0.7,1.6,0.5,2.3c-1.3,4.6-2.2,9.4-5.1,13.5c-1.9,2.7-4.6,4.2-7.6,4.7 c-4.2,0.7-8.6,0.9-12.9,1.3c-0.9,0.1-1.4-0.2-1.8-1.1c-2.6-6.1-5.3-12.2-8-18.2c-3.2-7.2-6.5-14.3-9.7-21.5 c-2.9-6.4-5.7-12.9-8.5-19.3c-3.2-7.3-6.5-14.5-9.8-21.8c-2.9-6.4-5.6-12.9-8.5-19.4c-3.2-7.3-6.5-14.5-9.8-21.8 c-2.9-6.4-5.6-12.9-8.5-19.4c-3.2-7.3-6.5-14.5-9.8-21.8c-2.7-6.1-5.4-12.2-8-18.4c-0.6-1.4-0.5-3.2-0.7-4.8 C269.6,308.8,269.8,308.7,270,308.6z"/>
<path onClick={() => props.onFill(26)} fill={props.fillColors[26]} d="M229.8,308.4c0.9,3.2-0.4,5.6-1.5,8c-2.5,5.7-5.2,11.4-7.7,17.1c-3.3,7.3-6.6,14.7-9.8,22 c-2.9,6.5-5.6,12.9-8.5,19.4c-3.2,7.2-6.5,14.4-9.7,21.6c-2.1,4.7-4.1,9.5-6.2,14.3c-2.4,5.5-4.9,10.9-7.3,16.4 c-2.2,4.9-4.3,9.7-6.4,14.6c-2.4,5.4-4.8,10.8-7.2,16.2c-2.2,4.9-4.3,9.8-6.5,14.7c-1.9,4.4-3.9,8.8-5.9,13.2c-1.4,3-2.8,6.1-4,9.1 c-0.4,1-0.9,1.3-1.9,1.2c-4.4-0.4-8.9-0.4-13.3-1.3c-4.2-0.9-7.4-3.5-8.9-7.8c-1.1-3.2-2.2-6.4-3.2-9.6c-0.7-1.9,0.6-3.3,1.5-4.7 c2.9-4.6,5.9-9.2,8.9-13.8c4.2-6.5,8.4-13,12.6-19.4c4.5-6.9,9.1-13.8,13.6-20.7c5.6-8.5,11.1-17.1,16.7-25.7c4-6.2,8-12.5,12-18.7 c4.9-7.6,9.9-15.2,14.8-22.8c5.1-7.8,10.2-15.6,15.3-23.4c4.1-6.3,8.1-12.7,12.2-19C229.2,309.1,229.4,308.8,229.8,308.4z"/>
<path onClick={() => props.onFill(27)} fill={props.fillColors[27]} d="M299.4,613.9c-16.5,0-32.7,0-49.2,0c0.2-30.5,0.5-61,0.7-91.8c11.4,3.7,22.7,4.8,33.3-1.9 C289.3,551.6,294.3,582.6,299.4,613.9z"/>
<path onClick={() => props.onFill(28)} fill={props.fillColors[28]} d="M212.9,519.3c-4.3,31.5-8.6,63-12.9,94.5c-14.5,0-28.7,0-43.1,0c1-3.6,1.9-7,2.8-10.5c0.9-3.5,1.8-6.9,2.7-10.4 c1.1-4.3,2.3-8.6,3.4-12.9c1.9-7.2,3.8-14.5,5.7-21.7c1.6-6.2,3.2-12.3,4.8-18.5c1.5-5.9,3.1-11.9,4.6-17.8c0.7-2.6,1.4-5.2,2.3-7.8 c0.1-0.5,0.7-1.1,1.1-1.1c7.5-1.1,14.6,0,21.4,3.2C208.1,517.4,210.4,518.3,212.9,519.3z"/>
<path onClick={() => props.onFill(29)} fill={props.fillColors[29]} d="M286.6,519.3c3.5-1.5,6.7-3,10.1-4.1c2.9-1,6-1.7,9-2c3.1-0.3,6.2-0.1,9.3,0c0.4,0,1.2,0.5,1.3,1 c1.7,6.3,3.3,12.6,5,18.8c2,7.2,4.3,14.3,6.3,21.5c1.8,6.3,3.3,12.7,5.1,19c1.3,4.6,2.8,9.1,4.1,13.7c1.4,4.9,2.6,9.8,3.9,14.7 c1.1,4,2.3,7.9,3.4,12c-14.1,0-28,0-42.2,0C296.9,582.4,291.8,550.8,286.6,519.3z"/>
<path onClick={() => props.onFill(30)} fill={props.fillColors[30]} d="M248,522.9c0,30.2,0,60.5,0,90.9c-15.2,0-30.2,0-45.5,0c4.2-31.1,8.5-62.1,12.8-93.3c5.1,2.3,10.3,4.9,16,4.5 C237,524.8,242.5,523.7,248,522.9z"/>
<path onClick={() => props.onFill(31)} fill={props.fillColors[31]} d="M232.3,293.8c-0.4,1.8-1.1,3.6-1,5.4c0.1,2.8-1.7,4.7-3,6.7c-2.8,4.8-6,9.3-9,14c-3.4,5.2-6.8,10.4-10.2,15.6 c-4.8,7.4-9.6,14.8-14.4,22.2c-4.9,7.6-9.9,15.2-14.8,22.8c-4.2,6.5-8.4,13-12.6,19.4c-4.9,7.6-9.9,15.2-14.8,22.9 c-4,6.2-7.9,12.5-11.9,18.7c-5.1,7.9-10.3,15.9-15.5,23.8c-1.1,1.7-2.3,3.5-3.7,5.2c0.5-13.7-3.6-25.1-15.7-32.7 c2-2.3,3.6-4.3,5.3-6.2c4.3-4.9,8.6-9.8,12.8-14.8c2.1-2.4,4.3-4.8,6.4-7.2c4.4-5,8.8-10,13.2-15c3.2-3.6,6.5-7.2,9.7-10.9 c4-4.5,7.8-9.1,11.8-13.7c2.9-3.3,5.8-6.6,8.7-9.9c4.5-5.1,9.1-10.3,13.6-15.4c3.2-3.6,6.3-7.2,9.5-10.8c4.2-4.9,8.5-9.8,12.7-14.6 c3-3.4,6-6.8,9-10.2c4.1-4.6,8.1-9.3,12.1-14c0.5-0.6,1-1.1,1.5-1.7C232,293.7,232.2,293.8,232.3,293.8z"/>
<path onClick={() => props.onFill(32)} fill={props.fillColors[32]} d="M394,437.8c-12.2,7.8-16.5,19.2-15.9,33c-1.4-2-2.7-4.1-4.1-6.1c-3.6-5.6-7.3-11.3-10.9-16.9 c-4.8-7.5-9.6-14.9-14.4-22.4c-5.8-9-11.7-18.1-17.5-27.1c-4.6-7.1-9.4-14.2-14-21.4c-4.5-6.9-8.9-13.9-13.4-20.9 c-4.5-7-9.1-13.9-13.7-20.9c-4.6-7.1-9.2-14.3-13.8-21.5c-1.9-3-3.7-6.1-5.8-8.9c-2.1-2.7-2.3-5.8-2.8-8.9c-0.1-0.7-0.2-1.3-0.1-2.2 c0.5,0.6,1,1.3,1.6,1.9c1.9,2.2,3.9,4.4,5.9,6.6c4.2,4.8,8.3,9.6,12.5,14.3c3.3,3.7,6.6,7.4,9.9,11.2c4.3,4.9,8.6,9.9,12.9,14.8 c3,3.4,6.1,6.8,9.1,10.3c5.2,5.9,10.4,11.9,15.6,17.9c3.1,3.5,6.2,6.9,9.3,10.4c4.1,4.7,8.2,9.5,12.3,14.2c2.9,3.3,5.8,6.6,8.7,9.9 c4.3,5,8.7,9.9,13,14.9c3,3.4,6,6.8,9,10.2C389.5,432.7,391.6,435.1,394,437.8z"/>
<path onClick={() => props.onFill(33)} fill={props.fillColors[33]} d="M458.8,497.1c-4.5-4.4-8.8-8.4-13-12.5c-4.9-4.7-9.7-9.5-14.6-14.2c-4.2-4.1-8.4-8.2-12.7-12.3 c-6.3-6.1-12.5-12.3-19.1-18.2c-2.4-2.2-3-4.6-3.1-7.4c-0.1-3.8-0.2-7.6,0-11.4c0.5-7.6,3.1-14.4,8.3-20.1c2-2.3,2.1-2.2,4.6-0.5 c3.7,2.5,7.5,5,11.2,7.4c6.7,4.4,13.4,8.8,20.1,13.2c5.7,3.7,11.3,7.5,17,11.1c1.1,0.7,1.2,1.4,1.2,2.5c0,10.8,0,21.6,0,32.4 c0,9.3,0,18.5,0,27.8C458.8,495.5,458.8,496.1,458.8,497.1z"/>
<path onClick={() => props.onFill(34)} fill={props.fillColors[34]} d="M41.6,497c0-0.9,0-1.4,0-2c0-20,0-40.1,0-60.1c0-1.5,0.5-2.2,1.7-3c4.2-2.7,8.4-5.6,12.5-8.3 c4.1-2.7,8.2-5.4,12.3-8.1c8-5.3,15.9-10.6,23.9-15.9c0.4-0.3,0.8-0.5,1.4-0.8c1.9,2.8,3.9,5.4,5.7,8.2c2.4,3.8,3.6,8,3.8,12.5 c0.2,3.1,0.4,6.3,0.4,9.4c0,2.5-0.2,5-0.4,7.5c0,0.5-0.4,1-0.8,1.4c-9.8,9.8-19.7,19.5-29.6,29.2c-6.3,6.2-12.6,12.4-19,18.6 C49.7,489.4,45.8,493,41.6,497z"/>
<path onClick={() => props.onFill(35)} fill={props.fillColors[35]} d="M227.8,324.8c-0.7,4-1.5,7.9-2.2,11.9c-0.6,3.4-1.1,6.8-1.7,10.1c-0.6,3.3-1.4,6.7-2.1,10 c-0.8,3.9-1.6,7.8-2.5,11.6c-1.3,5.6-2.7,11.2-4.1,16.8c-1.3,5-2.4,10-3.9,14.9c-1.5,5-3.3,10-5.2,14.8c-2.6,6.4-5.2,12.9-8.5,19.1 c-4.6,8.7-9.9,17.1-14.9,25.6c-1.6,2.7-1.4,3.5,1.7,4.2c2.5,0.6,5,1,7.4,1.6c1.2,0.3,2,0.7,1.5,2.4c-1.6,5.4-3,10.8-4.5,16.2 c-2.2,7.9-4.4,15.9-6.6,23.8c-0.1,0.4-0.1,0.9-0.2,1.6c-1.6-1.1-3.1-2.1-4.5-3.2c-4-3.2-8.4-5.8-13.2-7.4c-2.8-1-5.8-1.2-8.7-1.8 c-1.4-0.3-2.8-0.5-4.2-0.7c25.5-57.3,50.8-114.4,76.2-171.6C227.5,324.7,227.7,324.8,227.8,324.8z"/>
<path onClick={() => props.onFill(36)} fill={props.fillColors[36]} d="M317.8,509.7c-4.1-14.9-8-29.2-12-43.7c3.7-0.9,7-1.6,10.3-2.6c2.2-0.6,2.3-1.2,1-3.2c-3.1-5-6.4-9.8-9.1-15 c-4.3-7.9-8.3-16-12.2-24.2c-3-6.2-5.1-12.7-6.9-19.3c-1.1-4.2-2.8-8.2-3.9-12.3c-1.1-4.2-1.9-8.6-2.8-12.9 c-0.8-3.8-1.7-7.6-2.5-11.4c-0.7-3.2-1.4-6.3-2.1-9.5c-0.7-3.3-1.6-6.5-2.2-9.8c-0.9-5-1.6-10-2.4-14.9c-0.3-2.1-0.8-4.1-1.3-6.2 c0.1,0,0.3-0.1,0.4-0.1c25.4,57.1,50.7,114.3,76.3,171.9C336.5,496.8,326.7,501.8,317.8,509.7z"/>
<path onClick={() => props.onFill(37)} fill={props.fillColors[37]} d="M230.6,291.6c-3.8,4.3-7.6,8.7-11.4,13c-4.2,4.9-8.5,9.8-12.7,14.6c-3.2,3.7-6.6,7.3-9.8,11 c-4,4.5-7.9,9.1-11.8,13.7c-3.2,3.7-6.6,7.3-9.8,11c-4.2,4.8-8.4,9.7-12.6,14.5c-3.1,3.5-6.2,6.9-9.3,10.4 c-4.3,4.9-8.6,9.8-12.9,14.7c-3,3.4-6,6.8-9,10.2c-4.1,4.7-8.2,9.5-12.3,14.2c-1.9,2.2-3.9,4.4-5.8,6.6c-2.1,2.4-4.3,4.8-6.4,7.2 c-0.3,0.3-0.6,0.6-1.2,1.1c0-4.7,0.1-9.1,0-13.4c-0.1-6.5-2.3-12.4-6-17.7c-1.3-1.9-2.8-3.7-4.2-5.6c4.9-3.8,9.7-7.6,14.6-11.3 c6-4.6,11.9-9.2,18-13.8c4.5-3.4,9.1-6.8,13.6-10.2c5.5-4.1,11-8.2,16.3-12.5c6.1-4.9,12.1-10,18.1-15c5.7-4.8,11.4-9.6,17.1-14.4 c6.5-5.5,12.9-10.9,19.4-16.4c4.1-3.5,8.1-7.1,12.4-10.4c1.6-1.2,3.8-1.5,5.7-2.3C230.4,291.1,230.5,291.4,230.6,291.6z"/>
<path onClick={() => props.onFill(38)} fill={props.fillColors[38]} d="M268.7,291.3c2.7,0.2,4.8,0.5,6.7,2.1c6.2,5.4,12.4,10.8,18.7,16.1c6.1,5.2,12.3,10.2,18.4,15.4 c7,5.9,13.8,12,20.9,17.8c7.1,5.7,14.4,11.2,21.6,16.7c8.6,6.6,17.3,13.3,26,19.9c7.8,6,15.6,11.9,23.2,17.7 c-2.3,3.5-4.6,6.9-6.7,10.4c-2.2,3.6-3.1,7.7-3.3,11.9c-0.2,4.7,0,9.5,0,14.7c-2.1-2.2-3.8-4.1-5.5-6c-2.2-2.5-4.3-5.1-6.5-7.6 c-4.3-5-8.7-10-13-14.9c-4-4.6-8.1-9.1-12.1-13.7c-4.1-4.7-8.1-9.4-12.2-14.1c-3.2-3.7-6.5-7.2-9.7-10.9c-4.4-4.9-8.7-9.9-13-14.9 c-3-3.4-6-6.8-9-10.2c-4.2-4.8-8.3-9.6-12.4-14.3c-2.9-3.4-5.9-6.7-8.9-10.1c-4.4-5-8.8-10.1-13.3-15.2 C275.3,298.7,272.1,295.2,268.7,291.3z"/>
<path onClick={() => props.onFill(39)} fill={props.fillColors[39]} d="M163.9,40.7c16,0,31.8,0,47.9,0c4,24.1,8.1,48.1,12.2,72.5c-11.2-2.3-20.4,1.7-29,8 C184.5,94.3,174.2,67.6,163.9,40.7z"/>
<path onClick={() => props.onFill(40)} fill={props.fillColors[40]} d="M287.8,40.7c16.2,0,31.9,0,47.9,0c-10.1,26.6-20.2,53.1-30.3,79.6c-8.3-3.9-6-6.3-28.9-7.5 C280.2,88.8,284,64.8,287.8,40.7z"/>
<path onClick={() => props.onFill(41)} fill={props.fillColors[41]} d="M131,158.1c13.5-2.3,20-11.6,24.5-23.4c3.8,5.6,7.3,10.9,11,16.3c6.1,9,12.3,17.9,18.5,26.9 c5.4,7.9,10.7,15.9,16.1,23.8c4.9,7.1,9.8,14.3,14.6,21.4c3.3,4.8,6.5,9.6,9.7,14.4c0.6,1,0.8,2.3,1,3.5c0.5,3.1,0.9,6.3,1.4,9.4 c0.3,2,0.7,3.9,1,5.9c0.1,0.5,0.1,1.1,0.2,2c-0.8-0.7-1.2-1.1-1.6-1.6c-9.6-9.7-19.2-19.5-28.8-29.2c-10.5-10.7-21-21.5-31.5-32.2 c-5.7-5.9-11.5-11.7-17.3-17.7C143.5,171.2,137.4,164.7,131,158.1z"/>
<path onClick={() => props.onFill(42)} fill={props.fillColors[42]} d="M267.3,256c-4.8-4.8-10.6-7-16.7-8.6c0-0.9,0-1.7,0-2.5c0-24,0.1-48.1-0.1-72.1c0-2.9,1-4.2,3.3-5.3 c4.8-2.2,9.5-4.8,14.4-6.7c3.7-1.4,7.7-2.2,11.6-3.1c7.7-1.7,15.1,0,22.4,2.1c0.5,0.1,0.9,0.4,1.5,0.7c-2.4,3.1-4.9,6.1-7.2,9.2 c-2.6,3.5-5,7.1-7.4,10.8c-3.7,5.7-6.5,11.9-8.9,18.3c-1.6,4.3-2.8,8.9-3.9,13.4c-1.6,6.7-3.1,13.5-4.5,20.3 c-0.9,4.4-1.6,8.8-2.4,13.2C268.9,249,268.1,252.4,267.3,256z"/>
<path onClick={() => props.onFill(43)} fill={props.fillColors[43]} d="M231.5,258.3c-0.2-1.7-0.4-3-0.6-4.4c-0.4-3.1-0.8-6.2-1.3-9.3c-0.5-3.1-1-6.3-1.6-9.4 c-0.6-3.2-1.2-6.3-1.9-9.5c-1.3-5.7-2.5-11.5-4.1-17.2c-1.3-4.8-2.9-9.5-4.7-14.1c-1.4-3.7-3.2-7.3-5.2-10.6c-3-5.1-6.3-10-9.6-14.8 c-2.1-3-4.5-5.7-6.9-8.8c2.6-0.8,5.1-1.7,7.5-2.2c3-0.6,6.1-1.3,9.1-1.2c8.8,0.2,17.2,2.4,25.1,6.4c3.1,1.6,6.1,3.4,9.2,4.9 c1.2,0.6,1.4,1.4,1.4,2.5c0,14.1,0,28.3,0,42.4c0,10.8,0,21.6,0,32.4c0,1.5-0.6,2.2-1.8,2.5C240.2,249.8,235.9,252.2,231.5,258.3z" />
<path onClick={() => props.onFill(44)} fill={props.fillColors[44]} d="M277.7,292c3.5,0.3,6.9,0.1,10.1,1c2.6,0.7,4.9,2.5,7.4,3.9c6.5,3.7,13,7.4,19.4,11.1 c5.6,3.2,11.2,6.4,16.8,9.6c9.8,5.5,19.6,11.2,29.5,16.5c9.3,5,18.7,9.8,28.1,14.7c4.6,2.4,9.3,4.7,13.9,7c1.5,0.7,1.8,1.4,1.1,3 c-1.5,3.6-3.1,7.2-3.8,11c-0.6,3.6-0.5,7.5,0,11.2c0.6,4.1,2,8,2.9,11.9C359.8,361.3,318.1,327.7,277.7,292z"/>
<path onClick={() => props.onFill(45)} fill={props.fillColors[45]} d="M96.2,393c5.1-12.4,5.5-24.5-1.5-36.2c6.8-3.5,13.3-6.8,19.8-10.2c5.1-2.6,10.1-5.3,15.2-7.9 c5-2.6,10.1-5.2,15.1-8c5.9-3.2,11.7-6.6,17.5-9.9c7.7-4.3,15.3-8.7,23-13.1c6.5-3.7,12.9-7.3,19.4-11c2.2-1.3,4.4-2.6,6.6-3.8 c0.7-0.4,1.6-0.5,2.3-0.5c2.7-0.1,5.5,0,7.9,0C181.5,327.6,139.7,361.1,96.2,393z"/>
<path onClick={() => props.onFill(46)} fill={props.fillColors[46]} d="M270.3,254.8c1-5.1,1.8-10.3,3.2-15.4c0.6-2.2,2.2-4.3,3.5-6.3c3.1-4.6,6.3-9.1,9.4-13.7 c4.2-6.1,8.5-12.3,12.7-18.4c4.8-7.2,9.5-14.4,14.4-21.6c5.4-8,11-15.9,16.5-23.8c4.2-6.2,8.3-12.4,12.5-18.6c0.5-0.7,1-1.4,1.6-2.2 c4.5,11.1,10.3,20.9,23.3,22.8C335.1,190,302.7,222.3,270.3,254.8z"/>
<path onClick={() => props.onFill(47)} fill={props.fillColors[47]} d="M458.7,232.6c-16.3,2.6-32.5,5.2-48.4,7.7c0.4-5.8,0.9-11.5,1.3-17.2c0.3-5.4-1.9-10-4.9-14.2 c-2-2.8-4.4-5.3-6.7-8.1c19.5-8.5,39-17.1,58.7-25.6C458.7,194.3,458.7,213.4,458.7,232.6z"/>
<path onClick={() => props.onFill(48)} fill={props.fillColors[48]} d="M89.6,240.3c-16.2-2.6-32-5.2-47.9-7.7c0-19.2,0-38.2,0-57.5c19.4,8.6,38.7,17.1,57.8,25.6 c-2.7,3.5-5.5,6.9-8.1,10.5c-2.1,2.9-3.3,6.3-3.3,9.9c0,4.1,0.1,8.2,0.4,12.3c0.1,1.5,0.6,3,0.9,4.6 C89.5,238.7,89.5,239.3,89.6,240.3z"/>
<path onClick={() => props.onFill(49)} fill={props.fillColors[49]} d="M458.8,430c-3.2-2.1-6.2-4-9.1-5.9c-8.7-5.7-17.4-11.3-26.1-17c-5.2-3.4-10.5-6.7-15.5-10.4 c-1.3-1-1.9-3.3-2.5-5.1c-1-3-1.9-6-2.6-9.1c-1.5-5.9-1.2-11.6,1-17.3c0.8-2.2,1.8-4.3,2.8-6.4c0.2-0.3,1.1-0.7,1.5-0.5 c4.8,2.1,9.5,4.3,14.2,6.4c4.7,2.1,9.5,4.2,14.3,6.4c4.8,2.1,9.5,4.2,14.3,6.3c2.3,1,4.5,2,6.7,3.1c0.5,0.3,1,1.1,1,1.7 C458.8,398,458.8,413.8,458.8,430z"/>
<path onClick={() => props.onFill(50)} fill={props.fillColors[50]} d="M41.6,429.9c0-16.1,0-32,0-47.8c0-1,0.4-1.4,1.2-1.8c5.8-2.6,11.5-5.3,17.2-7.9c6.9-3.1,13.8-6.2,20.6-9.3 c3.6-1.6,7.2-3.4,10.8-5c0.4-0.2,1.3,0.2,1.4,0.5c2.6,5.7,5.2,11.4,4.6,17.9c-0.4,3.9-1.5,7.7-2.4,11.4c-0.7,2.6-1.6,5.1-2.5,7.6 c-0.2,0.5-0.6,0.9-1.1,1.2c-6.3,4.2-12.7,8.4-19,12.6c-6.4,4.2-12.7,8.5-19.1,12.8c-3.5,2.3-6.9,4.6-10.4,7 C42.6,429.4,42.2,429.6,41.6,429.9z"/>
<path onClick={() => props.onFill(51)} fill={props.fillColors[51]} d="M135.8,235.4c3.7,1.8,7.3,3.6,11,5.3c7.3,3.4,14.6,6.8,22.5,8.8c6.9,1.8,13.7,3.9,20.6,5.5 c7.9,1.8,16,3.1,23.9,4.8c4.5,0.9,8.9,2.1,13.4,3.2c0.6,0.1,1.2,0.3,1.5,0.3c0,4.3,0,8.4,0,12.8c-0.5,0-1.1,0.1-1.6,0.1 c-1.8,0-3.7,0-5.5,0c-15.4-0.1-30.8-0.2-46.2-0.4c-6.3-0.1-12.6-0.2-19-0.4c-4.5-0.1-9-0.3-13.4-0.3c-1.3,0-1.7-0.8-2.1-1.7 c-1.9-3.6-3.9-7.2-5.4-11c-1.2-3.1-1.8-6.4-2.5-9.6c-1-4.2,0.4-8.1,1.2-12.2C134.5,238.8,135.2,237.1,135.8,235.4z"/>
<path onClick={() => props.onFill(52)} fill={props.fillColors[52]} d="M270.7,276.1c0.4-5.1,1-10.1-0.7-15.3c10.9-0.9,21.8-1.1,32.5-3.3c10.6-2.1,21.2-4.7,31.2-8.8 c10-4.1,19.9-8.6,30.1-13.1c0.6,2,1.6,4.2,2,6.5c0.5,3.3,0.8,6.6,0.9,10c0,4.6-1.8,8.7-3.7,12.8c-1.3,2.9-2.8,5.8-4.3,8.6 c-0.5,1-1.1,1.7-2.4,1.6c-1.3-0.1-2.5,0-3.8,0c-8.5,0.1-17.1,0.3-25.6,0.4c-6.5,0.1-13,0.2-19.5,0.3c-11.4,0.1-22.8,0.3-34.2,0.4 C272.4,276.1,271.7,276.1,270.7,276.1z"/>
<path onClick={() => props.onFill(53)} fill={props.fillColors[53]} d="M285.1,40.8c-3.8,24.3-7.6,48.6-11.4,72.6c-4.1,1.2-8,2.2-11.8,3.5c-3.8,1.3-7.4,3-11.2,4.6c0-27,0-53.8,0-80.7 C262.1,40.8,273.5,40.8,285.1,40.8z"/>
<path onClick={() => props.onFill(54)} fill={props.fillColors[54]} d="M214.4,40.8c11.2,0,22.2,0,33.4,0c0,26.9,0,53.7,0,80.4c-4.9-2-9.9-3.9-14.9-5.9c-1.5-0.6-3-1.3-4.6-1.4 c-1.8-0.1-1.9-1.2-2.1-2.3c-1.1-5.7-2.1-11.5-3-17.2c-1-6-1.7-12.1-2.7-18.1c-0.9-5.3-2-10.5-2.9-15.8c-0.6-3.7-1.1-7.4-1.6-11.1 C215.5,46.6,214.9,43.8,214.4,40.8z"/>
<path onClick={() => props.onFill(55)} fill={props.fillColors[55]} d="M365,322.4c-6.2-4-11.9-8-17.8-11.5c-6.1-3.6-12.3-7-18.6-10c-5.3-2.5-10.9-4.7-16.5-6.4 c-6.5-2-13.2-3.6-20-3.9c-6.3-0.3-12.5-0.7-18.8-1.2c-2-0.2-3.9-0.8-6-1.2c1-3.2,2-6.2,3-9.4c0.8,0,1.8,0,2.7,0 c11.1-0.1,22.3-0.2,33.4-0.4c10.1-0.1,20.1-0.2,30.2-0.4c6.5-0.1,13-0.2,19.5-0.4c1.1,0,1.6,0.3,2,1.4c2,6.2,4.3,12.4,6.1,18.7 c1.6,5.6,3,11.4,2.5,17.3C366.4,317.4,365.6,319.7,365,322.4z"/>
<path onClick={() => props.onFill(56)} fill={props.fillColors[56]} d="M134.7,322.3c-0.6-2.3-1.5-4.4-1.6-6.5c-0.2-2.7-0.1-5.5,0.4-8.1c0.8-4.4,1.8-8.7,3-13 c1.1-3.9,2.4-7.6,3.7-11.5c0.5-1.4,1.3-2.7,1.5-4.2c0.2-1.3,0.9-1.4,1.9-1.4c15.7,0.1,31.4,0.2,47.1,0.4c6.3,0.1,12.5,0.3,18.8,0.4 c6.6,0.1,13.3,0,19.9,0c1.1,3.5,2,6.5,3.1,9.8c-2,0.4-4,1.1-6,1.2c-4.7,0.3-9.3,0.2-14,0.5c-4,0.3-8.1,0.8-12.1,1.5 c-3.8,0.7-7.6,1.6-11.4,2.6c-8.3,2.4-16.3,5.6-24,9.7c-2.8,1.5-5.7,2.8-8.3,4.5c-6.5,4.1-12.9,8.3-19.3,12.4 C136.6,321.1,135.8,321.6,134.7,322.3z"/>
<path onClick={() => props.onFill(57)} fill={props.fillColors[57]} d="M458.7,325.1c0,17.5,0,35.1,0,53c-16.7-7.5-33.4-14.9-50.2-22.4c5-5.2,9.7-10.4,12.6-16.9 c2.9-6.6,2.2-13.7,2.3-20.6c6,1.1,11.8,2.2,17.7,3.3C446.9,322.6,452.7,323.8,458.7,325.1z"/>
<path onClick={() => props.onFill(58)} fill={props.fillColors[58]} d="M41.7,378.1c0-17.9,0-35.5,0-53.1c11.5-2.3,22.9-4.6,34.5-7c0.2,7-0.5,14,2.3,20.7c2.8,6.6,7.7,11.6,12.6,16.9 C74.6,363.1,58.3,370.5,41.7,378.1z"/>
<path onClick={() => props.onFill(59)} fill={props.fillColors[59]} d="M368.2,304.1c2.5,0.6,4.9,1.1,7.3,1.7c5.5,1.4,10.9,2.7,16.4,4.1c3.9,1,7.8,2.1,11.7,3.1 c5.1,1.3,10.2,2.6,15.3,3.9c1.2,0.3,1.9,0.8,1.8,2.3c-0.2,4.3,0.1,8.8-0.5,13c-0.4,2.9-1.7,5.8-3.1,8.5c-2.7,5.3-6.7,9.6-11,13.8 c-15.1-7.7-30-15.4-45.2-23.2c3.1-3.6,6.9-6.8,7.6-12.2C369.1,314.2,369.1,309.4,368.2,304.1z"/>
<path onClick={() => props.onFill(60)} fill={props.fillColors[60]} d="M136.9,332.2c-14.6,7.5-28.9,14.9-43.4,22.3c-5.3-5.4-10.5-10.9-13.1-18.4c-2-5.8-1.9-11.7-1.7-17.7 c0-0.5,1.1-1.2,1.8-1.4c5.3-1.4,10.7-2.6,16-4c6.6-1.7,13.2-3.4,19.8-5.1c4.6-1.2,9.1-2.4,13.7-3.6c0.5-0.1,1,0,1.6,0 C128.6,314.6,131.5,323.6,136.9,332.2z"/>
<path onClick={() => props.onFill(61)} fill={props.fillColors[61]} d="M250.9,456.9c1.5,0.2,2.9,0.3,4.2,0.6c3.9,1,7.8,2,11.6,3.3c2.9,1,5.9,2,8.5,3.5c0.9,0.5,1,2.5,1.2,3.9 c0.8,4.8,1.6,9.7,2.2,14.6c0.6,4.1,1,8.3,1.6,12.4c0.5,3.3,1.1,6.5,1.7,9.7c0.5,3.1,0.7,6.2,1.4,9.2c0.7,3-0.3,4.1-3.1,5.2 c-5.6,2.4-11.3,4-17.4,2.7c-3.6-0.7-7.3-1.5-10.9-2.4c-0.5-0.1-1-1.1-1-1.8c-0.1-4,0-8,0-12c0-15.6,0-31.2,0-46.8 C250.9,458.5,250.9,457.8,250.9,456.9z"/>
<path onClick={() => props.onFill(62)} fill={props.fillColors[62]} d="M248.3,456.9c0,0.8,0,1.4,0,1.9c0,19.7,0,39.3,0,59c0,1.4-0.4,2-1.7,2.3c-3.3,0.6-6.7,1.4-10,2 c-4.8,0.9-9.5,0.4-14-1.4c-1.7-0.7-3.5-1.3-5.2-2.1c-1-0.4-1.7-1.1-1.5-2.4c0.8-4.4,1.5-8.8,2.2-13.2c0.6-3.9,1.1-7.8,1.7-11.7 c0.5-3.5,1.1-7,1.6-10.5c0.5-3.2,1-6.4,1.4-9.6c0.3-1.8,0.5-3.7,0.8-5.5c0.4-2.1,2.5-2,3.8-2.5c4-1.6,8-3.1,12.1-4.4 C242.4,457.9,245.2,457.5,248.3,456.9z"/>
<path onClick={() => props.onFill(63)} fill={props.fillColors[63]} d="M458.6,322.4c-5.1-1.1-10.2-2.2-15.3-3.2c-6.3-1.2-12.6-2.3-18.8-3.6c-0.6-0.1-1.4-1.1-1.5-1.7 c-0.6-3.6-1-7.1-1.6-10.7c-1.2-7.6-4.5-14.2-8.8-20.5c-1.3-1.8-2.5-3.7-3.8-5.6c0.3-0.1,0.5-0.2,0.7-0.2c7.1-0.1,14.3-0.3,21.4-0.4 c8.5-0.1,16.9-0.3,25.4-0.4c0.7,0,1.4,0,2.2,0C458.6,291.6,458.6,307,458.6,322.4z"/>
<path onClick={() => props.onFill(64)} fill={props.fillColors[64]} d="M90.7,276.9c-1.1,1.6-2,3.2-3,4.6c-4.8,6.6-8.4,13.9-9.9,22c-0.6,3.2-0.9,6.6-1.2,9.8c-0.1,1.4-0.5,2.2-2.2,2.4 c-3.2,0.4-6.3,1.3-9.5,1.9c-4.4,0.9-8.9,1.7-13.3,2.6c-3.3,0.7-6.6,1.4-10,2.1c0-15.4,0-30.8,0-46.3C58,276.3,74.2,276.6,90.7,276.9 z"/>
<path onClick={() => props.onFill(65)} fill={props.fillColors[65]} d="M408.6,273.8c1.1-2.9,2.6-6.2,3.7-9.6c1.6-5.2,1.1-10.4-0.7-15.5c-0.5-1.4-0.9-2.8-1.4-4.2 c-0.5-1.3,0.2-1.7,1.2-1.9c7.2-1.2,14.4-2.3,21.6-3.4c7.2-1.1,14.4-2.3,21.6-3.4c1.3-0.2,2.6-0.2,4-0.3c0,12.6,0,25.2,0,38 c-1.9,0.1-3.7,0.3-5.6,0.3c-9.3,0-18.5,0-27.8,0C419.6,273.8,414,273.8,408.6,273.8z"/>
<path onClick={() => props.onFill(66)} fill={props.fillColors[66]} d="M41.7,273.6c0-13,0-25.6,0-38.4c16,2.6,31.9,5.1,48.3,7.8c-5.2,10.7-5,20.9,1.6,31.2 C74.6,274,58.2,273.8,41.7,273.6z"/>
<path onClick={() => props.onFill(67)} fill={props.fillColors[67]} d="M249.3,305.4c-2.1-1.3-4.4-2.6-6.4-4.1c-2.9-2.3-4.7-5.5-5.9-8.9c-1.7-4.6-3.4-9.3-4.7-14 c-1.2-4.1-2-8.5-0.9-12.7c1.4-5.6,4.5-10.2,9.9-12.8c1.2-0.6,2.5-1.1,3.7-1.7c2.8-1.4,5.6-1.4,8.5-0.3c3.6,1.4,7.1,2.8,10.2,5.2 c2.1,1.6,3.3,3.7,4,6.1c1.5,5.1,1.2,10.3,0,15.4c-1.5,6.5-3.8,12.7-6.8,18.7c-2,3.9-5.5,6.1-9.3,8 C250.9,304.6,250.1,305,249.3,305.4z"/>
<path onClick={() => props.onFill(68)} fill={props.fillColors[68]} d="M78.9,314.8c0.6-4.1,0.8-7.9,1.6-11.5c1.4-6,3.6-11.8,7.1-17c1.9-2.8,3.8-5.7,5.8-8.4c0.4-0.5,1.3-1,1.9-1 c6.4-0.1,12.8-0.1,19.2,0c6.6,0.1,13.3,0.2,19.9,0.4c1.6,0,3.1,0.3,4.8,0.5c-1.2,3.3-2.4,6.5-3.5,9.9c-1.1,3.6-2.3,7.2-3.1,10.9 c-0.4,2-1.3,3-3.2,3.5c-3.6,0.9-7.2,1.7-10.9,2.7c-6,1.5-12,3.1-18,4.6c-6.1,1.5-12.1,3.1-18.2,4.6 C81.3,314.1,80.2,314.4,78.9,314.8z"/>
<path onClick={() => props.onFill(69)} fill={props.fillColors[69]} d="M420.9,314.8c-2.4-0.6-4.3-1-6.3-1.5c-5.9-1.5-11.9-2.9-17.8-4.4c-5.1-1.3-10.1-2.8-15.2-4.1 c-3.7-1-7.4-2-11.2-2.7c-2.8-0.5-3.1-2.5-3.7-4.6c-1.5-5.2-3.3-10.4-4.9-15.6c-0.4-1.3-0.9-2.7-1.4-4.1c1.6-0.2,3.3-0.5,4.9-0.5 c11.6-0.2,23.3-0.3,34.9-0.4c0.8,0,1.6,0.1,2.3,0c2.6-0.6,4,0.8,5.2,2.8c2.2,3.5,4.6,6.8,6.5,10.4c2,3.5,3.5,7.3,4.3,11.4 C419.2,305.7,420,310,420.9,314.8z"/>
<path onClick={() => props.onFill(70)} fill={props.fillColors[70]} d="M141,221.1c-5.8,8.1-10,16.9-10.8,27.1c-5.4-1.1-10.7-2.2-16-3.2c-6.5-1.2-12.9-2.4-19.4-3.5 c-2-0.3-2.6-1.7-2.8-3.2c-0.6-4.9-1.4-9.8-1.5-14.7c-0.1-4.1,1.1-8,3.5-11.4c2.3-3.1,4.8-6.1,7.2-9.1c0.6-0.8,1.3-1,2.3-0.5 c2.7,1.4,5.5,2.7,8.3,4c4.2,2.1,8.5,4.2,12.7,6.3c4.3,2.1,8.5,4.2,12.8,6.3C138.3,219.7,139.5,220.4,141,221.1z"/>
<path onClick={() => props.onFill(71)} fill={props.fillColors[71]} d="M397.4,201.9c3.9,4.2,7.9,8.4,10.1,13.8c1.6,4,2.3,8.3,1.4,12.7c-0.5,2.6-1.1,5.3-1.1,8c0,3.9-2.2,5-5.6,5.5 c-7,1.2-13.9,2.8-20.8,4.1c-4,0.8-8,1.4-12.2,2.1c-0.7-9.9-4.9-18.6-10.6-27.2C371.7,214.5,384.5,208.2,397.4,201.9z"/>
<path onClick={() => props.onFill(72)} fill={props.fillColors[72]} d="M315.2,510.2c-10.5-1.4-19.9,1.9-29,6.5c-2.6-17.1-5.1-34-7.6-50.5c4.9,0.5,9.6,1.2,14.3,1.4 c2.9,0.1,5.9-0.2,8.8-0.8c1.5-0.3,1.9,0.2,2.2,1.2c1.7,6.3,3.3,12.6,5,18.9c1.3,4.7,2.7,9.4,4,14.1C313.7,504,314.4,507,315.2,510.2 z"/>
<path onClick={() => props.onFill(73)} fill={props.fillColors[73]} d="M213.3,516.8c-9.1-4.8-18.4-7.8-28.9-6.7c0.4-1.7,0.7-3.4,1.2-5.1c1-3.8,2.2-7.5,3.2-11.2 c1.9-6.8,3.8-13.6,5.6-20.4c0.5-1.9,0.9-3.8,1.4-5.8c0.2-0.9,0.6-1.3,1.7-1.1c6.2,1.1,12.5,1.7,18.8,0c1.4-0.4,2.9-0.4,4.7-0.7 C218.4,482.8,215.9,499.7,213.3,516.8z"/>
<path onClick={() => props.onFill(74)} fill={props.fillColors[74]} d="M130.2,250.8c0.2,8.8,4.7,16.2,8.6,24c-4,0-7.7,0-11.5,0c-0.7,0-1.4-0.2-2-0.2c-7.7,0-15.5,0-23.2,0 c-2.3,0-4.7-0.1-7-0.4c-0.6-0.1-1.3-0.9-1.6-1.6c-1.5-3.4-3.1-6.7-4.1-10.3c-1.1-3.8-0.6-7.8,0.6-11.6c0.6-1.9,1.3-3.9,1.8-5.9 c0.2-1,0.6-1.3,1.6-1.1c3.5,0.7,7.1,1.3,10.6,2c7.1,1.4,14.1,2.7,21.2,4.1C126.8,250.1,128.4,250.4,130.2,250.8z"/>
<path onClick={() => props.onFill(75)} fill={props.fillColors[75]} d="M360.8,274.6c3.9-7.4,8.2-14.9,8.6-23.7c6-1.2,11.9-2.5,17.8-3.7c6-1.2,11.9-2.3,17.9-3.4 c1.3-0.2,2.2-0.1,2.7,1.6c1.2,3.9,2.7,7.8,2.8,11.9c0.1,2.2-0.1,4.4-0.8,6.5c-1.1,3.3-2.6,6.4-4.1,9.5c-0.3,0.5-1.2,1-1.9,1 c-7.9,0.2-15.8,0.3-23.7,0.4C373.8,274.6,367.3,274.6,360.8,274.6z"/>
<path onClick={() => props.onFill(76)} fill={props.fillColors[76]} d="M497.9,616.2c0,12.2,0,24.3,0,36.4c-12.2,0-24.4,0-36.8,0c0-3.9,0.3-7.7-0.1-11.5c-0.4-4.1,1-6.9,4-9.7 c4.9-4.6,9.3-9.7,14-14.5c0.3-0.4,1-0.6,1.5-0.6C486.1,616.2,491.9,616.2,497.9,616.2z"/>
<path onClick={() => props.onFill(77)} fill={props.fillColors[77]} d="M2.9,652.7c0-12.2,0-24.4,0-36.7c0.5,0,0.9-0.1,1.4-0.1c4.8,0,9.6,0,14.5,0c0.8,0,1.7,0.4,2.3,0.9 c5.6,5.5,11.1,11,16.5,16.5c0.6,0.6,1.3,1.6,1.3,2.4c0.1,5.6,0.1,11.2,0.1,16.9C26.6,652.7,14.9,652.7,2.9,652.7z"/>
<path onClick={() => props.onFill(78)} fill={props.fillColors[78]} d="M461.8,1.9c12.1,0,24,0,36,0c0,12.1,0,24.2,0,36.5c-5.9,0-11.7,0.1-17.5-0.1c-1,0-2-1.6-2.9-2.4 c-0.4-0.4-0.7-0.9-1-1.3c-4.6-4.7-9.2-9.4-13.7-14.2c-0.4-0.4-0.8-1-0.8-1.6C461.8,13.2,461.8,7.7,461.8,1.9z"/>
<path onClick={() => props.onFill(79)} fill={props.fillColors[79]} d="M2.9,38c0-12.2,0-24.1,0-36.1c11.9,0,23.7,0,35.7,0c0,5.5,0,10.9-0.1,16.4c0,0.7-0.6,1.5-1.2,2 C31.8,26,26.2,31.6,20.5,37.2c-0.4,0.4-0.9,0.8-1.4,0.8C13.8,38,8.4,38,2.9,38z"/>
<path onClick={() => props.onFill(80)} fill={props.fillColors[80]} d="M193.5,124.9c3.9,10.2,7.6,20.2,11.3,29.9c-4.3,1.5-8.9,2.4-12.8,4.5c-4,2.2-7.4,5.4-11.1,8.3 c-7.8-11.4-15.4-22.7-23.2-34.2C170.7,135.4,182.6,133.4,193.5,124.9z"/>
<path onClick={() => props.onFill(81)} fill={props.fillColors[81]} d="M318.4,167.9c-2.3-1.8-4.9-3.5-7-5.6c-3.9-4.1-8.9-5.3-14.1-6.5c-0.8-0.2-1.7-0.3-2.7-0.5 c4-10.2,7.9-20.4,12-30.9c10,9.9,22.1,10.7,35.2,9.1C334.1,144.9,326.3,156.2,318.4,167.9z"/>
<path onClick={() => props.onFill(82)} fill={props.fillColors[82]} d="M224.3,116c1.7,13.6,3.4,27.3,5.1,40.9c-3.5-0.7-7.1-1.5-10.8-1.9c-3.7-0.4-7.4-0.4-11.3-0.6 c-3.7-10-7.6-20.4-11.6-30.9C204.4,117.9,213.1,112.8,224.3,116z"/>
<path onClick={() => props.onFill(83)} fill={props.fillColors[83]} d="M304.4,122.8c-4.2,10.7-8.2,21.3-12.4,31.9c-7.4-0.6-14.7,0.2-22,2.6c0.5-3.6,1-7.1,1.5-10.6 c0.5-3.2,1-6.4,1.4-9.6c0.5-3.2,0.9-6.4,1.3-9.6c0.5-3.3,1-6.7,1.3-10c0.1-1.4,0.5-1.9,1.9-1.8c1,0,2-0.3,3-0.3 c6.8-0.6,12.9,1.4,18.8,4.7C300.9,121,302.6,121.8,304.4,122.8z"/>
<path onClick={() => props.onFill(84)} fill={props.fillColors[84]} d="M224.5,231.7c-14.1-20.7-28.2-41.4-42.3-62.1c3.6-2.7,7.2-5.4,10.8-8.1C211.6,181.3,221,205.1,224.5,231.7z"/>
<path onClick={() => props.onFill(85)} fill={props.fillColors[85]} d="M275.3,231.3c3.7-26.6,12.8-50.3,31.3-69.8c1.6,1.3,3.3,2.7,5.1,4.1c1.7,1.4,3.4,2.7,5.4,4.4 C303.2,190.2,289.4,210.5,275.3,231.3z"/>
<path onClick={() => props.onFill(86)} fill={props.fillColors[86]} d="M250.6,166c0-0.6,0-1.2,0-1.8c0-12.6,0-25.3,0-37.9c0-1.5,0.4-2.1,1.9-2.7c4.4-1.5,8.6-3.4,12.9-5 c2.5-0.9,5.2-1.5,7.9-2.3c-0.9,6.7-1.6,13-2.5,19.3c-0.5,3.5-1.3,7-1.8,10.5c-0.5,3.2-0.9,6.4-1.2,9.7c-0.1,1.6-0.7,2.6-2.1,3.2 c-4.7,2.3-9.3,4.5-14,6.8C251.4,166,251,165.9,250.6,166z"/>
<path onClick={() => props.onFill(87)} fill={props.fillColors[87]} d="M227,116.1c3.9,1.4,7.6,2.5,11.2,3.9c2.9,1.1,5.8,2.4,8.7,3.8c0.5,0.2,1,1,1,1.5c0.1,13.5,0,27,0,40.8 c-2.4-1.3-4.6-2.5-6.8-3.6c-2.2-1.1-4.5-2.3-6.8-3.3c-1.6-0.7-2.3-1.5-2.5-3.4c-0.3-4.2-1-8.3-1.5-12.4c-0.2-1.5-0.5-2.9-0.6-4.4 c-0.4-3.3-0.8-6.7-1.2-10.1C228,124.8,227.6,120.7,227,116.1z"/>
<path onClick={() => props.onFill(88)} fill={props.fillColors[88]} d="M477.4,615.1c-6,6.2-11.8,12.2-17.7,18.3c-5.8-6-11.6-12-17.3-17.9c5.9-5.8,11.7-11.6,17.5-17.4 C465.5,603.6,471.3,609.2,477.4,615.1z"/>
<path onClick={() => props.onFill(89)} fill={props.fillColors[89]} d="M39.8,21.6c5.9,5.8,11.7,11.5,18,17.8c-6.2,5.7-12.3,11.2-18.3,16.7C34.1,50.7,28.2,44.8,22.4,39 C28.1,33.2,33.9,27.4,39.8,21.6z"/>
<path onClick={() => props.onFill(90)} fill={props.fillColors[90]} d="M293.1,255.9c21.1-11.3,42.2-22.5,63.5-33.9c2,3.6,4.1,7.2,6.1,10.7C340.7,243.9,318,253.2,293.1,255.9z"/>
<path onClick={() => props.onFill(91)} fill={props.fillColors[91]} d="M477.9,39.6c-5.9,5.9-11.6,11.6-16.8,16.8c-6.3-5.8-12.3-11.3-18.1-16.6c6.2-6.1,12-11.9,17.9-17.8 C466.3,27.6,472.1,33.6,477.9,39.6z"/>
<path onClick={() => props.onFill(92)} fill={props.fillColors[92]} d="M40.2,632.2c-5.9-5.9-11.7-11.7-17.6-17.6c5.7-5.8,11.5-11.8,17.3-17.7c2.5,2.9,5,5.7,7.5,8.5 c2.8,3.1,5.7,6.3,8.6,9.3c0.9,0.9,0.5,1.5-0.2,2.2c-4.9,4.8-9.8,9.6-14.7,14.4C40.7,631.5,40.4,631.9,40.2,632.2z"/>
<path onClick={() => props.onFill(93)} fill={props.fillColors[93]} d="M204.5,255.1c-23.8-4-46.5-10.3-67.7-22.3c1.4-2.3,2.8-4.9,4.3-7.4c2-3.2,2-3.1,5.6-1.2 c6.7,3.6,13.4,7.1,20,10.6c8.3,4.4,16.6,8.9,24.9,13.4c3.8,2,7.6,3.9,11.4,5.9C203.5,254.2,203.9,254.6,204.5,255.1z"/>
<path onClick={() => props.onFill(94)} fill={props.fillColors[94]} d="M205.1,293.5c-22,12.5-44,25-66.1,37.5c-1-1.9-2-3.6-2.7-5.4c-0.2-0.5,0.3-1.6,0.8-1.9 c6.9-4.5,13.7-9.2,20.9-13.3c5.3-3.1,10.9-5.8,16.5-8.4c3.6-1.7,7.4-3.1,11.3-4.3c5-1.5,10.2-2.6,15.3-3.8c1.3-0.3,2.6-0.6,4-0.8 C205,293.3,205.1,293.4,205.1,293.5z"/>
<path onClick={() => props.onFill(95)} fill={props.fillColors[95]} d="M294.5,293.2c2.6,0.4,5.2,0.7,7.6,1.4c6,1.6,12,3.2,17.8,5.3c4.4,1.6,8.6,3.8,12.8,5.8c3.2,1.5,6.3,3.2,9.3,5.1 c5.7,3.5,11.3,7.2,17,10.9c1.5,1,3,2,4.5,3c-1.7,1.9-3.3,3.7-5,5.5c-21.5-12.3-42.8-24.4-64.2-36.6 C294.5,293.4,294.5,293.3,294.5,293.2z"/>


    </g>
            </svg>
        </div>
    )
}