import React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, withRouter } from "react-router-dom";


function Logout() {
  const dispatch = useDispatch();

  // 1. Clear the token from browser storage
  localStorage.removeItem('token');
  console.log("Token removed from localStorage"); // Optional: for verification

  // 2. Clear the user data from Redux state
  dispatch({
    type: 'CLEAR_USER'
  });

  // 3. Redirect user to home page
  return <Redirect to="/" push={true} />
}

export default withRouter(Logout);