import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import LoadingSpinner from "../LoadingSpinner";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

function AllOrders() {
  const [orders, setOrders] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch(
          "https://test-api.stainedglassusa.com/orders",
          {
            headers: {
              Authorization: `bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setOrders(data);
      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrders();
  }, [token]);

  return (
    <div>
      <h1>All Orders</h1>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>User ID</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Width</TableCell>
                <TableCell>Height</TableCell>
                <TableCell>Frame Type</TableCell>
                <TableCell>Window Shape</TableCell>
                <TableCell>Installation Options</TableCell>
                <TableCell>Shipping Zip Code</TableCell>
                <TableCell>Additional Notes</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Shipping Status</TableCell>
                <TableCell>Tracking Order</TableCell>
                <TableCell>Quote Requested</TableCell>
                <TableCell>Glass Height</TableCell>
                <TableCell>Glass Width</TableCell>
                <TableCell>User Image ID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(orders) &&
                orders.map((order) => (
                  <TableRow key={order.id}>
                    <TableCell>{order.id}</TableCell>
                    <TableCell>{order.user_id}</TableCell>
                    <TableCell>{order.status}</TableCell>
                    <TableCell>{order.width}</TableCell>
                    <TableCell>{order.height}</TableCell>
                    <TableCell>{order.frame_type}</TableCell>
                    <TableCell>{order.window_shape}</TableCell>
                    <TableCell>{order.installation_options}</TableCell>
                    <TableCell>{order.shipping_zip_code}</TableCell>
                    <TableCell>{order.additional_notes}</TableCell>
                    <TableCell>{order.price}</TableCell>
                    <TableCell>{order.shipping_status}</TableCell>
                    <TableCell>{order.tracking_order}</TableCell>
                    <TableCell>
                      {order.quote_requested ? "Yes" : "No"}
                    </TableCell>
                    <TableCell>{order.glass_height}</TableCell>
                    <TableCell>{order.glass_width}</TableCell>
                    <TableCell>{order.user_image_id}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}

export default AllOrders;