import React from 'react';


export default function Put03(props) {
    
    return (
        <div className={`Put03-container ${props.size}`}> 
 
            <svg  xmlns="http://www.w3.org/2000/svg" id={props.id} width="500" height="500" viewBox="0 0 500 500">
                
            <pattern id="01_yellow" width="100%" height="100%">
                      <image xlinkHref="/01_yellow.jpg"/>
              </pattern>
          <pattern id="02_lightAmber" width="100%" height="100%">
              <image xlinkHref="/02_lightAmber.jpg"/>
          </pattern>
          <pattern id="03_darkAmber" width="100%" height="100%">
              <image xlinkHref="/03_darkAmber.jpg"/>
          </pattern>
          <pattern id="04_cherry" width="100%" height="100%">
              <image xlinkHref="/04_cherry.jpg"/>
          </pattern>
          <pattern id="05_redAmber" width="100%" height="100%">
              <image xlinkHref="/05_redAmber.jpg"/>
          </pattern>    
          <pattern id="06_orange" width="100%" height="100%">
              <image xlinkHref="/06_orange.jpg"/>
          </pattern>
          <pattern id="07_green" width="100%" height="100%">
              <image xlinkHref="/07_green.jpg"/>
          </pattern>
          <pattern id="08_olive" width="100%" height="100%">
              <image xlinkHref="/08_olive.jpg"/>
          </pattern>
          <pattern id="09_purple" width="100%" height="100%">
              <image xlinkHref="/09_purple.jpg"/>
          </pattern>
          <pattern id="10_lightBlue" width="100%" height="100%">
              <image xlinkHref="/10_lightBlue.jpg"/>
          </pattern>  
          <pattern id="11_aquaBlue" width="100%" height="100%">
              <image xlinkHref="/11_aquaBlue.jpg"/>
          </pattern>
          <pattern id="12_oceanBlue" width="100%" height="100%">
              <image xlinkHref="/12_oceanBlue.jpg"/>
          </pattern>
          <pattern id="13_cobaltBlue" width="100%" height="100%">
              <image xlinkHref="/13_cobaltBlue.jpg"/>
          </pattern>
          <pattern id="14_tealBlue" width="100%" height="100%">
              <image xlinkHref="/14_tealBlue.jpg"/>
          </pattern>
          <pattern id="15_clear" width="100%" height="100%">
              <image xlinkHref="/15_clear.jpg"/>
          </pattern>  
          <pattern id="16_granite" width="100%" height="100%">
              <image xlinkHref="/16_granite.jpg"/>
          </pattern>
          <pattern id="17_crackle" width="100%" height="100%">
              <image xlinkHref="/17_crackle.jpg"/>
          </pattern>
          <pattern id="18_iridescent" width="100%" height="100%">
              <image xlinkHref="/18_iridescent.jpg"/>
          </pattern>
          <pattern id="19_taffy" width="100%" height="100%">
              <image xlinkHref="/19_taffy.jpg"/>
          </pattern>
          <pattern id="20_seedy" width="100%" height="100%">
              <image xlinkHref="/20_seedy.jpg"/>
          </pattern> 
          <pattern id="21_frosted" width="100%" height="100%">
              <image xlinkHref="/21_frosted.jpg"/>
          </pattern>                                      
            <g className="hummingbird-image" >

<path id="background" fill="black"  d="m0,500V0h500v500H0Z"/>

        <path onClick={() => props.onFill(1)} fill={props.fillColors[1]} d="M93.8,43.1c51.4,0,102.5,0,154,0c0,3.1,0,6.1,0,9.1c0.1,14.3,0.2,28.6,0.2,42.9c0,1.6-0.5,3.5-1.4,4.9
			c-7.7,12.7-15.6,25.2-23.4,37.8c-1.1,1.7-2.2,2.4-4.4,2.4c-30.4-0.1-60.8-0.1-91.2,0c-10.4,0-20.9,0.1-31.3,0.3
			c-2,0-2.6-0.5-2.6-2.6c0.1-30.8,0-61.6,0-92.4C93.8,44.7,93.8,44,93.8,43.1z"/>
		<path onClick={() => props.onFill(2)} fill={props.fillColors[2]} d="M252.5,43.1c50.3,0,100.4,0,150.7,0c0,31.7,0,63.3,0,95c-0.9,0-1.7,0.1-2.5,0.1c-19,0-38,0-56.9,0
			c-21.1,0-42.3-0.1-63.4-0.1c-1.8,0-2.8-0.5-3.7-2c-7.6-12.4-15.3-24.7-23-37.1c-0.5-0.8-0.8-2-0.8-2.9
			c-0.2-14.2-0.2-28.4-0.3-42.6C252.5,50,252.5,46.6,252.5,43.1z"/>
		<path onClick={() => props.onFill(3)} fill={props.fillColors[3]} d="M170.6,456.9c-24.6,0-49.3,0-73.9,0c-2.8,0-2.8,0-2.8-2.9c0-28.9,0-57.8,0-86.8c0-2.8,0-2.8,2.9-2.8
			c41.5,0,83.1,0.1,124.6,0.1c1.6,0,2.5,0.4,3.3,1.8c7.5,12.8,15,25.6,22.4,38.4c0.6,1.1,0.9,2.5,0.9,3.8c0,15.5-0.2,30.9-0.3,46.4
			c0,2.1-1.2,2-2.6,2c-18.8,0-37.7,0-56.5,0C182.6,456.9,176.6,456.9,170.6,456.9z"/>
		<path onClick={() => props.onFill(4)} fill={props.fillColors[4]} d="M327.8,456.9c-24.2,0-48.3,0-72.5,0c-3,0-3,0-3-3c0.1-15.3,0.3-30.7,0.5-46c0-0.9,0.3-2,0.7-2.8
			c7.6-13,15.2-25.9,22.8-38.9c1-1.6,2.1-2.2,4-2.2c40,0.1,80.1,0,120.1,0c2.9,0,2.9,0,2.9,2.9c0,29.1,0,58.1,0,87.2
			c0,2.8,0,2.8-2.9,2.8C376.3,456.9,352,456.9,327.8,456.9z"/>
		<path onClick={() => props.onFill(5)} fill={props.fillColors[5]} d="M220.2,359.7c-1.2,0.1-2,0.1-2.7,0.1c-14.6,0-29.1,0-43.7,0c-25.8-0.1-51.7-0.2-77.5-0.2
			c-2,0-2.6-0.6-2.6-2.6c0.1-27.4,0.1-54.8,0-82.1c0-1.7,0.5-3,1.7-4.2c5.4-5.2,10.6-10.6,15.9-15.8c0.7-0.7,1.9-1.2,2.9-1.2
			c9.9-0.1,19.7-0.1,29.6,0c0.7,0,1.5,0,2,0.3c5.7,3,11.6,5.9,17.1,9.3c5.5,3.3,9.6,8.2,13.6,13.2c3.6,4.5,7.5,8.8,11.6,12.9
			c1.7,1.7,4.1,2.6,6.1,4c0.7,0.5,1.4,1.5,1.3,2.2c-0.9,12.4,2.2,24,7.8,34.8c4.7,9.2,10.1,18,15.2,27
			C219.2,357.9,219.6,358.6,220.2,359.7z"/>
		<path onClick={() => props.onFill(6)} fill={props.fillColors[6]} d="M218.4,145.2c-2.5,4-4.9,7.7-7.2,11.6c-6.1,10.5-11.6,21.2-14.1,33.2c-1.2,5.4-1.8,10.9-1.3,16.5
			c0.1,0.8-0.4,2.1-1,2.3c-6.8,3.2-11.6,8.6-16.1,14.5c-4.5,5.9-9,11.9-15.5,15.8c-5.4,3.3-11.1,6.2-16.8,9.1
			c-0.9,0.5-2.2,0.6-3.3,0.6c-9.5,0-18.9,0.1-28.4,0c-1.2,0-2.6-0.6-3.5-1.5c-5.5-5.3-10.9-10.7-16.2-16.2c-0.7-0.8-1.4-2-1.4-3.1
			c-0.1-27.1-0.1-54.2-0.1-81.3c0-0.4,0.1-0.8,0.1-1.5C135.3,145.2,176.7,145.2,218.4,145.2z"/>
		<path onClick={() => props.onFill(7)} fill={props.fillColors[7]} d="M281.2,143.1c1.3,0,2,0,2.8,0c38.9,0,77.9,0,116.8,0c2.1,0,2.7,0.6,2.7,2.7c-0.1,27.2-0.1,54.3,0,81.5
			c0,1.7-0.4,2.9-1.6,4.1c-5.4,5.3-10.7,10.7-16.1,16c-0.9,0.8-2.3,1.4-3.5,1.5c-7.6,0.1-15.3,0.1-22.9,0c-1.1,0-2.3-0.1-3.3-0.5
			c-6-2.9-12.1-5.8-18-9c-6-3.3-10.2-8.6-14.5-13.8c-3.5-4.3-7.3-8.5-11.2-12.4c-1.7-1.7-4.1-2.6-6.1-4c-0.6-0.4-1.3-1.4-1.2-2
			c0.9-12.7-2.2-24.6-7.9-35.8c-4.4-8.6-9.5-16.9-14.3-25.4C282.4,145,282,144.3,281.2,143.1z"/>
		<path onClick={() => props.onFill(8)} fill={props.fillColors[8]} d="M280.9,359.1c1.9-3,3.5-5.5,5-8c6.6-10.6,12.7-21.4,16.4-33.4c2.2-7.2,3.2-14.5,2.8-22c0-0.8,0.3-2,0.9-2.3
			c7.9-3.7,13-10.2,18-17c5.7-7.8,13-13.4,21.6-17.8c7.6-3.9,15.3-6.1,23.9-5.3c4.3,0.4,8.7,0,13,0.1c1.1,0,2.3,0.5,3.1,1.2
			c5.5,5.4,10.9,10.9,16.4,16.3c1.1,1.1,1.5,2.2,1.5,3.7c0,27.2-0.1,54.5,0,81.7c0,2.2-0.7,2.7-2.7,2.7c-39-0.1-78,0-117,0
			C283,359.1,282.2,359.1,280.9,359.1z"/>
		<path onClick={() => props.onFill(9)} fill={props.fillColors[9]} d="M457,195.9c0,16.7,0,33.5,0,50.2c0,2.1-0.4,2.8-2.7,2.8c-8.3-0.1-16.7,0-25-0.1c-1.2,0-2.8-0.6-3.6-1.5
			c-5.5-5.3-10.9-10.7-16.2-16.2c-0.8-0.8-1.4-2.2-1.4-3.3c-0.1-27.5,0-54.9-0.1-82.4c0-1.7,0.5-2.3,2.3-2.3
			c14.7,0.1,29.4,0.1,44.1,0c2,0,2.6,0.5,2.6,2.5C457,162.4,457,179.1,457,195.9z"/>
		<path onClick={() => props.onFill(10)} fill={props.fillColors[10]} d="M457,306.4c0,16.7,0,33.5,0,50.2c0,2.1-0.6,2.6-2.6,2.6c-14.6-0.1-29.1-0.1-43.7,0c-2.2,0-2.7-0.7-2.7-2.7
			c0.1-27.2,0.1-54.3,0-81.5c0-1.7,0.5-2.9,1.7-4.1c5.4-5.3,10.7-10.7,16.1-16c0.8-0.8,2.2-1.3,3.3-1.3c8.5-0.1,17.1,0.1,25.6,0
			c1.9,0,2.3,0.7,2.3,2.4C457,272.8,457,289.6,457,306.4z"/>
		<path onClick={() => props.onFill(11)} fill={props.fillColors[11]} d="M88.8,359.4c-15.3,0-30.5,0-45.8,0c0-35.4,0-70.6,0-106.2c3.4,0,6.8,0,10.1,0c5,0.1,10.1,0.1,15.1,0.4
			c1,0,2.2,0.5,2.9,1.2c5.6,5.5,11.1,11,16.6,16.6c0.6,0.7,1.1,1.8,1.1,2.7c0.1,27.8,0,55.6,0,83.4
			C88.9,358.1,88.9,358.8,88.8,359.4z"/>
		<path onClick={() => props.onFill(12)} fill={props.fillColors[12]} d="M408.3,43.1c16.3,0,32.3,0,48.7,0c0,0.9,0,1.8,0,2.6c0,30,0,59.9,0,89.9c0,2.1-0.5,2.7-2.6,2.7
			c-14.6-0.1-29.1,0-43.7-0.1c-0.8,0-1.5-0.1-2.4-0.1C408.3,106.4,408.3,74.9,408.3,43.1z"/>
		<path onClick={() => props.onFill(13)} fill={props.fillColors[13]} d="M43,145.2c15.4,0,30.5,0,45.9,0c0,0.8,0,1.6,0,2.3c0,26.7,0,53.4,0,80.1c0,1.5-0.4,2.6-1.5,3.7
			c-5.3,5.2-10.6,10.6-16,15.8c-0.8,0.8-2.3,1.4-3.4,1.4c-7.8,0.1-15.5-0.1-23.3-0.1c-0.5,0-1.1-0.1-1.7-0.2
			C43,213.9,43,179.7,43,145.2z"/>
		<path onClick={() => props.onFill(14)} fill={props.fillColors[14]} d="M457,364c0,1,0,1.7,0,2.5c0,29.2,0,58.4,0,87.6c0,2.8,0,2.8-2.9,2.8c-14.4,0-28.8,0-43.3,0
			c-2.1,0-2.7-0.6-2.7-2.7c0.1-29.3,0.1-58.5,0-87.8c0-2,0.5-2.6,2.5-2.6c14.6,0.1,29.3,0,43.9,0.1C455.3,363.9,456,363.9,457,364z"
			/>
		<path onClick={() => props.onFill(15)} fill={props.fillColors[15]} d="M43.1,43c15.3,0,30.4,0,45.8,0c0,0.8,0,1.6,0,2.3c0,30.8,0,61.6,0,92.4c0,2-0.5,2.6-2.5,2.6
			c-13.7-0.1-27.3-0.1-41,0c-1.8,0-2.6-0.3-2.6-2.4C43,107,43,76.1,43,45.2C43,44.5,43.1,43.9,43.1,43z"/>
		<path onClick={() => props.onFill(16)} fill={props.fillColors[16]} d="M43,364.5c15.4,0,30.5,0,45.8,0c0,0.8,0.1,1.5,0.1,2.3c0,29.2,0,58.4,0,87.6c0,2-0.6,2.6-2.6,2.6
			c-13.6-0.1-27.3-0.1-40.9,0c-1.9,0-2.5-0.6-2.5-2.5c0-29.2,0-58.4,0-87.6C43,366.2,43,365.5,43,364.5z"/>
		<path onClick={() => props.onFill(17)} fill={props.fillColors[17]} d="M250.7,349.7c-1.6-2.6-2.6-4.9-4.2-6.8c-2.2-2.5-4.6-4.9-7.1-7.1c-6.3-5.5-10.5-12.2-12.1-20.4
			c-1.7-8.2-1.4-16.3,0.8-24.5c3.2-11.8,10.4-21.1,17.7-30.4c0.3-0.4,1.3-0.4,2-0.4c1.9,0,3.7,0,5.6,0.1c0.7,0,1.6,0.1,1.9,0.5
			c6.4,8,12.8,16,16.2,25.8c4.9,14,4.9,27.9-2.9,41.1c-1.7,3-4.3,5.5-6.8,7.9C257.5,339.6,253,343.5,250.7,349.7z"/>
		<path onClick={() => props.onFill(18)} fill={props.fillColors[18]} d="M250.1,155c2.8,6.2,7.3,10.4,11.6,14.8c9.9,10,13.4,22.1,12.1,35.9c-1,10.8-6.3,19.7-12.5,28.2
			c-1.8,2.5-3.7,4.9-5.7,7.2c-0.5,0.6-1.8,1.1-2.4,0.9c-2-0.7-3.8-0.3-5.8,0.1c-0.5,0.1-1.4-0.1-1.7-0.5
			c-6.5-8.1-13.1-16.1-16.4-26.1c-4.5-13.6-3.3-26.7,4.2-38.9c1.7-2.8,4.2-5.2,6.5-7.7c3.8-4,7.6-8,9.8-13.3
			C249.9,155.5,250,155.3,250.1,155z"/>
		<path onClick={() => props.onFill(19)} fill={props.fillColors[19]} d="M287.3,240.3c-4.7-2.6-7.8-6.3-10.9-10.1c-2.8-3.4-3-6.3-1.3-10.6c6.8-17,5-33.3-5.7-48.4
			c-2.3-3.2-5.3-5.9-8.1-8.7c-4.3-4.3-6.6-9.6-8.3-15.3c-0.1-0.5-0.1-1-0.1-1.5c0-12.2,0-24.4,0-36.5c0-0.4,0.1-0.8,0.2-1.8
			c0.9,1.4,1.5,2.4,2.1,3.4c10,16,20.2,31.9,30.1,48c5.3,8.6,9.8,17.7,12.7,27.5c4.1,13.7,2.8,26.8-3.4,39.5
			C292.3,230.6,289.8,235.3,287.3,240.3z"/>
		<path onClick={() => props.onFill(20)} fill={props.fillColors[20]} d="M248,108.2c0,0.7,0,1.5,0,2.2c0,10.5-0.1,21,0,31.5c0.1,7.8-3.1,14.2-8,19.9c-3.5,4.1-7.6,7.9-10.6,12.4
			c-7.2,11-9.1,23.4-6.5,36.2c0.9,4.5,2.9,8.7,4.4,13.1c0.2,0.7,0.4,1.6,0.1,2.1c-3.2,5.1-6.7,10-11.8,13.5
			c-0.6,0.4-1.2,0.7-2.2,1.3c-3.3-7-6.8-13.7-9.6-20.7c-4.9-12.1-4.1-24.3,0-36.5c4.4-12.7,11.3-24,18.4-35.2
			c8.2-12.9,16.3-25.9,24.5-38.9c0.2-0.3,0.5-0.7,0.7-1C247.6,108.2,247.8,108.2,248,108.2z"/>
		<path onClick={() => props.onFill(21)} fill={props.fillColors[21]} d="M287.2,262.1c4.4,8.8,9.4,17,11.7,26.4c2.4,10,1.5,19.8-1.8,29.5c-4,12-10.4,22.7-17.1,33.3
			c-9,14.3-18.4,28.4-26.2,43.5c-0.2,0.3-0.4,0.6-0.9,1.4c-0.1-1.4-0.1-2.2-0.1-2.9c0-10.4,0-20.9,0-31.3c0-7.6,3.1-13.8,8.2-19.1
			c3-3.1,6.3-5.8,9-9c6.6-8,9.1-17.5,9.4-27.7c0.3-9.6-1.8-18.6-6-27.2c-0.3-0.7-0.5-1.8-0.2-2.3c3.3-5.1,6.8-10.1,12-13.6
			C285.8,262.8,286.4,262.5,287.2,262.1z"/>
		<path onClick={() => props.onFill(22)} fill={props.fillColors[22]} d="M247.4,395.5c-1.9-3.3-3.6-6.7-5.6-10c-8.4-13.7-16.9-27.2-25.2-40.9c-6.1-10.1-11.5-20.5-14.4-32
			c-3.2-12.5-1.4-24.4,4.1-35.9c2.1-4.4,4.5-8.7,6.7-13.1c0.6-1.3,1.2-1.4,2.4-0.6c5.4,3.6,9.1,8.7,12.5,14c0.3,0.5,0,1.6-0.4,2.3
			c-4.7,9.4-6.6,19.3-5.9,29.7c0.7,9.5,3.4,18.3,9.8,25.6c2.7,3.1,6,5.7,8.9,8.6c5,4.9,7.7,10.9,7.8,18c0.1,10.6,0,21.3,0,31.9
			c0,0.7,0,1.5,0,2.2C247.8,395.3,247.6,395.4,247.4,395.5z"/>
		<path onClick={() => props.onFill(23)} fill={props.fillColors[23]} d="M177.8,461.7c0,5,0,9.8,0,14.5c0,0.3-0.6,0.9-1,1c-0.6,0.2-1.2,0.1-1.9,0.1c-42.9,0-85.8,0-128.8,0
			c-3.1,0-3.1,0-3.1-3.1c0-2.9,0-5.9,0-8.8c0-4-0.4-3.7,3.6-3.7c42.7,0,85.4,0,128.1,0C175.6,461.7,176.5,461.7,177.8,461.7z"/>
		<path onClick={() => props.onFill(24)} fill={props.fillColors[24]} d="M322.4,477.2c0-5.4,0-10.4,0-15.5c1.1,0,2,0,2.9,0c32.2,0,64.4,0,96.6,0c10.9,0,21.8,0,32.8-0.1
			c1.8,0,2.5,0.5,2.4,2.4c-0.1,3.6-0.1,7.3,0,10.9c0,1.7-0.4,2.3-2.2,2.3c-29.7-0.1-59.5,0-89.2,0c-13.5,0-27,0-40.5,0
			C324.2,477.2,323.4,477.2,322.4,477.2z"/>
		<path onClick={() => props.onFill(25)} fill={props.fillColors[25]} d="M250.1,461.7c21.7,0,43.4,0,65.1,0c1.7,0,2.2,0.6,2.1,2.2c-0.1,3.6-0.1,7.3,0,10.9c0.1,1.9-0.5,2.5-2.5,2.5
			c-20.9-0.1-41.9,0-62.8,0c-22.2,0-44.4,0-66.6,0c-2.8,0-2.8,0-2.8-2.9c0-3.6,0-7.1,0-10.7c0-1.4,0.5-2,1.9-2c21.2,0,42.4,0,63.7,0
			c0.6,0,1.3,0.1,1.9,0.1C250.1,461.8,250.1,461.8,250.1,461.7z"/>
		<path onClick={() => props.onFill(26)} fill={props.fillColors[26]} d="M22.8,43c5,0,9.7,0,14.5,0c0.3,0,0.8,0.6,1,1c0.2,0.5,0.1,1.1,0.1,1.7c0,31.3-0.1,62.6-0.1,93.9
			c0,11.9-0.1,23.8-0.1,35.7c0,1.9-0.4,2.6-2.5,2.5c-3.4-0.2-6.9-0.1-10.3,0c-2,0.1-2.6-0.5-2.6-2.6c0.1-17.9,0-35.8,0-53.8
			c0-25.3,0-50.7,0-76C22.8,44.8,22.8,44.1,22.8,43z"/>
		<path onClick={() => props.onFill(27)} fill={props.fillColors[27]} d="M461.7,43.1c5.2,0,10.2,0,15.4,0c0,0.8,0.1,1.5,0.1,2.2c0,43.3,0,86.6,0.1,129.8c0,2.2-0.7,2.8-2.7,2.7
			c-3.4-0.2-6.9-0.2-10.3,0c-2.1,0.1-2.4-0.8-2.4-2.6c0.1-10.5,0-21,0-31.5c0-16.5-0.1-33-0.1-49.6c0-16.1,0-32.2,0-48.3
			C461.7,45,461.7,44.1,461.7,43.1z"/>
		<path onClick={() => props.onFill(28)} fill={props.fillColors[28]} d="M22.8,322.3c5,0,9.6,0,14.2,0.1c0.4,0,1.1,1.1,1.1,1.8c0.1,5.7,0,11.5,0.1,17.2c0,27.9,0.1,55.9,0.1,83.8
			c0,9.8,0,19.6,0.1,29.4c0,1.8-0.5,2.5-2.4,2.4c-3.7-0.1-7.4-0.1-11.1,0c-1.5,0-2.2-0.4-2.1-2c0.1-1.5,0-2.9,0-4.4
			c0-41.7,0-83.5,0-125.2C22.8,324.4,22.8,323.5,22.8,322.3z"/>
		<path onClick={() => props.onFill(29)} fill={props.fillColors[29]} d="M477.2,389.8c0,21.4,0,42.9,0,64.3c0,2.8,0,2.8-2.9,2.8c-3.4,0-6.9-0.1-10.3,0c-1.7,0-2.4-0.4-2.3-2.2
			c0.1-10.9,0.2-21.7,0.2-32.6c0-9.5-0.1-18.9-0.2-28.4c0-9.5,0-18.9,0-28.4c0-13.6,0.1-27.2,0.1-40.7c0-1.9,0.5-2.6,2.5-2.5
			c3.5,0.2,7,0.2,10.5,0c1.9-0.1,2.5,0.5,2.5,2.5C477.2,346.4,477.2,368.1,477.2,389.8z"/>
		<path onClick={() => props.onFill(30)} fill={props.fillColors[30]} d="M177.8,22.8c0,4.9,0,9.5-0.1,14c0,0.5-0.8,1.1-1.3,1.4c-0.5,0.2-1.2,0.1-1.9,0.1c-26.5,0-52.9,0-79.4,0
			c-16.5,0-32.9,0-49.4,0c-2.8,0-2.8,0-2.8-2.7c0-3.6,0.1-7.1,0-10.7c-0.1-1.6,0.5-2,2.1-2c8,0.1,16,0,24,0c35.3,0,70.6,0,105.9,0
			C175.8,22.8,176.7,22.8,177.8,22.8z"/>
		<path onClick={() => props.onFill(31)} fill={props.fillColors[31]} d="M322.2,22.9c45.1,0,89.8,0,134.8,0c0,4.8,0,9.4-0.1,14.1c0,0.4-0.7,1-1.2,1.1c-0.6,0.2-1.2,0.1-1.9,0.1
			c-13.7,0-27.4,0-41.2,0c-29.2,0-58.4,0-87.6,0c-2.9,0-3,0-3-3C322.2,31.2,322.2,27.1,322.2,22.9z"/>
		<path onClick={() => props.onFill(32)} fill={props.fillColors[32]} d="M477.2,317.3c-2.2,0-4.1,0-6.1,0c-2.4,0-4.8,0.1-7.1,0.2c-1.6,0.1-2.2-0.5-2.2-2.2c0.1-6.9,0-13.9,0-20.8
			c0-12.9,0-25.8,0-38.7c0-23.4,0-46.9,0-70.3c0-3,0-3,3-3c3.4,0,6.7,0.1,10.1,0c1.9-0.1,2.3,0.6,2.3,2.4c-0.1,22.3,0,44.7,0,67
			c0,20.8,0,41.6,0,62.4C477.2,315.3,477.2,316.2,477.2,317.3z"/>
		<path onClick={() => props.onFill(33)} fill={props.fillColors[33]} d="M38,317.2c-5.1,0-10,0-15.1,0c0-0.8-0.1-1.5-0.1-2.3c0-43.3,0-86.6,0-129.8c0-2.1,0.7-2.6,2.6-2.5
			c3.6,0.1,7.1,0.1,10.7,0c1.5,0,1.9,0.5,1.9,1.9c-0.1,21.9-0.1,43.8-0.1,65.8c0,21.4,0.1,42.7,0.1,64.1C38,315.2,38,316.1,38,317.2
			z"/>
		<path onClick={() => props.onFill(34)} fill={props.fillColors[34]} d="M182.7,22.8c1.1,0,1.9,0,2.6,0c43.1,0,86.2,0,129.4,0c2,0,2.9,0.4,2.7,2.6c-0.2,3.5-0.2,7,0,10.5
			c0.1,2.1-0.8,2.4-2.6,2.4c-20.4,0-40.9,0-61.3,0c-22.5,0-45.1,0-67.6,0c-3.2,0-3.2,0-3.2-3.2C182.7,31,182.7,27.1,182.7,22.8z"/>
		<path onClick={() => props.onFill(35)} fill={props.fillColors[35]} d="M2.8,253.7c5.1,0,10,0,15.1,0c0,0.8,0.1,1.6,0.1,2.4c0,20,0,40,0,60.1c0,20.5,0,41,0,61.5
			c0,2.7,0,2.7-2.8,2.8c-4.1,0-8.2,0-12.5,0C2.8,338.1,2.8,296,2.8,253.7z"/>
		<path onClick={() => props.onFill(36)} fill={props.fillColors[36]} d="M497.3,378.9c-4.8,0-9.4,0-13.9-0.1c-0.5,0-1.1-0.8-1.3-1.4c-0.2-0.5-0.1-1.2-0.1-1.9c0-16.9,0-33.9,0-50.8
			c0-23.2,0-46.5,0-69.7c0-2.9,0-2.9,2.8-2.9c4.1,0,8.2,0,12.5,0C497.3,294.5,497.3,336.5,497.3,378.9z"/>
		<path onClick={() => props.onFill(37)} fill={props.fillColors[37]} d="M497.4,247.2c-4.9,0-9.5,0-14-0.1c-0.5,0-1.1-0.9-1.4-1.5c-0.2-0.5-0.1-1.2-0.1-1.9c0-19.8,0-39.6,0-59.4
			c0-19.2,0-38.3,0-57.5c0-2.9,0-2.9,2.8-2.9c3.6,0,7.3,0,10.9,0c1.3,0,1.8,0.4,1.7,1.7c0,0.5,0,1,0,1.5c0,39.1,0,78.1,0,117.2
			C497.4,245.2,497.4,246.1,497.4,247.2z"/>
		<path onClick={() => props.onFill(38)} fill={props.fillColors[38]} d="M2.6,125.6c3.5,0,6.9,0,10.2,0c5.2,0,5.2,0,5.2,5.2c0,15,0,30,0,44.9c0,23.4,0,46.7,0,70.1
			c0,1.9-0.3,2.9-2.6,2.9c-3.8-0.1-7.7,0.2-11.5,0.2c-0.5,0-1.1-0.7-1.4-1.3c-0.2-0.4,0-1,0-1.4c0-39.3,0-78.5,0-117.8
			C2.6,127.6,2.6,126.8,2.6,125.6z"/>
		<path onClick={() => props.onFill(39)} fill={props.fillColors[39]} d="M137.5,2.6c1.1-0.1,1.9-0.1,2.7-0.1c34.9,0,69.7,0,104.6,0c2.8,0,2.8,0,2.8,2.7c0,3.6-0.1,7.1,0,10.7
			c0,1.4-0.4,2-1.9,1.9c-21.6-0.1-43.1-0.1-64.7-0.1c-13.6,0-27.2,0-40.7,0.1c-2.7,0-2.7,0-2.7-2.7C137.5,11,137.5,7,137.5,2.6z"/>
		<path onClick={() => props.onFill(40)} fill={props.fillColors[40]} d="M22.8,2.6c1-0.1,1.9-0.1,2.7-0.1c34.9,0,69.7,0,104.6,0c2.1,0,2.8,0.5,2.7,2.7c-0.2,3.5-0.1,7,0,10.5
			c0.1,1.7-0.5,2.3-2.2,2.3c-6.8-0.1-13.6,0-20.4,0c-28.2,0-56.4,0-84.6,0c-2.7,0-2.7,0-2.7-2.7C22.8,11,22.8,7,22.8,2.6z"/>
		<path onClick={() => props.onFill(41)} fill={props.fillColors[41]} d="M307.5,17.8c-17.3,0-34.6,0-51.9,0c-3.8,0-3.3,0.2-3.3-3.4c0-3.1,0.1-6.3-0.1-9.4c-0.1-2.1,0.7-2.5,2.6-2.5
			c17.9,0.1,35.8,0,53.8,0c17.1,0,34.2,0,51.2,0c2,0,2.7,0.4,2.6,2.5c-0.2,3.6-0.1,7.3,0,10.9c0,1.4-0.3,2-1.9,2
			c-13.4-0.1-26.7-0.1-40.1-0.1C316.2,17.8,311.9,17.8,307.5,17.8C307.5,17.8,307.5,17.8,307.5,17.8z"/>
		<path onClick={() => props.onFill(42)} fill={props.fillColors[42]} d="M422.2,2.4c17.4,0,34.9,0,52.3,0c2.1,0,2.8,0.5,2.7,2.7c-0.2,3.5-0.1,7,0,10.5c0.1,1.7-0.5,2.3-2.2,2.3
			c-10.2-0.1-20.3,0-30.5,0c-24.9,0-49.7,0-74.6,0c-2.1,0-2.8-0.5-2.7-2.7c0.2-3.4,0.2-6.9,0-10.3c-0.1-1.9,0.6-2.5,2.5-2.5
			C387.2,2.5,404.7,2.4,422.2,2.4z"/>
		<path onClick={() => props.onFill(43)} fill={props.fillColors[43]} d="M137.5,497.6c0-5,0-9.5,0.1-14.1c0-0.5,0.8-1,1.4-1.3c0.5-0.2,1.1-0.1,1.7-0.1c11.4,0,22.8,0,34.2,0
			c23.2,0,46.5,0,69.7,0c3,0,3,0,3,3c0,3.6,0,7.1,0,10.7c0,1.3-0.4,1.8-1.7,1.7c-0.4,0-0.8,0-1.3,0c-34.7,0-69.5,0-104.2,0
			C139.6,497.6,138.7,497.6,137.5,497.6z"/>
		<path onClick={() => props.onFill(44)} fill={props.fillColors[44]} d="M77.8,497.6c-17.4,0-34.9,0-52.3,0c-2,0-2.8-0.4-2.7-2.6c0.2-3.5,0.2-7,0-10.5c-0.1-1.9,0.6-2.4,2.4-2.4
			c11.8,0.1,23.5,0,35.3,0c23.2,0,46.4,0,69.5,0c2.8,0,2.8,0,2.8,2.8c0,3.4-0.1,6.9,0,10.3c0.1,2-0.7,2.4-2.5,2.4
			C112.8,497.5,95.3,497.6,77.8,497.6z"/>
		<path onClick={() => props.onFill(45)} fill={props.fillColors[45]} d="M307.2,497.6c-17.4,0-34.9,0-52.3,0c-2.2,0-2.8-0.6-2.7-2.7c0.2-3.5,0.1-7,0-10.5c-0.1-1.8,0.6-2.3,2.3-2.3
			c10.7,0.1,21.4,0,32.1,0c11.1,0,22.1,0.2,33.2,0.2c13.2,0,26.5-0.1,39.7-0.2c2.8,0,2.8,0,2.8,2.7c0,3.5-0.1,7,0,10.5
			c0.1,1.8-0.5,2.2-2.3,2.2C342.5,497.5,324.9,497.6,307.2,497.6z"/>
		<path onClick={() => props.onFill(46)} fill={props.fillColors[46]} d="M422.2,497.6c-17.4,0-34.9,0-52.3,0c-2.7,0-2.7,0-2.7-2.8c0-3.4,0.1-6.9,0-10.3c-0.1-1.8,0.5-2.4,2.4-2.4
			c12.2,0.1,24.4,0,36.6,0c16.3,0,32.6,0,48.9,0c6.6,0,13.3,0.1,19.9,0c1.9,0,2.4,0.7,2.3,2.4c-0.1,3.6-0.1,7.1,0,10.7
			c0.1,1.9-0.5,2.4-2.3,2.4C457.3,497.5,439.8,497.6,422.2,497.6z"/>
		<path onClick={() => props.onFill(47)} fill={props.fillColors[47]} d="M18,22.9c0,0.9,0,1.7,0,2.5c0,30.9,0,61.7,0,92.6c0,2.7,0,2.8-2.8,2.8c-3.4,0-6.9-0.1-10.3,0
			c-1.9,0.1-2.5-0.6-2.5-2.5c0.1-24.1,0-48.2,0-72.2c0-7,0-14,0-21c0-0.7,0-1.4,0-2.2C7.7,22.9,12.7,22.9,18,22.9z"/>
		<path onClick={() => props.onFill(48)} fill={props.fillColors[48]} d="M497.3,22.8c0,32.3,0,64.3,0,96.4c-4.8,0-9.5,0-14.2,0c-0.4,0-0.9-0.6-1.1-1.1c-0.2-0.6-0.1-1.2-0.1-1.9
			c0-24,0-48,0-72c0-6.4,0.1-12.7,0-19.1c0-1.9,0.6-2.4,2.4-2.3C488.6,22.9,492.9,22.8,497.3,22.8z"/>
		<path onClick={() => props.onFill(49)} fill={props.fillColors[49]} d="M497.3,383.5c0.1,1,0.1,1.8,0.1,2.5c0,29.5,0,59.1,0.1,88.6c0,2.2-0.6,2.7-2.7,2.6c-3.6-0.2-7.3-0.1-10.9,0
			c-1.4,0-1.9-0.5-1.9-1.8c0.1-5.2,0.1-10.4,0.2-15.5c0-1.3-0.2-2.6-0.2-4c0-23.2,0-46.3,0-69.5c0-2.9,0-2.9,2.8-2.9
			C488.9,383.5,493,383.5,497.3,383.5z"/>
		<path onClick={() => props.onFill(50)} fill={props.fillColors[50]} d="M2.8,385.3c4.8,0,9.5,0,14.2,0c0.3,0,0.8,0.6,1,1c0.2,0.6,0.1,1.2,0.1,1.9c0,22.4,0,44.8,0,67.2
			c0,6.5,0,13,0,19.5c0,1.7-0.5,2.4-2.2,2.3c-3.8-0.1-7.7-0.1-11.5,0c-1.2,0-1.7-0.3-1.7-1.7c0-29.8,0-59.5,0-89.3
			C2.6,386.1,2.7,385.8,2.8,385.3z"/>
		<path onClick={() => props.onFill(51)} fill={props.fillColors[51]} d="M291.6,242.4c4.6-9.5,10.2-18.5,12.4-29c4.1,1.7,7.3,4.4,9.9,7.5c3,3.6,5.9,7.3,8.8,10.9
			c5.3,6.7,12,11.6,19.7,15.3c0.7,0.3,1.3,0.7,1.9,1.2C326.5,249.2,308.7,249.7,291.6,242.4z"/>
		<path onClick={() => props.onFill(52)} fill={props.fillColors[52]} d="M196.9,213.2c2.3,10.7,7.8,19.7,12.5,29.2c-16.8,7.2-34.4,6.8-52.5,6.2c0.9-0.6,1.3-1,1.8-1.2
			c8.8-4.4,16.1-10.6,21.9-18.5c3.6-4.8,7.1-9.8,12.2-13.2C193.9,214.9,195.2,214.2,196.9,213.2z"/>
		<path onClick={() => props.onFill(53)} fill={props.fillColors[53]} d="M344.8,253.7c-3.3,1.9-6.5,3.5-9.5,5.4c-7.3,4.4-12.5,10.9-17.5,17.6c-3.3,4.4-6.8,8.6-11.7,11.3
			c-0.6,0.3-1.2,0.6-1.7,0.9c-1.9-5-3.6-10-5.7-14.7c-2.1-4.7-4.7-9.2-7.2-14C308.7,252.4,326.7,253.3,344.8,253.7z"/>
		<path onClick={() => props.onFill(54)} fill={props.fillColors[54]} d="M157.1,254c17.7-0.8,35.3-1.5,52.1,5.9c-4.6,9.5-10.2,18.4-12.4,29c-4-1.9-7.2-4.3-9.7-7.4
			c-3.1-3.6-6-7.4-8.9-11.1c-5-6.5-11.4-11.3-18.6-15.1C158.8,254.9,157.9,254.5,157.1,254z"/>
		<path onClick={() => props.onFill(55)} fill={props.fillColors[55]} d="M198.7,251.2c13.4-3.1,24.8-8.9,31.7-21.4c0.5,0.5,0.8,0.6,1,0.9c3.4,4.6,6.8,9.2,10.3,13.8
			c0.7,0.9,1,1.7,0.4,2.8c-1.4,2.6-1.3,5.2-0.1,7.8c0.3,0.7,0.4,1.9,0,2.4c-3.6,5-7.4,9.8-11.4,15.1
			C223.6,260.3,212.3,254.2,198.7,251.2z"/>
		<path onClick={() => props.onFill(56)} fill={props.fillColors[56]} d="M270.5,229.7c6.9,12.6,18.4,18.4,31.8,21.5c-13.5,3.1-25,9-32.1,21.8c-1.3-1.9-2.3-3.5-3.5-5
			c-2.5-3.3-5-6.5-7.4-9.9c-0.4-0.6-0.6-1.9-0.3-2.5c1.3-2.9,1.3-5.6,0-8.5c-0.3-0.7-0.2-1.9,0.3-2.6
			C262.9,239.6,266.5,234.8,270.5,229.7z"/>
		<path onClick={() => props.onFill(57)} fill={props.fillColors[57]} d="M74.6,251c0.5-0.5,1.1-1.2,1.7-1.8c4.5-4.6,9.1-9.1,13.6-13.7c0.8-0.8,1.4-1.4,2.5-0.3c4.9,5,10,10,15,15
			c0.2,0.2,0.4,0.5,0.4,0.6c-5.7,5.7-11.2,11.2-16.8,16.7C85.8,262.2,80.3,256.7,74.6,251z"/>
		<path onClick={() => props.onFill(58)} fill={props.fillColors[58]} d="M389.4,251.1c5.4-5.3,11-10.9,16.4-16.3c5.3,5.4,10.9,10.9,16.2,16.3c-5.4,5.4-11,10.9-16.4,16.3
			C400.4,262.1,394.9,256.5,389.4,251.1z"/>
		<path onClick={() => props.onFill(59)} fill={props.fillColors[59]} d="M30.3,477.2c-2,0-3.9,0-5.9,0c-1.1,0-1.7-0.2-1.7-1.5c0-4.2,0-8.4,0.1-12.6c0-0.5,0.9-1.4,1.5-1.4
			c4.1-0.1,8.2,0,12.4-0.1c1.2,0,1.6,0.5,1.6,1.6c0,4.1,0,8.3,0,12.4c0,1.2-0.6,1.6-1.7,1.5C34.5,477.2,32.4,477.2,30.3,477.2z"/>
		<path onClick={() => props.onFill(60)} fill={props.fillColors[60]} d="M497.3,2.5c0,5.2,0,10.1,0,15.3c-4.8,0-9.4,0-14.1-0.1c-0.4,0-1.1-0.8-1.1-1.3c-0.1-4.2-0.1-8.4,0-12.6
			c0-0.5,0.7-1.3,1.1-1.3C487.9,2.4,492.5,2.5,497.3,2.5z"/>
		<path onClick={() => props.onFill(61)} fill={props.fillColors[61]} d="M2.6,17.8c0-5.2,0-10.1,0-15.4c2.3,0,4.6,0,6.9,0c2.3,0,4.6,0,6.9,0c1.2,0,1.6,0.4,1.6,1.6
			c0,4.1,0,8.1-0.1,12.2c0,0.5-0.7,1.5-1.1,1.5C12.2,17.8,7.5,17.8,2.6,17.8z"/>
		<path onClick={() => props.onFill(62)} fill={props.fillColors[62]} d="M477.2,469.5c0,2-0.1,3.9,0,5.9c0,1.3-0.3,1.9-1.8,1.9c-4-0.1-8-0.1-11.9,0c-1.4,0-1.8-0.5-1.8-1.9
			c0-3.9,0.1-7.8,0-11.7c0-1.4,0.4-2,1.9-2c3.9,0.1,7.8,0.1,11.7,0c1.5,0,1.9,0.6,1.9,2C477.1,465.6,477.2,467.6,477.2,469.5z"/>
		<path onClick={() => props.onFill(63)} fill={props.fillColors[63]} d="M22.8,22.8c5,0,9.7,0,14.4,0.1c0.4,0,1,0.8,1,1.2c0.1,4.3,0.1,8.5,0,12.8c0,0.5-0.9,1.3-1.4,1.3
			c-4.2,0.1-8.4,0.1-12.6,0c-0.5,0-1.4-0.7-1.4-1.1C22.8,32.4,22.8,27.7,22.8,22.8z"/>
		<path onClick={() => props.onFill(64)} fill={props.fillColors[64]} d="M477.2,22.8c0,4.9,0,9.4-0.1,14c0,0.5-0.8,1.3-1.3,1.4c-4.2,0.1-8.4,0.1-12.6,0.1c-1.2,0-1.6-0.6-1.6-1.7
			c0-4.1,0-8.1,0.1-12.2c0-0.5,0.7-1.4,1.2-1.5C467.6,22.7,472.2,22.8,477.2,22.8z"/>
		<path onClick={() => props.onFill(65)} fill={props.fillColors[65]} d="M497.4,497.5c-4.9,0-9.5,0-14.1-0.1c-0.4,0-1.2-0.9-1.2-1.4c-0.1-4.1,0-8.2-0.1-12.4c0-1.2,0.5-1.6,1.6-1.6
			c4.1,0,8.2,0,12.4,0.1c0.5,0,1.4,0.8,1.4,1.3C497.5,488,497.4,492.6,497.4,497.5z"/>
		<path onClick={() => props.onFill(66)} fill={props.fillColors[66]} d="M2.6,497.5c0-4.9,0-9.6,0.1-14.2c0-0.4,0.8-1.1,1.3-1.1c4.2-0.1,8.4-0.1,12.6,0c0.5,0,1.4,0.9,1.4,1.5
			c0.1,4.2,0.1,8.4,0,12.6c0,0.4-0.6,1.2-1,1.2C12.3,497.6,7.5,497.5,2.6,497.5z"/>
		<path onClick={() => props.onFill(67)} fill={props.fillColors[67]} d="M250.5,255.7c-2.5,0-4.5-2.1-4.5-4.5c0-2.5,2.2-4.7,4.7-4.6c2.6,0,4.6,2.2,4.5,4.8
			C255.1,253.9,253,255.8,250.5,255.7z"/>






</g>
            </svg>
        </div>
    )
}