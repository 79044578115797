import React from 'react';


export default function Put01(props) {
    
    return (
        <div className={`Put01-container ${props.size}`}> 
 
            <svg  xmlns="http://www.w3.org/2000/svg" id={props.id} width="500" height="500" viewBox="0 0 500 500">
                
            <pattern id="01_yellow" width="100%" height="100%">
                      <image xlinkHref="/01_yellow.jpg"/>
              </pattern>
          <pattern id="02_lightAmber" width="100%" height="100%">
              <image xlinkHref="/02_lightAmber.jpg"/>
          </pattern>
          <pattern id="03_darkAmber" width="100%" height="100%">
              <image xlinkHref="/03_darkAmber.jpg"/>
          </pattern>
          <pattern id="04_cherry" width="100%" height="100%">
              <image xlinkHref="/04_cherry.jpg"/>
          </pattern>
          <pattern id="05_redAmber" width="100%" height="100%">
              <image xlinkHref="/05_redAmber.jpg"/>
          </pattern>    
          <pattern id="06_orange" width="100%" height="100%">
              <image xlinkHref="/06_orange.jpg"/>
          </pattern>
          <pattern id="07_green" width="100%" height="100%">
              <image xlinkHref="/07_green.jpg"/>
          </pattern>
          <pattern id="08_olive" width="100%" height="100%">
              <image xlinkHref="/08_olive.jpg"/>
          </pattern>
          <pattern id="09_purple" width="100%" height="100%">
              <image xlinkHref="/09_purple.jpg"/>
          </pattern>
          <pattern id="10_lightBlue" width="100%" height="100%">
              <image xlinkHref="/10_lightBlue.jpg"/>
          </pattern>  
          <pattern id="11_aquaBlue" width="100%" height="100%">
              <image xlinkHref="/11_aquaBlue.jpg"/>
          </pattern>
          <pattern id="12_oceanBlue" width="100%" height="100%">
              <image xlinkHref="/12_oceanBlue.jpg"/>
          </pattern>
          <pattern id="13_cobaltBlue" width="100%" height="100%">
              <image xlinkHref="/13_cobaltBlue.jpg"/>
          </pattern>
          <pattern id="14_tealBlue" width="100%" height="100%">
              <image xlinkHref="/14_tealBlue.jpg"/>
          </pattern>
          <pattern id="15_clear" width="100%" height="100%">
              <image xlinkHref="/15_clear.jpg"/>
          </pattern>  
          <pattern id="16_granite" width="100%" height="100%">
              <image xlinkHref="/16_granite.jpg"/>
          </pattern>
          <pattern id="17_crackle" width="100%" height="100%">
              <image xlinkHref="/17_crackle.jpg"/>
          </pattern>
          <pattern id="18_iridescent" width="100%" height="100%">
              <image xlinkHref="/18_iridescent.jpg"/>
          </pattern>
          <pattern id="19_taffy" width="100%" height="100%">
              <image xlinkHref="/19_taffy.jpg"/>
          </pattern>
          <pattern id="20_seedy" width="100%" height="100%">
              <image xlinkHref="/20_seedy.jpg"/>
          </pattern> 
          <pattern id="21_frosted" width="100%" height="100%">
              <image xlinkHref="/21_frosted.jpg"/>
          </pattern>                                      
            <g className="hummingbird-image" >

<path id="background" fill="black"  d="m0,500V0h752v752H0Z"/>

  <path onClick={() => props.onFill(1)} fill={props.fillColors[1]} d="M249.03,50.1c0,9.93-.03,19.48,0,29.03,.16,37.76,.33,75.53,.56,113.29,.01,2.09-.56,3.57-2.06,5.06-16.54,16.4-32.99,32.91-49.55,49.29-1.09,1.08-2.94,1.97-4.44,1.98-46.83,.1-93.66,.09-140.5,.08-.85,0-1.71-.09-3.19-.16,0-5.87,.05-11.62,0-17.38-.46-44.71-.94-89.42-1.43-134.13-.12-10.91-.36-21.82-.37-32.73,0-1.57,.9-3.19,1.57-4.7,.38-.86,1.27-1.49,1.69-2.34,3.28-6.49,8.46-7.8,15.58-7.75,59.69,.42,119.39,.37,179.09,.46,.86,0,1.71,0,3.05,0Z"/>
  <path onClick={() => props.onFill(2)} fill={props.fillColors[2]} d="M253.64,449.42v-3.32c0-45.6,.02-91.21-.05-136.81,0-2.13,.67-3.57,2.15-5.03,16.53-16.42,32.99-32.92,49.54-49.32,1.04-1.03,2.76-1.93,4.17-1.93,46.36-.07,92.72-.02,139.08,.02,.48,0,.96,.05,1.88,.11,0,1.04,0,2.05,0,3.07,.01,59.71,.04,119.42-.03,179.13,0,1.57-.73,3.34-1.63,4.66-1.84,2.71-3.92,5.28-6.14,7.69-.85,.92-2.34,1.77-3.54,1.77-61.17,.08-122.34,.07-183.5,.07-.48,0-.96-.05-1.92-.1Z"/>
  <path onClick={() => props.onFill(3)} fill={props.fillColors[3]} d="M50.33,252.91h3.35c46.45,0,92.91,.01,139.36-.04,2.22,0,3.75,.67,5.3,2.23,16.44,16.51,32.97,32.93,49.38,49.47,1.01,1.02,1.76,2.79,1.76,4.22,.09,45.99,.08,91.97,.08,137.96v2.87h-3.37c-37.74,0-75.48-.02-113.23,.04-2.02,0-3.36-.52-4.64-2.15-25.47-32.35-51-64.64-76.47-96.99-.83-1.05-1.53-2.54-1.54-3.83-.11-30.62-.11-61.25-.13-91.87,0-.48,.07-.96,.14-1.9Z"/>
  <path onClick={() => props.onFill(4)} fill={props.fillColors[4]} d="M450.29,248.94h-3.5c-45.47,0-90.94-.02-136.42,.05-2.21,0-3.73-.64-5.29-2.2-16.5-16.58-33.09-33.07-49.58-49.65-.96-.97-1.78-2.59-1.79-3.92-.31-46.74-.52-93.48-.74-140.22,0-.85,0-1.7,0-2.88h3.62c28.15,0,56.31,0,84.46,0,8.13,0,16.25,0,24.38-.13,1.93-.03,3.16,.6,4.38,2.1,26.33,32.39,52.73,64.72,79.06,97.11,.83,1.03,1.48,2.55,1.48,3.85,.08,31.29,.06,62.59,.06,93.88,0,.56-.06,1.12-.11,2.03Z"/>
  <path onClick={() => props.onFill(5)} fill={props.fillColors[5]} d="M472.83,22.86H249.7V3.94h223.12V22.86Z"/>
  <path onClick={() => props.onFill(6)} fill={props.fillColors[6]} d="M249.57,476.71v18.93H27.23v-18.93H249.57Z"/>
  <path onClick={() => props.onFill(7)} fill={props.fillColors[7]} d="M477.35,27.17h18.79V249.06h-18.79V27.17Z"/>
  <path onClick={() => props.onFill(8)} fill={props.fillColors[8]} d="M22.87,472.22H3.89V252.94H22.87v219.28Z"/>
  <path onClick={() => props.onFill(9)} fill={props.fillColors[9]} d="M245.34,3.85V22.68H79.12c.43-.68,.74-1.3,1.17-1.84,4.18-5.19,8.32-10.42,12.63-15.5,.73-.86,2.21-1.55,3.35-1.55,48.95-.08,97.91-.07,146.86-.06,.66,0,1.31,.07,2.21,.12Z"/>
  <path onClick={() => props.onFill(10)} fill={props.fillColors[10]} d="M253.94,495.8v-19.08h165.4c-3.52,4.4-7.07,8.12-9.74,12.38-3.41,5.43-7.73,6.91-14.1,6.87-45.85-.31-91.7-.17-137.54-.17-1.24,0-2.48,0-4.02,0Z"/>
  <path onClick={() => props.onFill(11)} fill={props.fillColors[11]} d="M477.36,253.4h19v3.11c0,40.47,.02,80.94-.07,121.41,0,1.56-.71,3.38-1.67,4.63-5.53,7.18-11.24,14.22-17.26,21.77V253.4Z"/>
  <path onClick={() => props.onFill(12)} fill={props.fillColors[12]} d="M21.48,96.55c.68,50.56,1.36,101.12,2.04,151.89H3.78c-.06-1.02-.17-2.04-.17-3.05,0-41.63-.03-83.26,.07-124.89,0-1.66,.74-3.6,1.76-4.94,4.95-6.51,10.12-12.86,15.21-19.27l.83,.26Z"/>
  <path onClick={() => props.onFill(13)} fill={props.fillColors[13]} d="M496.37,387.1c0,1.54,0,2.26,0,2.99,0,10.14,.05,20.27-.06,30.41-.01,1.31-.55,2.86-1.37,3.89-18.27,23.09-36.64,46.12-54.91,69.21-1.27,1.6-2.52,2.29-4.58,2.26-8.49-.11-16.97-.05-26.13-.05,29.08-36.31,57.8-72.17,87.05-108.7Z"/>
  <path onClick={() => props.onFill(14)} fill={props.fillColors[14]} d="M183.14,45.85V27.27h133.75v18.58H183.14Z"/>
  <path onClick={() => props.onFill(15)} fill={props.fillColors[15]} d="M27.25,182.9h18.37v133.62H27.25V182.9Z"/>
  <path onClick={() => props.onFill(16)} fill={props.fillColors[16]} d="M454.68,182.9h18.19v133.7h-18.19V182.9Z"/>
  <path onClick={() => props.onFill(17)} fill={props.fillColors[17]} d="M317.05,453.83v18.58c-1.32,0-2.53,0-3.73,0-20.51-.05-41.02-.15-61.54-.15-21.77,0-43.54,.04-65.31,.21-2.91,.02-3.86-.67-3.69-3.67,.28-4.9,.07-9.83,.07-14.98h134.2Z"/>
  <path onClick={() => props.onFill(18)} fill={props.fillColors[18]} d="M88.61,3.73C60.3,39.55,32.35,74.92,3.94,110.87c-.16-1.41-.32-2.18-.32-2.94-.02-9.56-.08-19.12,.06-28.67,.02-1.5,.64-3.25,1.57-4.44C23.22,51.72,41.25,28.67,59.37,5.69c.8-1.01,2.47-1.86,3.76-1.88,8.37-.17,16.75-.09,25.48-.09Z"/>
  <path onClick={() => props.onFill(19)} fill={props.fillColors[19]} d="M321.31,27.12h129.02c0,5.84,.06,11.59-.12,17.33-.01,.45-1.69,1.21-2.61,1.23-8.99,.16-17.98,.23-26.97,.25-31.91,.05-63.83,.07-95.74,.11-1.14,0-2.28,0-3.57,0V27.12Z"/>
  <path onClick={() => props.onFill(20)} fill={props.fillColors[20]} d="M27.19,320.98h18.64v128.45H27.19v-128.45Z"/>
  <path onClick={() => props.onFill(21)} fill={props.fillColors[21]} d="M454.63,49.94h18.19V178.5h-18.19V49.94Z"/>
  <path onClick={() => props.onFill(22)} fill={props.fillColors[22]} d="M49.85,472.19v-18.31H178.61v18.31H49.85Z"/>
  <path onClick={() => props.onFill(23)} fill={props.fillColors[23]} d="M373.29,50.01c10.43,0,20.01-.04,29.58,.06,.87,0,1.99,.71,2.55,1.44,14.59,18.77,29.12,37.59,43.62,56.42,.61,.8,1.23,1.86,1.23,2.81,.09,10.89,.05,21.78-.56,33.01-25.32-31.06-50.65-62.11-76.44-93.74Z"/>
  <path onClick={() => props.onFill(24)} fill={props.fillColors[24]} d="M124.9,449.61c-10.36,0-19.93,.04-29.5-.07-.86,0-1.98-.74-2.52-1.48-13.98-18.85-27.9-37.73-41.79-56.65-.58-.8-.98-1.94-.98-2.92-.02-10.43,.06-20.85,.13-31.28,0-.36,.1-.73,.25-1.77,24.88,31.48,49.42,62.53,74.41,94.15Z"/>
  <path onClick={() => props.onFill(25)} fill={props.fillColors[25]} d="M178.81,27.2v18.54H60.71c.58-.93,.86-1.5,1.25-1.99,4.02-5.07,8.01-10.16,12.14-15.14,.69-.83,2-1.62,3.03-1.62,33.36-.05,66.72,0,100.07,.04,.37,0,.75,.08,1.61,.17Z"/>
  <path onClick={() => props.onFill(26)} fill={props.fillColors[26]} d="M437.79,453.79c-4.89,6.09-9.44,11.84-14.12,17.5-.58,.7-1.76,1.27-2.67,1.27-32.79,0-65.58-.09-98.37-.18-.37,0-.74-.15-1.3-.27v-18.32h116.45Z"/>
  <path onClick={() => props.onFill(27)} fill={props.fillColors[27]} d="M43.88,67.51c.52,37.27,1.03,74.07,1.54,111.03H26.64c0-3.93,.06-7.83,0-11.73-.45-24.52-.94-49.04-1.35-73.57-.02-1.34,.28-2.98,1.06-4,5.35-6.97,10.87-13.82,16.34-20.7,.21-.27,.52-.46,1.19-1.02Z"/>
  <path onClick={() => props.onFill(28)} fill={props.fillColors[28]} d="M454.75,320.93h18.31v3.29c0,27.53,.03,55.07-.07,82.6,0,1.66-.66,3.62-1.67,4.93-5.29,6.9-10.79,13.63-16.57,20.87v-111.69Z"/>
  <path onClick={() => props.onFill(29)} fill={props.fillColors[29]} d="M443.21,495.8c17.8-22.52,35.06-44.38,52.33-66.23,.24,.12,.48,.24,.72,.36,.04,.81,.11,1.63,.11,2.44,0,9.76,.06,19.51-.06,29.27-.02,1.39-.6,3.03-1.46,4.12-7.36,9.33-14.89,18.53-22.26,27.85-1.27,1.6-2.55,2.27-4.6,2.24-7.92-.11-15.85-.05-24.77-.05Z"/>
  <path onClick={() => props.onFill(30)} fill={props.fillColors[30]} d="M55.54,3.9C38.29,25.92,21.32,47.59,3.95,69.78c-.19-1.15-.32-1.59-.32-2.02-.01-10.14-.05-20.29,.05-30.43,.01-1.14,.62-2.43,1.34-3.37C12.18,24.48,19.4,15.04,26.71,5.67c.72-.92,2.11-1.84,3.2-1.86,8.03-.16,16.06-.08,24.09-.08,.35,0,.69,.07,1.53,.17Z"/>
  <path onClick={() => props.onFill(31)} fill={props.fillColors[31]} d="M255.45,250.58c7.6-7.6,15.65-15.65,23.57-23.57,7.86,7.89,15.89,15.96,23.73,23.83-7.76,7.74-15.8,15.75-23.56,23.48-7.74-7.74-15.82-15.82-23.75-23.74Z"/>
  <path onClick={() => props.onFill(32)} fill={props.fillColors[32]} d="M227.81,277.97c7.81-7.79,15.8-15.76,23.58-23.52,7.95,7.97,16.04,16.08,23.93,23.99-7.62,7.64-15.66,15.7-23.48,23.54l-24.03-24.01Z"/>
  <path onClick={() => props.onFill(33)} fill={props.fillColors[33]} d="M227.75,223.39c7.89-7.87,15.87-15.84,23.61-23.56,7.91,7.88,16,15.94,23.91,23.82-7.74,7.71-15.78,15.73-23.64,23.56-7.78-7.76-15.82-15.79-23.88-23.83Z"/>
  <path onClick={() => props.onFill(34)} fill={props.fillColors[34]} d="M248.02,250.96c-7.83,7.83-15.87,15.87-23.76,23.75-7.9-7.89-15.94-15.92-23.8-23.77,7.9-7.88,15.94-15.91,23.78-23.74,7.79,7.79,15.88,15.86,23.78,23.76Z"/>
  <path onClick={() => props.onFill(35)} fill={props.fillColors[35]} d="M450.18,102.69c-13.75-17.77-27-34.92-40.61-52.51h40.61v52.51Z"/>
  <path onClick={() => props.onFill(36)} fill={props.fillColors[36]} d="M88.88,449.5H49.95v-51.47l.64-.37c12.64,17.12,25.29,34.23,38.29,51.84Z"/>
  <path onClick={() => props.onFill(37)} fill={props.fillColors[37]} d="M496.21,3.97V22.78h-18.93V3.97h18.93Z"/>
  <path onClick={() => props.onFill(38)} fill={props.fillColors[38]} d="M22.78,495.66H3.84v-18.95H22.78v18.95Z"/>
  <path onClick={() => props.onFill(39)} fill={props.fillColors[39]} d="M27.14,472.19v-18.28h18.35v18.28H27.14Z"/>
  <path onClick={() => props.onFill(40)} fill={props.fillColors[40]} d="M454.57,45.55V27.24h18.3v18.31h-18.3Z"/>
  <path onClick={() => props.onFill(41)} fill={props.fillColors[41]} d="M496.22,471.31v24.32h-20.2c6.69-8.4,13.13-16.49,19.57-24.58l.64,.26Z"/>
  <path onClick={() => props.onFill(42)} fill={props.fillColors[42]} d="M3.73,27.75V3.91H22.68C16.48,12.02,10.39,20,4.29,27.97l-.56-.21Z"/>

    </g>
            </svg>
        </div>
    )
}