import React from 'react';
//import BasicModal from "./BasicModal"

class InstalationOptions extends React.Component {

    
    render() {


        const data = this.props.data

        function Installation() {


            if(data === 'easy') {
                return (
                    <><img src="/easy.jpg" alt="easy" style={{ width: '100%' }} />
                    <p className="installation">The stained glass panel is set against your existing interior window glass and then held in place by tacking in quarter-round molding.</p></>                    
                    );
            }else if(data === 'hang') {
                return (
                <><img src="/hang.jpg" alt="hang" style={{width: '100%'}}/>
                    <p className="installation">The stained glass is delivered in a single pane format and affixed with hooks for easy hanging (hardware included).</p></>               
                );
            }else if(data === 'insulated') {
                return (
                    <>
    <div class="triple">
                    <img src="/duraseal.jpg" alt="insulated" style={{width: '100%'}}/>
{/*<BasicModal></BasicModal>*/}
  <p class="quanex">Highest quality Seal available on the market <br></br><span> Duraseal® warm-edge spacer</span></p>
</div>
                    </>                    
                    );
            }else if (data === 'vinylframed') {
                return (
                    <><img src="/vinyl-frame.jpg" alt="framed" style={{width: '100%'}}/>
                    <p className="installation">The stained glass is fully insulated (triple paned) and pre-installed in a vinyl frame- Install ready!</p></>
                    );
            }else if (data === 'woodframed') {
                return (
                    <><img src="/wood-frame.jpg" alt="framed" style={{width: '100%'}}/>
                    <p className="installation">The stained glass is fully insulated (triple paned) and pre-installed in a wood frame- Install ready!</p></>
                    );
            }
        }

        return (
            Installation()
        );
    }
  }
    

export default InstalationOptions;