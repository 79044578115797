import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import swal from 'sweetalert';

export default function SignUp() {
    const [form, setForm] = useState({ username: '', password: '' });

    const dispatch = useDispatch();
    let history = useHistory();

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    }
    
    const handleSubmit = (e) => {
        e.preventDefault();
        const config = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(form)
        };
        fetch('https://test-api.stainedglassusa.com/users', config)
        .then(r => r.json())
        .then(user => {
            if (user.error) {
                swal("Username already taken", "Please try a different username", "error");
                return;
            }
            dispatch({
                type: 'SET_USER',
                payload: user
            });
            swal("Sign Up Success", `Welcome ${form.username}!`, "success");
            history.push("/mygallery");
        });
    }

    const handleFacebookSignUp = () => {
        // Add your Facebook Sign Up logic here
    }

    const handleGoogleSignUp = () => {
        // Add your Google Sign Up logic here
    }

    return (
        <div className="form-page" style={{ minHeight: '75vh' }}>
            <h3 className="login-signup-title">Sign Up</h3>
            <form onSubmit={handleSubmit} className="form">
                <input
                    type="text"
                    name="username"
                    value={form.username}
                    onChange={handleChange}
                    placeholder="Username"
                    style={{ marginBottom: "10px" }}
                    className="input"
                />
                <input
                    type="password"
                    name="password"
                    value={form.password}
                    onChange={handleChange}
                    placeholder="Password"
                    className="input"
                />
                <input type="submit" id="submit-button" value="Submit" />
            </form>
            <hr className="form-divider" />
            <div id="change-form-button">
                <button onClick={handleFacebookSignUp}>
                    Sign Up with Facebook
                </button>
                <button onClick={handleGoogleSignUp}>
                    Sign Up with Google
                </button>
                <button onClick={() => history.push("/login")}>
                    Already have an account? Log in
                </button>
            </div>
        </div>
    );
}