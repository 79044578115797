import React from 'react';
import Patterns from './patterns';

export default function Put01(props) {
    
    return (
        <div className={`Put01-container ${props.size}`}> 
 
            <svg  xmlns="http://www.w3.org/2000/svg" id={props.id} width="500" height="500" viewBox="0 0 500 500">
                
            <Patterns /> 
                                               
            <g className="hummingbird-image" >

<path id="background" fill="black"  d="m0,500V0h752v752H0Z"/>



<path onClick={() => props.onFill(1)} fill={props.fillColors[1]}  d="M162.29,252.5c19.65,0,39.18,0,58.83-.11,2.74,0,4.61,.77,6.59,2.74,6.15,6.26,12.29,12.51,18.55,18.66,1.43,1.32,2.09,2.74,2.09,4.72v119.64c0,2.2-.66,2.96-2.85,2.96-33.59-.11-67.06,0-100.65-.11-1.54,0-3.4-.77-4.5-1.87-13.17-13.06-26.34-26.23-39.51-39.29-1.43-1.43-1.98-2.96-1.98-5.05,.11-33.15,.11-66.18,.11-99.33,0-2.63,.88-3.18,3.29-3.07,19.98,.22,40.06,.11,60.04,.11Z"/>
<path onClick={() => props.onFill(2)} fill={props.fillColors[2]}  d="M248.23,162.39v59.82c0,2.2-.66,3.73-2.2,5.16-6.59,6.37-12.95,13.06-19.65,19.43-.99,.99-2.63,1.76-4.06,1.76-40.28,.11-80.45,.11-120.73,.11-2.09,0-2.85-.66-2.85-2.74,.11-32.27,0-64.43,.11-96.7,0-1.32,.77-3.07,1.76-4.06,14.71-14.82,29.42-29.63,44.23-44.23,1.1-1.1,2.96-1.87,4.5-1.87,31.83-.11,63.66,0,95.49-.11,2.41,0,3.18,.66,3.18,3.18-.11,20.09,0,40.28,0,60.37l.22-.11Z"/>
<path onClick={() => props.onFill(3)} fill={props.fillColors[3]}  d="M252.19,162.06v-60.59c0-1.76,.55-2.63,2.3-2.41h95.49c2.09,0,3.73,.55,5.27,2.09,14.49,14.6,29.2,29.2,43.68,43.79,.99,.99,1.76,2.85,1.76,4.28,.11,32.16,.11,64.32,.11,96.48,0,2.2-.66,2.74-2.74,2.74-10.1-.11-20.31,0-30.4,0-29.63,.11-59.16,.11-88.79,.33-2.09,0-3.51-.55-5.05-2.09-6.37-6.48-12.84-12.95-19.32-19.32-1.65-1.54-2.2-3.29-2.2-5.49,.11-18.99,0-37.98,0-56.96v-2.85h-.11Z"/>
<path onClick={() => props.onFill(4)} fill={props.fillColors[4]}  d="M252.29,338.23v-59.05c0-2.2,.66-3.84,2.3-5.49,6.48-6.26,12.84-12.73,19.21-19.1,1.43-1.43,2.96-1.98,5.05-1.98,39.51,0,78.92-.11,118.43-.22,3.62,0,3.73,0,3.73,3.62v94.39c0,2.3-.77,3.95-2.41,5.6-14.49,14.38-28.87,28.76-43.24,43.24-1.43,1.43-2.85,1.98-4.72,1.98-31.61,0-63.33-.11-94.94,0-2.74,0-3.29-.88-3.29-3.4-.11-19.87-.11-39.73-.11-59.6Z"/>
<path onClick={() => props.onFill(5)} fill={props.fillColors[5]}  d="M173.49,477.4H102.04c-2.52,0-3.18-.66-3.18-3.18,.11-7.79,0-15.7,.11-23.49,0-1.43,.77-3.18,1.76-4.28,13.17-13.28,26.45-26.56,39.73-39.73,.99-.99,2.63-1.65,4.06-1.65,33.7-.11,67.39-.11,101.2-.11,2.09,0,2.63,.66,2.63,2.74-.11,22.39-.11,44.67,0,67.06,0,2.2-.77,2.63-2.85,2.63h-72Z"/>
<path onClick={() => props.onFill(6)} fill={props.fillColors[6]}  d="M22.79,400.68V255.91q0-3.4,3.4-3.4c21.84,0,43.68,0,65.53-.11,2.63,0,3.29,.77,3.29,3.4-.11,33.15-.11,66.4-.11,99.55,0,1.87-.55,3.18-1.87,4.5-13.06,13.06-26.23,26.12-39.18,39.18-1.43,1.43-2.96,2.09-4.94,2.09-7.79-.11-15.7-.22-23.49-.22-.88,0-1.65-.11-2.63-.22Z"/>
<path onClick={() => props.onFill(7)} fill={props.fillColors[7]}  d="M477.3,248.22h-3.07c-22.06,0-44.23,0-66.29,.11-2.63,0-3.29-.77-3.29-3.4,.11-31.72,.11-63.44,0-95.16,0-2.2,.55-3.73,2.09-5.27,14.6-14.49,29.09-28.98,43.57-43.57,1.43-1.43,2.85-2.2,4.94-2.09,6.26,.22,12.51,.33,18.77,.22,2.41,0,3.18,.66,3.18,3.18-.11,36.22,0,72.33,0,108.55v34.24c.11,1.1,.11,1.98,.11,3.18Z"/>
<path onClick={() => props.onFill(8)} fill={props.fillColors[8]}  d="M477.19,252.07v148.5c-.66,.11-1.21,.33-1.76,.33-6.91,.11-13.72,.22-20.63,.11-1.32,0-3.07-.77-4.06-1.65-14.71-14.6-29.42-29.31-44.01-44.12-1.1-1.1-1.76-2.96-1.76-4.5-.11-10.76-.11-21.51-.11-32.27,0-20.85-.11-41.82-.22-62.67q0-3.51,3.62-3.51c21.84-.11,43.68-.11,65.53-.22,.99,0,1.98,0,3.4,0Z"/>
<path onClick={() => props.onFill(9)} fill={props.fillColors[9]}  d="M174.04,22.89h71.45c2.2,0,2.63,.77,2.63,2.85,0,22.17,0,44.34,.11,66.51,0,2.41-.77,2.96-3.07,2.96-31.94-.11-63.77-.11-95.71,0-2.09,0-3.51-.66-4.94-2.09-14.27-14.38-28.65-28.76-43.03-43.03-1.65-1.65-2.3-3.4-2.3-5.71,.11-6.26,.22-12.51,.11-18.77,0-2.2,.66-2.74,2.74-2.74,23.93,0,47.96,0,72,0Z"/>
<path onClick={() => props.onFill(10)} fill={props.fillColors[10]}  d="M22.79,173.92V102.46c0-2.41,.66-3.29,3.18-3.18,6.37,.22,12.73,0,19.1,.11,1.43,0,3.18,.77,4.28,1.76,14.71,14.6,29.31,29.2,43.9,43.9,.99,.99,1.65,2.63,1.76,3.95,.11,32.38,.11,64.65,.11,97.03,0,1.98-.66,2.63-2.63,2.63-22.28-.11-44.56-.11-66.84,0-2.2,0-2.74-.77-2.74-2.85-.11-23.93-.11-47.96-.11-71.89Z"/>
<path onClick={() => props.onFill(11)} fill={props.fillColors[11]}  d="M326.49,477.4h-70.9c-2.41,0-3.29-.44-3.29-3.07,.11-22.06,.11-44.23,0-66.29,0-2.3,.55-3.18,3.07-3.07,31.72,.11,63.44,.11,95.16,0,1.98,0,3.4,.66,4.72,1.98,14.49,14.6,29.2,29.2,43.68,43.79,1.1,1.1,1.76,2.96,1.87,4.5,.22,6.26,0,12.51,.11,18.77,.11,2.52-.55,3.29-3.29,3.29-23.71,.11-47.42,.11-71.12,.11Z"/>
<path onClick={() => props.onFill(12)} fill={props.fillColors[12]}  d="M326.6,22.89h71.23c2.41,0,3.29,.66,3.18,3.18-.22,6.48-.11,12.84-.22,19.32,0,1.21-.66,2.74-1.54,3.62-14.82,14.93-29.74,29.85-44.67,44.67-.88,.88-2.52,1.54-3.84,1.54-31.94,.11-63.99,0-95.93,.11-2.2,0-2.74-.77-2.74-2.85,.11-22.28,.11-44.56,0-66.84,0-2.2,.77-2.74,2.85-2.74h71.67Z"/>
<path onClick={() => props.onFill(13)} fill={props.fillColors[13]}  d="M22.6,18.9c0-5.1,0-10.1,.1-15.1,0-.4,.8-1,1.3-1.2,.6-.2,1.4-.1,2.1-.1H473.8q3.5,0,3.5,3.6v12.8H22.6Z"/>
<path onClick={() => props.onFill(14)} fill={props.fillColors[14]}  d="M2.5,249.8V26.3c0-4-.2-3.5,3.5-3.5H15.9q3.1,0,3.1,3.3V454c0,6.9-.1,13.8,0,20.7,0,1.9-.5,2.6-2.5,2.6-3.9-.1-7.8-.1-11.8,0-1.9,.1-2.5-.7-2.3-2.5,.1-1.9,0-3.8,0-5.6,.1-73.2,.1-146.3,.1-219.4Z"/>
<path onClick={() => props.onFill(15)} fill={props.fillColors[15]}  d="M480.9,477.2V24.9c0-1.7,.5-2.3,2.3-2.3,4.1,.1,8.1,.1,12.2,0,1.7,0,2.3,.6,2.2,2.2-.1,.7,0,1.4,0,2.1V472.9c0,4.6,.4,4.1-4.2,4.1-4.2,.2-8.2,.2-12.5,.2Z"/>
<path onClick={() => props.onFill(16)} fill={props.fillColors[16]}  d="M477.3,497.3c-1.2,.1-2.1,.1-3,.1-149.6,0-299.2,0-448.8,.1-2.4,0-3.1-.7-3-3.1,.2-3.6,.2-7.2,0-10.8-.1-2,.5-2.7,2.6-2.7,7.1,.1,14.3,0,21.4,0H453.9c7,0,13.9,.1,20.9,0,2,0,2.5,.6,2.5,2.6-.1,4.5,0,9,0,13.8Z"/>
<path onClick={() => props.onFill(17)} fill={props.fillColors[17]}  d="M94.79,477.29c-1.21,.11-2.09,.11-2.85,.11H25.64c-2.2,0-2.96-.55-2.96-2.85,.11-22.28,.11-44.45,0-66.73,0-2.09,.55-2.85,2.74-2.85,7.9,.11,15.81,0,23.71,.11,1.43,0,3.07,.66,4.06,1.65,13.39,13.28,26.78,26.67,40.17,40.06,.66,.66,1.32,1.54,1.32,2.3,.22,9.33,.11,18.66,.11,28.21Z"/>
<path onClick={() => props.onFill(18)} fill={props.fillColors[18]}  d="M477.08,404.96v72.44h-69.15c-2.41,0-3.29-.66-3.18-3.18,.22-6.37,0-12.73,.11-19.1,0-1.43,.77-3.18,1.76-4.28,14.6-14.82,29.31-29.42,44.12-44.12,.88-.88,2.3-1.76,3.51-1.76,7.24-.22,14.38-.22,21.62-.33,.33,.11,.66,.22,1.21,.33Z"/>
<path onClick={() => props.onFill(19)} fill={props.fillColors[19]}  d="M477.08,23.11c0,.99,.11,1.76,.11,2.63V92.26c0,3.29,0,3.07-3.4,2.96-6.04-.22-12.07-.22-18.11-.11-2.3,0-3.95-.66-5.71-2.3-14.38-14.49-28.87-28.98-43.35-43.35-1.54-1.54-2.2-3.07-2.09-5.27,.22-6.37,.22-12.73,.33-19.1,0-1.54,.44-2.3,2.2-2.3,22.94,0,45.88,0,68.93,.11,.22,0,.55,.11,1.1,.22Z"/>
<path onClick={() => props.onFill(20)} fill={props.fillColors[20]}  d="M22.79,59.11V25.63c0-2.09,.55-2.74,2.74-2.74,22.39,.11,44.67,.11,67.06,0,2.2,0,2.74,.77,2.74,2.85-.11,6.48,0,13.06-.22,19.54,0,1.32-.66,2.96-1.54,3.84-14.71,14.93-29.63,29.74-44.45,44.45-.77,.77-2.2,1.43-3.29,1.43-6.59,.22-13.28,.22-19.87,.33-2.3,.11-3.18-.55-3.07-3.07,0-10.98-.11-22.06-.11-33.15Z"/>
<path onClick={() => props.onFill(21)} fill={props.fillColors[21]}  d="M249.77,272.48c-1.65-1.87-3.18-3.84-4.83-5.6-4.83-4.94-9.66-9.77-14.6-14.6-1.32-1.32-1.54-2.09-.11-3.51,6.26-6.04,12.29-12.18,18.44-18.33,1.32-1.43,2.2-1.32,3.51,0,6.04,6.15,12.18,12.29,18.33,18.44,.88,.88,1.76,1.65,.44,2.96-6.7,6.59-13.28,13.17-19.87,19.76-.22,.22-.44,.22-.66,.33-.22,.22-.44,.44-.66,.55Z"/>
<path onClick={() => props.onFill(22)} fill={props.fillColors[22]}  d="M403.1,357.76c7.02,6.91,14.05,13.94,20.96,20.96-7.02,7.02-14.16,14.05-21.18,20.96-6.91-6.91-13.94-13.94-20.85-20.96,6.81-6.91,13.94-13.94,21.07-20.96Z"/>
<path onClick={() => props.onFill(23)} fill={props.fillColors[23]}  d="M428.02,381.91c4.83,4.94,10.1,10.21,15.15,15.48,1.87,1.87,4.83,3.84,4.94,5.93,.11,1.76-3.07,3.73-4.83,5.6-5.16,5.27-10.43,10.43-15.48,15.59-7.24-7.24-14.27-14.38-21.07-21.29,6.7-6.81,13.83-13.94,21.29-21.29Z"/>
<path onClick={() => props.onFill(24)} fill={props.fillColors[24]}  d="M100.5,96.98c6.91-6.91,13.94-14.05,20.85-20.96,7.02,7.02,14.05,14.05,20.96,20.96-6.91,6.91-14.05,13.94-20.96,20.96-6.81-6.81-13.83-13.94-20.85-20.96Z"/>
<path onClick={() => props.onFill(25)} fill={props.fillColors[25]}  d="M381.26,120.68c1.98-1.76,4.17-3.62,6.15-5.71,4.61-4.5,9.11-9.11,13.72-13.61,.88-.88,1.54-1.65,2.74-.44,6.59,6.7,13.17,13.28,19.76,19.87,.11,.11,.11,.33,.11,.22-7.13,7.13-14.27,14.16-21.18,21.07-6.7-6.7-13.72-13.72-21.29-21.4Z"/>
<path onClick={() => props.onFill(26)} fill={props.fillColors[26]}  d="M97.32,94.01c-7.13-7.13-14.16-14.27-21.4-21.51,.33-.33,.88-.99,1.54-1.65,5.93-5.93,11.74-11.74,17.56-17.67,1.43-1.43,2.3-1.76,3.84-.11,5.93,6.15,11.96,12.07,18,18,1.32,1.32,1.43,2.09,0,3.51-6.15,6.04-12.18,12.07-18.22,18.22-.44,.44-.88,.88-1.32,1.21Z"/>
<path onClick={() => props.onFill(27)} fill={props.fillColors[27]}  d="M399.26,403.2l-21.62,21.62c-.99-1.32-1.87-2.85-3.18-4.06-5.27-5.38-10.65-10.76-16.02-16.02-.88-.88-1.65-1.54-.44-2.74,6.7-6.7,13.39-13.5,19.98-20.09,7.35,7.24,14.38,14.38,21.29,21.29Z"/>
<path onClick={() => props.onFill(28)} fill={props.fillColors[28]}  d="M381.81,427.79l21.07-21.07c6.91,6.91,14.05,14.05,21.4,21.51-2.09,1.98-4.28,3.95-6.48,6.15-4.5,4.39-8.89,8.89-13.28,13.28-.88,.88-1.54,1.54-2.74,.44-6.59-6.7-13.17-13.28-19.76-19.98-.22-.22-.22-.33-.22-.33Z"/>
<path onClick={() => props.onFill(29)} fill={props.fillColors[29]}  d="M447.88,97.2c-6.7,6.7-13.72,13.72-20.63,20.63-6.91-6.91-14.05-13.94-20.96-20.85,6.91-6.91,13.94-13.83,20.85-20.74,6.81,6.91,13.83,13.94,20.74,20.96Z"/>
<path onClick={() => props.onFill(30)} fill={props.fillColors[30]}  d="M97.1,100.82c6.91,6.91,14.05,13.94,21.51,21.29-3.84,3.73-7.68,7.46-11.52,11.2-2.85,2.85-5.71,5.71-8.56,8.56-.88,.88-1.54,1.21-2.52,.11-6.7-6.7-13.5-13.5-20.09-19.98,7.13-7.24,14.27-14.38,21.18-21.18Z"/>
<path onClick={() => props.onFill(31)} fill={props.fillColors[31]}  d="M72.73,76.23c6.91,6.91,13.94,13.94,20.74,20.85-7.02,6.91-14.05,13.94-20.96,20.74-6.7-6.7-13.72-13.83-20.74-20.74,6.91-6.81,13.94-13.83,20.96-20.85Z"/>
<path onClick={() => props.onFill(32)} fill={props.fillColors[32]}  d="M378.19,117.72c-6.81-6.7-13.94-13.83-20.85-20.74,6.91-6.91,14.05-13.94,20.96-20.85,6.81,6.91,13.83,14.05,20.74,21.07-6.7,6.59-13.83,13.61-20.85,20.52Z"/>
<path onClick={() => props.onFill(33)} fill={props.fillColors[33]}  d="M402.88,93.79c-7.13-7.13-14.27-14.16-21.07-20.96,7.02-7.02,14.05-14.05,21.29-21.29,.33,.22,.99,.77,1.54,1.43,5.93,5.93,11.74,11.85,17.67,17.67,1.43,1.43,1.76,2.3,.11,3.84-6.15,5.93-12.07,11.96-18.11,18-.44,.55-.99,.99-1.43,1.32Z"/>
<path onClick={() => props.onFill(34)} fill={props.fillColors[34]}  d="M73.94,422.85c-1.1-1.54-2.09-3.18-3.4-4.5-4.5-4.61-9.11-9.11-13.61-13.72-.88-.88-1.54-1.65-.33-2.74,5.82-5.82,11.63-11.63,17.56-17.45,.22-.22,.44-.22,1.32-.99,1.76,2.09,3.51,4.17,5.38,6.15,3.84,3.95,7.9,7.9,11.85,11.85,.88,.88,1.54,1.54,.33,2.74-5.93,5.82-11.85,11.74-17.67,17.56-.11,.11-.33,.11-.44,.22-.44,.33-.66,.55-.99,.88Z"/>
<path onClick={() => props.onFill(35)} fill={props.fillColors[35]}  d="M115.97,425.81c-2.74,2.63-5.6,5.49-8.45,8.23-3.07,3.07-6.04,6.15-9.11,9.11-.55,.44-2.09,.66-2.41,.33-6.04-5.93-11.96-11.85-17.89-17.89-.11-.11-.11-.33-.11-.22,6.37-6.37,12.73-12.62,18.88-18.77,6.04,6.15,12.4,12.51,19.1,19.21Z"/>
<path onClick={() => props.onFill(36)} fill={props.fillColors[36]}  d="M97.1,400.13c-6.48-6.59-12.73-12.84-19.21-19.32,.33-.33,.77-.99,1.21-1.54,5.38-5.38,10.76-10.65,16.02-16.02,1.32-1.32,2.2-1.43,3.51-.11,5.27,5.38,10.65,10.76,16.02,16.02,.77,.77,1.76,1.43,.55,2.74-6.04,6.04-12.07,12.07-18.11,18.22Z"/>
<path onClick={() => props.onFill(37)} fill={props.fillColors[37]}  d="M119.49,422.08c-6.48-6.48-12.73-12.73-18.77-18.88,6.15-6.04,12.51-12.4,19.1-18.88,5.6,5.6,11.63,11.63,17.67,17.78,.33,.33,.33,1.54,0,1.87-6.04,6.15-12.07,12.18-18,18.11Z"/>
<path onClick={() => props.onFill(38)} fill={props.fillColors[38]}  d="M480.9,19.1c0-5.3,0-10.1,.1-15,0-.5,.9-1.5,1.4-1.5,4.5-.1,8.9-.1,13.4,0,.6,0,1.6,1,1.6,1.6,.1,4.5,.1,8.9,0,13.4,0,.5-.8,1.4-1.3,1.4-5,.1-9.9,.1-15.2,.1Z"/>
<path onClick={() => props.onFill(39)} fill={props.fillColors[39]}  d="M2.6,497.4c0-5.3,0-10.2,.1-15.1,0-.5,.9-1.3,1.4-1.3,4.5-.1,9.1-.1,13.6,0,.5,0,1.4,1,1.4,1.5,.1,4.4,.1,8.8,0,13.1,0,.6-.9,1.6-1.3,1.6-5,.3-9.9,.2-15.2,.2Z"/>
<path onClick={() => props.onFill(40)} fill={props.fillColors[40]}  d="M2.6,18.9c0-5.1,0-10,.1-14.9,0-.5,.8-1.4,1.3-1.4,4.6-.1,9.2-.1,13.8,0,.4,0,1.2,.8,1.3,1.3,.1,5,.1,10,.1,15.1-5.7-.1-11-.1-16.6-.1Z"/>
<path onClick={() => props.onFill(41)} fill={props.fillColors[41]}  d="M481,497.4v-16.4h10.8q5.7,0,5.7,5.7c0,3,.1,6-.1,8.9,0,.6-.8,1.8-1.2,1.8-5,.1-10,0-15.2,0Z"/>

    </g>
            </svg>
        </div>
    )
}