import React from 'react';
import './Mountainviewsquare';
import Patterns from './patterns';

export default function Mountainviewsquare(props) {
    
    return (
        <div className={`mountainviewsquare-container ${props.size}`}> 
 
            <svg  xmlns="http://www.w3.org/2000/svg" id={props.id} width="500" height="500" viewBox="0 0 500 500">
                
              <Patterns />                      
            <g className="hummingbird-image" >

<path id="background" fill="black"  d="m0,500V0h500v500H0Z"/>

  <path onClick={() => props.onFill(1)} fill={props.fillColors[1]} d="m252.4,29.61h217.47v218.44c-5.11,0-10.29-.03-15.47,0-34.25.21-68.5-.02-102.73.82-18.16.44-34.06-5.32-49.02-14.5-20.05-12.31-34.17-29.9-43.44-51.49-.84-1.95-1.76-3.87-2.63-5.81-3.5-7.83-5.62-15.71-5.46-24.62.73-39.98.81-79.96,1.12-119.95,0-.81.08-1.62.15-2.88Z"/>
  <path onClick={() => props.onFill(2)} fill={props.fillColors[2]} d="m251.12,471.21c0-2.17,0-3.96,0-5.76,0-40.53-.05-81.06.09-121.59.01-3.5.72-7.2,2.01-10.45,5.5-13.91,11.44-27.61,20.67-39.59,16.65-21.61,38.57-34.71,64.97-40.37,4.27-.91,8.87-.3,13.31-.32,33.78-.13,67.56-.21,101.34-.39,5.23-.03,10.46-.47,16.17-.74v219.21h-218.56Z"/>
  <path onClick={() => props.onFill(3)} fill={props.fillColors[3]} d="m30.51,29.49h217.76c0,1.59,0,3.16,0,4.72-.02,16.58.07,33.17-.08,49.75-.22,26.01-.44,52.02-1.08,78.02-.1,4.06-2.22,8.13-3.74,12.07-7.36,18.95-17.4,36.08-32.7,49.86-14.3,12.88-31.11,20.54-49.47,25.26-.78.2-1.64.08-2.47.08-41.97,0-83.94,0-125.92,0-.65,0-1.3-.09-2.3-.17V29.49Z"/>
  <path onClick={() => props.onFill(4)} fill={props.fillColors[4]} d="m247.46,471.34H30.48v-218.79c1.94,0,3.71,0,5.48,0,36.7,0,73.41.41,110.1-.16,19.47-.3,36.39,6.19,52.29,16.33,22.03,14.05,35.42,34.9,45.08,58.66,2.71,6.67,4.2,13.25,4.15,20.54-.25,39.15-.12,78.31-.12,117.46,0,1.81,0,3.62,0,5.97Z"/>
  <path onClick={() => props.onFill(5)} fill={props.fillColors[5]} d="m2.69,325.71h25.41c0,1.97,0,3.73,0,5.49,0,44.88-.07,89.76.1,134.64.02,4.59-1.17,6.08-5.78,5.8-6.42-.4-12.89-.1-19.73-.1v-145.82Z"/>
  <path onClick={() => props.onFill(6)} fill={props.fillColors[6]} d="m27.79,322.88H2.61v-145.35h25.18v145.35Z"/>
  <path onClick={() => props.onFill(7)} fill={props.fillColors[7]} d="m27.84,174.86H2.62V29.69h25.22v145.17Z"/>
  <path onClick={() => props.onFill(8)} fill={props.fillColors[8]} d="m472.87,177.38h24.49v145.43h-24.49v-145.43Z"/>
  <path onClick={() => props.onFill(9)} fill={props.fillColors[9]} d="m497.3,174.94h-24.48V29.67h24.48v145.27Z"/>
  <path onClick={() => props.onFill(10)} fill={props.fillColors[10]} d="m469.85,2.93v24.14h-144.87V2.93h144.87Z"/>
  <path onClick={() => props.onFill(11)} fill={props.fillColors[11]} d="m177.59,27.02V2.74h144.73v24.28h-144.73Z"/>
  <path onClick={() => props.onFill(12)} fill={props.fillColors[12]} d="m30.39,27.03V2.73h144.55v24.31H30.39Z"/>
  <path onClick={() => props.onFill(13)} fill={props.fillColors[13]} d="m497.5,325.8v145.35h-24.6v-145.35h24.6Z"/>
  <path onClick={() => props.onFill(14)} fill={props.fillColors[14]} d="m30.41,473.75h144.61v23.45H30.41v-23.45Z"/>
  <path onClick={() => props.onFill(15)} fill={props.fillColors[15]} d="m322.23,497.24h-144.6v-23.31h144.6v23.31Z"/>
  <path onClick={() => props.onFill(16)} fill={props.fillColors[16]} d="m469.78,497.22h-144.69v-23.36h144.69v23.36Z"/>
  <path onClick={() => props.onFill(17)} fill={props.fillColors[17]} d="m249.29,333.58c-4.55-9.69-8.16-18.74-12.92-27.13-4.79-8.42-10.73-16.19-16.1-24.14,9.42-9.79,19.36-20.12,28.92-30.05,10.13,10.15,19.93,19.95,29.9,29.94-13.82,13.83-22.17,32.03-29.8,51.38Z"/>
  <path onClick={() => props.onFill(18)} fill={props.fillColors[18]} d="m218.36,220.55c10.1,9.99,19.85,19.64,29.22,28.92-10.14,10.27-20.24,20.5-30.37,30.76-19.55-16.98-27.12-21.29-51.85-29.06,20.94-5.71,38.61-15.41,53-30.61Z"/>
  <path onClick={() => props.onFill(19)} fill={props.fillColors[19]} d="m220.75,218.09c13.27-13.95,20.76-31.14,28.22-49.74,7.91,18.83,15.36,36.92,29.24,50.58-9.46,9.52-18.86,18.97-28.18,28.34-9.29-9.25-19.07-19-29.29-29.18Z"/>
  <path onClick={() => props.onFill(20)} fill={props.fillColors[20]} d="m331.76,251.22c-19.39,5.3-36.03,14.84-50.2,28.44-9.82-9.89-19.52-19.66-28.85-29.06,9.19-9.29,18.58-18.79,28.31-28.63,13.97,14.08,31.13,23.73,50.75,29.25Z"/>
  <path onClick={() => props.onFill(21)} fill={props.fillColors[21]} d="m28.09,27.15H2.57V2.51c7.79,0,15.65-.07,23.5.12.69.02,1.91,1.74,1.93,2.7.16,7.11.09,14.22.09,21.82Z"/>
  <path onClick={() => props.onFill(22)} fill={props.fillColors[22]} d="m472.8,2.66h24.53v24.45h-24.53V2.66Z"/>
  <path onClick={() => props.onFill(23)} fill={props.fillColors[23]} d="m2.5,497.48v-23.61h25.55c0,7.12.09,14.34-.14,21.56-.02.72-2.03,1.94-3.14,1.97-7.25.18-14.5.09-22.27.09Z"/>
  <path onClick={() => props.onFill(24)} fill={props.fillColors[24]} d="m472.91,473.76h24.34v23.37h-24.34v-23.37Z"/>
           
    </g>
            </svg>
        </div>
    )
}