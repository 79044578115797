import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import { glassData } from "./data";
import Button from '@material-ui/core/Button';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

class SelectedColors extends React.Component {

  state={
    openModal : false
}

onClickButton = e =>{
    e.preventDefault()
    this.setState({openModal : true})
}

onCloseModal = ()=>{
    this.setState({openModal : false})
}  

  render() {

    return (

      <div className="SelectedColor">
      {
        glassData
        .filter(glassData => glassData.id === this.props.currentColor)
        .map(glassData => 
        
        <div key={glassData.id}>
                  <h2 id="description-heading" > Currently selected Color</h2>
        <hr />   
                  <Grid container spacing={2}>
          <Grid item>
            <ButtonBase>
            <svg width="150" height="150">
                <rect width="150" height="150" fill={this.props.currentColor}></rect>
                </svg>
            </ButtonBase>
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography gutterBottom variant="subtitle1">
                {glassData.color}
                </Typography>
                <Typography variant="body2" gutterBottom>
                {glassData.type}
                </Typography>
                <Button variant="contained" color="secondary" onClick={this.onClickButton}>
                Learn More
</Button>
                <Modal open={this.state.openModal} onClose={this.onCloseModal}>
                    <h1>{glassData.color}</h1>
                    <h2>{glassData.type}</h2>
                    <p>{glassData.description}</p>
                    <div>
                    <svg width="1080" height="1080">
                <rect width="1080" height="1080" fill={this.props.currentColor}></rect>
                </svg>
                    </div>

                </Modal>  
              </Grid>
            </Grid>
          </Grid>
        </Grid> 
          </div>)
  
      }
      </div>
      )
    }
  }
  export default SelectedColors;