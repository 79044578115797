import React from 'react';


export default function Mountainviewoctagon(props) {
    
    return (
        <div className={`mountainviewoctagon-container ${props.size}`}> 
 
            <svg  xmlns="http://www.w3.org/2000/svg" id={props.id} width="500" height="500" viewBox="0 0 500 500">

            <pattern id="01_yellow" width="100%" height="100%">
                      <image xlinkHref="/01_yellow.jpg"/>
              </pattern>
          <pattern id="02_lightAmber" width="100%" height="100%">
              <image xlinkHref="/02_lightAmber.jpg"/>
          </pattern>
          <pattern id="03_darkAmber" width="100%" height="100%">
              <image xlinkHref="/03_darkAmber.jpg"/>
          </pattern>
          <pattern id="04_cherry" width="100%" height="100%">
              <image xlinkHref="/04_cherry.jpg"/>
          </pattern>
          <pattern id="05_redAmber" width="100%" height="100%">
              <image xlinkHref="/05_redAmber.jpg"/>
          </pattern>    
          <pattern id="06_orange" width="100%" height="100%">
              <image xlinkHref="/06_orange.jpg"/>
          </pattern>
          <pattern id="07_green" width="100%" height="100%">
              <image xlinkHref="/07_green.jpg"/>
          </pattern>
          <pattern id="08_olive" width="100%" height="100%">
              <image xlinkHref="/08_olive.jpg"/>
          </pattern>
          <pattern id="09_purple" width="100%" height="100%">
              <image xlinkHref="/09_purple.jpg"/>
          </pattern>
          <pattern id="10_lightBlue" width="100%" height="100%">
              <image xlinkHref="/10_lightBlue.jpg"/>
          </pattern>  
          <pattern id="11_aquaBlue" width="100%" height="100%">
              <image xlinkHref="/11_aquaBlue.jpg"/>
          </pattern>
          <pattern id="12_oceanBlue" width="100%" height="100%">
              <image xlinkHref="/12_oceanBlue.jpg"/>
          </pattern>
          <pattern id="13_cobaltBlue" width="100%" height="100%">
              <image xlinkHref="/13_cobaltBlue.jpg"/>
          </pattern>
          <pattern id="14_tealBlue" width="100%" height="100%">
              <image xlinkHref="/14_tealBlue.jpg"/>
          </pattern>
          <pattern id="15_clear" width="100%" height="100%">
              <image xlinkHref="/15_clear.jpg"/>
          </pattern>  
          <pattern id="16_granite" width="100%" height="100%">
              <image xlinkHref="/16_granite.jpg"/>
          </pattern>
          <pattern id="17_crackle" width="100%" height="100%">
              <image xlinkHref="/17_crackle.jpg"/>
          </pattern>
          <pattern id="18_iridescent" width="100%" height="100%">
              <image xlinkHref="/18_iridescent.jpg"/>
          </pattern>
          <pattern id="19_taffy" width="100%" height="100%">
              <image xlinkHref="/19_taffy.jpg"/>
          </pattern>
          <pattern id="20_seedy" width="100%" height="100%">
              <image xlinkHref="/20_seedy.jpg"/>
          </pattern> 
          <pattern id="21_frosted" width="100%" height="100%">
              <image xlinkHref="/21_frosted.jpg"/>
          </pattern>                        

            <g className="mountainviewoctagon-image" >

<polygon points="0 146.61 147.73 0 352.56 0 500 145.92 500 353.15 353.49 500 146.04 500 0 352.5 0 146.61"/>
<path onClick={() => props.onFill(1)} fill={props.fillColors[1]} d="m466.9,248.06c-1.2,0-2.13,0-3.06,0-18.76.11-37.53.22-56.29.34-3.8.02-7.6.07-11.4.07-19.8.03-39.59.05-59.39.04-1.4,0-2.84-.2-4.2-.56-8.19-2.18-16.26-4.67-23.88-8.48-12.18-6.09-22.75-14.23-31.85-24.38-7.64-8.51-13.63-18.03-18.26-28.44-2.8-6.28-5.48-12.62-8.11-18.98-.46-1.12-.59-2.45-.59-3.69.01-19.4.09-38.79.11-58.19.03-23.83.02-47.66.02-71.49,0-.86,0-1.72,0-2.75.73-.09,1.33-.23,1.93-.23,28.67-.01,57.33,0,86-.03,1.22,0,2.14.32,2.89,1.25.3.37.67.68,1,1.01,40.99,40.98,81.97,81.97,123,122.9,1.53,1.53,2.13,3.04,2.12,5.18-.07,27.71-.04,55.42-.04,83.13v3.29Z"/>
<path onClick={() => props.onFill(2)} fill={props.fillColors[2]} d="m31.72,247.95v-2.83c0-28.03.02-56.06-.04-84.09,0-1.81.55-3.06,1.82-4.33,41.19-41.12,82.36-82.27,123.48-123.46,1.41-1.41,2.79-1.96,4.76-1.96,27.88.06,55.76.04,83.63.04h2.92c.05,1.04.12,1.81.12,2.58,0,39.35,0,78.7-.02,118.06,0,4.12-.03,8.23-.13,12.35-.03,1.08-.18,2.23-.57,3.23-3.84,9.8-7.76,19.59-13.01,28.73-13.96,24.3-34.15,40.96-61.03,49.42-6.58,2.07-13.14,2.97-20.04,2.92-23.92-.2-47.83-.09-71.75-.14-10.69-.02-21.38-.11-32.07-.2-5.46-.04-10.92-.14-16.38-.21-.46,0-.93-.05-1.71-.1Z"/>
<path onClick={() => props.onFill(3)} fill={props.fillColors[3]} d="m466.9,249.77v2.96c0,27.94-.02,55.89.04,83.83,0,1.83-.6,3.07-1.86,4.33-23.83,23.76-47.62,47.55-71.41,71.35-17.46,17.47-34.93,34.94-52.34,52.46-1.29,1.29-2.57,1.77-4.36,1.77-28.03-.05-56.06-.03-84.09-.03h-2.72c-.07-.89-.16-1.58-.16-2.26,0-12.43,0-24.86,0-37.29,0-22.79,0-45.59-.03-68.38,0-8.07-.1-16.15-.11-24.22,0-.93.05-1.93.39-2.78,3.87-9.79,7.77-19.57,12.97-28.74,13.82-24.4,33.99-41.06,60.8-49.64,6.86-2.2,13.73-3.19,20.96-3.15,29.06.16,58.12.01,87.18-.05,10.61-.02,21.22-.11,31.83-.16.86,0,1.72,0,2.93,0Z"/>
<path onClick={() => props.onFill(4)} fill={props.fillColors[4]} d="m248.31,466.45h-2.63c-28.12,0-56.23-.02-84.35.04-1.73,0-2.92-.52-4.13-1.73-41.24-41.29-82.52-82.56-123.82-123.79-1.23-1.23-1.7-2.43-1.7-4.15.05-28.03.03-56.06.03-84.09v-2.74c.74-.08,1.34-.2,1.93-.2,28.35.05,56.7.12,85.05.18,13.94.03,27.88.04,41.81.13,1.79.01,3.63.28,5.37.73,9.91,2.57,19.55,5.85,28.48,10.97,10.4,5.95,19.52,13.42,27.47,22.41,6.93,7.84,12.53,16.51,16.85,26,3.08,6.76,5.96,13.62,8.83,20.48.5,1.2.74,2.6.75,3.9.09,13.54.12,27.08.16,40.61.05,15.6.13,31.19.13,46.79,0,14.01-.08,28.03-.13,42.04,0,.7-.07,1.39-.12,2.41Z"/>
<path onClick={() => props.onFill(5)} fill={props.fillColors[5]} d="m147.73,495.73c3.86-9.39,7.61-18.5,11.4-27.72h2.72c58.27,0,116.53,0,174.8,0,.47,0,.95.04,1.42,0,.93-.08,1.45.32,1.78,1.19.61,1.63,1.31,3.22,1.97,4.83,2.65,6.43,5.29,12.87,7.94,19.31.3.72.55,1.46.9,2.39h-202.93Z"/>
<path onClick={() => props.onFill(6)} fill={props.fillColors[6]} d="m157.47,467.89c-3.59,8.73-7.35,17.85-11.04,26.81-47.63-47.62-95.18-95.16-142.82-142.79,8.86-3.76,17.87-7.58,26.58-11.28,42.47,42.46,84.77,84.75,127.28,127.25Z"/>
<path onClick={() => props.onFill(7)} fill={props.fillColors[7]} d="m2.83,147.73c9.12,3.7,18.05,7.33,27.06,10.98v180.31c-8.92,3.78-17.92,7.6-26.94,11.42-.51-1.93-.63-199.74-.12-202.72Z"/>
<path onClick={() => props.onFill(8)} fill={props.fillColors[8]} d="m495.84,350.57c-9.23-3.91-18.23-7.72-27.3-11.56v-180.26c8.97-3.64,18.02-7.32,27.3-11.09v202.91Z"/>
<path onClick={() => props.onFill(9)} fill={props.fillColors[9]} d="m159.13,29.73c-3.77-9-7.51-17.94-11.36-27.14h202.94c-3.81,9.1-7.57,18.1-11.35,27.14h-180.23Z"/>
<path onClick={() => props.onFill(10)} fill={props.fillColors[10]} d="m494.89,351.91c-47.65,47.64-95.21,95.18-142.83,142.8-3.67-8.92-7.42-18.04-11.05-26.86,42.45-42.44,84.71-84.69,127.25-127.22,8.66,3.67,17.73,7.51,26.63,11.29Z"/>
<path onClick={() => props.onFill(11)} fill={props.fillColors[11]} d="m30.12,157.1c-8.71-3.54-17.7-7.19-26.55-10.79C51.17,98.71,98.7,51.19,146.4,3.5c3.67,8.74,7.42,17.66,11.04,26.29-42.43,42.42-84.75,84.73-127.33,127.3Z"/>
<path onClick={() => props.onFill(12)} fill={props.fillColors[12]} d="m494.95,146.3c-9,3.65-18,7.31-26.78,10.87-42.4-42.39-84.65-84.63-127.2-127.17,3.62-8.63,7.38-17.63,11.11-26.53,47.65,47.63,95.16,95.13,142.86,142.83Z"/>
<path onClick={() => props.onFill(13)} fill={props.fillColors[13]} d="m219.99,279.74c10.11-10.21,20.02-20.22,29.8-30.11,9.84,9.68,19.57,19.25,29.39,28.91-1.59,1.79-3.11,3.48-4.61,5.18-6.95,7.91-12.48,16.73-16.82,26.3-2.67,5.9-5.19,11.88-7.77,17.83-.24.56-.49,1.11-.93,2.08-.49-1.13-.81-1.9-1.15-2.67-3.43-7.64-6.68-15.38-10.36-22.9-4.2-8.58-9.77-16.26-16.34-23.21-.43-.45-.81-.95-1.2-1.41Z"/>
<path onClick={() => props.onFill(14)} fill={props.fillColors[14]} d="m218.58,278.25c-7.14-6.95-14.96-13.05-23.69-17.98-8.63-4.88-17.92-8.06-27.44-10.98,20.35-5.17,37.81-15.12,52.1-29.93,9.84,9.73,19.55,19.33,28.94,28.61-9.9,10.03-19.84,20.09-29.92,30.29Z"/>
<path onClick={() => props.onFill(15)} fill={props.fillColors[15]} d="m221.06,217.61c13.38-13.76,20.75-30.99,27.96-48.99.63,1.48.99,2.39,1.4,3.28,3.56,7.85,6.93,15.79,10.75,23.51,3.84,7.76,8.88,14.77,14.84,21.09.7.75,1.34,1.55,2.22,2.58-9.33,9.33-18.64,18.63-27.61,27.6-9.88-9.72-19.63-19.3-29.56-29.07Z"/>
<path onClick={() => props.onFill(16)} fill={props.fillColors[16]} d="m330.59,249.33c-19.23,5.02-35.97,14.15-49.95,27.92-9.79-9.72-19.43-19.3-28.86-28.67,9.17-9.37,18.32-18.73,27.67-28.29,14.38,14.4,31.41,23.98,51.13,29.04Z"/>
                
    </g>
            </svg>
        </div>
    )
}