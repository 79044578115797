import React from 'react';
import Patterns from './patterns';


export default function Bakersfield(props) {
    
    return (
        <div className={`Bakersfield-container ${props.size}`}> 
 
            <svg  xmlns="http://www.w3.org/2000/svg" id={props.id} width="500" height="752" viewBox="0 0 500 752">
            <Patterns /> 
            <g className="hummingbird-image" >

<path id="background" fill="black"  d="m0,500V0h752v752H0Z"/>

    <path onClick={() => props.onFill(1)} fill={props.fillColors[1]} d="M235.3,401.3c-21.7,0-42.4,0-63.5,0c0-104.7,0-209.3,0-314.1c21.2,0,42.3,0,63.5,0C235.3,191.7,235.3,296.2,235.3,401.3z"/>
	<path onClick={() => props.onFill(2)} fill={props.fillColors[2]} d="M64,253c0-55.4,0-110.5,0-165.8c35.4,0,70.5,0,106.1,0c0,55.1,0,110.3,0,165.8C135,253,99.7,253,64,253z"/>
	<path onClick={() => props.onFill(3)} fill={props.fillColors[3]} d="M107.3,654.1c0-34.9,0-69.4,0-104.2c49.8,0,99.4,0,149.1,0c0,34.6,0,69.3,0,104.2C206.5,654.1,157.1,654.1,107.3,654.1z"/>
	<path onClick={() => props.onFill(4)} fill={props.fillColors[4]} d="M202.6,403.6c32.6,0,64.9,0,97.4,0c0,48,0,95.8,0,143.8c-32.5,0-64.8,0-97.4,0C202.6,499.6,202.6,451.8,202.6,403.6z"/>
	<path onClick={() => props.onFill(5)} fill={props.fillColors[5]} d="M64.1,318c35.4,0,70.5,0,106.1,0c0,37.9,0,75.7,0,113.9c-35.2,0-70.5,0-106.1,0C64.1,394,64.1,356.1,64.1,318z"/>
	<path onClick={() => props.onFill(6)} fill={props.fillColors[6]} d="M368.9,87.1c20.4,0,40.5,0,60.9,0c0,63.4,0,126.6,0,190c-20.3,0-40.4,0-60.9,0C368.9,213.9,368.9,150.8,368.9,87.1z"/>
	<path onClick={() => props.onFill(7)} fill={props.fillColors[7]} d="M322.8,549.3c35.8,0,71.2,0,107,0c0,35,0,70.4,0,105.9c-35.7,0-71.1,0-107,0C322.8,620.2,322.8,585.2,322.8,549.3z"/>
	<path onClick={() => props.onFill(8)} fill={props.fillColors[8]} d="M300,401.2c-21.1,0-41.8,0-62.8,0c0-57.8,0-115.5,0-173.5c20.8,0,41.6,0,62.8,0C300,285.4,300,343.1,300,401.2z"/>
	<path onClick={() => props.onFill(9)} fill={props.fillColors[9]} d="M366.4,87.3c0,27.8,0,55.3,0,83c-43.2,0-86.1,0-129.3,0c0-27.7,0-55.2,0-83C280.1,87.3,323,87.3,366.4,87.3z"/>
	<path onClick={() => props.onFill(10)} fill={props.fillColors[10]} d="M337.3,440.1c31,0,61.5,0,92.5,0c0,35.7,0,71.3,0,107.3c-30.7,0-61.5,0-92.5,0C337.3,511.6,337.3,475.8,337.3,440.1z"/>
	<path onClick={() => props.onFill(11)} fill={props.fillColors[11]} d="M63.2,433.4c14.3,0,28.3,0,42.6,0c0,73.6,0,147.1,0,220.8c-14.2,0-28.2,0-42.6,0C63.2,580.8,63.2,507.3,63.2,433.4z"/>
	<path onClick={() => props.onFill(12)} fill={props.fillColors[12]} d="M431.4,34.2c11.4,0,22.6,0,34,0c0,81,0,161.8,0,242.9c-11.3,0-22.5,0-34,0C431.4,196.3,431.4,115.5,431.4,34.2z"/>
	<path onClick={() => props.onFill(13)} fill={props.fillColors[13]} d="M465.3,718.9c-10.5,0-20.8,0.1-31-0.1c-1.1,0-2.4-1.8-3.2-3c-0.4-0.7-0.1-1.9-0.1-2.8c0-77.1,0-154.2,0-231.2 c0-1.6,0-3.1,0-4.9c11.6,0,22.7,0,34.2,0C465.3,557.3,465.3,637.8,465.3,718.9z"/>
	<path onClick={() => props.onFill(14)} fill={props.fillColors[14]} d="M301.5,354c0-42.3,0-84.2,0-126.4c21.6,0,43,0,64.8,0c0,42,0,84,0,126.4C344.9,354,323.4,354,301.5,354z"/>
	<path onClick={() => props.onFill(15)} fill={props.fillColors[15]} d="M190.5,655.8c0,21.1,0,41.8,0,62.9c-42.5,0-84.8,0-127.4,0c0-21,0-41.7,0-62.9C105.4,655.8,147.8,655.8,190.5,655.8z"/>
	<path onClick={() => props.onFill(16)} fill={props.fillColors[16]} d="M302.5,718.6c0-20.8,0-41.3,0-61.9c42.4,0,84.6,0,127.2,0c0,20.5,0,41.1,0,61.9C387.5,718.6,345.2,718.6,302.5,718.6z"/>
	<path onClick={() => props.onFill(17)} fill={props.fillColors[17]} d="M429.8,437.8c-31,0-61.7,0-92.6,0c0-27.4,0-54.6,0-82.1c30.7,0,61.5,0,92.6,0C429.8,382.9,429.8,410.2,429.8,437.8z"/>
	<path onClick={() => props.onFill(18)} fill={props.fillColors[18]} d="M107.3,547.4c0-38,0-75.7,0-113.8c20.9,0,41.8,0,62.5,0c0,37.9,0,75.7,0,113.8C148.8,547.4,128.3,547.4,107.3,547.4z"/>
	<path onClick={() => props.onFill(19)} fill={props.fillColors[19]} d="M33.4,470.9c9.5,0,18.6,0,28.1,0c0,82.5,0,164.9,0,247.7c-9.1,0-18.5,0-28.1,0C33.4,636.2,33.4,553.8,33.4,470.9z"/>
	<path onClick={() => props.onFill(20)} fill={props.fillColors[20]} d="M33.4,34.2c9.6,0,18.8,0,28.2,0c0,81.3,0,162.3,0,243.7c-9.2,0-18.6,0-28.2,0C33.4,196.9,33.4,115.8,33.4,34.2z"/>
	<path onClick={() => props.onFill(21)} fill={props.fillColors[21]} d="M465.6,278.4c0,66,0,131.2,0,196.8c-11.3,0-22.6,0-34.3,0c-0.1-1.4-0.2-2.9-0.2-4.5c0-62.2,0-124.3-0.1-186.5 c0-4.7,1.3-6.1,6-5.9C446.3,278.6,455.8,278.4,465.6,278.4z"/>
	<path onClick={() => props.onFill(22)} fill={props.fillColors[22]} d="M192.9,718.6c0-21.1,0-41.9,0-62.5c36.1,0,71.9,0,107.7,0c0,21.2,0,41.7,0,62.5C264.4,718.6,228.8,718.6,192.9,718.6z"/>
	<path onClick={() => props.onFill(23)} fill={props.fillColors[23]} d="M257.6,549.9c21.7,0,42.4,0,63.2,0c0,34.7,0,69.3,0,104.3c-21.5,0-42.4,0-63.2,0C257.6,619.5,257.6,585,257.6,549.9z"/>
	<path onClick={() => props.onFill(24)} fill={props.fillColors[24]} d="M193.4,34.3c0,16.9,0,33.5,0,50.4c-43.1,0-85.9,0-129.1,0c0-16.6,0-33.3,0-50.4C107.1,34.3,150,34.3,193.4,34.3z"/>
	<path onClick={() => props.onFill(25)} fill={props.fillColors[25]} d="M250.5,32.3c0-9.7,0-19.5,0-29.6c71.5,0,143,0,214.9,0c0,9.5,0,19.2,0,29.6C393.8,32.3,322.4,32.3,250.5,32.3z"/>
	<path onClick={() => props.onFill(26)} fill={props.fillColors[26]} d="M248.2,2.6c0,10.2,0,20.1,0,29.7c-71.7,0-143.2,0-214.9,0c0-10.3,0-19.8,0-29.7C104.8,2.6,176.3,2.6,248.2,2.6z"/>
	<path onClick={() => props.onFill(27)} fill={props.fillColors[27]} d="M429.9,34.3c0,16.9,0,33.5,0,50.3c-42.2,0-84.2,0-126.4,0c0-16.8,0-33.4,0-50.3C345.5,34.3,387.5,34.3,429.9,34.3z"/>
	<path onClick={() => props.onFill(28)} fill={props.fillColors[28]} d="M248.2,720.3c0,9.8,0,19.4,0,29.2c-71.7,0-143.2,0-214.9,0c0-9.8,0-19.3,0-29.2C104.8,720.3,176.3,720.3,248.2,720.3z"/>
	<path onClick={() => props.onFill(29)} fill={props.fillColors[29]} d="M465.4,720.2c0,9.9,0,19.5,0,29.2c-71.6,0-143,0-214.7,0c0-9.6,0-19.2,0-29.2C322.1,720.2,393.6,720.2,465.4,720.2z"/>
	<path onClick={() => props.onFill(30)} fill={props.fillColors[30]} d="M302,34.3c0,16.9,0,33.5,0,50.3c-35.8,0-71.4,0-107.2,0c0-16.8,0-33.4,0-50.3C230.4,34.3,266,34.3,302,34.3z"/>
	<path onClick={() => props.onFill(31)} fill={props.fillColors[31]} d="M33.5,280.4c9.5,0,18.6,0,28.1,0c0,63,0,125.8,0,189c-9.3,0-18.6,0-28.1,0C33.5,406.4,33.5,343.4,33.5,280.4z"/>
	<path onClick={() => props.onFill(32)} fill={props.fillColors[32]} d="M237.1,226.1c0-18.3,0-36.2,0-54.3c32.2,0,64.2,0,96.5,0c0,18,0,36,0,54.3C301.6,226.1,269.5,226.1,237.1,226.1z"/>
	<path onClick={() => props.onFill(33)} fill={props.fillColors[33]} d="M468,376.7c9.9,0,19.4,0,29.2,0c0,57,0,113.8,0,170.8c-9.8,0-19.3,0-29.2,0C468,490.6,468,433.9,468,376.7z"/>
	<path onClick={() => props.onFill(34)} fill={props.fillColors[34]} d="M2.5,547.3c0-57,0-113.7,0-170.6c9.7,0,19.3,0,29.2,0c0,56.7,0,113.5,0,170.6C22.2,547.3,12.5,547.3,2.5,547.3z"/>
	<path onClick={() => props.onFill(35)} fill={props.fillColors[35]} d="M468,205.5c9.8,0,19.4,0,29.2,0c0,56.7,0,113.1,0,169.8c-9.7,0-19.3,0-29.2,0C468,318.8,468,262.3,468,205.5z"/>
	<path onClick={() => props.onFill(36)} fill={props.fillColors[36]} d="M31.8,375.4c-9.8,0-19.2,0-29.1,0c0-56.5,0-112.9,0-169.7c9.4,0,19.1,0,29.1,0C31.8,262,31.8,318.5,31.8,375.4z"/>
	<path onClick={() => props.onFill(37)} fill={props.fillColors[37]} d="M468,34.2c9.9,0,19.4,0,29.4,0c0,1.8,0,3.3,0,4.9c0,53-0.1,106.1,0.1,159.1c0,4.9-1.4,6.4-6.2,6.1 c-7.6-0.4-15.3-0.1-23.3-0.1C468,147.5,468,91.1,468,34.2z"/>
	<path onClick={() => props.onFill(38)} fill={props.fillColors[38]} d="M2.6,34.2c9.9,0,19.4,0,29.4,0c0,1.8,0,3.4,0,4.9c0,53-0.1,106.1,0.1,159.1c0,5-1.4,6.4-6.2,6.1 c-7.6-0.4-15.3-0.1-23.3-0.1C2.6,147.5,2.6,91,2.6,34.2z"/>
	<path onClick={() => props.onFill(39)} fill={props.fillColors[39]} d="M2.6,549.9c9.8,0,19.4,0,29.4,0c0,1.8,0,3.3,0,4.9c0,52.7-0.1,105.4,0.1,158.1c0,4.9-1.3,6.4-6.2,6.2 c-7.6-0.4-15.3-0.1-23.3-0.1C2.6,662.6,2.6,606.4,2.6,549.9z"/>
	<path onClick={() => props.onFill(40)} fill={props.fillColors[40]} d="M467.8,718.9c0-56.8,0-112.8,0-169c9.8,0,19.3,0,29.5,0c0,1.7,0,3.4,0,5c0,52.7-0.1,105.4,0.1,158.1c0,4.6-1.2,6.2-5.9,6 C483.8,718.7,476,718.9,467.8,718.9z"/>
	<path onClick={() => props.onFill(41)} fill={props.fillColors[41]} d="M368.9,278.6c20.5,0,40.6,0,60.9,0c0,25.1,0,50.1,0,75.4c-20.2,0-40.4,0-60.9,0C368.9,329,368.9,304,368.9,278.6z"/>
	<path onClick={() => props.onFill(42)} fill={props.fillColors[42]} d="M64,316.5c0-20.5,0-40.6,0-61c24.5,0,48.8,0,73.4,0c0,20.2,0,40.4,0,61C113.2,316.5,88.8,316.5,64,316.5z"/>
	<path onClick={() => props.onFill(43)} fill={props.fillColors[43]} d="M335.1,547.6c-11,0-22,0-33.4,0c0-42.4,0-84.8,0-127.5c10.8,0,21.7,0,33.4,0C335.1,462.5,335.1,504.8,335.1,547.6z"/>
	<path onClick={() => props.onFill(44)} fill={props.fillColors[44]} d="M200.9,489.7c-9.9,0-19.4,0-29.2,0c0-28.7,0-57.1,0-85.9c9.5,0,19.2,0,29.2,0C200.9,432.2,200.9,460.8,200.9,489.7z"/>
	<path onClick={() => props.onFill(45)} fill={props.fillColors[45]} d="M301.6,355.5c11,0,21.8,0,33,0c0,20.6,0,41.1,0,62c-10.9,0-21.8,0-33,0C301.6,397,301.6,376.5,301.6,355.5z"/>
	<path onClick={() => props.onFill(46)} fill={props.fillColors[46]} d="M140,316.8c0-20.8,0-40.9,0-61.3c10.1,0,19.9,0,30.2,0c0.1,1.2,0.3,2.4,0.3,3.6c0,17.6-0.1,35.2,0.1,52.8 c0,3.8-1.1,5.1-4.9,5C157.2,316.6,148.8,316.8,140,316.8z"/>
	<path onClick={() => props.onFill(47)} fill={props.fillColors[47]} d="M366.4,226.4c-9.6,0-19,0.1-28.4-0.1c-1,0-2.9-2.1-2.9-3.2c-0.2-16-0.2-32,0-48c0-1.1,1.8-3.2,2.8-3.3 c9.4-0.3,18.8-0.1,28.5-0.1C366.4,189.8,366.4,207.7,366.4,226.4z"/>
	<path onClick={() => props.onFill(48)} fill={props.fillColors[48]} d="M200.9,547.5c-9.9,0-19.4,0-29.2,0c0-18.7,0-37.2,0-56.1c9.5,0,19.2,0,29.2,0C200.9,509.8,200.9,528.5,200.9,547.5z"/>
	<path onClick={() => props.onFill(49)} fill={props.fillColors[49]} d="M31.7,749.5c-9.9,0-19.4,0-29.2,0c0-9.7,0-19.3,0-29.2c9.5,0,19.2,0,29.2,0C31.7,729.8,31.7,739.5,31.7,749.5z"/>
	<path onClick={() => props.onFill(50)} fill={props.fillColors[50]} d="M468,2.5c9.9,0,19.4,0,29.2,0c0,9.7,0,19.3,0,29.2c-9.5,0-19.2,0-29.2,0C468,22.1,468,12.5,468,2.5z"/>
	<path onClick={() => props.onFill(51)} fill={props.fillColors[51]} d="M31.7,31.8c-9.9,0-19.5,0-29.2,0c0-9.8,0-19.3,0-29.2c9.6,0,19.3,0,29.2,0C31.7,12.2,31.7,21.8,31.7,31.8z"/>
	<path onClick={() => props.onFill(52)} fill={props.fillColors[52]} d="M497,749.5c-9.8,0-19.3,0-29.1,0c0-9.8,0-19.5,0-29.4c9.7,0,19.2,0,29.1,0C497,729.7,497,739.4,497,749.5z"/>
           
    </g>
            </svg>
        </div>
    )
}