import React from 'react';


export default function Put01(props) {
    
    return (
        <div className={`Put01-container ${props.size}`}> 
 
            <svg  xmlns="http://www.w3.org/2000/svg" id={props.id} width="500" height="500" viewBox="0 0 500 500">
                
            <pattern id="01_yellow" width="100%" height="100%">
                      <image xlinkHref="/01_yellow.jpg"/>
              </pattern>
          <pattern id="02_lightAmber" width="100%" height="100%">
              <image xlinkHref="/02_lightAmber.jpg"/>
          </pattern>
          <pattern id="03_darkAmber" width="100%" height="100%">
              <image xlinkHref="/03_darkAmber.jpg"/>
          </pattern>
          <pattern id="04_cherry" width="100%" height="100%">
              <image xlinkHref="/04_cherry.jpg"/>
          </pattern>
          <pattern id="05_redAmber" width="100%" height="100%">
              <image xlinkHref="/05_redAmber.jpg"/>
          </pattern>    
          <pattern id="06_orange" width="100%" height="100%">
              <image xlinkHref="/06_orange.jpg"/>
          </pattern>
          <pattern id="07_green" width="100%" height="100%">
              <image xlinkHref="/07_green.jpg"/>
          </pattern>
          <pattern id="08_olive" width="100%" height="100%">
              <image xlinkHref="/08_olive.jpg"/>
          </pattern>
          <pattern id="09_purple" width="100%" height="100%">
              <image xlinkHref="/09_purple.jpg"/>
          </pattern>
          <pattern id="10_lightBlue" width="100%" height="100%">
              <image xlinkHref="/10_lightBlue.jpg"/>
          </pattern>  
          <pattern id="11_aquaBlue" width="100%" height="100%">
              <image xlinkHref="/11_aquaBlue.jpg"/>
          </pattern>
          <pattern id="12_oceanBlue" width="100%" height="100%">
              <image xlinkHref="/12_oceanBlue.jpg"/>
          </pattern>
          <pattern id="13_cobaltBlue" width="100%" height="100%">
              <image xlinkHref="/13_cobaltBlue.jpg"/>
          </pattern>
          <pattern id="14_tealBlue" width="100%" height="100%">
              <image xlinkHref="/14_tealBlue.jpg"/>
          </pattern>
          <pattern id="15_clear" width="100%" height="100%">
              <image xlinkHref="/15_clear.jpg"/>
          </pattern>  
          <pattern id="16_granite" width="100%" height="100%">
              <image xlinkHref="/16_granite.jpg"/>
          </pattern>
          <pattern id="17_crackle" width="100%" height="100%">
              <image xlinkHref="/17_crackle.jpg"/>
          </pattern>
          <pattern id="18_iridescent" width="100%" height="100%">
              <image xlinkHref="/18_iridescent.jpg"/>
          </pattern>
          <pattern id="19_taffy" width="100%" height="100%">
              <image xlinkHref="/19_taffy.jpg"/>
          </pattern>
          <pattern id="20_seedy" width="100%" height="100%">
              <image xlinkHref="/20_seedy.jpg"/>
          </pattern> 
          <pattern id="21_frosted" width="100%" height="100%">
              <image xlinkHref="/21_frosted.jpg"/>
          </pattern>                                      
            <g className="hummingbird-image" >

<path id="background" fill="black"  d="m0,500V0h752v752H0Z"/>

<path onClick={() => props.onFill(1)} fill={props.fillColors[1]}  d="M188.63,221.08h121.84v232.18h-121.84V221.08Z"/>
  <path onClick={() => props.onFill(2)} fill={props.fillColors[2]}  d="M313.65,221.09h182.29v117.7h-4.03c-58.04,0-116.08-.03-174.12,.08-3.43,0-4.23-.92-4.22-4.27,.13-36.51,.08-73.03,.08-109.54v-3.96Z"/>
  <path onClick={() => props.onFill(3)} fill={props.fillColors[3]}  d="M184.96,221.42v117.17H2.98v-117.17H184.96Z"/>
  <path onClick={() => props.onFill(4)} fill={props.fillColors[4]}  d="M184.94,453.33H3.02v-111.21H184.94v111.21Z"/>
  <path onClick={() => props.onFill(5)} fill={props.fillColors[5]}  d="M313.9,342.12h182.03v111.12h-182.03v-111.12Z"/>
  <path onClick={() => props.onFill(6)} fill={props.fillColors[6]}  d="M420.82,164.48v53.32H78.34v-53.32H420.82Z"/>
  <path onClick={() => props.onFill(7)} fill={props.fillColors[7]}  d="M2.93,161.12V51.45H122.28v109.67H2.93Z"/>
  <path onClick={() => props.onFill(8)} fill={props.fillColors[8]}  d="M495.93,51.42v109.72h-119.25V51.42h119.25Z"/>
  <path onClick={() => props.onFill(9)} fill={props.fillColors[9]}  d="M247.69,47.89H3.01V3.39H247.69V47.89Z"/>
  <path onClick={() => props.onFill(10)} fill={props.fillColors[10]}  d="M251.34,3.33h244.61V47.74H251.34V3.33Z"/>
  <path onClick={() => props.onFill(11)} fill={props.fillColors[11]}  d="M217.76,51.48v109.6H125.83V51.48h91.93Z"/>
  <path onClick={() => props.onFill(12)} fill={props.fillColors[12]}  d="M282.8,51.4h90.24v109.68h-90.24V51.4Z"/>
  <path onClick={() => props.onFill(13)} fill={props.fillColors[13]}  d="M422.17,456.85v38.95H251.34v-38.95h170.83Z"/>
  <path onClick={() => props.onFill(14)} fill={props.fillColors[14]}  d="M221.19,161.09V51.43h58v109.66h-58Z"/>
  <path onClick={() => props.onFill(15)} fill={props.fillColors[15]}  d="M247.69,456.91v38.98H78.32v-38.98H247.69Z"/>
  <path onClick={() => props.onFill(16)} fill={props.fillColors[16]}  d="M3.03,164.38H74.57v53.49H3.03v-53.49Z"/>
  <path onClick={() => props.onFill(17)} fill={props.fillColors[17]}  d="M424.33,217.64v-53.21h71.53v53.21h-71.53Z"/>
  <path onClick={() => props.onFill(18)} fill={props.fillColors[18]}  d="M2.92,456.81H74.57v39.05H2.92v-39.05Z"/>
  <path onClick={() => props.onFill(19)} fill={props.fillColors[19]}  d="M425.47,495.91v-39.04h70.41v39.04h-70.41Z"/>





    </g>
            </svg>
        </div>
    )
}