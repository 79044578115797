import React from 'react';


export default function Put04(props) {
    
    return (
        <div className={`Put04-container ${props.size}`}> 
 
            <svg  xmlns="http://www.w3.org/2000/svg" id={props.id} width="500" height="500" viewBox="0 0 500 500">
                
            <pattern id="01_yellow" width="100%" height="100%">
                      <image xlinkHref="/01_yellow.jpg"/>
              </pattern>
          <pattern id="02_lightAmber" width="100%" height="100%">
              <image xlinkHref="/02_lightAmber.jpg"/>
          </pattern>
          <pattern id="03_darkAmber" width="100%" height="100%">
              <image xlinkHref="/03_darkAmber.jpg"/>
          </pattern>
          <pattern id="04_cherry" width="100%" height="100%">
              <image xlinkHref="/04_cherry.jpg"/>
          </pattern>
          <pattern id="05_redAmber" width="100%" height="100%">
              <image xlinkHref="/05_redAmber.jpg"/>
          </pattern>    
          <pattern id="06_orange" width="100%" height="100%">
              <image xlinkHref="/06_orange.jpg"/>
          </pattern>
          <pattern id="07_green" width="100%" height="100%">
              <image xlinkHref="/07_green.jpg"/>
          </pattern>
          <pattern id="08_olive" width="100%" height="100%">
              <image xlinkHref="/08_olive.jpg"/>
          </pattern>
          <pattern id="09_purple" width="100%" height="100%">
              <image xlinkHref="/09_purple.jpg"/>
          </pattern>
          <pattern id="10_lightBlue" width="100%" height="100%">
              <image xlinkHref="/10_lightBlue.jpg"/>
          </pattern>  
          <pattern id="11_aquaBlue" width="100%" height="100%">
              <image xlinkHref="/11_aquaBlue.jpg"/>
          </pattern>
          <pattern id="12_oceanBlue" width="100%" height="100%">
              <image xlinkHref="/12_oceanBlue.jpg"/>
          </pattern>
          <pattern id="13_cobaltBlue" width="100%" height="100%">
              <image xlinkHref="/13_cobaltBlue.jpg"/>
          </pattern>
          <pattern id="14_tealBlue" width="100%" height="100%">
              <image xlinkHref="/14_tealBlue.jpg"/>
          </pattern>
          <pattern id="15_clear" width="100%" height="100%">
              <image xlinkHref="/15_clear.jpg"/>
          </pattern>  
          <pattern id="16_granite" width="100%" height="100%">
              <image xlinkHref="/16_granite.jpg"/>
          </pattern>
          <pattern id="17_crackle" width="100%" height="100%">
              <image xlinkHref="/17_crackle.jpg"/>
          </pattern>
          <pattern id="18_iridescent" width="100%" height="100%">
              <image xlinkHref="/18_iridescent.jpg"/>
          </pattern>
          <pattern id="19_taffy" width="100%" height="100%">
              <image xlinkHref="/19_taffy.jpg"/>
          </pattern>
          <pattern id="20_seedy" width="100%" height="100%">
              <image xlinkHref="/20_seedy.jpg"/>
          </pattern> 
          <pattern id="21_frosted" width="100%" height="100%">
              <image xlinkHref="/21_frosted.jpg"/>
          </pattern>                                      
            <g className="hummingbird-image" >

<path id="background" fill="black"  d="m0,500V0h500v500H0Z"/>

  <path onClick={() => props.onFill(1)} fill={props.fillColors[1]} d="M248.24,156.75v55.4c0,.99-.22,2.08-.66,2.96-2.3,4.72-4.39,9.43-7.02,13.93-4.39,7.68-11.52,12.07-19.31,15.91-5.7,2.74-11.41,3.73-17.66,3.73-34.01-.22-68.12-.11-102.13-.11q-2.52,0-2.52-2.41v-107.07c0-2.96,.33-5.59,1.76-8.12,2.3-4.06,4.17-8.45,6.58-12.51,3.73-6.36,9.43-10.64,16.02-13.93,1.32-.66,2.74-1.21,3.95-1.97,4.72-2.85,9.87-3.51,15.36-3.51,34.34,.22,68.78,.11,103.12,.11q2.41,0,2.41,2.41c0,18.21,0,36.64,.11,55.18-.11,0,0,0,0,0Z"/>
  <path onClick={() => props.onFill(2)} fill={props.fillColors[2]} d="M98.94,309.57v-55.18c0-1.54,.33-2.08,1.97-2.08h110.25c.88,0,1.97,0,2.74,.44,5.05,2.63,10.2,5.05,15.03,8.01,7.02,4.28,11.63,10.64,15.03,17.99,1.21,2.63,2.52,5.16,3.73,7.9,.44,.88,.66,1.86,.66,2.85v89.84c0,6.8-.11,13.6,0,20.4,0,1.65-.33,2.19-2.08,2.19-36.64,0-73.28,0-109.92-.11-.77,0-1.65-.11-2.41-.44-5.05-2.41-10.09-4.72-14.92-7.46-7.68-4.39-12.51-11.41-16.02-19.42-.33-.66-.55-1.32-.99-1.86-2.52-4.17-3.07-8.67-3.07-13.49,.11-16.46,0-33.02,0-49.58Z"/>
  <path onClick={() => props.onFill(3)} fill={props.fillColors[3]} d="M400.51,248.14h-2.19c-30.94,0-61.87,.11-92.81,.11-4.61,0-9.21,0-13.82,.22-2.85,.11-5.49-.33-8.01-1.65-3.62-1.86-7.46-3.4-11.08-5.38-7.57-4.17-12.62-10.64-16.13-18.43-.22-.55-.55-1.21-.88-1.76-2.96-4.83-3.73-10.09-3.62-15.8,.22-27.75,.11-55.62,0-83.37,0-7.02,0-14.04-.11-21.06,0-1.54,.33-2.08,1.97-2.08h109.15c.88,0,1.76,.11,2.52,.44,4.94,2.41,9.98,4.72,14.81,7.46,7.57,4.39,12.4,11.41,15.91,19.31,.33,.77,.66,1.54,1.1,2.19,2.52,4.06,2.85,8.45,2.85,13.16-.11,34.78,0,69.55,.11,104.22,.22,.77,.22,1.43,.22,2.41Z"/>
  <path onClick={() => props.onFill(4)} fill={props.fillColors[4]} d="M400.51,252.08v2.19c0,32.69-.11,65.49-.11,98.18,0,4.06,0,8.12-.11,12.18,0,.88,0,1.86-.44,2.52-2.52,5.05-4.83,10.2-7.68,15.03-4.17,7.13-10.53,11.74-17.88,15.14-.55,.33-1.21,.55-1.76,.88-4.83,2.96-10.09,3.73-15.8,3.73-34.12-.22-68.34-.11-102.46-.11q-2.19,0-2.19-2.19c0-36.64,0-73.28,.11-109.92,0-1.54,.77-3.18,1.43-4.61,2.19-4.39,4.28-8.89,6.8-13.05,3.73-6.25,9.32-10.75,15.69-14.04,3.18-1.76,6.47-3.29,9.87-4.83,.99-.44,2.19-.77,3.29-.77,15.91-.11,31.7-.11,47.61-.22,20.4,0,40.81-.11,61.21-.11,.77,0,1.54,0,2.41,0Z"/>
  <path onClick={() => props.onFill(5)} fill={props.fillColors[5]} d="M477.08,326.9v72.62q0,2.3-2.19,2.3c-11.08,0-22.27,.11-33.35,.11-1.21,0-2.63-.33-3.73-.88-3.73-1.65-7.35-3.4-10.97-5.27-8.67-4.5-14.48-11.52-18.43-20.18-.88-1.86-1.65-3.73-2.63-5.49-1.32-2.3-1.65-4.61-1.65-7.24,.11-36.31,.11-72.51,.22-108.82,0-1.43,.33-1.86,1.86-1.86,23.04,0,45.96,0,69-.11,1.54,0,1.97,.44,1.97,1.97v70.32c-.11,.77-.11,1.65-.11,2.52Z"/>
  <path onClick={() => props.onFill(6)} fill={props.fillColors[6]} d="M404.46,248.14v-1.86c0-16.67,0-33.35-.11-50.02v-47.83c0-2.96,0-6.03-.11-9-.11-3.4,.33-6.47,1.97-9.43,2.52-4.39,4.5-9,7.24-13.16,4.06-6.25,10.09-10.31,16.89-13.38,.55-.33,1.21-.44,1.76-.88,5.81-3.84,12.18-3.84,18.76-3.51,4.83,.22,9.65,0,14.48,.11,3.4,0,6.91,.11,10.31,.11,1.21,0,1.54,.44,1.43,1.54V245.83q0,2.3-2.3,2.3h-26.88c-13.71,0-27.43,0-41.25,.11-.66-.11-1.32-.11-2.19-.11Z"/>
  <path onClick={() => props.onFill(7)} fill={props.fillColors[7]} d="M173.76,22.92h72.62c1.32,0,1.76,.33,1.76,1.76-.11,8.12,0,16.24,0,24.35,0,14.7,0,29.29,.11,43.99,0,1.54-.33,2.19-2.08,2.19-36.64-.11-73.39,0-110.03-.11-1.21,0-2.63-.33-3.73-.88-5.27-2.63-10.64-5.16-15.58-8.34-6.58-4.17-10.64-10.64-13.71-17.55-.33-.77-.55-1.54-1.1-2.19-3.4-4.94-2.96-10.42-2.96-15.91,.11-8.45,.22-17,.22-25.45,0-1.32,.44-1.76,1.76-1.76h70.43c.77-.11,1.54-.11,2.3-.11h0Z"/>
  <path onClick={() => props.onFill(8)} fill={props.fillColors[8]} d="M22.92,327.12v-72.95c0-1.43,.44-1.86,1.86-1.86H93.13c1.54,0,1.97,.44,1.97,1.97,0,36.86,0,73.72-.11,110.47,0,1.21-.33,2.52-.88,3.62-2.19,4.61-4.39,9.32-7.02,13.71-3.73,6.47-9.43,10.97-16.13,14.26-3.07,1.54-6.25,3.07-9.54,4.5-1.1,.44-2.3,.77-3.51,.77-11.08,.11-22.27,0-33.35,0-1.1,0-1.86,0-1.86-1.54,.22-24.24,.22-48.6,.22-72.95Z"/>
  <path onClick={() => props.onFill(9)} fill={props.fillColors[9]} d="M22.92,173.87V101.68q0-2.52,2.63-2.52c10.53,0,21.06-.11,31.48,0,1.76,0,3.73,.44,5.38,1.21,4.17,1.86,8.23,3.84,12.18,6.03,7.79,4.5,12.94,11.3,16.56,19.42,.44,.88,.77,1.86,1.32,2.63,2.41,3.84,2.74,8.12,2.74,12.51-.11,35.1-.11,70.21-.11,105.2q0,2.19-2.3,2.19H25.11q-2.19,0-2.19-2.19v-72.29h0Z"/>
  <path onClick={() => props.onFill(10)} fill={props.fillColors[10]} d="M326.35,22.92h72.4c1.21,0,2.08,0,1.97,1.65-.22,11.19-.22,22.38-.44,33.46,0,1.1-.33,2.3-.77,3.29-2.74,5.7-5.05,11.63-8.89,16.78-4.61,6.14-10.97,9.98-17.77,13.38-5.27,2.74-10.64,3.62-16.46,3.62-34.01-.22-68.01-.11-102.02-.11q-2.3,0-2.3-2.3c0-22.6,0-45.09-.11-67.69q0-2.19,2.19-2.19h69.33c.99,.11,1.97,.11,2.85,.11Z"/>
  <path onClick={() => props.onFill(11)} fill={props.fillColors[11]} d="M173.65,477.08H101.57q-2.3,0-2.3-2.3c-.11-9.65-.11-19.31-.33-28.85-.11-3.18,.33-6.03,1.86-8.78,2.63-4.61,4.72-9.43,7.57-13.82,4.39-6.69,11.08-10.42,18.1-13.93,5.27-2.63,10.53-3.62,16.35-3.62,34.34,.22,68.67,.11,103.01,.11q2.41,0,2.41,2.41c0,22.27,0,44.43-.11,66.7q0,2.19-2.3,2.19h-69.66c-.88-.11-1.65-.11-2.52-.11Z"/>
  <path onClick={() => props.onFill(12)} fill={props.fillColors[12]} d="M326.24,477.08h-72.4c-1.43,0-1.86-.44-1.86-1.86,.11-22.6,.11-45.09,0-67.69,0-1.54,.44-1.86,1.86-1.86h109.37c.77,0,1.65-.11,2.3,.22,5.16,2.52,10.53,4.94,15.47,7.9,6.47,3.84,10.97,9.65,14.26,16.35,1.32,2.74,2.74,5.49,3.84,8.34,.66,1.76,1.21,3.62,1.21,5.49,.22,10.31,.22,20.73,.44,31.05,0,1.54-.44,1.97-1.97,1.97h-70.43c-.66,.11-1.32,.11-2.08,.11Z"/>
  <path onClick={() => props.onFill(13)} fill={props.fillColors[13]} d="M249.9,2.5h225.1q2.3,0,2.3,2.3v12.6c0,1.2-.3,1.8-1.7,1.8H24.2c-1.2,0-1.6-.4-1.6-1.5V3.9c0-1,.3-1.5,1.4-1.4,.5,.1,1,0,1.5,0,74.8,0,149.6,0,224.4,0Z"/>
  <path onClick={() => props.onFill(14)} fill={props.fillColors[14]} d="M497.5,250.1v225.8c0,.9-.3,1.3-1.2,1.3h-14.2c-.9,0-1.2-.4-1.2-1.3,.1-5.6,.1-11.2,.1-16.8,0-1.2-.1-2.4-.1-3.6V24.4c0-1.5,.5-1.8,1.9-1.8,4.4,.1,8.9,.1,13.3,0,1.1,0,1.6,.3,1.5,1.5-.1,.5,0,1,0,1.5-.1,74.9-.1,149.7-.1,224.5Z"/>
  <path onClick={() => props.onFill(15)} fill={props.fillColors[15]} d="M250,497.5H25.4c-.5,0-1.2,.1-1.6,0-.4-.2-1-.7-1-1-.1-4.8,0-9.6-.1-14.4,0-.8,.3-1.2,1.2-1.2,5.6,.1,11.2,.1,16.8,.1,1.2,0,2.5-.1,3.7-.1H475.4c1.4,0,1.9,.3,1.9,1.8-.1,4.2,0,8.4,0,12.6q0,2.2-2.2,2.2H250Z"/>
  <path onClick={() => props.onFill(16)} fill={props.fillColors[16]} d="M2.5,250.1V25.1c0-2.6-.2-2.3,2.3-2.3h12.7c1.1,0,1.6,.3,1.6,1.5,0,5.5-.1,11.1-.1,16.7,0,1,.1,2.1,.1,3.1V475.5c0,1.3-.3,1.8-1.7,1.7-4.5-.1-9-.1-13.5,0-1.1,0-1.6-.3-1.5-1.4,.1-.5,0-1,0-1.5,.1-74.7,.1-149.5,.1-224.2Z"/>
  <path onClick={() => props.onFill(17)} fill={props.fillColors[17]} d="M440.88,22.92h34.12c1.65,0,2.08,.44,2.08,2.08V93.35c0,1.65-.44,2.19-2.08,2.08-11.41-.22-22.71-.22-34.12-.33-.66,0-1.43,0-1.97-.22-5.05-2.52-10.2-4.83-15.03-7.57-7.35-4.28-11.96-10.86-15.47-18.43-1.1-2.41-2.3-4.72-3.29-7.13-.44-1.1-.77-2.3-.77-3.4,0-10.09,.11-20.18,.22-30.17,0-.99,.11-1.97,.11-2.96q0-2.08,2.19-2.08c11.41-.22,22.71-.22,34.01-.22Z"/>
  <path onClick={() => props.onFill(18)} fill={props.fillColors[18]} d="M59.12,22.92h34.12c1.54,0,2.08,.33,2.08,1.97-.11,11.19-.22,22.38-.33,33.46,0,.66,0,1.43-.22,1.97-2.96,6.03-5.27,12.29-9.32,17.66-3.84,5.27-8.89,9-14.59,11.96-3.18,1.65-6.36,3.07-9.65,4.5-1.1,.44-2.3,.77-3.51,.77-10.97,.11-22.05,0-33.02,.11-1.54,0-1.97-.44-1.97-1.97V24.89c0-1.54,.44-1.97,1.97-1.97,11.52,0,23.04,0,34.45,0Z"/>
  <path onClick={() => props.onFill(19)} fill={props.fillColors[19]} d="M440.77,477.08h-34.01c-1.54,0-2.08-.33-2.08-1.97-.11-10.86-.33-21.72-.22-32.69,0-1.86,.99-3.73,1.86-5.49,2.41-4.72,4.61-9.43,7.46-13.82,4.39-6.58,11.08-10.31,17.99-13.82,5.38-2.74,10.64-3.84,16.67-3.62,8.89,.33,17.88,0,26.77-.11,1.54,0,2.08,.44,2.08,2.08v67.47c0,1.65-.55,1.97-2.08,1.97h-34.45Z"/>
  <path onClick={() => props.onFill(20)} fill={props.fillColors[20]} d="M59.01,477.08H25c-1.65,0-2.19-.33-2.19-2.08,.11-22.49,.11-44.87,0-67.36,0-1.65,.33-2.19,2.08-2.19,10.86,.11,21.72,0,32.47,.11,1.32,0,2.74,.33,3.95,.88,4.28,1.97,8.67,4.06,12.73,6.36,7.9,4.5,13.16,11.41,16.78,19.53,.77,1.65,1.43,3.4,2.41,5.05,1.32,2.41,1.65,4.83,1.65,7.57,0,9.87,.11,19.75,.22,29.51q0,2.52-2.63,2.52c-10.97,.11-22.16,.11-33.46,.11Z"/>
  <path onClick={() => props.onFill(21)} fill={props.fillColors[21]} d="M19.1,10.8v6.9c0,1-.2,1.5-1.4,1.5-4.6-.1-9.3,0-13.9,0-1,0-1.3-.3-1.3-1.3V3.7c0-.9,.4-1.2,1.3-1.2h14c1.1,0,1.4,.4,1.3,1.4v6.9Z"/>
  <path onClick={() => props.onFill(22)} fill={props.fillColors[22]} d="M489.2,19.2c-2.3,0-4.6-.1-6.9,0-1.1,0-1.5-.4-1.5-1.5V3.9c0-1,.2-1.5,1.3-1.4h14.1c.9,0,1.2,.3,1.2,1.2v14.2c0,.9-.3,1.2-1.2,1.2-2.3,0-4.7,.1-7,.1Z"/>
  <path onClick={() => props.onFill(23)} fill={props.fillColors[23]} d="M497.5,489v7c0,1-.3,1.4-1.4,1.4h-13.9c-.9,0-1.4-.3-1.3-1.3v-14.1c0-1,.4-1.3,1.4-1.3h13.9c1.1,0,1.4,.5,1.3,1.5-.1,2.3,0,4.6,0,6.8Z"/>
  <path onClick={() => props.onFill(24)} fill={props.fillColors[24]} d="M10.8,497.5H3.9c-1.1,0-1.5-.4-1.5-1.5v-13.7c0-1,.3-1.4,1.4-1.4h13.9c1,0,1.4,.4,1.4,1.4v13.7c0,1.1-.3,1.5-1.4,1.5-2.3-.1-4.6,0-6.9,0Z"/>
  <path onClick={() => props.onFill(25)} fill={props.fillColors[25]} d="M402.37,436.05c-3.18-6.8-6.03-13.27-10.75-18.32,3.73-3.62,7.24-7.13,10.64-10.64,3.4,3.4,7.02,6.91,10.64,10.64-4.61,5.16-7.46,11.52-10.53,18.32Z"/>
  <path onClick={() => props.onFill(26)} fill={props.fillColors[26]} d="M402.37,64.93c2.96,6.58,5.92,13.05,10.64,18.21-3.73,3.73-7.24,7.24-11.08,11.08-1.21-1.32-2.52-2.74-3.84-4.17-1.97-1.97-3.95-3.95-5.92-5.92-.33-.33-.44-1.21-.22-1.43,4.28-4.94,7.02-10.86,9.76-16.78,.22-.22,.33-.44,.66-.99Z"/>
  <path onClick={() => props.onFill(27)} fill={props.fillColors[27]} d="M415.98,86.11c5.49,4.94,11.85,7.79,18.54,10.97-6.8,3.07-13.16,6.03-18.76,11.08-1.21-1.32-2.3-2.74-3.51-3.95-2.08-2.08-4.17-4.17-6.25-6.25-.33-.33-.55-1.21-.33-1.43,3.4-3.51,6.8-6.91,10.31-10.42Z"/>
  <path onClick={() => props.onFill(28)} fill={props.fillColors[28]} d="M415.87,414.88c-2.63-2.74-5.27-5.38-7.79-8.01-2.96-3.07-3.07-3.07,0-6.03l7.9-7.9c5.38,4.83,11.74,7.79,18.54,10.86-6.69,3.18-13.16,5.92-18.65,11.08Z"/>
  <path onClick={() => props.onFill(29)} fill={props.fillColors[29]} d="M370.45,403.58c6.36-2.96,12.73-5.81,17.99-10.53,3.62,3.62,7.13,7.24,10.75,10.86-.11,.11-.44,.55-.77,.88-2.96,2.96-5.81,5.92-8.78,8.78-.55,.55-.99,1.1-1.76,.44-4.83-4.17-10.53-6.8-16.13-9.54-.33-.11-.55-.33-.88-.44-.11,0-.22-.22-.44-.44Z"/>
  <path onClick={() => props.onFill(30)} fill={props.fillColors[30]} d="M128.89,96.97c-4.5,2.41-8.89,4.83-13.16,7.24-1.43,.77-2.74,1.86-3.95,2.96-.77,.66-1.21,.66-1.86,0-3.29-3.29-6.58-6.58-9.98-9.87,.33-.44,.55-.77,.88-1.1,2.96-3.07,6.03-6.03,9-9.11,.55-.55,.99-.88,1.76-.22,4.72,4.17,10.31,6.8,15.91,9.32,.44,.22,.88,.55,1.43,.77Z"/>
  <path onClick={() => props.onFill(31)} fill={props.fillColors[31]} d="M86.33,111.01c3.73-3.62,7.24-7.24,10.75-10.75,3.4,3.4,7.02,6.91,10.53,10.42-1.86,2.85-3.95,5.7-5.7,8.67-1.76,3.07-3.18,6.25-4.94,9.76-3.07-6.58-5.92-12.94-10.64-18.1Z"/>
  <path onClick={() => props.onFill(32)} fill={props.fillColors[32]} d="M129.11,403.8c-2.19,1.1-4.39,1.97-6.25,3.07-3.73,2.19-7.35,4.5-10.97,6.91-.99,.66-1.43,.66-2.3-.11-2.85-2.96-5.81-5.81-8.67-8.67-.33-.33-.66-.77-.88-.99,3.62-3.62,7.13-7.13,10.64-10.64,2.96,1.97,5.81,4.06,8.89,5.81,2.96,1.54,6.14,2.85,9.54,4.61Z"/>
  <path onClick={() => props.onFill(33)} fill={props.fillColors[33]} d="M261.19,264.59c-4.94,5.38-7.68,11.74-10.86,18.43-3.29-6.58-6.03-13.05-11.19-18.65,1.43-1.32,2.96-2.63,4.28-3.95,1.97-1.97,3.95-3.95,5.92-5.92,.33-.33,1.32-.44,1.54-.22,3.4,3.51,6.8,6.8,10.31,10.31Z"/>
  <path onClick={() => props.onFill(34)} fill={props.fillColors[34]} d="M96.86,400.4c-3.4-3.4-6.91-6.91-10.53-10.64,4.72-5.05,7.57-11.52,10.75-18.21,3.18,6.69,5.92,13.05,10.64,18.21-3.62,3.62-7.24,7.13-10.86,10.64Z"/>
  <path onClick={() => props.onFill(35)} fill={props.fillColors[35]} d="M282.36,250.44c-2.3,1.1-4.17,1.97-5.92,3.07-3.84,2.41-7.68,4.94-11.52,7.35-.44,.22-1.32,.44-1.54,.22-3.4-3.29-6.8-6.58-10.31-10.09,3.07-3.07,5.92-5.92,8.89-8.78q2.08-2.08,4.5-.33c4.06,3.18,8.67,5.27,13.27,7.35,.55,.33,1.32,.66,2.63,1.21Z"/>
  <path onClick={() => props.onFill(36)} fill={props.fillColors[36]} d="M402.37,371.66c.99,2.19,1.86,4.17,2.96,5.92,2.3,3.84,4.72,7.57,7.02,11.41,.22,.44,.55,1.32,.33,1.65-3.29,3.51-6.69,6.91-10.31,10.53-2.85-2.96-5.59-5.81-8.34-8.56q-2.41-2.41-.33-5.05c3.4-4.28,5.59-9.21,7.9-14.15,.11-.44,.33-.99,.77-1.76Z"/>
  <path onClick={() => props.onFill(37)} fill={props.fillColors[37]} d="M399.41,96.86c-3.73,3.73-7.24,7.24-10.97,10.97-5.16-4.61-11.52-7.68-18.1-10.53,.44-.33,.66-.66,.88-.77,5.92-2.63,11.74-5.49,16.67-9.65,.22-.22,.99-.22,1.21,0,3.51,3.18,6.91,6.58,10.31,9.98Z"/>
  <path onClick={() => props.onFill(38)} fill={props.fillColors[38]} d="M402.26,99.71c3.51,3.51,6.91,6.91,10.2,10.31,.22,.22,.33,.99,.11,1.21-4.39,5.05-7.13,11.08-9.98,17.11-.11,.11-.22,.22-.44,.55-1.1-2.3-1.97-4.5-3.18-6.58-2.08-3.62-4.5-7.13-6.69-10.64-.22-.44-.44-1.32-.22-1.54,3.29-3.51,6.69-6.8,10.2-10.42Z"/>
  <path onClick={() => props.onFill(39)} fill={props.fillColors[39]} d="M247.48,250.88c-3.51,3.51-6.91,6.91-10.42,10.31-.22,.22-.99,.22-1.21,0-4.94-4.39-10.86-7.24-16.78-10.09-.22-.11-.33-.22-.66-.55,.44-.33,.88-.55,1.21-.77,5.7-2.41,11.3-5.05,16.24-9.11,.22-.22,1.1-.11,1.32,.11,3.4,3.29,6.8,6.58,10.31,10.09Z"/>
  <path onClick={() => props.onFill(40)} fill={props.fillColors[40]} d="M86.33,417.62c3.62-3.62,7.13-7.24,10.53-10.64,3.51,3.51,7.13,7.13,10.75,10.86-4.5,4.94-7.35,11.3-10.53,18.1-3.18-6.69-6.03-13.16-10.75-18.32Z"/>
  <path onClick={() => props.onFill(41)} fill={props.fillColors[41]} d="M86.44,83.04c4.61-5.05,7.46-11.41,10.64-18.21,3.18,6.8,6.03,13.16,10.64,18.32l-10.64,10.64c-3.51-3.4-7.02-7.02-10.64-10.75Z"/>
  <path onClick={() => props.onFill(42)} fill={props.fillColors[42]} d="M261.19,237.27c-3.51,3.51-6.91,6.91-10.31,10.2-.22,.22-.99,.22-1.21,0-3.51-3.29-6.91-6.69-10.31-10.2,5.05-5.38,7.79-11.74,10.97-18.54,3.07,6.8,5.81,13.16,10.86,18.54Z"/>
  <path onClick={() => props.onFill(43)} fill={props.fillColors[43]} d="M83.15,107.72c-5.16-4.61-11.52-7.46-17.99-10.53,.44-.33,.66-.66,.99-.77,5.81-2.63,11.74-5.49,16.67-9.76,.22-.22,.88-.22,1.1-.11,3.51,3.4,6.91,6.8,10.31,10.2-3.84,3.84-7.46,7.35-11.08,10.97Z"/>
  <path onClick={() => props.onFill(44)} fill={props.fillColors[44]} d="M64.93,403.69c6.69-3.07,13.05-5.92,18.1-10.53,3.62,3.62,7.24,7.24,11.19,11.19-.88,.77-2.08,1.65-3.07,2.63-2.3,2.19-4.5,4.5-6.69,6.69-.55,.55-.99,.99-1.76,.22-4.5-3.95-9.87-6.58-15.25-9.11-.88-.22-1.54-.55-2.52-1.1Z"/>

</g>
            </svg>
        </div>
    )
}