export const glassData = [
    {
        "id": "url(#15_clear)",
        "color": "Clear",
        "type": "water ripple",
        "description" : "A clear, transparent glass featuring a gentle rippled effect, reminiscent of flowing water. As our most popular choice, it brings a calming, natural elegance to any space. ",
    },
    {
        "id": "url(#16_granite)",
        "color": "Clear",
        "type": "grantite glass",
        "description" : "Granite Glass – A heavily textured, translucent glass that shimmers with a soft white glow, creating a natural light ambiance in your living space. Ideal for enhancing privacy while maintaining a bright and airy feel. ",
    },
    {
        "id": "url(#17_crackle)",
        "color": "Clear",
        "type": "crackle glass",
        "description" : " A clear, transparent glass with a distinctive crackled texture, offering excellent privacy while adding visual interest. Perfect for contrasting with other clear textured glass types in a cohesive window design.",
    },
    {
        "id": "url(#18_iridescent)",
        "color": "Clear",
        "type": "iridescent glass",
        "description" : "Iridescent glass is a type of glass with a shimmering, rainbow-like surface that changes color when viewed from different angles, achieved by applying a thin metallic coating during or after production. Its iridescence can appear subtle from certain angles and more vibrant from others, creating a dynamic and captivating visual effect.",
    },
    {
        "id": "url(#19_taffy)",
        "color": "Clear",
        "type": "Baroque glass",
        "description" : "A clear, transparent glass featuring elegant, swirling patterns reminiscent of taffy candy. Its playful design adds a touch of sophistication and charm to any space. ",
    },
    {
        "id": "url(#20_seedy)",
        "color": "Clear",
        "type": "seedy glass",
        "description" : "A highly transparent glass with delicate, seed-like speckles scattered throughout, adding texture and character while maintaining clarity. ",
    },
    {
        "id": "url(#21_frosted)",
        "color": "Clear Frosted",
        "type": "glue chip glass",
        "description" : "A classic glass that offers high privacy with a textured design resembling delicate frost forming on the surface, adding a timeless and elegant touch. ",
    },
    {
        "id": "url(#07_green)",
        "color": "Green",
        "type": "water ripple",
        "description" : "The green glass captures the essence of rippling water, its surface delicately mimicking gentle waves. When the sun touches it, the glass shimmers beautifully, casting mesmerizing reflections that dance with light.",
    },
    {
        "id": "url(#08_olive)",
        "color": "Olive Green",
        "type": "water ripple",
        "description" : "The olive green glass captures the essence of rippling water, its surface delicately mimicking gentle waves. When the sun touches it, the glass shimmers beautifully, casting mesmerizing reflections that dance with light.",
    },
    {
        "id": "url(#09_purple)",
        "color": "Purple",
        "type": "water ripple",
        "description" : "The purple glass captures the essence of rippling water, its surface delicately mimicking gentle waves. When the sun touches it, the glass shimmers beautifully, casting mesmerizing reflections that dance with light.",
    },
    {
        "id": "url(#10_lightBlue)",
        "color": "Light Blue",
        "type": "water ripple",
        "description" : "The light blue glass captures the essence of rippling water, its surface delicately mimicking gentle waves. When the sun touches it, the glass shimmers beautifully, casting mesmerizing reflections that dance with light.",
    },
    {
        "id": "url(#11_aquaBlue)",
        "color": "Aqua Blue",
        "type": "water ripple",
        "description" : "The aqau blue  glass captures the essence of rippling water, its surface delicately mimicking gentle waves. When the sun touches it, the glass shimmers beautifully, casting mesmerizing reflections that dance with light.",
    },
    {
        "id": "url(#12_oceanBlue)",
        "color": "Ocean Blue",
        "type": "water ripple",
        "description" : "colored transparent glass with a subtle, delicate texture that causes the glass to sparkle in the sunlight. ",
    },
    {
        "id": "url(#13_cobaltBlue)",
        "color": "Cobalt Blue",
        "type": "water ripple",
        "description" : "The colbatblue glass captures the essence of rippling water, its surface delicately mimicking gentle waves. When the sun touches it, the glass shimmers beautifully, casting mesmerizing reflections that dance with light.",
    },
    {
        "id": "url(#14_tealBlue)",
        "color": "Teal Blue",
        "type": "water ripple",
        "description" : "colored transparent glass with a subtle, delicate texture that causes the glass to sparkle in the sunlight. ",
    },
    {
        "id": "url(#01_yellow)",
        "color": "Yellow",
        "type": "water ripple",
        "description" : "he yellow glass captures the essence of rippling water, its surface delicately mimicking gentle waves. When the sun touches it, the glass shimmers beautifully, casting mesmerizing reflections that dance with light.",
    },
    {
        "id": "url(#02_lightAmber)",
        "color": "Light Amber",
        "type": "water ripple",
        "description" : "The light amber  glass captures the essence of rippling water, its surface delicately mimicking gentle waves. When the sun touches it, the glass shimmers beautifully, casting mesmerizing reflections that dance with light.",
    },
    {
        "id": "url(#03_darkAmber)",
        "color": "Dark Amber",
        "type": "water ripple",
        "description" : "The dark amber  glass captures the essence of rippling water, its surface delicately mimicking gentle waves. When the sun touches it, the glass shimmers beautifully, casting mesmerizing reflections that dance with light.",
    },
    {
        "id": "url(#04_cherry)",
        "color": "Cherry Red",
        "type": "water ripple",
        "description" : "The light amber  glass captures the essence of rippling water, its surface delicately mimicking gentle waves. When the sun touches it, the glass shimmers beautifully, casting mesmerizing reflections that dance with light.",
    },
    {
        "id": "url(#05_redAmber)",
        "color": "Amber Red",
        "type": "water ripple",
        "description" : "The red and  amber glass captures the essence of rippling water, its surface delicately mimicking gentle waves. When the sun touches it, the glass shimmers beautifully, casting mesmerizing reflections that dance with light.",
    },
    {
        "id": "url(#06_orange)",
        "color": "Orange",
        "type": "water ripple",
        "description" : "The Orange   glass captures the essence of rippling water, its surface delicately mimicking gentle waves. When the sun touches it, the glass shimmers beautifully, casting mesmerizing reflections that dance with light.",
    },
    {
        "id": "url(#00_bevel)",
        "color": "Bevel",
        "type": "beveled glass",
        "description" : "Beveled Clear glass is thicker than stained glass, allowing its edges to be shaped through a process of grinding and smoothing on three wheels. This creates a distinctive edge that reflects light, adding vibrancy and a multi-dimensional look to the glass. Because it is crafted by hand, this option will increase the cost of your project.",
    }
 

];

export default glassData;