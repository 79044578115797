import React, { useState } from 'react';
import {NavLink} from 'react-router-dom'
import { useSelector } from 'react-redux';
import InputIcon from '@mui/icons-material/Input';
import LogoutIcon from '@mui/icons-material/Logout';

import { useHistory } from "react-router-dom";


export default function NavBar2() {
    const {token} = useSelector(state => state.auth);
    
    return (
        <>
        {(!token) ?
            ( 
            <ul className="login">
            <li>
                <NavLink className="nav-bar-link" to="/login"><InputIcon/> Login/Create Account</NavLink>
            </li>
            </ul>
            )
        :
            (
            <ul  className="login">
                <li>
                <NavLink className="nav-bar-link" to="/logout"><LogoutIcon/> Log Out</NavLink>
                </li>
            </ul>
            )}
        </>

    )
};