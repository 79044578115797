import React from 'react';
import Patterns from './patterns';

export default function Put01(props) {
    
    return (
        <div className={`Put01-container ${props.size}`}> 
 
            <svg  xmlns="http://www.w3.org/2000/svg" id={props.id} width="500" height="500" viewBox="0 0 500 500">
                
<Patterns />
            <g className="hummingbird-image" >

<path id="background" fill="black"  d="m0,500V0h752v752H0Z"/>

<path onClick={() => props.onFill(1)} fill={props.fillColors[1]}  d="M431.32,373.87c-9.1-9.06-18.04-17.93-26.94-26.83-6.41-6.41-12.82-12.81-19.08-19.36-1.22-1.27-2.12-3.07-2.59-4.78-3.2-11.69-5.61-23.64-9.55-35.07-3.03-8.77-7.28-17.32-12.26-25.17-3.58-5.64-5.35-11.26-6.19-17.68-2.88-22.08-9.86-42.73-22.49-61.26-1.61-2.36-3.37-4.63-5.49-7.52,27.03-26.35,54.07-52.7,81.13-79.06,71.29,69.35,84.05,191.08,23.47,276.72Z"/>
  <path onClick={() => props.onFill(2)} fill={props.fillColors[2]}  d="M69.37,375.05C12.14,293.85,18.76,181.81,81.28,109.04c30.12,23.22,60.25,46.45,90.19,69.53-4.12,6.31-8.43,12.18-11.98,18.48-9.81,17.41-14.26,36.21-13.82,56.2,.04,1.72-.39,3.87-1.44,5.12-13.96,16.6-20.6,36.25-24.32,57.18-.48,2.71-.86,5.45-1.44,8.14-.22,1.03-.68,2.17-1.4,2.9-15.77,16.09-31.59,32.13-47.69,48.47Z"/>
  <path onClick={() => props.onFill(3)} fill={props.fillColors[3]}  d="M71.48,378.4c8.17-8.2,16.1-16.2,24.06-24.15,7.66-7.65,15.4-15.22,23-22.94,1.53-1.55,2.78-1.99,4.87-1.14,13.86,5.62,28.09,10.03,43.11,10.91,7.91,.46,15.88-.06,23.82-.05,1.47,0,3.24-.07,4.37,.67,15.94,10.32,33.39,15.84,52.32,16.85,.33,.02,.65,.11,.99,.17v111.12c-35.76-.55-69.03-8.82-100.22-25.2-30.62-16.07-56.05-38.18-76.31-66.24Z"/>
  <path onClick={() => props.onFill(4)} fill={props.fillColors[4]}  d="M429.7,377.22c-45.12,59.51-103.79,90-177.15,92.86v-111.22c4.52-.5,8.65-.85,12.76-1.44,14.51-2.07,27.98-7.07,40.35-14.92,2.32-1.47,4.49-1.89,7.15-1.55,15.46,1.97,30.85,2.22,45.81-3.09,6.65-2.36,13.17-5.08,19.8-7.49,1.01-.37,2.78-.39,3.43,.23,15.89,15.35,31.68,30.81,47.86,46.61Z"/>
  <path onClick={() => props.onFill(5)} fill={props.fillColors[5]}  d="M83.76,105.43C127.92,56.82,182.26,31.72,247.93,29.71c0,7-.06,13.48,.01,19.96,.15,13.58,.31,27.16,.62,40.74,.06,2.78-.84,4.55-2.86,6.49-8.49,8.18-17.09,16.31-24.95,25.08-7.35,8.19-11.81,18.31-15.99,28.42-1.03,2.49-2.59,4.13-4.96,5.42-9.38,5.11-17.45,11.93-25.05,19.76-30.24-23.31-60.37-46.54-91.01-70.15Z"/>
  <path onClick={() => props.onFill(6)} fill={props.fillColors[6]}  d="M404.39,93.17c-27.11,26.47-53.96,52.7-81.02,79.13,.1,.1-.55-.44-1.12-1.05-6.13-6.46-13.12-11.76-20.9-16.11-1.68-.94-3.3-2.64-4.09-4.38-4.93-10.86-9.34-22.12-17.52-30.98-7.54-8.16-15.9-15.58-24.1-23.1-2.07-1.9-2.93-3.67-2.94-6.47-.06-19-.32-37.99-.51-56.99,0-.96,0-1.93,0-3.28,21.24,.16,41.74,3.3,61.76,9.44,34.16,10.47,64.08,28.15,90.44,53.8Z"/>
  <path onClick={() => props.onFill(7)} fill={props.fillColors[7]}  d="M469.83,29.9V202.42c-23.47-91.01-81.54-149.02-172.77-172.52h172.77Z"/>
  <path onClick={() => props.onFill(8)} fill={props.fillColors[8]}  d="M431.79,381.49c10.84,10.66,21.34,21.41,32.35,31.62,4.63,4.29,6.25,8.75,6.01,14.96-.49,12.57-.15,25.18-.15,37.77v3.78h-174.36c56.64-12.71,101.89-42.06,136.15-88.13Z"/>
  <path onClick={() => props.onFill(9)} fill={props.fillColors[9]}  d="M67.69,380.72c17.2,23.41,36.97,42.31,60.3,57.41,23.29,15.08,48.55,25.45,75.61,31.58H30.17c-.09-.84-.26-1.68-.26-2.51-.02-15.14-.04-30.28,.04-45.42,0-1.19,.44-2.69,1.23-3.51,11.96-12.41,24.01-24.73,36.52-37.55Z"/>
  <path onClick={() => props.onFill(10)} fill={props.fillColors[10]}  d="M80.4,102.84c-10.58-8.16-20.89-16.12-31.21-24.07-5.77-4.45-11.58-8.87-17.3-13.38-.86-.68-1.9-1.82-1.91-2.75-.13-10.81-.09-21.63-.09-32.68H204.99c-49.72,10.8-90.99,34.97-124.59,72.88Z"/>
  <path onClick={() => props.onFill(11)} fill={props.fillColors[11]}  d="M495.83,252.49v243.12h-21.57v-3.54c0-78.56,0-157.11,0-235.67q0-4.38,4.28-4.39c5.41,0,10.83,.03,16.24,.08,.3,0,.6,.22,1.05,.4Z"/>
  <path onClick={() => props.onFill(12)} fill={props.fillColors[12]}  d="M30.04,25.33V4H247.58V25.33H30.04Z"/>
  <path onClick={() => props.onFill(13)} fill={props.fillColors[13]}  d="M469.86,4V25.33H252.31V4h217.54Z"/>
  <path onClick={() => props.onFill(14)} fill={props.fillColors[14]}  d="M25.46,247.5H4.21V29.91H25.46V247.5Z"/>
  <path onClick={() => props.onFill(15)} fill={props.fillColors[15]}  d="M495.68,247.59h-21.11V29.9h21.11V247.59Z"/>
  <path onClick={() => props.onFill(16)} fill={props.fillColors[16]}  d="M25.37,469.66H4.11V252.31H25.37v217.35Z"/>
  <path onClick={() => props.onFill(17)} fill={props.fillColors[17]}  d="M469.83,474.49v21.12H252.33v-21.12h217.51Z"/>
  <path onClick={() => props.onFill(18)} fill={props.fillColors[18]}  d="M247.64,474.5v21.1H30.11v-21.1H247.64Z"/>
  <path onClick={() => props.onFill(19)} fill={props.fillColors[19]}  d="M77.58,106.18c-23.73,28.88-39.54,61.39-47.51,97.76V69.58c15.99,12.32,31.67,24.4,47.51,36.61Z"/>
  <path onClick={() => props.onFill(20)} fill={props.fillColors[20]}  d="M334.59,267.11c3.78,6.59,7.25,12.51,10.54,18.53,.36,.65-.16,1.92-.52,2.8-14.66,35.59-40.68,57.67-78.67,64.43-22.33,3.97-43.64-.45-63.45-11.63-.29-.16-.58-.32-.84-.52-.17-.13-.28-.33-.67-.82,3.95-.99,7.91-1.5,11.49-2.97,7.62-3.12,14.89-2.8,22.98-1.13,22.82,4.72,44.07-.31,63.12-13.66,18.62-13.04,30-31.05,35.09-53.12,.07-.29,.26-.55,.94-1.92Z"/>
  <path onClick={() => props.onFill(21)} fill={props.fillColors[21]}  d="M193.89,315.51c-7.06,.19-13.3,.45-19.54,.44-.93,0-2.1-1.07-2.76-1.94-12.61-16.6-19.94-35.27-21.17-56.11-2.12-36.19,10.83-66.02,38.52-89.4,3.34-2.82,7.08-5.17,11.31-7.27-.33,1.09-.68,2.17-.98,3.26-1.76,6.36-3.4,12.76-5.33,19.07-.58,1.89-1.67,3.79-3.01,5.24-18.33,19.83-26.48,43.17-24.08,70.06,1.91,21.38,10.71,39.62,25.79,54.85,.22,.23,.37,.53,1.24,1.79Z"/>
  <path onClick={() => props.onFill(22)} fill={props.fillColors[22]}  d="M350.63,248.51c-5.42-4.98-10.51-9.53-15.43-14.27-.93-.89-1.32-2.47-1.66-3.82-7.23-28.87-24.17-49.79-51.64-61.35-17.61-7.41-35.98-8.13-54.42-2.52-1.05,.32-2.12,.57-3.18,.84-.09,.02-.2-.06-1.19-.41,3.15-6.08,6.19-12.08,9.4-17.99,.37-.68,1.61-1.09,2.51-1.25,33.52-6.03,74.49,7.75,94.89,40.34,11.35,18.12,17.85,37.75,20.7,58.76,.06,.43,0,.87,0,1.67Z"/>
  <path onClick={() => props.onFill(23)} fill={props.fillColors[23]}  d="M269.69,300.95c16.22-16.61,28.08-35.56,33.86-58.72,4.83,3.16,9.44,5.85,13.67,9.04,4.04,3.04,7.77,6.51,11.44,10,.85,.81,1.44,2.58,1.23,3.74-3.37,18.74-12.11,34.59-26.14,47.46-.92,.85-2.62,1.67-3.69,1.41-10.65-2.61-20.62-6.89-30.37-12.92Z"/>
  <path onClick={() => props.onFill(24)} fill={props.fillColors[24]}  d="M281.67,231.52c-4.08,21.83-17.82,37.96-30.73,54.83-16.2-14.66-24.7-33.36-30.2-54.27,20.3-5.77,40.51-5.98,60.93-.56Z"/>
  <path onClick={() => props.onFill(25)} fill={props.fillColors[25]}  d="M283.62,205.18c-22.15-3.43-43.97-3.78-66.05,1.67,0-3.04-.29-5.77,.05-8.42,.97-7.54,2.06-15.07,3.43-22.55,.25-1.36,1.82-3.06,3.17-3.55,17.18-6.31,34.5-6.49,51.9-.76,2.03,.67,3.15,1.69,3.53,3.77,1.28,7.05,2.77,14.05,3.89,21.12,.42,2.67,.07,5.46,.07,8.71Z"/>
  <path onClick={() => props.onFill(26)} fill={props.fillColors[26]}  d="M198.51,241.76c4.35,23.46,15.85,42.54,33.18,59.58-7.97,3.52-15.03,7.46-22.58,9.8-11.72,3.64-8.23,4.98-17.15-4.66-10.11-10.93-16.32-24-19.34-38.56-.28-1.34-.15-3.28,.64-4.24,6.65-8.02,13.87-15.44,23-20.73,.56-.33,1.15-.61,2.26-1.19Z"/>
  <path onClick={() => props.onFill(27)} fill={props.fillColors[27]}  d="M282.87,226.82c-43.09-8.76-80.88-1.6-111.58,31.85,0-6.21,.03-12.18,0-18.15-.03-3.92,2.07-6.19,5.17-8.43,14.56-10.51,30.01-18.89,47.87-22.28,14.85-2.82,29.75-3.44,44.76-1.87,4.91,.51,9.83,1.04,15.31,1.63-.52,5.9-1.02,11.54-1.53,17.26Z"/>
  <path onClick={() => props.onFill(28)} fill={props.fillColors[28]}  d="M202.44,239.16c4.57-1.98,9.07-3.92,14.07-6.09,10.7,43.8,38.16,71.08,80.93,85.02-5.03,2.8-9.62,5.25-14.1,7.88-3.37,1.98-6.27,1.5-9.83-.07-25.83-11.38-44.71-30.33-59.35-53.91-6.12-9.86-9.44-20.85-11.73-32.84Z"/>
  <path onClick={() => props.onFill(29)} fill={props.fillColors[29]}  d="M265.72,298.58c-3.74-2.88-7.55-5.81-11.63-8.95,5.32-7.11,10.52-13.81,15.44-20.7,7.7-10.79,13.42-22.53,16.32-35.55,4.12-18.47,3.3-36.84-.95-55.15-.17-.75-.33-1.51-.45-2.27-.03-.2,.09-.42,.2-.85,6.79,3.16,12.91,7.21,18.4,12.21,.55,.5,.9,1.54,.87,2.32-.51,13.89,.2,27.84-2.31,41.63-4.74,26.13-16.69,48.39-35.9,67.31Z"/>
  <path onClick={() => props.onFill(30)} fill={props.fillColors[30]}  d="M65.06,376.91c-11.81,12.07-23.28,23.79-34.97,35.73v-118.09c6.44,30.6,18.18,57.94,34.97,82.36Z"/>
  <path onClick={() => props.onFill(31)} fill={props.fillColors[31]}  d="M234.21,304.35c4.25,3.85,8.24,7.47,12.23,11.1-29.62,23.64-79.09,29.18-120.9,10.7,6.23-3.58,12.18-7.1,18.27-10.38,.97-.52,2.59-.13,3.83,.17,10.63,2.57,21.26,5.11,32.31,4.71,17.36-.63,33.82-4.59,48.92-13.45,1.71-1,3.5-1.87,5.34-2.85Z"/>
  <path onClick={() => props.onFill(32)} fill={props.fillColors[32]}  d="M434.72,377.18c16.94-24.69,28.56-51.85,35.11-81.16v115.8c-11.59-11.44-23.26-22.95-35.11-34.64Z"/>
  <path onClick={() => props.onFill(33)} fill={props.fillColors[33]}  d="M248.5,100.51c0,7.47,.07,14.4-.08,21.33-.02,1.01-.98,2.14-1.76,2.98-8.06,8.64-16,17.33-21.51,27.97-6.01,11.6-9.71,23.84-11.06,36.8-.56,5.37-.96,10.77-1.22,16.16-.09,1.86-.71,2.7-2.45,3.27-4.26,1.42-8.45,3.05-12.68,4.61-5.41-30.35,12.72-85.44,50.76-113.12Z"/>
  <path onClick={() => props.onFill(34)} fill={props.fillColors[34]}  d="M377.95,322.41c-6.15-3.55-11.81-6.67-17.29-10.09-1.01-.63-1.34-2.43-1.88-3.73-4.13-10.08-7.64-20.47-12.48-30.2-8.14-16.36-21.05-28.4-36.78-37.58-5.99-3.49-4.73-1.81-3.72-8.46,.61-4.01,1.17-8.03,1.83-12.61,16.55,9.8,30.84,21.79,43.05,36.09,16.16,18.94,22.4,42.13,27.27,66.59Z"/>
  <path onClick={() => props.onFill(35)} fill={props.fillColors[35]}  d="M375.92,326.31c-19.53,9.15-39.34,14.48-59.68,10.4,4.23-4.65,8.84-9.8,13.56-14.83,.59-.63,1.8-.74,2.76-.93,7.45-1.48,14.9-3.02,22.39-4.3,1.68-.29,3.76-.07,5.24,.71,5.26,2.76,10.35,5.85,15.72,8.94Z"/>
  <path onClick={() => props.onFill(36)} fill={props.fillColors[36]}  d="M252.77,100.15c17.83,13.37,31.63,29.04,39.12,50.18-3.22-1.16-6.15-2.73-9.26-3.25-7.58-1.27-13.01-4.77-16.94-11.7-2.41-4.25-6.79-7.43-10.51-10.85-1.57-1.44-2.54-2.69-2.48-4.97,.17-6.27,.06-12.54,.06-19.41Z"/>
  <path onClick={() => props.onFill(37)} fill={props.fillColors[37]}  d="M123.19,322.44c3.64-21.75,8.78-41.97,22.71-58.79,1.71,6.87,3.44,13.56,4.98,20.29,.24,1.06-.22,2.35-.6,3.46-2.46,7.28-4.96,14.55-7.5,21.81-.34,.99-.72,2.28-1.49,2.74-5.75,3.46-11.6,6.75-18.11,10.49Z"/>
  <path onClick={() => props.onFill(38)} fill={props.fillColors[38]}  d="M4.1,3.97H25.45V25.34H4.1V3.97Z"/>
  <path onClick={() => props.onFill(39)} fill={props.fillColors[39]}  d="M474.45,3.96h21.33V25.33h-21.33V3.96Z"/>
  <path onClick={() => props.onFill(40)} fill={props.fillColors[40]}  d="M25.39,495.69H4.21v-21.26H25.39v21.26Z"/>
  <path onClick={() => props.onFill(41)} fill={props.fillColors[41]}  d="M271.61,330.2c-13.63,3.69-26.85,3.95-40.24,.42,6.19-3.95,12.22-7.83,18.3-11.62,.51-.32,1.54-.33,2.07-.03,6.52,3.62,12.99,7.32,19.86,11.23Z"/>
  <path onClick={() => props.onFill(42)} fill={props.fillColors[42]}  d="M309.05,193.07c9.26,9.97,15.6,21.55,19.35,34.97-6.72-4.41-13.08-8.53-19.35-12.77-.66-.44-1.16-1.6-1.17-2.44-.1-6.5-.05-13-.05-19.5l1.23-.27Z"/>
  <path onClick={() => props.onFill(43)} fill={props.fillColors[43]}  d="M173.65,228.44c3.51-13.18,9.6-24.52,19.26-34.24,0,6.89,.02,13.78-.04,20.67,0,.67-.32,1.64-.82,1.96-5.98,3.85-12.03,7.61-18.41,11.61Z"/>
  <path onClick={() => props.onFill(44)} fill={props.fillColors[44]}  d="M236.74,142.4c4.78-5.34,9.38-10.47,14.08-15.71,6.19,4.38,11.04,9.49,14.6,15.71h-28.68Z"/>
  <path onClick={() => props.onFill(45)} fill={props.fillColors[45]}  d="M348.39,292.47c2.33,6.5,4.61,12.85,7.03,19.59-7.04,1.54-13.68,2.99-20.69,4.52,4.63-8.18,8.97-15.85,13.66-24.11Z"/>
  <path onClick={() => props.onFill(46)} fill={props.fillColors[46]}  d="M166.45,315.63c-6.54-1.45-12.83-2.84-20.03-4.43,2.19-6.91,4.24-13.39,6.39-20.16,4.69,8.45,9.03,16.27,13.65,24.59Z"/>



    </g>
            </svg>
        </div>
    )
}