import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import swal from 'sweetalert';
//import { GoogleLogin, useGoogleLogin, googleLogout } from '@react-oauth/google';
//import axios from 'axios';

export default function Auth() {

    const [form, setForm] = useState({ username: '', password: '' });

    const dispatch = useDispatch();
    let history = useHistory();

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    }
    
    const handleSubmit = (e) => {
        e.preventDefault();
        const endpoint = '/login';
        const config = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(form)
        };
        fetch(`https://test-api.stainedglassusa.com/${endpoint}`, config)
        .then(r => r.json())
        .then(response => { 
            if (response.error) {
                swal("Login Failed", response.error || "Invalid username or password", "error");
                return;
            }
            
            dispatch({
                type: 'SET_USER',
                payload: response 
            });

            if (response.token) {
                localStorage.setItem('token', response.token);
                console.log("Token saved to localStorage"); // Add log for verification
              } else {
                console.error("No token received in login response:", response);
              }

              const welcomeName = response.user?.username || form.username;
              swal("Login Success", `Welcome ${welcomeName}!`, "success");  
            history.push("/mygallery");
        });
    }

    const handleCreateAccount = () => {
        history.push("/signup");
    }


    return (
        <div>
            <div className="form-page" style={{ minHeight: '75vh' }}>
                <h3 className="login-signup-title">Login</h3>
                <form onSubmit={handleSubmit} className="form">
                    <input
                        type="text"
                        name="username"
                        value={form.username}
                        onChange={handleChange}
                        placeholder="Username"
                        style={{ marginBottom: "10px" }}
                        className="input"
                    />
                    <input
                        type="password"
                        name="password"
                        value={form.password}
                        onChange={handleChange}
                        placeholder="Password"
                        className="input"
                    />
                    <input type="submit" id="submit-button" value="Login" />
                </form>

                <div>
                <hr className="form-divider" />
                    {/* <button onClick={handleFacebookLogin}>Login with Facebook</button> */}

                    {/* {profile ? (
                <div>
                    <img src={profile.picture} alt="user image" />
                    <h3>User Logged in</h3>
                    <p>Name: {profile.name}</p>
                    <p>Email Address: {profile.email}</p>
                    <br />
                    <br />
                    <button onClick={logOut}>Log out</button>
                </div>
            ) : (
                // <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
                null
            )} */}
                </div>
                <hr className="form-divider" />
                <h4>Don't have an account? </h4>
                <button onClick={handleCreateAccount} className="HeroButton">Create a free account</button>
                <p>It just takes a minute and you will start coloring your masterpiece free!</p>

            </div>
            <div className="footer"></div>
        </div>
    );
}
