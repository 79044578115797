// authReducer.js (Corrected - Pure Function)

// Define a clear initial state structure
const initialState = {
  user: null,
  token: null
};

// Reducer function - MUST be pure (no side effects)
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_USER':
      // Payload IS { user: { id: ..., admin: ... }, token: "..." }
      // Update state structure correctly:
      return {
        // Keep other potential state properties if needed (...)
        ...state,
        user: payload.user,   // Extract user object from payload
        token: payload.token  // Extract token from payload
      };

    case 'CLEAR_USER':
      // Return the initial state structure (or explicitly null values)
      // Do NOT interact with localStorage here.
      return {
        ...state,
        user: null,
        token: null
      };

    default:
      return state;
  }
};