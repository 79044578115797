import React from 'react';


export default function Put03(props) {
    
    return (
        <div className={`Put03-container ${props.size}`}> 
 
            <svg  xmlns="http://www.w3.org/2000/svg" id={props.id} width="500" height="500" viewBox="0 0 500 500">
                
            <pattern id="01_yellow" width="100%" height="100%">
                      <image xlinkHref="/01_yellow.jpg"/>
              </pattern>
          <pattern id="02_lightAmber" width="100%" height="100%">
              <image xlinkHref="/02_lightAmber.jpg"/>
          </pattern>
          <pattern id="03_darkAmber" width="100%" height="100%">
              <image xlinkHref="/03_darkAmber.jpg"/>
          </pattern>
          <pattern id="04_cherry" width="100%" height="100%">
              <image xlinkHref="/04_cherry.jpg"/>
          </pattern>
          <pattern id="05_redAmber" width="100%" height="100%">
              <image xlinkHref="/05_redAmber.jpg"/>
          </pattern>    
          <pattern id="06_orange" width="100%" height="100%">
              <image xlinkHref="/06_orange.jpg"/>
          </pattern>
          <pattern id="07_green" width="100%" height="100%">
              <image xlinkHref="/07_green.jpg"/>
          </pattern>
          <pattern id="08_olive" width="100%" height="100%">
              <image xlinkHref="/08_olive.jpg"/>
          </pattern>
          <pattern id="09_purple" width="100%" height="100%">
              <image xlinkHref="/09_purple.jpg"/>
          </pattern>
          <pattern id="10_lightBlue" width="100%" height="100%">
              <image xlinkHref="/10_lightBlue.jpg"/>
          </pattern>  
          <pattern id="11_aquaBlue" width="100%" height="100%">
              <image xlinkHref="/11_aquaBlue.jpg"/>
          </pattern>
          <pattern id="12_oceanBlue" width="100%" height="100%">
              <image xlinkHref="/12_oceanBlue.jpg"/>
          </pattern>
          <pattern id="13_cobaltBlue" width="100%" height="100%">
              <image xlinkHref="/13_cobaltBlue.jpg"/>
          </pattern>
          <pattern id="14_tealBlue" width="100%" height="100%">
              <image xlinkHref="/14_tealBlue.jpg"/>
          </pattern>
          <pattern id="15_clear" width="100%" height="100%">
              <image xlinkHref="/15_clear.jpg"/>
          </pattern>  
          <pattern id="16_granite" width="100%" height="100%">
              <image xlinkHref="/16_granite.jpg"/>
          </pattern>
          <pattern id="17_crackle" width="100%" height="100%">
              <image xlinkHref="/17_crackle.jpg"/>
          </pattern>
          <pattern id="18_iridescent" width="100%" height="100%">
              <image xlinkHref="/18_iridescent.jpg"/>
          </pattern>
          <pattern id="19_taffy" width="100%" height="100%">
              <image xlinkHref="/19_taffy.jpg"/>
          </pattern>
          <pattern id="20_seedy" width="100%" height="100%">
              <image xlinkHref="/20_seedy.jpg"/>
          </pattern> 
          <pattern id="21_frosted" width="100%" height="100%">
              <image xlinkHref="/21_frosted.jpg"/>
          </pattern>                                      
            <g className="hummingbird-image" >

<path id="background" fill="black"  d="m0,500V0h500v500H0Z"/>

  <path onClick={() => props.onFill(1)} fill={props.fillColors[1]} d="M78.66,23.01H247.65v9.99c.11,15.69,.22,31.38,.22,47.08,0,1.76-.55,3.84-1.54,5.38-8.45,13.94-17.12,27.65-25.68,41.48-1.21,1.87-2.41,2.63-4.83,2.63-33.36-.11-66.72-.11-100.08,0-11.41,0-22.94,.11-34.35,.33-2.19,0-2.85-.55-2.85-2.85,.11-33.8,0-67.6,0-101.4,.11-.88,.11-1.65,.11-2.63Z"/>
  <path onClick={() => props.onFill(2)} fill={props.fillColors[2]} d="M252.81,23.01h165.37V127.26c-.99,0-1.87,.11-2.74,.11h-62.44c-23.15,0-46.42-.11-69.57-.11-1.98,0-3.07-.55-4.06-2.19-8.34-13.61-16.79-27.11-25.24-40.71-.55-.88-.88-2.19-.88-3.18-.22-15.58-.22-31.17-.33-46.75-.11-3.84-.11-7.57-.11-11.41Z"/>
  <path onClick={() => props.onFill(3)} fill={props.fillColors[3]} d="M162.93,477.1H81.84q-3.07,0-3.07-3.18v-95.25q0-3.07,3.18-3.07c45.54,0,91.19,.11,136.73,.11,1.76,0,2.74,.44,3.62,1.98,8.23,14.05,16.46,28.09,24.58,42.14,.66,1.21,.99,2.74,.99,4.17,0,17.01-.22,33.91-.33,50.92,0,2.3-1.32,2.19-2.85,2.19h-81.75Z"/>
  <path onClick={() => props.onFill(4)} fill={props.fillColors[4]} d="M335.44,477.1h-79.56q-3.29,0-3.29-3.29c.11-16.79,.33-33.69,.55-50.48,0-.99,.33-2.19,.77-3.07,8.34-14.27,16.68-28.42,25.02-42.69,1.1-1.76,2.3-2.41,4.39-2.41,43.89,.11,87.9,0,131.79,0q3.18,0,3.18,3.18v95.69q0,3.07-3.18,3.07c-26.45,0-53.11,0-79.67,0Z"/>
  <path onClick={() => props.onFill(5)} fill={props.fillColors[5]} d="M217.36,370.44c-1.32,.11-2.19,.11-2.96,.11h-47.96c-28.31-.11-56.73-.22-85.05-.22-2.19,0-2.85-.66-2.85-2.85,.11-30.07,.11-60.14,0-90.09,0-1.87,.55-3.29,1.87-4.61,5.93-5.71,11.63-11.63,17.45-17.34,.77-.77,2.09-1.32,3.18-1.32,10.86-.11,21.62-.11,32.48,0,.77,0,1.65,0,2.19,.33,6.26,3.29,12.73,6.47,18.77,10.21,6.04,3.62,10.53,9,14.92,14.49,3.95,4.94,8.23,9.66,12.73,14.16,1.87,1.87,4.5,2.85,6.69,4.39,.77,.55,1.54,1.65,1.43,2.41-.99,13.61,2.41,26.34,8.56,38.19,5.16,10.1,11.08,19.75,16.68,29.63,.77,.55,1.21,1.32,1.87,2.52Z"/>
  <path onClick={() => props.onFill(6)} fill={props.fillColors[6]} d="M215.39,135.05c-2.74,4.39-5.38,8.45-7.9,12.73-6.69,11.52-12.73,23.26-15.47,36.43-1.32,5.93-1.98,11.96-1.43,18.11,.11,.88-.44,2.3-1.1,2.52-7.46,3.51-12.73,9.44-17.67,15.91s-9.88,13.06-17.01,17.34c-5.93,3.62-12.18,6.8-18.44,9.99-.99,.55-2.41,.66-3.62,.66-10.43,0-20.74,.11-31.17,0-1.32,0-2.85-.66-3.84-1.65-6.04-5.82-11.96-11.74-17.78-17.78-.77-.88-1.54-2.19-1.54-3.4-.11-29.74-.11-59.48-.11-89.22,0-.44,.11-.88,.11-1.65,45.76,0,91.19,0,136.95,0Z"/>
  <path onClick={() => props.onFill(7)} fill={props.fillColors[7]} d="M284.3,132.75h131.25c2.3,0,2.96,.66,2.96,2.96-.11,29.85-.11,59.59,0,89.44,0,1.87-.44,3.18-1.76,4.5-5.93,5.82-11.74,11.74-17.67,17.56-.99,.88-2.52,1.54-3.84,1.65-8.34,.11-16.79,.11-25.13,0-1.21,0-2.52-.11-3.62-.55-6.58-3.18-13.28-6.36-19.75-9.88-6.58-3.62-11.19-9.44-15.91-15.14-3.84-4.72-8.01-9.33-12.29-13.61-1.87-1.87-4.5-2.85-6.69-4.39-.66-.44-1.43-1.54-1.32-2.19,.99-13.94-2.41-27-8.67-39.29-4.83-9.44-10.43-18.55-15.69-27.87-.55-1.1-.99-1.87-1.87-3.18Z"/>
  <path onClick={() => props.onFill(8)} fill={props.fillColors[8]} d="M283.97,369.78c2.08-3.29,3.84-6.04,5.49-8.78,7.24-11.63,13.94-23.48,18-36.65,2.41-7.9,3.51-15.91,3.07-24.14,0-.88,.33-2.19,.99-2.52,8.67-4.06,14.27-11.19,19.75-18.66,6.26-8.56,14.27-14.7,23.7-19.53,8.34-4.28,16.79-6.69,26.23-5.82,4.72,.44,9.55,0,14.27,.11,1.21,0,2.52,.55,3.4,1.32,6.04,5.93,11.96,11.96,18,17.89,1.21,1.21,1.65,2.41,1.65,4.06,0,29.85-.11,59.81,0,89.66,0,2.41-.77,2.96-2.96,2.96-42.8-.11-85.6,0-128.39,0-.88,.11-1.76,.11-3.18,.11Z"/>
  <path onClick={() => props.onFill(9)} fill={props.fillColors[9]} d="M477.22,190.69v55.09c0,2.3-.44,3.07-2.96,3.07-9.11-.11-18.33,0-27.43-.11-1.32,0-3.07-.66-3.95-1.65-6.04-5.82-11.96-11.74-17.78-17.78-.88-.88-1.54-2.41-1.54-3.62-.11-30.18,0-60.25-.11-90.42,0-1.87,.55-2.52,2.52-2.52,16.13,.11,32.26,.11,48.39,0,2.19,0,2.85,.55,2.85,2.74,0,18.44,0,36.76,0,55.2Z"/>
  <path onClick={() => props.onFill(10)} fill={props.fillColors[10]} d="M477.22,311.95v55.09c0,2.3-.66,2.85-2.85,2.85-16.02-.11-31.93-.11-47.96,0-2.41,0-2.96-.77-2.96-2.96,.11-29.85,.11-59.59,0-89.44,0-1.87,.55-3.18,1.87-4.5,5.93-5.82,11.74-11.74,17.67-17.56,.88-.88,2.41-1.43,3.62-1.43,9.33-.11,18.77,.11,28.09,0,2.08,0,2.52,.77,2.52,2.63,0,18.44,0,36.87,0,55.31Z"/>
  <path onClick={() => props.onFill(11)} fill={props.fillColors[11]} d="M73.17,370.11H22.91v-116.54h11.08c5.49,.11,11.08,.11,16.57,.44,1.1,0,2.41,.55,3.18,1.32,6.15,6.04,12.18,12.07,18.22,18.22,.66,.77,1.21,1.98,1.21,2.96,.11,30.51,0,61.01,0,91.52,.11,.66,.11,1.43,0,2.08Z"/>
  <path onClick={() => props.onFill(12)} fill={props.fillColors[12]} d="M423.78,23.01h53.44V124.52c0,2.3-.55,2.96-2.85,2.96-16.02-.11-31.93,0-47.96-.11-.88,0-1.65-.11-2.63-.11V23.01Z"/>
  <path onClick={() => props.onFill(13)} fill={props.fillColors[13]} d="M22.91,135.05h50.37v90.42c0,1.65-.44,2.85-1.65,4.06-5.82,5.71-11.63,11.63-17.56,17.34-.88,.88-2.52,1.54-3.73,1.54-8.56,.11-17.01-.11-25.57-.11-.55,0-1.21-.11-1.87-.22v-113.03Z"/>
  <path onClick={() => props.onFill(14)} fill={props.fillColors[14]} d="M477.22,375.16v98.87q0,3.07-3.18,3.07h-47.52c-2.3,0-2.96-.66-2.96-2.96,.11-32.15,.11-64.2,0-96.35,0-2.19,.55-2.85,2.74-2.85,16.02,.11,32.15,0,48.17,.11,.88,0,1.65,0,2.74,.11Z"/>
  <path onClick={() => props.onFill(15)} fill={props.fillColors[15]} d="M23.02,22.9h50.26V126.82c0,2.19-.55,2.85-2.74,2.85-15.03-.11-29.96-.11-44.99,0-1.98,0-2.85-.33-2.85-2.63,.22-33.91,.22-67.82,.22-101.73,0-.77,.11-1.43,.11-2.41Z"/>
  <path onClick={() => props.onFill(16)} fill={props.fillColors[16]} d="M22.91,375.71h50.26c0,.88,.11,1.65,.11,2.52v96.13c0,2.19-.66,2.85-2.85,2.85-14.92-.11-29.96-.11-44.88,0-2.09,0-2.74-.66-2.74-2.74v-96.13c.11-.77,.11-1.54,.11-2.63Z"/>
  <path onClick={() => props.onFill(17)} fill={props.fillColors[17]} d="M250.83,359.46c-1.76-2.85-2.85-5.38-4.61-7.46-2.41-2.74-5.05-5.38-7.79-7.79-6.91-6.04-11.52-13.39-13.28-22.39-1.87-9-1.54-17.89,.88-26.89,3.51-12.95,11.41-23.15,19.42-33.36,.33-.44,1.43-.44,2.19-.44,2.08,0,4.06,0,6.15,.11,.77,0,1.76,.11,2.08,.55,7.02,8.78,14.05,17.56,17.78,28.31,5.38,15.36,5.38,30.62-3.18,45.1-1.87,3.29-4.72,6.04-7.46,8.67-4.72,4.5-9.66,8.78-12.18,15.58Z"/>
  <path onClick={() => props.onFill(18)} fill={props.fillColors[18]} d="M250.18,145.81c3.07,6.8,8.01,11.41,12.73,16.24,10.86,10.97,14.7,24.25,13.28,39.4-1.1,11.85-6.91,21.62-13.72,30.95-1.98,2.74-4.06,5.38-6.26,7.9-.55,.66-1.98,1.21-2.63,.99-2.19-.77-4.17-.33-6.36,.11-.55,.11-1.54-.11-1.87-.55-7.13-8.89-14.38-17.67-18-28.64-4.94-14.92-3.62-29.3,4.61-42.69,1.87-3.07,4.61-5.71,7.13-8.45,4.17-4.39,8.34-8.78,10.75-14.6,.11-.11,.22-.33,.33-.66Z"/>
  <path onClick={() => props.onFill(19)} fill={props.fillColors[19]} d="M291,239.41c-5.16-2.85-8.56-6.91-11.96-11.08-3.07-3.73-3.29-6.91-1.43-11.63,7.46-18.66,5.49-36.54-6.26-53.11-2.52-3.51-5.82-6.47-8.89-9.55-4.72-4.72-7.24-10.53-9.11-16.79-.11-.55-.11-1.1-.11-1.65v-40.05c0-.44,.11-.88,.22-1.98,.99,1.54,1.65,2.63,2.3,3.73,10.97,17.56,22.17,35.01,33.03,52.67,5.82,9.44,10.75,19.42,13.94,30.18,4.5,15.03,3.07,29.41-3.73,43.35-2.52,5.27-5.27,10.43-8.01,15.91Z"/>
  <path onClick={() => props.onFill(20)} fill={props.fillColors[20]} d="M247.87,94.45v2.41c0,11.52-.11,23.04,0,34.57,.11,8.56-3.4,15.58-8.78,21.84-3.84,4.5-8.34,8.67-11.63,13.61-7.9,12.07-9.99,25.68-7.13,39.72,.99,4.94,3.18,9.55,4.83,14.38,.22,.77,.44,1.76,.11,2.3-3.51,5.6-7.35,10.97-12.95,14.81-.66,.44-1.32,.77-2.41,1.43-3.62-7.68-7.46-15.03-10.53-22.72-5.38-13.28-4.5-26.67,0-40.05,4.83-13.94,12.4-26.34,20.19-38.63,9-14.16,17.89-28.42,26.89-42.69,.22-.33,.55-.77,.77-1.1,.22,.11,.44,.11,.66,.11Z"/>
  <path onClick={() => props.onFill(21)} fill={props.fillColors[21]} d="M290.89,263.33c4.83,9.66,10.32,18.66,12.84,28.97,2.63,10.97,1.65,21.73-1.98,32.37-4.39,13.17-11.41,24.91-18.77,36.54-9.88,15.69-20.19,31.17-28.75,47.74-.22,.33-.44,.66-.99,1.54-.11-1.54-.11-2.41-.11-3.18v-34.35c0-8.34,3.4-15.14,9-20.96,3.29-3.4,6.91-6.36,9.88-9.88,7.24-8.78,9.99-19.2,10.32-30.4,.33-10.53-1.98-20.41-6.58-29.85-.33-.77-.55-1.98-.22-2.52,3.62-5.6,7.46-11.08,13.17-14.92,.66-.33,1.32-.66,2.19-1.1Z"/>
  <path onClick={() => props.onFill(22)} fill={props.fillColors[22]} d="M247.21,409.72c-2.08-3.62-3.95-7.35-6.15-10.97-9.22-15.03-18.55-29.85-27.65-44.88-6.69-11.08-12.62-22.5-15.8-35.12-3.51-13.72-1.54-26.78,4.5-39.4,2.3-4.83,4.94-9.55,7.35-14.38,.66-1.43,1.32-1.54,2.63-.66,5.93,3.95,9.99,9.55,13.72,15.36,.33,.55,0,1.76-.44,2.52-5.16,10.32-7.24,21.18-6.47,32.59,.77,10.43,3.73,20.08,10.75,28.09,2.96,3.4,6.58,6.26,9.77,9.44,5.49,5.38,8.45,11.96,8.56,19.75,.11,11.63,0,23.37,0,35.01v2.41c-.33,0-.55,.11-.77,.22Z"/>
  <path onClick={() => props.onFill(23)} fill={props.fillColors[23]} d="M2.8,253.7h15.1c0,.8,.1,1.6,.1,2.4v121.6q0,2.7-2.8,2.8H2.7c.1-42.4,.1-84.5,.1-126.8Z"/>
  <path onClick={() => props.onFill(24)} fill={props.fillColors[24]} d="M497.3,378.9c-4.8,0-9.4,0-13.9-.1-.5,0-1.1-.8-1.3-1.4-.2-.5-.1-1.2-.1-1.9v-120.5q0-2.9,2.8-2.9h12.5v126.8Z"/>
  <path onClick={() => props.onFill(25)} fill={props.fillColors[25]} d="M497.4,247.2c-4.9,0-9.5,0-14-.1-.5,0-1.1-.9-1.4-1.5-.2-.5-.1-1.2-.1-1.9V126.8q0-2.9,2.8-2.9h10.9c1.3,0,1.8,.4,1.7,1.7v118.7c.1,.9,.1,1.8,.1,2.9Z"/>
  <path onClick={() => props.onFill(26)} fill={props.fillColors[26]} d="M2.6,125.6H12.8q5.2,0,5.2,5.2v115c0,1.9-.3,2.9-2.6,2.9-3.8-.1-7.7,.2-11.5,.2-.5,0-1.1-.7-1.4-1.3-.2-.4,0-1,0-1.4v-117.8c.1-.8,.1-1.6,.1-2.8Z"/>
  <path onClick={() => props.onFill(27)} fill={props.fillColors[27]} d="M137.5,2.6c1.1-.1,1.9-.1,2.7-.1h104.6q2.8,0,2.8,2.7c0,3.6-.1,7.1,0,10.7,0,1.4-.4,2-1.9,1.9-21.6-.1-43.1-.1-64.7-.1-13.6,0-27.2,0-40.7,.1q-2.7,0-2.7-2.7c-.1-4.1-.1-8.1-.1-12.5Z"/>
  <path onClick={() => props.onFill(28)} fill={props.fillColors[28]} d="M22.8,2.6c1-.1,1.9-.1,2.7-.1h104.6c2.1,0,2.8,.5,2.7,2.7-.2,3.5-.1,7,0,10.5,.1,1.7-.5,2.3-2.2,2.3-6.8-.1-13.6,0-20.4,0H25.6q-2.7,0-2.7-2.7c-.1-4.3-.1-8.3-.1-12.7Z"/>
  <path onClick={() => props.onFill(29)} fill={props.fillColors[29]} d="M307.5,17.8h-51.9c-3.8,0-3.3,.2-3.3-3.4,0-3.1,.1-6.3-.1-9.4-.1-2.1,.7-2.5,2.6-2.5,17.9,.1,35.8,0,53.8,0h51.2c2,0,2.7,.4,2.6,2.5-.2,3.6-.1,7.3,0,10.9,0,1.4-.3,2-1.9,2-13.4-.1-26.7-.1-40.1-.1h-12.9Z"/>
  <path onClick={() => props.onFill(30)} fill={props.fillColors[30]} d="M422.2,2.4h52.3c2.1,0,2.8,.5,2.7,2.7-.2,3.5-.1,7,0,10.5,.1,1.7-.5,2.3-2.2,2.3-10.2-.1-20.3,0-30.5,0h-74.6c-2.1,0-2.8-.5-2.7-2.7,.2-3.4,.2-6.9,0-10.3-.1-1.9,.6-2.5,2.5-2.5,17.5,.1,35,0,52.5,0Z"/>
  <path onClick={() => props.onFill(31)} fill={props.fillColors[31]} d="M137.5,497.6c0-5,0-9.5,.1-14.1,0-.5,.8-1,1.4-1.3,.5-.2,1.1-.1,1.7-.1h103.9q3,0,3,3v10.7c0,1.3-.4,1.8-1.7,1.7h-105.5c-.8,.1-1.7,.1-2.9,.1Z"/>
  <path onClick={() => props.onFill(32)} fill={props.fillColors[32]} d="M77.8,497.6H25.5c-2,0-2.8-.4-2.7-2.6,.2-3.5,.2-7,0-10.5-.1-1.9,.6-2.4,2.4-2.4,11.8,.1,23.5,0,35.3,0h69.5q2.8,0,2.8,2.8c0,3.4-.1,6.9,0,10.3,.1,2-.7,2.4-2.5,2.4-17.5-.1-35,0-52.5,0Z"/>
  <path onClick={() => props.onFill(33)} fill={props.fillColors[33]} d="M307.2,497.6h-52.3c-2.2,0-2.8-.6-2.7-2.7,.2-3.5,.1-7,0-10.5-.1-1.8,.6-2.3,2.3-2.3,10.7,.1,21.4,0,32.1,0s22.1,.2,33.2,.2c13.2,0,26.5-.1,39.7-.2q2.8,0,2.8,2.7c0,3.5-.1,7,0,10.5,.1,1.8-.5,2.2-2.3,2.2-17.5,0-35.1,.1-52.8,.1Z"/>
  <path onClick={() => props.onFill(34)} fill={props.fillColors[34]} d="M422.2,497.6h-52.3q-2.7,0-2.7-2.8c0-3.4,.1-6.9,0-10.3-.1-1.8,.5-2.4,2.4-2.4,12.2,.1,24.4,0,36.6,0h48.9c6.6,0,13.3,.1,19.9,0,1.9,0,2.4,.7,2.3,2.4-.1,3.6-.1,7.1,0,10.7,.1,1.9-.5,2.4-2.3,2.4-17.7-.1-35.2,0-52.8,0Z"/>
  <path onClick={() => props.onFill(35)} fill={props.fillColors[35]} d="M18,22.9V118c0,2.7,0,2.8-2.8,2.8-3.4,0-6.9-.1-10.3,0-1.9,.1-2.5-.6-2.5-2.5,.1-24.1,0-48.2,0-72.2V22.9c5.3,0,10.3,0,15.6,0Z"/>
  <path onClick={() => props.onFill(36)} fill={props.fillColors[36]} d="M497.3,22.8V119.2h-14.2c-.4,0-.9-.6-1.1-1.1-.2-.6-.1-1.2-.1-1.9V44.2c0-6.4,.1-12.7,0-19.1,0-1.9,.6-2.4,2.4-2.3,4.3,.1,8.6,0,13,0Z"/>
  <path onClick={() => props.onFill(37)} fill={props.fillColors[37]} d="M497.3,383.5c.1,1,.1,1.8,.1,2.5,0,29.5,0,59.1,.1,88.6,0,2.2-.6,2.7-2.7,2.6-3.6-.2-7.3-.1-10.9,0-1.4,0-1.9-.5-1.9-1.8,.1-5.2,.1-10.4,.2-15.5,0-1.3-.2-2.6-.2-4v-69.5q0-2.9,2.8-2.9c4.1,0,8.2,0,12.5,0Z"/>
  <path onClick={() => props.onFill(38)} fill={props.fillColors[38]} d="M2.8,385.3h14.2c.3,0,.8,.6,1,1,.2,.6,.1,1.2,.1,1.9v86.7c0,1.7-.5,2.4-2.2,2.3-3.8-.1-7.7-.1-11.5,0-1.2,0-1.7-.3-1.7-1.7v-89.3c-.1-.1,0-.4,.1-.9Z"/>
  <path onClick={() => props.onFill(39)} fill={props.fillColors[39]} d="M295.72,241.72c5.05-10.43,11.19-20.3,13.61-31.82,4.5,1.87,8.01,4.83,10.86,8.23,3.29,3.95,6.47,8.01,9.66,11.96,5.82,7.35,13.17,12.73,21.62,16.79,.77,.33,1.43,.77,2.08,1.32-19.53,.99-39.07,1.54-57.83-6.47Z"/>
  <path onClick={() => props.onFill(40)} fill={props.fillColors[40]} d="M191.8,209.67c2.52,11.74,8.56,21.62,13.72,32.04-18.44,7.9-37.75,7.46-57.61,6.8,.99-.66,1.43-1.1,1.98-1.32,9.66-4.83,17.67-11.63,24.03-20.3,3.95-5.27,7.79-10.75,13.39-14.49,1.21-.88,2.63-1.65,4.5-2.74Z"/>
  <path onClick={() => props.onFill(41)} fill={props.fillColors[41]} d="M354.1,254.12c-3.62,2.08-7.13,3.84-10.43,5.93-8.01,4.83-13.72,11.96-19.2,19.31-3.62,4.83-7.46,9.44-12.84,12.4-.66,.33-1.32,.66-1.87,.99-2.08-5.49-3.95-10.97-6.26-16.13s-5.16-10.1-7.9-15.36c18.87-8.56,38.63-7.57,58.49-7.13Z"/>
  <path onClick={() => props.onFill(42)} fill={props.fillColors[42]} d="M148.12,254.45c19.42-.88,38.74-1.65,57.17,6.47-5.05,10.43-11.19,20.19-13.61,31.82-4.39-2.08-7.9-4.72-10.64-8.12-3.4-3.95-6.58-8.12-9.77-12.18-5.49-7.13-12.51-12.4-20.41-16.57-.88-.44-1.87-.88-2.74-1.43Z"/>
  <path onClick={() => props.onFill(43)} fill={props.fillColors[43]} d="M193.77,251.37c14.7-3.4,27.21-9.77,34.79-23.48,.55,.55,.88,.66,1.1,.99,3.73,5.05,7.46,10.1,11.3,15.14,.77,.99,1.1,1.87,.44,3.07-1.54,2.85-1.43,5.71-.11,8.56,.33,.77,.44,2.08,0,2.63-3.95,5.49-8.12,10.75-12.51,16.57-7.68-13.5-20.08-20.19-35.01-23.48Z"/>
  <path onClick={() => props.onFill(44)} fill={props.fillColors[44]} d="M272.56,227.78c7.57,13.83,20.19,20.19,34.9,23.59-14.81,3.4-27.43,9.88-35.23,23.92-1.43-2.08-2.52-3.84-3.84-5.49-2.74-3.62-5.49-7.13-8.12-10.86-.44-.66-.66-2.08-.33-2.74,1.43-3.18,1.43-6.15,0-9.33-.33-.77-.22-2.08,.33-2.85,3.95-5.38,7.9-10.64,12.29-16.24Z"/>
  <path onClick={() => props.onFill(45)} fill={props.fillColors[45]} d="M57.59,251.15c.55-.55,1.21-1.32,1.87-1.98,4.94-5.05,9.99-9.99,14.92-15.03,.88-.88,1.54-1.54,2.74-.33,5.38,5.49,10.97,10.97,16.46,16.46,.22,.22,.44,.55,.44,.66-6.26,6.26-12.29,12.29-18.44,18.33-5.71-5.82-11.74-11.85-18-18.11Z"/>
  <path onClick={() => props.onFill(46)} fill={props.fillColors[46]} d="M403.04,251.26c5.93-5.82,12.07-11.96,18-17.89,5.82,5.93,11.96,11.96,17.78,17.89-5.93,5.93-12.07,11.96-18,17.89-5.71-5.82-11.74-11.96-17.78-17.89Z"/>
  <path onClick={() => props.onFill(47)} fill={props.fillColors[47]} d="M497.3,2.5v15.3c-4.8,0-9.4,0-14.1-.1-.4,0-1.1-.8-1.1-1.3-.1-4.2-.1-8.4,0-12.6,0-.5,.7-1.3,1.1-1.3,4.7-.1,9.3,0,14.1,0Z"/>
  <path onClick={() => props.onFill(48)} fill={props.fillColors[48]} d="M2.6,17.8V2.4h13.8c1.2,0,1.6,.4,1.6,1.6,0,4.1,0,8.1-.1,12.2,0,.5-.7,1.5-1.1,1.5-4.6,.1-9.3,.1-14.2,.1Z"/>
  <path onClick={() => props.onFill(49)} fill={props.fillColors[49]} d="M497.4,497.5c-4.9,0-9.5,0-14.1-.1-.4,0-1.2-.9-1.2-1.4-.1-4.1,0-8.2-.1-12.4,0-1.2,.5-1.6,1.6-1.6,4.1,0,8.2,0,12.4,.1,.5,0,1.4,.8,1.4,1.3,.1,4.6,0,9.2,0,14.1Z"/>
  <path onClick={() => props.onFill(50)} fill={props.fillColors[50]} d="M2.6,497.5c0-4.9,0-9.6,.1-14.2,0-.4,.8-1.1,1.3-1.1,4.2-.1,8.4-.1,12.6,0,.5,0,1.4,.9,1.4,1.5,.1,4.2,.1,8.4,0,12.6,0,.4-.6,1.2-1,1.2-4.7,.1-9.5,0-14.4,0Z"/>
  <path onClick={() => props.onFill(51)} fill={props.fillColors[51]} d="M250.61,256.31c-2.74,0-4.94-2.3-4.94-4.94s2.41-5.16,5.16-5.05c2.85,0,5.05,2.41,4.94,5.27-.11,2.74-2.41,4.83-5.16,4.72Z"/>



</g>
            </svg>
        </div>
    )
}