import React from 'react';
import { useHistory } from "react-router-dom";
import ColoredInImage from '../Images/ColoredInImage';
import Card from '@mui/material/Card';
import { useSelector, useDispatch } from 'react-redux';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import swal from 'sweetalert';
import IconButton from '@mui/material/IconButton';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import Fab from '@mui/material/Fab';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';


export default function MediaCard(props) {

    let image = props.userImage.image
    const addedToCart = props.userImage.cart
    const history = useHistory();
    const dispatch = useDispatch();
    const {token} = useSelector(state => state.auth);

    
    const showFeatured = () => {
        history.push(`/featuredimage/${props.userImage.id}`)   
    }

    
    const order = () => {
        history.push(`/cart/${props.userImage.id}`)   
    }


    const deleteImage = () => {
      swal({
          title: "Are you sure?",
          text: "Once deleted, you will not be able to recover this image",
          icon: "warning",
          buttons: true,
          dangerMode: true,
      })
      .then((willDelete) => {
          if (willDelete) {
              fetch(`https://test-api.stainedglassusa.com/user_images/${props.userImage.id}`,{
                  method: 'DELETE',
                  headers: {'Access-Control-Allow-Origin':'*','Authorization': `bearer ${token}`}
              })
              .then(r => r.json())
              .then(data => {
                  dispatch({
                      type: 'SET_IMAGES',
                      payload: data
                  });
                  swal("Image Deleted", "This image has been removed from your gallery", "success");
                  window.location.reload();
              })        
          }
        });
  }

  const addToCart = () => {

    {props.userImage.cost <= 0 ?
        swal({
            title: "Window Size not defined",
            text: "Please select window size before adding to the cart",
            icon: "warning",
            buttons: false,
            dangerMode: true,
        })

        :


    swal({
        title: "Do you Want to Add this image to Cart?",
        text: "You can review all the details on the Cart Page",
        icon: "info",
        buttons: true,
        dangerMode: false,
        
    })
    .then((willAddtoCart) => {
        if (willAddtoCart) {

            fetch(`https://test-api.stainedglassusa.com/user_images/${props.userImage.id}`,{
                method: 'PATCH',
                headers: {
                    'Access-Control-Allow-Origin':'*',
                    'Content-Type': 'application/json',
                    'Authorization': `bearer ${token}`},
                body: JSON.stringify({id: props.userImage.id, cart: true })
            })
            .then(r => r.json())
            .then(console.log)   
            
                swal("Image Added", "This image has been added to your cart", "success");
                history.push(`/cart`)
            }        
        }
      );
}
}

  return (

    <Card className="mycard" sx={{ maxWidth: 345 }}>
                          <IconButton onClick={deleteImage} size="large" color="error" aria-label="add to shopping cart">
                      <HighlightOffIcon />
                  </IconButton>  
       <ColoredInImage className="new-image" component={image.component} onFill={()=>{}} fillColors={props.userImage.fill_colors}/>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
        {image.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
        {image.description}
        </Typography>
      </CardContent>
      <CardActions>
          
      {!addedToCart ?
          <>
          
      <Fab onClick={addToCart} color="success" aria-label="add">
  <AddShoppingCartIcon />
</Fab>           
                  </> 
            :

            <><IconButton disabled aria-label="add to shopping cart">
            <RemoveShoppingCartIcon /><p>Item in the cart</p>
        </IconButton></>         
}
  

<Fab color="primary" aria-label="edit" onClick={showFeatured}>
  <EditIcon />
</Fab>                            

      </CardActions>
    </Card>
  );
}



