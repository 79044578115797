import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { glassData } from "./data";
import Button from '@material-ui/core/Button';
import { SwipeableDrawer } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery'; 

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

export default function ColorPalette(props) {
  const [showColors, setShowColors] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)'); 

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setShowColors(open);
  };

  useEffect(() => {
    if (!isMobile) {
      setShowColors(false);
    }
  }, [isMobile]);

  const getFillId = (id) => {
    return id === "url(#00_bevel)"? "url(#bevels)": id;
  };

  return (
    <><h2 className='coloring-subtitle'>Color palette</h2><div className="color-palette calculator">

      {isMobile && <Button onClick={toggleDrawer(true)}>Select Color</Button>}

      {isMobile? (
        <SwipeableDrawer
          anchor="bottom"
          open={showColors}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          <div className="color-palette-mobile" role="presentation">
            <Grid container spacing={2}>
              {glassData.map((data, key) => {
                const fillId = getFillId(data.id); // Get fillId for mobile version
                return (
                  <Grid item xs key={key} onClick={() => { props.changeColor(data.id); toggleDrawer(false)(); } }>
                    <HtmlTooltip title={<React.Fragment>
                      <Typography color="inherit">{data.color} {data.type}</Typography>
                      <div>
                        <em>{data.description}</em>
                      </div>
                      <svg width="150" height="150">
                        <rect width="150" height="150" fill={fillId} /> {/* Use fillId here */}
                      </svg>
                    </React.Fragment>}>
                      <svg id={data.id} className={`color-swatch ${props.currentColor === (data.id)? "color-swatch-active": ""}`} width="50" height="50">
                        <rect width="50" height="50" fill={data.id} />
                      </svg>
                    </HtmlTooltip>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </SwipeableDrawer>
      ): (
        <Grid container spacing={2}>
          {glassData.map((data, key) => {
            const activeClass = props.currentColor === (data.id)? "color-swatch-active": "";
            const fillId = getFillId(data.id); // Get the potentially modified fill ID
            return (
              <Grid item xs key={key} onClick={() => props.changeColor(data.id)}>
                <HtmlTooltip title={<React.Fragment>
                  <Typography color="inherit">{data.color} {data.type}</Typography>
                  <div>
                    <em>{data.description}</em>
                  </div>
                  <svg width="150" height="150">
                    <rect width="150" height="150" fill={fillId} /> {/* Use the modified ID here */}
                  </svg>
                </React.Fragment>}>
                  <svg id={data.id} className={`color-swatch ${activeClass}`} width="50" height="50">
                    <rect width="50" height="50" fill={data.id} /> {/* Original ID used here */}
                  </svg>
                </HtmlTooltip>
              </Grid>
            );
          })}
        </Grid>
      )}
    </div></>
  )
}