import React from 'react';


export default function Sun(props) {
    
    return (
        <div className={`Sun-container ${props.size}`}> 
 
            <svg  xmlns="http://www.w3.org/2000/svg" id={props.id} width="500" height="500" viewBox="0 0 500 500">
                
            <pattern id="01_yellow" width="100%" height="100%">
                      <image xlinkHref="/01_yellow.jpg"/>
              </pattern>
          <pattern id="02_lightAmber" width="100%" height="100%">
              <image xlinkHref="/02_lightAmber.jpg"/>
          </pattern>
          <pattern id="03_darkAmber" width="100%" height="100%">
              <image xlinkHref="/03_darkAmber.jpg"/>
          </pattern>
          <pattern id="04_cherry" width="100%" height="100%">
              <image xlinkHref="/04_cherry.jpg"/>
          </pattern>
          <pattern id="05_redAmber" width="100%" height="100%">
              <image xlinkHref="/05_redAmber.jpg"/>
          </pattern>    
          <pattern id="06_orange" width="100%" height="100%">
              <image xlinkHref="/06_orange.jpg"/>
          </pattern>
          <pattern id="07_green" width="100%" height="100%">
              <image xlinkHref="/07_green.jpg"/>
          </pattern>
          <pattern id="08_olive" width="100%" height="100%">
              <image xlinkHref="/08_olive.jpg"/>
          </pattern>
          <pattern id="09_purple" width="100%" height="100%">
              <image xlinkHref="/09_purple.jpg"/>
          </pattern>
          <pattern id="10_lightBlue" width="100%" height="100%">
              <image xlinkHref="/10_lightBlue.jpg"/>
          </pattern>  
          <pattern id="11_aquaBlue" width="100%" height="100%">
              <image xlinkHref="/11_aquaBlue.jpg"/>
          </pattern>
          <pattern id="12_oceanBlue" width="100%" height="100%">
              <image xlinkHref="/12_oceanBlue.jpg"/>
          </pattern>
          <pattern id="13_cobaltBlue" width="100%" height="100%">
              <image xlinkHref="/13_cobaltBlue.jpg"/>
          </pattern>
          <pattern id="14_tealBlue" width="100%" height="100%">
              <image xlinkHref="/14_tealBlue.jpg"/>
          </pattern>
          <pattern id="15_clear" width="100%" height="100%">
              <image xlinkHref="/15_clear.jpg"/>
          </pattern>  
          <pattern id="16_granite" width="100%" height="100%">
              <image xlinkHref="/16_granite.jpg"/>
          </pattern>
          <pattern id="17_crackle" width="100%" height="100%">
              <image xlinkHref="/17_crackle.jpg"/>
          </pattern>
          <pattern id="18_iridescent" width="100%" height="100%">
              <image xlinkHref="/18_iridescent.jpg"/>
          </pattern>
          <pattern id="19_taffy" width="100%" height="100%">
              <image xlinkHref="/19_taffy.jpg"/>
          </pattern>
          <pattern id="20_seedy" width="100%" height="100%">
              <image xlinkHref="/20_seedy.jpg"/>
          </pattern> 
          <pattern id="21_frosted" width="100%" height="100%">
              <image xlinkHref="/21_frosted.jpg"/>
          </pattern>                                      
            <g className="hummingbird-image" >

<path id="background" fill="black"  d="m0,500V0h500v500H0Z"/>

       onClick={() => props.onFill(1)} fill={props.fillColors[1]} 



       <path onClick={() => props.onFill(1)} fill={props.fillColors[1]}  d="M472.38,473.74h-4.05c-46.56,0-93.11,0-139.67,0-4.79,0-9.59-.13-14.38,.06-2.3,.09-3.53-.82-4.86-2.61-7.5-10.09-14.85-20.32-22.86-29.99-7.04-8.5-15.75-15.19-25.46-20.63-7.2-4.04-14.6-8.04-20.9-13.28-5.82-4.84-10.29-11.29-15.36-17.03l.49-.72c4.55,.63,9.09,1.27,13.64,1.9,13.44,1.86,26.12-.21,38.31-6.41,12.43-6.31,25.04-12.31,37.83-17.82,6.74-2.9,14.02-4.57,21.11-6.59,1.11-.32,2.75,.08,3.76,.73,14.79,9.46,31.01,14.02,48.49,14.02,5.25,0,10.51-.72,16-1.13,.7,4.6,1.41,9.39,2.15,14.17,2.05,13.25,8.94,22.8,21.37,28.23,4.02,1.76,7.89,3.84,11.84,5.75,8.51,4.14,15.09,10.33,19.89,18.47,5.93,10.07,9.27,21.11,12.64,32.87Z"/>
  <path onClick={() => props.onFill(2)} fill={props.fillColors[2]}  d="M306.6,473.54c-1.25,.08-2.11,.19-2.96,.19-43.35,0-86.7,0-130.05-.09-1.03,0-2.52-.84-3.01-1.73-7.61-13.6-17.21-25.69-27.78-37.05-4.15-4.45-8.6-8.63-12.85-12.99-3.13-3.22-6.17-6.53-9.23-9.82-3.89-4.18-8.46-7.22-14.01-8.8-1.01-.29-2.05-1.14-2.69-2.02-3.79-5.22-7.1-10.84-11.28-15.72-5.33-6.23-9.81-12.81-12.34-20.64-3.54-10.97-9.52-20.16-19.47-26.4-.41-.26-.93-.36-1.13-.88,6.86-1.57,13.34-1.2,19.11,2.09,6.21,3.54,12.26,7.49,17.91,11.87,8.82,6.83,18.13,12.29,29.35,13.99,10.04,1.52,20.1,1.56,30.15,.33,5.86-.72,11.31-.18,16.92,2,9.27,3.61,18.76,6.69,28.28,9.62,9.63,2.96,16.62,9.16,22.58,16.93,5.22,6.8,10.09,14.01,17.42,18.8,5.42,3.54,11,6.89,16.73,9.91,11.97,6.32,21.78,15.18,30.2,25.56,5.96,7.36,11.31,15.22,16.92,22.86,.39,.54,.7,1.13,1.24,2Z"/>
  <path onClick={() => props.onFill(3)} fill={props.fillColors[3]}  d="M320.8,191.95c2.17-9.85,3.77-19.1,6.3-28.08,3.48-12.31,12.65-18.7,24.69-21.35,9.54-2.1,19.12-4.05,28.72-5.92,13.72-2.67,24.93-9.39,33.35-20.56,5.47-7.25,10.95-14.51,16.09-22,9.13-13.28,21.62-20.63,37.58-22.3,1.76-.18,3.5-.54,5.6-.87v3.59c0,43.06-.03,86.13,.07,129.19,0,2.67-.91,3.51-3.33,4.12-4.92,1.24-9.82,2.66-14.56,4.45-6.78,2.55-11.89,7.2-15.42,13.56-.37,.67-.83,1.3-1.34,2.1-2.61-1.94-5.06-3.8-7.55-5.6-9.58-6.93-20.59-9.97-32.09-11.31-5.62-.66-11.36-.31-17.03-.62-1.48-.08-3.07-.65-4.37-1.4-8.19-4.74-16.13-9.93-24.49-14.31-8.14-4.26-17.12-4.24-26.07-3.34-1.67,.17-3.33,.34-6.14,.63Z"/>
  <path onClick={() => props.onFill(4)} fill={props.fillColors[4]}  d="M334.22,146.49c-18.8-26.68-34.21-28.4-62.73-29.94,.72-1.17,1.28-2.14,1.91-3.07,1.34-1.98,2.75-3.93,4.09-5.91,7.68-11.37,18.44-16.76,32.09-17.37,7.04-.32,14.2-1.06,21-2.82,13.34-3.45,22.53-12.79,29.2-24.25,6.31-10.83,11.52-22.3,17.16-33.52,.72-1.42,1.29-2.38,3.23-2.37,30.37,.08,60.74,.06,91.11,.06,.49,0,.97,.1,1.86,.2v2.92c0,11.19-.09,22.38,.07,33.57,.04,2.6-.82,3.34-3.27,3.71-5.02,.76-10.04,1.73-14.94,3.03-10.12,2.68-18.11,8.7-24.44,16.87-5.01,6.47-9.95,13.02-14.49,19.83-10.02,15.04-23.49,24.36-41.64,26.79-7.01,.94-13.93,2.6-20.84,4.14-6.84,1.53-13.46,3.72-19.35,8.13Z"/>
  <path onClick={() => props.onFill(5)} fill={props.fillColors[5]}  d="M82.44,202.72c7.86-11.01,17.94-19.22,28.97-26.43,9.76-6.39,19.72-12.5,27.98-20.94,8.54-8.73,11.79-19.2,10.91-31.15-.34-4.68-.92-9.33-1.22-14.01-.07-1.09,.38-2.32,.89-3.33,2.61-5.16,5.44-10.2,7.95-15.4,3.7-7.68,9.65-13.02,16.77-17.41,7.12-4.39,14.07-9.05,20.99-13.75,1.6-1.08,2.76-2.81,4.04-4.16,5.54,7.73,10.66,15.35,16.27,22.59,7.98,10.29,17.68,18.66,29.33,24.68,4.69,2.43,9.45,4.79,13.87,7.66,9,5.85,11.16,16.83,5.43,25.88-7.47,11.8-15,23.58-22.34,35.46-1.45,2.35-2.74,3.15-5.64,2.62-12.06-2.23-24.19-4.11-36.29-6.12-.3-.05-.61-.02-.89-.12-9.85-3.57-14.22,.95-18.24,8.17-1.66,2.98-3.91,4.72-6.82,6.28-13.73,7.37-28.42,10.1-43.86,9.97-9.77-.08-19.62-.18-29.09,2.73-6.35,1.95-12.53,4.45-19.01,6.79Z"/>
  <path onClick={() => props.onFill(6)} fill={props.fillColors[6]}  d="M465.22,294.07c-.61,24.4-9.79,45.43-28.55,62.04-1.13,1-2.99,1.64-4.51,1.66-35.34,.36-65.06-26.13-69.12-61.51-4.08-35.5,20.88-69.08,56.06-75.3,1.22-.22,2.79,.08,3.87,.71,23.33,13.64,37.16,33.8,41.34,60.54,.2,1.28,.41,2.56,.5,3.85,.18,2.49,.27,4.98,.42,8.01Z"/>
  <path onClick={() => props.onFill(7)} fill={props.fillColors[7]}  d="M334.13,357.46c-15.86,4.54-31.1,9.26-45.41,17.49-.88-2.21-1.58-4.23-2.46-6.17-2.26-4.99-4.34-10.08-6.98-14.87-4.03-7.34-10.1-12.5-18.06-15.38-4.97-1.8-9.96-3.57-14.78-5.71-9.03-4.02-15.24-11.46-21.68-18.55-6.24-6.87-12.05-14.23-20.16-19.08-4.61-2.75-9.48-5.05-14.34-7.61,.03-.13,.02-.5,.19-.64,6.3-5.1,8.97-12.01,10.03-19.77,.3-2.17,.92-4.29,1.36-6.43,1.29-6.23,4.27-10.63,10.47-13.63,12.2-5.9,23.92-12.81,35.84-19.3,.86-.47,1.75-.9,2.81-1.44,5.82,7.07,11.58,14.07,17.35,21.08,3.49,4.24,6.94,8.52,10.49,12.71,3.36,3.98,6.34,8.09,8.26,13.06,2.48,6.44,7.56,10.89,12.91,15.04,1.06,.82,1.98,2.57,2,3.9,.29,25.88,10.64,46.9,30.29,63.52,.51,.43,.98,.93,1.87,1.77Z"/>
  <path onClick={() => props.onFill(8)} fill={props.fillColors[8]}  d="M374.03,27.29c-2.08,4.64-3.91,8.82-5.82,12.96-4.48,9.71-9.5,19.11-16.06,27.63-9.14,11.87-21.17,17.74-36.08,18.33-5.77,.23-11.54,.99-17.24,1.95-9.49,1.61-16.45,7.35-22.21,14.68-2.89,3.68-5.53,7.55-8.46,11.59-5.82-7.48-14.53-10.6-22.44-14.89-12.96-7.02-22.99-17.14-31.5-28.92-4.14-5.73-7.94-11.72-11.42-16.88,4.3-8.49,8.35-16.38,12.25-24.33,.77-1.58,1.63-2.15,3.38-2.15,35.27,.07,70.53,.08,105.8,.09,15.29,0,30.57-.03,45.86-.05,1.09,0,2.18,0,3.93,0Z"/>
  <path onClick={() => props.onFill(9)} fill={props.fillColors[9]}  d="M81.74,293.39c9.31-3.18,17.89-2.47,26.56-.09,8.44,2.32,16.9,4.81,25.51,6.25,13.99,2.35,27.61-.14,40.84-5,1.03-.38,2.14-.62,3.07-1.18,6.01-3.62,11.55-2.66,17.55,.59,7.47,4.04,14.24,8.79,19.94,15.07,4.83,5.32,9.65,10.66,14.58,15.89,6.28,6.66,13.66,11.85,22.56,14.1,15.43,3.91,24.6,13.95,29.64,28.49,1.04,2.99,2.19,5.94,3.23,8.78-15.13,10.1-31.24,14.53-49.1,11.17-1.96-.37-3.94-.65-5.92-.91-5.46-.73-10.35-2.16-14.8-6.08-5.85-5.16-13.76-6.48-21.05-8.87-8.8-2.89-17.49-6.14-26.18-9.36-1.13-.42-2.14-1.44-2.95-2.4-10.92-12.99-21.74-26.07-32.7-39.02-8.12-9.59-17.93-16.52-30.43-19.53-5.67-1.37-11.07-3.87-16.56-5.92-1.18-.44-2.25-1.17-3.8-1.99Z"/>
  <path onClick={() => props.onFill(10)} fill={props.fillColors[10]}  d="M61.52,161.03c1.37-5.87,4.61-10.92,8.76-15.45,3.03-3.32,6-6.71,9.16-9.89,9.02-9.07,11.78-20.23,10.33-32.47-1.04-8.78-2.8-17.49-4.62-26.15-1.07-5.08-1.21-9.98,1.11-14.6,5.39-10.74,6.06-21.97,3.99-33.56-.07-.39,0-.79,0-1.4h121.7c-1.77,3.86-3.41,7.63-5.21,11.33-5.71,11.71-13.79,21.2-25.54,27.26-1.86,.96-3.59,2.17-5.44,3.14-10.83,5.69-18.66,13.97-23.36,25.44-5.24,12.77-14.68,22.47-24.85,31.43-5.14,4.53-11.1,7.65-17.87,9.3-7.26,1.77-14.45,3.85-21.61,6.01-7.46,2.25-13.3,7.11-18.86,12.35-2.59,2.44-5.17,4.89-7.67,7.25Z"/>
  <path onClick={() => props.onFill(11)} fill={props.fillColors[11]}  d="M167.12,473.56H26.75v-100.77c7.97,1.9,15.34,4.78,21.32,10.55,4.31,4.16,8.5,8.45,12.88,12.52,8.56,7.96,18.78,11.41,30.42,10.76,2.39-.13,4.8-.02,7.18-.27,7.33-.77,13.32,1.97,18.33,7.02,7.73,7.8,15.45,15.61,22.95,23.63,9.7,10.38,18.74,21.29,25.78,33.72,.47,.83,.91,1.69,1.53,2.84Z"/>
  <path onClick={() => props.onFill(12)} fill={props.fillColors[12]}  d="M413.58,218.15c-38.93,10.96-57.34,46.4-54.18,77.9,3.31,32.96,29.77,62.85,68.46,66.18-4.76,1.89-9.39,4.23-14.29,5.59-32.56,9.07-61.65,2.66-85.8-21.27-30.45-30.17-29.36-80.33,2.07-109.49,23.1-21.43,50.31-27.6,80.76-20.2,.77,.19,1.53,.44,2.29,.69,.16,.05,.28,.24,.68,.6Z"/>
  <path onClick={() => props.onFill(13)} fill={props.fillColors[13]}  d="M26.65,366.14V143.62c3.38,2.26,6.51,4.25,9.54,6.38,16.83,11.87,26.77,28.74,33.99,47.46,8.88,23.02,12.03,46.8,9.31,71.4-2.67,24.1-11.66,45.59-25.7,65.19-7.09,9.9-13.76,20.1-22.96,28.28-1.24,1.1-2.46,2.24-4.18,3.81Z"/>
  <path onClick={() => props.onFill(14)} fill={props.fillColors[14]}  d="M106.18,250.24c6.27-5.32,12.25-10.94,18.81-15.76,4.67-3.43,10.08-5.91,15.29-8.56,2.28-1.16,4.85-1.84,7.36-2.46,14.11-3.46,22.83-12.79,28.32-25.72,2.53-5.96,5.03-11.93,7.69-17.84,.77-1.71,1.89-3.3,3.06-4.78,4.03-5.12,9.54-5.31,14.05-.61,8.23,8.57,16.45,17.17,24.75,25.67,1.33,1.36,1.56,2.37,.63,4.09-7.95,14.64-16.22,29.13-23.51,44.09-3.17,6.51-4.6,13.95-6.21,21.1-1.32,5.91-3.82,10.8-8.37,14.79-1.5,1.32-2.57,1.52-4.43,.77-10.09-4.06-19.2-9.83-27.77-16.38-12.32-9.43-26.44-14.12-41.39-16.97-2.83-.54-5.67-.98-8.3-1.43Z"/>
  <path onClick={() => props.onFill(15)} fill={props.fillColors[15]}  d="M26.66,135.29V27.54h59.7c1.46,9.66,2.58,19.21-1.48,28.72-1.93,4.51-3.02,9.41-4.11,14.22-.4,1.75-.08,3.89,.57,5.6,1.53,3.99,.46,7.49-.92,11.23-4.09,11.1-10.87,19.57-22.04,24.25-11.88,4.98-22.12,12.24-30.24,22.35-.29,.37-.69,.65-1.48,1.38Z"/>
  <path onClick={() => props.onFill(16)} fill={props.fillColors[16]}  d="M457.69,336.39c3.94,5.11,8.07,9.25,15.17,8.17v117.38c-.73-1.9-1.3-3.49-1.96-5.04-2.15-5.04-4.09-10.18-6.54-15.06-5.39-10.71-13.66-18.54-24.57-23.57-3.71-1.71-7.33-3.63-11.05-5.32-10.14-4.6-16.17-12.4-18.15-23.3-.82-4.51-1.31-9.08-2.11-13.59-.41-2.34,.49-3.13,2.66-3.9,6.47-2.28,12.96-4.59,19.14-7.52,9.35-4.44,15.98-12.09,22.02-20.25,1.83-2.47,3.45-5.08,5.4-7.99Z"/>
  <path onClick={() => props.onFill(17)} fill={props.fillColors[17]}  d="M302.02,285.04c-9.57-6.57-14.27-15.63-15.26-27.1-1.24-14.31-2.97-28.58-4.6-42.85-.21-1.81,.27-2.64,1.91-3.36,11.4-5.02,22.49-10.91,34.21-15,10.37-3.62,21.59-3.84,31.94,.66,8.25,3.59,15.95,8.47,23.74,13.16-40.17,9.44-64.84,33.21-71.94,74.49Z"/>
  <path onClick={() => props.onFill(18)} fill={props.fillColors[18]}  d="M161.82,361.69c-13.07,1.52-25.68,2.29-38.27-.41-6.94-1.49-13.26-4.3-18.93-8.56-4.71-3.54-9.31-7.22-14.11-10.63-9.39-6.66-19.55-10.83-31.64-8.48,3.73-6.43,7.55-12.37,10.7-18.64,3.12-6.21,5.58-12.76,8.38-19.3-.12,.02,.1-.09,.22-.02,8.17,4.74,16.98,7.88,26.1,10.18,9.25,2.33,16.75,7.53,22.87,14.6,11.37,13.13,22.48,26.5,33.69,39.77,.3,.36,.53,.79,.99,1.5Z"/>
  <path onClick={() => props.onFill(19)} fill={props.fillColors[19]}  d="M331.27,149.05c-10.22,12.38-11.08,27.67-13.5,42.7-8.69-1.93-17.22-3.82-25.75-5.73-3.79-.85-7.6-1.65-11.35-2.65-.85-.23-2.05-1.12-2.16-1.85-2.57-15.84-5.14-31.68-7.37-47.57-.61-4.37-.09-8.9-.09-13.69,11.68,.48,23.65,.75,34.88,5.56,11.1,4.76,18,14.17,25.34,23.23Z"/>
  <path onClick={() => props.onFill(20)} fill={props.fillColors[20]}  d="M84.36,251.45c-.44-5.55-.59-11.15-1.4-16.65-1.09-7.39-2.44-14.76-4.19-22.01-.76-3.12-.1-4.28,2.75-5.44,8.96-3.64,17.66-8.12,27.38-9.28,7.11-.85,14.32-1.08,21.49-1.26,9.5-.23,18.99-.46,28.13-3.34,4.55-1.43,8.94-3.36,13.37-5.12,1.45-.57,2.83-1.33,4.87-2.3-5.57,14.68-11.16,28.4-27.66,33.1-3.35,.95-6.63,2.17-9.94,3.26-6.53,2.15-12.42,5.39-17.53,10.02-2.89,2.61-5.87,5.12-8.8,7.69-8.82,7.73-14.83,10.13-28.47,11.34Z"/>
  <path onClick={() => props.onFill(21)} fill={props.fillColors[21]}  d="M145.79,112.81c.88,10.26,2.34,19.88-1.66,29.22-3.39,7.91-9.45,13.55-16.14,18.51-6.09,4.51-12.37,8.78-18.75,12.87-11.55,7.4-21.71,16.26-29.95,27.26-.72,.96-1.5,1.86-2.28,2.78-.11,.13-.33,.17-.64,.32-1.3-3.44-2.35-6.98-3.94-10.26-3.61-7.43-7.4-14.78-11.31-22.06-.89-1.66-1.13-2.68,.28-4.11,3.71-3.77,7.15-7.81,10.93-11.51,5.92-5.8,12.65-10.22,20.93-12.17,7.47-1.76,14.92-3.7,22.18-6.14,7.5-2.52,13.64-7.42,19.2-12.95,3.66-3.65,7.13-7.49,11.16-11.75Z"/>
  <path onClick={() => props.onFill(22)} fill={props.fillColors[22]}  d="M26.75,23.48V4.27H172.71V23.48H26.75Z"/>
  <path onClick={() => props.onFill(23)} fill={props.fillColors[23]}  d="M176.73,23.52V4.33h146.09V23.52H176.73Z"/>
  <path onClick={() => props.onFill(24)} fill={props.fillColors[24]}  d="M472.97,4.29V23.55c-1.12,.05-2.18,.14-3.24,.14-46.55,0-93.1-.02-139.66,.07-2.8,0-3.58-.79-3.48-3.51,.2-5.26,.06-10.53,.06-15.97h146.32Z"/>
  <path onClick={() => props.onFill(25)} fill={props.fillColors[25]}  d="M476.85,473.51v-146.04h19.03v146.04h-19.03Z"/>
  <path onClick={() => props.onFill(26)} fill={props.fillColors[26]}  d="M22.79,323.5H3.7V177.43H22.79v146.06Z"/>
  <path onClick={() => props.onFill(27)} fill={props.fillColors[27]}  d="M495.9,323.5h-19.01V177.46h19.01v146.04Z"/>
  <path onClick={() => props.onFill(28)} fill={props.fillColors[28]}  d="M495.97,27.53V173.51h-19.04V27.53h19.04Z"/>
  <path onClick={() => props.onFill(29)} fill={props.fillColors[29]}  d="M22.83,473.62H3.78v-146.2H22.83v146.2Z"/>
  <path onClick={() => props.onFill(30)} fill={props.fillColors[30]}  d="M22.81,173.51H3.66V27.55H22.81V173.51Z"/>
  <path onClick={() => props.onFill(31)} fill={props.fillColors[31]}  d="M26.76,496.65v-19.15H172.71v19.15H26.76Z"/>
  <path onClick={() => props.onFill(32)} fill={props.fillColors[32]}  d="M322.94,477.55v19.08H176.81v-19.08h146.12Z"/>
  <path onClick={() => props.onFill(33)} fill={props.fillColors[33]}  d="M472.97,477.61v19.05h-146.11v-19.05h146.11Z"/>
  <path onClick={() => props.onFill(34)} fill={props.fillColors[34]}  d="M180.37,288.41c-12.28,5.26-25.61,9.36-39.85,8.1-7.68-.68-15.4-2.16-22.82-4.28-12.59-3.6-25-7.17-38.53-1.78,.73-3.25,1.51-5.78,1.85-8.38,1.04-7.9,2.05-15.81,2.76-23.75,.21-2.36,1.11-3.1,3.22-3.61,11.15-2.7,21.96-.49,32.83,1.83,12.69,2.71,24.07,7.93,34.64,15.51,8.35,5.99,17.39,11.04,25.89,16.37Z"/>
  <path onClick={() => props.onFill(35)} fill={props.fillColors[35]}  d="M100.09,402.68c-9.14,.41-18.51,1.63-26.94-3.04-4.91-2.72-9.24-6.54-13.67-10.06-2.17-1.73-4.04-3.86-5.92-5.93-5.94-6.54-13.23-10.82-21.68-13.22-.95-.27-1.88-.61-3.5-1.14,10.86-8.78,19.06-19.37,26.47-30.51,9.9,5.9,17.58,13.41,21.04,24.56,3.26,10.49,9.09,19.4,16.63,27.36,3.16,3.34,5.54,7.42,8.27,11.16l-.7,.82Z"/>
  <path onClick={() => props.onFill(36)} fill={props.fillColors[36]}  d="M56.72,165.03c-8.06-11.03-18.26-18.33-29.06-25.09,7.61-8.87,15.25-17.25,26.01-22.02,3.19-1.41,6.21-3.2,9.36-4.7,8.91-4.24,14.95-11.12,18.77-20.09,.57-1.34,1.22-2.66,2.07-4.52,1.78,8.73,3.25,16.79,2.27,25.16-.85,7.23-3.71,13.39-8.61,18.68-3.46,3.74-6.91,7.49-10.39,11.21-5.47,5.83-8.65,12.81-10.42,21.38Z"/>
  <path onClick={() => props.onFill(37)} fill={props.fillColors[37]}  d="M472.93,340.78c-5.11,.78-11.52-2.96-13.05-7.67-.25-.76,.28-1.87,.67-2.72,10.5-23.24,11.03-46.69,1.37-70.29-4.6-11.24-11.55-20.93-20.31-29.28,4.27-10.86,13.06-16.84,31.32-19.85v129.81Z"/>
  <path onClick={() => props.onFill(38)} fill={props.fillColors[38]}  d="M268.08,139.13c2.25,14.25,4.51,28.33,6.65,42.41,.15,1.02-.11,2.56-.8,3.17-5.82,5.16-11.78,10.16-17.96,15.42-4.13-7.26-8.17-14.3-12.11-21.41-.34-.61-.22-1.8,.17-2.42,7.75-12.32,15.58-24.6,23.4-36.89,.04-.07,.17-.08,.64-.29Z"/>
  <path onClick={() => props.onFill(39)} fill={props.fillColors[39]}  d="M282.68,259.19c-4.99-6.05-9.99-12.1-14.98-18.15-4.25-5.16-8.43-10.39-12.78-15.47-1.24-1.45-1.55-2.76-1.16-4.59,1.1-5.06,2.03-10.15,3.1-15.62,7,2.46,13.82,4.81,20.58,7.33,.59,.22,.98,1.58,1.08,2.45,1.4,12.29,2.73,24.6,4.07,36.9,.25,2.27,.47,4.55,.71,6.83l-.62,.32Z"/>
  <path onClick={() => props.onFill(40)} fill={props.fillColors[40]}  d="M208.62,244.52c5.78-10.79,11.55-21.58,17.34-32.36,1.51-2.8,2.46-6.68,4.82-8.02,2.36-1.33,6.17-.05,9.34,0,4.26,.05,8.52,.01,13.25,.01-1.25,6.53-2.31,12.16-3.46,17.77-.12,.58-.72,1.22-1.27,1.53-13.18,7.21-26.37,14.38-39.57,21.56l-.45-.49Z"/>
  <path onClick={() => props.onFill(41)} fill={props.fillColors[41]}  d="M312.63,194.97c-1.94,.89-3.88,1.8-5.82,2.67-8.26,3.7-16.52,7.41-24.81,11.06-.92,.41-2.16,.78-3.04,.49-6.51-2.15-12.97-4.47-19.9-6.89,5.16-4.42,10.19-8.44,14.86-12.83,2.54-2.39,4.9-2.9,8.27-2.07,10.03,2.49,20.16,4.61,30.25,6.88l.2,.68Z"/>
  <path onClick={() => props.onFill(42)} fill={props.fillColors[42]}  d="M205.67,173.56c6.86,1.11,13.72,2.2,20.57,3.32,3.93,.64,7.86,1.27,11.77,2.02,.88,.17,2.05,.6,2.45,1.28,3.9,6.69,7.67,13.45,11.52,20.27-4.17,0-8.12-.3-12,.06-7.33,.69-12.41-2.41-17.02-7.93-5.4-6.48-11.62-12.27-17.49-18.36l.19-.67Z"/>
  <path onClick={() => props.onFill(43)} fill={props.fillColors[43]}  d="M495.96,4.29V23.51h-19.03V4.29h19.03Z"/>
  <path onClick={() => props.onFill(44)} fill={props.fillColors[44]}  d="M3.72,23.44V4.37H22.83V23.44H3.72Z"/>
  <path onClick={() => props.onFill(45)} fill={props.fillColors[45]}  d="M22.89,477.63v18.95H3.74v-18.95H22.89Z"/>
  <path onClick={() => props.onFill(46)} fill={props.fillColors[46]}  d="M476.89,496.63v-19.1h19.04v19.1h-19.04Z"/>






</g>
            </svg>
        </div>
    )
}