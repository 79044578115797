import React from 'react';
import './Denton';


export default function Denton(props) {
    
    return (
        <div className={`denton-container ${props.size}`}> 
 
            <svg  xmlns="http://www.w3.org/2000/svg" id={props.id} width="500" height="500" viewBox="0 0 500 500">
                
            <pattern id="01_yellow" width="100%" height="100%">
                      <image xlinkHref="/01_yellow.jpg"/>
              </pattern>
          <pattern id="02_lightAmber" width="100%" height="100%">
              <image xlinkHref="/02_lightAmber.jpg"/>
          </pattern>
          <pattern id="03_darkAmber" width="100%" height="100%">
              <image xlinkHref="/03_darkAmber.jpg"/>
          </pattern>
          <pattern id="04_cherry" width="100%" height="100%">
              <image xlinkHref="/04_cherry.jpg"/>
          </pattern>
          <pattern id="05_redAmber" width="100%" height="100%">
              <image xlinkHref="/05_redAmber.jpg"/>
          </pattern>    
          <pattern id="06_orange" width="100%" height="100%">
              <image xlinkHref="/06_orange.jpg"/>
          </pattern>
          <pattern id="07_green" width="100%" height="100%">
              <image xlinkHref="/07_green.jpg"/>
          </pattern>
          <pattern id="08_olive" width="100%" height="100%">
              <image xlinkHref="/08_olive.jpg"/>
          </pattern>
          <pattern id="09_purple" width="100%" height="100%">
              <image xlinkHref="/09_purple.jpg"/>
          </pattern>
          <pattern id="10_lightBlue" width="100%" height="100%">
              <image xlinkHref="/10_lightBlue.jpg"/>
          </pattern>  
          <pattern id="11_aquaBlue" width="100%" height="100%">
              <image xlinkHref="/11_aquaBlue.jpg"/>
          </pattern>
          <pattern id="12_oceanBlue" width="100%" height="100%">
              <image xlinkHref="/12_oceanBlue.jpg"/>
          </pattern>
          <pattern id="13_cobaltBlue" width="100%" height="100%">
              <image xlinkHref="/13_cobaltBlue.jpg"/>
          </pattern>
          <pattern id="14_tealBlue" width="100%" height="100%">
              <image xlinkHref="/14_tealBlue.jpg"/>
          </pattern>
          <pattern id="15_clear" width="100%" height="100%">
              <image xlinkHref="/15_clear.jpg"/>
          </pattern>  
          <pattern id="16_granite" width="100%" height="100%">
              <image xlinkHref="/16_granite.jpg"/>
          </pattern>
          <pattern id="17_crackle" width="100%" height="100%">
              <image xlinkHref="/17_crackle.jpg"/>
          </pattern>
          <pattern id="18_iridescent" width="100%" height="100%">
              <image xlinkHref="/18_iridescent.jpg"/>
          </pattern>
          <pattern id="19_taffy" width="100%" height="100%">
              <image xlinkHref="/19_taffy.jpg"/>
          </pattern>
          <pattern id="20_seedy" width="100%" height="100%">
              <image xlinkHref="/20_seedy.jpg"/>
          </pattern> 
          <pattern id="21_frosted" width="100%" height="100%">
              <image xlinkHref="/21_frosted.jpg"/>
          </pattern>                                      
            <g className="denton-image" >

<path id="background" fill="black"  d="m0,500V0h500v500H0Z"/>


  <path onClick={() => props.onFill(1)} fill={props.fillColors[1]} d="m438.05,435.81c-1.84.5-58.31.56-60.75.03,0-.82,0-1.69,0-2.55,0-53.54,0-107.09,0-160.63,0-17.98.03-35.96.06-53.94.01-7.11.09-14.22.07-21.33,0-1.22.36-2.09,1.22-2.94,12.61-12.48,25.18-25,37.78-37.5,6.47-6.42,12.97-12.8,19.45-19.2.61-.6,1.23-1.2,2.16-2.12v300.19Z"/>
  <path onClick={() => props.onFill(2)} fill={props.fillColors[2]} d="m121.59,436.01h-59.27V136.35c.87.1,1.36.87,1.93,1.43,5.7,5.61,11.37,11.23,17.05,16.86,11.3,11.19,22.6,22.39,33.91,33.58,1.76,1.74,3.52,3.49,5.35,5.15.91.83,1.22,1.73,1.21,2.95-.06,5.12-.02,10.23-.02,15.35,0,22.78,0,45.56,0,68.34,0,14.55-.04,29.09-.05,43.64-.04,36.45-.07,72.9-.1,109.34,0,.95,0,1.89,0,3.01Z"/>
  <path onClick={() => props.onFill(3)} fill={props.fillColors[3]} d="m125.11,198.1c.83.78,1.41,1.29,1.96,1.83,11.7,11.58,23.42,23.14,35.07,34.76,14.54,14.5,29,29.06,43.54,43.55,1.06,1.06,1.42,2.13,1.42,3.59-.03,36.67-.03,73.35-.03,110.02,0,.71,0,1.43,0,1.96-.19.24-.24.3-.29.35-.06.05-.13.11-.2.13-.31.06-.62.15-.94.15-26.62,0-53.23,0-79.85,0-.31,0-.62-.09-.89-.14-.54-2-.37-189.43.19-196.21Z"/>
  <path onClick={() => props.onFill(4)} fill={props.fillColors[4]} d="m374.12,394.3h-81.09v-2.49c0-36.69.01-73.37-.03-110.06,0-1.58.48-2.66,1.58-3.76,16.93-16.87,33.82-33.79,50.74-50.67,7.81-7.79,15.66-15.53,23.52-23.28,1.68-1.66,3.43-3.25,5.6-5.3-.06,65.51.14,130.43-.31,195.55Z"/>
  <path onClick={() => props.onFill(5)} fill={props.fillColors[5]} d="m251.89,496.08v-56.77h186.21v56.77h-186.21Z"/>
  <path onClick={() => props.onFill(6)} fill={props.fillColors[6]} d="m248.75,439.31v56.52c-1.8.51-181.15.74-186.08.26-.51-1.64-.66-53.61-.14-56.78h186.22Z"/>
  <path onClick={() => props.onFill(7)} fill={props.fillColors[7]} d="m1.95,436.03v-179.91h57.12v179.91H1.95Z"/>
  <path onClick={() => props.onFill(8)} fill={props.fillColors[8]} d="m498.71,256.12c-.18,59.96-.35,119.78-.53,179.49-.15.17-.2.23-.26.28-.06.05-.12.13-.19.14-.39.06-.79.15-1.18.15-18.15,0-36.29,0-54.44-.02-.23,0-.45-.12-.84-.23v-179.81h57.43Z"/>
  <path onClick={() => props.onFill(9)} fill={props.fillColors[9]} d="m167.27,101.13c-3.17-3.02-6.23-5.93-9.29-8.85-.4-.39-.76-.82-1.16-1.21-12.22-12.3-24.46-24.58-36.67-36.9-15.58-15.72-31.15-31.45-46.71-47.18-.7-.71-1.33-1.49-2.25-2.54h177.16c.1.44.25.82.25,1.2.02,4.07-.02,8.15.04,12.22.02,1.3-.76,2.03-1.55,2.82-5.41,5.43-10.83,10.87-16.23,16.31-8.95,9.02-17.89,18.04-26.83,27.07-7.76,7.83-15.52,15.67-23.28,23.51-3.99,4.03-7.97,8.08-11.95,12.11-.55.56-1.14,1.08-1.53,1.45Z"/>
  <path onClick={() => props.onFill(10)} fill={props.fillColors[10]} d="m429.09,4.48c-32.17,32.5-63.97,64.63-95.95,96.94-1.32-1.31-2.5-2.47-3.67-3.64-11.25-11.35-22.5-22.7-33.76-34.05-10.29-10.38-20.6-20.75-30.89-31.14-3.99-4.03-7.93-8.11-11.95-12.12-.92-.92-1.32-1.85-1.3-3.15.07-4.22.03-8.45.03-12.83h177.48Z"/>
  <path onClick={() => props.onFill(11)} fill={props.fillColors[11]} d="m498.68,252.67h-57.49c-.03-.92-.07-1.84-.07-2.76,0-22.22,0-44.44,0-66.67,0-16.15.02-32.29-.03-48.44,0-1.66.5-2.82,1.67-3.97,9.86-9.81,19.67-19.67,29.49-29.53,8.01-8.04,16-16.11,24-24.16.61-.61,1.25-1.2,1.84-1.76.21.1.28.12.34.16.06.04.15.1.16.16.04.72.1,1.43.1,2.15,0,58.18,0,116.35,0,174.81Z"/>
  <path onClick={() => props.onFill(12)} fill={props.fillColors[12]} d="m59.22,252.65H1.96V75.21c.62.48,1.13.78,1.53,1.19,10.84,10.97,21.67,21.95,32.5,32.92.73.74,1.46,1.48,2.19,2.22,6.45,6.54,12.89,13.1,19.39,19.6.99.99,1.35,1.99,1.35,3.36.04,24.05.13,48.09.19,72.14.04,14.38.07,28.76.1,43.15,0,.87,0,1.73,0,2.85Z"/>
  <path onClick={() => props.onFill(13)} fill={props.fillColors[13]} d="m495.66,4.83c-53.49,53.65-106.98,107.3-160.7,161.18-.17-.71-.34-1.12-.34-1.52-.01-19.58,0-39.17-.04-58.75,0-1.27.54-2.06,1.36-2.88,10.3-10.38,20.58-20.79,30.88-31.18,8.55-8.63,17.11-17.25,25.66-25.89,7.54-7.61,15.06-15.23,22.6-22.84,5.74-5.79,11.49-11.57,17.21-17.38.84-.85,1.67-1.29,2.91-1.29,19.59,0,39.18-.04,58.76-.07.47,0,.93.05,1.4.07l.31.54Z"/>
  <path onClick={() => props.onFill(14)} fill={props.fillColors[14]} d="m165.27,166C111.4,111.94,57.94,58.31,4.49,4.67c.06-.15.11-.3.17-.46.77,0,1.53,0,2.3,0,12.39,0,24.79-.01,37.18,0,6.87,0,13.75.1,20.62.07,1.39,0,2.37.41,3.35,1.4,16.91,17.12,33.85,34.19,50.78,51.29,4.84,4.88,9.63,9.81,14.46,14.69,10.24,10.33,20.48,20.66,30.77,30.95,1.11,1.11,1.55,2.27,1.53,3.82-.06,7.83,0,15.67-.03,23.5-.05,11.34-.14,22.69-.22,34.03,0,.46-.06.92-.13,2.02Z"/>
  <path onClick={() => props.onFill(15)} fill={props.fillColors[15]} d="m165.24,233.41c-.57-.53-1.16-1.05-1.71-1.6-13.04-12.95-26.06-25.92-39.12-38.86-15.84-15.69-31.71-31.35-47.57-47.02-4.49-4.44-8.97-8.88-13.49-13.29-.86-.83-1.27-1.68-1.27-2.92.04-20.46.03-40.92.03-61.37,0-.32.05-.63.1-1.27.61.47,1.11.78,1.51,1.18,16.64,16.7,33.28,33.4,49.91,50.11,16.75,16.82,33.48,33.66,50.26,50.45,1.11,1.11,1.55,2.21,1.56,3.77.02,14.94.11,29.88.19,44.82.02,4.47.06,8.95.09,13.42,0,.78,0,1.57,0,2.35-.16.08-.32.16-.48.24Z"/>
  <path onClick={() => props.onFill(16)} fill={props.fillColors[16]} d="m334.6,233.04v-2.35c0-19.26.03-38.52-.04-57.78,0-1.84.57-3.1,1.85-4.38,33.21-33.14,66.38-66.32,99.56-99.49.59-.59,1.2-1.16,1.8-1.75l.46.22v2.31c0,19.66-.02,39.32.03,58.98,0,1.73-.45,2.97-1.71,4.2-8.17,7.99-16.26,16.06-24.39,24.1-7.61,7.53-15.23,15.05-22.84,22.59-8.07,7.98-16.12,15.97-24.19,23.95-7.73,7.64-15.45,15.29-23.19,22.92-2.26,2.23-4.57,4.42-6.86,6.63-.16-.05-.32-.11-.48-.16Z"/>
  <path onClick={() => props.onFill(17)} fill={props.fillColors[17]} d="m248.58,397.62v38.39h-123.96v-38.39h123.96Z"/>
  <path onClick={() => props.onFill(18)} fill={props.fillColors[18]} d="m374.01,397.59v38.2c-1.79.5-119.29.61-122.17.09v-38.29h122.17Z"/>
  <path onClick={() => props.onFill(19)} fill={props.fillColors[19]} d="m498.3,7.11c0,2.69,0,5.37,0,8.06.02,14.14.02,28.28.07,42.42.01,3.59.09,7.18.24,10.77.06,1.48-.39,2.55-1.45,3.59-3.71,3.63-7.34,7.35-11,11.03-13.12,13.2-26.25,26.41-39.37,39.6-1.74,1.75-3.49,3.47-5.43,5.4-.1-.69-.23-1.19-.23-1.69-.01-20.21,0-40.42-.04-60.64,0-1.33.48-2.22,1.38-3.11,3.36-3.31,6.65-6.68,9.97-10.03,11.03-11.11,22.07-22.22,33.1-33.34,3.49-3.52,6.96-7.05,10.44-10.57.55-.56,1.14-1.09,1.71-1.64.21.05.42.09.63.14Z"/>
  <path onClick={() => props.onFill(20)} fill={props.fillColors[20]} d="m58.74,128.01c-3.05-3.05-5.76-5.73-8.44-8.45-2.19-2.22-4.3-4.51-6.5-6.72-10.69-10.78-21.4-21.54-32.08-32.33-2.98-3.01-5.89-6.08-8.88-9.08-.72-.73-1.06-1.48-1.05-2.53.06-17.02.08-34.03.11-51.05,0-2.88,0-5.75,0-8.63,0-.7.06-1.39.12-2.41.66.51,1.1.79,1.46,1.15,18.08,18.09,36.14,36.18,54.23,54.26.77.77,1.21,1.54,1.21,2.7-.03,20.53-.02,41.07-.03,61.6,0,.31-.06.62-.15,1.48Z"/>
  <path onClick={() => props.onFill(21)} fill={props.fillColors[21]} d="m210.13,282.88c.9.83,1.48,1.33,2.02,1.87,7.35,7.34,14.7,14.68,22.03,22.03,4.4,4.41,8.74,8.88,13.17,13.27.93.92,1.31,1.87,1.3,3.17-.03,23-.02,46.01-.03,69.01,0,.62-.09,1.25-.13,1.83-2.02.55-36.25.57-38.37.03v-111.19Z"/>
  <path onClick={() => props.onFill(22)} fill={props.fillColors[22]} d="m289.91,283.07v111.24h-38.1c-.07-.63-.19-1.23-.19-1.83,0-23.16,0-46.33-.03-69.49,0-1.2.3-2.09,1.17-2.96,11.94-11.9,23.84-23.84,35.75-35.76.37-.37.8-.68,1.41-1.19Z"/>
  <path onClick={() => props.onFill(23)} fill={props.fillColors[23]} d="m59.09,439.49v56.59H2.08v-56.52c1.8-.5,54.5-.59,57.01-.07Z"/>
  <path onClick={() => props.onFill(24)} fill={props.fillColors[24]} d="m441.26,439.27h56.88c.33,18.98-.51,37.84-.23,56.84h-56.65v-56.84Z"/>
  <path onClick={() => props.onFill(25)} fill={props.fillColors[25]} d="m250.06,101.46c-13.17-13.3-26.18-26.45-39.37-39.78,13.11-13.26,26.12-26.4,39.36-39.78,13.11,13.21,26.18,26.39,39.44,39.76-13.18,13.31-26.25,26.5-39.43,39.81Z"/>
  <path onClick={() => props.onFill(26)} fill={props.fillColors[26]} d="m291.56,63.81c13.18,13.3,26.17,26.4,39.37,39.71-12.91,13.12-26.2,25.77-39.3,39.11-13.18-13.05-26.21-25.94-39.43-39.03,13.07-13.21,26.09-26.37,39.37-39.79Z"/>
  <path onClick={() => props.onFill(27)} fill={props.fillColors[27]} d="m208.58,142.55c-13.24-13.1-26.24-25.94-39.4-38.96,13.13-13.27,26.14-26.43,39.35-39.78,13.16,13.27,26.19,26.41,39.42,39.75-13.12,12.99-26.15,25.89-39.38,38.99Z"/>
  <path onClick={() => props.onFill(28)} fill={props.fillColors[28]} d="m250.08,105.7c13.2,13.09,26.17,25.95,39.31,38.98-13.15,13.01-26.13,25.86-39.33,38.92-13.12-12.96-26.15-25.85-39.39-38.92,13.15-13.01,26.19-25.9,39.4-38.97Z"/>
  <path onClick={() => props.onFill(29)} fill={props.fillColors[29]} d="m210.51,215.82c.57.53,1.15,1.05,1.71,1.6,11.59,11.58,23.15,23.17,34.77,34.72,1.17,1.16,1.68,2.34,1.67,3.99-.05,19.33-.03,38.67-.03,58,0,.76-.05,1.52-.09,2.71-.9-.82-1.45-1.26-1.94-1.76-11.51-11.54-22.99-23.11-34.53-34.62-1.45-1.44-2.09-2.91-2.08-5.01.08-18.93.05-37.87.05-56.8v-2.58c.16-.08.32-.16.48-.24Z"/>
  <path onClick={() => props.onFill(30)} fill={props.fillColors[30]} d="m331.72,174.26c0,.77,0,1.55,0,2.32-.03,17.33-.06,34.66-.11,51.99,0,2.39-.21,4.78-.18,7.17.02,1.24-.74,1.86-1.46,2.59-7.78,7.8-15.57,15.6-23.35,23.42-3.83,3.85-7.63,7.73-11.45,11.59-.54.55-1.13,1.05-2,1.85-.07-.91-.14-1.42-.14-1.93,0-19.73,0-39.45-.02-59.18,0-1.27.31-2.23,1.25-3.16,11.88-11.84,23.72-23.72,35.58-35.59.44-.44.93-.84,1.4-1.26.16.06.33.13.49.19Z"/>
  <path onClick={() => props.onFill(31)} fill={props.fillColors[31]} d="m248.51,249.23c-1.33-1.24-2.4-2.19-3.41-3.19-11.12-11.13-22.21-22.29-33.37-33.38-1.23-1.22-1.74-2.43-1.74-4.18.06-19.01.04-38.01.04-57.02v-2.89c.81.66,1.34,1.03,1.8,1.48,6.88,6.78,13.75,13.57,20.63,20.37,4.83,4.77,9.62,9.58,14.5,14.3,1.17,1.13,1.71,2.29,1.71,3.96-.05,19.4-.03,38.81-.04,58.21,0,.69-.07,1.39-.12,2.33Z"/>
  <path onClick={() => props.onFill(32)} fill={props.fillColors[32]} d="m168.78,173.89c.89.84,1.52,1.41,2.13,2.01,11.56,11.6,23.11,23.22,34.69,34.8.95.95,1.52,1.87,1.52,3.3-.05,19.81-.03,39.63-.03,59.44,0,.39-.04.79-.08,1.46-.57-.42-1.03-.67-1.38-1.03-11.93-11.91-23.85-23.84-35.79-35.74-.77-.77-1.25-1.54-1.24-2.7.03-20.13.02-40.27.03-60.4,0-.23.07-.47.17-1.16Z"/>
  <path onClick={() => props.onFill(33)} fill={props.fillColors[33]} d="m290.09,215.29v2.69c0,19.33-.02,38.67.04,58,0,1.74-.5,2.96-1.73,4.19-11.63,11.54-23.19,23.14-34.78,34.72-.5.5-1.03.96-1.79,1.67-.09-.85-.21-1.43-.21-2.01,0-19.65,0-39.31-.03-58.96,0-1.18.26-2.09,1.14-2.98,11.98-11.98,23.92-23.99,35.87-35.99.33-.33.69-.62,1.49-1.33Z"/>
  <path onClick={() => props.onFill(34)} fill={props.fillColors[34]} d="m331.72,107.35v2.42c0,19.09,0,38.18.02,57.27,0,1.34-.25,2.4-1.27,3.41-12.01,11.94-23.97,23.93-35.95,35.9-.33.33-.7.61-1.3,1.13-.08-.86-.19-1.45-.19-2.04,0-19.41,0-38.82-.03-58.23,0-1.27.28-2.24,1.23-3.17,12.08-11.86,24.12-23.76,36.18-35.65.27-.26.59-.48,1.3-1.04Z"/>
  <path onClick={() => props.onFill(35)} fill={props.fillColors[35]} d="m289.97,148.57c.05,1.09.12,1.85.12,2.61,0,19.09-.02,38.17.04,57.26,0,1.74-.5,2.96-1.73,4.19-11.54,11.5-23.01,23.07-34.51,34.61-.6.6-1.24,1.16-2.13,2-.07-.97-.15-1.56-.15-2.16,0-19.65,0-39.29-.03-58.94,0-1.39.43-2.37,1.42-3.33,11.91-11.69,23.78-23.43,35.66-35.14.32-.31.68-.58,1.31-1.1Z"/>
  <path onClick={() => props.onFill(36)} fill={props.fillColors[36]} d="m168.84,107.5c.65.6,1.06.96,1.45,1.34,11.68,11.6,23.35,23.22,35.07,34.78,1.23,1.21,1.77,2.41,1.77,4.17-.07,19.02-.04,38.04-.04,57.06,0,.79,0,1.57,0,2.73-.68-.5-1.13-.75-1.48-1.11-11.97-12-23.92-24-35.89-36-.71-.71-1.14-1.42-1.14-2.49.03-19.82.03-39.64.04-59.46,0-.22.09-.45.22-1.03Z"/>


          
    </g>
            </svg>
        </div>
    )
}