import React from 'react';
import Patterns from './patterns';


export default function Maywood(props) {
    
    return (
        <div className={`maywood-container ${props.size}`}> 
 
            <svg  xmlns="http://www.w3.org/2000/svg" id={props.id} width="500" height="500" viewBox="0 0 500 500">
                
<Patterns />
            <g className="hummingbird-image" >

<path id="background" fill="black"  d="m0,500V0h500v500H0Z"/>

  <path onClick={() => props.onFill(1)} fill={props.fillColors[1]} d="m365.59,140.47c-16.7-16.62-33.22-33.05-49.94-49.7,18.8-19.99,37.51-39.89,56.04-59.61,16.7,16.53,33.29,32.96,50.06,49.56-18.71,19.9-37.36,39.75-56.16,59.75Z"/>
  <path onClick={() => props.onFill(2)} fill={props.fillColors[2]} d="m422.51,82.14c15.77,15.6,31.37,31.02,47.14,46.62-19.84,18.74-39.57,37.38-59.43,56.13-14.41-14.63-28.68-29.13-43.12-43.79,18.34-19.51,36.94-39.3,55.41-58.96Z"/>
  <path onClick={() => props.onFill(3)} fill={props.fillColors[3]} d="m320.76,402.57c16.05-16.63,31.89-33.05,47.94-49.68,17.21,17.36,34.26,34.57,51.48,51.95-16.18,16.6-32.21,33.05-48.38,49.63-17.05-17.33-33.94-34.51-51.04-51.9Z"/>
  <path onClick={() => props.onFill(4)} fill={props.fillColors[4]} d="m137.77,354.92c15.88,16.01,31.55,31.81,47.33,47.73-16.95,17.33-33.74,34.5-50.38,51.52-15.29-15.35-30.49-30.6-45.95-46.12,16.01-17.36,32.38-35.11,48.99-53.13Z"/>
  <path onClick={() => props.onFill(5)} fill={props.fillColors[5]} d="m136.84,41.62c16.98,16.84,33.75,33.47,50.69,50.27-16.27,15.67-32.42,31.21-48.67,46.86-16.47-16.57-32.78-32.98-49.24-49.54,15.75-15.87,31.37-31.61,47.22-47.58Z"/>
  <path onClick={() => props.onFill(6)} fill={props.fillColors[6]} d="m44.57,364.09c17.03-16.9,34.19-33.93,51.55-51.16,13.3,13.41,26.85,27.07,40.57,40.91-16.52,17.91-32.89,35.66-49.22,53.36-14.36-14.43-28.56-28.7-42.9-43.11Z"/>
  <path onClick={() => props.onFill(7)} fill={props.fillColors[7]} d="m168.38,28.88V3.4c1.68-.5,159.72-.75,163.75-.25v25.72h-163.75Z"/>
  <path onClick={() => props.onFill(8)} fill={props.fillColors[8]} d="m421.24,403.74c-17.21-17.36-34.27-34.58-51.47-51.93,13.55-14.04,26.97-27.94,40.2-41.65,17.32,17.28,34.53,34.45,51.94,51.82-13.4,13.76-26.97,27.7-40.67,41.76Z"/>
  <path onClick={() => props.onFill(9)} fill={props.fillColors[9]} d="m186.19,403.72c21.91,22.07,43.61,43.94,65.65,66.15-1.11.08-1.78.16-2.44.16-8.01.02-16.02.03-24.03.03-24.34,0-48.69-.03-73.03.02-1.82,0-3.1-.52-4.35-1.83-3.51-3.67-7.15-7.2-10.74-10.79-.55-.55-1.06-1.14-1.76-1.89,16.87-17.25,33.64-34.41,50.68-51.84Z"/>
  <path onClick={() => props.onFill(10)} fill={props.fillColors[10]} d="m256.11,469.58c21.17-21.93,42.33-43.87,63.6-65.91,17.06,17.35,33.96,34.54,50.99,51.86-.98,1.02-1.84,1.94-2.71,2.84-3.2,3.3-6.47,6.55-9.59,9.92-1.17,1.27-2.37,1.78-4.12,1.77-10.07-.09-20.15-.05-30.22-.05-21.74.01-43.47.03-65.21.05-.84,0-1.68,0-2.53,0-.07-.16-.14-.31-.21-.47Z"/>
  <path onClick={() => props.onFill(11)} fill={props.fillColors[11]} d="m137.76,139.78c-14.14,13.62-28.07,27.03-42.12,40.56-16.04-16.35-31.92-32.53-47.98-48.9,13.66-13.75,27.17-27.35,40.9-41.17,16.4,16.5,32.7,32.9,49.2,49.5Z"/>
  <path onClick={() => props.onFill(12)} fill={props.fillColors[12]} d="m94.5,311.54c-17.26,17.11-34.42,34.12-51.7,51.26-.18-.15-.68-.53-1.11-.96-3.21-3.18-6.36-6.42-9.63-9.54-1.19-1.14-1.62-2.33-1.61-3.96.05-18.71.05-37.43.04-56.14,0-13.96-.04-27.91-.07-41.87,0-.93,0-1.87,0-2.91,1.82.95,60.13,59.34,64.09,64.12Z"/>
  <path onClick={() => props.onFill(13)} fill={props.fillColors[13]} d="m2.86,326.51c-.06-1.37-.17-2.77-.17-4.18,0-9.28.04-18.56.04-27.84,0-22.28.01-44.57,0-66.86-.01-15.15-.07-30.29-.11-45.44,0-.86,0-1.72,0-2.52,1.84-.59,23.31-.64,26.15-.08v146.88c-1.65.5-23.06.57-25.91.05Z"/>
  <path onClick={() => props.onFill(14)} fill={props.fillColors[14]} d="m176.02,471.56v26.22c-.87.05-1.71.14-2.55.14-10.55,0-21.1.01-31.65,0-17.21-.02-34.42-.07-51.63-.08-18.88,0-37.75.02-56.63.04-.93,0-1.86,0-2.91,0v-26.22c.89-.05,1.72-.14,2.56-.14,9.91,0,19.83,0,29.74,0,18.64.01,37.28.03,55.92.03,18.08,0,36.16,0,54.24,0,.86,0,1.73,0,2.9,0Z"/>
  <path onClick={() => props.onFill(15)} fill={props.fillColors[15]} d="m322.94,471.55v26.16c-1.09.05-2.18.15-3.27.15-4.84,0-9.68-.03-14.52-.04-32.76,0-65.52-.01-98.28-.01-8.8,0-17.61.02-26.41.03-.85,0-1.71,0-2.69,0v-26.2c.85-.04,1.68-.11,2.52-.11,11.82,0,23.64,0,35.46,0,15.62,0,31.25.03,46.87.03,19.12,0,38.23-.01,57.35-.02.93,0,1.86,0,2.97,0Z"/>
  <path onClick={() => props.onFill(16)} fill={props.fillColors[16]} d="m28.84,177.64H2.76c-.05-.91-.13-1.74-.13-2.57,0-14.68.03-29.35.03-44.03,0-22.37-.02-44.74-.04-67.11,0-10.15-.02-20.31-.03-30.46,0-.86,0-1.72,0-2.52,1.9-.54,21.67-.67,26.04-.19.04.86.13,1.77.13,2.68,0,19.36-.02,38.71-.02,58.07,0,22.13.02,44.26.04,66.4,0,5.63.04,11.26.05,16.89,0,.86,0,1.72,0,2.84Z"/>
  <path onClick={() => props.onFill(17)} fill={props.fillColors[17]} d="m497.43,469.95h-26.06c-.08-24.33,0-48.56,0-72.79,0-24.27,0-48.54,0-72.76,1.94-.54,23.63-.53,26.05-.02v145.57Z"/>
  <path onClick={() => props.onFill(18)} fill={props.fillColors[18]} d="m469.74,471.51v26.17h-144.98v-25.71c.06-.06.1-.13.16-.18.06-.05.13-.13.2-.13,9.9-.05,19.81-.14,29.71-.14,38.2-.02,76.41,0,114.92,0Z"/>
  <path onClick={() => props.onFill(19)} fill={props.fillColors[19]} d="m46.31,132.24c16.36,16.67,32.22,32.84,48.24,49.18-21.31,20.48-42.47,40.83-63.63,61.17-.17-.07-.33-.14-.5-.21,0-.76,0-1.51,0-2.27,0-15.38,0-30.77-.03-46.15-.02-14.12,0-28.23-.12-42.35-.02-2.01.6-3.39,2.02-4.76,4.05-3.91,7.97-7.95,11.91-11.97.72-.73,1.29-1.61,2.11-2.64Z"/>
  <path onClick={() => props.onFill(20)} fill={props.fillColors[20]} d="m471.55,177.16h25.62c.49,1.65.59,142.06.1,145.21h-25.73v-145.21Z"/>
  <path onClick={() => props.onFill(21)} fill={props.fillColors[21]} d="m497.46,175.38h-25.84V30.85c1.83-.52,22.75-.59,25.84-.11v144.64Z"/>
  <path onClick={() => props.onFill(22)} fill={props.fillColors[22]} d="m421.18,406.01c.62.56,1.17,1.01,1.66,1.5,15.24,15.39,30.48,30.78,45.69,46.2.57.58,1.22,1.42,1.24,2.16.11,4.59.06,9.18.06,14.01-1.35.07-2.51.18-3.66.18-11.74.01-23.48.01-35.22,0-13.88,0-27.77-.04-41.65,0-1.55,0-2.66-.44-3.74-1.56-3.72-3.9-7.54-7.72-11.31-11.57-.38-.39-.73-.82-1.29-1.44,16.07-16.49,32.1-32.94,48.23-49.49Z"/>
  <path onClick={() => props.onFill(23)} fill={props.fillColors[23]} d="m28.88,469.92H2.93v-141.42h25.95v141.42Z"/>
  <path onClick={() => props.onFill(24)} fill={props.fillColors[24]} d="m250.78,31.03c-20.64,19.87-41.29,39.74-62.13,59.81-16.94-16.79-33.74-33.45-50.72-50.27,1.81-1.83,3.46-3.52,5.13-5.19,1.57-1.56,2.93-3.68,4.83-4.48,1.9-.81,4.35-.35,6.56-.35,22.75-.01,45.51,0,68.26-.01,8.48,0,16.97-.02,25.45-.03.79,0,1.57,0,2.36,0,.09.18.17.35.26.53Z"/>
  <path onClick={() => props.onFill(25)} fill={props.fillColors[25]} d="m242.88,371.46c.57,1.98,1.19,4.1,1.8,6.23,2.54,9,5.12,17.99,7.59,27.01.45,1.66.68,3.43.68,5.15.09,19.19.12,38.37.16,57.56,0,.3-.07.59-.18,1.4-22-22.17-43.73-44.06-65.63-66.12.7-.75,1.32-1.44,1.96-2.1,10.36-10.61,20.72-21.21,31.09-31.81.7-.71,1.26-1.51,2.59-1.24,6.51,1.34,13.04,2.58,19.95,3.93Z"/>
  <path onClick={() => props.onFill(26)} fill={props.fillColors[26]} d="m166.56,28.96H30.38V3.13h135.92c.55,1.58.78,20.81.26,25.83Z"/>
  <path onClick={() => props.onFill(27)} fill={props.fillColors[27]} d="m469.94,3.09v25.66c-2.08.06-4.1.17-6.12.18-5.15.03-10.31.03-15.46.03-13.56-.01-27.12-.04-40.68-.04-20.46,0-40.92.02-61.38.03-3.33,0-6.66.03-9.99.03-.77,0-1.54-.06-2.42-.1V3.09h136.06Z"/>
  <path onClick={() => props.onFill(28)} fill={props.fillColors[28]} d="m95.68,310.32c-21.32-21.48-42.48-42.79-63.93-64.4.89-.13,1.39-.27,1.89-.27,18.96.03,37.92.02,56.89.18,2.65.02,5.32.79,7.95,1.35,8.77,1.85,17.52,3.75,26.28,5.65.85.18,1.67.46,2.68.74.15,1.26.34,2.5.44,3.74.45,5.38,1.36,10.69,2.57,15.96.28,1.24.13,2.19-.87,3.19-10.9,10.87-21.74,21.79-32.6,32.7-.33.33-.7.63-1.29,1.16Z"/>
  <path onClick={() => props.onFill(29)} fill={props.fillColors[29]} d="m318.55,402.67c-21.19,21.96-42.27,43.8-63.76,66.07-.09-1.53-.2-2.58-.2-3.62-.04-15.86.09-31.72-.15-47.57-.1-6.81.63-13.36,2.67-19.88,2.55-8.16,4.67-16.47,6.98-24.71.21-.75.47-1.49.72-2.28,1.38-.16,2.64-.26,3.89-.45,4.7-.73,9.39-1.47,14.07-2.26,1-.17,1.75-.18,2.55.65,10.81,11.05,21.66,22.06,32.5,33.09.22.22.39.49.74.95Z"/>
  <path onClick={() => props.onFill(30)} fill={props.fillColors[30]} d="m411.54,308.52c19.37-20.07,38.65-40.06,58.29-60.42.07,1.03.13,1.48.13,1.92,0,30.85-.02,61.7-.04,92.55,0,3.17.03,6.35-.06,9.51-.02.81-.2,1.83-.72,2.36-1.92,1.99-4,3.81-5.9,5.58-17.05-16.98-34.27-34.14-51.71-51.51Z"/>
  <path onClick={() => props.onFill(31)} fill={props.fillColors[31]} d="m369.73,31.04c-18.35,19.51-36.7,39.03-55.17,58.66-19.75-19.65-39.35-39.15-59.29-58.98,1.06-.09,1.72-.19,2.37-.19,22.76,0,45.52,0,68.27-.01,13.72,0,27.43-.04,41.15-.07.84,0,1.68,0,2.52,0,.05.2.09.4.14.59Z"/>
  <path onClick={() => props.onFill(32)} fill={props.fillColors[32]} d="m469.85,130.67v113.54c-19.71-19.61-39.01-38.81-58.54-58.24,19.46-18.38,38.82-36.67,58.54-55.3Z"/>
  <path onClick={() => props.onFill(33)} fill={props.fillColors[33]} d="m32.11,243.56c21.12-20.31,42.25-40.62,63.37-60.92,3.02,2.96,5.93,5.75,8.77,8.62,7.85,7.96,15.67,15.95,23.5,23.93.56.57,1.2,1.08,1.61,1.74.34.55.63,1.3.54,1.91-.84,5.88-1.77,11.74-2.71,17.88-.31.11-.88.38-1.48.51-8.83,1.9-17.68,3.7-26.49,5.69-4.83,1.09-9.68,1.43-14.62,1.39-16.57-.13-33.14-.13-49.71-.17-.85,0-1.7,0-2.56,0-.07-.19-.15-.39-.22-.58Z"/>
  <path onClick={() => props.onFill(34)} fill={props.fillColors[34]} d="m31.7,469.9c18.7-20.28,37.05-40.17,55.32-59.99,15.35,15.39,30.54,30.64,45.86,46-.14.17-.45.61-.82,1-3.87,3.98-7.78,7.92-11.61,11.93-.85.89-1.72,1.33-2.91,1.23-.31-.03-.63,0-.95,0-27.28-.01-54.55-.02-81.83-.04-.84,0-1.67-.07-3.05-.13Z"/>
  <path onClick={() => props.onFill(35)} fill={props.fillColors[35]} d="m88.53,88.14c-19.03-19.14-37.86-38.07-57.05-57.36,1.07-.08,1.71-.18,2.36-.18,16.42,0,32.84,0,49.25-.01,13.4,0,26.81,0,40.21-.07,1.63,0,2.83.4,3.96,1.6,2.66,2.82,5.45,5.52,8.43,8.5-15.71,15.83-31.37,31.61-47.17,47.52Z"/>
  <path onClick={() => props.onFill(36)} fill={props.fillColors[36]} d="m189.83,91.8c20.74-19.97,41.41-39.86,62.34-60.01.14.84.29,1.33.29,1.83.04,16.42.06,32.85.07,49.27,0,.94-.1,1.91-.34,2.82-3.04,11.17-6.11,22.34-9.18,33.5-.1.37-.24.74-.42,1.3-1.71.22-3.43.38-5.13.67-4.61.79-9.21,1.6-13.81,2.47-1.1.21-1.91.14-2.77-.72-10.03-10.05-20.11-20.06-30.17-30.08-.27-.27-.49-.59-.88-1.06Z"/>
  <path onClick={() => props.onFill(37)} fill={props.fillColors[37]} d="m379.6,254.27c1.65-.43,3.24-.92,4.86-1.26,9.62-2.01,19.25-3.99,28.88-5.96,1.08-.22,2.2-.35,3.3-.35,16.66.02,33.31.06,49.97.1.53,0,1.06.05,1.66.07-.82,1.59-55.27,58.3-58.04,60.48-.49-.45-1.03-.89-1.52-1.38-10.15-10.16-20.27-20.34-30.45-30.46-1.13-1.13-1.46-2.15-1.19-3.75.83-4.92,1.44-9.88,2.13-14.82.12-.86.25-1.71.39-2.69Z"/>
  <path onClick={() => props.onFill(38)} fill={props.fillColors[38]} d="m85.77,409.01c-18.1,19.62-36.4,39.47-55.08,59.72-.06-1.36-.13-2.19-.13-3.01,0-25.93,0-51.86-.01-77.79,0-2.78-.09-5.55,0-8.32.02-.8.27-1.79.79-2.33,3.69-3.82,7.47-7.55,11.23-11.31.1-.1.28-.14.26-.13,14.27,14.35,28.51,28.65,42.96,43.19Z"/>
  <path onClick={() => props.onFill(39)} fill={props.fillColors[39]} d="m30.46,31.9c19.14,19.24,37.96,38.16,57,57.3-13.63,13.72-27.17,27.36-40.78,41.06-2.67-2.66-5.2-5.15-7.7-7.67-2.4-2.42-4.73-4.92-7.18-7.29-1.11-1.08-1.58-2.21-1.56-3.77.07-5.55,0-11.1,0-16.65,0-20.47,0-40.93.02-61.4,0-.36.09-.72.2-1.58Z"/>
  <path onClick={() => props.onFill(40)} fill={props.fillColors[40]} d="m254.5,32.07c19.61,19.51,39.22,39.03,58.92,58.64-.45.56-.8,1.07-1.22,1.51-9.29,9.89-18.6,19.75-27.85,29.67-1.1,1.17-2.06,1.52-3.7,1.19-4.97-1-9.99-1.77-15-2.51-1.21-.18-1.79-.63-2.11-1.79-3.05-11.17-6.15-22.32-9.21-33.49-.21-.75-.26-1.57-.26-2.35-.06-16.42-.1-32.84-.13-49.27,0-.45.05-.91.07-1.36.16-.08.32-.16.49-.24Z"/>
  <path onClick={() => props.onFill(41)} fill={props.fillColors[41]} d="m410.29,187.09c19.36,19.26,38.68,38.48,58.35,58.05-1.08.08-1.67.16-2.26.16-16.66-.03-33.32-.01-49.98-.17-2.66-.02-5.33-.73-7.96-1.29-8.84-1.86-17.67-3.8-26.51-5.72-.76-.17-1.5-.44-2.33-.69-.11-.66-.24-1.27-.32-1.89-.73-5.1-1.4-10.21-2.19-15.3-.19-1.23.02-2.06.95-2.93,10.36-9.71,20.68-19.47,31.01-29.21.34-.32.72-.6,1.23-1.02Z"/>
  <path onClick={() => props.onFill(42)} fill={props.fillColors[42]} d="m254.39,399.93v-152.25c.57.35,1.05.56,1.41.89,10.37,9.22,20.71,18.48,31.11,27.66,1.24,1.09,1.25,2.09.91,3.53-5.47,23.72-12.5,47.03-18.97,70.48-4.39,15.89-8.87,31.75-13.31,47.63-.21.74-.46,1.46-.69,2.19-.16-.04-.31-.08-.47-.13Z"/>
  <path onClick={() => props.onFill(43)} fill={props.fillColors[43]} d="m252.46,399.72c-1.59-5.63-3.19-11.26-4.78-16.89-7.4-26.24-14.86-52.47-22.17-78.73-2.32-8.32-4.24-16.75-6.35-25.12-.25-.98-.24-1.76.64-2.54,10.7-9.44,21.37-18.92,32.06-28.39.15-.14.4-.17.87-.35v152.03c-.09,0-.18,0-.26,0Z"/>
  <path onClick={() => props.onFill(44)} fill={props.fillColors[44]} d="m252.57,92.15v151.23c-.61-.44-1.12-.75-1.56-1.14-10.19-9.07-20.35-18.18-30.59-27.19-1.33-1.18-1.57-2.18-1.1-3.87,6.77-24.37,13.46-48.76,20.15-73.15,4.01-14.6,7.98-29.22,11.97-43.82.18-.68.39-1.35.59-2.02l.54-.03Z"/>
  <path onClick={() => props.onFill(45)} fill={props.fillColors[45]} d="m254.24,243.64V91.84c.08-.02.16-.04.24-.06.12.34.25.67.34,1.01,11.15,39.52,22.24,79.06,32.87,118.73.44,1.64.14,2.57-1.1,3.66-10.18,8.96-20.31,17.99-30.45,26.99-.45.4-.96.74-1.91,1.47Z"/>
  <path onClick={() => props.onFill(46)} fill={props.fillColors[46]} d="m160.46,330.55c16.4,17.5,36.24,29.49,59.64,36.28-11.4,11.67-22.56,23.1-33.92,34.74-15.78-15.91-31.44-31.7-47.08-47.47.76-1.57,18.01-20.58,21.36-23.55Z"/>
  <path onClick={() => props.onFill(47)} fill={props.fillColors[47]} d="m346.59,331.17c7.08,6.94,13.98,13.7,21.04,20.61-15.98,16.56-31.87,33.03-47.94,49.68-11.17-11.36-22.26-22.62-33.64-34.19,23.88-6.34,43.95-18.47,60.54-36.11Z"/>
  <path onClick={() => props.onFill(48)} fill={props.fillColors[48]} d="m374.22,30.54h94.68c-15.52,16.51-30.68,32.65-45.62,48.56-16.32-16.16-32.52-32.19-49.06-48.56Z"/>
  <path onClick={() => props.onFill(49)} fill={props.fillColors[49]} d="m463.41,362.64c2.09,2.2,4.06,4.15,5.85,6.25.49.57.61,1.59.61,2.41.04,8.88.03,17.76.02,26.64,0,10.86-.03,21.72-.03,32.59,0,6.5,0,13,0,19.5,0,.68,0,1.37-.25,2.18-15.9-15.62-31.48-31.44-47.3-47.37,13.64-14.01,27.17-27.9,41.09-42.19Z"/>
  <path onClick={() => props.onFill(50)} fill={props.fillColors[50]} d="m220.27,124.42c-23.47,6.45-43.28,18.3-59.67,35.51-6.76-6.78-13.45-13.49-20.34-20.4,15.91-15.32,32.03-30.84,48.38-46.59,10.52,10.46,20.92,20.81,31.63,31.48Z"/>
  <path onClick={() => props.onFill(51)} fill={props.fillColors[51]} d="m284.49,123.9c10.24-10.89,20.12-21.38,30.12-32.01,16.68,16.59,33.2,33.03,49.94,49.68-6.03,6.42-11.97,12.74-18.07,19.23-17.16-18.09-37.63-30.36-61.98-36.9Z"/>
  <path onClick={() => props.onFill(52)} fill={props.fillColors[52]} d="m101.45,245.78c49.78.16,99.56.32,149.66.48-.48.59-.75,1.03-1.12,1.35-10.31,9.17-20.65,18.3-30.92,27.51-1.15,1.03-2.16.94-3.44.59-22.65-6.11-45.31-12.16-67.94-18.31-14.68-3.99-29.37-7.94-44.34-10.75-.67-.13-1.31-.37-1.97-.56l.07-.32Z"/>
  <path onClick={() => props.onFill(53)} fill={props.fillColors[53]} d="m256.25,246.21c49.7.16,99.41.33,149.11.49.02.09.04.19.06.28-.47.15-.93.35-1.41.44-23.9,4.41-47.15,11.43-70.64,17.45-13.98,3.58-27.92,7.33-41.86,11.04-1.31.35-2.32.43-3.46-.6-10.38-9.34-20.82-18.61-31.25-27.89-.24-.21-.55-.33-.83-.49.1-.24.19-.48.29-.73Z"/>
  <path onClick={() => props.onFill(54)} fill={props.fillColors[54]} d="m256.14,244.22c2.54-2.26,5.07-4.52,7.61-6.78,8-7.11,16.03-14.19,23.98-21.35,1.16-1.05,2.16-1.21,3.61-.81,28.53,7.73,57.05,15.48,85.63,23.05,8.57,2.27,17.29,3.98,25.94,5.95.76.17,1.51.36,2.24.9-49.61-.15-99.22-.31-148.83-.46-.06-.16-.12-.33-.18-.49Z"/>
  <path onClick={() => props.onFill(55)} fill={props.fillColors[55]} d="m250.69,244.75c-49.59-.15-99.18-.31-148.77-.46-.02-.11-.03-.22-.05-.33,1.21-.26,2.41-.57,3.63-.79,19.61-3.54,38.66-9.38,57.92-14.34,17.35-4.47,34.67-9.07,51.97-13.71,1.68-.45,2.77-.15,4.02.97,9.91,8.91,19.88,17.75,29.82,26.62.56.5,1.07,1.05,1.61,1.58-.06.15-.11.3-.17.45Z"/>
  <path onClick={() => props.onFill(56)} fill={props.fillColors[56]} d="m470.07,126c-15.35-15.16-30.61-30.24-46.03-45.47,15.17-16.15,30.3-32.25,45.39-48.32.22.08.3.1.37.13.06.04.15.09.15.14.05.31.12.62.12.94,0,30.92,0,61.83,0,92.57Z"/>
  <path onClick={() => props.onFill(57)} fill={props.fillColors[57]} d="m96.91,311.56c11.63-11.46,23.05-22.71,34.5-33.99,5.14,19.23,14.44,36.5,27.93,51.7-7.21,7.83-14.33,15.55-21.61,23.45-13.66-13.78-27.15-27.38-40.82-41.16Z"/>
  <path onClick={() => props.onFill(58)} fill={props.fillColors[58]} d="m130.53,215.88c-11.42-11.63-22.55-22.97-33.82-34.45,14.08-13.57,27.99-26.97,41.8-40.27,6.85,6.85,13.6,13.6,20.51,20.51-13.86,15.44-23.4,33.45-28.49,54.21Z"/>
  <path onClick={() => props.onFill(59)} fill={props.fillColors[59]} d="m369.08,350.32c-7.08-6.93-14.03-13.74-21.11-20.67,13.83-15.54,23.25-33.39,28.2-53.33,10.92,10.82,21.82,21.62,32.88,32.58-13.35,13.83-26.77,27.73-39.97,41.41Z"/>
  <path onClick={() => props.onFill(60)} fill={props.fillColors[60]} d="m347.49,161.95c6.13-6.52,12.04-12.81,18.14-19.3,14.55,14.47,28.97,28.82,43.51,43.28-10.94,10.31-21.71,20.47-32.74,30.87-5.13-20.88-14.76-38.95-28.91-54.86Z"/>
  <path onClick={() => props.onFill(61)} fill={props.fillColors[61]} d="m223.49,302.6c4.92,17.42,9.73,34.49,14.66,51.96-12.97-1.7-24.86-5.79-36.08-11.76-11.2-5.96-21.16-13.59-29.93-23.41,17.2-5.63,34.1-11.16,51.35-16.8Z"/>
  <path onClick={() => props.onFill(62)} fill={props.fillColors[62]} d="m283.01,188.68c-4.59-16.91-9.13-33.64-13.77-50.72,12.44,1.46,23.82,5.12,34.65,10.55,11.57,5.81,21.8,13.45,30.86,23.26-17.39,5.68-34.45,11.26-51.73,16.9Z"/>
  <path onClick={() => props.onFill(63)} fill={props.fillColors[63]} d="m283.72,302.77c17.15,5.7,34.04,11.31,51.31,17.04-8.32,9.15-17.8,16.29-28.43,21.91-11.5,6.07-23.7,10.01-37.02,11.57,4.75-16.99,9.42-33.65,14.14-50.51Z"/>
  <path onClick={() => props.onFill(64)} fill={props.fillColors[64]} d="m172.12,171.31c8.42-9.15,18.01-16.18,28.73-21.65,11.45-5.84,23.58-9.54,36.78-10.84-4.5,16.42-8.93,32.58-13.44,48.99-17.39-5.51-34.57-10.95-52.08-16.5Z"/>
  <path onClick={() => props.onFill(65)} fill={props.fillColors[65]} d="m189.63,220.39c-15.14,3.99-30.14,7.94-45.51,12,.97-11.24,3.94-21.67,8.37-31.68,4.42-9.98,10.21-19.12,17.61-27.23,1.11,1.35,18.55,43.17,19.54,46.91Z"/>
  <path onClick={() => props.onFill(66)} fill={props.fillColors[66]} d="m336.74,317.99c-6.57-15.9-12.98-31.41-19.53-47.26,15.2-4.14,30.27-7.98,45.68-12.08-1,11.21-3.98,21.63-8.37,31.65-4.38,10-10.16,19.15-17.79,27.69Z"/>
  <path onClick={() => props.onFill(67)} fill={props.fillColors[67]} d="m317.09,220.7c6.47-15.75,12.81-31.2,19.31-47.03,7.6,8.33,13.34,17.53,17.82,27.51,4.5,10.02,7.49,20.49,8.66,31.85-15.39-4.14-30.48-8.2-45.78-12.32Z"/>
  <path onClick={() => props.onFill(68)} fill={props.fillColors[68]} d="m170.23,317.34c-14.57-17.12-23.24-36.68-26.2-59.33,15.4,4.14,30.43,8.19,45.44,12.22-.15,1.8-17.62,44.56-19.24,47.11Z"/>
  <path onClick={() => props.onFill(69)} fill={props.fillColors[69]} d="m242.2,122.15c-1.42,5.21-2.73,10-4.1,15-26.45,2.96-48.94,14.02-67.41,33.28-.44-.36-.76-.58-1.04-.85-2.72-2.65-5.43-5.31-8.36-8.19,22.2-23.07,48.92-36.05,80.91-39.23Z"/>
  <path onClick={() => props.onFill(70)} fill={props.fillColors[70]} d="m336.19,320.9c3.11,3.12,6.06,6.08,9.21,9.24-10.24,11.1-22.45,20.22-36.39,27.04-13.74,6.73-28.23,10.7-43.82,11.86,1.35-4.82,2.61-9.37,3.93-14.1,12.93-1.54,25.18-5.21,36.71-11.08,11.53-5.87,21.75-13.58,30.35-22.95Z"/>
  <path onClick={() => props.onFill(71)} fill={props.fillColors[71]} d="m264.6,121.88c31.94,3.59,58.67,16.87,80.43,39.57-3.1,3.19-6.01,6.17-9,9.26-.25-.22-.62-.5-.94-.83-5.78-6.15-12.22-11.53-19.25-16.19-6.56-4.35-13.52-7.95-20.86-10.79-7.79-3.01-15.8-5.2-24.1-6.26-1.52-.19-2.36-.78-2.76-2.36-.86-3.38-1.95-6.69-2.94-10.04-.19-.66-.33-1.33-.57-2.35Z"/>
  <path onClick={() => props.onFill(72)} fill={props.fillColors[72]} d="m161.69,329.65c3.11-3.25,5.95-6.23,9-9.42,1.03,1.05,2.02,2.03,2.98,3.03,9,9.35,19.36,16.81,31,22.53,9.89,4.86,20.24,8.23,31.12,9.99.63.1,1.3.13,1.85.4.5.24,1.12.69,1.26,1.18,1.13,3.8,2.16,7.62,3.21,11.45.03.12-.09.28-.22.66-31.54-3.72-58.38-16.82-80.2-39.82Z"/>
  <path onClick={() => props.onFill(73)} fill={props.fillColors[73]} d="m169.4,318.72c-3.13,3.22-6,6.16-9.1,9.34-18.53-21.09-28.95-45.53-31.16-73.53,1.78-.13,9.86,1.86,13.34,3.27,1.32,11.19,4.25,22.1,8.86,32.51,4.61,10.41,10.67,19.94,18.06,28.4Z"/>
  <path onClick={() => props.onFill(74)} fill={props.fillColors[74]} d="m337.44,172.05c.28-.32.52-.63.8-.91,2.64-2.61,5.29-5.22,8.16-8.05,7.94,8.76,14.36,18.36,19.48,28.86,6.87,14.11,10.88,28.97,11.93,44.82-.67-.06-1.14-.04-1.57-.16-3.37-.89-6.72-1.87-10.1-2.69-1.33-.32-1.74-1.01-1.89-2.29-.84-7.1-2.44-14.04-4.69-20.83-3.19-9.61-7.57-18.64-13.31-26.97-2.74-3.97-5.77-7.74-8.81-11.79Z"/>
  <path onClick={() => props.onFill(75)} fill={props.fillColors[75]} d="m142.43,232.83c-4.47,1.18-8.74,2.3-13.39,3.53,2.08-28.32,12.55-52.7,31.02-73.57,3.07,3.08,6.02,6.04,9.17,9.22-15.17,17.12-24.24,37.41-26.81,60.83Z"/>
  <path onClick={() => props.onFill(76)} fill={props.fillColors[76]} d="m364.54,258.22c4.55-1.2,8.75-2.31,13.31-3.51-.84,13.89-3.94,26.94-9.23,39.51-5.3,12.61-12.52,24-21.46,33.99-3.04-3.05-6.04-6.05-9.21-9.22,7.31-8.19,13.42-17.7,18-28.13,4.55-10.36,7.44-21.21,8.59-32.64Z"/>
  <path onClick={() => props.onFill(77)} fill={props.fillColors[77]} d="m28.94,471.58v25.91c-1.64.54-22.48.67-25.78.17-.5-1.75-.57-21.91-.05-26.13,8.54.01,17.12.02,25.83.05Z"/>
  <path onClick={() => props.onFill(78)} fill={props.fillColors[78]} d="m497.34,497.71h-25.93c-.07-8.7-.07-17.29-.1-26.05h25.79c.48,1.77.67,23.01.24,26.05Z"/>
  <path onClick={() => props.onFill(79)} fill={props.fillColors[79]} d="m497.51,3.08v25.47c-1.68.55-21.77.65-25.54.15-.51-1.69-.7-22.72-.21-25.62h25.75Z"/>
  <path onClick={() => props.onFill(80)} fill={props.fillColors[80]} d="m2.78,3.07h25.48c.58,1.73.67,23.22.13,25.81H2.78V3.07Z"/>
  <path onClick={() => props.onFill(81)} fill={props.fillColors[81]} d="m333.76,316.01c-14.22-12.68-28.45-25.37-43-38.34,8.5-2.25,16.59-4.38,24.95-6.59,6.18,14.94,12.3,29.76,18.43,44.59-.13.11-.25.23-.38.34Z"/>
  <path onClick={() => props.onFill(82)} fill={props.fillColors[82]} d="m216.37,277.46c-14.54,12.91-28.73,25.51-42.92,38.11-.14-.09-.27-.18-.41-.26,6.07-14.75,12.15-29.51,18.34-44.54,8.24,2.21,16.37,4.39,24.99,6.7Z"/>
  <path onClick={() => props.onFill(83)} fill={props.fillColors[83]} d="m216.07,213.42c-8.37,2.2-16.46,4.33-24.72,6.51-6.17-14.94-12.26-29.7-18.39-44.56,1.66.7,39.31,33.93,43.11,38.05Z"/>
  <path onClick={() => props.onFill(84)} fill={props.fillColors[84]} d="m315.61,220.29c-8.33-2.24-16.35-4.4-24.34-6.55-.04-.16-.06-.23-.07-.3,0-.07-.01-.18.03-.22.32-.35.62-.71.98-1.03,13.74-12.2,27.48-24.39,41.64-36.35-6.04,14.73-12.09,29.45-18.24,44.45Z"/>
  <path onClick={() => props.onFill(85)} fill={props.fillColors[85]} d="m223.8,189.27c-2.12,7.74-4.15,15.14-6.16,22.46-.2.08-.26.12-.33.13-.08.01-.19.04-.23,0-13.99-12.42-27.97-24.84-41.95-37.27-.15-.13-.21-.37-.5-.91,16.51,5.23,32.71,10.37,49.17,15.58Z"/>
  <path onClick={() => props.onFill(86)} fill={props.fillColors[86]} d="m332.07,174.77c-14.09,12.51-28.17,25.01-42.57,37.79-2.08-7.64-4.05-14.88-6.1-22.41,16.21-5.3,32.3-10.56,48.39-15.82.09.15.18.29.28.44Z"/>
  <path onClick={() => props.onFill(87)} fill={props.fillColors[87]} d="m289.63,278.7c14.46,12.89,28.58,25.48,42.7,38.08-.08.15-.17.3-.25.45-15.9-5.28-31.81-10.56-47.97-15.93,1.83-7.51,3.63-14.84,5.52-22.6Z"/>
  <path onClick={() => props.onFill(88)} fill={props.fillColors[88]} d="m223.09,301.13c-16.12,5.27-31.99,10.46-47.86,15.65-.1-.15-.19-.31-.29-.46,14.07-12.55,28.18-25.06,42.58-37.8,1.91,7.74,3.72,15.09,5.58,22.61Z"/>
  <path onClick={() => props.onFill(89)} fill={props.fillColors[89]} d="m30.42,145.72v-29.7c5.22,5.33,10.07,10.28,14.94,15.25-5.04,4.88-9.97,9.65-14.94,14.46Z"/>
  <path onClick={() => props.onFill(90)} fill={props.fillColors[90]} d="m359.31,469.88c.21-.41.27-.64.42-.79,3.96-4.09,7.92-8.17,11.62-11.98,4.4,4.21,8.71,8.33,13.08,12.5-1.97.55-19.01.72-25.12.26Z"/>
  <path onClick={() => props.onFill(91)} fill={props.fillColors[91]} d="m121.65,469.75c4.34-4.43,8.43-8.61,12.51-12.78,4.27,4.4,8.43,8.67,12.44,12.81-1.22.36-21.3.48-24.96-.03Z"/>
  <path onClick={() => props.onFill(92)} fill={props.fillColors[92]} d="m41.77,364.38c-3.68,3.67-7.26,7.24-10.83,10.8-.54-1.44-.71-17.77-.21-21.51,1.65.87,8.43,7.43,11.04,10.71Z"/>
  <path onClick={() => props.onFill(93)} fill={props.fillColors[93]} d="m145.49,30.7c-3.04,3.08-5.73,5.8-8.67,8.78-2.88-2.87-5.68-5.65-8.82-8.78h17.49Z"/>
  <path onClick={() => props.onFill(94)} fill={props.fillColors[94]} d="m469.77,366.99c-1.84-2.04-3.39-3.75-5.05-5.6,1.53-1.36,3.3-2.94,5.05-4.49v10.09Z"/>
        
                
    </g>
            </svg>
        </div>
    )
}