import React from 'react';
import Patterns from './patterns';

export default function Habra(props) {
    
    return (
        <div className={`Habra-container ${props.size}`}> 
 
            <svg  xmlns="http://www.w3.org/2000/svg" id={props.id} width="500" height="500" viewBox="0 0 500 500">
                
<Patterns />                                      
            <g className="hummingbird-image" >

<path id="background" fill="black"  d="m0,500V0h752v752H0Z"/>


    <path onClick={() => props.onFill(1)} fill={props.fillColors[1]} d="M326.9,251.02c8.43,0,17.09,0,25.75,0,7.6,0,15.21-.07,22.81,.07,1.32,.03,2.92,.67,3.88,1.57,4.13,3.86,8.16,7.85,12.03,11.97,.98,1.04,1.76,2.74,1.76,4.14,.09,38.76,.06,77.53,.11,116.29,0,2.28-.57,3.16-3.05,3.16-41.12-.07-82.23-.03-123.35-.11-1.32,0-2.94-.67-3.9-1.57-4.27-4.02-8.39-8.22-12.48-12.43-.68-.7-1.36-1.78-1.36-2.68-.08-15.01-.06-30.02-.06-44.82,46.04-4.62,71.83-29.58,77.87-75.59Z"/>
    <path onClick={() => props.onFill(2)} fill={props.fillColors[2]} d="M246.44,326.03c0,8.11,0,15.97,0,23.83,0,6.82,.08,13.64-.06,20.45-.03,1.33-.6,2.95-1.5,3.91-3.94,4.2-8.01,8.3-12.22,12.24-1.03,.96-2.77,1.66-4.19,1.66-40.16,.09-80.32,.07-120.48,.06-.94,0-1.87-.12-3.2-.22v-3.98c0-38.24,.02-76.48-.05-114.72,0-2.56,.73-4.4,2.59-6.14,3.9-3.64,7.57-7.54,11.42-11.23,.77-.73,1.98-1.45,2.98-1.44,16.16,.12,32.32,.35,48.48,.57,.41,0,.81,.13,1.42,.24,1.06,20.04,8.19,37.51,22.42,51.82,14.25,14.32,31.7,21.68,52.39,22.94Z"/>
    <path onClick={() => props.onFill(3)} fill={props.fillColors[3]} d="M393.2,111.74c0,1.66,0,2.92,0,4.17,0,37.81-.02,75.62,.05,113.43,0,2.66-.71,4.6-2.68,6.42-3.84,3.55-7.41,7.39-11.2,10.99-.84,.8-2.14,1.57-3.24,1.58-16.17,.13-32.33,.14-48.5,.17-.31,0-.62-.11-1.26-.24-.74-20.75-8.04-38.91-23.01-53.67-14.9-14.69-33.08-21.88-54.2-22.44-.07-1.16-.19-2.19-.19-3.21,.02-13.1,0-26.2,.17-39.3,.02-1.39,.76-3.08,1.72-4.11,3.87-4.12,7.89-8.12,12.03-11.97,1.03-.96,2.72-1.74,4.11-1.74,41.12-.09,82.24-.07,123.36-.07h2.83Z"/>
    <path onClick={() => props.onFill(4)} fill={props.fillColors[4]} d="M104.96,111.74h3.71c39.73,0,79.46,.02,119.2-.04,2.21,0,3.8,.57,5.33,2.18,3.75,3.94,7.74,7.67,11.52,11.59,.81,.84,1.63,2.13,1.64,3.22,.11,14.37,.07,28.74,.07,43.45-20.61,1.27-38.36,8.67-52.73,23.34-14.31,14.6-21.35,32.3-22.1,52.86-2.9,0-5.65,.03-8.4,0-13.59-.17-27.19-.32-40.78-.62-1.31-.03-2.88-.74-3.85-1.65-3.89-3.65-7.51-7.59-11.41-11.24-1.79-1.68-2.45-3.46-2.44-5.88,.07-23.54,.04-47.08,.04-70.62,0-14.17,0-28.33,0-42.5,0-1.25,.11-2.49,.18-4.07Z"/>
    <path onClick={() => props.onFill(5)} fill={props.fillColors[5]} d="M249.38,37.75h143.63c.07,1.29,.19,2.43,.19,3.56,.01,21.31-.07,42.62,.09,63.92,.02,3.35-1.05,4.02-4.19,4.01-40.49-.1-80.98-.08-121.47-.02-2.33,0-4.01-.64-5.61-2.34-3.66-3.88-7.56-7.54-11.25-11.4-.8-.84-1.61-2.12-1.62-3.2-.08-17.9-.04-35.8-.02-53.69,0-.2,.12-.39,.26-.85Z"/>
    <path onClick={() => props.onFill(6)} fill={props.fillColors[6]} d="M105.09,37.71H246.26c.07,1.22,.17,2.25,.17,3.29,.01,16.72,.06,33.45-.08,50.17-.01,1.58-.84,3.49-1.92,4.67-3.67,4.01-7.55,7.86-11.53,11.56-1.04,.97-2.76,1.7-4.17,1.7-40.27,.09-80.54,.06-120.81,.1-1.97,0-3.19-.2-3.18-2.68,.07-22.26,.04-44.52,.06-66.79,0-.61,.17-1.23,.29-2.02Z"/>
    <path onClick={() => props.onFill(7)} fill={props.fillColors[7]} d="M392.9,460.4H249.17c-.05-.99-.14-2-.14-3.02,0-16.19-.06-32.38,.08-48.57,.01-1.58,.85-3.48,1.93-4.66,3.76-4.07,7.73-7.98,11.8-11.75,1.04-.97,2.75-1.73,4.16-1.73,41.02-.09,82.03-.06,123.05-.11,2.3,0,3.21,.55,3.2,3.01-.08,21.41-.04,42.82-.06,64.23,0,.82-.18,1.64-.29,2.6Z"/>
    <path onClick={() => props.onFill(8)} fill={props.fillColors[8]} d="M104.92,460.31c-.05-1.2-.14-2.25-.14-3.29,0-20.88,.06-41.75-.08-62.63-.02-3.16,1.01-3.86,4.02-3.85,39.73,.09,79.46,.04,119.2,.15,1.69,0,3.74,.87,5,2.01,4.1,3.73,7.95,7.75,11.81,11.74,.75,.78,1.58,1.93,1.58,2.91,.1,17.57,.07,35.14,.07,52.97H104.92Z"/>
    <path onClick={() => props.onFill(9)} fill={props.fillColors[9]} d="M458.89,250.67v137.28h-63.07c-.06-1.05-.18-2.07-.18-3.09,0-38.54-.03-77.08,.07-115.62,0-1.58,.83-3.49,1.92-4.66,3.76-4.07,7.76-7.93,11.77-11.77,.85-.81,2.11-1.68,3.2-1.71,15.08-.31,30.16-.48,45.25-.67,.21,0,.42,.09,1.04,.24Z"/>
    <path onClick={() => props.onFill(10)} fill={props.fillColors[10]} d="M395.85,111.86h62.67V247.55c-.84,.1-1.86,.32-2.88,.33-14.02,.19-28.04,.4-42.06,.44-1.41,0-3.14-.7-4.17-1.66-4.21-3.94-8.25-8.06-12.23-12.23-.79-.83-1.47-2.16-1.47-3.27-.07-39.3-.06-78.59-.05-117.89,0-.4,.11-.81,.19-1.41Z"/>
    <path onClick={() => props.onFill(11)} fill={props.fillColors[11]} d="M102.15,388.04c-1.23,.06-2.18,.14-3.13,.14-17.88,0-35.77-.05-53.65,.07-2.81,.02-3.64-.75-3.64-3.57,.08-43.56,.06-87.12,.06-130.68,0-1.05,0-2.09,0-3.61,1.37,0,2.4,0,3.43,0,12.96,0,25.92-.07,38.87,.08,1.6,.02,3.54,.81,4.71,1.89,4.16,3.83,8.11,7.9,12.01,12,.79,.83,1.47,2.17,1.47,3.27,.07,39.62,.06,79.24,.05,118.86,0,.41-.09,.83-.18,1.56Z"/>
    <path onClick={() => props.onFill(12)} fill={props.fillColors[12]} d="M41.98,247.48V111.99h60.2c.05,1.01,.15,2.04,.15,3.07,0,38.14,.03,76.28-.07,114.42,0,1.58-.8,3.51-1.88,4.68-3.74,4.09-7.72,7.99-11.73,11.82-.91,.87-2.33,1.69-3.52,1.7-13.92,.11-27.85,.06-41.77,.05-.31,0-.62-.11-1.37-.25Z"/>
    <path onClick={() => props.onFill(13)} fill={props.fillColors[13]} d="M458.47,37.75V108.91h-62.58V37.75h62.58Z"/>
    <path onClick={() => props.onFill(14)} fill={props.fillColors[14]} d="M458.87,460.4h-63.05v-69.56h63.05v69.56Z"/>
    <path onClick={() => props.onFill(15)} fill={props.fillColors[15]} d="M42.25,37.57h59.87V108.97c-1.05,.08-1.98,.2-2.92,.21-17.99,.01-35.97-.04-53.96,.06-2.59,.01-3.53-.63-3.51-3.36,.21-22.04,.28-44.08,.4-66.12,0-.62,.06-1.25,.12-2.18Z"/>
    <path onClick={() => props.onFill(16)} fill={props.fillColors[16]} d="M102.19,460.46H42.05c-.09-1.28-.25-2.5-.25-3.72-.02-20.76,.05-41.53-.08-62.29-.02-3.08,.85-3.95,3.94-3.93,17.66,.14,35.33,.07,52.99,.07h3.53v69.87Z"/>
    <path onClick={() => props.onFill(17)} fill={props.fillColors[17]} d="M458.94,463.12v14.5H249.22v-14.5h209.72Z"/>
    <path onClick={() => props.onFill(18)} fill={props.fillColors[18]} d="M41.82,477.56c0-4.38-.08-8.62,.09-12.86,.03-.64,1.15-1.42,1.93-1.8,.61-.29,1.47-.07,2.22-.07,65.44,0,130.88,0,196.32,0h3.74v14.73H41.82Z"/>
    <path onClick={() => props.onFill(19)} fill={props.fillColors[19]} d="M249.3,20.25h208.94v14.54H249.3v-14.54Z"/>
    <path onClick={() => props.onFill(20)} fill={props.fillColors[20]} d="M478.64,324.23V173.8h19.41v150.43h-19.41Z"/>
    <path onClick={() => props.onFill(21)} fill={props.fillColors[21]} d="M22.47,477.74H3.24v-150.56H22.47v150.56Z"/>
    <path onClick={() => props.onFill(22)} fill={props.fillColors[22]} d="M498.16,20.11V170.8h-19.61c0-1.17,0-2.21,0-3.24,.05-47.94,.12-95.87,.07-143.81,0-3.01,.9-3.82,3.79-3.7,5.12,.21,10.26,.06,15.74,.06Z"/>
    <path onClick={() => props.onFill(23)} fill={props.fillColors[23]} d="M42.25,34.74v-14.37H246.14v14.37H42.25Z"/>
    <path onClick={() => props.onFill(24)} fill={props.fillColors[24]} d="M22.83,170.81H3.72V20.34H22.83V170.81Z"/>
    <path onClick={() => props.onFill(25)} fill={props.fillColors[25]} d="M497.84,477.63h-19.28v-150.41h19.28v150.41Z"/>
    <path onClick={() => props.onFill(26)} fill={props.fillColors[26]} d="M22.77,324.21H3.45V173.58c6.17,0,12.12-.07,18.07,.12,.51,.02,1.38,1.67,1.39,2.57,.13,6.17,.08,12.35,.07,18.53-.07,41.74-.14,83.47-.21,125.21,0,1.36,0,2.71,0,4.2Z"/>
    <path onClick={() => props.onFill(27)} fill={props.fillColors[27]} d="M475.83,111.91V247.76c-4,0-7.72,.01-11.44,0-.93,0-1.87-.11-3.01-.19V111.91h14.45Z"/>
    <path onClick={() => props.onFill(28)} fill={props.fillColors[28]} d="M363.84,480.31h111.81v17.15h-111.81v-17.15Z"/>
    <path onClick={() => props.onFill(29)} fill={props.fillColors[29]} d="M249.1,497.43v-17.17c1.16-.06,2.2-.16,3.23-.16,35.03,0,70.06,.03,105.08-.08,3.1-.01,3.82,1.03,3.7,3.84-.19,4.45-.05,8.91-.05,13.57h-111.96Z"/>
    <path onClick={() => props.onFill(30)} fill={props.fillColors[30]} d="M461.78,250.49h13.9v137.48h-13.9V250.49Z"/>
    <path onClick={() => props.onFill(31)} fill={props.fillColors[31]} d="M246.2,480.39v17.05h-108.81v-17.05h108.81Z"/>
    <path onClick={() => props.onFill(32)} fill={props.fillColors[32]} d="M134.33,480.32v17.12H25.41v-17.12h108.92Z"/>
    <path onClick={() => props.onFill(33)} fill={props.fillColors[33]} d="M25.54,250.58h13.58v137.34h-13.58V250.58Z"/>
    <path onClick={() => props.onFill(34)} fill={props.fillColors[34]} d="M39.04,247.49c-.46,.09-.86,.24-1.26,.24-3.95,.02-7.91,.01-12.13,.01V111.93h13.38V247.49Z"/>
    <path onClick={() => props.onFill(35)} fill={props.fillColors[35]} d="M249.11,189.74v-14.91c17.73-.03,33.4,5.48,46.94,16.54,17.86,14.58,26.91,33.58,27.71,56.88h-16.8c-4.02-34.27-22.86-53.99-57.85-58.51Z"/>
    <path onClick={() => props.onFill(36)} fill={props.fillColors[36]} d="M361.22,2.75v14.64h-111.92V2.75h111.92Z"/>
    <path onClick={() => props.onFill(37)} fill={props.fillColors[37]} d="M476.27,17.45h-112.05V2.56c1.26,0,2.4,0,3.55,0,34.92,0,69.83,.03,104.75-.06,2.86,0,4.04,.62,3.81,3.68-.27,3.68-.06,7.4-.06,11.26Z"/>
    <path onClick={() => props.onFill(38)} fill={props.fillColors[38]} d="M246.31,2.76v14.62h-108.81V2.76h108.81Z"/>
    <path onClick={() => props.onFill(39)} fill={props.fillColors[39]} d="M134.61,2.56v14.86H25.59c0-4.5-.05-8.94,.07-13.37,.01-.51,.97-1.17,1.63-1.42,.66-.25,1.48-.07,2.23-.07,33.72,0,67.43,0,101.15,0h3.94Z"/>
    <path onClick={() => props.onFill(40)} fill={props.fillColors[40]} d="M323.39,251.17c-.03,41.23-37.15,73.49-74.16,71.88v-13.29c34.86-4.43,53.7-24.17,57.74-58.59h16.42Z"/>
    <path onClick={() => props.onFill(41)} fill={props.fillColors[41]} d="M246.22,175.21v14.56c-34.53,4.38-53.37,23.99-57.66,58.39h-13.85c-1.77-36.48,31.69-73.32,71.51-72.95Z"/>
    <path onClick={() => props.onFill(42)} fill={props.fillColors[42]} d="M246.26,309.32v14.03c-18.78-.79-34.89-7.38-48.37-20-15-14.03-22.78-31.41-23.62-52.16h14.68c.7,15.51,6.11,29.09,16.95,40.24,10.88,11.2,24.34,17.01,40.36,17.88Z"/>
    <path onClick={() => props.onFill(43)} fill={props.fillColors[43]} d="M249.82,306.4c5.32-24.34,22.89-44.22,53.8-54.4,.62,27.94-24.8,54.59-53.8,54.4Z"/>
    <path onClick={() => props.onFill(44)} fill={props.fillColors[44]} d="M303.72,247.49c-29.69-9.31-48.91-29.65-53.79-54.34,28.16-.35,53.9,25.47,53.79,54.34Z"/>
    <path onClick={() => props.onFill(45)} fill={props.fillColors[45]} d="M245.9,306.32c-26.61,.65-53.81-23.62-54.05-54.34,12.59,4.38,24.25,10.01,33.88,19.2,10.12,9.65,16.83,21.27,20.17,35.15Z"/>
    <path onClick={() => props.onFill(46)} fill={props.fillColors[46]} d="M191.9,247.51c-.23-27.55,24.08-54.32,53.51-54.41,1.05,18.72-25.63,45.93-53.51,54.41Z"/>
    <path onClick={() => props.onFill(47)} fill={props.fillColors[47]} d="M461.38,37.69h14.65V108.95h-14.65V37.69Z"/>
    <path onClick={() => props.onFill(48)} fill={props.fillColors[48]} d="M39.39,108.98h-13.91c0-1.08,0-2.1,0-3.11,.03-21.73,.1-43.46,.02-65.18-.01-2.72,.85-3.55,3.47-3.35,2.56,.2,5.15,.19,7.7,0,2.49-.18,3.08,.86,3.05,3.15-.18,15.23-.26,30.46-.33,45.69-.04,7.53,0,15.07,0,22.8Z"/>
    <path onClick={() => props.onFill(49)} fill={props.fillColors[49]} d="M461.93,390.82h13.64v69.54h-13.64v-69.54Z"/>
    <path onClick={() => props.onFill(50)} fill={props.fillColors[50]} d="M25.52,390.77h13.54v69.53h-13.54v-69.53Z"/>
    <path onClick={() => props.onFill(51)} fill={props.fillColors[51]} d="M268.94,268.72c-6.68-6.8-13.09-13.33-19.12-19.48,5.83-6.16,11.9-12.55,18.18-19.18,9.66,9.28,21.19,14.73,34.05,19.66-12.35,4.97-23.75,9.98-33.11,18.99Z"/>
    <path onClick={() => props.onFill(52)} fill={props.fillColors[52]} d="M228.19,270.12c6.23-6.59,12.22-12.94,18.12-19.18,6.78,6.95,13.22,13.54,19.81,20.31-8.04,8.38-14.71,18.74-18.29,31.18-3.97-12.55-10.43-23.47-19.64-32.31Z"/>
    <path onClick={() => props.onFill(53)} fill={props.fillColors[53]} d="M193.85,249.61c12.24-4.74,23.44-9.93,32.69-18.65,6.03,6.28,11.91,12.4,17.55,18.27-5.53,5.81-11.61,12.2-17.57,18.46-5.46-3.57-10.35-7.17-15.61-10.09-5.27-2.92-10.91-5.15-17.05-7.99Z"/>
    <path onClick={() => props.onFill(54)} fill={props.fillColors[54]} d="M247.79,197.21c3.59,11.92,9.83,22.16,18.11,30.81-6.21,6.44-12.33,12.79-18.51,19.19-6.13-6.35-12.02-12.45-18.13-18.78,8.3-8.45,14.76-18.92,18.53-31.23Z"/>
    <path onClick={() => props.onFill(55)} fill={props.fillColors[55]} d="M247.93,375.56c4.52,4.52,9.18,9.18,13.59,13.6-4.59,4.59-9.29,9.3-13.8,13.81-4.4-4.38-9.13-9.09-13.71-13.64,4.44-4.39,9.12-9.02,13.91-13.76Z"/>
    <path onClick={() => props.onFill(56)} fill={props.fillColors[56]} d="M261.7,110.64c-4.6,4.59-9.26,9.23-13.69,13.65-4.61-4.58-9.33-9.28-14.23-14.15,4.6-4.29,9.42-8.77,14.12-13.16,4.35,4.31,9.08,8.99,13.8,13.66Z"/>
    <path onClick={() => props.onFill(57)} fill={props.fillColors[57]} d="M408.52,249.59c-4.92,4.74-9.65,9.3-14.36,13.84-4.32-4.31-9-8.96-13.6-13.55,4.67-4.51,9.47-9.14,14.09-13.6,4.26,4.09,9,8.64,13.87,13.31Z"/>
    <path onClick={() => props.onFill(58)} fill={props.fillColors[58]} d="M104.04,235.81c4.18,4,8.93,8.56,13.47,12.91-4.76,4.73-9.41,9.37-13.76,13.69l-13.44-13.25c4.29-4.17,9.05-8.8,13.73-13.35Z"/>
    <path onClick={() => props.onFill(59)} fill={props.fillColors[59]} d="M22.56,480.29v17.14H3.16v-17.14H22.56Z"/>
    <path onClick={() => props.onFill(60)} fill={props.fillColors[60]} d="M497.82,497.5h-19.24v-17.37c5.99,0,11.94-.04,17.88,.08,.46,0,1.28,1.1,1.3,1.71,.1,5.09,.06,10.19,.06,15.59Z"/>
    <path onClick={() => props.onFill(61)} fill={props.fillColors[61]} d="M478.71,17.35c0-4.47-.07-8.7,.07-12.93,.02-.63,1.01-1.75,1.58-1.76,5.85-.13,11.7-.08,17.77-.08v14.77h-19.41Z"/>
    <path onClick={() => props.onFill(62)} fill={props.fillColors[62]} d="M23.14,17.48H3.71V2.56c4.17,0,8.39,0,12.6,0q6.83,0,6.83,6.91v8.01Z"/>
    <path onClick={() => props.onFill(63)} fill={props.fillColors[63]} d="M461.2,34.81v-14.39h14.84v14.39h-14.84Z"/>
    <path onClick={() => props.onFill(64)} fill={props.fillColors[64]} d="M461.93,462.98h13.62v14.68h-13.62v-14.68Z"/>
    <path onClick={() => props.onFill(65)} fill={props.fillColors[65]} d="M25.44,477.62v-14.58h13.67v14.58h-13.67Z"/>
    <path onClick={() => props.onFill(66)} fill={props.fillColors[66]} d="M25.78,34.68v-14.52c4.15,0,8.26-.06,12.37,.07,.52,.02,1.42,1.01,1.44,1.58,.12,4.22,.07,8.45,.07,12.87h-13.87Z"/>

    </g>
            </svg>
        </div>
    )
}