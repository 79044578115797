import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import { glassData } from "./data";
import Button from "@material-ui/core/Button";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export default function SelectedColors(props) {
  const [openModal, setOpenModal] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)"); // Adjust breakpoint as needed

  const onClickButton = (e) => {
    e.preventDefault();
    setOpenModal(true);
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const getFillId = (id) => {
    return id === "url(#00_bevel)" ? "url(#bevels)" : id;
  };

  const selectedColorData = glassData.filter(
    (glassData) => glassData.id === props.currentColor
  );

  if (isMobile) {
    const fillId = getFillId(props.currentColor);
    return (
      <div className="SelectedColor">
        <Grid container spacing={2}>
          <Grid item>
            <ButtonBase>
              <svg width="50" height="50">
                {selectedColorData.length > 0 ? (
                  <rect width="50" height="50" fill={fillId} />
                ) : (
                  <rect
                    width="50"
                    height="50"
                    fill="white"
                    stroke="gray"
                    strokeWidth="5"
                  />
                )}
              </svg>
            </ButtonBase>

          </Grid>
          <span className="selected-color">Selected<br></br>Color</span>
        </Grid>
      </div>
    );
  }

  // Desktop version
  return (
    <>
      <h2 className="coloring-subtitle">Current Selected Color</h2>
      <div className="SelectedColor">
        {selectedColorData.length > 0 ? (
          selectedColorData.map((glassData) => {
            const fillId = getFillId(props.currentColor);
            return (
              <div key={glassData.id}>
                <Grid container spacing={2}>
                  <Grid item>
                    <ButtonBase>
                      <svg width="100" height="100">
                        <rect
                          width="100"
                          height="100"
                          fill={fillId}
                        ></rect>
                      </svg>
                    </ButtonBase>
                  </Grid>
                  <Grid item xs={12} sm container>
                    <Grid item xs container direction="column" spacing={2}>
                      <Grid item xs>
                        <Typography gutterBottom variant="subtitle1">
                          {glassData.color}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {glassData.type}
                        </Typography>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={onClickButton}
                        >
                          Learn More
                        </Button>
                        <Modal open={openModal} onClose={onCloseModal}>
                          <h1>{glassData.color}</h1>
                          <h2>{glassData.type}</h2>
                          <p>{glassData.description}</p>
                          <div>
                            <svg width="1080" height="1080">
                              <rect
                                width="1080"
                                height="1080"
                                fill={fillId}
                              ></rect>
                            </svg>
                          </div>
                        </Modal>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            );
          })
        ) : (
          <div>
            <Grid container spacing={2}>
              <Grid item>
                <ButtonBase>
                  <svg width="100" height="100">
                    <rect
                      width="100"
                      height="100"
                      fill="white"
                      stroke="gray"
                      strokeWidth="5"
                    />
                  </svg>
                </ButtonBase>
              </Grid>
              <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>
                    <Typography gutterBottom variant="subtitle1">
                      White / Transparent
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    </>
  );
}