import React from 'react';


export default function Hermosaoctagon(props) {
    
    return (
        <div className={`hermosaoctagon-container ${props.size}`}> 
 
            <svg  xmlns="http://www.w3.org/2000/svg" id={props.id} width="500" height="500" viewBox="0 0 500 500">

            <pattern id="01_yellow" width="100%" height="100%">
                      <image xlinkHref="/01_yellow.jpg"/>
              </pattern>
          <pattern id="02_lightAmber" width="100%" height="100%">
              <image xlinkHref="/02_lightAmber.jpg"/>
          </pattern>
          <pattern id="03_darkAmber" width="100%" height="100%">
              <image xlinkHref="/03_darkAmber.jpg"/>
          </pattern>
          <pattern id="04_cherry" width="100%" height="100%">
              <image xlinkHref="/04_cherry.jpg"/>
          </pattern>
          <pattern id="05_redAmber" width="100%" height="100%">
              <image xlinkHref="/05_redAmber.jpg"/>
          </pattern>    
          <pattern id="06_orange" width="100%" height="100%">
              <image xlinkHref="/06_orange.jpg"/>
          </pattern>
          <pattern id="07_green" width="100%" height="100%">
              <image xlinkHref="/07_green.jpg"/>
          </pattern>
          <pattern id="08_olive" width="100%" height="100%">
              <image xlinkHref="/08_olive.jpg"/>
          </pattern>
          <pattern id="09_purple" width="100%" height="100%">
              <image xlinkHref="/09_purple.jpg"/>
          </pattern>
          <pattern id="10_lightBlue" width="100%" height="100%">
              <image xlinkHref="/10_lightBlue.jpg"/>
          </pattern>  
          <pattern id="11_aquaBlue" width="100%" height="100%">
              <image xlinkHref="/11_aquaBlue.jpg"/>
          </pattern>
          <pattern id="12_oceanBlue" width="100%" height="100%">
              <image xlinkHref="/12_oceanBlue.jpg"/>
          </pattern>
          <pattern id="13_cobaltBlue" width="100%" height="100%">
              <image xlinkHref="/13_cobaltBlue.jpg"/>
          </pattern>
          <pattern id="14_tealBlue" width="100%" height="100%">
              <image xlinkHref="/14_tealBlue.jpg"/>
          </pattern>
          <pattern id="15_clear" width="100%" height="100%">
              <image xlinkHref="/15_clear.jpg"/>
          </pattern>  
          <pattern id="16_granite" width="100%" height="100%">
              <image xlinkHref="/16_granite.jpg"/>
          </pattern>
          <pattern id="17_crackle" width="100%" height="100%">
              <image xlinkHref="/17_crackle.jpg"/>
          </pattern>
          <pattern id="18_iridescent" width="100%" height="100%">
              <image xlinkHref="/18_iridescent.jpg"/>
          </pattern>
          <pattern id="19_taffy" width="100%" height="100%">
              <image xlinkHref="/19_taffy.jpg"/>
          </pattern>
          <pattern id="20_seedy" width="100%" height="100%">
              <image xlinkHref="/20_seedy.jpg"/>
          </pattern> 
          <pattern id="21_frosted" width="100%" height="100%">
              <image xlinkHref="/21_frosted.jpg"/>
          </pattern>                        

            <g className="hermosaoctagon-image" >

 <path id="background" fill="black" d="M500,146.6V353.9l-.8.5-144,143.8-1.5,1.8H146.2c-.7-.8-1.3-1.6-2-2.3L2.3,355.9A6.9,6.9,0,0,1,0,350.5Q.2,250,0,149.6a4.8,4.8,0,0,0,0-1.2,4.1,4.1,0,0,1,1.4-3.2L67.2,79.3,143.9,2.7c.8-.8,1.5-1.8,2.3-2.7H353.7l1.7,2,82,82Q468.6,115.4,500,146.6Z" />
<path onClick={() => props.onFill(0)} fill={props.fillColors[0]} d="M251.1,468.1V391.9c19.4-.6,36.3-7.3,50.1-20.8s21-30.3,21.7-49.8c19.3-.8,36-7.5,49.7-20.9s20.8-30,21.5-49.2c1.7-.5,70.9-.7,74.2-.2,0,.6.1,1.3.1,2v85.4a3.6,3.6,0,0,1-1.3,3.1,4.6,4.6,0,0,1-.7.7L342.6,466.1a6,6,0,0,1-5,2H251.1Z" />
<path onClick={() => props.onFill(1)} fill={props.fillColors[1]} d="M31.7,250.8h74c.7,19.4,7.7,36,21.5,49.6s30.4,20.1,49.7,20.9c.8,19.5,7.9,36.3,21.9,49.9s30.8,20.1,50.1,20.7v76l-2.1.2H161.2c-1.6.1-2.5-.7-3.5-1.6q-42.9-43-85.9-85.9-19.2-19.4-38.5-38.5a5.4,5.4,0,0,1-1.7-4.2q.1-42,.1-84.2Z" />
<path onClick={() => props.onFill(2)} fill={props.fillColors[2]} d="M468.4,248.7H394.2c-.8-19.4-7.7-36-21.5-49.6s-30.5-20.1-49.8-20.9c-.4-20-6.9-37.5-20.7-52s-30.9-21.9-51.1-22.7v-72h87.7a4.9,4.9,0,0,1,3.5,1.7l88.9,89,35.5,35.4a5.7,5.7,0,0,1,1.8,4.4q-.2,41.8-.1,83.7Z" />
<path onClick={() => props.onFill(3)} fill={props.fillColors[3]} d="M248.8,31.4v72.1c-20.1.8-37.2,8.1-51,22.6s-20.4,32-20.9,52.1c-19.2.8-36,7.5-49.7,21s-20.7,30-21.5,49.2c-1.9.5-68.9.7-73.8.1a9,9,0,0,1-.2-1.8c-.1-28.6,0-57.2-.1-85.8a4.6,4.6,0,0,1,1.7-3.5l48.1-48.1,76-76.1a6.2,6.2,0,0,1,4.6-1.9h86.8Z" />
<path onClick={() => props.onFill(4)} fill={props.fillColors[4]} d="M351.6,497.5H148.8c.1-1.7,9.1-24,11-27.3H340.4C344.1,479.1,347.8,488.2,351.6,497.5Z" />
<path onClick={() => props.onFill(5)} fill={props.fillColors[5]} d="M470,342.1l26.4,11.2L353.6,496.2c-.9-1-10-22.6-11.1-26.6Q406.1,405.9,470,342.1Z" />
<path onClick={() => props.onFill(6)} fill={props.fillColors[6]} d="M496.4,146.6l-26.3,10.7L342.4,29.6c3.5-8.4,7.3-17.3,10.9-26.1Z" />
<path onClick={() => props.onFill(7)} fill={props.fillColors[7]} d="M29.5,340.2c-9,3.7-17.8,7.5-27,11.4V148.8c1.7.1,21.2,7.7,27,10.6Z" />
<path onClick={() => props.onFill(8)} fill={props.fillColors[8]} d="M3.1,353.5l26.8-11.4L157.6,469.7l-10.9,26.5h-.6l-1.1-.9Z" />
<path onClick={() => props.onFill(9)} fill={props.fillColors[9]} d="M470.5,159.3l27-11V351.6l-27-11.4Z" />
<path onClick={() => props.onFill(10)} fill={props.fillColors[10]} d="M340.2,29.4H159.6L148.3,2.5H351.1C351.1,4.2,342.6,24.8,340.2,29.4Z" />
<path onClick={() => props.onFill(11)} fill={props.fillColors[11]} d="M30,157.4,3.8,146.7,146.7,3.9l10.9,25.9Z" />
<path onClick={() => props.onFill(12)} fill={props.fillColors[12]} d="M179.2,178.2c-1-39.8,33-73.1,69.5-72.3,0,.2.2.4.2.6-.4,12.6-1.1,25.2-5.6,37.3a106.7,106.7,0,0,1-11.9,23.6,41,41,0,0,1-18.6,15c-3.2,1.3-5.9,1.6-9.2.3a63.7,63.7,0,0,0-22.8-4.4Z" />
<path onClick={() => props.onFill(13)} fill={props.fillColors[13]} d="M320.8,178c-4.4.5-8.5.8-12.6,1.4a64.2,64.2,0,0,0-14.4,4.1,4.1,4.1,0,0,1-3.9,0c-1.6-.8-3.3-1.4-5-2.1-7.9-3.4-13.6-9.3-18.1-16.4a102,102,0,0,1-13-30.8,91.2,91.2,0,0,1-2.3-17.2c-.2-3.7-.3-7.3-.4-11.1,2.1-.6,4.3-.2,6.3.1,32.6,3.7,57.6,28.8,62.6,60.9.5,3.3.6,6.6.9,10C320.9,177.2,320.8,177.6,320.8,178Z" />
<path onClick={() => props.onFill(14)} fill={props.fillColors[14]} d="M248.8,389.7c-38.3,0-69.7-32-69.6-68.3l2.5-.2A72.4,72.4,0,0,0,206,316a4.1,4.1,0,0,1,3.9,0l4.8,2c8,3.4,13.8,9.3,18.3,16.5,7.2,11.6,12.4,24,14.4,37.6.8,5.5,1,11,1.5,16.6A1.8,1.8,0,0,1,248.8,389.7Z" />
<path onClick={() => props.onFill(15)} fill={props.fillColors[15]} d="M251.1,389.7c.3-4.5.5-8.9.9-13.2,1.3-15.1,6.8-28.7,14.5-41.4a49.8,49.8,0,0,1,8.4-10.4c3.6-3.4,7.9-5.5,12.3-7.6s5.7-1.7,9-.3a63.5,63.5,0,0,0,22.1,4.4l2.2.2C321.3,355.9,290.7,389.6,251.1,389.7Z" />
<path onClick={() => props.onFill(16)} fill={props.fillColors[16]} d="M107.9,248.5c.1-37.1,32.1-68,69-68.1.1.6.3,1.2.4,1.8a81.6,81.6,0,0,0,5.4,25,3.1,3.1,0,0,1,.1,2.3c-2.4,6-5.1,12-10,16.6a54.1,54.1,0,0,1-10.6,7.9c-10,5.6-20.4,10.1-31.6,12.3-7.1,1.5-14.4,1.9-21.6,2.3Z" />
<path onClick={() => props.onFill(17)} fill={props.fillColors[17]} d="M323.4,319.3c-1-5.7-1.7-11.1-2.8-16.3a71.8,71.8,0,0,0-3.4-10.2,4.1,4.1,0,0,1,.1-3.6c.8-1.7,1.5-3.5,2.3-5.2,3.6-7.9,9.6-13.4,16.9-17.7,12.4-7.3,25.6-12.6,40.1-14.2,5-.6,10.1-.9,15.1-1.3,2.4,9-5.6,31.2-15.6,43C362.2,310.1,344.5,318.3,323.4,319.3Z" />
<path onClick={() => props.onFill(18)} fill={props.fillColors[18]} d="M323,180.4c36.5.2,68.7,30.5,69,68l-1.6.2a104.5,104.5,0,0,1-48.9-12.5c-5.6-3-10.9-6.3-15.3-10.9s-5.4-7.4-7.4-11.5a11,11,0,0,1-.3-10.3c2.9-6.9,3.9-14.2,4.3-21.7A6.9,6.9,0,0,1,323,180.4Z" />
<path onClick={() => props.onFill(19)} fill={props.fillColors[19]} d="M107.9,251h1.5a198.5,198.5,0,0,1,20.4,2.1,105,105,0,0,1,25.6,8.9,75.7,75.7,0,0,1,15.3,9.6,37.3,37.3,0,0,1,10.2,14c.7,1.7,2,3.4,2.1,5.2s-1.1,3.6-1.8,5.4a72.6,72.6,0,0,0-4.2,21.2,8.6,8.6,0,0,1-.1,1.6C145.1,320,108.4,292.4,107.9,251Z" />
<path onClick={() => props.onFill(20)} fill={props.fillColors[20]} d="M377.5,249.8l-6.7,1.1a102.7,102.7,0,0,0-40.5,17.2A38.5,38.5,0,0,0,317,284.9l-1.6,3.6c-10.9-20.4-27.3-33.1-49.4-38.7a77.2,77.2,0,0,0,29-13.9,74.2,74.2,0,0,0,20.4-25,10.2,10.2,0,0,1,1,2.2c3.5,9.6,10.1,16.6,18.8,21.7,12.9,7.6,26.6,13.1,41.7,14.7Z" />
<path onClick={() => props.onFill(21)} fill={props.fillColors[21]} d="M184.4,211.1c10.9,20.4,27.3,33.1,49.9,38.7-22.5,5.6-39,18.3-49.9,38.6-.4-.9-.7-1.4-.9-2-3.7-9.8-10.5-16.9-19.5-22.1-12.2-7-25.1-12.3-39.2-14.1a5.4,5.4,0,0,1-2.1-.5l6-1c13.8-2.4,26.3-7.8,38.1-15.2a40.8,40.8,0,0,0,16.4-19.6Z" />
<path onClick={() => props.onFill(22)} fill={props.fillColors[22]} d="M210.6,314.1c20.3-10.4,33.4-26.3,39.2-48.7,1.3,3.8,2.3,7.2,3.6,10.4a71,71,0,0,0,4.7,9.6,76.6,76.6,0,0,0,6.1,8.8,64.7,64.7,0,0,0,7.2,7.6,77.1,77.1,0,0,0,8.4,6.7c2.9,2,6.1,3.7,9.3,5.7l-4.9,2.1c-8.4,3.5-14.4,9.7-19.1,17.3A102.6,102.6,0,0,0,252,364.2c-.7,3-1.1,6.1-1.6,9.1a2.7,2.7,0,0,1-.5,1.1l-.4-1c-1.3-11.4-5.1-21.9-10.3-32a85.5,85.5,0,0,0-8.2-13.2,40.2,40.2,0,0,0-16.6-12.5Z" />
<path onClick={() => props.onFill(23)} fill={props.fillColors[23]} d="M249.9,233.7a75.6,75.6,0,0,0-14.2-28.3,72.3,72.3,0,0,0-25.1-19.9l2.2-1c9.6-3.5,16.6-9.9,21.9-18.4A106.1,106.1,0,0,0,248,134.7c.7-2.7,1-5.5,1.5-8.2a2,2,0,0,1,.6-1.1c.3,1.8.7,3.6,1,5.5a94,94,0,0,0,9.5,27.1,74.9,74.9,0,0,0,8,12.9A40,40,0,0,0,285.9,184l2.6,1.1c.1,0,.2.2.4.4C268.9,195.9,255.8,211.8,249.9,233.7Z" />
<path onClick={() => props.onFill(24)} fill={props.fillColors[24]} d="M260.3,248.6c-1-5.1-4.1-7.9-8.9-9,3.4-32.4,35.2-60.1,69.4-59.1-.5,17.5-6.5,32.7-18.2,45.6S277.1,246,260.3,248.6Z" />
<path onClick={() => props.onFill(25)} fill={props.fillColors[25]} d="M179.1,180.4c36-.5,66.2,28.3,69.3,59.2-4.8,1-7.8,4-8.9,9a69.8,69.8,0,0,1-39.8-19.9C186.3,215.5,180,199.2,179.1,180.4Z" />
<path onClick={() => props.onFill(26)} fill={props.fillColors[26]} d="M179.1,319.3c.1-32.8,25.2-63.3,60.4-68.2,1,4.8,4.1,7.8,9.2,9a67.7,67.7,0,0,1-22.1,40.8C213.1,312.9,197.3,318.9,179.1,319.3Z" />
<path onClick={() => props.onFill(27)} fill={props.fillColors[27]} d="M251.1,260.1c5-1.2,8.2-3.9,9.1-8.8,30.7,2.9,60.4,31.7,60.5,67.6-8.2,1.3-28.3-2.8-43.1-14.4A69.1,69.1,0,0,1,251.1,260.1Z" />
<path onClick={() => props.onFill(28)} fill={props.fillColors[28]} d="M249.8,241.6a8.2,8.2,0,1,1-8.2,8.4A8.1,8.1,0,0,1,249.8,241.6Z" />  
                
    </g>
            </svg>
        </div>
    )
}