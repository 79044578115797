import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
// Import useHistory if needed elsewhere, but not strictly needed for NavLinks/logout shown
// import { useHistory } from "react-router-dom";
import CollectionsIcon from '@mui/icons-material/Collections';
import LogoutIcon from '@mui/icons-material/Logout';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'; // Optional: Icon for admin section
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary'; // Optional: Icon
import ListAltIcon from '@mui/icons-material/ListAlt'; // Optional: Icon
// Nav from react-bootstrap seems unused? Commenting out unless needed.
// import { Nav } from 'react-bootstrap';


// Accept isAdmin as a prop
export default function NavBar({ isAdmin }) {
    const { token } = useSelector(state => state.auth); // Check token for basic logged-in status
    const [mobileActive, setMobileActive] = useState(false);
    const activeClass = mobileActive ? "active" : ""


    const toggleActive = () => {
        setMobileActive(!mobileActive)
    }

    return (
        <>
            <img src="/white-hamburger-menu.png" alt="mobile menu" id="hamburger-menu" onClick={toggleActive} />
            {(!token) ?
                (
                    // --- Logged Out View ---
                    <ul className={`nav-bar ${activeClass}`}>

                        <li>
                            {/* Using <a> for external link */}
                            <a href="https://www.stainedglasswindows.com/" onClick={toggleActive}><span id="instagram">Home</span></a>
                        </li>
                        <li>
                            <NavLink onClick={toggleActive} className="nav-bar-link" to="/images">
                                Designs
                            </NavLink>
                        </li>
                        <li className='login-mobile'>
                            <NavLink onClick={toggleActive} className="nav-bar-link" to="/login">Login</NavLink>
                            {/* Typo Correction: Changed "/singup" to "/signup" */}
                            <NavLink onClick={toggleActive} className="nav-bar-link" to="/signup">Create Account</NavLink>
                        </li>
                    </ul>
                )
                :
                (
                    // --- Logged In View ---
                    <ul className={`nav-bar ${activeClass}`}>
                        <li>
                            <a href="https://www.stainedglasswindows.com/" onClick={toggleActive}><span id="instagram">Home</span></a>
                        </li>
                        <li>
                            <NavLink onClick={toggleActive} className="nav-bar-link" to="/mygallery"><CollectionsIcon /> My Saved Designs</NavLink>
                        </li>
                        <li>
                            <NavLink onClick={toggleActive} className="nav-bar-link" to="/images">Designs</NavLink>
                        </li>

                        {/* --- Admin Links Section --- */}
                        {isAdmin && ( // Only render this block if isAdmin prop is true
                            <>
                                <hr className="nav-divider" /> {/* Optional divider */}
                                <li>
                                    <NavLink onClick={toggleActive} className="nav-bar-link admin-link" to="/admin/images/edit">
                                        <AdminPanelSettingsIcon fontSize="small" /> Edit Designs
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink onClick={toggleActive} className="nav-bar-link admin-link" to="/all-gallery">
                                        <PhotoLibraryIcon fontSize="small" /> All User Galleries
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink onClick={toggleActive} className="nav-bar-link admin-link" to="/all-orders">
                                        <ListAltIcon fontSize="small" /> All Orders
                                    </NavLink>
                                </li>
                                <hr className="nav-divider" /> {/* Optional divider */}
                            </>
                        )}
                        {/* --- End Admin Links Section --- */}

                        <li className='logout-mobile'>
                            <NavLink onClick={toggleActive} className="nav-bar-link" to="/logout"><LogoutIcon /> Log Out</NavLink>
                        </li>
                    </ul>
                )}
            {/* Commented out Cart/Orders links - uncomment if needed */}
            {/* <li>
              <NavLink onClick={toggleActive} className="nav-bar-link" to="/cart">My Cart</NavLink>
            </li>
            <li>
              <NavLink onClick={toggleActive} className="nav-bar-link" to="/orders">My Orders</NavLink>
            </li> */}

        </>
    )
};

// Removed withRouter wrapper as it wasn't explicitly used in the component logic shown.
// Add it back if necessary: export default withRouter(NavBar);