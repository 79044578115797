// AllImagesCard.js
import React from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import ColoredInImage from '../Images/ColoredInImage';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Fab from '@mui/material/Fab';
import swal from 'sweetalert';

function AllImagesCard(props) {
  const { userImage, imageId, user, imageComponent } = props; // Destructure props
  const history = useHistory();
  const { token } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  if (!userImage) {
    return <p>...</p>; // Handle missing data
  }

  const showFeatured = () => {
        history.push(`/featuredimage/${userImage.id}`)
    }

  if (!imageComponent) { // Check for missing component
    console.error(`Image component not found for imageId: ${imageId}`);
    return <div></div>;
  }

  return (
    <Card className="mycard" sx={{ maxWidth: 500 }}>
      {/* Correctly use ColoredInImage */}
      <ColoredInImage
        className="new-image"
        component={imageComponent} // Pass the component NAME (string)
        fillColors={userImage.fill_colors ?? []}
        onFill={() => {}}
        id={userImage.id}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Image ID: {userImage.id}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          User: {user?.username ?? 'Unknown User'}
          <br />
          Width: {userImage.glass_width ?? 'N/A'}
          <br />
          Height: {userImage.glass_height ?? 'N/A'}
        </Typography>
      </CardContent>
      <CardActions>
      </CardActions>
    </Card>
  );
}

export default AllImagesCard;