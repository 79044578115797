import React from 'react';


export default function Put01(props) {
    
    return (
        <div className={`Put01-container ${props.size}`}> 
 
            <svg  xmlns="http://www.w3.org/2000/svg" id={props.id} width="500" height="500" viewBox="0 0 500 500">
                
            <pattern id="01_yellow" width="100%" height="100%">
                      <image xlinkHref="/01_yellow.jpg"/>
              </pattern>
          <pattern id="02_lightAmber" width="100%" height="100%">
              <image xlinkHref="/02_lightAmber.jpg"/>
          </pattern>
          <pattern id="03_darkAmber" width="100%" height="100%">
              <image xlinkHref="/03_darkAmber.jpg"/>
          </pattern>
          <pattern id="04_cherry" width="100%" height="100%">
              <image xlinkHref="/04_cherry.jpg"/>
          </pattern>
          <pattern id="05_redAmber" width="100%" height="100%">
              <image xlinkHref="/05_redAmber.jpg"/>
          </pattern>    
          <pattern id="06_orange" width="100%" height="100%">
              <image xlinkHref="/06_orange.jpg"/>
          </pattern>
          <pattern id="07_green" width="100%" height="100%">
              <image xlinkHref="/07_green.jpg"/>
          </pattern>
          <pattern id="08_olive" width="100%" height="100%">
              <image xlinkHref="/08_olive.jpg"/>
          </pattern>
          <pattern id="09_purple" width="100%" height="100%">
              <image xlinkHref="/09_purple.jpg"/>
          </pattern>
          <pattern id="10_lightBlue" width="100%" height="100%">
              <image xlinkHref="/10_lightBlue.jpg"/>
          </pattern>  
          <pattern id="11_aquaBlue" width="100%" height="100%">
              <image xlinkHref="/11_aquaBlue.jpg"/>
          </pattern>
          <pattern id="12_oceanBlue" width="100%" height="100%">
              <image xlinkHref="/12_oceanBlue.jpg"/>
          </pattern>
          <pattern id="13_cobaltBlue" width="100%" height="100%">
              <image xlinkHref="/13_cobaltBlue.jpg"/>
          </pattern>
          <pattern id="14_tealBlue" width="100%" height="100%">
              <image xlinkHref="/14_tealBlue.jpg"/>
          </pattern>
          <pattern id="15_clear" width="100%" height="100%">
              <image xlinkHref="/15_clear.jpg"/>
          </pattern>  
          <pattern id="16_granite" width="100%" height="100%">
              <image xlinkHref="/16_granite.jpg"/>
          </pattern>
          <pattern id="17_crackle" width="100%" height="100%">
              <image xlinkHref="/17_crackle.jpg"/>
          </pattern>
          <pattern id="18_iridescent" width="100%" height="100%">
              <image xlinkHref="/18_iridescent.jpg"/>
          </pattern>
          <pattern id="19_taffy" width="100%" height="100%">
              <image xlinkHref="/19_taffy.jpg"/>
          </pattern>
          <pattern id="20_seedy" width="100%" height="100%">
              <image xlinkHref="/20_seedy.jpg"/>
          </pattern> 
          <pattern id="21_frosted" width="100%" height="100%">
              <image xlinkHref="/21_frosted.jpg"/>
          </pattern>                                      
            <g className="hummingbird-image" >

<path id="background" fill="black"  d="m0,500V0h752v752H0Z"/>

<path onClick={() => props.onFill(1)} fill={props.fillColors[1]} d="M363.39,231.59H186.51c-.49-4.48-1.23-9.02-1.45-13.57-2.3-48.02,26.19-92.04,71.2-108.93,36.9-13.85,72.13-9.63,105.27,11.8,1.58,1.02,2.1,2.16,2.1,3.99-.05,34.68-.03,69.35-.04,104.03,0,.84-.12,1.68-.2,2.68Z"/>
  <path onClick={() => props.onFill(2)} fill={props.fillColors[2]} d="M160.37,476.17v-187.48c1.05-.05,1.98-.14,2.91-.14,15.31,0,30.62,.03,45.93-.05,1.93-.01,3.2,.64,4.48,1.98,15.21,15.93,33.6,26.45,54.87,31.93,2.22,.57,2.87,1.42,2.87,3.66-.04,49.02,0,98.04,.03,147.06,0,.94,0,1.88,0,3.05h-111.09Z"/>
  <path onClick={() => props.onFill(3)} fill={props.fillColors[3]} d="M395.11,231.25V24.53h80.74V231.25h-80.74Z"/>
  <path onClick={() => props.onFill(4)} fill={props.fillColors[4]} d="M274.44,323.87c14.2,2.73,27.96,3,41.79,.84,.81-.13,2.18,.89,2.69,1.74,4.7,7.69,10.31,14.62,17.07,20.6,14.39,12.74,31.05,20.45,50.23,22.41,4.95,.5,9.95,.57,15.14,.85v105.95c-1.21,.06-2.24,.15-3.27,.15-40.05,0-80.09-.02-120.14,.07-2.86,0-3.75-.66-3.74-3.65,.09-48.53,.07-97.06,.07-145.59,0-1.04,.09-2.07,.15-3.36Z"/>
  <path onClick={() => props.onFill(5)} fill={props.fillColors[5]} d="M24.12,231.53V92.98h3.32c36.3,0,72.6,0,108.9,0,3.72,0,3.73,0,3.74,3.81,0,43.72,0,87.44,0,131.16v3.58H24.12Z"/>
  <path onClick={() => props.onFill(6)} fill={props.fillColors[6]} d="M256.8,24.5v3.75c0,24.65-.04,49.29,.06,73.94,.01,2.63-.78,3.77-3.26,4.79-25.96,10.71-45.7,28.39-58.85,53.28-.72,1.36-1.34,2.32-3.16,2.31-15.59-.07-31.19-.04-46.78-.06-.47,0-.94-.12-1.62-.22V24.5h113.62Z"/>
  <path onClick={() => props.onFill(7)} fill={props.fillColors[7]} d="M127.42,369.25H24.07c-.05-1.09-.15-2.1-.15-3.12,0-33.41,0-66.82-.04-100.23,0-2.11,.13-3.31,2.85-3.3,32.82,.09,65.65,.06,98.47,.07,.66,0,1.32,.08,2.21,.14v106.44Z"/>
  <path onClick={() => props.onFill(8)} fill={props.fillColors[8]} d="M24.1,476.22v-103.79H127.44v103.79H24.1Z"/>
  <path onClick={() => props.onFill(9)} fill={props.fillColors[9]} d="M475.89,321.61v154.49h-71.47c0-1.31,0-2.53,0-3.76,.03-33.04,.08-66.08,.02-99.12,0-2.7,.55-3.57,3.49-4.02,29.11-4.41,51.2-19.38,66.18-44.77,.46-.78,.97-1.55,1.77-2.83Z"/>
  <path onClick={() => props.onFill(10)} fill={props.fillColors[10]} d="M321.16,324.31c7.5-2.55,14.9-4.68,21.99-7.56,13.89-5.64,26.15-13.89,36.6-24.67,2.43-2.51,4.82-3.66,8.46-3.63,28.02,.18,56.05,.1,84.07,.1h3.76c0,5.23-.46,10.26,.1,15.17,.98,8.6-1.95,15.85-6.55,22.71-19.35,28.83-46.44,42.91-81.01,40.29-28.63-2.16-50.78-16.44-66.42-40.62-.41-.64-.74-1.33-1-1.79Z"/>
  <path onClick={() => props.onFill(11)} fill={props.fillColors[11]} d="M259.77,24.5h103.61V118.45c-32.4-20.75-66.89-25.43-103.61-13.98V24.5Z"/>
  <path onClick={() => props.onFill(12)} fill={props.fillColors[12]} d="M140.07,24.54V89.89H24.12V24.54h115.95Z"/>
  <path onClick={() => props.onFill(13)} fill={props.fillColors[13]} d="M363.43,234.86v24.84H130.76v-24.84h232.67Z"/>
  <path onClick={() => props.onFill(14)} fill={props.fillColors[14]} d="M215.71,288.69h163.5c-20.95,20.98-45.64,32.53-74.55,34.24-34.37,2.03-63.76-9.67-88.94-34.24Z"/>
  <path onClick={() => props.onFill(15)} fill={props.fillColors[15]} d="M363.32,285.44H196.34v-22.58h166.99v22.58Z"/>
  <path onClick={() => props.onFill(16)} fill={props.fillColors[16]} d="M143.23,165.49h48.88c-9.33,21.26-12.4,43.17-8.45,66.11h-40.43v-66.11Z"/>
  <path onClick={() => props.onFill(17)} fill={props.fillColors[17]} d="M172.57,479.49v17.95H24.14v-17.95H172.57Z"/>
  <path onClick={() => props.onFill(18)} fill={props.fillColors[18]} d="M327.33,479.42h148.5v17.98h-148.5v-17.98Z"/>
  <path onClick={() => props.onFill(19)} fill={props.fillColors[19]} d="M475.87,3.31V21.21h-148.48V3.31h148.48Z"/>
  <path onClick={() => props.onFill(20)} fill={props.fillColors[20]} d="M172.62,3.13V21.16H23.95c0-5.61-.04-11.16,.07-16.72,0-.44,.9-1.06,1.5-1.25,.7-.22,1.52-.06,2.29-.06,47.07,0,94.13,0,141.2,0h3.62Z"/>
  <path onClick={() => props.onFill(21)} fill={props.fillColors[21]} d="M175.77,21.15V3.37h148.53V21.15H175.77Z"/>
  <path onClick={() => props.onFill(22)} fill={props.fillColors[22]} d="M175.82,497.38v-17.93h148.21v17.93H175.82Z"/>
  <path onClick={() => props.onFill(23)} fill={props.fillColors[23]} d="M3.12,24.51H20.81V173.14H3.12V24.51Z"/>
  <path onClick={() => props.onFill(24)} fill={props.fillColors[24]} d="M479.23,24.48h17.65V173.03h-17.65V24.48Z"/>
  <path onClick={() => props.onFill(25)} fill={props.fillColors[25]} d="M479.16,176.13h17.73v148.45h-17.73V176.13Z"/>
  <path onClick={() => props.onFill(26)} fill={props.fillColors[26]} d="M21.02,324.64H3.06V175.96c2.54,0,5.08-.03,7.63,0,2.5,.04,5.01,.34,7.49,.19,2.4-.14,2.88,.88,2.87,3.03-.09,17.9-.11,35.81-.11,53.71,0,29.17,.05,58.33,.07,87.5,0,1.33,0,2.66,0,4.23Z"/>
  <path onClick={() => props.onFill(27)} fill={props.fillColors[27]} d="M478.98,327.94h17.91v148.29c-1.23,.06-2.43,.18-3.64,.15-4.68-.12-9.36-.29-14.03-.44-.12-4.6-.35-9.19-.34-13.79,.01-43.52,.06-87.05,.1-130.57,0-1.14,0-2.27,0-3.65Z"/>
  <path onClick={() => props.onFill(28)} fill={props.fillColors[28]} d="M3.09,327.95H20.86c.06,1.28,.16,2.41,.16,3.54,0,47.08-.02,94.17,.06,141.25,0,2.85-.64,3.9-3.66,3.73-4.69-.27-9.4-.07-14.32-.07v-148.44Z"/>
  <path onClick={() => props.onFill(29)} fill={props.fillColors[29]} d="M24.06,234.78H127.45v24.98c-1.02,.05-1.96,.14-2.9,.14-32.45,0-64.9-.03-97.34,.07-2.71,0-3.42-.8-3.35-3.41,.18-6.25,.05-12.52,.06-18.78,0-.94,.09-1.88,.14-2.99Z"/>
  <path onClick={() => props.onFill(30)} fill={props.fillColors[30]} d="M475.94,262.91v22.37h-109.21v-22.37h109.21Z"/>
  <path onClick={() => props.onFill(31)} fill={props.fillColors[31]} d="M394.78,259.69v-25.04h81.1v25.04h-81.1Z"/>
  <path onClick={() => props.onFill(32)} fill={props.fillColors[32]} d="M157.38,384.09c0,9.21,.03,18.14-.08,27.06,0,.56-1.12,1.57-1.73,1.58-8.16,.11-16.32,.07-24.73,.07v-28.71h26.54Z"/>
  <path onClick={() => props.onFill(33)} fill={props.fillColors[33]} d="M130.75,444.58v-28.72h26.65c0,2.71,0,5.27,0,7.84,0,6.15,.06,12.3-.08,18.45-.02,.82-.99,2.32-1.55,2.33-8.25,.15-16.5,.1-25.03,.1Z"/>
  <path onClick={() => props.onFill(34)} fill={props.fillColors[34]} d="M130.76,447.65h26.35c.11,.76,.27,1.4,.27,2.05,.02,8.08,.06,16.16-.06,24.24-.01,.82-.99,2.32-1.55,2.33-8.25,.15-16.51,.1-25.02,.1v-28.72Z"/>
  <path onClick={() => props.onFill(35)} fill={props.fillColors[35]} d="M157.2,317.13h-26.44v-28.43h26.44v28.43Z"/>
  <path onClick={() => props.onFill(36)} fill={props.fillColors[36]} d="M130.69,349.21v-28.65h26.71c0,3.82,0,7.55,0,11.27,0,5,.07,10-.08,14.99-.02,.81-1.02,2.27-1.59,2.28-8.24,.15-16.48,.1-25.03,.1Z"/>
  <path onClick={() => props.onFill(37)} fill={props.fillColors[37]} d="M130.81,352.28h26.4c.07,1.07,.18,2,.19,2.93,.01,7.69-.06,15.38,.05,23.07,.03,2.15-.67,2.82-2.81,2.79-7.86-.11-15.73-.04-23.82-.04v-28.75Z"/>
  <path onClick={() => props.onFill(38)} fill={props.fillColors[38]} d="M193.09,285.37h-32.7v-22.45c.81-.09,1.55-.24,2.29-.24,9.24-.02,18.49-.02,27.73,0,1.16,0,2.69-.44,2.69,1.61,0,6.92,0,13.85,0,21.09Z"/>
  <path onClick={() => props.onFill(39)} fill={props.fillColors[39]} d="M391.94,111.38h-25.19v-26.72h25.19v26.72Z"/>
  <path onClick={() => props.onFill(40)} fill={props.fillColors[40]} d="M392.18,54.61v26.63h-25.46v-26.63h25.46Z"/>
  <path onClick={() => props.onFill(41)} fill={props.fillColors[41]} d="M366.65,51.19V24.57h25.47v26.62h-25.47Z"/>
  <path onClick={() => props.onFill(42)} fill={props.fillColors[42]} d="M366.64,231.49v-26.68h24.94v26.68h-24.94Z"/>
  <path onClick={() => props.onFill(43)} fill={props.fillColors[43]} d="M366.69,174.62h24.92v26.88h-24.92v-26.88Z"/>
  <path onClick={() => props.onFill(44)} fill={props.fillColors[44]} d="M366.73,144.68h24.95v26.72h-24.95v-26.72Z"/>
  <path onClick={() => props.onFill(45)} fill={props.fillColors[45]} d="M366.67,141.31v-26.62h25.08v26.62h-25.08Z"/>
  <path onClick={() => props.onFill(46)} fill={props.fillColors[46]} d="M366.7,259.65v-24.86h24.79v24.86h-24.79Z"/>
  <path onClick={() => props.onFill(47)} fill={props.fillColors[47]} d="M157.37,285.34h-26.6v-22.68c1.13,0,2.23,0,3.34,0,6.92,0,13.84-.05,20.76,.07,.84,.01,2.38,.94,2.4,1.47,.16,6.98,.11,13.97,.11,21.14Z"/>
  <path onClick={() => props.onFill(48)} fill={props.fillColors[48]} d="M496.84,21.21h-17.82c0-5.7-.03-11.23,.07-16.76,0-.44,.94-1.23,1.46-1.24,5.35-.09,10.71-.06,16.3-.06V21.21Z"/>
  <path onClick={() => props.onFill(49)} fill={props.fillColors[49]} d="M21.01,21.21H3.13V3.16c5.51,0,11.05-.04,16.58,.08,.44,0,1.22,1.16,1.24,1.79,.1,5.27,.06,10.55,.06,16.18Z"/>
  <path onClick={() => props.onFill(50)} fill={props.fillColors[50]} d="M3.04,479.45H20.82v17.99H3.04v-17.99Z"/>
  <path onClick={() => props.onFill(51)} fill={props.fillColors[51]} d="M496.9,497.44h-17.71v-18h17.71v18Z"/>

    </g>
            </svg>
        </div>
    )
}