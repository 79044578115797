import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import Fab from '@mui/material/Fab';
import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';

export default function ImageCard(props) {

    const {user, token} = useSelector(state => state.auth);
    let image = props.image
    const dispatch = useDispatch();
    const history = useHistory();

    const colorImage = () => { 
        if(!token){
            history.push("/login")
            return
        } 

        let newImage = {image_id: image.id, user_id: user.id}

        fetch(`https://test-api.stainedglassusa.com/user_images`, {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin':'*',
                'Content-Type':'application/json',
                'Accept': 'application/json',
                'Authorization': `bearer ${token}`
            },
            body: JSON.stringify(newImage)
        })
        .then(r => r.json())
        .then((resp) => {
            dispatch({
                type: 'ADD_USER_IMAGE',
                payload: resp
            });
            history.push(`/coloringpage/${resp.id}`)    
        })
    }

   
    return(
        <div className="image-card">
                <h4>{image.title}</h4>
                <img alt="Stained Glass" className="new-image" src={`https://test-api.stainedglassusa.com${image.svg_url}`} />
            
<Fab color="secondary" aria-label="edit" onClick={colorImage}>
  <EditIcon />
</Fab>
        </div>
    )
    

}